import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reverse',
    pure: false,
})
export class ReversePipe implements PipeTransform {
    transform(values): any {
        if (values) {
            const copy = [...values];
            return copy.reverse();
        }
    }
}
