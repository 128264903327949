export enum ModuleActions {
    GET_FOR_COACHING = 'modules:getForCoaching',
    GET_ALL = 'modules:getAll',
    GET_BY_ID = 'module:getById',
    GET_NAME_BY_ID = 'module:getNameById',
    CREATE = 'module:create',
    UPDATE = 'module:update',
    REMOVE = 'module:remove',
    CREATE_NEW_VERSION = 'module:createNewVersion',
    PUBLISH = 'module:publish',
    LOCK = 'module:lock',
    UNLOCK = 'module:unlock',

    ADD_STEP_TO_HISTORY = 'moduleMeta:addStepToHistory',
    REMOVE_STEP_FROM_HISTORY = 'moduleMeta:removeStepFromHistory',
    CLEAR_HISTORY = 'moduleMeta:clearHistory',

    UPLOAD_SUMMARY = 'module:uploadSummary',

    CHECK_IF_MODULE_IS_IN_COACHING = 'module:checkIfModuleIsInCoaching',

    COPY_TO_COACHING = 'module:copyToCoaching',
}
