/**
 * register sbk user body
 */
import { IsArray, IsBoolean, IsDate, IsDateString, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { SbkTel } from './sbk-tel.model';
import { SbkLanguage } from '../enums/sbk-language.enum';
import { SbkSalutation } from '../enums/sbk-salutation.enum';
import { SbkTitle } from '../enums/sbk-title.enum';
import { SbkProfileField } from './sbk-profile-field';

export class RegisterSbkUserBody {
    /**
     * due to dynamic handling of choices: use sting
     */
    @IsString()
    salutation: SbkSalutation;

    /**
     * due to dynamic handling of choices: use sting
     */
    @IsString()
    title: SbkTitle;

    @IsString()
    firstName: string;

    @IsString()
    lastName: string;

    @IsString()
    email: string;

    @IsString()
    @IsOptional()
    password: string;

    @IsString()
    @IsOptional()
    passwordRepeated: string;

    @IsDateString()
    dateOfBirth: Date;

    @IsString()
    tel1: string;

    @IsString()
    tel2: string;

    @IsString()
    tel3: string;

    @IsString()
    insurance: string;

    @IsString()
    policyNumber: string;

    @IsBoolean()
    dataConsent: boolean;

    @IsBoolean()
    adApproval: boolean;

    @IsNumber()
    campaignId: number;

    @IsArray()
    @IsOptional()
    additionalFields: SbkProfileField[];

    @IsString()
    language: SbkLanguage;

    /**
     * constructor
     * @param campaignId
     * @param language
     * @param salutation
     * @param title
     * @param firstName
     * @param lastName
     * @param email
     * @param password
     * @param passwordRepeated
     * @param dateOfBirth
     * @param tel1
     * @param tel2
     * @param tel3
     * @param insurance
     * @param policyNumber
     * @param dataConsent
     * @param adApproval
     * @param additionalFields
     */
    constructor(
        campaignId: number,
        language: SbkLanguage,
        salutation: SbkSalutation,
        title: SbkTitle,
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        passwordRepeated: string,
        dateOfBirth: Date,
        tel1: string,
        tel2: string,
        tel3: string,
        insurance: string,
        policyNumber: string,
        dataConsent: boolean,
        adApproval: boolean,
        additionalFields: SbkProfileField[],
    ) {
        this.campaignId = campaignId;
        this.language = language;
        this.salutation = salutation;
        this.title = title;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.password = password;
        this.passwordRepeated = passwordRepeated;
        this.dateOfBirth = dateOfBirth;
        this.tel1 = tel1;
        this.tel2 = tel2;
        this.tel3 = tel3;
        this.insurance = insurance;
        this.policyNumber = policyNumber;
        this.dataConsent = dataConsent;
        this.adApproval = adApproval;
        this.additionalFields = additionalFields;
    }
}
