import { ID } from '@datorama/akita';
import { Step } from '../../../../../../backend/src/modules/coaching/models/step/step.model';
import { AnimationValidityState } from '../../../modules/coaching/module-stepper/state/animation-validity-state.enum';
import { IAnswer } from '../answer/answer.model';

/**
 * step type
 */
export interface IStep extends Step {
    _id: ID;
    animationValidityState: AnimationValidityState;
    translationVersionNumber: number;
    answers: IAnswer[];
}

/**
 * factory function
 */
export function createStep(params: Partial<IStep>) {
    return { ...params } as IStep;
}
