export enum UserActions {
    GET = 'users:get',
    UPDATE = 'user:update',
    REMOVE = 'user:remove',
    ANONYMIZE = 'user:anonymize',
    NEW_PASSWORD = 'user:newPassword',
    GET_CURRENT = 'users:getCurrent',
    GENERATE_DEMO_ENTRIES = 'users:generateDemoEntries',
    GET_ACTIVATION_LINK = 'users:getActivationLink',
    SEND_BRAND_AMBASSADOR_REQUEST_EMAIL = 'user:sendBrandAmbassadorRequestEmail',
    GET_USER_NAME_BY_ID = 'users:getUserNameById',
}
