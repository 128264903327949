import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SupportService } from './state/support.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Branding } from '../../../../../../backend/src/shared/modules/authentication/enums/brandings.enum';
import { AppContextQuery } from '../../../shared/application/state/app-context.query';
import { LanguageQuery } from '../../../shared/state/language/language.query';
import { Observable } from 'rxjs';
import { Language } from '../../../shared/state/language/language.model';
import { LicenseQuery } from '../../../shared/state/license/license.query';
import { LicenseService } from '../../../shared/state/license/license.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslationService } from '../../../shared/translation/translation.service';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { ConfirmationDialogComponent } from '../../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContactSupportBody } from '../../../../../../backend/src/shared/modules/support/models/contact-support-body';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'mm-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.sass'],
})

/**
 * support component
 */
export class SupportComponent implements OnInit {
    /**
     * tab index
     */
    public tabIndex = 0;

    /**
     * isSending
     */
    public isSending: boolean = false;

    /**
     * support form
     */
    public supportForm: UntypedFormGroup;

    /**
     * theme
     */
    public branding: any;

    /**
     * theming
     */
    public Branding: typeof Branding = Branding;

    /**
     * translation key for the support page hint
     */
    public supportPageTranslationKey = 'general_support_page_somnio';

    /**
     * active user language
     */
    public activeLanguage: Language;

    public application: Application;

    public faqs: { link: string; question: string }[];

    public Application: typeof Application = Application;

    get commonStrings() {
        return this.translationService.strings.common;
    }

    get strings() {
        return this.translationService.strings.coaching.supportDialogue;
    }

    /**
     * form getters
     */
    get text(): AbstractControl {
        return this.supportForm.get('text');
    }

    /**
     * constructor
     */
    constructor(
        private formBuilder: UntypedFormBuilder,
        private appContextQuery: AppContextQuery,
        private supportService: SupportService,
        private languageQuery: LanguageQuery,
        private licenseQuery: LicenseQuery,
        private licenseService: LicenseService,
        private deviceService: DeviceDetectorService,
        private translationService: TranslationService,
        private dialog: MatDialog,
    ) {}

    initFAQs(): void {
        const faqs = [];
        const appStrings = this.strings[this.application].faqs;
        for (const key in appStrings) {
            faqs.push({
                link: appStrings[key].link(),
                question: appStrings[key].question(),
            });
        }
        this.faqs = faqs;
    }

    /**
     * called on component init
     */
    ngOnInit(): void {
        // theming
        this.branding = this.appContextQuery.getValue().branding;

        // set appropriate support page hint
        this.application = this.appContextQuery.getValue().application;
        if (this.application) {
            this.supportPageTranslationKey = `general_support_page_${this.application}`;
        }

        // setup form
        this.supportForm = this.formBuilder.group({
            text: ['', Validators.required],
        });

        (this.languageQuery.selectActive() as Observable<Language>)
            .pipe(untilDestroyed(this))
            .subscribe((language: Language) => {
                this.activeLanguage = language;
            });

        this.initFAQs();
    }

    /**
     * send the support request
     */
    public sendRequest(): void {
        if (this.supportForm.valid) {
            // set isSending to true
            this.isSending = true;
            const contactSupportBody: ContactSupportBody = {
                deviceInfo: this.deviceService.getDeviceInfo(),
                isMobile: this.deviceService.isMobile(),
                isTablet: this.deviceService.isTablet(),
                isDesktop: this.deviceService.isDesktop(),
                userQuestion: this.text.value,
                application: this.appContextQuery.getValue().application,
            };
            this.supportService.contactSupport(contactSupportBody).subscribe(
                () => {
                    this.tabIndex = 2;
                    this.isSending = false;
                },
                error => {
                    this.isSending = false;
                },
            );
        } else {
            this.supportForm.markAllAsTouched();
        }
    }

    public onClickExternalLink(url: string): void {
        ConfirmationDialogComponent.open(this.dialog, {
            data: { linkToOpenOnConfirm: url, message: this.translationService.strings.common.leaveAppConfirmation() },
        });
    }
}
