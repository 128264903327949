import { IsArray, IsString } from 'class-validator';
import { NotificationChannel } from '../../coaching/modules/notification-channel/notification-channel.model';

/**
 * body to set push notifications
 */
export class SetPushNotificationBody {
    @IsArray()
    notificationChannels: NotificationChannel[];

    @IsString()
    application: string;
}
