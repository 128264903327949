import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../language/language.service';

@Pipe({
    name: 'localise',
    pure: false,
})

/**
 * localisation pipe
 * */
export class LocalisationPipe implements PipeTransform {
    /**
     * constrcust a localisation pipe
     */
    constructor(private languageService: LanguageService) {}

    /**
     * localises a key with optional parameters
     * */
    public transform(key: string, args?: any[]): string {
        if (key) {
            return this.languageService.getText(key, args);
        }
        return '[keyMissing ' + key + ']';
    }
}
