import { Component, Inject, OnInit } from '@angular/core';
import { LicenseExpirePromptService } from './license-expire-promt.service';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import { SettingsEnum } from '../../../../../../backend/src/shared/modules/coaching/enums/settings.enum';
import { CoachingService } from '../../../shared/state/coaching/coaching.service';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import * as cloneDeep from 'lodash/cloneDeep';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LicenseQuery } from '../../../shared/state/license/license.query';
import { ILicense } from '../../../shared/state/license/license.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'mm-license-expire-prompt',
    templateUrl: './license-expire-prompt.component.html',
    styleUrls: ['./license-expire-prompt.component.sass'],
})
export class LicenseExpirePromptComponent implements OnInit {
    public expireDays: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) days: number,
        private coachingService: CoachingService,
        private licenseExpirePromptService: LicenseExpirePromptService,
        private licenseQuery: LicenseQuery,
        private coachingQuery: CoachingQuery,
    ) {
        this.expireDays = days;
    }

    ngOnInit(): void {}

    /**
     * user button got it
     */
    public onGotItClick(): void {
        this.saveAndCloseDialog();
    }

    /**
     * user button send all infos via mail
     */
    public sendInfosViaMail(): void {
        this.licenseExpirePromptService.sendInfosViaMail(this.expireDays).pipe(untilDestroyed(this)).subscribe();
        this.saveAndCloseDialog();
    }

    /**
     * update coaching settings and close dialog
     * @private
     */
    private saveAndCloseDialog(): void {
        const coaching: ICoaching = cloneDeep(this.coachingQuery.getActive());
        const license = this.licenseQuery.getActive() as ILicense;

        if (!coaching.settings?.length) {
            coaching.settings = [];
        }

        let values = [];
        if (typeof license.vouchers[0] === 'string') {
            values = license.vouchers;
        } else {
            for (const voucher of license.vouchers) {
                values.push(voucher._id);
            }
        }

        coaching.settings.push({
            property: SettingsEnum.HAS_SEEN_LICENSE_EXPIRE_PROMPT,
            value: JSON.stringify(values),
        });

        this.coachingService.updateActiveCoachingSettings(coaching.settings).pipe(untilDestroyed(this)).subscribe();

        this.licenseExpirePromptService.closePrompt();
    }
}
