import { NgModule } from '@angular/core';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PipesModule } from '../pipes/pipe.module';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SmallDialogWrapperComponent } from './small-dialog-wrapper/small-dialog-wrapper.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { StaticHtmlDialogComponent } from './static-html-dialog/static-html-dialog.component';

export const DIALOG_COMPONENTS = [
    DialogHeaderComponent,
    DialogActionsComponent,
    SmallDialogWrapperComponent,
    ConfirmationDialogComponent,
    StaticHtmlDialogComponent,
];

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        PipesModule,
    ],
    declarations: DIALOG_COMPONENTS,
    exports: DIALOG_COMPONENTS,
    providers: [],
})
export class DialogModule {}
