const dictionarySbk = {
    en_us: {
        general_entries_remaining_help:
            'sleep well controls various contents based on your sleep journal. For this to work, a certain amount of entries is necessary before modules can be accessed.',
        general_data_export_visual_analysis_file_name: 'sleep well_sleep_data',
        general_data_processing_agreement:
            'When creating your account, you have given consent to sleep well for the processing your personal data.',
        general_support_page_somnio:
            'For questions and support please visit our <a href="https://support.mementor.de/help/en-gb" target="_blank">support page</a>.',
        handlebars_mail_disable_notification_message_success:
            'You will no longer receive notifications from sleep well via e-mail. You can modify this setting in the profile page of your sleep well account.',
        handlebars_mail_disable_notification_message_error:
            'The link to disable the notification from sleep well is invalid. You can modify this setting in the profile page of your sleep well account.',
        mail_registration_activation_title: 'Welcome to sleep well',
        mail_inform_user_registered_with_oauth_message:
            'You have requested a new password. Please note that this account has been registered with an external login, so you do not have a specific password for sleep well. Thus, instead of a regular "Login", you need to click on the corresponding provider that you have registered this account with (e.g. "Login with Google").',
        mail_somnio_license_expiring_title: 'Your license for the sleep well sleep training',
        mail_somnio_journal_reminder_title: 'Sleep journal entries missed',
        mail_somnio_license_expiring_message_non_diga:
            'We would like to inform you that your license for the sleep well sleep training expires in $$ days. After the license expires, you will need a new license code to log in again.',
        mail_somnio_license_expired_title: 'Your license for the sleep well sleep training',
        mail_somnio_registered_not_logged_in_title: 'Your registration at sleep well',
        mail_somnio_registered_not_logged_in_body:
            'Welcome to sleep well! Thanks for your registration. We have not seen you lately. If you have trouble logging in, please contact our support. Please be aware that if you never log in',
        mail_unsubscribe_newsletter_text:
            'Would you like to stop receiving notifications by mail from sleep well? Please click ',
        mail_module_open_not_started_title: 'Open modules in the sleep well sleep training',
        mail_module_open_not_started_body:
            'We noticed there are open modules in your sleep training that you haven\'t started yet. On <a href="https://schlafgut.sbk.org">sleep well</a> you can launch the open modules.',
        mail_module_started_not_completed_title: 'New module available',
        mail_module_started_not_completed_body:
            'We noticed that you have been working on the module $$ but didn\'t complete it yet. You can complete the module on <a href="https://schlafgut.sbk.org">sleep well</a>',
        mail_somnio_not_started_first_module_warning_title:
            "Let's go: Launch your first training session at Sleep well!",
        mail_somnio_not_started_first_module_warning_body:
            'get started with your first module for <b>improved sleep</b> and <b>increased well-being</b> at<a href="https://schlafgut.sbk.org">schlafgut.sbk.org</a> or in the SBK Schlaf gut-App (<a href="https://play.google.com/store/apps/details?id=org.sbk.schlafgut.debug">Playstore</a> oder <a href="https://apps.apple.com/de/app/sbk-schlaf-gut/id1557618165">App Store</a>). Your <b>digital sleep expert Albert</b> is looking forward to meet you.<br><br>If you have any problems <b>signing in</b>, don\'t hesitate to contact us at <a href="mailto:sbk.schlafgut@mementor.de ">sbk.schlafgut@mementor.de</a>.',
        mail_payment_voucher_bought_title: 'Your code for mementor sleep well',
        mail_payment_voucher_bought_message:
            "Welcome to sleep well, your sleep training for a good night's sleep! We've received your payment and you can get started now:",
        mail_payment_voucher_bought_step_1: 'Click <a href="$$">here</a> to create a new sleep well user account',
        mail_payment_voucher_bought_step_3:
            'After that, you can get going with sleep well. If you have any questions during the activation process or later on in the training, please contact support on <a href="mailto:sbk.schlafgut@mementor.de ">sbk.schlafgut@mementor.de</a>',
        mail_duplicate_registration_message:
            'You just tried to register. A sleep well account with your email address already exists. You can log in Do you have problems signing in? You can reset your password',
        mail_demo_account_welcome:
            'wir freuen uns sehr, dass Sie sich für sleep well interessieren. Mit dieser E-Mail erhalten Sie Ihre persönliche Testlizenz und somit die Möglichkeit, sich mit unserem digitalen Schlaftraining vertraut zu machen.',
        mail_flyer_confirmation: 'sleep well: Eingang Ihrer Bestellung von Flyern',
        mail_flyer_confirmation_details_a:
            'Vielen Dank für Ihr Interesse am digitalen Schlaftraining sleep well. Die Flyer für Ihre Patient:innen senden wir Ihnen in Kürze an die angegebene Adresse.',
        mail_certificate_download_body:
            "You have completed the sleep training somnio and showed a lot of motivation and perseverance - you can be proud of yourself! By clicking the button below, you may now download your participation certificate. Please note that the download of the certificate is only available for $$ days after receiving this e-mail. Please contact us anytime if you have any questions. We're here to help!",
    },
    de_ch: {
        general_entries_remaining_help:
            'Schlafgut steuert verschiedene Inhalte aufgrund deines Schlaftagebuchs. Damit das funktioniert und weitere Module durchgeführt werden können, ist eine gewisse Mindestanzahl an Schlaftagebucheinträgen erforderlich.',
        general_data_export_visual_analysis_file_name: 'Schlafgut_schlafdaten',
        general_data_processing_agreement:
            'Bei der Erstellung deines Kontos hast du Schlafgut die Zustimmung zur Verarbeitung deiner personenbezogenen Daten erteilt.',
        general_support_page_somnio:
            'Für Fragen und Support besuche bitte die <a href="https://support.mementor.de/help/de-de/16-somnio" target="_blank">Supportseite</a> von Schlafgut.',
        handlebars_mail_disable_notification_message_success:
            'Du wirst keine weiteren Benachrichtigungen von Schlafgut erhalten. Du kannst diese Einstellung im Profil deines Kontos anpassen.',
        handlebars_mail_disable_notification_message_error:
            'Der Link zum Abmelden der Benachrichtigungen ist nicht gültig. Du kannst diese Einstellung im Profil deines Kontos anpassen.',
        mail_registration_activation_title: 'Willkommen bei Schlafgut',
        mail_inform_user_registered_with_oauth_message:
            'Du hast ein neues Passwort angefordert. Bitte beachte, dass du dich mit einem externen Login angemeldet hast, sodass du kein persönliches Passwort für Schlafgut hast. Klicke deshalb anstelle des normalen Logins, auf den Link zum Login vom entsprechenden Anbieter (beispielsweise "Login mit Google")',
        mail_somnio_license_expiring_title: 'Deine Lizenz für das Schlafgut Schlaftraining',
        mail_somnio_license_expiring_message_non_diga:
            'Wir möchten dich darüber informieren, dass deine Lizenz für das Schlafgut Schlaftraining in $$ Tagen abläuft. Nach dem Ablauf der Lizenz benötigst du einen neuen Lizenzcode, um dich wieder einloggen zu können.',
        mail_somnio_license_expired_title: 'Deine Lizenz für das Schlafgut Schlaftraining',
        mail_somnio_journal_reminder_title: 'Schlaftagebucheinträge Schlafgut',
        mail_somnio_registered_not_logged_in_title: 'Deine Registrierung bei Schlafgut',
        mail_somnio_registered_not_logged_in_body:
            'Willkommen beim Schlafgut Schlaftraining! Du hast dich registriert, jedoch die Module nicht bearbeitet. Auf <a href="https://schlafgut.sbk.org">Schlafgut</a> kannst du die Bearbeitung der Module starten. Wenn du Probleme beim Einloggen hast, kannst du gerne unseren Support <a href="mailto: sbk.schlafgut@mementor.de">kontaktieren</a>. Bitte beachte, dass dein Konto ohne Login infolge von Inaktivität nach 30 Tagen gelöscht wird.',
        mail_unsubscribe_newsletter_text:
            'Du wünschst keine weiteren Benachrichtigungen per E-Mail von Schlafgut zu erhalten? Dann klicke bitte ',
        mail_module_open_not_started_title: 'Offene Module im Schlafgut Schlaftraining',
        mail_module_open_not_started_body:
            'es gibt Neuigkeiten: In deinem Schlaftraining wurde ein neues Modul freigeschaltet. Klicke auf <a href="https://schlafgut.sbk.org">Schlaf gut!</a> , um direkt mit dem Modul zu starten.',
        mail_module_started_not_completed_title: 'Nicht abgeschlossenes Modul im Schlafgut Schlaftraining',
        mail_module_started_not_completed_body:
            'wir haben gesehen, dass du kürzlich das Modul $$ angefangen, aber noch nicht abgeschlossen hast. Mit Klick auf <a href="https://schlafgut.sbk.org">Schlaf gut!</a>, kannst du das Modul fortsetzen und somit abschließen. Viel Spaß!',
        mail_somnio_not_started_first_module_warning_title: "Los geht's: Starte jetzt das erste Modul bei Schlaf gut!",
        mail_somnio_not_started_first_module_warning_body:
            'beginnen Sie unter <a href="https://schlafgut.sbk.org">schlafgut.sbk.org</a> oder in der Schlaf gut-App der SBK (<a href="https://play.google.com/store/apps/details?id=org.sbk.schlafgut.debug">Playstore</a> oder <a href="https://apps.apple.com/de/app/sbk-schlaf-gut/id1557618165">App Store</a>) jetzt Ihr erstes Modul für <b>erholsamen Schlaf</b> und <b>mehr Wohlbefinden</b>. Ihr <b>digitaler Coach Albert</b> freut sich auf Sie.<br><br>Sollten Sie Probleme beim <b>Einloggen</b> haben, melden Sie sich bei uns unter <a href="mailto:sbk.schlafgut@mementor.de ">sbk.schlafgut@mementor.de </a>.',
        mail_payment_voucher_bought_title: 'Dein Code für Schlafgut',
        mail_payment_voucher_bought_message:
            'Willkommen bei Schlafgut, deinem Schlaftraining für einen guten und erholsamen Schlaf! Wir haben deine Zahlung erhalten und du kannst loslegen:',
        mail_payment_voucher_bought_step_1:
            'Klicke <a href="$$">hier</a>, um für Schlafgut ein neues Konto zu erstellen',
        mail_payment_voucher_bought_step_3: 'Danach kannst du mit Schlafgut starten.',
        mail_demo_account: 'Testlizenz Schlafgut',
        mail_demo_account_welcome:
            'wir freuen uns sehr, dass Sie sich für <b>Schlafgut</b> interessieren. Mit dieser E-Mail erhalten Sie Ihre persönliche Testlizenz und somit die Möglichkeit, sich mit unserem digitalen Schlaftraining vertraut zu machen.',
        mail_flyer_confirmation: 'Schlafgut: Eingang Ihrer Bestellung von Flyern',
        mail_flyer_confirmation_details_a:
            'Vielen Dank für Ihr Interesse am digitalen Schlaftraining Schlafgut. Die Flyer für Ihre Patient:innen senden wir Ihnen in Kürze an die angegebene Adresse.',
        mail_certificate_download_body:
            'Du hast das Schlaftraining somnio abgeschlossen und dabei viel Motivation und Durchhaltevermögen bewiesen - Du kannst stolz auf dich sein! Mit Klick auf den unten stehenden Button kannst du dir nun dein Teilnahmezertifikat herunterladen. Beachte jedoch, dass der Download des Zertifikates ab Erhalt der Mail nur $$ Tage zur Verfügung steht. Bei Fragen kannst du uns jeder Zeit kontaktieren. Wir helfen gerne weiter!',
    },
    fr_ch: {},
    it_ch: {},
};

export default dictionarySbk;
