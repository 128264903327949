import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { LicenseQuery } from '../../../shared/state/license/license.query';
import { ILicense } from '../../../shared/state/license/license.model';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import { SettingsEnum } from '../../../../../../backend/src/shared/modules/coaching/enums/settings.enum';
import { Observable } from 'rxjs';
import { IUser } from '../../../shared/state/user/user.model';
import { SocketService } from '../../../shared/socket/socket.service';
import { LicensingActions } from '../../../../../../backend/src/shared/modules/licensing/actions/licensing.actions';
import { LicenseService } from '../../../shared/state/license/license.service';
import { LanguageQuery } from '../../../shared/state/language/language.query';

@Injectable({
    providedIn: 'root',
})

/**
 * license expire service
 */
export class LicenseExpirePromptService {
    private license: ILicense;

    constructor(
        private dialog: MatDialog,
        private coachingQuery: CoachingQuery,
        private licenseQuery: LicenseQuery,
        private licenseService: LicenseService,
        private languageQuery: LanguageQuery,
        private socketService: SocketService,
    ) {}

    /**
     * checks if a license if about to expire
     * @param component
     */
    public checkLicenseExpiration(component: any): void {
        this.license = this.licenseQuery.getActive() as ILicense;
        const isDiga = this.licenseService.isDigaLicense(this.license);

        if (!isDiga) {
            return;
        }

        this.getLicenseExpirationDaysForUser().subscribe(response => {
            if (response.expireDays <= response.warnDays && response.expireDays > 0 && !this.checkIfAlreadySeen()) {
                setTimeout(() => {
                    this.dialog.open(component, {
                        autoFocus: false,
                        maxWidth: '550px',
                        data: response.expireDays,
                        disableClose: true,
                    });
                }, 1000);
            }
        });
    }

    /**
     * checks if user has seen the prompt already
     * @private
     */
    private checkIfAlreadySeen(): boolean {
        const coaching: ICoaching = this.coachingQuery.getActive() as ICoaching;
        const setting = coaching?.settings?.find(
            coachingSetting => coachingSetting.property === SettingsEnum.HAS_SEEN_LICENSE_EXPIRE_PROMPT,
        );
        // if the length of the license voucher is different from the setting voucher, either the user has not seen the message yet.
        if (!setting || this.license.vouchers.length !== JSON.parse(setting.value)?.length) {
            return false;
        }
        return true;
    }

    /**
     * closes the dialog
     */
    public closePrompt(): void {
        this.dialog.closeAll();
    }

    /**
     * user has clicked send expiration infos via mail
     * @param daysTillExpire
     */
    public sendInfosViaMail(daysTillExpire: number): Observable<any> {
        return new Observable(observer => {
            // load
            this.socketService
                .fire(LicensingActions.SEND_RENEWAL_INFOS_VIA_MAIL, {
                    key: this.license.coachingKey,
                    days: daysTillExpire,
                })
                .subscribe(
                    (user: IUser) => {
                        observer.next(user);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                    },
                );
        });
    }

    /**
     * calculates expiration days
     * @private
     */
    private getLicenseExpirationDaysForUser(): Observable<{ expireDays: number; warnDays: number }> {
        return new Observable(observer => {
            // load
            this.socketService.fire(LicensingActions.GET_LICENSE_EXPIRATION_DAYS, {}).subscribe(
                (days: { expireDays: number; warnDays: number }) => {
                    observer.next(days);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }
}
