import {
    Component,
    DestroyRef,
    ElementRef,
    HostListener,
    inject,
    isDevMode,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { akitaConfig, akitaDevtools } from '@datorama/akita';
import { FadeTransition } from './shared/animations/route.animations';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { KeyListenerService } from './shared/key-listener/key-listener.service';
import { IconsService } from './shared/icons/icons.service';
import { Branding } from '../../../backend/src/shared/modules/authentication/enums/brandings.enum';
import { AppContextQuery } from './shared/application/state/app-context.query';
import { Application } from '../../../backend/src/shared/modules/coaching/enums/application.enum';
import { SocketService } from './shared/socket/socket.service';
import { AppContextState } from './shared/application/state/app-context.store';
import { Subscription } from 'rxjs';
import { TranslationService } from './shared/translation/translation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggingTool } from '../tools/logging/contract';

declare const loadMatomo: any;

declare let screen;

@Component({
    selector: 'mm-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    animations: [FadeTransition()],
})
export class AppComponent implements OnInit, OnDestroy {
    /*
     * the subscription for matomo tracking
     * @private
     */
    private applicationSubscription: Subscription;

    /**
     * angular changePath description
     * @private
     */
    private pathSubscription: Subscription;

    private matomoIsEnabledByApp: Record<Application, boolean> = {
        [Application.SOMNIO]: true,
        [Application.ACTENSIO]: false,
        [Application.SOMNIO_JUNIOR]: false,
        [Application.GLYKIO]: false,
        [Application.MALIO]: false,
    };

    private destroyRef = inject(DestroyRef);
    private logoutInterval: NodeJS.Timer;

    /**
     * mitigates key events to a service
     * @param event
     */
    @HostListener('window:keyup', ['$event'])
    keyDown(event: KeyboardEvent): void {
        this.keyListenerService.keyDown(event);
    }

    constructor(
        private ngZone: NgZone,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private matDialog: MatDialog,
        private elementRef: ElementRef,
        private keyListenerService: KeyListenerService,
        private iconService: IconsService,
        private socketService: SocketService,
        private appContextQuery: AppContextQuery,
        private transService: TranslationService,
        private deviceService: DeviceDetectorService,
        private loggingTool: LoggingTool,
    ) {
        transService.init().then();

        // enable redux debugging in dev mode
        if (isDevMode()) {
            akitaDevtools(ngZone);
        }

        // make stores resettable
        akitaConfig({ resettable: true });

        // lock orientation if possible
        if (screen && screen.hasOwnProperty('lockOrientation')) {
            screen.lockOrientation('portrait');
        }

        // close dialogs on navigation
        router.events
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter((event: NavigationStart) => event instanceof NavigationStart),
                tap(() => this.matDialog.closeAll()),
            )
            .subscribe();

        // add matomo tracking
        const debugMode = isDevMode();
        this.applicationSubscription = this.appContextQuery
            .select()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((state: AppContextState) => {
                // stop tracking if the user is logged in
                if (this.socketService?.socket?.connected) {
                    if (this.applicationSubscription) {
                        this.applicationSubscription.unsubscribe();
                        this.applicationSubscription = null;
                    }
                    return;
                }
                this.addMatomoHandling(router, state.branding, state.application, debugMode);
            });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && !event.url.includes('session')) {
                this.socketService.connectToSocket().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
            }
        });
    }

    /**
     * hide ng version
     */
    ngOnInit(): void {
        this.elementRef.nativeElement.removeAttribute('ng-version');
        this.iconService.registerStandardIconSet();

        if (!window.location.href.includes('session')) {
            const date = new Date();
            const isWednesdayAfter230am = date.getDay() === 3 && date.getHours() === 2 && date.getMinutes() > 30;
            this.logoutInterval = setInterval(() => {
                if (isWednesdayAfter230am && this.socketService.socket?.connected) {
                    this.loggingTool.info(`Automatic logout it triggered`);
                    this.socketService.handleFailedSocketConnection();
                }
            }, 1000 * 60 * 5);
        }
    }

    /**
     * gets the router state
     */
    public getState(outlet): string | undefined {
        return outlet.activatedRouteData.state;
    }

    /**
     * depending on the app: add a matomo tracking tag
     * @param router
     * @param branding
     * @param application
     * @param debug
     * @private
     */
    private addMatomoHandling(router: Router, branding: Branding, application: Application, debug: boolean): void {
        if (branding === Branding.MEMENTOR && this.matomoIsEnabledByApp[application]) {
            // skip if already subscribed
            if (this.pathSubscription) {
                return;
            }
            this.pathSubscription = router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
                // stop tracking if the user is logged in
                if (this.socketService?.socket?.connected) {
                    if (this.pathSubscription) {
                        this.pathSubscription.unsubscribe();
                        this.pathSubscription = null;
                    }
                    return;
                }
                if (event instanceof NavigationEnd) {
                    const path = window?.location?.pathname;
                    // double-check that no logged-in paths are tracked (but only the registration and login page)
                    if (path?.endsWith('session/login') || path?.endsWith('session/registration') || path === '') {
                        loadMatomo(application, path, debug);
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        clearInterval(this.logoutInterval);
    }
}
