import { NgModule } from '@angular/core';
import { DialogModule } from '../../../shared/dialog/dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../shared/pipes/pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { LicenseExpirePromptService } from './license-expire-promt.service';
import { LicenseExpirePromptComponent } from './license-expire-prompt.component';

@NgModule({
    imports: [DialogModule, MatDialogModule, PipesModule, MatIconModule, MatButtonModule, CommonModule],
    declarations: [LicenseExpirePromptComponent],
    providers: [LicenseExpirePromptService],
    exports: [],
})
export class LicenseExpirePromptModule {}
