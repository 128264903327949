import { LanguageCode } from '../../modules/language/enums/language.enum';
import { ITranslation } from '../../modules/language/models/translation.model';

/**
 * Get the translation for the given language code with fallback to ***Dialect Language Default***
 *
 * @param {ITranslation} translation - the translation object containing language codes and their respective translations
 * @param {string} code - the language code for which translation is requested
 * @return {string} the translated text for the given language code
 * @example
 * getTranslationWithFallback({en_au: undefined, en_us: "Hello"}, "en_au");
 * // returns "Hello"
 */
export const getTranslationWithDialectFallback = (translation: ITranslation, code: string): string => {
    switch (code) {
        // Check if en_au is available, if not, fallback to en_us
        case LanguageCode.EN_AU:
            return translation.en_au || translation.en_us;
        default:
            return translation[code];
    }
};
