import { NgModule } from '@angular/core';
import { DialogModule } from '../../../shared/dialog/dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../shared/pipes/pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { NutritionProtocolScoreComponent } from './nutrition-profile-score/nutrition-protocol-score.component';
import { NutritionProtocolComponent } from './nutrition-protocol.component';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NutritionProtocolScoresOverviewComponent } from './nutrition-protocol-scores-overview/nutrition-protocol-scores-overview.component';
import { HeaderModule } from '../../../shared/header/header.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        DialogModule,
        MatDialogModule,
        PipesModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
        MatCardModule,
        ReactiveFormsModule,
        MatTooltipModule,
        HeaderModule,
        MatInputModule,
    ],
    declarations: [
        NutritionProtocolScoreComponent,
        NutritionProtocolComponent,
        NutritionProtocolScoresOverviewComponent,
    ],
    providers: [],
    exports: [NutritionProtocolScoresOverviewComponent],
})
export class NutritionProtocolModule {}
