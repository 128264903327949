import { Inject, Injectable } from '@angular/core';
import { Application } from '../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { AppContextQuery } from './state/app-context.query';
import { AppContextState, AppContextStore } from './state/app-context.store';
import { Branding } from '../../../../../backend/src/shared/modules/authentication/enums/brandings.enum';
import { ConfigService } from '../config/config.service';
import { DOCUMENT } from '@angular/common';
import { CoachingQuery } from '../state/coaching/coaching.query';
import { ICoaching } from '../state/coaching/coaching.model';
import { Observable } from 'rxjs';
import { toApplication } from '../../../../../backend/src/modules/coaching/enums/coaching-types.enum';
import { KeyListenerService, MMKeyEventEnum, MMSpecialKeyEnum } from '../key-listener/key-listener.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Region } from '../../../../../backend/src/shared/entities/region.enum';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class AppContextService {
    public faviconSmall: HTMLLinkElement = document.querySelector('#faviconSmall');
    public faviconLarge: HTMLLinkElement = document.querySelector('#faviconLarge');
    public faviconApple: HTMLLinkElement = document.querySelector('#faviconApple');

    constructor(
        private query: AppContextQuery,
        private store: AppContextStore,
        private configService: ConfigService,
        private coachingQuery: CoachingQuery,
        private keyListenerService: KeyListenerService,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(DOCUMENT) private doc,
    ) {}

    private keyByApp: Record<Application, MMKeyEventEnum> = {
        [Application.SOMNIO]: MMKeyEventEnum.S,
        [Application.SOMNIO_JUNIOR]: MMKeyEventEnum.J,
        [Application.ACTENSIO]: MMKeyEventEnum.A,
        [Application.GLYKIO]: MMKeyEventEnum.G,
        [Application.MALIO]: MMKeyEventEnum.M,
    };

    public initialise(): void {
        this.store.update(this.getInitialState());

        this.updateApplicationOnCoachingChange();

        if (this.configService.isDevEnvironment()) {
            this.updateApplicationOnKeyPress();
            this.updateBrandingOnKeyPress();
            this.updateApplicationOnQueryParamChange();
        }

        this.updateAppearanceOnStateChange();
    }

    private getInitialState(): AppContextState {
        const url = window.location.href;

        // SBK
        if (url.includes('sbk.org')) {
            return { application: Application.SOMNIO, branding: Branding.SBK, region: Region.DEFAULT };
        }

        // ALA
        if (url.includes('au.somn.io') || url.includes('au-int.somn.io')) {
            return { application: Application.SOMNIO, branding: Branding.MEMENTOR, region: Region.OCEANIC };
        }

        // handle application as query param in dev environment
        if (this.configService.isDevEnvironment()) {
            const application = this.getApplicationFromRouteParams(new URLSearchParams(window.location.search));
            const region = this.getRegionFromRouteParams(new URLSearchParams(window.location.search));
            if (application) {
                return { application, branding: Branding.MEMENTOR, region: Region.DEFAULT };
            }
            if (region) {
                return { application: Application.SOMNIO, branding: Branding.MEMENTOR, region: Region.OCEANIC };
            }
        }

        // default handling
        for (const [application, urlDomain] of Object.entries(this.configService.urlDomainByApp)) {
            if (url.includes(urlDomain)) {
                return { application: application as Application, branding: Branding.MEMENTOR, region: Region.DEFAULT };
            }
        }

        // fallback
        return { application: Application.SOMNIO, branding: Branding.MEMENTOR, region: Region.DEFAULT };
    }

    private updateAppearanceOnStateChange(): void {
        this.query.select().subscribe(({ application, branding }: AppContextState) => {
            if (this.isCalledFromAdminPanel()) {
                return;
            }

            if (branding === Branding.SBK) {
                document.body.className = Branding.SBK;
                document.title = 'SBK - Schlaf gut!';
                this.faviconSmall.href = 'assets/logos/sbk/sbk_favicon_small.png';
                this.faviconLarge.href = 'assets/logos/sbk/sbk_favicon_large.png';
                this.faviconApple.href = 'assets/logos/sbk/sbk_favicon_apple.png';
                return;
            }

            document.title = application;
            // TODO remove special case for somnio after updating CSS (mementor-theme to somnio-theme)
            document.body.className = application === Application.SOMNIO ? Branding.MEMENTOR : application;

            this.faviconSmall.href = `assets/logos/mementor/favicon_${application}_32x32.png`;
            this.faviconLarge.href = `assets/logos/mementor/favicon_${application}_180x180.png`;
            this.faviconApple.href = `assets/logos/mementor/favicon_${application}_192x192.png`;
        });
    }

    private updateApplicationOnCoachingChange(): void {
        (this.coachingQuery.selectActive() as Observable<ICoaching>).subscribe(coaching => {
            if (this.isCalledFromAdminPanel()) {
                return;
            }

            if (!coaching) {
                return this.store.update(this.getInitialState());
            }
            // can't change application if branding is SBK
            if (this.query.getValue().branding === Branding.SBK) {
                return;
            }

            let application = toApplication[coaching.key];
            // use somnio as default for coachings that are not assigned to an application yet
            if (!application) {
                application = Application.SOMNIO;
            }

            this.store.update({ application });
        });
    }

    private updateApplicationOnKeyPress(): void {
        for (const [app, keyEvent] of Object.entries(this.keyByApp)) {
            this.keyListenerService
                .listenForKeyDown(keyEvent, [MMSpecialKeyEnum.ALT])
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    // can't change application if branding is SBK
                    if (this.query.getValue().branding === Branding.SBK) {
                        return;
                    }

                    this.store.update({ application: app as Application });
                });
        }
    }

    private updateApplicationOnQueryParamChange(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe(params => {
            if (this.query.getValue().branding === Branding.SBK) {
                return undefined;
            }

            const application = this.getApplicationFromRouteParams(params);
            if (application) {
                this.store.update({ application });
            }
        });
    }

    private updateBrandingOnKeyPress(): void {
        this.keyListenerService
            .listenForKeyDown(MMKeyEventEnum.B, [MMSpecialKeyEnum.ALT])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const { branding, application } = this.query.getValue();

                // can only change branding for somnio
                if (application !== Application.SOMNIO) {
                    return;
                }

                this.store.update({ branding: branding === Branding.SBK ? Branding.MEMENTOR : Branding.SBK });
            });
    }

    // HELPERS

    private getApplicationFromRouteParams(params: ParamMap | URLSearchParams): Application | undefined {
        const app = params.get('application');
        if (app && Object.values(Application).includes(app as Application)) {
            return app as Application;
        }

        return undefined;
    }

    private isCalledFromAdminPanel(): boolean {
        return this.router.url.includes('/admin/');
    }

    private getRegionFromRouteParams(params: ParamMap | URLSearchParams): Region | undefined {
        const region = params.get('region');
        if (region && Object.values(Region).includes(region as Region)) {
            return region as Region;
        }

        return undefined;
    }
}
