<mm-dialog-header [title]="data?.title || strings.defaultTitle()"></mm-dialog-header>

<mat-dialog-content>
    <p id="message">{{ data?.message || strings.defaultMessage() }}</p>
</mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" fxFlexFill>
        <div fxFlex>
            <button mat-button (click)="onConfirm()" type="button" color="warn">
                <span>{{ strings.confirm() }}</span>
            </button>
        </div>

        <div fxFlex="grow"></div>

        <div fxFlex>
            <button mat-button type="button" mat-dialog-close cdkFocusInitial>
                <span>{{ strings.decline() }}</span>
            </button>
        </div>
    </div>
</mat-dialog-actions>
