import { Application } from '../../../shared/modules/coaching/enums/application.enum';

export enum CoachingType {
    ACTENSIO = 'HYPERTENS',
    GLYKIO = 'DIABETES',
    SLEEP = 'SLEEP',
    SLEEP_DIARY = 'SLEEPDIARY',
    SLEEP_JUNIOR = 'SLEEPJUNIOR',
    SLEEP_LIGHT = 'SLEEPLIGHT',
}

export const toApplication: Record<CoachingType, Application> = {
    [CoachingType.ACTENSIO]: Application.ACTENSIO,
    [CoachingType.GLYKIO]: Application.GLYKIO,
    [CoachingType.SLEEP]: Application.SOMNIO,
    [CoachingType.SLEEP_DIARY]: Application.MALIO,
    [CoachingType.SLEEP_JUNIOR]: Application.SOMNIO_JUNIOR,
    [CoachingType.SLEEP_LIGHT]: Application.SOMNIO,
};

export const nameByCoaching: Record<CoachingType, string> = {
    [CoachingType.ACTENSIO]: 'actensio',
    [CoachingType.GLYKIO]: 'glykio',
    [CoachingType.SLEEP]: 'somnio',
    [CoachingType.SLEEP_DIARY]: 'malio',
    [CoachingType.SLEEP_JUNIOR]: 'somnio junior',
    [CoachingType.SLEEP_LIGHT]: 'somnio light',
};

export const baseDomainByApplication: Record<Application, string> = {
    [Application.ACTENSIO]: 'actens',
    [Application.GLYKIO]: 'glyk',
    [Application.SOMNIO]: 'somn',
    [Application.MALIO]: 'somn',
    [Application.SOMNIO_JUNIOR]: 'junior-somn',
};

export const byApplication: Record<Application, CoachingType[]> = {
    [Application.ACTENSIO]: [CoachingType.ACTENSIO],
    [Application.GLYKIO]: [CoachingType.GLYKIO],
    [Application.MALIO]: [CoachingType.SLEEP_DIARY],
    [Application.SOMNIO]: [CoachingType.SLEEP, CoachingType.SLEEP_LIGHT],
    [Application.SOMNIO_JUNIOR]: [CoachingType.SLEEP_JUNIOR],
};

export const COACHING_UPGRADE_CASES = [
    {
        from: [CoachingType.SLEEP_LIGHT, CoachingType.SLEEP_DIARY],
        to: CoachingType.SLEEP,
    },
];

export const applicationHasWaitingRoom: Record<Application, boolean> = {
    [Application.ACTENSIO]: true,
    [Application.GLYKIO]: false,
    [Application.SOMNIO_JUNIOR]: false,
    [Application.MALIO]: false,
    [Application.SOMNIO]: true,
};

export const defaultCoachingTypeByApp: Record<Application, CoachingType> = {
    [Application.ACTENSIO]: CoachingType.ACTENSIO,
    [Application.GLYKIO]: CoachingType.GLYKIO,
    [Application.MALIO]: CoachingType.SLEEP_DIARY,
    [Application.SOMNIO]: CoachingType.SLEEP,
    [Application.SOMNIO_JUNIOR]: CoachingType.SLEEP_JUNIOR,
};
