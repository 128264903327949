import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IStep } from './step.model';

export interface StepState extends EntityState<IStep> {}

@Injectable({
    providedIn: 'root',
})
/**
 * step store
 */
@StoreConfig({ name: 'steps', idKey: '_id' })
export class StepStore extends EntityStore<StepState, IStep> {
    /**
     * constructor
     */
    constructor() {
        super();
    }
}
