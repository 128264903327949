import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';

@Injectable({
    providedIn: 'root',
})

/**
 * time change service
 */
export class TimeChangePromptService {
    constructor(private dialog: MatDialog, private coachingQuery: CoachingQuery) {}

    public openTimeChangePrompt(component: any): void {
        const isInThreeDaysStdTime = moment(new Date()).isDST() && !moment().clone().add(3, 'days').isDST();
        const isInThreeDaysDST = !moment(new Date()).isDST() && moment().clone().add(3, 'days').isDST();

        if ((isInThreeDaysStdTime || isInThreeDaysDST) && !this.hasAlreadySeen()) {
            setTimeout(() => {
                this.dialog.open(component, {
                    autoFocus: false,
                    maxWidth: '500px',
                    disableClose: true,
                });
            }, 1000);
        }
    }

    private hasAlreadySeen(): boolean {
        const coaching: ICoaching = this.coachingQuery.getActive() as ICoaching;
        const hasSeenWarning = coaching?.settings?.find(
            setting => setting.property === this.getTimeChangeKey() && setting.value === 'true',
        );
        return !!hasSeenWarning;
    }

    public closePrompt(): void {
        this.dialog.closeAll();
    }

    public getTimeChangeKey(): string {
        const year = moment().format('YYYY');
        const month = moment().format('MM');
        return `hasSeenStandardTimeChangeInfo${year}${month}`;
    }
}
