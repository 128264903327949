import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ILicense } from './license.model';

export interface State extends EntityState<ILicense> {}

@Injectable({
    providedIn: 'root',
})
/**
 * coaching store
 */
@StoreConfig({ name: 'licenses', idKey: '_id' })
export class LicenseStore extends EntityStore<State, ILicense> {
    /**
     * constructor
     */
    constructor() {
        super();
    }
}
