import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { State } from './language.store';
import { Language } from './language.model';
import { LanguageStore } from './language.store';

@Injectable({
    providedIn: 'root',
})

/**
 *  @deprecated query should not be used anymore
 * module query
 */
export class LanguageQuery extends QueryEntity<State, Language> {
    /**
     * constructor
     */
    constructor(protected store: LanguageStore) {
        super(store);
    }
}
