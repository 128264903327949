<mm-dialog-header [title]="data.title"></mm-dialog-header>

<mat-dialog-content [innerHTML]="data.html"></mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" fxFlexFill>
        <div fxFlex></div>

        <div fxFlex="grow"></div>

        <div fxFlex>
            <button mat-button type="button" mat-dialog-close cdkFocusInitial>
                <span>{{ commonStrings.general.close() }}</span>
            </button>
        </div>
    </div>
</mat-dialog-actions>
