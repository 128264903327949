export enum ErrorCode {
    OPERATION_NOT_ALLOWED = 1001,

    WRONG_CREDENTIALS = 9001,
    USER_NOT_FOUND = 9002,
    USER_NO_CONSENT = 9005,
    EMAIL_ALREADY_IN_USE = 9006,
    USER_NOT_ACTIVATED = 9007,
    PASSWORD_MISMATCH = 9008,
    INVALID_ACTIVATION_TOKEN = 9009,
    CANNOT_UPDATE_USER = 9010,
    ACTIVATION_ERROR = 9011,
    INVALID_RESET_TOKEN = 9013,
    MISSING_TEXT_TO_TRANSLATE = 9014,
    SECOND_FACTOR_REQUIRED = 9015,
    SECOND_FACTOR_WRONG = 9016,
    MODULE_NOT_FOUND = 9017,
    MODULE_ALREADY_PUBLISHED = 9018,
    MODULE_ALREADY_LOCKED = 9019,
    STEP_NOT_FOUND = 9020,
    INVALID_GOAL = 9021,
    VOUCHER_NOT_FOUND = 9022,
    ALL_VOUCHERS_USED = 9023,
    VOUCHER_NOT_VALID_FOR_COACHING = 9024,
    VOUCHER_INVALID_DISCOUNT = 9025,
    COULD_NOT_INITIALISE_LICENSE = 9026,
    LICENSE_OF_TYPE_ALREADY_EXISTS = 9027,
    PROVIDER_IN_USE = 9028,
    COACHING_WITH_KEY_EXISTS = 9029,
    ANIMATION_NOT_FOUND = 9030,
    CANNOT_REMOVE_DIRECTORY = 9032,
    CANNOT_SAVE_ANIMATION = 9033,
    MISSING_PARAMETERS = 9034,
    CANNOT_HANDLE_CALLBACK = 9035,
    INVALID_VERIFICATION = 9037,
    MODULE_WITH_KEY_EXISTS = 9038,
    JOURNAL_ENTRY_OVERLAP = 9039,
    CANNOT_ADD_CODE_MULTIPLE_TIMES = 9040,
    MODULE_USED_IN_COACHING = 9041,
    ACCESS_REQUEST_REJECTED = 9042,
    USER_NOT_CONNECTED = 9043,
    CANNOT_SAVE_SYSTEM_NOTIFICATION = 9044,
    CANNOT_FIND_ENTITY = 9045,
    INVALID_AUTH_PROVIDER = 9046,
    INVALID_LICENSE_CODE = 9047,
    NOT_A_LICENSE_CODE = 9048,
    VOUCHER_DEPLETED = 9049,
    VOUCHER_IN_USE = 9051,
    VOUCHER_LICENSE_TYPE_NOT_RECOGNIZED = 9052,
    NOT_AUTHORIZED_TO_ACCESS_FILE = 9053,
    NO_LICENSE_CODE_PROVIDED = 9054,
    INVALID_DIGA_CODE = 9055,
    DIGA_CODE_USED = 9056,
    VOUCHER_NOT_VALID_YET = 9057,
    VOUCHER_EXPIRED = 9058,
    INVALID_OBJECT_ID = 9059,
    DIGA_CODE_COULD_NOT_BE_VERIFIED = 9060,
    DIGA_CODE_XRECHNUNG_COULD_NOT_BE_SENT = 9061,
    DIGA_ENDPOINT_COMMUNICATION_ERROR = 9062,
    X_RECHNUNG_ALREADY_SENT_OR_PAID = 9063,
    X_RECHNUNG_ALREADY_IN_SEVDESK = 9064,
    CERTIFICATE_DOWNLOAD_EXPIRED = 9065,
    NOT_ENOUGH_DATA_MUST_REDO_INTRODUCTION_MODULE = 9066,
    INVALID_PAYMENT_PROVIDER = 9067,
    NO_TRANSACTION_ID_FOUND = 9068,
    COACHING_NOT_FOUND = 9069,
    WRONG_APP_CONTEXT_LOGIN = 9070,
    WRONG_APP_CONTEXT_REGISTRATION = 9071,
    QUESTIONNAIRE_NOT_FOUND = 9072,
    QUESTIONNAIRE_IN_USE = 9073,
    MEDICATION_NOT_FOUND = 9080,
    SLEEP_WINDOW_DURATION_NULL = 9081,
    CONNECTION_LOST_RETRY = 9082,
    COULD_NOT_LOAD_VISUAL_EVALUATION = 9083,
    INVALID_REFRESH_TOKEN = 9084,
    IP_ADDRESS_NOT_ALLOWED = 9085,
    RESET_MAIL_TOKEN_EXPIRED = 9086,
    MODULE_VERSION_ALREADY_EXISTS = 9087,
    COACHING_VERSION_ALREADY_EXISTS = 9088,
    UPGRADE_ERROR = 9089,
    DOWNGRADE_ERROR = 9090,
    PASSWORDLESS_SETUP_PHONE_FAILURE = 9091,
    PASSWORDLESS_SETUP_PHONE_ALREADY_REGISTERED = 9092,
    NO_HEADLESS_BROWSER_OR_TEMPLATE = 9093,
    LICENSE_NOT_FOUND = 9094,
    INVALID_AUTHORIZATION_OBJECT = 9095,
    MISSING_CONSENT = 9096,
    NOT_A_LEGAL_UPGRADE = 9097,
    COACHING_IS_IN_USE = 9098,

    // sbk atm
    SBK_ATM_UNKNOWN_ERROR = 9100,
    SBK_ATM_ACTIVATION_KEY_UNKNOWN = 9101,
    SBK_ATM_ACTIVATION_KEY_INVALID = 9102,
    SBK_ATM_ACTIVATION_KEY_TOO_OLD = 9103,
    SBK_ATM_ACTIVATION_KEY_NEW_PASSWORD = 9104,
    SBK_ATM_LOGIN_INVALID_CREDENTIALS = 9105,
    SBK_ATM_LOGIN_NOT_REGISTERED = 9106,
    SBK_ATM_LOGIN_CAMPAIGN_NOT_STARTED = 9107,
    SBK_ATM_LOGIN_CAMPAIGN_DEACTIVATED = 9108,
    SBK_ATM_LOGIN_DATA_CONSENT_REMOVED = 9109,
    SBK_ATM_LOGIN_ACCOUNT_NOT_ACTIVATED = 9110,
    SBK_ATM_LOGIN_NO_DATA_CONSENT_GIVEN = 9111,
    SBK_ATM_LOGIN_ALREADY_REGISTERED_OTHER_CAMPAIGN = 9112,
    SBK_ATM_LOGIN_REGISTRATION_CLOSED = 9113,
    SBK_ATM_REQUEST_NEW_PASSWORD_UNKNOWN_EMAIL = 9114,
    SBK_ATM_REQUEST_NEW_PASSWORD_WRONG_CAMPAIGN_ID = 9115,
    SBK_ATM_REQUEST_NEW_PASSWORD_DELAY = 9116,
    SBK_ATM_ACTIVATION_KEY_REQUEST_DELAY_NOT_RESPECTED = 9117,
    SBK_ATM_INVALID_REG_DATA = 9118,
    SBK_ATM_ACTIVATION_ALREADY_ACTIVATED = 9119,

    // thryve
    THRYVE_ALREADY_CONNECTED = 9200,
    THRYVE_NOT_CONNECTED = 9201,
    THRYVE_WAIT_BEFORE_RECONNECT = 9202,

    EMAIL_COULD_NOT_BE_SENT = 9203,
    WRONG_LANGUAGE = 9204,

    EXPRESSION_INVALID = 9205,
    ANSWER_CONTAINER_NOT_FOUND = 9206,

    // journal entry
    INVALID_SLEEP_DURATION = 9209,

    DYNAMIC_FLOW_NO_NEXT_MODULE_FOUND = 9207,
    DYNAMIC_FLOW_NO_MODULE_STEP_FOUND = 9208,
    DYNAMIC_FLOW_NOT_EMPTY_MODULES_ARRAY = 9210,
    DYNAMIC_FLOW_NO_MODULES_FOUND = 9211,
    DYNAMIC_FLOW_MORE_THAN_ONE_STEP_FOUND = 9214,

    PRESCRIPTION_NOT_FOUND = 9212,
    INSURANCE_NOT_FOUND = 9213,

    // protocols
    ENTRY_ALREADY_EXISTS_AT_DATE = 9300,

    // general Purposes
    INVALID_DATA = 9400,
    BLACKLISTED_CHARACTER = 9401,
}
