import { Provider } from '@angular/core';
import { LoggingTool } from './contract';
import { ConsoleLoggingTool } from './console';
import { SentryLoggingTool } from './sentry';
import { environment } from '../../environments/environment';

export const LoggingToolProvider: Provider = {
    provide: LoggingTool,
    // TODO 1205119246380061 comment in this line, once sentry server is production ready
    // useClass: environment.name === 'development' ? ConsoleLoggingTool : SentryLoggingTool,
    useClass: environment.name === 'integration' ? SentryLoggingTool : ConsoleLoggingTool,
};
