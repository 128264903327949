import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
/**
 * used to centralize custom icons, for usage see icons.ts
 */
export class IconsService {
    /**
     * base path to icons
     * @private
     */
    private readonly BASE_PATH = '../../../assets/icons/';

    /**
     * icon suffix (has to be svg in this case to work at all)
     * @private
     */
    private readonly SUFFIX = '.svg';

    /**
     * standard icon set
     * @private
     */
    private readonly FILE_STANDARD_ICONS = 'sprites.svg';

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    /**
     * add icon to registry for usage consult icons.ts
     * @param icon
     * @param subDir subdirectory path of the form: 'dirname/'
     */
    public registerIcon(icon: string, subDir?: string): void {
        subDir = subDir ?? '';
        const path = this.BASE_PATH.concat(subDir).concat(icon).concat(this.SUFFIX);
        this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    }

    /**
     * register multiple icons at once #efficiency dry
     * @param icons
     *  * @param subDir subdirectory path of the form: 'dirname/'
     */
    public registerIcons(icons: string[], subDir?: string): void {
        for (const icon of icons) {
            this.registerIcon(icon, subDir);
        }
    }

    /**
     * registers icon set from svg file
     * (can be also a relative path from src/assets/icons/)
     * @param fileName
     */
    public registerIconSet(fileName: string): void {
        this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(this.BASE_PATH + fileName));
    }

    /**
     * registers the standard icon set
     */
    public registerStandardIconSet(): void {
        this.registerIconSet(this.FILE_STANDARD_ICONS);
    }
}
