declare let unescape;

/**
 * cookie store
 */
export class CookieStore {
    /**
     * retrieves a single cookie by it's name
     * @param  {string} name as the cookie identifier
     * @returns The Cookie's value
     */
    public static getCookie(name: string): Object {
        // get the cookie
        const cookie: string = localStorage.getItem(name);

        // parse the cookie if available
        return cookie ? JSON.parse(cookie) : null;
    }

    /**
     * sets a cookie
     * @param  {string} name
     * @param  {string} value
     */
    public static setCookie(name: string, value: Object) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    /**
     * deletes a cookie by name
     * @param  {string} name Cookie's identification
     */
    public static deleteCookie(name: string) {
        localStorage.removeItem(name);
    }
}
