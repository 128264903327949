import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PushNotificationActions } from '../../../../../../../backend/src/modules/notifications/enums/push-notification.actions';
import { SocketService } from '../../../../shared/socket/socket.service';
import { Application } from '../../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { SetPushNotificationBody } from '../../../../../../../backend/src/modules/notifications/models/SetPushNotificationBody.model';
import { NotificationChannel } from '../../../../../../../backend/src/modules/coaching/modules/notification-channel/notification-channel.model';
import { LoggingTool } from '../../../../../tools/logging/contract';

@Injectable()
export class PushNotificationService {
    constructor(private socketService: SocketService, private loggingTool: LoggingTool) {}

    /**
     * set the notification properties
     */
    public setPushNotification(
        notificationChannels: NotificationChannel[],
        application: Application,
    ): Observable<void> {
        const body = new SetPushNotificationBody();

        body.notificationChannels = notificationChannels;
        body.application = application;

        return new Observable(observer => {
            this.socketService.fire(PushNotificationActions.SET_PUSH_NOTIFICATION, body).subscribe(
                () => {
                    observer.next();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * get the notification property
     * TRUE = enabled, FALSE = disabled, NULL = not available
     */
    public getPushNotification(application: Application): Observable<NotificationChannel[]> {
        return new Observable(observer => {
            this.socketService.fire(PushNotificationActions.GET_PUSH_NOTIFICATION, application).subscribe(
                (result: NotificationChannel[]) => {
                    observer.next(result);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * trigger a test notification
     */
    public triggerTestPushNotification(application: Application): Observable<boolean> {
        return new Observable(observer => {
            this.socketService.fire(PushNotificationActions.TRIGGER_TEST_NOTIFICATION, application).subscribe(
                () => {
                    observer.next();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }
}
