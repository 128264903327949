import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationService } from '../translation/translation.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
    constructor(private transService: TranslationService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const headers = req.headers.append('accept-language', this.transService.activeLanguageCode);
        return next.handle(req.clone({ headers }));
    }
}
