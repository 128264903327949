import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'mm-dialog-actions',
    templateUrl: './dialog-actions.component.html',
    styleUrls: ['./dialog-actions.component.sass'],
})
export class DialogActionsComponent implements OnInit {
    @Input() entityForm: any;
    @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() loading: boolean;
    @Input() disableDelete = false;
    @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>();

    get id(): AbstractControl {
        return this.entityForm.get('_id');
    }

    constructor() {}

    public ngOnInit(): void {}

    public onDeleteEntity(): void {
        this.delete.emit(true);
    }
}
