<mm-header *ngIf="!isInStep"></mm-header>

<div class="nutrition-protocol-wrapper">
    <div class="header" *ngIf="!isInStep">
        <h2>{{ 'ht_nutrition_protocol' | localise }}</h2>
        <div style="display: flex; align-items: center">
            <div>{{ 'ht_average_of' | localise }}</div>
            <div class="input-wrapper">
                <input
                    type="number"
                    matInput
                    [defaultValue]="1"
                    min="1"
                    max="56"
                    (input)="onWeeksBackChange(getValue($event))"
                    autocomplete="off"
                />
            </div>
            <div>{{ 'general_week_weeks' | localise }}</div>
        </div>
    </div>

    <mm-nutrition-protocol-scores-overview
        [nutritionEvaluation]="nutritionEvaluation$ | async"
        [isInStep]="isInStep"
    ></mm-nutrition-protocol-scores-overview>

    <button *ngIf="isInStep" mat-raised-button (click)="proceedToNextStep()" class="continue-button" color="primary">
        <!-- update -->
        <ng-container>
            <span>{{ 'general_continue' | localise }}</span>
        </ng-container>
    </button>
</div>
