<mat-card appearance="outlined" class="nutrition-score-card">
    <mat-card-content style="display: flex">
        <div style="display: flex">
            <mat-icon svgIcon="{{ icon }}" color="primary">visibility</mat-icon>
            <div class="label">{{ key | localise }}</div>
        </div>
        <div style="align-self: center">
            <div
                class="thumb-label"
                [ngStyle]="{
                    transform: 'translateY(-5%) translateY(-1px) ' + getTranslateX(averageValue) + ' rotate(45deg)'
                }"
            >
                <div class="thumb-value">{{ averageValue }}</div>
            </div>
            <div class="dot-wrapper">
                <ng-container *ngIf="startsGreen">
                    <ng-container *ngFor="let dot of greenDots">
                        <div class="dot" style="background-color: #9fc369"></div>
                    </ng-container>
                    <ng-container *ngFor="let dot of redDots">
                        <div class="dot"></div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!startsGreen">
                    <ng-container *ngFor="let dot of redDots">
                        <div class="dot"></div>
                    </ng-container>
                    <ng-container *ngFor="let dot of greenDots">
                        <div class="dot" style="background-color: #9fc369"></div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="numbering">
                <div style="margin-left: 8px">0</div>
                <div>10</div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
