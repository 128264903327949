<div class="wrapper">
    <div class="header">
        <div></div>
        <div>
            <h3 class="title primary" [innerHTML]="title"></h3>
            <!-- you may pass a custom header here -->
            <ng-content select="[slot=header]"></ng-content>
        </div>
        <button class="close-button" mat-dialog-close mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!-- rest goes here -->
    <div class="body-wrapper">
        <ng-content></ng-content>
    </div>
    <ng-content select="[slot=actions]"></ng-content>
</div>
