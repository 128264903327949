import { IsMongoId, IsString } from 'class-validator';

export class GetActivationLinkBody {
    @IsString()
    @IsMongoId()
    userId: string | any;
    @IsString()
    @IsMongoId()
    coachingId: string | any;
}
