import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ModuleState } from './module.store';
import { ModuleStore } from './module.store';
import { IModule } from './module.model';

@Injectable({
    providedIn: 'root',
})

/**
 * module query
 */
export class ModuleQuery extends QueryEntity<ModuleState, IModule> {
    /**
     * constructor
     * @param store
     */
    constructor(protected store: ModuleStore) {
        super(store);
    }
}
