import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { SocketService } from '../socket/socket.service';
import { StepProgressState } from '../../modules/coaching/module-stepper/state/step-progress-state.enum';
import { UserQuery } from '../state/user/user.query';
import { IUser } from '../state/user/user.model';
import { CoachStore } from '../../modules/coaching/module-stepper/state/coach/coach.store';
import { CoachQuery } from '../../modules/coaching/module-stepper/state/coach/coach.query';
import { SpeechActions } from '../../../../../backend/src/modules/coaching/actions/speech.actions';
import { LanguageCode } from '../../../../../backend/src/shared/modules/language/enums/language.enum';
import { CoachName } from '../../../../../backend/src/shared/entities/coach-name.enum';
import { specialWords } from '../../modules/coaching/module-stepper/state/subtitle/special-words';
import { SpeechBody } from '../../../../../backend/src/modules/coaching/interfaces/speech-body.type';
import { SpeechConfig } from './speech-config';
import { ISpeechApiResponse } from '../../../../../backend/src/modules/coaching/interfaces/speech-api-response.interface';
import { LoggingTool } from '../../../tools/logging/contract';
import { ParsedTextAndCacheableInformation } from './parsed-text-and-cacheable-information.type';

declare const window;

@Injectable()
export class SpeechService {
    public playCanvasAppRef: any = null;

    public jaw: any;
    public mouthUpper: any;

    public file = null;
    public source: AudioBufferSourceNode = null; // the audio source
    public animationId = null;
    public values = [];
    public audioContext: AudioContext = null;
    public observer: Observer<StepProgressState>;

    public skipAudioEnd: boolean;

    /**
     * keeps track of playback state, set to true when starting and false when ended
     * must do this, because audio buffer source node does not expose a playback progress
     */
    public playbackEnded = true;

    /**
     * time points for markers in SSML
     */
    public timePoints: any[] = [];
    private replay = false;
    public buffer: Blob;

    /**
     * audio buffer for holding the next steps audio when cached
     * @private
     */
    private cachedBuffer: Blob;

    /**
     * audio buffer to hold the last played step for replay mode
     * @private
     */
    private lastPlayedBuffer: Blob;

    constructor(
        private http: HttpClient,
        private coachStore: CoachStore,
        private coachQuery: CoachQuery,
        private userQuery: UserQuery,
        private socketService: SocketService,
        @Inject(LoggingTool) private loggingTool: LoggingTool,
    ) {
        // react to changes in paused state
        this.coachQuery.select('paused').subscribe((paused: boolean) => {
            if (this.audioContext) {
                if (paused) {
                    this.audioContext.suspend().then();
                } else {
                    this.audioContext.resume().then();
                }
            }
        });
    }

    private static getVoiceNameForUser(user: IUser): string {
        switch (user.language) {
            case LanguageCode.DE_CH:
                switch (user.coach) {
                    case CoachName.ALBERT:
                        return 'de-DE-Wavenet-B';
                    case CoachName.OLIVIA:
                        return 'de-DE-Wavenet-C';
                }
                break;

            case LanguageCode.FR_CH:
                switch (user.coach) {
                    case CoachName.ALBERT:
                        return 'fr-FR-Wavenet-B';
                    case CoachName.OLIVIA:
                        return 'fr-FR-Wavenet-E';
                }
                break;

            case LanguageCode.EN_US:
                switch (user.coach) {
                    case CoachName.ALBERT:
                        return 'en-US-Wavenet-D';
                    case CoachName.OLIVIA:
                        return 'en-US-Wavenet-H';
                }
                break;

            case LanguageCode.EN_AU:
                switch (user.coach) {
                    case CoachName.ALBERT:
                        return 'en-AU-Neural2-B';
                    case CoachName.OLIVIA:
                        return 'en-US-Wavenet-H';
                }
                break;
        }
    }

    public createAudioContext(): void {
        // fix browser vendor for AudioContext and requestAnimationFrame
        window.AudioContext =
            window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;

        window.requestAnimationFrame =
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame;

        window.cancelAnimationFrame =
            window.cancelAnimationFrame ||
            window.webkitCancelAnimationFrame ||
            window.mozCancelAnimationFrame ||
            window.msCancelAnimationFrame;

        // if no audio context exists
        if (!this.audioContext) {
            // try to create one
            try {
                this.audioContext = new AudioContext();
                const gainNode = this.audioContext.createGain();
                gainNode.gain.value = 1; //
            } catch (err) {
                this.loggingTool.debug('Your browser does not support AudioContext or context already created');
                this.observer.error(err);
            }
        }
    }

    public speak(speechConfig: SpeechConfig): Observable<StepProgressState> {
        return new Observable(observer => {
            this.observer = observer;

            const activeUser = this.userQuery.getActive() as IUser;
            const audioConfig = this.getAudioConfigForUser(activeUser);

            // appropriate text pronunciations before compiling
            const textToSpeak = this.replaceSpecialWords(speechConfig.textToSpeak);

            // create request body // E -3.6 // B -4.4
            const body: SpeechBody = {
                audioConfig: {
                    audioEncoding: 'LINEAR16',
                    pitch: audioConfig.pitch.toString(),
                    speakingRate: audioConfig.speakingRate.toString(),
                },
                input: {
                    ssml: `<speak>${textToSpeak}</speak>`,
                },
                voice: audioConfig.voice,
                enableTimePointing: ['SSML_MARK'], // needs to be set to work with <mark>
                isSpeechApiCacheable: speechConfig.isSpeechApiCacheable,
            };

            // inform about step progress
            if (!speechConfig.cached) {
                this.coachStore.update({ stepProgressState: StepProgressState.LOADING });
            }
            // ask our server to speak text
            const subscription = this.socketService
                .fire(SpeechActions.SPEAK, body)
                .subscribe((response: ISpeechApiResponse) => {
                    if (this.cachedBuffer && !this.replay) {
                        this.buffer = this.cachedBuffer;
                        observer.next(StepProgressState.LOADING);
                        observer.complete();
                        return;
                    }

                    const contentType = 'audio/mpeg';
                    // retrieve time points.
                    this.timePoints = response.timepoints ?? [];

                    const b64Data = response.audioContent;

                    // convert base 64 encoded audio to blob
                    const blob = this.b64toBlob(b64Data, contentType);

                    // create a file from blob
                    if (!speechConfig.cached) {
                        // prevent racing condition between cache and loading of previous step
                        if (this.replay) {
                            subscription.unsubscribe();
                        }

                        this.buffer = new Blob([blob], { type: 'audio/mpeg' });
                    } else if (!this.replay) {
                        this.cachedBuffer = new Blob([blob], { type: 'audio/mpeg' });
                    }
                    observer.next(StepProgressState.LOADING);
                    observer.complete();
                });
        });
    }

    public startSpeaking(speakFromCache = false): void {
        // read the file as buffer
        if (speakFromCache && !this.replay) {
            this.lastPlayedBuffer = this.cachedBuffer;
            this.readFileAsBuffer(this.cachedBuffer);
            this.cachedBuffer = null;
        } else {
            this.lastPlayedBuffer = this.buffer;
            this.readFileAsBuffer(this.buffer);
            this.replay = false;
        }
    }

    public stopSpeaking(): void {
        this.loggingTool.debug(`Stop speaking, playback ended? ${this.playbackEnded ? 'yes' : 'no'}`);
        this.skipAudioEnd = false;

        // if audio is playing,s top and let onended to it's thing
        if (this.source && !this.playbackEnded) {
            this.source.stop(0);
        }
    }

    public disconnect(): void {
        if (this.source) {
            this.source.disconnect();
        }
        if (this.audioContext) {
            this.audioContext.close().then(() => {
                this.audioContext = null;
            });
        }
    }

    public readFileAsBuffer(file): void {
        // read and decode the file into audio array buffer
        const fr: FileReader = new FileReader();

        // file reader loaded event
        fr.onload = (e: any) => {
            const fileResult = e.target.result;

            if (this.audioContext === null) {
                return;
            }

            // decode the audio
            this.audioContext.decodeAudioData(
                fileResult,
                buffer => {
                    // aks to visualise
                    this.playAudioBuffer(buffer);
                },
                err => {
                    this.observer.error(err);
                },
            );
        };

        // file reader error event
        fr.onerror = err => {
            this.observer.error(err);
        };

        // read the file as buffer
        fr.readAsArrayBuffer(file);
    }

    public playAudioBuffer(buffer): void {
        this.coachStore.update({ audioDuration: buffer.duration });
        this.audioContext.resume().then();

        const audioBufferSourceNode: AudioBufferSourceNode = this.audioContext.createBufferSource();
        const analyser = this.audioContext.createAnalyser();

        // connect the source to the analyser
        audioBufferSourceNode.connect(analyser);

        // connect the analyser to the destination(the speaker), or we won't hear the sound
        analyser.connect(this.audioContext.destination);

        // then assign the buffer to the buffer source node
        audioBufferSourceNode.buffer = buffer;

        // reassign for old browsers
        if (!audioBufferSourceNode.start) {
            audioBufferSourceNode.start = audioBufferSourceNode['noteOn']; // in old browsers use noteOn method
            audioBufferSourceNode.stop = audioBufferSourceNode['noteOff']; // in old browsers use noteOff method
        }

        // stop the previous sound if any
        if (this.animationId !== null) {
            cancelAnimationFrame(this.animationId);
        }

        // if source already exists and step is playing stop
        if (this.source) {
            // this is important because otherwise audio is overlaid on top!
            try {
                this.source.stop(0);
            } catch (e) {
                this.loggingTool.debug('Cannot stop audio source node');
            }
        }

        // assign the audio buffer source node
        this.source = audioBufferSourceNode;

        // listen to event when audio ends, always called when stop() is called, so we need to check for skip state where we ignore it
        audioBufferSourceNode.onended = () => {
            this.loggingTool.debug(`Audio ended, "skip audio end event" is ${this.skipAudioEnd ? 'true' : 'false'}`);

            if (this.skipAudioEnd) {
                this.skipAudioEnd = false;
            } else {
                this.playbackEnded = true;
                this.audioEnd();
            }
        };

        // start playing at beginning
        audioBufferSourceNode.start(0);
        this.playbackEnded = false;

        this.loggingTool.debug('Actually playing audio');

        // inform about step progress
        this.observer.next(StepProgressState.PLAYING);
        this.coachStore.update({ stepProgressState: StepProgressState.PLAYING, paused: false, progress: 0 });
        this.drawShape(analyser);
    }

    public drawShape(analyser): void {
        // on frame function
        const draw = () => {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);

            // if audio complete:
            // cancelAnimationFrame(that.animationId);

            const step = Math.round(array.length / 15); // sample limited data from the total array
            const value = array[step];

            this.values.push(value);

            if (this.values.length >= 5) {
                this.values.splice(0, 1);
            }

            const amplitude = this.values.reduce((memo, num) => memo + num, 0) / this.values.length || 1;

            if (this.mouthUpper) {
                // not defined in preview of step editor
                this.mouthUpper.setLocalPosition(0, -1.03875 + amplitude / 2000, 3.091);
            }
            if (this.jaw) {
                // not defined in preview of step editor
                this.jaw.setLocalPosition(0, -1.39639 - amplitude / 1000, 1.464);
            }

            const amplitudeScript = this.playCanvasAppRef.root.findByName('SpeechAmplitude').script.get('amplitude');
            amplitudeScript.amplitude = amplitude;

            this.animationId = requestAnimationFrame(draw);
        };

        draw();
    }

    public audioEnd(): void {
        this.coachStore.update({
            stepProgressState: StepProgressState.ENDED,
        });

        this.observer.next(StepProgressState.ENDED);
        this.observer.complete();
    }

    /**
     * modifies german words that are hard to pronounce by google's speech service
     * @param textBlock
     * @private
     */
    private replaceSpecialWords(textBlock: string): string {
        // replace if there is a special character by the object key
        for (const specialWord of specialWords) {
            if (textBlock.includes(Object.keys(specialWord)[0])) {
                textBlock = textBlock.replace(
                    new RegExp(Object.keys(specialWord)[0], 'g'),
                    Object.values(specialWord)[0],
                );
            }
        }
        return textBlock;
    }

    public parseTextValues(textToSpeak: string): Observable<ParsedTextAndCacheableInformation> {
        return new Observable(observer => {
            // get from backend
            this.socketService.fire(SpeechActions.PARSE, textToSpeak).subscribe(
                (response: ParsedTextAndCacheableInformation) => {
                    observer.next(response);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    public b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    public setReplay(replay: boolean): void {
        this.replay = replay;
    }

    public switchBufferWithCached(): void {
        this.buffer = this.lastPlayedBuffer;
    }

    public clearCacheBuffer(): void {
        this.cachedBuffer = null;
    }

    private getAudioConfigForUser(user: IUser): any {
        const defaultSpeakingRate = 5;
        const userSpeakingRate = user ? user.speakingRate : defaultSpeakingRate;

        let audioConfig = null;

        // check which voice to use
        switch (user.language) {
            case LanguageCode.DE_CH:
                audioConfig = {
                    voice: {
                        languageCode: 'de-DE',
                        name: SpeechService.getVoiceNameForUser(user),
                    },
                    speakingRate: (userSpeakingRate - 5) / 10 + 1.18,
                    pitch: -3.6,
                };
                break;

            case LanguageCode.FR_CH:
                audioConfig = {
                    voice: {
                        languageCode: 'fr-FR',
                        name: SpeechService.getVoiceNameForUser(user),
                    },
                    speakingRate: (userSpeakingRate - 5) / 10 + 1.08,
                    pitch: -3.6,
                };
                break;

            case LanguageCode.EN_US:
                audioConfig = {
                    voice: {
                        languageCode: 'en-US',
                        name: SpeechService.getVoiceNameForUser(user),
                    },
                    speakingRate: (userSpeakingRate - 5) / 10 + 1.18,
                    pitch: 0,
                };
                break;

            case LanguageCode.EN_AU:
                audioConfig = {
                    voice: {
                        languageCode: 'en-AU',
                        name: SpeechService.getVoiceNameForUser(user),
                    },
                    speakingRate: (userSpeakingRate - 5) / 10 + 1.08,
                    pitch: 0,
                };
                break;
        }

        return audioConfig;
    }
}
