import { EventEmitter, Injectable } from '@angular/core';
import { SocketService } from '../../socket/socket.service';
import { createStep, IStep } from './step.model';
import { StepStore } from './step.store';
import { EMPTY, Observable } from 'rxjs';
import { applyTransaction, ID } from '@datorama/akita';
import { ModuleQuery } from '../module/module.query';
import { StepQuery } from './step.query';
import { NotificationType } from '../../notification/notification-type.enum';
import { NotificationService } from '../../notification/notification.service';
import { StepActions } from '../../../../../../backend/src/modules/coaching/actions/step.actions';
import { createAnswer, IAnswer } from '../answer/answer.model';
import { IChartEvaluation } from '../../../../../../backend/src/shared/modules/evaluation/interfaces/chart-evaluation.interface';
import { Questionnaire } from '../../../../../../backend/src/modules/coaching/models/questionnaire/questionnaire.model';
import { IQuestionnaire } from '../questionnaire/questionnaire.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AnimationValidityState } from '../../../modules/coaching/module-stepper/state/animation-validity-state.enum';
import { IModule } from '../module/module.model';
import { AuthQuery } from '../auth/auth.query';
import { StepSearchFilter } from '../../../../../../backend/src/modules/coaching/interfaces/step-search-filter';
import { LanguageQuery } from '../language/language.query';
import { Language } from '../language/language.model';
import { IBlotParameter } from '../../../modules/admin/step-manager/step-editor/blot-parameter-picker/models/blot-parameter.interface';
import { createTranslation } from '../translation/translation.model';
import { createCondition, ICondition } from '../condition/condition.model';
import { Point } from '@angular/cdk/drag-drop';
import { LanguageService } from '../../language/language.service';
import * as _ from 'lodash';
import { RoleName } from '../../../../../../backend/src/shared/modules/security/enums/security-roles.enum';
import { SecurityService } from '../../security/security.service';
import { PersonalNoteFieldsF } from '../personal-note-fields/personal-note-fields.entity';
import { CoachingQuery } from '../coaching/coaching.query';
import { ICoaching } from '../coaching/coaching.model';
import { LoggingTool } from '../../../../tools/logging/contract';
import { createDownloadLink } from '../../../utils/createDownloadLink';

@Injectable()
@UntilDestroy({ checkProperties: true })
export class StepService {
    private static readonly PARAMS_SEPARATOR = ' ';
    public onStepMove = new EventEmitter<IStep>();
    public addStepTriggered = new EventEmitter();
    public stepsIntoClipboardPasted = new EventEmitter<string>();

    constructor(
        private socketService: SocketService,
        private stepStore: StepStore,
        private stepQuery: StepQuery,
        private authQuery: AuthQuery,
        private notificationService: NotificationService,
        private moduleQuery: ModuleQuery,
        private coachingQuery: CoachingQuery,
        private languageQuery: LanguageQuery,
        private languageService: LanguageService,
        private securityService: SecurityService,
        private loggingTool: LoggingTool,
    ) {}

    /**
     * get all steps of a active module
     * @param activeStepId as the step to select (e.g. make active)
     */
    public getAllStepsOfActiveModule(activeStepId?: string): Observable<IStep[]> {
        return new Observable(observer => {
            const moduleId = this.moduleQuery.getActiveId();

            this.socketService.fire(StepActions.GET_ALL_FOR_MODULE, moduleId).subscribe(
                (steps: IStep[]) => {
                    applyTransaction(() => {
                        // get all
                        this.stepStore.set(steps);

                        // only select step if module is not locked
                        const userId = this.authQuery.getValue()._id;
                        const activeModule: IModule = this.moduleQuery.getActive() as IModule;
                        const locked: boolean = activeModule?.locked && activeModule.editor !== userId;

                        if (locked && activeStepId) {
                            this.notificationService.displayNotification(
                                NotificationType.WARNING,
                                'notification_cannot_edit_step',
                            );
                        }

                        if (!locked && !!activeStepId && this.stepQuery.getEntity(activeStepId)) {
                            this.stepStore.setActive(activeStepId);
                        }
                    });

                    observer.next(steps);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * get first step of a module
     */
    public getFirstStepOfModule(moduleId: ID): Observable<IStep> {
        return new Observable(observer => {
            this.socketService.fire(StepActions.GET_FIRST_OF_MODULE, moduleId).subscribe(
                (step: IStep) => {
                    this.addStepAndSetActive(step);

                    observer.next(step);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    public triggerAddStep(): void {
        this.addStepTriggered.emit();
    }

    /**
     * get a step by id
     */
    public getStepByIdAndSetState(
        stepId: string,
        translationVersionNumber: number = 0,
        setActive = true,
    ): Observable<IStep> {
        return new Observable(observer => {
            this.socketService.fire(StepActions.GET_BY_ID, stepId).subscribe(
                (step: IStep) => {
                    this.addStepAndSetActive(step, translationVersionNumber, setActive);

                    observer.next(step);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * get a step by id
     */
    public generatePdfFromSteps(stepIds: string[]): Observable<any> {
        const language = this.languageQuery.getActive() as Language;

        return new Observable(observer => {
            this.socketService.fire(StepActions.GENERATE_PDF, { stepIds, language: language.code }).subscribe(
                (doc: string) => {
                    const blob = new Blob([doc], {
                        type: 'application/pdf',
                    });

                    const fileName = `steps.pdf`;
                    createDownloadLink(blob, fileName);
                    observer.next(doc);
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    public createStep(step: IStep, displayNotification = true): Observable<IStep> {
        return new Observable(observer => {
            this.stepStore.setLoading(true);
            this.socketService.fire(StepActions.CREATE, step).subscribe({
                next: (savedStep: IStep) => {
                    this.stepStore.add(savedStep);

                    if (displayNotification) {
                        this.notificationService.displayNotification(NotificationType.INFO, 'notification_step_add', [
                            step.name,
                        ]);
                    }
                    observer.next(savedStep);
                },
                error: err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_step_save_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                complete: () => {
                    this.stepStore.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    public updateStep(step: IStep, displayNotification = true): Observable<IStep> {
        return new Observable(observer => {
            this.stepStore.setLoading(true);
            this.socketService.fire(StepActions.UPDATE, step).subscribe({
                next: (savedStep: IStep) => {
                    this.stepStore.update(savedStep._id, savedStep);

                    if (displayNotification) {
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_step_update',
                            [step.name],
                        );
                    }
                    observer.next(savedStep);
                },
                error: err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_step_save_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                complete: () => {
                    this.stepStore.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    /**
     * updates position of many steps
     */
    public updateManyPosition(steps: IStep[], displayNotification = true): Observable<IStep[]> {
        return new Observable(observer => {
            this.socketService.fire(StepActions.UPDATE_MANY_POSITION, steps).subscribe(
                (savedSteps: IStep[]) => {
                    // if steps have an id > update, else add
                    if (steps[0]?._id) {
                        applyTransaction(() => {
                            for (const savedStep of savedSteps) {
                                this.stepStore.update(savedStep._id, {
                                    fx: savedStep.fx,
                                    fy: savedStep.fy,
                                });
                            }
                        });

                        if (displayNotification) {
                            this.notificationService.displayNotification(
                                NotificationType.INFO,
                                'notification_steps_update',
                            );
                        }
                    } else {
                        applyTransaction(() => {
                            for (const savedStep of savedSteps) {
                                this.stepStore.add(savedStep);
                            }
                        });

                        if (displayNotification) {
                            this.notificationService.displayNotification(
                                NotificationType.INFO,
                                'notification_steps_add',
                            );
                        }
                    }

                    observer.next(savedSteps);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_step_save_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * removes a step
     */
    public removeStep(step: IStep): Observable<IStep> {
        if (!this.securityService.hasRoles([RoleName.ADMIN, RoleName.MANAGER, RoleName.CONTENT])) {
            return EMPTY;
        }

        return new Observable(observer => {
            this.socketService.fire(StepActions.REMOVE, step._id).subscribe(
                () => {
                    // remove the step
                    this.stepStore.remove(step._id);
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_step_remove', [
                        step.name,
                    ]);

                    observer.next(step);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * removes a step
     */
    public copySteps(steps: IStep[]): Observable<IStep[]> {
        return new Observable(observer => {
            this.socketService.fire(StepActions.COPY, steps).subscribe(
                (savedSteps: IStep[]) => {
                    this.stepStore.add(savedSteps);
                    observer.next(savedSteps);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * get the next coaching step by resolving all answer conditions
     */
    public getNextStepFromAnswer(step: IStep, answer: IAnswer): Observable<IStep> {
        return new Observable(observer => {
            this.socketService
                .fire(StepActions.GET_FROM_ANSWER, {
                    step: step,
                    answer: answer,
                })
                .subscribe(
                    (nextStep: IStep) => {
                        this.addStepAndSetActive(nextStep);

                        observer.next(nextStep);
                        observer.complete();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                );
        });
    }

    /**
     * load en evaluation for a step
     */
    public loadEvaluation(stepId: string): Observable<IChartEvaluation> {
        return new Observable(observer => {
            // nothing to do
            if (!stepId) {
                observer.complete();
            } else {
                this.socketService.fire(StepActions.LOAD_EVALUATION, stepId).subscribe(
                    (evaluation: IChartEvaluation) => {
                        observer.next(evaluation); // store in the state whether the data is valid
                        const validityState = !!evaluation?.invalidData
                            ? AnimationValidityState.INVALID
                            : AnimationValidityState.VALID;
                        this.stepStore.update(stepId, {
                            animationValidityState: validityState,
                        });
                        observer.complete();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                );
            }
        });
    }

    /**
     * load questionnaire for a step
     */
    public loadQuestionnaire(stepId: string): Observable<Questionnaire> {
        return new Observable(observer => {
            // nothing to do
            if (!stepId) {
                observer.complete();
            } else {
                this.socketService.fire(StepActions.LOAD_QUESTIONNAIRE, stepId).subscribe(
                    (questionnaire: IQuestionnaire) => {
                        observer.next(questionnaire);
                        observer.complete();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                );
            }
        });
    }

    /**
     * get all steps using a questionnaire
     * @param questionnaireName
     */
    public getAllStepsWithQuestionnaire(questionnaireName: string): Observable<IStep[]> {
        return new Observable(observer => {
            // nothing to do
            if (!questionnaireName) {
                observer.complete();
            } else {
                this.socketService.fire(StepActions.GET_ALL_WITH_QUESTIONNAIRE, questionnaireName).subscribe(
                    (steps: IStep[]) => {
                        observer.next(steps);
                        observer.complete();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                );
            }
        });
    }

    public loadNote(stepId: string): Observable<PersonalNoteFieldsF> {
        return new Observable(observer => {
            // nothing to do
            if (!stepId) {
                observer.complete();
            } else {
                this.socketService.fire(StepActions.LOAD_NOTE, stepId).subscribe(
                    (note: PersonalNoteFieldsF) => {
                        observer.next(note);
                        observer.complete();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                );
            }
        });
    }

    /**
     * set active step
     */
    public addStepAndSetActive(step: IStep, translationVersionNumber = 0, setActive = true): void {
        applyTransaction(() => {
            this.stepStore.upsert(step._id, step);
            if (setActive) {
                this.stepStore.setActive(step._id);
            }
            // use specific translationVersion
            if (translationVersionNumber) {
                this.stepStore.update(step._id.toString(), {
                    translationVersionNumber: translationVersionNumber,
                });
            }
        });
    }

    /**
     * validate an expression
     */
    public validateExpression(expression: string): Observable<string> {
        return new Observable(observer => {
            this.socketService.fire(StepActions.VALIDATE_EXPRESSION, expression).subscribe(
                (validationError: string) => {
                    observer.next(validationError);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    public removeSteps(stepIds: string[]): Observable<{ deletedCount: number }> {
        if (!this.securityService.hasRoles([RoleName.ADMIN, RoleName.MANAGER])) {
            return EMPTY;
        }
        return new Observable(observer => {
            this.socketService.fire(StepActions.REMOVE_MANY, stepIds).subscribe(
                (resp: { deletedCount: number }) => {
                    // remove the step
                    for (const stepId of stepIds) {
                        this.stepStore.remove(stepId);
                    }

                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_step_remove_count',
                        [resp.deletedCount.toString()],
                    );

                    observer.next(resp);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * get the admin url to the specified step
     * @param coachingId
     * @param moduleId
     * @param stepId
     */
    getUrlToStep(coachingId: string, moduleId: string, stepId: string): string {
        // declare the base url
        let url = '/admin/steps/';

        // assign active coaching and module id ot url
        url += `${coachingId || ''}/${moduleId || ''}`;
        // handle active step
        const activeStepId = stepId;
        if (!!activeStepId) {
            url += `/${activeStepId}`;
        }
        return url;
    }

    /**
     * get step url with step only
     * @param step
     */
    public getUrlToStepWithStepOnly(step: IStep): string {
        if (!step) {
            return;
        }

        const module = this.moduleQuery.getEntity(step.module);

        const coachingKey = module?.coachings?.length > 0 ? module.coachings[0] : null;

        if (!coachingKey) {
            return;
        }

        // get all coachings that match this key
        const refinedCoachings = this.coachingQuery.getAll({
            filterBy: entity => entity.key === coachingKey,
        });

        // select highest version
        const coaching: ICoaching = _.maxBy(refinedCoachings, 'version');

        const coachingId = coaching?._id ? coaching?._id?.toString() : coachingKey;

        return this.getUrlToStep(coachingId, step?.module, step?._id?.toString());
    }

    /**
     * get the module a questionnaire is used in
     * @param step
     */
    public getModuleNameOfStep(step: IStep): string {
        const module = this.moduleQuery.getEntity(step.module);
        const moduleName = module?.name['de_ch'] ?? 'unbekannt';
        const moduleVersion = module?.version ?? '?';
        return `${moduleName} v.${moduleVersion}`;
    }

    /**
     * moves to step in graph step editor
     * @param stepId
     */
    public moveToStep(stepId: string): void {
        const step = this.stepQuery.getAll().find(c => c._id.toString() === stepId);
        if (step) {
            this.onStepMove.emit(step);
        }
    }

    /**
     * seraches steps of current module for search term
     * @param searchTerm
     * @param filter
     */
    public findSteps(searchTerm: string, filter: StepSearchFilter): IStep[] {
        // split quotes => quotes will be preseverd, everything else will be split by spaces and trimmed
        const splittedByQuotes = searchTerm.toLowerCase().split('"');

        // invalid search term
        if (splittedByQuotes.length % 2 === 0) {
            return [];
        }

        const nonQuotes = splittedByQuotes
            .filter(c => splittedByQuotes.indexOf(c) % 2 === 0) // extract non-quotes
            .map(c => c.split(' ')) // split by space
            .reduce((a, b) => a.concat(b)) // flatten
            .filter(c => c); // remove empty strings

        // extract quotes and remove empty strings
        const quotes = splittedByQuotes.filter(c => splittedByQuotes.indexOf(c) % 2 === 1).filter(c => c);

        // combine again
        const searchTerms = [...quotes, ...nonQuotes];

        return this.stepQuery
            .getAll()
            .map(c => {
                let prio = 0;
                for (const term of searchTerms) {
                    const result = this.containsSearchTerm(c, term, filter);
                    if (result < 0) {
                        // -1 => not contained
                        prio = -1; // will be excluded
                        break;
                    }
                    prio += result; // add result to prio => more (relevant) hits => more likely at the top of the list
                }
                return {
                    prio,
                    step: c,
                };
            })
            .filter(c => c.prio >= 1) // exclude not found steps
            .sort((a, b) => b.prio - a.prio) // sort by prio
            .map(c => c.step); // return only the steps
    }

    /**
     * checks, if a search term is contained somewhere within a step
     * @param step
     * @param searchTerm
     * @param filter
     * @private
     */
    private containsSearchTerm(step: IStep, searchTerm: string, filter: StepSearchFilter): number {
        // only check for complete match, as no one will search parts of ids
        if (filter.id && step._id.toString().toLowerCase() === searchTerm) {
            return 100;
        }

        // title
        if (filter.name && this.included(step.name, searchTerm)) {
            return 10;
        }

        // filter text of step
        if (
            (filter.de && this.included(step.text.de_ch, searchTerm)) ||
            (filter.fr && this.included(step.text.fr_ch, searchTerm)) ||
            (filter.en && this.included(step.text.en_us, searchTerm))
        ) {
            return 1;
        }

        // not found => exclude from search
        return -1;
    }

    /**
     * literally checks, if the handed value includes the search term
     * (can be tweaked further this way)
     * @param value
     * @param searchTerm
     * @private
     */
    private included(value: string, searchTerm: string): boolean {
        return value.toLowerCase().indexOf(searchTerm) >= 0;
    }

    /**
     * searches for step by transmitting the call to the backend and letting the backend handle
     * the search
     * @param searchQuery
     * @param filter
     */
    public searchStep(searchQuery: string, filter: StepSearchFilter): Observable<any[]> {
        return new Observable(observer => {
            this.socketService.fire(StepActions.SEARCH_FOR_STEP, { searchQuery, filter }).subscribe(
                (steps: IStep[]) => {
                    observer.next(steps);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    public addNewStep(position: Point, oldStep?: IStep): void {
        // create the new step to be linked
        const newStep: IStep = createStep({ name: '<empty>' });
        newStep.hasNewTimingSystem = true;

        newStep.text = createTranslation({
            de_ch: '<p>Ipsum lorem</p>',
            fr_ch: '<p>Ipsum lorem</p>',
            it_ch: '<p>Ipsum lorem</p>',
            en_us: '<p>Ipsum lorem</p>',
            en_au: '<p>Ipsum lorem</p>',
        });

        const activeModule = this.moduleQuery.getActive() as IModule;
        newStep.module = activeModule._id.toString();

        newStep.fx = position.x;
        newStep.fy = position.y;

        const answerText = {
            de_ch: this.languageService.getText('general_continue', null, 'de_ch'),
            fr_ch: this.languageService.getText('general_continue', null, 'fr_ch'),
            it_ch: this.languageService.getText('general_continue', null, 'it_ch'),
            en_us: this.languageService.getText('general_continue', null, 'en_us'),
            en_au: this.languageService.getText('general_continue', null, 'en_au'),
        };

        const newStepCondition = createCondition({ step: null });
        const newStepAnswer = createAnswer({ text: answerText, conditions: [newStepCondition] });

        newStep.answers = [newStepAnswer];

        // create
        this.createStep(newStep).subscribe((savedStep: IStep) => {
            if (oldStep) {
                const updateStep = _.cloneDeep(oldStep);
                const condition: ICondition = createCondition({ step: savedStep._id });

                const answer: IAnswer = createAnswer({ text: answerText, conditions: [condition] });
                if (updateStep.answers) {
                    updateStep.answers.push(answer);
                } else {
                    updateStep.answers = [answer];
                }
                this.updateStep(updateStep).subscribe(() => {
                    this.stepStore.setActive(null);
                });
            } else {
                this.stepStore.setActive(null);
            }
        });
    }

    public blotParametersToString(blotKey: string, parameters: IBlotParameter): string {
        let paramProperty = blotKey;
        for (const parameter in parameters) {
            if (parameters.hasOwnProperty(parameter)) {
                paramProperty += StepService.PARAMS_SEPARATOR + parameters[parameter];
            }
        }
        return paramProperty;
    }

    public stringToBlotParameterValues(parametersString: string): string[] {
        if (!parametersString || parametersString.indexOf(StepService.PARAMS_SEPARATOR) < 0) {
            return [];
        }
        return parametersString.split(StepService.PARAMS_SEPARATOR).slice(1);
    }

    /**
     *  can be called with some text to transmit clipboard events from other components (such as steep-manager)
     *  to transmit them to e.g. the step-graph
     */
    public stepsPasted(clipboardText: string): void {
        this.stepsIntoClipboardPasted.emit(clipboardText);
    }

    public getWithPersonalNoteField(personalNoteFieldId: string): Observable<IStep[]> {
        return this.socketService.fire(StepActions.GET_WITH_PERSONAL_NOTE_FIELD, personalNoteFieldId);
    }

    public getWithAnswerContainer(answerContainerId: string): Observable<IStep[]> {
        return this.socketService.fire(StepActions.GET_WITH_ANSWER_CONTAINER, answerContainerId);
    }
}
