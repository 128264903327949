/**
 * update sbk user body
 */
import { IsBoolean, IsDate, IsDateString, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { SbkTel } from './sbk-tel.model';
import { SbkLanguage } from '../enums/sbk-language.enum';
import { SbkSalutation } from '../enums/sbk-salutation.enum';
import { SbkTitle } from '../enums/sbk-title.enum';

export class UpdateSbkUserBody {
    /**
     * due to the fantastic and beloved dynamic handling of choices: use sting
     */
    @IsString()
    salutation: SbkSalutation;

    /**
     * due to the fantastic and beloved dynamic handling of choices: use sting
     */
    @IsString()
    title: SbkTitle;

    @IsString()
    firstName: string;

    @IsString()
    lastName: string;

    @IsString()
    email: string;

    @IsString()
    @IsOptional()
    plainPassword: string;

    @IsString()
    @IsOptional()
    language: SbkLanguage;

    @IsDateString()
    dateOfBirth: Date;

    @IsString()
    tel1: string;

    @IsString()
    tel2: string;

    @IsString()
    tel3: string;

    @IsString()
    insurance: string;

    @IsString()
    policyNumber: string;

    @IsBoolean()
    adApproval: boolean;

    /**
     * constructor
     * @param salutation
     * @param title
     * @param firstName
     * @param lastName
     * @param email
     * @param plainPassword
     * @param language
     * @param dateOfBirth
     * @param tel1
     * @param tel2
     * @param tel3
     * @param insurance
     * @param policyNumber
     * @param adApproval
     */
    constructor(
        salutation: SbkSalutation,
        title: SbkTitle,
        firstName: string,
        lastName: string,
        email: string,
        plainPassword: string,
        language: SbkLanguage,
        dateOfBirth: Date,
        tel1: string,
        tel2: string,
        tel3: string,
        insurance: string,
        policyNumber: string,
        adApproval: boolean,
    ) {
        this.salutation = salutation;
        this.title = title;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.plainPassword = plainPassword;
        this.language = language;
        this.dateOfBirth = dateOfBirth;
        this.tel1 = tel1;
        this.tel2 = tel2;
        this.tel3 = tel3;
        this.insurance = insurance;
        this.policyNumber = policyNumber;
        this.adApproval = adApproval;
    }
}
