import { NgModule } from '@angular/core';
import { DialogModule } from '../../../shared/dialog/dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UserTestInvitationPromptComponent } from './user-test-invitation-prompt.component';
import { UserTestInvitationPromptService } from './user-test-invitation-prompt.service';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../shared/pipes/pipe.module';

@NgModule({
    declarations: [UserTestInvitationPromptComponent],
    imports: [DialogModule, MatDialogModule, MatButtonModule, CommonModule, PipesModule],
    providers: [UserTestInvitationPromptService],
})
export class UserTestInvitationPromptModule {}
