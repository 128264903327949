<mm-dialog-header
    [title]="getHeaderByTab(view)"
    [showBackButton]="view === viewType.TECHNICAL_ISSUES || view === viewType.WARNING"
    (backButtonClicked)="switchView(viewType.DEFAULT)"
></mm-dialog-header>

<mat-dialog-content class="center" [ngSwitch]="view">
    <div *ngSwitchCase="viewType.TECHNICAL_ISSUES">
        <form [formGroup]="form" (submit)="sendRequest()">
            <div style="margin-bottom: 25px" class="fw-500">{{ strings.supportPageDiga24HourDisclaimer() }}</div>

            <mat-form-field appearance="outline" class="full">
                <mat-label>{{ 'general_request' | localise }}</mat-label>
                <textarea
                    [placeholder]="'general_request_detail' | localise"
                    [cdkAutosizeMinRows]="deviceService.isMobile() ? 5 : 10"
                    [cdkAutosizeMaxRows]="deviceService.isMobile() ? 5 : 18"
                    cdkTextareaAutosize
                    formControlName="text"
                    matInput
                ></textarea>
                <mat-error *ngIf="text.hasError('required')">{{ 'validation_required' | localise }}</mat-error>
            </mat-form-field>
            <div class="fw-500">
                {{ strings.expertSupport.sendEmail() }}
                <a href="mailto:support@glyk.io" class="primary-color">support@glyk.io</a>
            </div>
            <button
                color="primary"
                mat-fab
                extended
                type="submit"
                style="box-shadow: none; margin-block: 15px; width: 250px"
                [disabled]="isSending"
            >
                <mat-spinner
                    *ngIf="isSending"
                    class="button-spinner"
                    color="primary"
                    [diameter]="20"
                    [strokeWidth]="3"
                ></mat-spinner>
                <mat-icon svgIcon="send-message"></mat-icon>
                <span>{{ strings.expertSupport.sendMessage() }}</span>
            </button>
        </form>
    </div>
    <div *ngSwitchCase="viewType.MESSAGE_SENT">
        <div style="width: 300px">
            <mm-lottie name="emailConfirmation"></mm-lottie>
        </div>
        <div style="text-align: center">
            <p>{{ 'general_support_confirmation' | localise }}</p>
        </div>
    </div>
    <div *ngSwitchCase="viewType.WARNING">
        <div class="support-options-field">
            <div class="fw-500" [innerHTML]="'general_leave_app_confirmation' | localise"></div>
            <div style="margin-top: 1.5em; display: flex; justify-content: center">
                <a
                    mat-fab
                    extended
                    color="primary"
                    href="https://book.calenso.com/mementor/glykio-support"
                    target="_blank"
                    style="box-shadow: none; color: white; width: 250px"
                    mat-dialog-close
                >
                    <mat-icon svgIcon="calendar"></mat-icon>
                    {{ strings.expertSupport.openCalendar() }}
                    <mat-icon iconPositionEnd svgIcon="external-link" style="width: 15px"></mat-icon>
                </a>
            </div>
        </div>
    </div>
    <div *ngSwitchDefault>
        <p class="bold">{{ strings.expertSupport.subheader() }}</p>
        <p class="fw-500">{{ strings.expertSupport.description() }}</p>
        <div class="buttons-wrapper">
            <p class="fw-500" [innerHTML]="strings.expertSupport.telephoneContact()"></p>
            <a
                *ngIf="deviceService.isMobile()"
                mat-fab
                extended
                color="primary"
                href="tel:034197852848"
                style="box-shadow: none; color: white"
            >
                <mat-icon>phone</mat-icon>
                {{ strings.expertSupport.callUsNow() }}
            </a>
            <button
                mat-fab
                extended
                color="primary"
                (click)="switchView(viewType.TECHNICAL_ISSUES)"
                style="box-shadow: none"
            >
                <mat-icon svgIcon="message-icon"></mat-icon>
                {{ strings.expertSupport.sendMessage() }}
            </button>
            <button mat-fab extended color="primary" (click)="switchView(viewType.WARNING)" style="box-shadow: none">
                <mat-icon svgIcon="calendar"></mat-icon>
                {{ strings.makeAnAppointment() }}
                <mat-icon iconPositionEnd svgIcon="external-link" style="width: 15px"></mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>
