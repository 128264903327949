import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'unique',
})

/**
 * returns all unique objects based on a certainproperty
 */
export class UniquePipe implements PipeTransform {
    /**
     * transform
     */
    transform(list: any[], args?: any): any {
        // prevent missing args
        if (!args || args.length === 0) {
            return list;
        }

        const property = args[0];
        return _.uniqBy(list, property || 'id');
    }
}
