import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MatDrawerMode } from '@angular/material/sidenav';

export interface NavigationState {
    open: boolean;
    mode: MatDrawerMode;
    mobile: boolean;
}

export function createInitialState(): NavigationState {
    return {
        open: false,
        mode: 'side',
        mobile: false,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'navigation' })
export class NavigationStore extends Store<NavigationState> {
    constructor() {
        super(createInitialState());
    }
}
