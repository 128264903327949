import { Region } from '../../../../../backend/src/shared/entities/region.enum';
import { inject } from '@angular/core';
import { AppContextQuery } from '../application/state/app-context.query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const isCurrentRegionObservable$ = (): ((region: Region) => Observable<boolean>) => {
    const appContextQuery = inject(AppContextQuery);

    return (region: Region) => appContextQuery.select().pipe(map(state => state.region === region));
};

export const isCurrentRegion = (): ((region: Region) => boolean) => {
    const appContextQuery = inject(AppContextQuery);

    return (region: Region) => appContextQuery.getValue().region === region;
};
