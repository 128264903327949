import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { State } from './user.store';
import { UserStore } from './user.store';
import { IUser } from './user.model';

@Injectable({
    providedIn: 'root',
})

/**
 * module query
 */
export class UserQuery extends QueryEntity<State, IUser> {
    /**
     * filter change observable
     */
    public filtersChange$ = this.select(state => state.ui.filters);

    /**
     * lazy change observable
     */
    public selectLazy$ = this.select(state => state.lazy);

    /**
     * constructor
     */
    constructor(protected store: UserStore) {
        super(store);
    }
}
