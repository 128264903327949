import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as uuid from 'uuid';
import * as _ from 'lodash';
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap, tap } from 'rxjs/operators';
import { resetStores } from '@datorama/akita';
import { OAuthService } from 'angular-oauth2-oidc';
import * as crypto from 'crypto-js';

import { SocketService } from '../../socket/socket.service';
import { IUser } from '../user/user.model';
import { ConfigService } from '../../config/config.service';
import { CookieStore } from '../../cookie/cookie-store';
import { AuthStore, AuthTokenPayload } from './auth.store';
import { UserStore } from '../user/user.store';
import { ErrorTranslationKey, NotificationService } from '../../notification/notification.service';
import { SessionActions } from '../../../../../../backend/src/modules/session/actions/session.actions';
import { NotificationType } from '../../notification/notification-type.enum';
import { ErrorCode } from '../../../../../../backend/src/shared/modules/errorHandling/enums/errors.enum';
import { IdleCheckService } from '../../idle-check/idle-check.service';
import { UserQuery } from '../user/user.query';
import { AppContextQuery } from '../../application/state/app-context.query';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { MMCookie } from '../../enums/cookie-enum';
import { RequestNewPasswordDto } from '../../../../../../backend/src/modules/session/dtos/requestNewPassword.dto';
import { DeviceQuery } from '../../device/state/device.query';
import { RegistrationDto } from '../../../../../../backend/src/modules/session/dtos/registration.dto';
import { Platform } from '../../../../../../backend/src/modules/notifications/enums/platform.enum';
import { MtmParametersInterface } from '../../../../../../backend/src/modules/session/interfaces/mtm-parameters.interface';
import { AuthorizationActions } from '../../../../../../backend/src/modules/authorization/actions/authorization.actions';
import { TokenResponse } from '../../../../../../backend/src/modules/session/interfaces/token-response.interface';
import { PrescriptionDetails } from '../../../../../../backend/src/shared/modules/licensing/models/voucher/prescription-details';
import { NavRoutes } from '../../enums/navigation_router';

@Injectable()
export class AuthService {
    private changeMailBeforeMailActivationToken: string; // token for email change before registration

    constructor(
        private socketService: SocketService,
        private authStore: AuthStore,
        private deviceQuery: DeviceQuery,
        private userStore: UserStore,
        private userQuery: UserQuery,
        private idleCheckService: IdleCheckService,
        private notificationService: NotificationService,
        private router: Router,
        private appContextQuery: AppContextQuery,
        private configService: ConfigService,
        private http: HttpClient,
        private jwtHelperService: JwtHelperService,
        private oauthService: OAuthService,
    ) {}

    private decodedTokenPayload: AuthTokenPayload;

    get authToken(): string {
        return this.jwtHelperService.tokenGetter() as string; // TODO make async
    }

    get user(): IUser {
        if (!this.decodedTokenPayload) {
            this.decodedTokenPayload = this.jwtHelperService.decodeToken(this.authToken);
        }

        return this.decodedTokenPayload?.user;
    }

    public login(user: IUser, factorCode?: string, isRequestingFactorCode?: boolean): Observable<IUser> {
        return new Observable(observer => {
            // store the device ID used for two factor authentication
            this.storeDeviceId();
            this.setLoading(true);
            this.setError(null);

            // create http load
            const load = {
                email: user && user.email ? user.email : '',
                password: user && user.password ? crypto.SHA512(user.password).toString(crypto.enc.Hex) : '',
                deviceId: CookieStore.getCookie(MMCookie.DEVICE_ID),
                factorCode: factorCode,
                application: this.appContextQuery.getValue().application,
                isRequestingFactorCode,
            };

            const login$ = this.http.post(this.configService.BASE_URL + '/api/session/login', load);
            const connect$ = login$.pipe(
                tap((token: { authToken: string }) => {
                    this.setTokensInSessionStorage(token.authToken);
                }),
                switchMap(token => this.socketService.connectToSocket()),
            );

            connect$.subscribe({
                next: user => {
                    observer.next(user);
                },
                error: err => {
                    // only show error if not from second factor
                    if (err.error && err.error.statusCode !== ErrorCode.SECOND_FACTOR_REQUIRED) {
                        this.setError(err.error);
                    }

                    this.socketService.handleFailedSocketConnection();
                    this.setLoading(false);
                    observer.error(err);
                },
                complete: () => {
                    this.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    public connectAfterSuccessfulLogin(authToken: string): void {
        this.setTokensInSessionStorage(authToken);

        this.socketService.connectToSocket().subscribe({
            next: user => {
                this.setupAppAfterSocketConnection(user);
                void this.router.navigate(['/coaching']);
            },
            error: error => console.error(error),
            complete: () => {},
        });
    }

    public setupAppAfterSocketConnection(user: IUser): void {
        this.updateAuthUser(user);
        this.userStore.set([user]);
        this.userStore.setActive(user ? user._id : null);
        if (!CookieStore.getCookie(MMCookie.SHOW_LOGIN_SCREEN) && !CookieStore.getCookie(MMCookie.WEB_AUTHN)) {
            CookieStore.setCookie(MMCookie.SHOW_LOGIN_SCREEN, true);
        }

        this.setLoading(false);
    }

    public updateAuthUser(user: IUser): void {
        const authUser = {
            _id: user._id,
            language: user.language,
            token: user.token,
        };

        this.authStore.update(authUser);
    }

    public register(
        email: string,
        licenseCode: string,
        languageCode: string,
        application: Application,
        newsletter: boolean,
        provider?: string,
        token?: string,
        mtmParams?: MtmParametersInterface,
    ): Observable<void> {
        // implement as observable (instead of promise)
        return new Observable<void>(observer => {
            this.setLoading(true);
            this.setError(null);

            // handle platform info
            const deviceInfo = this.deviceQuery.getValue();
            let platform = Platform.WEB;
            if (deviceInfo?.app) {
                if (deviceInfo.iOS) {
                    platform = Platform.IOS;
                } else if (deviceInfo.android) {
                    platform = Platform.ANDROID;
                }
            }
            mtmParams.platform = platform;

            // create http load
            const load: RegistrationDto = {
                email: email,
                licenseCode: licenseCode,
                application: application,
                language: languageCode,
                newsletter: newsletter,
                provider: provider,
                token: token,
                mtmParams: mtmParams,
                concierge: false,
            };

            // register
            this.http
                .post(this.configService.BASE_URL + '/api/session/register', load, {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                })
                .subscribe(
                    (response: { token: string }) => {
                        this.changeMailBeforeMailActivationToken = response?.token;
                        this.setLoading(false);
                        observer.next();
                    },
                    err => {
                        this.setLoading(false);
                        this.setError(err.error);
                        observer.error(err.error);
                    },
                );
        });
    }

    /**
     *
     * change mail returns only false, if token invalid
     * in all other cases this returns true (for security reasons)
     */
    public changeMailBeforeMailActivation(newEmail: string, oldEmail: string): Observable<void> {
        return new Observable<void>(observer => {
            const error = { statusCode: ErrorCode.RESET_MAIL_TOKEN_EXPIRED };

            if (!this.changeMailBeforeMailActivationToken) {
                this.setError(error);
                return observer.error(error);
            }

            // manual check for expiration
            const decoded = this.jwtHelperService.decodeToken(this.changeMailBeforeMailActivationToken);
            if (!decoded?.exp || Date.now() >= decoded.exp * 1000) {
                this.setError(error);
                return observer.error(error);
            }

            this.setLoading(true);
            this.setError(null);

            // create http load
            const load = {
                newEmail: newEmail,
                oldEmail: oldEmail,
                token: this.changeMailBeforeMailActivationToken,
            };

            this.http
                .post(this.configService.BASE_URL + '/api/session/changeMailBeforeMailActivation', load, {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                })
                .subscribe(
                    () => {
                        observer.next();
                        this.setLoading(false);
                        observer.complete();
                    },
                    err => {
                        this.setLoading(false);
                        this.setError(err.error);
                        observer.error(err.error);
                    },
                );
        });
    }

    public resendActivationMail(email: string): Observable<IUser> {
        // implement as observable (instead of promise)
        return new Observable<IUser>(observer => {
            // create http load
            const load = {
                email: email,
                application: this.appContextQuery.getValue().application,
            };

            // register
            this.http
                .post(this.configService.BASE_URL + '/api/session/resendActivationMail', load, {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                })
                .subscribe(
                    (savedUser: IUser) => {
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_resent_activation_email',
                        );
                        observer.next(savedUser);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_resent_activation_email_error',
                        );
                        observer.error(err.error);
                    },
                );
        });
    }

    public requestNewPassword(input: RequestNewPasswordDto): Observable<string> {
        // implement as observable (instead of promise)
        return new Observable<string>(observer => {
            this.setLoading(true);
            this.setError(null);

            const headers = new Headers();
            headers.append('Content-Type', 'application/json');

            // send the request
            this.http
                .post(this.configService.BASE_URL + '/api/session/requestPasswordReset', input, {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                })
                .subscribe(
                    () => {
                        this.setError(null);
                        this.setSuccess(true);
                        this.setLoading(false);

                        observer.next();
                    },
                    err => {
                        this.setLoading(false);
                        this.setSuccess(false);
                        this.setError(err.error);

                        observer.error(err.error);
                    },
                );
        });
    }

    public requestResetPasswordLink(email: string): Observable<string> {
        return new Observable(observer => {
            this.socketService.fire(AuthorizationActions.REQUEST_PASSWORD_RESET_LINK, email).subscribe(
                (resetLink: string) => {
                    observer.next(resetLink);
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public resetPassword(password: string, resetToken: string): Observable<string> {
        return new Observable<string>(observer => {
            this.setLoading(true);
            this.setError(null);

            // create http load
            const body = {
                password: crypto.SHA512(password).toString(crypto.enc.Hex),
                token: resetToken,
                application: this.appContextQuery.getValue().application,
            };

            // reset
            this.http.post(this.configService.BASE_URL + '/api/session/setNewPassword', body).subscribe(
                () => {
                    this.setError(null);
                    this.setLoading(false);
                    this.setSuccess(true);

                    observer.next();
                },
                err => {
                    this.setLoading(false);
                    this.setSuccess(false);
                    this.setError(err.error);

                    observer.error(err.error);
                },
            );
        });
    }

    public logout(): void {
        this.socketService.fire(SessionActions.LOGOUT, {}).subscribe();

        // clear the decoded token
        this.decodedTokenPayload = null;

        // disconnect from socket
        if (this.socketService.socket && this.socketService.socket.connected) {
            this.socketService.socket.close();
        }

        //health id logout
        this.oauthService.logOut();

        this.notificationService.displayNotification(NotificationType.INFO, 'notification_secure_logout');

        // stop checking for inactivity
        this.idleCheckService.stopChecking();

        // clear the cookie
        sessionStorage.removeItem(MMCookie.AUTH_TOKEN);
        try {
            //TODO: check if resetStores leaves user creds in the store
            // clear akita store
            resetStores({ exclude: ['languages', 'device', 'appContext'] });
        } catch (_e) {
            console.error('Error in when resetting stores');
        }

        // navigate to logout
        if (this.router.url !== NavRoutes.LOGIN) {
            this.router.navigate([NavRoutes.LOGIN]);
        }

        void this.router.navigate(['session/login']);
    }

    public activateAccount(
        token: string,
        password: string,
        prescriptionDetails: PrescriptionDetails,
    ): Observable<IUser> {
        return new Observable(observer => {
            this.setLoading(true);
            this.setError(null);

            // activation body
            const body = {
                token: token,
                password: password && password !== '' ? crypto.SHA512(password).toString(crypto.enc.Hex) : null,
                prescriptionDetails: prescriptionDetails,
            };

            const activate$ = this.http.post(this.configService.BASE_URL + '/api/session/activateAccount', body);
            activate$.subscribe(
                (response: { user: IUser; authToken: string }) => {
                    this.setLoading(false);
                    this.setSuccess(true);
                    this.setTokensInSessionStorage(response.authToken);
                    this.setupAppAfterSocketConnection(response.user);

                    observer.next(response.user);
                },
                err => {
                    this.setLoading(false);
                    this.setError(err.error);

                    observer.error(err.error);
                },
            );
        });
    }

    public updateUser(user: IUser, notify: boolean = true): Observable<IUser> {
        return new Observable(observer => {
            this.socketService.fire(SessionActions.UPDATE_USER, user).subscribe(
                (updatedUser: IUser) => {
                    const previousUser = this.userQuery.getActive() as IUser;
                    const isNewEmailAddress = _.get(user, 'email', previousUser.email) !== previousUser.email;
                    this.updateAuthUser(updatedUser);
                    this.userStore.updateActive(updatedUser);

                    if (isNewEmailAddress) {
                        // user must click the confirmation link sent by e-mail
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_profile_email_updated',
                        );
                    } else if (notify) {
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_profile_saved',
                        );
                    }
                    observer.next(updatedUser);
                },
                err => {
                    this.notificationService.displayErrorWithCode(err, ErrorTranslationKey.PROFILE_SAVE_ERROR);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public removeAccount(): Observable<void> {
        return new Observable(observer => {
            this.socketService.fire(SessionActions.REMOVE_ACCOUNT, {}).subscribe(
                () => {
                    this.logout();
                    observer.next();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_remove_account_error',
                    );
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public revokeConsent(): Observable<IUser> {
        return new Observable(observer => {
            this.socketService.fire(SessionActions.REVOKE_CONSENT, {}).subscribe(
                (updatedUser: IUser) => {
                    this.logout();
                    observer.next(updatedUser);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_revoke_consent_error',
                    );
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public getDownloadLinkToProfileDownload(): Observable<string> {
        const application = this.appContextQuery.getValue().application;
        return new Observable(observer => {
            this.authStore.setLoading(true);
            this.socketService.fire(SessionActions.EXPORT_USER_DATA_AS_DOWNLOAD, { application }).subscribe(
                (response: any) => {
                    this.authStore.setLoading(false);
                    observer.next(response);
                },
                err => {
                    this.authStore.setLoading(false);
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_profile_export_error',
                    );
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public exportUserDataAsStream(userId?: string): Observable<string> {
        const application = this.appContextQuery.getValue().application;
        return new Observable(observer => {
            this.authStore.setLoading(true);
            this.socketService.fire(SessionActions.EXPORT_USER_DATA_AS_STREAM, { userId, application }).subscribe(
                (response: any) => {
                    this.authStore.setLoading(false);
                    observer.next(response);
                },
                err => {
                    this.authStore.setLoading(false);
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_profile_export_error',
                    );
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public storeDeviceId(): void {
        let deviceId = CookieStore.getCookie(MMCookie.DEVICE_ID);

        // no device ID, create one
        if (!deviceId) {
            deviceId = uuid.v4();
            CookieStore.setCookie(MMCookie.DEVICE_ID, deviceId);
        }
    }

    public usesDigaVoucher(activationToken: string): Observable<boolean> {
        return new Observable<boolean>(observer => {
            const body = { token: activationToken };

            this.http.post(this.configService.BASE_URL + '/api/session/usesDigaCode', body).subscribe(
                (usesDigaCode: boolean) => {
                    observer.next(usesDigaCode);
                },
                err => {
                    observer.error(err.error);
                },
            );
        });
    }

    public setSuccess(success: boolean): void {
        this.authStore.setSuccess(success);
    }

    public setError(error: any): void {
        this.authStore.setError(error);
    }

    public setLoading(loading: boolean): void {
        this.authStore.setLoading(loading);
    }

    public setTokensInSessionStorage(authToken: string): void {
        sessionStorage.setItem(MMCookie.AUTH_TOKEN, authToken);
    }
}
