import { InjectionToken } from '@angular/core';

export interface LoggingTool {
    debug: (message: string, extras?: Record<string, unknown>) => void;
    error: (error: unknown, extras?: Record<string, unknown>) => void;
    info: (message: string, extras?: Record<string, unknown>) => void;
    warn: (message: string, extras?: Record<string, unknown>) => void;
}

export const LoggingTool = new InjectionToken<LoggingTool>('LoggingTool');
