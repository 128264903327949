export enum LanguageCode {
    DE_CH = 'de_ch',
    EN_US = 'en_us',
    FR_CH = 'fr_ch',
    IT_CH = 'it_ch',
    EN_AU = 'en_au',
}

export enum LocaleFormat {
    EN = 'en-us',
    DE = 'de',
}
