import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { NotificationType } from '../notification/notification-type.enum';

/**
 * idle check service
 * */
@Injectable()
export class IdleCheckService {
    /**
     * interval
     */
    private interval;

    /**
     * last interaction
     */
    private lastInteractionTime: any;

    /**
     * allowed time to be inactive in minutes
     */
    private readonly INACTIVE_LIMIT_MINUTES = 5;

    /**
     * interval to check for inactivity in seconds
     */
    private readonly CHECK_INTERVAL_SECONDS = 10;

    /**
     * warning seconds
     */
    private readonly WARNING_SECONDS = 180;

    /**
     * constructor
     * */
    constructor(private notificationService: NotificationService) {}

    /**
     * start watching
     *
     */
    public startChecking(): Observable<boolean> {
        this.lastInteractionTime = moment();
        let warningWasDisplayed = false;
        return new Observable(observer => {
            // periodically check if user was active
            this.interval = setInterval(() => {
                const inactiveLimitSeconds = this.INACTIVE_LIMIT_MINUTES * 60;
                const durationSinceLastInteraction = moment.duration(moment().diff(this.lastInteractionTime));
                const secondsLeft = inactiveLimitSeconds - durationSinceLastInteraction.asSeconds();

                if (secondsLeft <= 0) {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'notification_idle_logout');

                    // inform about inactivity in three seconds
                    setTimeout(() => {
                        observer.next(true);
                        this.stopChecking();
                    }, 3000);
                } else if (secondsLeft < this.WARNING_SECONDS && !warningWasDisplayed) {
                    const minutesLeft = Math.ceil(secondsLeft / 60);
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_idle_logout_warning',
                        [minutesLeft.toString()],
                    );
                    warningWasDisplayed = true;
                }
            }, this.CHECK_INTERVAL_SECONDS * 1000);
            this.listenToActivity();
        });
    }

    /**
     * stop watching
     */
    public stopChecking(): void {
        clearInterval(this.interval);
    }

    /**
     * listen to user activity
     */
    private listenToActivity(): void {
        ['mousemove', 'click'].forEach(e => {
            window.addEventListener(e, () => {
                // update interaction time
                this.lastInteractionTime = moment();
            });
        });
    }
}
