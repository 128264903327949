<mm-dialog-header [title]="'general_allow_medical_access_short' | localise"> </mm-dialog-header>

<div style="text-align: center">
    <mat-icon id="warn-icon" color="primary">help </mat-icon>
</div>

<p class="text-content">{{ 'general_allow_medical_access' | localise : [identifier] }}</p>

<div style="text-align: right">
    <button mat-button (click)="confirm()" type="button" color="warn">
        <mat-icon>check</mat-icon>
        <span>{{ 'general_yes' | localise }}</span>
    </button>

    <button mat-button type="button" mat-dialog-close>
        <mat-spinner
            style="display: inline-block; margin-right: 5px"
            [value]="timeLeft"
            mode="determinate"
            color="primary"
            strokeWidth="3"
            diameter="24"
        ></mat-spinner>
        <span>{{ 'general_no' | localise }}</span>
    </button>
</div>
