import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { AppContextQuery } from '../../../shared/application/state/app-context.query';
import { Observable, Subscription } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { CoachingSettings } from '../../../../../../backend/src/modules/coaching/models/coaching/coaching-settings';
import promptConfig, { TestInvitationPromptCoachingSetting } from './user-test-invitation-prompt.config';
import { isCurrentRegion } from '../../../shared/region-helper/region.helper';
import { Branding } from '../../../../../../backend/src/shared/modules/authentication/enums/brandings.enum';

// TODO: add spec file
@Injectable({
    providedIn: 'root',
})
export class UserTestInvitationPromptService implements OnDestroy {
    private isRegion = isCurrentRegion();

    application: Application;
    branding: Branding;
    coaching: ICoaching;
    coachingSubscription: Subscription;
    activeUserTestTimeout: NodeJS.Timeout;

    constructor(
        private dialog: MatDialog,
        private coachingQuery: CoachingQuery,
        private appContextQuery: AppContextQuery,
    ) {
        this.application = this.appContextQuery.getValue().application;
        this.branding = this.appContextQuery.getValue().branding;
        // we need to register to this observable since when this service is injected active coaching could be undefined
        // NOTE: alternatively we can pass active coaching when dialog needs to be displayed
        this.coachingSubscription = (this.coachingQuery.selectActive() as Observable<ICoaching>).subscribe(
            (activeCoachingQuery: ICoaching): void => {
                this.coaching = activeCoachingQuery;
            },
        );
    }

    ngOnDestroy(): void {
        this.coachingSubscription.unsubscribe();
        this.destroyTimeoutIfExists();
    }

    destroyTimeoutIfExists(): void {
        if (this.activeUserTestTimeout) clearTimeout(this.activeUserTestTimeout);
    }

    public openUserTestInvitationPrompt(component: ComponentType<unknown>): void {
        const appliesToThisApplication: boolean = promptConfig.whenToDisplay.applications.includes(this.application);
        const appliesToThisCoaching: boolean = promptConfig.whenToDisplay.coachingTypes.includes(this.coaching.key);
        const appliesToThisRegion: boolean = this.isRegion(promptConfig.whenToDisplay.region);
        const appliesToThisBranding: boolean = this.branding === promptConfig.whenToDisplay.branding;
        const userHasNotYetDecided: boolean = !this.hasAlreadyDecided();
        const userHasNotSeenPromptToday: boolean = !this.hasAlreadySeenPromptToday();
        if (
            appliesToThisApplication &&
            appliesToThisCoaching &&
            appliesToThisRegion &&
            appliesToThisBranding &&
            userHasNotYetDecided &&
            userHasNotSeenPromptToday
        ) {
            this.destroyTimeoutIfExists();
            this.activeUserTestTimeout = setTimeout((): void => {
                this.dialog.open(component, promptConfig.matDialogConfig);
            }, promptConfig.promptDisplayDelayMs);
        }
    }

    private hasAlreadyDecided(): boolean {
        const hasSeenWarning: CoachingSettings = this.coaching?.settings?.find(
            (setting: CoachingSettings) =>
                setting.property === promptConfig.promptDecisionCoachingSetting &&
                promptConfig.promptAddressedStates.includes(setting.value as TestInvitationPromptCoachingSetting),
        );
        return !!hasSeenWarning;
    }

    // TODO: we can reuse the logic here for date matching
    private hasAlreadySeenPromptToday(): boolean {
        const lastDecisionTimestamp: CoachingSettings = this.coaching?.settings?.find(
            (setting: CoachingSettings): boolean =>
                setting.property === promptConfig.promptDecisionTimestampCoachingSetting,
        );
        if (lastDecisionTimestamp && lastDecisionTimestamp.value) {
            const dateFromISO: Date = new Date(lastDecisionTimestamp.value);
            const today: Date = new Date();
            return (
                dateFromISO.getFullYear() === today.getFullYear() &&
                dateFromISO.getMonth() === today.getMonth() &&
                dateFromISO.getDate() === today.getDate()
            );
        }
        return false;
    }

    public closePrompt(): void {
        this.dialog.closeAll();
    }
}
