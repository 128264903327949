import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { AppContextQuery } from '../../../shared/application/state/app-context.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Directive({
    selector: '[notApplication]',
})
export class NotApplicationDirective implements OnInit, OnDestroy {
    private blackList: Application[] = [];
    private isRendered = false;

    @Input() set notApplication(blackList: Application[]) {
        this.blackList = blackList;
        this.renderConditionally(this.appContextQuery.getValue().application);
    }

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private appContextQuery: AppContextQuery,
    ) {}

    ngOnInit(): void {
        this.appContextQuery
            .select()
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.renderConditionally(value.application);
            });
    }

    private renderConditionally(application: Application): void {
        const matchesBlackList = this.matchesBlackList(application);

        // should be rendered but is not
        if (!matchesBlackList && !this.isRendered) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isRendered = true;
            return;
        }

        // should not be rendered but is
        if (matchesBlackList && this.isRendered) {
            this.viewContainer.clear();
            this.isRendered = false;
        }
    }

    private matchesBlackList(application: Application): boolean {
        return this.blackList.indexOf(application) >= 0;
    }

    ngOnDestroy(): void {}
}
