/* eslint no-console: 0 */
import { LoggingTool } from './contract';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConsoleLoggingTool implements LoggingTool {
    public debug(message: string, extras?: Record<string, unknown>) {
        console.debug(message, ...(extras ? [extras] : []));
    }

    public error(error: unknown, extras?: Record<string, unknown>) {
        console.error(error, ...(extras ? [extras] : []));
    }

    public info(message: string, extras?: Record<string, unknown>) {
        console.info(message, ...(extras ? [extras] : []));
    }

    public warn(message: string, extras?: Record<string, unknown>) {
        console.warn(message, ...(extras ? [extras] : []));
    }
}
