import { Injectable, OnInit } from '@angular/core';
import { SocketService } from '../../../socket/socket.service';
import { AuthStore } from '../auth.store';
import { AuthQuery } from '../auth.query';
import { UserStore } from '../../user/user.store';
import { UserQuery } from '../../user/user.query';
import { IdleCheckService } from '../../../idle-check/idle-check.service';
import { NotificationService } from '../../../notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../language/language.service';
import { ConfigService } from '../../../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorCode } from '../../../../../../../backend/src/shared/modules/errorHandling/enums/errors.enum';
import { RegisterSbkUserBody } from '../../../../../../../backend/src/shared/modules/authentication/sbk/models/register-sbk-user-body.model';
import { SbkSalutation } from '../../../../../../../backend/src/shared/modules/authentication/sbk/enums/sbk-salutation.enum';
import { SbkTitle } from '../../../../../../../backend/src/shared/modules/authentication/sbk/enums/sbk-title.enum';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IUser } from '../../user/user.model';
import { AuthService } from '../auth.service';
import { SbkProfile } from '../../../../../../../backend/src/shared/modules/authentication/sbk/models/sbk-profile.model';
import { SbkActions } from '../../../../../../../backend/src/shared/modules/authentication/sbk/enums/sbk-actions.enum';
import { SbkLanguage } from '../../../../../../../backend/src/shared/modules/authentication/sbk/enums/sbk-language.enum';
import { UpdateSbkUserBody } from '../../../../../../../backend/src/shared/modules/authentication/sbk/models/update-sbk-user-body.model';
import { NotificationType } from '../../../notification/notification-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { SbkProfileField } from '../../../../../../../backend/src/shared/modules/authentication/sbk/models/sbk-profile-field';
import { switchMap, tap } from 'rxjs/operators';
import { LanguageCode } from '../../../translation/translation.service';
import { StaticHtmlDialogComponent } from '../../../dialog/static-html-dialog/static-html-dialog.component';

@Injectable()

/**
 * sbk atm communication service
 * */
export class SbkService implements OnInit {
    /**
     * base url to contact the endpoints for the sbk atm tool
     * @private
     */
    private baseUrl: string;

    /**
     * observer for static pages
     */
    public staticPages$: Observable<any[]>;

    /**
     * constructor
     * */
    constructor(
        private socketService: SocketService,
        private authStore: AuthStore,
        private authService: AuthService,
        private authQuery: AuthQuery,
        private userStore: UserStore,
        private userQuery: UserQuery,
        private idleCheckService: IdleCheckService,
        private notificationService: NotificationService,
        private router: Router,
        private languageService: LanguageService,
        private configService: ConfigService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private http: HttpClient,
    ) {
        this.baseUrl = `${this.configService.BASE_URL}/api/sbk/`;
        this.staticPages$ = this.getStaticPages();
    }

    /**
     * service initialisation
     */
    ngOnInit(): void {}

    /**
     * get the fields required in the sbk atm registration form
     */
    public getRegistrationFields(campaignId, sbkLanguageCode): Observable<any> {
        const url = `${this.baseUrl}registerFields/${campaignId}/${sbkLanguageCode}`;
        return new Observable(observer => {
            return this.http.get(url).subscribe(
                (response: any) => {
                    observer.next(response);
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    /**
     * register the user with the given credentials
     */
    public register(
        campaignId: number,
        sbkLanguageCode: string,
        salutation: number,
        title: number,
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        passwordRepeated: string,
        dateOfBirth: Date,
        tel1: string,
        tel2: string,
        tel3: string,
        insurance: string,
        policyNumber: string,
        dataConsent: boolean,
        adApproval: boolean,
        additionalFields: SbkProfileField[],
    ): Observable<any> {
        const body = new RegisterSbkUserBody(
            campaignId,
            sbkLanguageCode as SbkLanguage,
            salutation as SbkSalutation,
            title as SbkTitle,
            firstName,
            lastName,
            email,
            password,
            passwordRepeated,
            dateOfBirth,
            tel1,
            tel2,
            tel3,
            insurance,
            policyNumber,
            dataConsent,
            adApproval,
            additionalFields,
        );

        const url = `${this.baseUrl}register`;
        return new Observable(observer => {
            return this.http.post(url, body).subscribe(
                (response: any) => {
                    observer.next(response);
                },
                err => {
                    this.checkErrorForRedirects(err, observer);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * activate an sbk account
     */
    public activateSbkAccount(token: string): Observable<IUser> {
        return new Observable(observer => {
            this.authService.setLoading(true);
            this.authService.setError(null);

            // activation body
            const body = {
                activationCode: token,
            };

            // call the sbk backend activate method
            const activate$ = this.http.post(`${this.configService.BASE_URL}/api/sbk/activate`, body);

            activate$.subscribe(
                (dbUser: IUser) => {
                    this.authService.setLoading(false);
                    this.authService.setSuccess(true);

                    observer.next(dbUser);
                },
                err => {
                    this.checkErrorForRedirects(err, observer);
                    this.authService.setLoading(false);
                    this.authService.setError(err.error);

                    observer.error(err.error);
                },
            );
        });
    }

    /**
     * create the body sent when updating an sbk usser profile
     * @param salutation
     * @param title
     * @param firstName
     * @param lastName
     * @param email
     * @param language
     * @param plainPassword
     * @param dateOfBirth
     * @param tel1
     * @param tel2
     * @param tel3
     * @param insurance
     * @param policyNumber
     */
    private createUpdateBody(
        salutation: number,
        title: number,
        firstName: string,
        lastName: string,
        email: string,
        language: SbkLanguage,
        plainPassword: string,
        dateOfBirth: Date,
        tel1: string,
        tel2: string,
        tel3: string,
        insurance: string,
        policyNumber: string,
        adApproval: boolean,
    ): UpdateSbkUserBody {
        return new UpdateSbkUserBody(
            salutation,
            title,
            firstName,
            lastName,
            email,
            plainPassword,
            language,
            dateOfBirth,
            tel1,
            tel2,
            tel3,
            insurance,
            policyNumber,
            adApproval,
        );
    }

    /**
     * resend the activation link
     */
    public resendActivationLink(email: string): Observable<any> {
        const url = `${this.baseUrl}resend-activation-link`;
        return new Observable(observer => {
            return this.http.post(url, { email: email }).subscribe(
                (response: any) => {
                    observer.next(response);
                },
                err => {
                    this.authService.setLoading(false);
                    this.authService.setSuccess(false);
                    this.authService.setError(err.error);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * request a password
     */
    public requestNewPassword(email: string): Observable<any> {
        const url = `${this.baseUrl}reset-password`;
        return new Observable(observer => {
            return this.http.post(url, { email: email }).subscribe(
                (response: any) => {
                    this.authService.setError(null);
                    this.authService.setSuccess(true);
                    this.authService.setLoading(false);
                    observer.next(response);
                },
                err => {
                    this.authService.setLoading(false);
                    this.authService.setSuccess(false);
                    this.authService.setError(err.error);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * set a new password
     */
    public resetPassword(password: string, key: string): Observable<any> {
        const url = `${this.baseUrl}set-new-password`;
        return new Observable(observer => {
            return this.http.post(url, { key: key, password: password }).subscribe(
                (response: any) => {
                    this.authService.setError(null);
                    this.authService.setSuccess(true);
                    this.authService.setLoading(false);
                    observer.next(response);
                },
                err => {
                    this.authService.setLoading(false);
                    this.authService.setSuccess(false);
                    this.authService.setError(err.error);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * activate the user with the given credentials
     */
    public activate(activationCode: string, desiredPassword: string = ''): Observable<any> {
        const url = `${this.baseUrl}activate`;
        return new Observable(observer => {
            return this.http.post(url, { activationCode: activationCode, desiredPassword: desiredPassword }).subscribe(
                (response: any) => {
                    observer.next(response);
                },
                err => {
                    this.checkErrorForRedirects(err, observer);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * login the user with the given credentials
     * @param email
     * @param password
     * @param gaveDataConsent
     * @param gaveAdApproval
     * @param optionalCampaignId Careful! this campaignId is only available if the login is launched from a 'special registration page' like https://pilot-schlafgut.sbk.org/registrieren#197-test-schlafgut-2
     */
    public login(
        email: string,
        password: string,
        gaveDataConsent: boolean,
        gaveAdApproval: boolean,
        optionalCampaignId: number = null,
    ): Observable<IUser> {
        return new Observable(observer => {
            // store the device ID used for two factor authentication
            this.authService.storeDeviceId();
            this.authService.setLoading(true);
            this.authService.setError(null);

            // prepare the call to the sbk login
            const url = `${this.baseUrl}login`;
            const load = {
                email: email,
                password: password,
                gaveDataConsent: gaveDataConsent ?? true, // optional parameter in the UI, but the sbk atm backend api requires it to be set. craaaaazy...
                gaveAdApproval: gaveAdApproval ?? false, // optional parameter in the UI, but the sbk atm backend api requires it to be set. craaaaazy...
            };

            if (optionalCampaignId) {
                load['campaignId'] = optionalCampaignId;
            }

            const sbkLogin$ = this.http.post(url, load);
            // define the action to perform after successful sbk login
            const connect$ = sbkLogin$.pipe(
                tap((token: { authToken: string }) => {
                    this.authService.setTokensInSessionStorage(token.authToken);
                }),
                switchMap(token => this.socketService.connectToSocket()),
            );
            // connect to socket
            connect$.subscribe(
                (dbUser: IUser) => {
                    observer.next(dbUser);
                },
                (err: any) => {
                    /*
                if (_.get(err, 'error.statusCode', 0) === ErrorCode.SBK_ATM_LOGIN_ACCOUNT_NOT_ACTIVATED) {
                    // automatically re-request a new password
                    // (in front-end as only there the mail address is available)
                    this.resendActivationLink(email).subscribe();
                }
                */

                    if (_.get(err, 'error.statusCode', 0) === ErrorCode.SBK_ATM_LOGIN_NOT_REGISTERED) {
                        // case: user is present in atm, but has not yet registered at sleep well.
                        // meaning, he must be redirected to the page where he must log in AND accept the terms
                        // however, can only be done if we know the registration link
                        // but at login, we do not know this link, hence we only can show the error message
                        // Therefore, do not add a special handling but continue with just displaying the error message
                    }

                    this.checkErrorForRedirects(err, observer);
                    this.authService.setLoading(false);
                    this.authService.setError(err.error);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * get the sbk user profile data
     */
    public getDataSbk(): Observable<SbkProfile> {
        return new Observable(observer => {
            this.socketService.fire(SbkActions.GET_USER, {}).subscribe(
                (user: SbkProfile) => {
                    const profile = new SbkProfile(
                        user.sbkUserId,
                        user.salutation,
                        user.title,
                        user.firstName,
                        user.lastName,
                        user.language,
                        user.dateOfBirth,
                        user.insurance,
                        user.policyNumber,
                        user.tel,
                        user.email,
                        user.dataConsent,
                        user.adApproval,
                        user.sendMails,
                        user.campaignId,
                    );
                    observer.next(profile);
                    observer.complete();
                },
                err => {
                    if (!this.checkTokenExpired(err)) {
                        this.userStore.setLoading(false);
                        observer.error(err);
                    }
                },
            );
        });
    }

    /**
     * handle errors to check if the atm session (jwt token) has expired
     * @param err
     */
    public checkTokenExpired(err: any): boolean {
        if (err.text && err.text.toUpperCase().indexOf('EXPIRED') >= 0) {
            this.socketService.handleFailedSocketConnection();
        }
        return false;
    }

    /**
     * update a user's profile data
     * @param salutation
     * @param title
     * @param firstName
     * @param lastName
     * @param email
     * @param language
     * @param newPassword
     * @param dateOfBirth
     * @param tel1
     * @param tel2
     * @param tel3
     * @param insurance
     * @param policyNumber
     * @param adApproval
     */
    public update(
        salutation: number,
        title: number,
        firstName: string,
        lastName: string,
        email: string,
        language: string,
        newPassword: string,
        dateOfBirth: Date,
        tel1: string,
        tel2: string,
        tel3: string,
        insurance: string,
        policyNumber: string,
        adApproval: boolean,
    ): Observable<SbkProfile> {
        const body = this.createUpdateBody(
            salutation,
            title,
            firstName,
            lastName,
            email,
            language as SbkLanguage,
            newPassword,
            dateOfBirth,
            tel1,
            tel2,
            tel3,
            insurance,
            policyNumber,
            adApproval,
        );

        return new Observable(observer => {
            this.socketService.fire(SbkActions.UPDATE_USER, body).subscribe(
                (user: SbkProfile) => {
                    const profile = new SbkProfile(
                        user.sbkUserId,
                        user.salutation,
                        user.title,
                        user.firstName,
                        user.lastName,
                        user.language,
                        user.dateOfBirth,
                        user.insurance,
                        user.policyNumber,
                        user.tel,
                        user.email,
                        user.dataConsent,
                        user.adApproval,
                        user.sendMails,
                        user.campaignId,
                    );
                    observer.next(profile);
                    observer.complete();
                },
                err => {
                    if (!this.checkTokenExpired(err)) {
                        this.userStore.setLoading(false);
                        observer.error(err);
                    }
                },
            );
        });
    }

    public mementorLanguageToSbkLanguage(code: LanguageCode): SbkLanguage {
        switch (code) {
            case 'de-DE':
                return SbkLanguage.DE;
            case 'en-US':
                return SbkLanguage.EN;
            default:
                return SbkLanguage.DE;
        }
    }

    public sbkLanguageToMementorLanguage(code: SbkLanguage): LanguageCode {
        switch (code) {
            case SbkLanguage.DE:
                return 'de-DE';
            case SbkLanguage.EN:
                return 'en-US';
            default:
                return 'de-DE';
        }
    }

    /**
     * read the error message returned by the backend; in some special cases, it is not an error but the user must be redirected
     * @param err
     * @private
     */
    private checkErrorForRedirects(err: any, observer) {
        /*
        // according to sbk, there should only be an error message, no redirect
        if (_.get(err, 'error.statusCode', 0) === ErrorCode.SBK_ATM_LOGIN_ALREADY_REGISTERED_OTHER_CAMPAIGN) {
            // when logging in: user is already registered for other campaign.
            // no error: forward to the login page instead
            // inform the user that he has to accept the data consent
            // the new login form will be used to 'activate' the existing account
            this.router.navigate(['/session/sbk/registration-existing-user']);
            observer.complete();
            return;
        }
        */

        /*
        // this message is now handled at the login page by catching the error there
       if (_.get(err, 'error.statusCode', 0) === ErrorCode.SBK_ATM_LOGIN_ACCOUNT_NOT_ACTIVATED) {
           // when logging in: user has not clicked yet the activation link
           // no error: forward to the login page instead
           // inform the user that he has to click the activation link that was sent to his mail address
           this.router.navigate(['/session/login']);
           this.notificationService.displayNotification(NotificationType.INFO, 'sbk_must_click_confirmation_link', null, 20000);
           observer.complete();
           return;
       }
       */
        if (
            err.error &&
            err.error.statusCode === ErrorCode.SBK_ATM_LOGIN_NOT_REGISTERED &&
            _.get(this.route, 'snapshot.fragment')
        ) {
            // when logging in: user is in the database of the atm, but
            // not registered for any campaign.
            // So the user must go through the activation process using his existing credentials
            // preserve fragment: contains campaignId
            // if no fragment available, the if clause should evaluate to false
            this.router.navigate(['/session/sbk/registration-existing-user'], { preserveFragment: true });
            return;
        } else if (err.error && err.error.statusCode === ErrorCode.SBK_ATM_ACTIVATION_KEY_NEW_PASSWORD) {
            // When registering. The user has no password yet. Don't show an error in this case but instead let the user provide a new password (with a repeated field) and send it together with the key to the activation request.
            // If this new request return "success", you can redirect the user to your login page.
            // preserve fragment: contains campaignId
            this.router.navigate(['/session/sbk/activate-using-password'], { preserveFragment: true });
            this.notificationService.displayNotification(
                NotificationType.INFO,
                'sbk_must_set_password',
                null,
                NotificationService.DURATION_LONG,
            );
            observer.complete();
            return;
        }
    }

    /**
     * display a dialog with static data
     * @param name
     * @protected
     */
    public async displayStaticPage(name: string): Promise<void> {
        this.staticPages$.subscribe(pages => {
            const staticPageData = _.find(pages, { name: name });
            StaticHtmlDialogComponent.open(this.dialog, {
                disableClose: false,
                autoFocus: false,
                width: '100%',
                maxWidth: '600px',
                data: {
                    title: staticPageData.title,
                    html: staticPageData.content,
                },
            });
        });
    }

    /**
     * get the static pages defined in the sbk atm
     */
    public getStaticPages(): Observable<any> {
        const url = `${this.baseUrl}staticPages`;
        return new Observable(observer => {
            return this.http.get(url).subscribe(
                (response: any) => {
                    observer.next(response);
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    /**
     * convert the form field value to the form that is accepted by the atm tool
     * @param dateOfBirthFormValue
     */
    public formatDateOfBirth(dateOfBirthFormValue: any): Date {
        return moment(dateOfBirthFormValue, 'DD.MM.YYYY').toDate();
    }

    /**
     * remove an account
     * similar to userService.removeAccount() (did not want to spoil the userService with sbk specific handling)
     */
    public removeAccount(): Observable<void> {
        return new Observable(observer => {
            this.socketService.fire(SbkActions.REMOVE_ACCOUNT, {}).subscribe(
                () => {
                    this.authService.logout();
                    observer.next();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_remove_account_error',
                    );
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }
}
