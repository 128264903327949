import { Component, OnInit } from '@angular/core';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { TranslationService } from '../../../shared/translation/translation.service';
import { CoachingService } from '../../../shared/state/coaching/coaching.service';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import * as cloneDeep from 'lodash/cloneDeep';
import { ServerDowntimePromptService } from './server-downtime-prompt.service';

@Component({
    selector: 'mm-server-downtime-prompt',
    templateUrl: './server-downtime-prompt.component.html',
})
export class ServerDowntimePromptComponent implements OnInit {
    get strings() {
        return this.translationService.strings.coaching.serverDowntimePrompt;
    }

    get commons() {
        return this.translationService.strings.common;
    }

    constructor(
        private coachingQuery: CoachingQuery,
        private serverDowntimeService: ServerDowntimePromptService,
        private translationService: TranslationService,
        private coachingService: CoachingService,
    ) {}

    ngOnInit(): void {}

    public onGotItClick(): void {
        const coaching: ICoaching = cloneDeep(this.coachingQuery.getActive());
        const serverDowntimeKey = this.serverDowntimeService.getServerDowntimeKey();
        if (coaching.settings?.find(setting => setting.property === serverDowntimeKey && setting.value === 'true')) {
            return;
        }

        if (!coaching.settings?.length) {
            coaching.settings = [];
        }
        coaching.settings.push({ property: serverDowntimeKey, value: 'true' });

        this.coachingService.updateActiveCoachingSettings(coaching.settings).subscribe();

        this.serverDowntimeService.closePrompt();
    }
}
