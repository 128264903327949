import { Injectable } from '@angular/core';
import { LanguageService } from '../language/language.service';

@Injectable({
    providedIn: 'root',
})

/**
 * lottie service
 */
export class LottieService {
    /**
     * constructor
     */
    constructor(private languageService: LanguageService) {}

    /**
     * localise text layers of lottie animations
     */
    public localiseTextLayers(player: any): void {
        // iterate all renderer elements
        for (const layer of player.renderer.elements) {
            // check if this is a text layer
            if (layer.hasOwnProperty('textProperty')) {
                // get the text
                const localisation_key = layer.data.nm;
                const localisedString = this.languageService.getText(localisation_key);

                // check if string is found
                if (localisedString) {
                    // update the text
                    layer.updateDocumentData({ t: localisedString });
                }
            }
        }
    }
}
