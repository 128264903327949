export enum StepActions {
    GET = 'steps:get',
    GET_ALL_FOR_MODULE = 'steps:getAllStepsOfModule',
    GET_FIRST_OF_MODULE = 'step:getFirstStepOfModule',
    GET_ALL_WITH_QUESTIONNAIRE = 'steps:getAllStepsWithQuestionnaire',
    GET_BY_ID = 'step:getById',
    GET_BY_FUNCTION = 'step:getByFunction',
    GET_FROM_ANSWER = 'step:getFromAnswer',
    CREATE = 'step:create',
    UPDATE = 'step:update',
    UPDATE_MANY_POSITION = 'step:updateManyPosition',
    REMOVE = 'step.remove',
    REMOVE_MANY = 'step.removeMany',
    VALIDATE_EXPRESSION = 'step:validateExpression',
    LOAD_EVALUATION = 'step:loadEvaluation',
    LOAD_QUESTIONNAIRE = 'step:loadQuestionnaire',
    COPY = 'steps:copy',
    GENERATE_PDF = 'steps:generatePdf',
    SEARCH_FOR_STEP = 'steps:searchForStep',
    LOAD_NOTE = 'steps:loadNote',
    GET_WITH_PERSONAL_NOTE_FIELD = 'step:getWithPersonalNoteField',
    GET_WITH_ANSWER_CONTAINER = 'step:getWithAnswerContainer',
}
