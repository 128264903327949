import { NgModule } from '@angular/core';
import { IdleCheckService } from './idle-check.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [IdleCheckService],
    exports: [],
    bootstrap: [],
})
export class IdleCheckModule {}
