<mm-dialog-header
    (backButtonClicked)="backButtonClicked()"
    [showBackButton]="view !== ViewType.PRE && view !== ViewType.MESSAGE_SENT"
    [title]="'general_support' | localise"
></mm-dialog-header>

<form (submit)="sendRequest()" [formGroup]="supportDigaForm" [ngSwitch]="view">
    <ng-container *ngSwitchCase="ViewType.PRE">
        <p [innerHTML]="strings[this.application].supportPageDescription()"></p>

        <div id="faq-container">
            <h2 class="faq-header">{{ strings.mostImportantFAQs() }}</h2>
            <ng-container *ngFor="let faq of faqs; let idx = index">
                <div class="faq-link">
                    <a [href]="faq.link" target="_blank">{{ faq.question }}</a>
                </div>
                <hr *ngIf="idx !== faqs.length - 1" />
            </ng-container>
        </div>

        <a
            [href]="strings[application].supportPageLink()"
            target="_blank"
            mat-raised-button
            color="primary"
            class="support-button"
        >
            {{ strings.supportPageDigaButtonPrimary() }}
        </a>

        <button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="noticeBreadcrumb(ViewType.TECHNICAL_ISSUES)"
            class="contact-support"
        >
            {{ strings.supportPageDigaButtonStroked() }}
        </button>
        <div class="disclaimer" *ngIf="application === Application.ACTENSIO">
            <p>{{ strings.supportPageDiga24HourDisclaimer() }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ViewType.TECHNICAL_ISSUES">
        <ng-container [ngTemplateOutlet]="supportFaq"></ng-container>

        <ng-container [ngTemplateOutlet]="supportTextField"></ng-container>

        <div class="hr-sect" style="margin: 1rem 0 1rem 0">{{ 'general_or' | localise }}</div>

        <ng-container [ngTemplateOutlet]="supportCounseling"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ViewType.WARNING">
        <div class="support-options-field">
            <div [innerHTML]="'general_leave_app_confirmation' | localise"></div>
            <div style="margin-top: 1.5em; display: flex; justify-content: space-between">
                <button mat-dialog-close="" mat-raised-button type="button">
                    <span>{{ 'general_close' | localise }}</span>
                </button>
                <button
                    (click)="onLeaveDigaConfirmed()"
                    color="primary"
                    mat-dialog-close=""
                    mat-raised-button
                    type="button"
                >
                    <span>
                        {{ 'general_continue' | localise }}
                    </span>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="ViewType.MESSAGE_SENT">
        <div style="width: 300px">
            <mm-lottie name="emailConfirmation"></mm-lottie>
        </div>
        <div style="text-align: center">
            <p>{{ 'general_support_confirmation' | localise }}</p>
        </div>
    </ng-container>

    <ng-template *ngIf="application === Application.SOMNIO" #supportFaq>
        <div class="support-faq">
            <span>{{ 'general_support_faq' | localise }}</span>
            <a *ngIf="application === Application.SOMNIO" class="color-primary" href="{{ faqLink }}" target="_blank"
                >somnio</a
            >
        </div>
    </ng-template>

    <ng-template #supportTextField>
        <div class="support-options-field">
            <div>
                {{ 'general_support_form' | localise }}
            </div>

            <mat-form-field appearance="outline" class="full">
                <mat-label>{{ 'general_request' | localise }}</mat-label>
                <textarea
                    [placeholder]="'general_request_detail' | localise"
                    cdkAutosizeMaxRows="8"
                    cdkAutosizeMinRows="2"
                    cdkTextareaAutosize
                    formControlName="text"
                    matInput
                ></textarea>
                <mat-error *ngIf="text.hasError('required')">{{ 'validation_required' | localise }}</mat-error>
            </mat-form-field>

            <button color="primary" mat-raised-button type="submit" [disabled]="isSending">
                <mat-spinner
                    *ngIf="isSending"
                    class="button-spinner"
                    color="primary"
                    [diameter]="20"
                    [strokeWidth]="3"
                ></mat-spinner>
                <span>{{ 'general_send_request' | localise }}</span>
            </button>
        </div>
    </ng-template>

    <ng-template #supportCounseling>
        <div class="support-options-field">
            <div>
                {{ 'general_support_counseling' | localise }}
            </div>
            <button (click)="noticeBreadcrumb(ViewType.WARNING)" color="primary" mat-raised-button>
                {{ 'general_support_calendar' | localise }}
            </button>

            <div *ngIf="showHint" style="display: flex; padding-top: 1rem">
                <mat-icon class="icon-warn icon-md" style="margin-right: -4px" svgIcon="exclamation"></mat-icon>
                <span>Le service téléphonique n'est malheureusement disponible qu'en allemand et en anglais.</span>
            </div>
        </div>
    </ng-template>
</form>
