import { Translation } from '../../../../../../backend/src/shared/modules/language/models/translation.model';

/**
 * translation type
 */
export interface ITranslation extends Translation {}

/**
 * factory function
 */
export function createTranslation(params: Partial<ITranslation>) {
    return { de_ch: '', fr_ch: '', it_ch: '', en_us: '', ...params } as ITranslation;
}
