import type { Locales, Formatters } from './i18n-types';
import * as moment from 'moment';
import { FormattersInitializer } from 'typesafe-i18n';

export const initFormatters: FormattersInitializer<Locales, Formatters> = (locale: Locales) => {
    return getFormatters(locale);
};

export function getFormatters(locale: Locales) {
    const formatters = {
        timeShort: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            switch (l) {
                case 'de-DE':
                    return m.format('HH:mm');
                case 'en-US':
                    return m.format('hh:mm A');
                case 'fr-FR':
                    return m.format('HH [h] mm');
                case 'it-IT':
                    return '';
            }
        },
        timeLong: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            switch (l) {
                case 'de-DE':
                    return m.format('HH:mm [Uhr]');
                case 'en-US':
                    return m.format('hh:mm A');
                case 'fr-FR':
                    return m.format('HH [h] mm');
                case 'it-IT':
                    return '';
            }
        },
        dateShort: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            switch (l) {
                case 'de-DE':
                    return m.format('DD.MM.YYYY');
                case 'en-US':
                    return m.format('MM/DD/YYYY');
                case 'fr-FR':
                    return m.format('DD/MM/YYYY');
                case 'it-IT':
                    return '';
            }
        },
        dateShortWithoutSameYear: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            const isSameYear = m.isSame(new Date(), 'year');
            if (!isSameYear) {
                return formatters.dateShort(d, l);
            }
            switch (l) {
                case 'de-DE':
                    return m.format('DD.MM.');
                case 'en-US':
                    return m.format('MM/DD');
                case 'fr-FR':
                    return m.format('DD/MM');
                case 'it-IT':
                    return '';
            }
        },
        dateLong: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            switch (l) {
                case 'de-DE':
                    return m.format('DD. MMMM YYYY');
                case 'en-US':
                    return m.format('MMMM Do, YYYY');
                case 'fr-FR':
                    return m.format('DD MMMM YYYY');
                case 'it-IT':
                    return '';
            }
        },
        dateTimeShort: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            switch (l) {
                case 'de-DE':
                    return m.format('DD.MM.YYYY, HH:mm');
                case 'en-US':
                    return m.format('MM/DD/YYYY, hh:mm A');
                case 'fr-FR':
                    return m.format('DD/MM/YYYY, HH [h] mm');
                case 'it-IT':
                    return '';
            }
        },
        dateTimeLong: (d: Date, l: Locales = locale) => {
            const m = moment(d).locale(l);
            switch (l) {
                case 'de-DE':
                    return m.format('DD. MMMM YYYY [um] HH:mm [Uhr]');
                case 'en-US':
                    return m.format('MMMM Do, YYYY [at] hh:mm A');
                case 'fr-FR':
                    return m.format('DD MMMM YYYY [à] HH [h] mm');
                case 'it-IT':
                    return '';
            }
        },
    };
    return formatters;
}
