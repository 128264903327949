import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../../../shared/language/language.service';
import hypertensionJournalDictionary from '../../hypertension-journal/localisation/hypertension-dictionary';
import {
    getNutritionIcon,
    NutritionType,
} from '../../../../../../../backend/src/modules/coaching/modules/hypertensionJournal/enums/nutrition-type.enum';

@Component({
    selector: 'mm-nutrition-protocol-score',
    templateUrl: './nutrition-protocol-score.component.html',
    styleUrls: ['./nutrition.protocol-score.component.sass'],
})
export class NutritionProtocolScoreComponent implements OnInit {
    @Input() type: NutritionType = NutritionType.FRUITS;
    @Input() averageValue = 0;

    public get key(): string {
        return this.type === NutritionType.GOOD_FATS ? `ht_${NutritionType.GOOD_FATS}_short` : `ht_${this.type}`;
    }

    public get startsGreen(): boolean {
        return [NutritionType.SALT, NutritionType.RED_MEAT, NutritionType.SUGAR].includes(this.type);
    }

    public get greenDots(): number[] {
        return NutritionProtocolScoreComponent.dots(this.greenDotCount);
    }

    public get redDots(): number[] {
        return NutritionProtocolScoreComponent.dots(11 - this.greenDotCount);
    }

    public get icon(): string {
        return getNutritionIcon(this.type);
    }

    public static dots(count: number): number[] {
        return Array.from(Array(count).keys());
    }

    constructor(private languageService: LanguageService) {}

    ngOnInit(): void {
        this.languageService.addDictionary(hypertensionJournalDictionary);
    }

    public getTranslateX(averageValue: number): string {
        if (averageValue === 0) {
            return 'translateX(5%)';
        }
        return `translateX(${74 * averageValue}%)`;
    }

    private get greenDotCount(): number {
        switch (this.type) {
            case NutritionType.FRUITS:
            case NutritionType.VEGETABLES:
                return 7;
            case NutritionType.WHOLE_GRAIN:
                return 8;
            case NutritionType.LOW_FAT_MILK:
                return 9;
            case NutritionType.GOOD_FATS:
                return 10;
            case NutritionType.SALT:
            case NutritionType.RED_MEAT:
                return 2;
            case NutritionType.SUGAR:
                return 1;
            default:
                return 0;
        }
    }
}
