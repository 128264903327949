import { Component, Input, OnChanges } from '@angular/core';
import { NutritionType } from '../../../../../../../backend/src/modules/coaching/modules/hypertensionJournal/enums/nutrition-type.enum';

@Component({
    selector: 'mm-nutrition-protocol-scores-overview',
    templateUrl: './nutrition-protocol-scores-overview.component.html',
    styleUrls: ['./nutrition-protocol-scores-overview.component.sass'],
})
export class NutritionProtocolScoresOverviewComponent implements OnChanges {
    @Input() nutritionEvaluation: any[] = [];
    @Input() isInReport = false;
    @Input() isInStep = false;

    public nutritionValues: { type: NutritionType; averageValue: number }[] = Object.values(NutritionType).map(
        type => ({
            type,
            averageValue: 0,
        }),
    );

    public averageDashScore = 0;

    constructor() {}

    ngOnChanges(): void {
        for (const nutrition of this.nutritionValues) {
            if (!this.nutritionEvaluation?.length) {
                nutrition.averageValue = 0;
                this.averageDashScore = 0;
            } else {
                nutrition.averageValue = Math.round(this.nutritionEvaluation[0][nutrition.type.toString()] * 10) / 10;
                this.averageDashScore = Math.round(this.nutritionEvaluation[0].dashScore * 10) / 10;
            }
        }
    }
}
