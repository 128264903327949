import { Injectable } from '@angular/core';
import { DeviceQuery } from '../shared/device/state/device.query';
import { NotificationService } from '../shared/notification/notification.service';
import { IOSNativeMessages } from './ios-native-messages.enum';
import { NotificationType } from '../shared/notification/notification-type.enum';
import { ThryveDevice } from '../../../../backend/src/shared/modules/devices/thryve/enums/thryve-device.enum';
import { IUser } from '../shared/state/user/user.model';
import { MobileAppWindow } from '../android/mobile-app-window';

declare let window: MobileAppWindow;

@Injectable({
    providedIn: 'root',
})
export class IosService {
    public isIOSApp: boolean = false;

    constructor(private notificationService: NotificationService, private deviceQuery: DeviceQuery) {
        this.init();
    }

    public init(): void {
        const deviceInfo = this.deviceQuery.getValue();
        this.isIOSApp = deviceInfo.app && deviceInfo.iOS;
    }

    public static retrieveUserThryveToken(user: IUser): string | null {
        const thryveConnections = user?.thryveConnections;
        const appleConnections = thryveConnections?.filter(
            connection => connection.thryveDevice === ThryveDevice.Apple,
        );
        const firstConnection = appleConnections?.length > 0 ? appleConnections.shift() : null;

        if (!firstConnection) {
            return null;
        }

        return firstConnection.authenticationToken;
    }

    public static retrieveUserThryveUsages(user: IUser): string | null {
        const thryveConnections = user?.thryveConnections;
        const appleConnections = thryveConnections?.filter(
            connection => connection.thryveDevice === ThryveDevice.Apple,
        );
        const firstConnection = appleConnections?.length > 0 ? appleConnections.shift() : null;

        if (!firstConnection) {
            return null;
        }

        return JSON.stringify(firstConnection.usages);
    }

    /**
     * sends message to native iOS components to Health Kit data
     * @param authenticationToken
     */
    public sendThryveIntegrationMessage(authenticationToken: string, selectedUsages: string): void {
        this.sendMessageFromWeb(
            IOSNativeMessages.START_THRYVE_INTEGRATION + '|' + authenticationToken + '|' + selectedUsages,
        );
    }

    /**
     * sends message to native iOS components to disconnect Health Kit data integration
     * @param authenticationToken
     */
    public sendThryveDisconnectMessage(): void {
        this.sendMessageFromWeb(IOSNativeMessages.STOP_THRYVE_INTEGRATION);
    }

    /**
     * sends message to native app for verification of web auth support for users device
     */
    public sendIsUserVerifyingPlatformAuthenticatorAvailableToNativeApp(): void {
        this.sendMessageFromWeb(IOSNativeMessages.WEB_HANKO_IS_PLATFORM_AVAILABLE);
    }

    public createHankoCredential(body: any): Promise<void> {
        // try to send the credential body to the native app for native hanko credential creation
        try {
            this.sendMessageFromWeb(IOSNativeMessages.WEB_HANKO_CREATE_CREDENTIAL + '|' + JSON.stringify(body));
            // once completed, ios will call the window's method 'sendRegistrationToServer'
            return Promise.resolve();
        } catch (e) {
            const msg = `Unable to create credentials`;
            return Promise.reject(`${msg}: ${e}`);
        }
    }

    public useHankoCredential(body: any): Promise<void> {
        // try to get the credentials from the native app
        try {
            this.sendMessageFromWeb(IOSNativeMessages.WEB_HANKO_USE_CREDENTIAL + '|' + JSON.stringify(body));
            // once completed, ios will call the window's method 'sendAuthenticationToServer'
            return Promise.resolve();
        } catch (e) {
            const msg = `Unable to use credentials`;
            return Promise.reject(`${msg}: ${e}`);
        }
    }

    /**
     * sends message from Web Application to iOS native App components
     * @param message
     */
    public sendMessageFromWeb(message: string): void {
        if (!this.isIOSApp) {
            return null;
        }

        try {
            window['webkit'].messageHandlers.postMessageListener.postMessage(message);
        } catch (e) {
            this.notificationService.displayNotification(
                NotificationType.ERROR,
                'ios_message_failure',
                null,
                NotificationService.DURATION_LONG,
            );
        }
    }
}
