export const specialWords = [
    { Genetik: 'Ge-netik' },
    { systolischen: 'systo-lischen' },
    { Wohlbefinden: 'Wohl-befinden' },
    { fettärmere: 'fett-ärmere' },
    { anaerob: 'an-aerob' },
    { anaerob: 'an-ärob' },
    { aerob: 'ärob' },
    { anaerob: 'anärob' },
    { Portion: 'Portsyohn' },
    { Lupinen: 'Lupiienen' },
    { Selen: 'Se-len' },
    { Tempeh: 'Tem-peh' },
    { Seitan: 'Sei-tan' },
    { schematisch: 'sche-matisch' },
    { Nervenzellen: 'Nerfenzellen' },
    { Nerven: 'Nerfen' },
    { Reduktion: 'Redduktion' },
    { diagnostischen: 'diag-nostischen' },
    { Lebensstil: 'Lebenss-tiel' },
    { platzieren: 'plattzieren' },
    { hermetisch: 'hermehtisch' },
    { Wohnraums: 'Wohn-Raums' },
    { Spaziergängen: 'Spazier-Gängen' },
    // newer special characters
    { Hyper: 'Hüper' },
    { 'mmol/l': 'Millimol pro Liter' },
    { insulinfreisetzende: 'insulin-freisetzennde' },
    { 'Dawn-Phänomen': 'Darn-Phänomen' },
    { Moduls: 'Mo-duuls' },
    { Klientinnen: 'Kli-enntinnen' },
    { Kost: 'Kosst' },
    { Stimmungstief: 'Stimmungs-tief' },
    { Mittagstief: 'Mittags-tief' },
    { 'Binge Eating': 'Bintsch Eating' },
    { Reflexion: 'Reffllektion' },
    { trainierbar: 'tre-nierbar' },
    { präprandial: 'prähprandi-ahl' },
    { postprandial: 'postprandi-ahl' },
    { Walnüsse: 'Wallnüsse' },
    { Wohlbefinden: 'Woohl-befinden' },
    { Hormon: 'Hor-mohhn' },
    { Tempeh: 'Temm-peh' },
    { Hilfestelle: 'Hilfe-Stelle' },
    { abebben: 'ab-ebben' },
    { angeregt: 'angereegt' },
    { interaktiv: 'interaktif' },
    { zappeln: 'zapppeln' },
    { Kefir: 'Kehfihr' },
    { motivierendes: 'motiiwiirendes' },
    { breakfast: 'brekkfast' },
    { Kolleginnen: 'Ko-le-ginnnen' },
    { Begleiterscheinungen: 'Begleit-erscheinungen' },
    { letztendlich: 'letzt-endlich' },
    { Zusammenstellung: 'Zusammen-stellung' },
    { spezifische: 'spezih-fische' },
    { hervorgehoben: 'hervor-gehoben' },
    { Hauptanliegen: 'Haupt-anliegen' },
    { Remission: 'Remiss-ion' },
    { verschriebenen: 'versch-riebenen' },
    { Lebensstil: 'Lebens-tiel' },
    { Lebensstiländerung: 'Lebens-tiel-änderung' },
    { Protokolle: 'Prottokolle' },
    { COMISA: 'Comisa' },
    { OSA: 'Osa' },
    { CPAP: 'Cpap' },
];
