import { Locales, TranslationFunctions } from '../../i18n/i18n-types';
import { loadLocale } from '../../i18n/i18n-util.sync';
import { i18nObject } from '../../i18n/i18n-util';

let obj: TranslationFunctions;

export function validationStrings(): TranslationFunctions['validation'] {
    return obj.validation;
}

export function loadValidationLocale(locale: Locales): void {
    loadLocale(locale);
    obj = i18nObject(locale);
}
