import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { CoachingStore, CoachingState } from './coaching.store';
import { ICoaching } from './coaching.model';

@Injectable({
    providedIn: 'root',
})

/**
 * coaching query
 */
export class CoachingQuery extends QueryEntity<CoachingState, ICoaching> {
    /**
     * constructor
     */
    constructor(protected store: CoachingStore) {
        super(store);
    }
}
