import { Injectable } from '@angular/core';
import { SocketService } from '../../socket/socket.service';
import { WeeklyPlannerActions } from '../../../../../../backend/src/modules/coaching/modules/weekly-planner/actions/weekly-planner.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorTranslationKey, NotificationService } from '../../notification/notification.service';
import { NotificationType } from '../../notification/notification-type.enum';
import { IWeeklyPlanner } from './weekly-planner.model';
import { WeeklyPlanner } from '../../../../../../backend/src/modules/coaching/modules/weekly-planner/models/weekly-planner.model';
import { IWeeklyPlannerSettings } from './weekly-planner-settings.model';

@Injectable({
    providedIn: 'root',
})
export class WeeklyPlannerService {
    public settings = new BehaviorSubject<IWeeklyPlannerSettings | undefined>(undefined);

    constructor(private notificationService: NotificationService, private socketService: SocketService) {}

    public create(weeklyPlanner: WeeklyPlanner): Observable<IWeeklyPlanner> {
        return new Observable(observer => {
            this.socketService.fire(WeeklyPlannerActions.CREATE, weeklyPlanner).subscribe(
                weeklyPlan => {
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_exercise_saved');
                    observer.next(weeklyPlan);
                },
                err => {
                    this.notificationService.displayErrorWithCode(err, ErrorTranslationKey.EXERCISE_SAVE_ERROR);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public read(): Observable<IWeeklyPlanner[]> {
        return new Observable(observer => {
            this.socketService.fire(WeeklyPlannerActions.GET, {}).subscribe(
                weeklyPlan => {
                    observer.next(weeklyPlan);
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public update(plan: IWeeklyPlanner): Observable<IWeeklyPlanner> {
        return new Observable(observer => {
            this.socketService.fire(WeeklyPlannerActions.UPDATE, plan).subscribe(
                weeklyPlan => {
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_exercise_saved');
                    observer.next(weeklyPlan);
                },
                err => {
                    observer.error(err);
                    this.notificationService.displayErrorWithCode(err, ErrorTranslationKey.EXERCISE_SAVE_ERROR);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public delete(id: string): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(WeeklyPlannerActions.DElETE, { id }).subscribe(
                weeklyPlan => {
                    observer.next(weeklyPlan);
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public fetchSettings(): Observable<IWeeklyPlannerSettings> {
        return new Observable(observer => {
            this.socketService.fire(WeeklyPlannerActions.GET_SETTINGS, {}).subscribe(
                (settings: IWeeklyPlannerSettings) => {
                    this.settings.next(settings);
                    observer.next(settings);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    public updateSettings(customExercises: string[]): Observable<IWeeklyPlannerSettings> {
        return new Observable(observer => {
            this.socketService.fire(WeeklyPlannerActions.UPDATE_SETTINGS, { customExercises }).subscribe(
                (updatedSettings: IWeeklyPlannerSettings) => {
                    this.settings.next(updatedSettings);
                    observer.next(updatedSettings);
                    observer.complete();
                },
                err => observer.error(err),
            );
        });
    }
}
