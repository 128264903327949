import { AfterViewInit, Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavigationService } from '../navigation/navigation.service';
import { Location } from '@angular/common';
import { SupportComponent } from '../../modules/coaching/support/support.component';
import { MatDialog } from '@angular/material/dialog';
import { LicenseType } from '../../../../../backend/src/shared/modules/licensing/enums/license-type.enum';
import { SupportDigaComponent } from '../../modules/coaching/support/support-diga/support-diga.component';
import { LanguageQuery } from '../state/language/language.query';
import { LicenseService } from '../state/license/license.service';
import { LicenseQuery } from '../state/license/license.query';
import { ILicense } from '../state/license/license.model';
import { AppContextQuery } from '../application/state/app-context.query';
import { Application } from '../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { NavigationState } from '../state/navigation/navigation.store';
import { NavigationQuery } from '../state/navigation/navigation.query';
import { Observable } from 'rxjs';
import { TranslationService } from '../translation/translation.service';
import { ComponentType } from '@angular/cdk/portal';
import { ExpertSupportComponent } from '../../modules/coaching/support/expert-support/expert-support.component';

@Component({
    selector: 'mm-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
    @HostBinding('class.static') isStatic = false;

    @Input() title = '';

    // When false title only shows on scroll.
    @Input() alwaysShowTitle = false;

    @Input() scrollWrapper: any;

    @Input() static = false;

    @Input() white = false;

    public scrollPosition = 0;

    public navigationState$: Observable<NavigationState>;

    get strings() {
        return this.translationService.strings.common;
    }

    get glykioHeaderString() {
        return this.translationService.strings.coaching.supportDialogue.expertSupport.header;
    }

    public glykioApp = Application.GLYKIO;

    constructor(
        private navigationService: NavigationService,
        private location: Location,
        private dialog: MatDialog,
        private languageQuery: LanguageQuery,
        private licenseService: LicenseService,
        private navigationQuery: NavigationQuery,
        private licenseQuery: LicenseQuery,
        private translationService: TranslationService,
        public appContextQuery: AppContextQuery,
    ) {}

    ngOnInit(): void {
        if (this.static) {
            this.isStatic = true;
        }

        this.navigationState$ = this.navigationQuery.select();
    }

    public ngAfterViewInit(): void {
        if (this.scrollWrapper) {
            this.scrollWrapper.addEventListener('scroll', event => {
                this.scrollPosition = event.target.scrollTop;
            });
        }
    }

    public toggleNavigation(): void {
        this.navigationService.toggleNavigation();
    }

    public reportProblem(): void {
        let componentToOpen: ComponentType<SupportComponent | SupportDigaComponent>;
        const license = this.licenseQuery.getActive() as ILicense;
        const application = this.appContextQuery.getValue().application;

        if (
            (application === Application.SOMNIO && this.licenseService.isDigaLicense(license)) ||
            license.licenseType === LicenseType.ADMIN ||
            application === Application.ACTENSIO ||
            application === Application.GLYKIO
        ) {
            componentToOpen = SupportDigaComponent;
        } else {
            componentToOpen = SupportComponent;
        }
        this.dialog.open(componentToOpen, {
            autoFocus: false,
            maxWidth: '600px',
        });
    }

    public contactExpert(): void {
        this.dialog.open(ExpertSupportComponent, {
            autoFocus: false,
            maxWidth: '600px',
        });
    }
}
