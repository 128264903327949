import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactSupportBody } from '../../../../../../../backend/src/shared/modules/support/models/contact-support-body';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppContextQuery } from '../../../../shared/application/state/app-context.query';
import { SupportService } from '../state/support.service';
import { LanguageQuery } from '../../../../shared/state/language/language.query';
import { Observable } from 'rxjs';
import { Language } from '../../../../shared/state/language/language.model';
import { LanguageCode } from '../../../../../../../backend/src/shared/modules/language/enums/language.enum';
import { Application } from '../../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { TranslationService } from '../../../../shared/translation/translation.service';

export enum ViewType {
    PRE = 'pre',
    TECHNICAL_ISSUES = 'technicalIssues',
    WARNING = 'warning',
    MESSAGE_SENT = 'messageSent',
}

@Component({
    selector: 'mm-support-diga',
    templateUrl: './support-diga.component.html',
    styleUrls: ['./support-diga.component.sass'],
})
export class SupportDigaComponent implements OnInit {
    public Application = Application;

    public view: ViewType = ViewType.PRE;

    public isSending: boolean = false;

    public supportDigaForm: UntypedFormGroup;

    public ViewType = ViewType;

    private breadcrumbs: ViewType[] = [ViewType.PRE];

    public showHint: boolean;

    public faqLink: string = 'https://support.mementor.de/help/en-gb/16-somnio';

    public application: Application;

    public faqs: { link: string; question: string }[];

    get text(): AbstractControl {
        return this.supportDigaForm.get('text');
    }

    get strings() {
        return this.translationService.strings.coaching.supportDialogue;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private deviceService: DeviceDetectorService,
        private supportService: SupportService,
        private languageQuery: LanguageQuery,
        private appContextQuery: AppContextQuery,
        private translationService: TranslationService,
    ) {}

    ngOnInit(): void {
        // setup form
        this.supportDigaForm = this.formBuilder.group({
            text: ['', Validators.required],
        });

        (this.languageQuery.selectActive() as Observable<Language>).subscribe((language: Language) => {
            this.showHint = language.code === LanguageCode.FR_CH;

            if (language.code === LanguageCode.DE_CH || language.code === LanguageCode.FR_CH) {
                this.faqLink = 'https://support.mementor.de/help/de-de/16-somnio';
            }
        });

        this.application = this.appContextQuery.getValue().application;
        this.initFAQs();
    }

    initFAQs(): void {
        const faqs: { link: string; question: string }[] = [];
        const appStrings = this.strings[this.application].faqs;
        for (const key in appStrings) {
            faqs.push({
                link: appStrings[key].link() as string,
                question: appStrings[key].question() as string,
            });
        }
        this.faqs = faqs;
    }

    public noticeBreadcrumb(view: ViewType): void {
        this.breadcrumbs.push(view);
        this.view = view;
    }

    public sendRequest(): void {
        if (this.supportDigaForm.valid) {
            // set isSending to true
            this.isSending = true;
            const contactSupportBody: ContactSupportBody = {
                deviceInfo: this.deviceService.getDeviceInfo(),
                isMobile: this.deviceService.isMobile(),
                isTablet: this.deviceService.isTablet(),
                isDesktop: this.deviceService.isDesktop(),
                userQuestion: this.text.value as string,
                application: this.application,
            };

            this.supportService.contactSupport(contactSupportBody).subscribe(
                () => {
                    this.view = ViewType.MESSAGE_SENT;
                    this.isSending = false;
                },
                error => {
                    this.isSending = false;
                },
            );
        } else {
            this.supportDigaForm.markAllAsTouched();
        }
    }

    public backButtonClicked(): void {
        this.breadcrumbs.pop();
        this.view = this.breadcrumbs[this.breadcrumbs.length - 1];
    }

    public onLeaveDigaConfirmed(): void {
        window.open(`https://my.calenso.com/book/mementor/${this.application}-support`);
    }
}
