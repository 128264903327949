import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Language } from './language.model';

export interface State extends EntityState<Language> {}

@Injectable({
    providedIn: 'root',
})
/**
 * @deprecated query should not be used anymore
 */
@StoreConfig({ name: 'languages', idKey: '_id' })
export class LanguageStore extends EntityStore<State, Language> {
    /**
     * constructor
     */
    constructor() {
        super();
    }
}
