import { Injectable } from '@angular/core';
import { I18nService } from '../../../i18n/i18n.service';
import { baseLocale, detectLocale, loadedLocales, locales, namespaces } from '../../../i18n/i18n-util';
import { localStorageDetector, navigatorDetector } from 'typesafe-i18n/detectors';
import { Locales, Namespaces } from '../../../i18n/i18n-types';
import { loadLocaleAsync, loadNamespaceAsync } from '../../../i18n/i18n-util.async';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getFormatters } from '../../../../../backend/src/i18n/formatters';
import { loadValidationLocale } from '../../../../../backend/src/shared/validation/translation';
import { AppContextQuery } from '../application/state/app-context.query';
import { Application } from '../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { AuthQuery } from '../state/auth/auth.query';
import { LanguageService } from '../language/language.service';
import { Region } from '../../../../../backend/src/shared/entities/region.enum';
import { isCurrentRegionObservable$ } from '../region-helper/region.helper';
import { KeyListenerService, MMKeyEventEnum, MMSpecialKeyEnum } from '../key-listener/key-listener.service';
import { ConfigService } from '../config/config.service';

export type LanguageCode = Locales;
export type I18nNamespace = Namespaces;

@UntilDestroy()
@Injectable()
export class TranslationService {
    constructor(
        private i18nService: I18nService,
        private authQuery: AuthQuery,
        private appContextQuery: AppContextQuery,
        private configService: ConfigService,
        private languageService: LanguageService,
        private keyListenerService: KeyListenerService,
    ) {
        isCurrentRegionObservable$()(Region.OCEANIC).subscribe(isOceanic => {
            if (isOceanic) {
                this.disabledLanguageCodesByApp.somnio = ['it-IT'];
            }
        });
    }

    static defaultLanguageCode = baseLocale;

    private disabledLanguageCodesByApp: Record<Application, LanguageCode[]> = {
        [Application.SOMNIO]: ['it-IT', 'en-AU'],
        [Application.ACTENSIO]: ['it-IT', 'fr-FR', 'en-US', 'en-AU'],
        [Application.GLYKIO]: ['it-IT', 'fr-FR', 'en-US', 'en-AU'],
        [Application.MALIO]: ['it-IT', 'fr-FR', 'en-US', 'en-AU'],
        [Application.SOMNIO_JUNIOR]: ['it-IT', 'fr-FR', 'en-US', 'en-AU'],
    };

    private keyByLanguage: Record<LanguageCode, MMKeyEventEnum> = {
        ['de-DE']: MMKeyEventEnum.D_1,
        ['en-US']: MMKeyEventEnum.D_2,
        ['fr-FR']: MMKeyEventEnum.D_3,
        ['en-AU']: MMKeyEventEnum.D_4,
        ['it-IT']: MMKeyEventEnum.D_5,
    };

    private defaultLanguageByRegion: Record<Region, LanguageCode> = {
        [Region.OCEANIC]: 'en-AU',
        [Region.DEFAULT]: TranslationService.defaultLanguageCode,
    };
    private regionDefaultOverridesBrowserLanguage: Record<Region, boolean> = {
        [Region.OCEANIC]: true,
        [Region.DEFAULT]: false,
    };

    get enabledLanguageCodes(): LanguageCode[] {
        const disabledLanguageCodes = this.disabledLanguageCodesByApp[this.appContextQuery.getValue().application];
        return locales.filter(l => !disabledLanguageCodes?.includes(l));
    }

    get activeLanguageCode(): LanguageCode {
        return this.i18nService.locale;
    }

    get strings() {
        return this.i18nService.LL;
    }

    get formatters() {
        return getFormatters(this.activeLanguageCode);
    }

    private static detectBrowserLanguageCode(): LanguageCode {
        return detectLocale(localStorageDetector, navigatorDetector);
    }

    // these conversion functions will be needed during the migration of LanguageCode formats
    public static convertToNewLanguageCode(code: string): LanguageCode {
        if (code === 'en_au') {
            return 'en-AU';
        }

        switch (code.substring(0, 2)) {
            case 'de':
                return 'de-DE';
            case 'en':
                return 'en-US';
            case 'fr':
                return 'fr-FR';
            case 'it':
                return 'it-IT';
            default:
                return TranslationService.defaultLanguageCode;
        }
    }

    public static convertToOldLanguageCode(code: string): string {
        if (code === 'en-AU') {
            return 'en_au';
        }

        switch (code.substring(0, 2)) {
            case 'de':
                return 'de_ch';
            case 'en':
                return 'en_us';
            case 'fr':
                return 'fr_ch';
            case 'it':
                return 'it_ch';
            default:
                return 'de_ch';
        }
    }

    public async init(): Promise<void> {
        const region = this.appContextQuery.getValue().region;
        const detectedLanguageCode = TranslationService.detectBrowserLanguageCode();
        const defaultLanguageCode = this.defaultLanguageByRegion[region];
        const mustUseDefault =
            this.regionDefaultOverridesBrowserLanguage[region] ||
            !this.enabledLanguageCodes.includes(detectedLanguageCode);

        const languageToLoad = mustUseDefault ? defaultLanguageCode : detectedLanguageCode;

        await this.loadLanguage(languageToLoad);

        this.keepInSyncWithUserLanguage();

        if (this.configService.isDevEnvironment()) {
            this.updateLanguageOnKeyPress();
        }
    }

    private keepInSyncWithUserLanguage(): void {
        this.authQuery
            .select()
            .pipe(untilDestroyed(this))
            .subscribe(async authState => {
                if (!authState.language) {
                    return;
                }

                const savedUserLanguageCode = TranslationService.convertToNewLanguageCode(authState.language);
                if (!this.enabledLanguageCodes.includes(savedUserLanguageCode)) {
                    return;
                }

                await this.changeLanguage(savedUserLanguageCode);
            });
    }

    private async loadLanguage(newCode: LanguageCode): Promise<void> {
        await loadLocaleAsync(newCode);
        loadValidationLocale(newCode);
        this.i18nService.setLocale(newCode);

        // backwards compatibility with LanguageService
        this.languageService.setActiveLanguageByCode(TranslationService.convertToOldLanguageCode(newCode));
    }

    public async changeLanguage(newCode: LanguageCode): Promise<void> {
        if (newCode === this.activeLanguageCode) {
            return;
        }

        const oldLoadedNamespaces = this.getLoadedNamespaces();

        await this.loadLanguage(newCode);

        const newLoadedNamespaces = this.getLoadedNamespaces();

        for (const namespace of oldLoadedNamespaces) {
            if (!newLoadedNamespaces.includes(namespace)) {
                await this.loadNamespace(namespace as I18nNamespace);
            }
        }
    }

    public async loadNamespace(namespace: I18nNamespace): Promise<void> {
        await loadNamespaceAsync(this.activeLanguageCode, namespace);
        this.i18nService.setLocale(this.activeLanguageCode);
    }

    private getLoadedNamespaces(): I18nNamespace[] {
        const loadedTranslations = loadedLocales[this.activeLanguageCode];
        return Object.keys(loadedTranslations ?? {}).filter(k =>
            namespaces.includes(k as I18nNamespace),
        ) as I18nNamespace[];
    }

    private updateLanguageOnKeyPress(): void {
        for (const [languageCode, keyEvent] of Object.entries(this.keyByLanguage)) {
            this.keyListenerService.listenForKeyDown(keyEvent, [MMSpecialKeyEnum.ALT]).subscribe(() => {
                this.changeLanguage(languageCode as Locales).then();
            });
        }
    }
}
