import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import lottie from 'lottie-web';
import { LottieService } from './lottie.service';
import { ConfigService } from '../config/config.service';
import { Application } from '../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { LoggingTool } from '../../../tools/logging/contract';

@Component({
    selector: 'mm-lottie',
    templateUrl: './lottie.component.html',
    styleUrls: ['./lottie.component.sass'],
})

/**
 * lottie component
 */
export class LottieComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * holds a reference to the animation container
     */
    @ViewChild('animationContainer') animationContainer: ElementRef;

    /**
     * loaded event emitter
     */
    @Output() loaded: EventEmitter<any> = new EventEmitter<any>();

    /**
     * ended event emitter
     */
    @Output() ended: EventEmitter<any> = new EventEmitter<any>();

    /**
     * setter to play animations
     */
    @Input('play') set play(play: boolean) {
        if (play) {
            if (this.player) {
                this.player.play();
            } else {
                this.playOnLoad = true;
            }
        } else {
            if (this.player) {
                this.player.pause(0);
            }
        }
    }

    /**
     * animation name
     */
    @Input('name') set setName(name: string) {
        if (this.isDevEnvironment && name == null) {
            this.loggingTool.debug(`Setting undefined lottie name - was ${this.name}`);
        }
        if (this.name !== name && this.animationContainer) {
            this.name = name;
            this.loadLottieAnimation();
        } else {
            this.name = name;
        }
    }

    /**
     * define if asset (changes loading location)
     */
    @Input() isAsset;

    /**
     * autoplay
     */
    @Input() autoplay = true;

    /**
     * autoplay
     */
    @Input() loop = false;

    /**
     * boolean
     */
    @Input() interaction = false;

    /**
     * frame to loop
     */
    @Input() loopFrame: number;

    /**
     * coordinates for the gradient
     */
    @Input() gradientCoordinates: {
        x1: string;
        y1: string;
        x2: string;
        y2: string;
    };

    /**
     * animation ready state
     */
    public ready = false;

    /**
     * name
     */
    private name: string = null;

    /**
     * player
     */
    private player: any;

    /**
     * play on load
     */
    private playOnLoad: boolean;

    /**
     * check whether we are in a development environment
     */
    public isDevEnvironment: boolean;
    constructor(
        private ngZone: NgZone,
        private lottieService: LottieService,
        private configService: ConfigService,
        private loggingTool: LoggingTool,
    ) {
        this.isDevEnvironment = this.configService.isDevEnvironment();
    }

    /**
     * called on init
     */
    ngOnInit(): void {}

    /**
     * called after view init
     */
    ngAfterViewInit(): void {
        if (this.isDevEnvironment && this.name == null) {
            this.loggingTool.debug(`Loading lottie animation after init`);
        }
        this.loadLottieAnimation();
    }

    /**
     * called on component destruction
     */
    ngOnDestroy(): void {
        if (this.player) {
            this.player.destroy();
        }
    }

    /**
     * loads a lottie animation
     */
    public loadLottieAnimation(): void {
        if (this.player) {
            this.ready = false;
            this.player.destroy();
        }

        if (this.name == null) {
            this.loggingTool.error(`Trying to load lottie animation with undefined name`);
            return;
        }

        let path = this.configService.BASE_URL;

        if (this.isAsset) {
            path += `/assets/lottie/${this.name}.json`;
        } else {
            // TODO remove hardcoded clientId
            path += `/public/uploads/5d7a81c4f7bb54302067e087/lottie/${this.name}.json`;
        }

        lottie.setLocationHref(document.location.href);

        this.player = lottie.loadAnimation({
            container: this.animationContainer.nativeElement, // the dom element that will contain the animation
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            path: path,
        });

        this.player.addEventListener('DOMLoaded', () => {
            // todo: check if gradients need to be injected
            // inject gradients
            this.injectGradients(Application.SOMNIO);
            this.injectGradients(Application.ACTENSIO);
            this.injectGradients(Application.GLYKIO);
            this.injectGradients(Application.MALIO);
            this.injectGradients('sbk');

            this.ngZone.run(() => {
                this.ready = true;
            });

            this.lottieService.localiseTextLayers(this.player);

            if (this.loaded) {
                this.loaded.emit(this.player);
            }

            if (this.playOnLoad) {
                this.player.play();
            }
        });

        this.player.addEventListener('complete', () => {
            if (this.loopFrame) {
                this.player.goToAndPlay(this.loopFrame, true);
            }
        });
    }

    /**
     * mouse enter
     */
    public onMouseEnter(): void {
        if (this.interaction) {
            this.player.play();
        }
    }

    /**
     * mouse enter
     */
    public onMouseLeave(): void {
        if (this.interaction) {
            this.player.goToAndStop(0);
        }
    }

    /**
     * inject gradients
     * @param theme
     * @private
     */
    private injectGradients(theme: Application | string): void {
        const defs = this.animationContainer.nativeElement.children[1].children[0];
        const xmlns = 'http://www.w3.org/2000/svg';

        const linearGradient = document.createElementNS(xmlns, 'linearGradient');
        linearGradient.setAttributeNS(null, 'id', theme + '-gradient');
        linearGradient.setAttributeNS(null, 'spreadMethod', 'pad');
        linearGradient.setAttributeNS(null, 'gradientUnits', 'userSpaceOnUse');
        linearGradient.setAttributeNS(null, 'x1', this.gradientCoordinates?.x1 ?? '-240');
        linearGradient.setAttributeNS(null, 'y1', this.gradientCoordinates?.y1 ?? '-222');
        linearGradient.setAttributeNS(null, 'x2', this.gradientCoordinates?.x2 ?? '284');
        linearGradient.setAttributeNS(null, 'y2', this.gradientCoordinates?.x2 ?? '200');
        linearGradient.setAttribute('width', '100%');
        linearGradient.setAttribute('height', '100%');

        const stopA = document.createElementNS(xmlns, 'stop');
        let stopAColor = '';
        let stopBColor = '';
        switch (theme) {
            case Application.SOMNIO:
                stopAColor = '#6EB9BC';
                stopBColor = '#736588';
                break;
            case Application.ACTENSIO:
                stopAColor = '#E68686';
                stopBColor = '#F7D2A1';
                break;
            case Application.GLYKIO:
                stopAColor = '#755981';
                stopBColor = '#7f80bb';
                break;
            case Application.MALIO:
                stopAColor = '#749eb5';
                stopBColor = '#755981';
                break;
            case 'sbk':
                stopAColor = '#003c6e';
                stopBColor = '#28567d';
                break;
        }
        stopA.setAttributeNS(null, 'offset', '0%');
        stopA.setAttributeNS(null, 'stop-color', stopAColor);

        const stopB = document.createElementNS(xmlns, 'stop');
        stopB.setAttributeNS(null, 'offset', '100%');
        stopB.setAttributeNS(null, 'stop-color', stopBColor);

        linearGradient.appendChild(stopA);
        linearGradient.appendChild(stopB);

        defs.appendChild(linearGradient);
    }
}
