import { Injectable } from '@angular/core';
import { NavigationQuery } from '../state/navigation/navigation.query';
import { NavigationStore } from '../state/navigation/navigation.store';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()

/**
 * navigation service
 * */
export class NavigationService {
    /**
     * constructor
     */
    constructor(
        private navigationQuery: NavigationQuery,
        private breakpointObserver: BreakpointObserver,
        private navigationStore: NavigationStore,
    ) {}

    /**
     * toggle the the menu
     */
    public toggleNavigation(): void {
        this.navigationStore.update({ open: !this.navigationQuery.getValue().open });
    }

    /**
     * sidenav has been opened externally
     */
    public opened(): void {
        this.navigationStore.update({ open: true });
    }

    /**
     * sidenav has been closed externally
     */
    public closed(): void {
        this.navigationStore.update({ open: false });
    }

    /**
     * observer layout
     */
    public observeLayout(): void {
        // observe to layout changes
        const breakpointObserver = this.breakpointObserver.observe(['(max-width: 699px)']);

        breakpointObserver.subscribe(result => {
            // small screen
            if (result.matches) {
                // change side navigation mode
                this.navigationStore.update({ mode: 'push', open: false, mobile: true });
            }
            // large screen
            else {
                // change navigation mode
                this.navigationStore.update({ mode: 'side', open: true, mobile: false });
            }
        });
    }
}
