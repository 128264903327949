<mm-dialog-header [title]="strings.header()"></mm-dialog-header>

<mat-dialog-content>
    <div style="text-align: center">
        <p [innerHTML]="strings.body()"></p>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-raised-button
        style="display: block; margin: auto"
        color="primary"
        (click)="onGotItClick()"
        type="button"
    >
        <span [innerHTML]="commons.general.gotIt()"></span>
    </button>
</mat-dialog-actions>
