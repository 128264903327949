import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocketService } from '../socket/socket.service';
import { RoleStore } from './state/role/role.store';
import { IRole } from './state/role/role.model';
import { ID } from '@datorama/akita';
import { RoleActions } from '../../../../../backend/src/shared/modules/security/actions/role.actions';
import { RoleName } from '../../../../../backend/src/shared/modules/security/enums/security-roles.enum';
import { AddRoleBody } from '../../../../../backend/src/shared/modules/security/models/add-role-body';
import { NotificationService } from '../notification/notification.service';
import { NotificationType } from '../notification/notification-type.enum';

@Injectable()

/**
 * role service
 */
export class RoleService {
    /**
     * constructs a Socket Service
     */
    constructor(
        private socketService: SocketService,
        private notificationService: NotificationService,
        private roleStore: RoleStore,
    ) {}

    /**
     * gets the role of the socket user
     */
    public getForSocketUser(): Observable<IRole[]> {
        return new Observable(observer => {
            this.socketService.fire(RoleActions.GET_FOR_SOCKET_USER, null).subscribe(
                (roles: IRole[]) => {
                    this.roleStore.set(roles);
                    observer.next(roles);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    /**
     * gets the role of a single user by id
     */
    public getForUser(userId: ID): Observable<IRole[]> {
        return new Observable(observer => {
            this.socketService.fire(RoleActions.GET_FOR_USER, userId).subscribe(
                (roles: IRole[]) => {
                    observer.next(roles);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    /**
     * remove role from user
     * @param userId
     * @param roleName
     */
    public addRole(userId: string, roleName: RoleName) {
        return new Observable(observer => {
            // declare add role body
            const addRoleBody: AddRoleBody = { userId: userId, roleName: roleName };

            this.socketService.fire(RoleActions.ADD, addRoleBody).subscribe(
                (roles: IRole[]) => {
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_role_added');
                    observer.next(roles);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_role_added_error',
                    );
                    observer.error(err);
                },
            );
        });
    }

    /**
     * remove role from user
     * @param roleId
     */
    public removeRole(roleId: string) {
        return new Observable(observer => {
            this.socketService.fire(RoleActions.REMOVE, roleId).subscribe(
                (roles: IRole[]) => {
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_role_removed');
                    observer.next(roles);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_role_removed_error',
                    );
                    observer.error(err);
                },
            );
        });
    }
}
