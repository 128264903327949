import { Injectable } from '@angular/core';
import { SocketService } from '../../socket/socket.service';
import { IModule } from './module.model';
import { ModuleStore } from './module.store';
import { forkJoin, from, Observable } from 'rxjs';
import { ICoaching } from '../coaching/coaching.model';
import { applyTransaction, ID } from '@datorama/akita';
import { CoachingQuery } from '../coaching/coaching.query';
import { NotificationType } from '../../notification/notification-type.enum';
import { NotificationService } from '../../notification/notification.service';
import { ModuleActions } from '../../../../../../backend/src/modules/coaching/actions/module.actions';
import { ModuleQuery } from './module.query';
import { switchMap } from 'rxjs/operators';
import { DynamicModuleMeta } from '../coaching/dynamic-module.meta';
import { FlowHandling } from '../../../../../../backend/src/modules/coaching/enums/flow-handling.enum';
import { CopyModuleToCoachingDto } from '../../../../../../backend/src/modules/coaching/dtos/copy-module-to-coaching.dto';
import { LoggingTool } from '../../../../tools/logging/contract';

declare let ss;

@Injectable()
export class ModuleService {
    constructor(
        private socketService: SocketService,
        private notificationService: NotificationService,
        private coachingQuery: CoachingQuery,
        private moduleQuery: ModuleQuery,
        private moduleStore: ModuleStore,
        private loggingTool: LoggingTool,
    ) {}

    public getModules(activeModuleId?: string): Observable<IModule[]> {
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.GET_ALL, {}).subscribe(
                (modules: IModule[]) => {
                    applyTransaction(() => {
                        this.moduleStore.set(modules);

                        if (modules && modules.length > 0) {
                            if (!!activeModuleId && this.moduleQuery.getEntity(activeModuleId)) {
                                this.moduleStore.setActive(activeModuleId);
                            } else {
                                this.moduleStore.setActive(modules[0]._id);
                            }
                        }
                    });
                    observer.next(modules);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public loadModulesForActiveCoaching(): Observable<IModule[]> {
        return new Observable(observer => {
            const activeCoaching: ICoaching = this.coachingQuery.getActive() as ICoaching;
            this.socketService.fire(ModuleActions.GET_FOR_COACHING, activeCoaching._id).subscribe(
                (modules: IModule[]) => {
                    applyTransaction(() => {
                        this.moduleStore.set(modules);

                        if (modules && modules.length > 0) {
                            this.moduleStore.setActive(modules[0]._id);
                        }
                    });

                    observer.next(modules);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public loadModuleById(moduleId: ID): Observable<IModule> {
        this.moduleStore.setLoading(true);
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.GET_BY_ID, moduleId).subscribe(
                (module: IModule) => {
                    this.moduleStore.setLoading(false);

                    applyTransaction(() => {
                        this.moduleStore.upsert(module._id, module);
                        this.moduleStore.setActive(module._id);
                    });

                    observer.next(module);
                    observer.complete();
                },
                err => {
                    this.moduleStore.setLoading(false);
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public createModule(module: IModule): Observable<IModule> {
        this.moduleStore.setLoading(true);
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.CREATE, module).subscribe({
                next: (savedModule: IModule) => {
                    this.moduleStore.add(savedModule);

                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_module_saved');

                    observer.next(savedModule);
                    observer.complete();
                },
                error: err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_module_saved_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                complete: () => {
                    this.moduleStore.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    public updateModule(module: IModule): Observable<IModule> {
        this.moduleStore.setLoading(true);
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.UPDATE, module).subscribe({
                next: (savedModule: IModule) => {
                    this.moduleStore.update(savedModule._id, savedModule);

                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_module_saved');

                    observer.next(savedModule);
                    observer.complete();
                },
                error: err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_module_saved_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                complete: () => {
                    this.moduleStore.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    public removeModule(module: IModule): Observable<IModule> {
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.REMOVE, module._id).subscribe(
                () => {
                    this.moduleStore.remove(module._id);

                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_module_removed');
                    observer.next(module);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_module_removed_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * creates a new version of a module
     * @param module to create new version of
     */
    public createNewVersion(module: IModule): Observable<IModule> {
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.CREATE_NEW_VERSION, module._id).subscribe(
                (newVersion: IModule) => {
                    this.moduleStore.add(newVersion, { prepend: true });
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_version_created');
                    observer.next(newVersion);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_version_created_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * publishes a module
     * @param module to create new version of
     */
    public publish(module: IModule, flowHandling: FlowHandling): Observable<IModule> {
        return new Observable(observer => {
            this.socketService
                .fire(ModuleActions.PUBLISH, {
                    moduleId: module._id,
                    flowHandling,
                })
                .subscribe(
                    (publishedModule: IModule) => {
                        this.moduleStore.update(module._id, publishedModule);
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_module_published',
                        );
                        observer.next(module);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_module_published_error',
                        );
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    /**
     * locks a module
     * @param module to create new version of
     * @param force
     */
    public lock(module: IModule, force: boolean = false): Observable<IModule> {
        return new Observable(observer => {
            this.socketService
                .fire(ModuleActions.LOCK, {
                    moduleId: module._id,
                    force: force,
                })
                .subscribe(
                    (lockedModule: IModule) => {
                        this.moduleStore.update(module._id, {
                            locked: lockedModule.locked,
                            editor: lockedModule.editor,
                        });
                        observer.next(module);
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    /**
     * locks a module
     * @param module to create new version of
     * @param force unlock
     */
    public unlock(module: IModule, force: boolean = false): Observable<IModule> {
        return new Observable(observer => {
            if (!module) {
                observer.next(null);
                observer.complete();
                return;
            }

            this.socketService.fire(ModuleActions.UNLOCK, module._id).subscribe(
                (unlockedModule: IModule) => {
                    this.moduleStore.update(module._id, {
                        locked: unlockedModule.locked,
                        editor: unlockedModule.editor,
                    });
                    observer.next(module);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public uploadFiles(module: IModule, files: any): Observable<any> {
        // get an array of objects
        const saveSummary$: Observable<IModule>[] = [];
        from(Object.keys(files).map(id => ({ file: files[id], language: id })))
            .pipe(
                switchMap((file: any) => {
                    const uploadSummary$ = this.uploadSummary(module, file);
                    saveSummary$.push(uploadSummary$);
                    return uploadSummary$;
                }),
            )
            .subscribe();

        // return observable
        return forkJoin(saveSummary$);
        // return uploadFiles$;
    }

    public uploadSummary(module: IModule, file: any): Observable<IModule> {
        return new Observable(observer => {
            if (!file.file) {
                observer.next();
                observer.complete();
                return;
            }
            // create the stream
            const stream = ss.createStream();

            // create blob stream
            const blobStream = ss.createBlobReadStream(file.file[0]);
            let size = 0;

            // send to pipe
            blobStream.pipe(stream);

            // upload a file to the server.
            ss(this.socketService.socket).emit(
                ModuleActions.UPLOAD_SUMMARY,
                stream,
                {
                    module: module,
                    language: file.language,
                    file: {
                        name: file.file[0].name,
                        type: file.file[0].type,
                        size: file.file[0].size,
                    },
                },
                err => {
                    // all good
                    if (!err) {
                        observer.next();
                        observer.complete();
                    } else {
                        observer.error(err);
                    }
                },
            );

            // listen to stream progress events
            blobStream.on('data', chunk => {
                // compute size
                size += chunk.length;
            });
            blobStream.on('finish', () => {});

            blobStream.on('error', err => {
                observer.error(err);
            });
        });
    }

    /**
     * returns coaching keys of coachings that contain the giving module id.
     * in other words check if module is already in flow manager (Modulfluss)
     * @param moduleId
     */
    public checkIfActiveModuleIsInCoaching(moduleId: ID): Observable<string[]> {
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.CHECK_IF_MODULE_IS_IN_COACHING, moduleId).subscribe(
                keys => {
                    observer.next(keys);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public extractDynamicModulesFromCoaching(coaching: ICoaching): DynamicModuleMeta[] {
        const dynamicModuleMetas: DynamicModuleMeta[] = [];
        for (const moduleMeta of coaching.modules) {
            if (moduleMeta.key.toLowerCase().includes('compass') && moduleMeta.completed) {
                continue;
            }

            if (!moduleMeta.parentMetaModuleId) {
                dynamicModuleMetas.push({
                    ...moduleMeta,
                    subModules: [],
                });
            }
        }

        for (const moduleMeta of coaching.modules) {
            if (!moduleMeta.parentMetaModuleId) {
                continue;
            }

            const moduleWithParent = dynamicModuleMetas.find(m => m._id === moduleMeta.parentMetaModuleId);
            if (!moduleWithParent) {
                this.loggingTool.error('Could not found a parent module');
            }
            moduleWithParent.subModules.push(moduleMeta);
        }

        return dynamicModuleMetas;
    }

    public getModuleNameById(moduleId: string): Observable<string> {
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.GET_NAME_BY_ID, moduleId).subscribe(
                (module: string) => {
                    observer.next(module);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public copyToModule(moduleId: string, coachingKey: string, prefix: string): Observable<IModule> {
        this.moduleStore.setLoading(true);
        return new Observable(observer => {
            this.socketService
                .fire(ModuleActions.COPY_TO_COACHING, { moduleId, coachingKey, prefix } as CopyModuleToCoachingDto)
                .subscribe(
                    (module: IModule) => {
                        this.moduleStore.setLoading(false);
                        observer.next(module);
                        observer.complete();
                    },
                    err => {
                        this.moduleStore.setLoading(false);
                        this.loggingTool.error(err);
                        this.notificationService.displayError(err.text);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }
}
