import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false,
})
export class FilterPipe implements PipeTransform {
    transform<T>(items: T[], predicate: (item: T, index?: number) => boolean): T[] {
        return items.filter(predicate);
    }
}
