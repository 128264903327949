/**
 * unit types
 */
export enum Numerus {
    ANSWER_FROM_SELECTION = 'answerFromSelection',
    BLOOD_PRESSURE = 'bloodPressure',
    CIGARETTES = 'cigarettes',
    DAY = 'day',
    ENTRIES = 'entries',
    EURO = 'euro',
    GLASS = 'glass',
    MINUTE = 'minute_minutes',
    PERCENT = 'percent',
    PERCENTAGE_POINT = 'percentagePoint',
    PIECE = 'piece_pieces',
    POINT = 'point',
    PORTION = 'portion_portions',
    PULSE = 'pulse',
    WEEK = 'week_weeks',
    WHOLE_NUMBER = 'wholeNumber',
    STEPS = 'steps',
    KILOGRAM = 'kilogram',
    MGDL = 'mgdl',
    MMOL = 'MMOL',
}
