import { Pipe, PipeTransform } from '@angular/core';
import { IModule } from '../state/module/module.model';

@Pipe({
    name: 'coachings',
})

/**
 * coachings pipe only returns modules that are assigned to a certain coaching by key
 */
export class CoachingsPipe implements PipeTransform {
    /**
     * transform
     */
    transform(modules: IModule[], args?: any): any {
        // prevent missing args
        if (!args || args.length === 0) {
            return modules;
        }

        const coachingKey = args[0]?.key;

        if (coachingKey) {
            return modules.filter((module: IModule) => module.coachings.includes(coachingKey));
        }

        return modules;
    }
}
