import { BaseTranslation } from '../i18n-types';
import * as common from './common.json';
import * as email from './email.json';
import * as error from './error.json';
import * as validation from './validation.json';
import * as notification from './notification.json';
import * as report from './report.json';

const tr: BaseTranslation = { common, email, error, validation, notification, report };

export default tr;
