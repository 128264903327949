export enum Application {
    ACTENSIO = 'actensio',
    GLYKIO = 'glykio',
    MALIO = 'malio',
    SOMNIO = 'somnio',
    SOMNIO_JUNIOR = 'somnioJunior',
}

export const nameByApplication: Record<Application, string> = {
    [Application.ACTENSIO]: 'actensio',
    [Application.GLYKIO]: 'glykio',
    [Application.SOMNIO]: 'somnio',
    [Application.MALIO]: 'malio',
    [Application.SOMNIO_JUNIOR]: 'somnio junior',
};

export const domainByApplication: Record<Application, string> = {
    [Application.ACTENSIO]: 'actens.io',
    [Application.GLYKIO]: 'glyk.io',
    [Application.SOMNIO]: 'somn.io',
    [Application.MALIO]: 'mal.io',
    [Application.SOMNIO_JUNIOR]: 'junior-somn.io',
};
