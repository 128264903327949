import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HypertensionJournalEntryService } from '../hypertension-journal/state/hypertension-journal-entry/hypertension-journal-entry.service';
import { SocketService } from '../../../shared/socket/socket.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'mm-nutrition-protocol',
    templateUrl: './nutrition-protocol.component.html',
    styleUrls: ['./nutrition-protocol.component.sass'],
})
export class NutritionProtocolComponent implements OnInit {
    public isInStep: boolean;
    @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
    public nutritionEvaluation$: Observable<unknown>;

    constructor(
        private hypertensionJournalEntryService: HypertensionJournalEntryService,
        private route: ActivatedRoute,
        private socketService: SocketService,
    ) {}

    ngOnInit(): void {
        this.getNutritionAverages(7);
        this.isInStep = this.route.snapshot.routeConfig.path !== 'nutrition-protocol';
    }

    public getValue(event: Event): number {
        return (event.target as HTMLInputElement).valueAsNumber;
    }

    public onWeeksBackChange(days: number): void {
        this.getNutritionAverages(days);
    }

    private getNutritionAverages(weeksBack: number): void {
        this.nutritionEvaluation$ = this.hypertensionJournalEntryService.getAverageNutritionValues(weeksBack);
    }

    public proceedToNextStep(): void {
        this.saved.emit(true);
    }
}
