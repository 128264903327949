<div class="weekly-day-wrapper">
    <div class="day" [class.primary-color]="isFirstItem">{{ formatDay(day) }}</div>
    <div>
        <div class="dot" [class.primary-background-color]="isFirstItem"></div>
        <div class="vertical-line" [style.height]="isLastItem && '60px'"></div>
    </div>
    <div class="items-wrapper">
        <div class="day-plan" *ngFor="let plan of dayPlans">
            <mat-card
                appearance="outlined"
                class="plan-item"
                [ngClass]="{
                    'next-module': plan.weeklyPlannerType === WeeklyPlannerType.NEXT_MODULE,
                    exercise: plan.weeklyPlannerType === WeeklyPlannerType.EXERCISE
                }"
                (click)="onClickExistingExercise(plan)"
            >
                <div class="plan-time">{{ plan.date | date : 'HH:mm' }}</div>
                <ng-container [ngSwitch]="plan.weeklyPlannerType">
                    <div class="plan-exercise" *ngSwitchCase="WeeklyPlannerType.EXERCISE">
                        {{ this.getExerciseName(plan.eventData) }}
                    </div>
                    <div class="plan-exercise" *ngSwitchCase="WeeklyPlannerType.NEXT_MODULE">
                        {{ strings.weeklyPlannerScreen.nextModuleShort() }}
                    </div>
                </ng-container>
            </mat-card>
        </div>
        <mat-card appearance="outlined" class="add-item" [style.height]="dayPlans?.length ? '16px' : ''">
            <button type="button" mat-icon-button (click)="onAddItemClicked()" class="">
                <mat-icon>add_circle</mat-icon>
            </button>
        </mat-card>
    </div>
</div>

<ng-template #addItemTemplate let-data>
    <div class="add-item">
        <mm-dialog-header [title]="strings.weeklyPlannerScreen.date({ date: day })"></mm-dialog-header>

        <div class="selection-group-wrapper">
            <mat-radio-group
                class="weekly-planner-type-selection-group"
                color="primary"
                [(ngModel)]="weeklyPlannerType"
            >
                <div *ngIf="weeklyPlannerType === WeeklyPlannerType.NEXT_MODULE" class="next-module-label">
                    {{ strings.weeklyPlannerScreen.nextModuleShort() }}
                </div>

                <!-- next module -->
                <div
                    class="radio-option"
                    [class]="{ 'radio-selected': weeklyPlannerType === WeeklyPlannerType.NEXT_MODULE }"
                    *ngIf="showFormForWeeklyPlannerType(WeeklyPlannerType.NEXT_MODULE, data?.plan)"
                >
                    <div
                        matTooltip="{{ strings.weeklyPlannerScreen.nextModuleDeleteEntry() }}"
                        [matTooltipDisabled]="!hasScheduledNextModule"
                    >
                        <div [class.disabled-div]="hasScheduledNextModule">
                            <mat-radio-button
                                class="weekly-planner-type-selection-item"
                                [value]="WeeklyPlannerType.NEXT_MODULE"
                                *ngIf="!data?.plan?._id"
                            >
                                <div class="input-label">{{ strings.weeklyPlannerScreen.nextModule() }}</div>
                            </mat-radio-button>

                            <form [formGroup]="addNextModuleForm">
                                <div class="input">
                                    <div class="input-label">{{ strings.weeklyPlannerScreen.scheduledFor() }}</div>
                                    <div class="select-box" style="padding: 7px; display: flex">
                                        <input
                                            mbsc-datepicker
                                            matInput
                                            style="border: none; width: 100%"
                                            #mobiscrollTimespan="mobiscroll"
                                            [locale]="localeDe"
                                            theme="ios"
                                            themeVariant="light"
                                            formControlName="time"
                                            placeholder="__:__"
                                            [controls]="['time']"
                                            [stepMinute]="15"
                                            timeFormat="HH:mm"
                                            display="bubble"
                                            [touchUi]="true"
                                            [disabled]="weeklyPlannerType != WeeklyPlannerType.NEXT_MODULE"
                                            [showOnClick]="true"
                                            [showOnFocus]="false"
                                        />
                                        <mat-icon (click)="mobiscrollTimespan.open()">arrow_drop_down</mat-icon>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- exercise -->
                <div
                    class="radio-option"
                    [class]="{ 'radio-selected': weeklyPlannerType === WeeklyPlannerType.EXERCISE }"
                    *ngIf="showFormForWeeklyPlannerType(WeeklyPlannerType.EXERCISE, data?.plan)"
                >
                    <mat-radio-button
                        class="weekly-planner-type-selection-item"
                        [value]="WeeklyPlannerType.EXERCISE"
                        *ngIf="!data?.plan?._id"
                    >
                        <div class="input-label">{{ strings.weeklyPlannerScreen.exercise() }}</div>
                    </mat-radio-button>
                    <form [formGroup]="addExerciseForm" style="margin-top: 1rem">
                        <div class="select-box">
                            <!-- 'width: 100%' is a hacky override of the glykio override on angular material select-->
                            <mat-select
                                style="width: 100%"
                                formControlName="exercise"
                                (selectionChange)="setHasCustomInput($event.value)"
                                [disabled]="weeklyPlannerType != WeeklyPlannerType.EXERCISE"
                            >
                                <mat-option
                                    *ngIf="
                                        this.getExerciseName(data?.plan?.eventData) !== data?.plan?.eventData ||
                                        items.indexOf(this.getExerciseName(data?.plan?.eventData)) < 0
                                    "
                                    [value]="this.getExerciseName(data?.plan?.eventData)"
                                    >{{ this.getExerciseName(data?.plan?.eventData) }}</mat-option
                                >
                                <mat-option
                                    *ngFor="let exercise of items"
                                    [value]="exercise.name"
                                    [ngClass]="
                                        !exercise.custom &&
                                        exercise.name == strings.weeklyPlannerScreen.setNewExercise()
                                            ? 'add-menu-item'
                                            : ''
                                    "
                                >
                                    {{ exercise.name }}
                                    <div class="menu-item-info">
                                        <mat-icon
                                            *ngIf="exercise.custom"
                                            (click)="removeUserCustomExercise(exercise.name)"
                                        >
                                            delete_outline
                                        </mat-icon>
                                        <mat-icon
                                            *ngIf="
                                                !exercise.custom &&
                                                exercise.name == strings.weeklyPlannerScreen.setNewExercise()
                                            "
                                        >
                                            add_circle_outline
                                        </mat-icon>
                                    </div>
                                </mat-option>
                                <mat-select-trigger>
                                    {{ exercise.value }}
                                </mat-select-trigger>
                            </mat-select>
                        </div>

                        <!-- custom exercise field -->
                        <mat-form-field appearance="outline" class="full" *ngIf="hasCustomInput">
                            <div class="input-label">{{ strings.weeklyPlannerScreen.exerciseName() }}</div>
                            <input
                                matInput
                                [disabled]="weeklyPlannerType !== WeeklyPlannerType.EXERCISE"
                                [required]="hasCustomInput"
                                formControlName="customExercise"
                            />
                            <mat-icon matSuffix></mat-icon>
                            <mat-error
                                *ngIf="
                                    customExercise.hasError('required') &&
                                    weeklyPlannerType === WeeklyPlannerType.EXERCISE
                                "
                                >{{ 'validation_required' | localise }}</mat-error
                            >
                        </mat-form-field>

                        <div class="input">
                            <div class="input-label">{{ strings.weeklyPlannerScreen.scheduledFor() }}</div>
                            <div class="select-box" style="padding: 7px; display: flex">
                                <input
                                    mbsc-datepicker
                                    matInput
                                    style="border: none; width: 100%"
                                    #mobiscrollTimespan="mobiscroll"
                                    [locale]="localeDe"
                                    theme="ios"
                                    themeVariant="light"
                                    formControlName="time"
                                    placeholder="__:__"
                                    [controls]="['time']"
                                    [stepMinute]="15"
                                    timeFormat="HH:mm"
                                    display="bubble"
                                    [disabled]="weeklyPlannerType != WeeklyPlannerType.EXERCISE"
                                    [touchUi]="true"
                                    [showOnClick]="true"
                                    [showOnFocus]="false"
                                />
                                <mat-icon (click)="mobiscrollTimespan.open()">arrow_drop_down</mat-icon>
                            </div>
                        </div>
                    </form>
                </div>

                <ng-container *ngIf="!data?.plan; else editMode">
                    <button mat-raised-button type="submit" (click)="saveItem(day)" color="primary">
                        {{ 'general_add' | localise }}
                    </button>
                </ng-container>
                <ng-template #editMode>
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <!-- delete -->
                        <button
                            mat-button
                            color="accent"
                            type="button"
                            class="row-button"
                            (click)="onDeleteClicked(data?.plan?._id)"
                        >
                            <mat-icon>delete</mat-icon>
                            <span>{{ 'general_delete' | localise }}</span>
                        </button>
                        <!-- cancel -->
                        <button mat-button type="button" fxLayout="column" matDialogClose>
                            {{ 'general_cancel' | localise }}
                        </button>
                        <!-- save -->
                        <button
                            mat-raised-button
                            fxLayout="column"
                            type="submit"
                            (click)="onUpdateClicked(data?.plan)"
                            color="primary"
                            class="row-button"
                        >
                            <mat-icon>check</mat-icon>
                            <span>{{ 'general_save' | localise }}</span>
                        </button>
                    </div>
                </ng-template>
            </mat-radio-group>
        </div>
    </div>
</ng-template>
