import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IUser } from './user.model';

export interface State extends EntityState<IUser> {
    lazy: {
        loaded: number;
    };
    ui: {
        filters: {
            search: string;
            provider: string;
        };
    };
}

const initialState = {
    lazy: {
        loaded: 100,
    },
    ui: {
        filters: {
            search: '',
            role: '',
            provider: '',
        },
    },
};

@Injectable({
    providedIn: 'root',
})
/**
 * step store
 */
@StoreConfig({ name: 'users', idKey: '_id' })
export class UserStore extends EntityStore<State, IUser> {
    /**
     * constructor
     */
    constructor() {
        super(initialState);
    }
}
