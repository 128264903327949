import { Injectable } from '@angular/core';
import { SocketService } from '../../socket/socket.service';
import { Observable } from 'rxjs';
import { IUser } from './user.model';
import { UserStore } from './user.store';
import { AuthStore } from '../auth/auth.store';
import { UserQuery } from './user.query';
import { NotificationService } from '../../notification/notification.service';
import { NotificationType } from '../../notification/notification-type.enum';
import { UserActions } from '../../../../../../backend/src/modules/user/actions/user.actions';
import { CoachingService } from '../coaching/coaching.service';
import { CoachingQuery } from '../coaching/coaching.query';
import { applyTransaction } from '@datorama/akita';
import { DocActions } from '../../../../../../backend/src/modules/doc/actions/doc.actions';
import { MatDialog } from '@angular/material/dialog';
import { DataAccessPromptComponent } from '../../data-access-prompt/data-access-prompt.component';
import * as crypto from 'crypto-js';
import { SessionActions } from '../../../../../../backend/src/modules/session/actions/session.actions';
import { AuthService } from '../auth/auth.service';
import { SecurityService } from '../../security/security.service';

@Injectable()
export class UserService {
    constructor(
        private socketService: SocketService,
        private coachingService: CoachingService,
        private authStore: AuthStore,
        private notificationService: NotificationService,
        private userQuery: UserQuery,
        private authService: AuthService,
        private dialog: MatDialog,
        private coachingQuery: CoachingQuery,
        private userStore: UserStore,
        private securityService: SecurityService,
    ) {}

    public getUsers(stream?: boolean): Observable<IUser[]> {
        return new Observable(observer => {
            this.userStore.setLoading(true);

            // declare the load and pass filters
            const load = { filters: this.userQuery.getValue().ui.filters };

            // if streaming, set skip count
            if (stream) {
                load['skip'] = this.userQuery.getCount();
            }

            this.socketService.fire(UserActions.GET, load).subscribe(
                (users: IUser[]) => {
                    applyTransaction(() => {
                        if (stream) {
                            this.userStore.add(users);
                        } else {
                            this.userStore.set(users);
                        }

                        this.userStore.update({ lazy: { loaded: users.length } });
                    });

                    observer.next(users);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, `error_code_${err.code}`);
                    this.userStore.setLoading(false);
                    observer.error(err);
                },
            );
        });
    }

    public loadCurrentUser(): Observable<IUser> {
        return new Observable(observer => {
            this.socketService.fire(UserActions.GET_CURRENT, {}).subscribe(
                (user: IUser) => {
                    if (user) {
                        this.userStore.upsert(user._id, user);
                        this.userStore.setActive(user._id);
                    }
                    observer.next(user);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    public updateUser(user: IUser, notifyUser: boolean = true): Observable<IUser> {
        return new Observable(observer => {
            if (user.password && user.password !== '') {
                user.password = crypto.SHA512(user.password).toString(crypto.enc.Hex);
            }

            this.socketService.fire(UserActions.UPDATE as string, user).subscribe(
                (savedUser: IUser) => {
                    if (user._id) {
                        this.userStore.update(savedUser._id, savedUser);
                    } else {
                        this.userStore.add(savedUser);
                    }

                    if (notifyUser) {
                        this.notificationService.displayNotification(NotificationType.INFO, 'notification_user_saved', [
                            user.name,
                        ]);
                    }

                    observer.next(savedUser);
                    observer.complete();
                },
                err => {
                    if (notifyUser) {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_user_saved_error',
                            [user.name],
                        );
                    }

                    observer.error(err);
                },
            );
        });
    }

    /**
     * generates demo entries depending on users coaching
     */
    public generateDemoEntries(): Observable<string> {
        return new Observable(observer => {
            this.socketService.fire(UserActions.GENERATE_DEMO_ENTRIES, {}).subscribe(
                () => {
                    observer.next();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * update the session user password
     */
    public updatePassword(user: IUser, oldPassword: String, newPassword: String): Observable<IUser> {
        return new Observable(observer => {
            const encryptedOldPassword = crypto.SHA512(oldPassword).toString(crypto.enc.Hex);
            const encryptedNewPassword = crypto.SHA512(newPassword).toString(crypto.enc.Hex);

            this.socketService
                .fire(UserActions.NEW_PASSWORD, { old: encryptedOldPassword, new: encryptedNewPassword })
                .subscribe(
                    usr => {
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_password_saved',
                        );
                        observer.next(usr);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_password_saved_error',
                        );
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    /**
     * removes a user
     */
    public removeUser(user: IUser, anonymize: boolean): Observable<IUser> {
        return new Observable(observer => {
            const action = anonymize ? UserActions.ANONYMIZE : UserActions.REMOVE;
            this.socketService.fire(action, user._id).subscribe(
                () => {
                    this.userStore.remove(user._id);
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        anonymize ? 'notification_user_anonymized' : 'notification_user_removed',
                        [user.name || user.email],
                    );

                    observer.next(user);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        anonymize ? 'notification_user_anonymized_error' : 'notification_user_removed_error',
                        [user.name || user.email],
                    );
                    observer.error(err);
                },
            );
        });
    }

    public digestSocket(): void {
        // listen to user updates
        this.socketService.socket.on('user:update', payload => {
            this.userStore.updateActive(payload);
        });

        // listen to access requests
        this.socketService.socket.on(DocActions.REQUEST_ACCESS, (payload, callback) => {
            // get the identifier form the request
            const identifier = payload.identifier;
            const dialogRef = this.dialog.open(DataAccessPromptComponent, {
                maxWidth: '400px',
                data: {
                    identifier: identifier,
                },
            });

            dialogRef.afterClosed().subscribe((allowAccess: boolean) => {
                if (allowAccess) {
                    const user = this.userQuery.getActive() as IUser;
                    const medicalAccess: any = { ...user.medicalAccess };
                    medicalAccess.used = true;
                    this.userStore.updateActive({ medicalAccess });
                }
                callback(allowAccess);
            });
        });

        // listen to logout
        this.socketService.socket.on(SessionActions.LOGOUT, (payload, callback) => {
            this.authService.logout();
        });
    }

    sendBrandAmbassadorRequestEmail(userId: string): Observable<boolean> {
        return new Observable(observer => {
            this.socketService.fire(UserActions.SEND_BRAND_AMBASSADOR_REQUEST_EMAIL, userId).subscribe(
                result => {
                    observer.next(true);
                    observer.complete();
                },
                error => {
                    observer.error(error);
                },
                () => observer.complete(),
            );
        });
    }

    public getUserNameById(userId: string): Observable<string> {
        return new Observable(observer => {
            this.socketService.fire(UserActions.GET_USER_NAME_BY_ID, userId).subscribe(
                (userName: string) => {
                    observer.next(userName);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }
}
