export enum HypertensionJournalActions {
    GET = 'hypertensionJournalEntries:get',
    CREATE = 'hypertensionJournalEntry:create',
    UPDATE = 'hypertensionJournalEntry:update',
    DELETE = 'hypertensionJournalEntry:delete',
    SUMMARY = 'hypertensionJournal:summary',
    ADD_MINDFULNESS_DURATION = 'hypertensionJournal:addMindfulnessDuration',
    GENERATE_DEMO_ENTRIES = 'hypertensionJournal:generateDemoEntries',
    GENERAL_ACTIVITY_SCORE = 'hypertensionJournal:generalActivityScore',
    AVERAGE_NUTRITION_VALUES = 'hypertensionJournal:averageNutritionValues',
    EXPORT_EXCEL = 'hypertensionJournal:exportExcel',
    EXPORT_EXCEL_MAIL = 'hypertensionJournal:exportExcelMail',
    EXPORT_EXCEL_GENERATION_COMPLETED = 'hypertensionJournal:exportExcelCompleted',
    GENERATE_REPORT_DATA = 'hypertensionJournal:generateReportData',
    GENERATE_PDF = 'hypertensionJournal:generatePdf',
}
