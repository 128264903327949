import { Component, OnInit } from '@angular/core';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { TranslationService } from '../../../shared/translation/translation.service';
import { CoachingService } from '../../../shared/state/coaching/coaching.service';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import * as cloneDeep from 'lodash/cloneDeep';
import { UserTestInvitationPromptService } from './user-test-invitation-prompt.service';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { AppContextQuery } from '../../../shared/application/state/app-context.query';
import { CoachingSettings } from '../../../../../../backend/src/modules/coaching/models/coaching/coaching-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import promptConfig, { TestInvitationPromptCoachingSetting } from './user-test-invitation-prompt.config';
import { LoggingTool } from '../../../../tools/logging/contract';

enum PromptViewState {
    INITIAL_INVITE = 'default',
    LEAVE_APP_WARNING = 'warning',
    CONFIRM_SUCCESS = 'confirm',
    COMPLETED = 'success_confirmed',
}

// TODO: add spec file
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'mm-user-test-invitation-prompt',
    templateUrl: './user-test-invitation-prompt.component.html',
    styleUrls: ['./user-test-invitation-prompt.component.sass'],
})
export class UserTestInvitationPromptComponent implements OnInit {
    protected readonly PromptViewState = PromptViewState;
    protected readonly TestInvitationPromptCoachingSetting = TestInvitationPromptCoachingSetting;
    protected viewState: PromptViewState = PromptViewState.INITIAL_INVITE;

    public promptViewStateSubject: Subject<PromptViewState> = new Subject<PromptViewState>();
    private userTestInvitationLink: string = '';

    get applicationDomain(): string {
        return this.applicationNameToDomain[this.applicationName];
    }

    get applicationName(): Application {
        return this.appContextQuery.getValue().application;
    }

    private applicationNameToDomain: Record<Application, string> = {
        [Application.ACTENSIO]: 'actens.io',
        [Application.SOMNIO]: 'somn.io',
        [Application.GLYKIO]: 'glyk.io',
        [Application.MALIO]: '',
        [Application.SOMNIO_JUNIOR]: 'junior-somn.io',
    };

    get strings() {
        return this.translationService.strings.coaching.userTestInvitationPrompt;
    }

    get commons() {
        return this.translationService.strings.common;
    }

    constructor(
        private coachingQuery: CoachingQuery,
        private userTestInvitationPromptService: UserTestInvitationPromptService,
        private translationService: TranslationService,
        private coachingService: CoachingService,
        private appContextQuery: AppContextQuery,
        private loggingTool: LoggingTool,
    ) {}

    ngOnInit(): void {
        this.setupConfirmationLogic();
    }

    private setupConfirmationLogic(): void {
        this.promptViewStateSubject.subscribe((newViewState: PromptViewState): void => {
            switch (newViewState) {
                case PromptViewState.CONFIRM_SUCCESS:
                    // user has already seen the leave app warning and chose to go through with invite link
                    // Continue with navigation in a new tab
                    if (this.userTestInvitationLink) {
                        window.open(this.userTestInvitationLink, '_blank');
                    } else {
                        this.loggingTool.error('user test invitation link is empty.');
                    }
                    // NOTE: in this state we ask user if they were successful once they get back from external link
                    this.viewState = PromptViewState.CONFIRM_SUCCESS;
                    break;
                case PromptViewState.COMPLETED:
                    // update on BE
                    this.setUserPromptDecision(TestInvitationPromptCoachingSetting.COMPLETED);
                    // user has confirmed they could continue with the invite link, so we close the prompt
                    this.userTestInvitationPromptService.closePrompt();
                    break;
                default:
                    this.viewState = newViewState;
            }
        });
    }

    public onInvitationMessageClick(event: Event): void {
        const target: HTMLElement = event.target as HTMLElement;
        // NOTE: assuming the anchor in the invitation with the external link has a specific class as defined in our config
        if (target.tagName === 'A' && target.className === promptConfig.invitationLinkClassName) {
            event.preventDefault(); // if confirmed we continue with url navigation later
            this.userTestInvitationLink = (target as HTMLAnchorElement).href;
            this.promptViewStateSubject.next(PromptViewState.LEAVE_APP_WARNING);
        }
    }

    public onResponseClick(userResponse: TestInvitationPromptCoachingSetting): void {
        this.setUserPromptDecision(userResponse);
        this.userTestInvitationPromptService.closePrompt();
    }

    get todayAsDateString(): string {
        return new Date().toISOString();
    }

    private setUserPromptDecision(decision: TestInvitationPromptCoachingSetting): void {
        const coaching: ICoaching = cloneDeep(this.coachingQuery.getActive());
        const coachingSettingIdx: number = coaching.settings?.findIndex(
            (setting: CoachingSettings): boolean => setting.property === promptConfig.promptDecisionCoachingSetting,
        );
        const coachingSettingTimestampIdx: number = coaching.settings?.findIndex(
            (setting: CoachingSettings): boolean =>
                setting.property === promptConfig.promptDecisionTimestampCoachingSetting,
        );
        // first check if settings array exists
        if (!coaching.settings?.length) {
            coaching.settings = [];
        }
        // now check if existing setting for user decision exists
        if (coaching.settings && coaching.settings[coachingSettingIdx]) {
            if (coaching.settings[coachingSettingIdx].value === decision.toString()) {
                this.userTestInvitationPromptService.closePrompt();
                return; // no need to update anything on BE, same decision
            } else {
                // we do NOT add a new setting, just update the existing ones
                coaching.settings[coachingSettingIdx].value = decision.toString();
                coaching.settings[coachingSettingTimestampIdx].value = this.todayAsDateString;
            }
        } else {
            // no such decision > add one
            coaching.settings.push({
                property: promptConfig.promptDecisionCoachingSetting,
                value: decision.toString(),
            });
            coaching.settings.push({
                property: promptConfig.promptDecisionTimestampCoachingSetting,
                value: this.todayAsDateString,
            });
        }
        this.coachingService.updateActiveCoachingSettings(coaching.settings).pipe(untilDestroyed(this)).subscribe();
    }
}
