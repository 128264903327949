import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingTool } from '../tools/logging/contract';

@Injectable()
export class AppGlobalErrorhandler implements ErrorHandler {
    constructor(private loggingTool: LoggingTool) {}

    public handleError(error: unknown) {
        this.loggingTool.error(error);
    }
}
