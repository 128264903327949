<mm-dialog-header [title]="'general_support' | localise"></mm-dialog-header>

<mat-tab-group class="no-header p-20" [(selectedIndex)]="tabIndex">
    <mat-tab>
        <p>{{ strings[this.application].supportPageDescription() }}</p>

        <div id="faq-container">
            <h2 id="faq-header">{{ strings.mostImportantFAQs() }}</h2>
            <ng-container *ngFor="let faq of faqs; let idx = index">
                <div class="faq-link">
                    <a (click)="onClickExternalLink(faq.link)">{{ faq.question }}</a>
                </div>
                <hr *ngIf="idx !== faqs.length - 1" />
            </ng-container>
        </div>

        <button
            (click)="onClickExternalLink(strings[this.application].supportPageLink())"
            *notApplication="[Application.MALIO]"
            mat-raised-button
            color="primary"
            class="faq-page-buttons"
        >
            {{ strings.supportPageButtonText() }}
        </button>
        <button mat-stroked-button color="primary" type="button" (click)="tabIndex = 1" class="faq-page-buttons">
            {{ strings.supportPageDigaButtonStroked() }}
        </button>
    </mat-tab>

    <!-- ask support question -->
    <mat-tab>
        <form [formGroup]="supportForm" (submit)="sendRequest()">
            <a (click)="tabIndex = 0" class="back-button">
                <mat-icon>chevron_left</mat-icon>
                {{ commonStrings.general.back() }}
            </a>

            <!--additional (not replacing) hint for sbk-->
            <ng-container *ngIf="branding === Branding.SBK">
                <p [innerHTML]="'general_support_page_sbk' | localise"></p>
                <p [innerHTML]="'general_support_form_sbk' | localise"></p>
            </ng-container>

            <ng-container *ngIf="branding !== Branding.SBK">
                <p style="margin-bottom: 0">{{ strings.requestDescription() }}</p>
            </ng-container>

            <mat-form-field appearance="outline" class="full">
                <mat-label>{{ 'general_request' | localise }}</mat-label>
                <textarea
                    matInput
                    formControlName="text"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="8"
                    [placeholder]="'general_request_detail' | localise"
                ></textarea>
                <mat-error *ngIf="text.hasError('required')">{{ 'validation_required' | localise }}</mat-error>
            </mat-form-field>
            <!--actions -->
            <div style="text-align: center">
                <button mat-raised-button type="submit" color="primary" [disabled]="isSending">
                    <mat-spinner
                        *ngIf="isSending"
                        class="button-spinner"
                        color="primary"
                        [diameter]="20"
                        [strokeWidth]="3"
                    ></mat-spinner>
                    <mat-icon class="icon-primary icon-xs" svgIcon="check" style="fill: white !important"></mat-icon>
                    <span>{{ 'general_send_request' | localise }}</span>
                </button>
            </div>
        </form>
    </mat-tab>

    <!-- confirm support -->
    <mat-tab>
        <div style="width: 300px">
            <mm-lottie name="emailConfirmation"></mm-lottie>
        </div>
        <div style="text-align: center">
            <p>{{ 'general_support_confirmation' | localise }}</p>
        </div>
    </mat-tab>
</mat-tab-group>
