import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IModule } from './module.model';
import { IModuleMeta } from '../module-meta/module-meta.model';

export interface ModuleState extends EntityState<IModule> {
    moduleMeta: IModuleMeta;
    ui: {
        filters: {
            search: string;
        };
    };
}

const initialState = {
    moduleMeta: null,
    ui: {
        filters: {
            search: '',
        },
    },
};

@Injectable({
    providedIn: 'root',
})
/**
 * module store
 */
@StoreConfig({ name: 'modules', idKey: '_id' })
export class ModuleStore extends EntityStore<ModuleState, IModule> {
    /**
     * constructor
     */
    constructor() {
        super(initialState);
    }
}
