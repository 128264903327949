import { Injectable } from '@angular/core';
import { LanguageService } from '../language/language.service';
import { DeviceQuery } from '../device/state/device.query';

@Injectable({
    providedIn: 'root',
})

/**
 * fitbit service
 */
export class DialogHelperService {
    /**
     * constructor
     */
    constructor(private languageService: LanguageService, private deviceQuery: DeviceQuery) {}

    /**
     * create and open a window popup with a 'waiting' message such that a later download can be redirected to this window without triggering a download popup message
     * @private
     */
    public prepareWindowPopup(): Window {
        if (this.deviceQuery.getValue().app && this.deviceQuery.getValue().iOS) {
            return null;
        }

        const features = this.getFeatures();
        const windowRef = window.open('', '_blank', features);
        const waitingMessage = this.languageService.getText('general_in_progress_please_wait');
        windowRef.document.write(`<html><body>${waitingMessage}</body></html>`);
        return windowRef;
    }

    /**
     * get the features to configure a window popup
     * @private
     */
    private getFeatures(): string {
        let features = `toolbar=no,
                      location=no,
                      status=no,
                      menubar=no,
                      scrollbars=yes,
                      resizable=yes`;
        features = '';
        return features;
    }
}
