import { Component, Input, OnInit } from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { CoachingService } from '../../../shared/state/coaching/coaching.service';
import { TimeChangePromptService } from './time-change-prompt.service';
import { TranslationService } from '../../../shared/translation/translation.service';

@Component({
    selector: 'mm-time-change-prompt',
    templateUrl: './time-change-prompt.component.html',
    styleUrls: ['./time-change-prompt.component.sass'],
})
export class TimeChangePromptComponent implements OnInit {
    @Input() popoverText: string;

    get strings() {
        return this.translationService.strings.coaching.timeChangePrompt;
    }

    constructor(
        private coachingQuery: CoachingQuery,
        private timeChangeService: TimeChangePromptService,
        private translationService: TranslationService,
        private coachingService: CoachingService,
    ) {}

    ngOnInit(): void {}

    public onGotItClick(): void {
        const coaching: ICoaching = cloneDeep(this.coachingQuery.getActive());
        const timeChangeKey = this.timeChangeService.getTimeChangeKey();
        if (coaching.settings?.find(setting => setting.property === timeChangeKey && setting.value === 'true')) {
            return;
        }

        if (!coaching.settings?.length) {
            coaching.settings = [];
        }
        coaching.settings.push({ property: timeChangeKey, value: 'true' });

        this.coachingService.updateActiveCoachingSettings(coaching.settings).subscribe();

        this.timeChangeService.closePrompt();
    }

    public getTimeChangeLanguageKey(): string {
        if (new Date().getMonth() + 1 === 3) {
            return this.strings.dayLightSavingTimeInfo();
        }
        return this.strings.standardTimeInfo();
    }
}
