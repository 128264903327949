import { Pipe, PipeTransform } from '@angular/core';

declare let _;

@Pipe({
    name: 'search',
    pure: false,
})
export class SearchPipe implements PipeTransform {
    public transform(list: any[], args: string[]): any {
        if (list && args[1] !== '') {
            // check if property contains search string
            return list.filter(item => {
                const property = args[0];
                let languageSpecifier = null;

                if (item[property].hasOwnProperty('de_ch')) {
                    languageSpecifier = 'de_ch';
                }

                if (languageSpecifier) {
                    return item[property][languageSpecifier].toLowerCase().indexOf(args[1].toLowerCase()) !== -1;
                }
                return item[property].toLowerCase().indexOf(args[1].toLowerCase()) !== -1;
            });
        }

        return list;
    }
}
