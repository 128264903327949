import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { RoleQuery } from './state/role/role.query';
import { RoleName } from '../../../../../backend/src/shared/modules/security/enums/security-roles.enum';
import { IRole } from './state/role/role.model';
import { IUser } from '../state/user/user.model';
import { UserQuery } from '../state/user/user.query';

@Injectable()
export class SecurityService {
    constructor(private roleQuery: RoleQuery, private router: Router, private userQuery: UserQuery) {}

    /**
     * checks if user has a certain role
     */
    public hasRoles(roleNames: RoleName[]): boolean {
        //TODO: check why this func gets invoked after stores are reset
        let hasRole = false;
        
        const activeUser: IUser = this.userQuery.getActive() as IUser;
        const hasUserAnyRoles: boolean = this.roleQuery.hasEntity();

        if (!activeUser || hasUserAnyRoles) {
            return false;
        }

        for (const roleName of roleNames) {
            const roles: IRole[] = this.roleQuery.getAll({
                filterBy: role => role.name === roleName,
            });
            hasRole = hasRole || roles.length > 0;
        }

        return hasRole;
    }

    /**
     * check if the password is valid
     */
    public isPasswordValid(password: string): boolean {
        // check length
        if (password.length < 8 || password.length > 24) {
            return false;
        }

        // check for uppercase
        if (password === password.toLowerCase()) {
            return false;
        }

        // check for special character
        if (/^[a-zA-Z0-9]*$/.test(password)) {
            return false;
        }

        return true;
    }

    /**
     * grant user access to analysis manager if 'admin' or 'analysis'
     * also prevents url access
     * @private
     */
    public grantUserAccess(roles: RoleName[]): void {
        const canAccessAnalysis = this.hasRoles(roles);
        if (!canAccessAnalysis) {
            void this.router.navigate(['coaching/']);
        }
    }
}
