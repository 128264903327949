import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthStore, AuthState } from './auth.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
    /**
     * selects the success state
     */
    public selectSuccess(): Observable<boolean> {
        return this.select('success');
    }

    constructor(protected store: AuthStore) {
        super(store);
    }
}
