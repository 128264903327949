import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * local definition, since currently the 'best' available package was last updated 3 years ago
 * and keeping the bundle as small as possible by only defining relevant keys => all others will be
 * ignored
 */
export enum MMKeyEventEnum {
    ENTER = 'Enter',
    SPACE = 'Space',
    A = 'KeyA',
    B = 'KeyB',
    C = 'KeyC',
    G = 'KeyG',
    I = 'KeyI',
    J = 'KeyJ',
    S = 'KeyS',
    T = 'KeyT',
    L = 'KeyL',
    V = 'KeyV',
    M = 'KeyM',

    BACKSPACE = 'Backspace',
    ARROW_RIGHT = 'ArrowRight',
    D_0 = 'Digit0',
    D_1 = 'Digit1',
    D_2 = 'Digit2',
    D_3 = 'Digit3',
    D_4 = 'Digit4',
    D_5 = 'Digit5',
    D_6 = 'Digit6',
    D_7 = 'Digit7',
    ESCAPE = 'Escape',
}

export enum MMSpecialKeyEnum {
    CTRL = 'Control',
    ALT = 'Alt',
    SHIFT = 'Shift',
}

@Injectable({
    providedIn: 'root',
})
export class KeyListenerService {
    /** you can subsribe directly to this event emitter
     * or use LISTEN_FOR below, which provides more fine tuning as well as applied filters
     * beforehand
     */
    @Output() keyUp$: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

    /** you can subsribe directly to this event emitter
     * or use LISTEN_FOR below, which provides more fine tuning as well as applied filters
     * beforehand
     */
    @Output() keyDown$: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

    /**
     * checks a key combination for the event as well as special keys
     * @param event
     * @param key
     * @param specialKeys
     */
    static checkCombi(event: KeyboardEvent, key: MMKeyEventEnum | string, specialKeys?: MMSpecialKeyEnum[]): boolean {
        if (event.code !== key.toString()) {
            return false;
        }

        // check for provided special chars
        if (specialKeys?.length && specialKeys.length > 0) {
            for (const specialKey of specialKeys) {
                switch (specialKey) {
                    case MMSpecialKeyEnum.CTRL:
                        if (!event.ctrlKey) {
                            return false;
                        }
                        break;

                    case MMSpecialKeyEnum.ALT:
                        if (!event.altKey) {
                            return false;
                        }
                        break;
                    case MMSpecialKeyEnum.SHIFT:
                        if (!event.shiftKey) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return true;
    }

    constructor() {}

    /**
     * called from app.component.ts
     * @param event aka the keyboard event
     */
    public keyDown(event: KeyboardEvent): void {
        this.keyDown$.emit(event); // case-sensitive
    }

    /**
     * shortcut, listens for keyevents
     * @param key
     * @param specialKeys
     */
    public listenForKeyDown(key: MMKeyEventEnum | string, specialKeys?: MMSpecialKeyEnum[]): Observable<KeyboardEvent> {
        return this.keyDown$.pipe(filter(event => KeyListenerService.checkCombi(event, key, specialKeys)));
    }
}
