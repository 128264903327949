import { Injectable } from '@angular/core';
import { Store, StoreConfig, ID } from '@datorama/akita';
import { IUser } from '../user/user.model';

export interface AuthTokenPayload {
    user: IUser;
    exp: number; // expiration
    iat?: number;
}

export interface AuthState {
    _id: ID;
    name: string;
    token: string;
    language: string;
    coaching: string;
    isLoggedIn: boolean;
    success: boolean; // general success state, similar to loading and error
}

export function createInitialState(): AuthState {
    return {
        _id: null,
        name: null,
        token: null,
        language: null,
        coaching: null,
        isLoggedIn: false,
        success: false,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
    /**
     * constructor
     */
    constructor() {
        super(createInitialState());
    }

    /**
     * setter for success
     */
    public setSuccess(success: boolean): void {
        this.update({ success: success });
    }

    /**
     * provide a uniform method to update user data
     */
    public updateWithUserData(
        userId: ID,
        userName: string,
        token: string,
        language: string,
        coaching: string = null,
        isLoggedIn = true,
    ): void {
        this.update({
            _id: userId,
            name: userName,
            token: token,
            language: language,
            coaching: coaching,
            isLoggedIn: isLoggedIn,
        });
    }
}
