import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ServerDowntimePromptService {
    constructor(private dialog: MatDialog, private coachingQuery: CoachingQuery) {}

    public openServerDowntimePrompt(component: any): void {
        const msAfterPromptIsDisplayed = 10000;

        if (this.isBeforeEndDate() && !this.hasAlreadySeen()) {
            setTimeout(() => {
                this.dialog.open(component, {
                    autoFocus: false,
                    maxWidth: '500px',
                    disableClose: true,
                });
            }, msAfterPromptIsDisplayed);
        }
    }

    private hasAlreadySeen(): boolean {
        const coaching: ICoaching = this.coachingQuery.getActive() as ICoaching;
        const hasSeenWarning = coaching?.settings?.find(
            setting => setting.property === this.getServerDowntimeKey() && setting.value === 'true',
        );
        return !!hasSeenWarning;
    }

    private isBeforeEndDate(): boolean {
        const now = moment(new Date());
        const endDate = moment('2023-09-19T10:00:00');

        return now.isBefore(endDate);
    }

    public closePrompt(): void {
        this.dialog.closeAll();
    }

    public getServerDowntimeKey(): string {
        return 'hasSeenServerDowntime19.09.2023Prompt';
    }
}
