import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AppContextState, AppContextStore } from './app-context.store';

@Injectable({
    providedIn: 'root',
})
export class AppContextQuery extends Query<AppContextState> {
    constructor(protected store: AppContextStore) {
        super(store);
    }
}
