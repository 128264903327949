import { Pipe, PipeTransform } from '@angular/core';
import { LanguageCode } from '../../../../../backend/src/shared/modules/language/enums/language.enum';

@Pipe({ name: 'getDateFormat' })
export class GetDateFormatPipe implements PipeTransform {
    transform(languageCode: LanguageCode): string {
        switch (languageCode) {
            case LanguageCode.FR_CH:
                return 'DD/MM/YYYY';
            case LanguageCode.EN_US:
                return 'MM/DD/YYYY';
            case LanguageCode.DE_CH:
            default:
                return 'DD.MM.YYYY';
        }
    }
}
