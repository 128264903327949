import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationType } from '../notification-type.enum';

@Component({
    selector: 'mm-notification-info',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.sass'],
})

/**
 * info notification
 */
export class NotificationComponent implements OnInit {
    /**
     * notification to display
     */
    public notification: string;
    public notificationType: NotificationType;
    public NotificationType = NotificationType;

    /**
     * constructor
     */
    constructor(@Inject(MAT_SNACK_BAR_DATA) data: any, private snackRef: MatSnackBarRef<NotificationComponent>) {
        this.notification = data.notification;
        this.notificationType = data.notificationType;
    }

    /**
     * called on component init
     */
    ngOnInit() {}

    /**
     * close the dialog
     */
    public onClose(): void {
        this.snackRef.dismissWithAction();
    }
}
