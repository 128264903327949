import { Component } from '@angular/core';
import { TranslationService } from '../../../../shared/translation/translation.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SupportService } from '../state/support.service';
import { Application } from '../../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { LocalizedString } from 'typesafe-i18n';
import { ContactSupportBody } from '../../../../../../../backend/src/shared/modules/support/models/contact-support-body';

enum ViewType {
    DEFAULT = 'default',
    TECHNICAL_ISSUES = 'technicalIssues',
    MESSAGE_SENT = 'messageSent',
    WARNING = 'warning',
}

@Component({
    selector: 'mm-expert-support',
    templateUrl: './expert-support.component.html',
    styleUrls: ['./expert-support.component.sass'],
})
export class ExpertSupportComponent {
    public view: ViewType = ViewType.DEFAULT;
    public viewType = ViewType;
    public form: FormGroup;
    public isSending: boolean = false;

    get text(): AbstractControl {
        return this.form.get('text');
    }

    get strings() {
        return this.translationService.strings.coaching.supportDialogue;
    }

    public getHeaderByTab(view: ViewType): LocalizedString {
        switch (view) {
            case ViewType.TECHNICAL_ISSUES:
                return this.strings.expertSupport.sendMessageHeader();
            case ViewType.WARNING:
                return this.strings.expertSupport.goToCalendarHeader();
            default:
                return this.strings.expertSupport.header();
        }
    }

    constructor(
        private formBuilder: FormBuilder,
        private supportService: SupportService,
        public deviceService: DeviceDetectorService,
        private translationService: TranslationService,
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            text: ['', Validators.required],
        });
    }

    public switchView(view: ViewType): void {
        this.view = view;
    }

    public sendRequest(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        // set isSending to true
        this.isSending = true;

        const contactSupportBody: ContactSupportBody = {
            deviceInfo: this.deviceService.getDeviceInfo(),
            isMobile: this.deviceService.isMobile(),
            isTablet: this.deviceService.isTablet(),
            isDesktop: this.deviceService.isDesktop(),
            userQuestion: this.text.value as string,
            application: Application.GLYKIO,
        };

        this.supportService.contactSupport(contactSupportBody).subscribe(
            () => {
                this.view = ViewType.MESSAGE_SENT;
                this.isSending = false;
            },
            error => {
                this.isSending = false;
            },
        );
    }
}
