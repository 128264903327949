import { Pipe, PipeTransform } from '@angular/core';
import { LanguageQuery } from '../state/language/language.query';
import { Language } from '../state/language/language.model';
import { LanguageCode } from '../../../../../backend/src/shared/modules/language/enums/language.enum';
import * as moment from 'moment';

/**
 * formats a date for de_ch language users to DD.MM and for all other languages to DD/MM
 */
@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    constructor(private languageQuery: LanguageQuery) {}

    transform(day: Date, format?: any, withYear?: boolean): string {
        const language = this.languageQuery.getActive() as Language;
        let momentFormat = format ? format.toString() : '';

        if (!format) {
            switch (language.code) {
                case LanguageCode.FR_CH:
                case LanguageCode.EN_AU:
                    momentFormat = withYear ? 'DD/MM/YYYY' : 'DD/MM';
                    break;
                case LanguageCode.EN_US:
                    momentFormat = withYear ? 'MM/DD/YYYY' : 'MM/DD';
                    break;
                case LanguageCode.DE_CH:
                default:
                    momentFormat = withYear ? 'DD.MM.YYYY' : 'DD.MM';
                    break;
            }
        }
        return moment(day).format(momentFormat);
    }
}
