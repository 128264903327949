import { Injectable } from '@angular/core';
import { SocketService } from '../../socket/socket.service';
import { ICoaching } from './coaching.model';
import { CoachingStore } from './coaching.store';
import { EMPTY, Observable } from 'rxjs';
import { applyTransaction } from '@datorama/akita';
import { CoachingQuery } from './coaching.query';
import { NotificationService } from '../../notification/notification.service';
import { NotificationType } from '../../notification/notification-type.enum';
import { CoachingActions } from '../../../../../../backend/src/modules/coaching/actions/coaching.actions';
import { IModuleMeta } from '../module-meta/module-meta.model';
import { IStep } from '../step/step.model';
import { ModuleActions } from '../../../../../../backend/src/modules/coaching/actions/module.actions';
import * as _ from 'lodash';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { byApplication, CoachingType } from '../../../../../../backend/src/modules/coaching/enums/coaching-types.enum';
import { UserActions } from '../../../../../../backend/src/modules/user/actions/user.actions';
import { IUser } from '../user/user.model';
import { GetActivationLinkBody } from '../../../../../../backend/src/modules/user/models/get-activation-link-body';
import { AppRating } from '../../../../../../backend/src/modules/coaching/models/coaching/app-rating.mode';
import { CoachingSettings } from '../../../../../../backend/src/modules/coaching/models/coaching/coaching-settings';
import { ModuleMeta } from '../../../../../../backend/src/modules/coaching/models/moduleMeta/module-meta.model';
import { IModule } from '../module/module.model';
import { LoggingTool } from '../../../../tools/logging/contract';
import { WaitingRoom } from '../../../../../../backend/src/modules/coaching/models/coaching/waiting-room-model';

@Injectable()
export class CoachingService {
    public isFakeHistoryUsed = false;
    public fakeHistory = [];

    constructor(
        private socketService: SocketService,
        private coachingQuery: CoachingQuery,
        private notificationService: NotificationService,
        private coachingStore: CoachingStore,
        private loggingTool: LoggingTool,
    ) {}

    public getCoachings(activeCoachingId?: string): Observable<ICoaching[]> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.GET_SOURCE, null).subscribe(
                (coachings: ICoaching[]) => {
                    applyTransaction(() => {
                        // set the coachings
                        this.coachingStore.set(coachings);

                        // set active by version
                        if (coachings && coachings.length > 0) {
                            if (!!activeCoachingId && this.coachingQuery.getEntity(activeCoachingId)) {
                                this.coachingStore.setActive(activeCoachingId);
                            } else {
                                // get latest latest version and set active
                                const coaching: ICoaching = _.maxBy(coachings, 'version');
                                this.coachingStore.setActive(coaching._id);
                            }
                        }
                    });
                    observer.next(coachings);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, `error_code_${err.code}`);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public getUserCoaching(): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.GET_FOR_SOCKET_USER, null).subscribe(
                (coaching: ICoaching) => {
                    // must at least have a coaching
                    if (coaching) {
                        this.coachingStore.set([coaching]);
                        this.coachingStore.setActive(coaching._id);
                        this.setActiveCoaching(coaching._id.toString()).subscribe(() => {
                            observer.next(coaching);
                            observer.complete();
                        });
                    } else {
                        observer.error();
                        observer.complete();
                    }
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                    observer.complete();
                },
            );
        });
    }

    public getByUser(userId: string): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.GET_FOR_USER, userId).subscribe(
                (coaching: ICoaching) => {
                    observer.next(coaching);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public createCoaching(coaching: ICoaching): Observable<ICoaching> {
        this.coachingStore.setLoading(true);
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.CREATE, coaching).subscribe({
                next: (savedCoaching: ICoaching) => {
                    this.coachingStore.add(savedCoaching);
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_coaching_saved');
                    observer.next(savedCoaching);
                },
                error: err => {
                    this.coachingStore.setLoading(false);
                    this.notificationService.displayNotification(NotificationType.ERROR, 'error_code_' + err.text.code);
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                complete: () => {
                    this.coachingStore.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    public updateCoaching(coaching: ICoaching): Observable<ICoaching> {
        this.coachingStore.setLoading(true);
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.UPDATE, coaching).subscribe({
                next: (savedCoaching: ICoaching) => {
                    this.coachingStore.update(savedCoaching._id, savedCoaching);
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_coaching_saved');
                    observer.next(savedCoaching);
                },
                error: err => {
                    this.coachingStore.setLoading(false);
                    this.notificationService.displayNotification(NotificationType.ERROR, 'error_code_' + err.text.code);
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                complete: () => {
                    this.coachingStore.setLoading(false);
                    observer.complete();
                },
            });
        });
    }

    public updateAppRating(rating: AppRating): Observable<ICoaching> {
        return new Observable(observer => {
            this.coachingStore.setMinorLoading(true);

            this.socketService.fire(CoachingActions.UPDATE_APP_RATING, rating).subscribe(
                (coaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, coaching);
                    this.coachingStore.setMinorLoading(false);
                    observer.next(coaching);
                },
                err => {
                    this.coachingStore.setMinorLoading(false);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public updateActiveCoachingSettings(coachingSettings: CoachingSettings[]): Observable<ICoaching> {
        return new Observable(observer => {
            this.coachingStore.setMinorLoading(true);

            this.socketService.fire(CoachingActions.UPDATE_COACHING_SETTINGS, coachingSettings).subscribe(
                (coaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, coaching);
                    this.coachingStore.setMinorLoading(false);
                    observer.next(coaching);
                },
                err => {
                    this.coachingStore.setMinorLoading(false);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public removeCoaching(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.REMOVE, coaching._id).subscribe(
                () => {
                    this.coachingStore.remove(coaching._id);
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_coaching_removed',
                    );
                    observer.next(coaching);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_coaching_removed_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public publish(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.PUBLISH, coaching._id).subscribe(
                () => {
                    this.coachingStore.update(coaching._id, { published: true });
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_coaching_published',
                    );
                    observer.next(coaching);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_coaching_published_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public createVersion(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.NEW_VERSION, coaching._id).subscribe(
                (newVersion: ICoaching) => {
                    this.coachingStore.add(newVersion);
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_version_created');
                    observer.next(newVersion);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_version_created_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public checkForUpdate(coaching: ICoaching): Observable<boolean> {
        return coaching
            ? new Observable(observer => {
                  this.socketService.fire(CoachingActions.UPDATE_AVAILABLE, coaching?._id).subscribe(
                      (updateAvailable: boolean) => {
                          this.coachingStore.update({ updateAvailable: updateAvailable });
                          observer.next(updateAvailable);
                      },
                      err => {
                          this.loggingTool.error(err);
                          observer.error(err);
                      },
                      () => {
                          observer.complete();
                      },
                  );
              })
            : EMPTY;
    }

    public updateToLatest(coaching: ICoaching, publishedOnly: boolean): Observable<ICoaching> {
        return new Observable(observer => {
            this.coachingStore.setLoading(true);

            this.socketService
                .fire(CoachingActions.UPDATE_TO_LATEST, {
                    coachingId: coaching._id,
                    publishedOnly: publishedOnly,
                })
                .subscribe(
                    (updatedCoaching: ICoaching) => {
                        this.coachingStore.update(coaching._id, updatedCoaching);
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_coaching_version_updated',
                        );
                        observer.next(updatedCoaching);
                    },
                    err => {
                        this.coachingStore.setLoading(false);
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_coaching_version_updated_error',
                        );
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        this.coachingStore.setLoading(false);
                        observer.complete();
                    },
                );
        });
    }

    public unlockAllModules(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.UNLOCK_ALL_MODULES, coaching._id).subscribe(
                (unlockedCoaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, unlockedCoaching);
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_all_modules_unlocked',
                    );
                    observer.next(unlockedCoaching);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'notification_generic_error');
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public lockAllModules(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.LOCK_ALL_MODULES, coaching._id).subscribe(
                (lockedCoaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, lockedCoaching);
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_generic_success');
                    observer.next(lockedCoaching);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'notification_generic_error');
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public updateAllModulesToLatestVersion(coaching: ICoaching, publishedOnly: boolean): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService
                .fire(CoachingActions.UPDATE_ALL_MODULES, {
                    coachingId: coaching._id,
                    publishedOnly: publishedOnly,
                })
                .subscribe(
                    (lockedCoaching: ICoaching) => {
                        this.coachingStore.update(coaching._id, lockedCoaching);
                        this.notificationService.displayNotification(
                            NotificationType.INFO,
                            'notification_generic_success',
                        );
                        observer.next(lockedCoaching);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_generic_error',
                        );
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    public removeWaitTimes(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.REMOVE_WAIT_TIMES, coaching._id).subscribe(
                (updatedCoaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, updatedCoaching);
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_wait_times_removed',
                    );
                    observer.next(updatedCoaching);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_wait_times_removed_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public addWaitTimes(coaching: ICoaching): Observable<ICoaching> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.ADD_WAIT_TIMES, coaching._id).subscribe(
                (updatedCoaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, updatedCoaching);
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_generic_success');
                    observer.next(updatedCoaching);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'notification_generic_error');
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public completeModule(moduleId: string, metaId: string, stepId?: string): Observable<ICoaching> {
        return new Observable(observer => {
            const coaching = this.coachingQuery.getActive() as ICoaching;

            this.socketService
                .fire(CoachingActions.COMPLETE_MODULE, {
                    coachingId: coaching._id,
                    moduleId: moduleId,
                    metaId: metaId,
                    stepId: stepId,
                })
                .subscribe(
                    (updatedCoaching: ICoaching) => {
                        this.coachingStore.updateActive(updatedCoaching);
                        observer.next(updatedCoaching);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_module_completed_error',
                        );
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    public completeDynamicModuleDemoAction(moduleId: string, metaId: string): Observable<ICoaching> {
        return new Observable(observer => {
            const coaching = this.coachingQuery.getActive() as ICoaching;

            this.socketService
                .fire(CoachingActions.COMPLETE_DYNAMIC_MODULE_DEMO_ACTION, {
                    coachingId: coaching._id,
                    moduleId: moduleId,
                    metaId: metaId,
                })
                .subscribe(
                    (updatedCoaching: ICoaching) => {
                        this.coachingStore.updateActive(updatedCoaching);
                        observer.next(updatedCoaching);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_module_completed_error',
                        );
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    public getCoachingKeys(): Observable<String[]> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.GET_COACHING_KEYS, {}).subscribe(
                (coachingKeys: string[]) => {
                    this.coachingStore.update({ keys: coachingKeys });
                    observer.next(coachingKeys);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public addStepToHistory(moduleMeta: IModuleMeta, step: IStep): Observable<IStep> {
        // fake history local
        if (this.isFakeHistoryUsed) {
            this.fakeHistory.push(step);
            return new Observable(observer => {
                observer.next(step);
                observer.complete();
            });
        }

        return new Observable(observer => {
            this.socketService
                .fire(ModuleActions.ADD_STEP_TO_HISTORY, {
                    step,
                    moduleMeta,
                })
                .subscribe(
                    (coaching: ICoaching) => {
                        const meta = _.cloneDeep(moduleMeta);
                        meta.history.push(step._id.toString());

                        applyTransaction(() => {
                            this.coachingStore.update({ activeMeta: meta });
                            this.coachingStore.updateActive(coaching);
                        });

                        observer.next(step);
                    },
                    err => {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_add_step_to_history_error',
                        );
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    public getLatestStepFromHistory(): string {
        return [...this.coachingQuery.getValue().activeMeta.history].pop();
    }

    public removeStepFromHistory(): Observable<IStep> {
        // fake history local
        if (this.isFakeHistoryUsed) {
            return new Observable(observer => {
                // retrieve last step
                this.fakeHistory.pop();
                observer.next(this.fakeHistory.pop());
                observer.complete();
            });
        }

        return new Observable(observer => {
            this.socketService.fire(ModuleActions.REMOVE_STEP_FROM_HISTORY, {}).subscribe(
                (step: IStep) => {
                    const meta = _.cloneDeep(this.coachingQuery.getValue().activeMeta);
                    meta.history.splice(-1, 1);
                    this.coachingStore.update({ activeMeta: meta });

                    observer.next(step);
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_add_step_to_history_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public clearHistory(): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(ModuleActions.CLEAR_HISTORY, {}).subscribe(
                () => {
                    const meta = _.cloneDeep(this.coachingQuery.getValue().activeMeta);
                    meta.history = [];
                    this.coachingStore.update({ activeMeta: meta });

                    observer.next();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_clear_history_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public setActiveModuleMeta(moduleMeta: IModuleMeta): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.SET_ACTIVE_MODULE_META, moduleMeta._id).subscribe(
                () => {
                    this.coachingStore.update({ activeMeta: moduleMeta });
                    observer.next(module);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'error_code_' + err.code);
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public setActiveCoaching(coachingId: string): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.SET_ACTIVE_COACHING, coachingId).subscribe(
                () => {
                    this.coachingStore.setActive(coachingId);
                    observer.next(module);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'error_code_' + err.code);
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public markMenuAsOpened(coachingId: string, menuKey: string): Observable<any> {
        return new Observable(observer => {
            this.socketService
                .fire(CoachingActions.MARK_MENU_AS_OPENED, {
                    coachingId,
                    menuKey,
                })
                .subscribe(
                    (updatedCoaching: ICoaching) => {
                        if (updatedCoaching) {
                            this.coachingStore.updateActive(updatedCoaching);
                        }
                        observer.next();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    public markSummaryAsOpened(metaId: string): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.MARK_SUMMARY_AS_OPENED, metaId).subscribe(
                (updatedCoaching: ICoaching) => {
                    if (updatedCoaching) {
                        this.coachingStore.updateActive(updatedCoaching);
                    }
                    observer.next();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public setWaitingRoomState(waitingRoomScreen: WaitingRoom): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.SET_WAITING_ROOM_STATE, waitingRoomScreen).subscribe(
                (updatedCoaching: ICoaching) => {
                    if (updatedCoaching) {
                        this.coachingStore.updateActive(updatedCoaching);
                    }
                    observer.next();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public downloadAllSummaries(languageCode: string): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.DOWNLOAD_ALL_SUMMARIES, languageCode).subscribe(
                (url: string) => {
                    observer.next(url);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public checkAppRatingCondition(metaId: string): Observable<boolean> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.RATING_CONDITION_MET, metaId).subscribe(
                (ratingConditionMet: boolean) => {
                    observer.next(ratingConditionMet);
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public digestSocket(): void {
        // listen to user updates
        this.socketService.socket.on('coaching:waitTime', () => {
            const coaching = _.cloneDeep(this.coachingQuery.getActive() as ICoaching);

            for (const module of coaching.modules) {
                if (module.completed && module.requiredEntries > 0 && module.createdEntries < module.requiredEntries) {
                    module.createdEntries += 1;
                    break;
                }
            }

            this.coachingStore.updateActive(coaching);
        });
    }

    public getActivationLink(user: IUser, coaching: ICoaching): Observable<string> {
        return new Observable(observer => {
            const body = new GetActivationLinkBody();
            body.userId = user._id;
            body.coachingId = coaching._id;
            this.socketService.fire(UserActions.GET_ACTIVATION_LINK, body).subscribe(
                (link: string) => {
                    observer.next(link);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
            );
        });
    }

    public updateCoachingModules(modules: ModuleMeta): Observable<ICoaching> {
        return new Observable(observer => {
            this.coachingStore.setMinorLoading(true);

            this.socketService.fire(CoachingActions.UPDATE_COACHING_MODULES, modules).subscribe(
                (coaching: ICoaching) => {
                    this.coachingStore.update(coaching._id, coaching);
                    this.coachingStore.setMinorLoading(false);
                    observer.next(coaching);
                },
                err => {
                    this.coachingStore.setMinorLoading(false);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public isCoachingKeyApplication(coachingType: CoachingType, application: Application): boolean {
        const coachingTypesForApplication = byApplication[application];
        return coachingTypesForApplication.includes(coachingType);
    }

    public getKeyOfNextUnlockedModule(): string {
        const coaching = this.coachingQuery.getActive() as ICoaching;
        return coaching?.modules?.find(m => !m.startedDate)?.module.key;
    }

    public computeNextDynamicModuleFromModuleStep(activeModule: IModule): Observable<void> {
        return new Observable(observer => {
            this.coachingStore.setMinorLoading(true);

            this.socketService
                .fire(CoachingActions.COMPUTE_NEXT_DYNAMIC_MODULE_FROM_MODULE_STEP, { module: activeModule })
                .subscribe({
                    next: () => {
                        observer.next();
                    },
                    error: e => {
                        observer.error(e);
                    },
                    complete: () => {
                        this.coachingStore.setMinorLoading(false);
                        observer.complete();
                    },
                });
        });
    }

    public popStepIdFromHistory(): string {
        const meta = _.cloneDeep(this.coachingQuery.getValue().activeMeta);
        const lastStepId = meta.history.pop();
        this.coachingStore.update({ activeMeta: meta });

        return lastStepId.toString();
    }

    public evaluateCondition(condition: string): Observable<boolean> {
        return new Observable(observer => {
            this.socketService.fire(CoachingActions.EVALUATE_EXPRESSION, { condition }).subscribe(
                (response: boolean) => {
                    observer.next(response);
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public checkRequiredEntries(): Observable<
        Array<{
            moduleId: string;
            entryType: string;
            value: string;
        }>
    > {
        const coaching = this.coachingQuery.getActive() as ICoaching;
        return new Observable(observer => {
            this.socketService
                .fire(CoachingActions.ARE_REQUIRED_ENTRIES_FULFILLED, { coachingKey: coaching.key })
                .subscribe(
                    response => {
                        observer.next(response);
                        observer.complete();
                    },
                    err => {
                        this.loggingTool.error(err);
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }
}
