import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Language } from '../../../shared/state/language/language.model';
import { LanguageQuery } from '../../../shared/state/language/language.query';
import { WeeklyPlannerService } from '../../../shared/state/weekly-planner/weekly-planner.service';
import { SocketService } from '../../../shared/socket/socket.service';
import { IWeeklyPlanner } from '../../../shared/state/weekly-planner/weekly-planner.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoachingQuery } from '../../../shared/state/coaching/coaching.query';
import { CoachingService } from '../../../shared/state/coaching/coaching.service';
import { Observable } from 'rxjs';
import { ICoaching } from '../../../shared/state/coaching/coaching.model';
import { Exercise } from './interfaces/exercise.interface';
import { TranslationService } from '../../../shared/translation/translation.service';
import { WeeklyPlanner } from '../../../../../../backend/src/modules/coaching/modules/weekly-planner/models/weekly-planner.model';
import { StepService } from '../../../shared/state/step/step.service';
import { WeeklyPlannerType } from '../../../../../../backend/src/shared/entities/weekly-planner-type.enum';
import { MenuItemKey } from '../navigation/enums/menu-item-key.enum';
import { LoggingTool } from '../../../../tools/logging/contract';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'mm-weekly-planner',
    templateUrl: './weekly-planner.component.html',
    styleUrls: ['./weekly-planner.component.sass'],
})
export class WeeklyPlannerComponent implements OnInit {
    @Input() data: string;

    constructor(
        private languageQuery: LanguageQuery,
        private socketService: SocketService,
        private coachingQuery: CoachingQuery,
        private coachingService: CoachingService,
        private weeklyPlannerService: WeeklyPlannerService,
        private translationService: TranslationService,
        private stepService: StepService,
        private changeDetectorRef: ChangeDetectorRef,
        private loggingTool: LoggingTool,
    ) {}

    private locale: string;

    public weekDays: { day: Date; dayPlans: IWeeklyPlanner[] }[] = [];
    public from: string;
    public to: string;
    public items: Exercise[] = [];
    public currentLanguage: any;
    public inStepEditor = false;
    public weeklyPlannerType = WeeklyPlannerType.SELECT;
    public hasScheduledNextModule: boolean;

    get strings() {
        return this.translationService.strings.coaching.weeklyPlannerScreen;
    }

    get commons() {
        return this.translationService.strings.common;
    }

    ngOnInit(): void {
        this.locale = (this.languageQuery.getActive() as Language).code;
        this.from = moment().format('Do MMM');
        this.to = moment().add(6, 'days').format('Do MMM');

        setTimeout(() => {
            this.readPlan();
            this.listenForSettingsChanges();
        }, 1000);

        (this.languageQuery.selectActive() as Observable<Language>)
            .pipe(untilDestroyed(this))
            .subscribe((activeLanguage: Language) => {
                this.currentLanguage = activeLanguage;
            });

        if (this.data) {
            this.inStepEditor = true;
            const parameters = this.stepService.stringToBlotParameterValues(this.data);
            if (parameters?.length) {
                this.weeklyPlannerType = parameters[0] as WeeklyPlannerType;
            }
        }
    }

    public onSaveClicked(weeklyPlanner: WeeklyPlanner): void {
        this.weeklyPlannerService
            .create(weeklyPlanner)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.readPlan();
            });
    }

    public onUpdateClicked(plan: IWeeklyPlanner): void {
        this.weeklyPlannerService
            .update(plan)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    // On successful update
                    this.readPlan();
                },
                error => {
                    // On error
                    this.loggingTool.error(error, { source: `${WeeklyPlannerComponent.name}` });
                    // still read plan and update to the latest plans in view
                    this.readPlan();
                },
            );
    }

    public onDeleteClicked(id: string): void {
        this.weeklyPlannerService
            .delete(id)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.readPlan());
    }

    private readPlan(): void {
        this.weeklyPlannerService
            .read()
            .pipe(untilDestroyed(this))
            .subscribe(weeklyPlan => {
                this.weekDays = [];
                this.addCurrentWeekDays();
                for (const plan of weeklyPlan) {
                    const index = this.weekDays.findIndex(
                        item => moment(item.day).startOf('day').diff(moment(plan.date).startOf('day'), 'days') === 0,
                    );
                    if (index >= 0) {
                        this.weekDays[index].dayPlans.push(plan);
                    }
                }
                this.hasScheduledNextModule = weeklyPlan.some(
                    plan =>
                        plan.weeklyPlannerType === WeeklyPlannerType.NEXT_MODULE &&
                        plan.eventData === this.coachingService.getKeyOfNextUnlockedModule(),
                );

                this.changeDetectorRef.markForCheck();
            });
    }

    private addCurrentWeekDays(): void {
        for (let i = 0; i <= 6; i++) {
            this.weekDays.push({
                dayPlans: [],
                day: moment().locale(this.locale).add(i, 'days').toDate(),
            });
        }
    }

    private generateExerciseOptions(coaching: ICoaching, customExercises: string[]): void {
        const exerciseMenuItems = [];

        const isUnlocked = (key: MenuItemKey) =>
            coaching.modules
                .filter(m => m.completed)
                .find(module => module.module.menu === key || module.module.secondaryMenu === key);

        if (isUnlocked(MenuItemKey.RELAXATION)) {
            exerciseMenuItems.push({
                name: this.strings.relaxationExercise(),
                custom: false,
            });
        }

        if (isUnlocked(MenuItemKey.BREATHING_MEDITATION)) {
            exerciseMenuItems.push({
                name: this.strings.breathingExercise(),
                custom: false,
            });
        }

        customExercises.forEach(customExercise => exerciseMenuItems.push({ name: customExercise, custom: true }));

        // Add option to include custom options todo this seems to not belong here
        exerciseMenuItems.push({ name: this.strings.setNewExercise(), custom: false });
        this.items = exerciseMenuItems;
    }

    private listenForSettingsChanges(): void {
        this.weeklyPlannerService.fetchSettings().pipe(untilDestroyed(this)).subscribe();

        this.weeklyPlannerService.settings.pipe(untilDestroyed(this)).subscribe(currentSettings => {
            const activeCoaching = this.coachingQuery.getActive() as ICoaching;
            this.generateExerciseOptions(activeCoaching, currentSettings?.customExercises || []);
        });
    }
}
