import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { RoleStore, State } from './role.store';
import { IRole } from './role.model';

@Injectable({
    providedIn: 'root',
})

/**
 * role query
 */
export class RoleQuery extends QueryEntity<State, IRole> {
    constructor(protected store: RoleStore) {
        super(store);
    }
}
