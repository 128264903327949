export enum NutritionType {
    FRUITS = 'fruits',
    VEGETABLES = 'vegetables',
    WHOLE_GRAIN = 'wholeGrain',
    LOW_FAT_MILK = 'lowFatMilk',
    GOOD_FATS = 'goodFats',
    SALT = 'salt',
    RED_MEAT = 'redMeat',
    SUGAR = 'sugar',
}

export function getNutritionIcon(type: NutritionType): string {
    switch (type) {
        case NutritionType.VEGETABLES:
            return 'broccoli';
        case NutritionType.WHOLE_GRAIN:
            return 'cereal';
        case NutritionType.LOW_FAT_MILK:
            return 'milk';
        case NutritionType.GOOD_FATS:
            return 'nuts';
        case NutritionType.SALT:
            return 'salt';
        case NutritionType.RED_MEAT:
            return 'meat';
        case NutritionType.SUGAR:
            return 'sweet';
        case NutritionType.FRUITS:
        default:
            return 'apple';
    }
}
