import { ID } from '@datorama/akita';
import { LanguageCode } from '../../../../../../backend/src/shared/modules/language/enums/language.enum';

/**
 * Language class
 * */
export interface Language {
    _id: ID;

    code: LanguageCode;
    label: string;
    active: boolean;
}

/**
 * factory function
 * @param id
 * @param label
 * @param code
 * @param active
 */
export function createLanguage(id: ID, label: string, code: string, active: boolean) {
    return { _id: id, label: label, code: code, active: active } as Language;
}
