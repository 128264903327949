export enum SessionActions {
    UPDATE_USER = 'session:updateUser',
    GENERATE_ACCESS_CODE = 'session:generateAccessCode',
    REMOVE_ACCESS_CODE = 'session:removeAccessCode',
    REMOVE_ACCOUNT = 'session:removeAccount',
    REVOKE_CONSENT = 'session:revokeConsent',
    LOGOUT = 'session:logout',
    EXPORT_USER_DATA_AS_DOWNLOAD = 'session:exportUserAsDownload',
    EXPORT_USER_DATA_AS_STREAM = 'session:exportUserDataAsStream',
    AUTH_TOKEN_UPDATE = 'session:authTokenUpdate',
    GET_MEDICAL_ACCESS_CODE = 'user:getMedicalAccessCode',
    JWT_NOT_VERIFIED = 'session:jwtNotVerified',
}
