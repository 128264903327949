import { RouterModule, Routes } from '@angular/router';
import { Injectable, ModuleWithProviders } from '@angular/core';
import { I18nNamespace, TranslationService } from './shared/translation/translation.service';

const I18nNamespaceResolver = (namespace: I18nNamespace) => {
    @Injectable({ providedIn: 'root' })
    class Resolver {
        constructor(private transService: TranslationService) {}

        async resolve(): Promise<void> {
            await this.transService.loadNamespace(namespace);
        }
    }

    return Resolver;
};

// declare app routes
const appRoutes: Routes = [
    // doc child routes
    {
        path: 'doc',
        loadChildren: () => import('./modules/doc/doc.module').then(m => m.DocModule),
        resolve: {
            data: I18nNamespaceResolver('doc'),
        },
    },

    // lazy load waiting room module
    {
        path: 'waitingRoom',
        loadChildren: () => import('./modules/waiting-room/waiting-room.module').then(m => m.WaitingRoomModule),
        resolve: {
            data: I18nNamespaceResolver('waitingRoom'),
        },
    },

    // lazy loaded login module
    {
        path: 'session',
        loadChildren: () => import('./modules/session/session.module').then(m => m.SessionModule),
        data: { preload: true },
        resolve: {
            data: I18nNamespaceResolver('session'),
        },
    },

    // lazy loaded admin module
    { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },

    // coaching child routes
    {
        path: 'coaching',
        loadChildren: () => import('./modules/coaching/coaching.module').then(m => m.CoachingModule),
        resolve: {
            data: I18nNamespaceResolver('coaching'),
        },
    },

    // redirect for simpler reg. link
    { path: 'register', redirectTo: 'session/registration' },

    // redirect for sbk atm tool links (listening on schlafgut.sbk.org)
    { path: 'aktivieren', redirectTo: 'session/sbk/activate/' },
    { path: 'passwort-vergessen', redirectTo: 'session/sbk/reset/' },
    { path: 'registrieren', redirectTo: 'session/sbk/registration' },

    // fallback
    { path: '**', redirectTo: 'coaching', pathMatch: 'full' },
];

// export routes
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {});
