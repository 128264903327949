import { LoggingTool } from './contract';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SentryLoggingTool implements LoggingTool {
    constructor() {
        Sentry.init({
            // having this key hardcoded here is fine: https://forum.sentry.io/t/dsn-private-public/6297/2
            dsn: 'https://0f9a72dd8238e28beab2efedd033e98a@sentry.mementor.de/4',
            environment: environment.name,
            integrations: [
                new Sentry.BrowserTracing({ routingInstrumentation: Sentry.routingInstrumentation }),
                new Sentry.Replay(),
            ],
            tracesSampleRate: environment.name === 'production' ? 0.5 : 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            // TODO investigate whether tracePropagationTargets property can be beneficial for us
        });
    }

    public debug(message: string, extras?: Record<string, unknown>) {
        Sentry.withScope(scope => {
            scope.setLevel('debug');
            if (extras) scope.setExtras(extras);
            Sentry.captureMessage(message);
        });
    }

    public error(error: unknown, extras?: Record<string, unknown>) {
        Sentry.withScope(scope => {
            scope.setLevel('error');
            if (extras) scope.setExtras(extras);
            Sentry.captureException(error);
        });
    }

    public info(message: string, extras?: Record<string, unknown>) {
        Sentry.withScope(scope => {
            scope.setLevel('info');
            if (extras) scope.setExtras(extras);
            Sentry.captureMessage(message);
        });
    }

    public warn(message: string, extras?: Record<string, unknown>) {
        Sentry.withScope(scope => {
            scope.setLevel('warning');
            if (extras) scope.setExtras(extras);
            Sentry.captureMessage(message);
        });
    }
}
