import { Pipe, PipeTransform } from '@angular/core';
import { Numerus } from '../../../../../backend/src/modules/coaching/enums/numerus.enum';

/**
 * pipe that returns the dictionary key for a numerus
 */
@Pipe({
    name: 'numToDictionaryKey',
    pure: false,
})
export class NumerusToDictionaryKeyPipe implements PipeTransform {
    transform(unit: Numerus, ...args: unknown[]): string {
        switch (unit) {
            case Numerus.WHOLE_NUMBER:
                return '';
            case Numerus.DAY:
                return `general_day_days`;
            case Numerus.WEEK:
                return `general_week_weeks`;
            case Numerus.POINT:
                return `general_portion_portions`;
            case Numerus.PERCENT:
                return `general_percent`;
            case Numerus.PERCENTAGE_POINT:
                return `general_percentage_points`;
            case Numerus.BLOOD_PRESSURE:
                return `general_mmHg`;
            case Numerus.PULSE:
                return `general_bpm`;
            case Numerus.ENTRIES:
                return `general_entries`;
            case Numerus.PIECE:
                return `general_piece_pieces`;
            case Numerus.PORTION:
                return `general_portion_portions`;
            case Numerus.EURO:
                return `general_euros`;
            case Numerus.CIGARETTES:
                return `general_cigarettes`;
            case Numerus.GLASS:
                return `general_drinking_glasses`;
            case Numerus.MINUTE:
                return `general_minute_minutes`;
        }
        return '';
    }
}
