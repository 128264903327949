export enum MenuItemKey {
    DIABETES_JOURNAL = 'diabetesJournal',
    GOALS = 'goals',
    CHALLENGES = 'challenges',
    PERCEPTION_TRAINING = 'perceptionTraining',
    MINDFULNESS_EXERCISES = 'mindfulness-exercises',
    SLEEP_RHYTHM = 'sleepRhythm',
    RECIPES = 'recipes',
    TRI_EVALUATION_STRESS = 'triEvaluationStress',
    WEEKLY_PLANNER = 'weeklyPlanner',
    BREATHING_MEDITATION = 'breathingMeditation',
    NUTRITION_PYRAMID = 'nutritionPyramid',
    MEDICATION_PLAN = 'medicationPlan',
    NUTRITION_PROTOCOL = 'nutrition-protocol',
    HYPERTENSION_JOURNAL = 'hypertensionJournal',
    RELAXATION = 'relaxation',
    INSOMNIA_CYCLE = 'insomniaCycle',
    SLEEP_WINDOW = 'sleepWindow',
    SLEEP_JOURNAL = 'sleepJournal',
    TRI_EVALUATION_ACTIVITY = 'triEvaluationActivity',
    BODY_SCAN = 'bodyScan',
}
