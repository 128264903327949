<div class="dialog-header" fxLayout="row">
    <div
        *ngIf="showBackButton"
        fxFlex="20px"
        class="chevron"
        fxLayoutAlign="start center"
        (click)="backButtonClicked.emit()"
    >
        <mat-icon>chevron_left</mat-icon>
    </div>

    <div fxFlex="grow" fxLayoutAlign="center center">
        <h2 [innerHTML]="title"></h2>
    </div>

    <div fxFlex="30px" *ngIf="showCloseButton">
        <button mat-icon-button tabindex="-1" matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
