export enum ThryveDevice {
    Fitbit = 1,
    Garmin = 2,
    Polar = 3,
    Apple = 5,
    Samsung = 6,
    Misfit = 7,
    Withings = 8,
    Thryve_Connector,
    Thryve_Wearable = 10,
    Strava = 11,
    GoogleFitREST = 12,
    MyFitnessPal = 14,
    Runtastic = 15,
    OmronConnect = 16,
    Suunto = 17,
    Oura = 18,
    Runkeeper = 19,
    Endomondo = 20,
    iHealth = 21,
    Abbott = 22,
    iSens = 23,
    SleepAsAndroid = 24,
    Clue = 25,
    HealthForYou = 26,
    Beurer = 27,
    OmronWellness = 29,
    Lykon = 30,
    Lifesum = 31,
    Ovy = 32,
    Motiv = 33,
    Medisafe = 34,
    B_Braun = 35,
    RocheAccu = 36,
    Biomes = 37,
    Huawei_Health = 38,
    Amazfit = 39,
    GoogleFit_Native = 40,
}

export namespace ThryveDevice {
    /**
     * get the human-readable name of the connected thryve device
     * @param thryveDevice
     */
    export function thryveDeviceName(thryveDevice: ThryveDevice): string {
        const enumName = ThryveDevice[thryveDevice];
        return enumName;
    }

    /**
     * check if for a thryveDevice, in order to check the activity, the metabolic equivalent values should be used rather than the 'activity' properties
     * @param thryveDevice
     */
    export function useMetInsteadOfActivity(thryveDevice: ThryveDevice): boolean {
        return [ThryveDevice.Apple, ThryveDevice.iHealth, ThryveDevice.Garmin].indexOf(thryveDevice) >= 0;
    }

    /**
     * check if for a thryveDevice, in order to check the activity, the daily values should be used as opposed to the dynamicEpochValues
     * @param thryveDevice
     */
    export function useDailyActivityValues(thryveDevice: ThryveDevice): boolean {
        return [ThryveDevice.Withings, ThryveDevice.Fitbit].indexOf(thryveDevice) >= 0;
    }
}
