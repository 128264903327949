<div id="header-wrapper" [class.white]="white" [class.backdrop]="alwaysShowTitle || scrollPosition > 10">
    <div class="flex-container">
        <div class="icon-menu-wrapper">
            <button
                mat-icon-button
                [attr.aria-label]="
                    (navigationState$ | async)?.open
                        ? strings.header.closeScrollableMenu()
                        : strings.header.openScrollableMenu()
                "
                class="menu-toggle gray"
                (click)="toggleNavigation()"
            >
                <mat-icon class="icon-menu" *ngIf="(navigationState$ | async)?.open; else closed" inline="true">
                    menu_open
                </mat-icon>
                <ng-template #closed>
                    <mat-icon class="icon-menu" inline="true">menu</mat-icon>
                </ng-template>
            </button>
        </div>

        <div
            style="text-align: center"
            *ngIf="!(appContextQuery.getValue().application === glykioApp); else glykioHeader"
        >
            <span class="title header" [class.show]="alwaysShowTitle || scrollPosition > 50">{{ title }}</span>
        </div>
        <ng-template #glykioHeader>
            <div class="show title header glykio-expert-header">
                <button mat-button (click)="contactExpert()" color="primary" style="text-decoration: underline">
                    <mat-icon color="primary" class="header-icon" svgIcon="message-expert"></mat-icon>
                    {{ glykioHeaderString() }}
                </button>
            </div>
        </ng-template>

        <div style="text-align: right; margin-right: 3px">
            <button
                mat-button
                class="feedback-button gray"
                [matTooltip]="'general_support' | localise"
                (click)="reportProblem()"
            >
                <mat-icon svgIcon="support" class="header-icon"></mat-icon>
            </button>
        </div>
    </div>
    <div class="glykio-title title header" *ngIf="appContextQuery.getValue().application === glykioApp">
        <span>{{ title }}</span>
    </div>
</div>
