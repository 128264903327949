import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Branding } from '../../../../../../backend/src/shared/modules/authentication/enums/brandings.enum';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { Region } from '../../../../../../backend/src/shared/entities/region.enum';

export interface AppContextState {
    branding: Branding;
    application: Application;
    region: Region;
}

export function createInitialState(): AppContextState {
    return {
        branding: Branding.MEMENTOR,
        application: Application.SOMNIO,
        region: Region.DEFAULT,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'appContext' })
export class AppContextStore extends Store<AppContextState> {
    constructor() {
        super(createInitialState());
    }
}
