import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import * as io from 'socket.io-client';
import * as get from 'lodash/get';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IUser } from '../state/user/user.model';
import { ConfigService } from '../config/config.service';
import { AuthStore } from '../state/auth/auth.store';
import { NotificationService } from '../notification/notification.service';
import { NotificationType } from '../notification/notification-type.enum';
import { ErrorCode } from '../../../../../backend/src/shared/modules/errorHandling/enums/errors.enum';
import { CoachingQuery } from '../state/coaching/coaching.query';
import { CoachingActions } from '../../../../../backend/src/modules/coaching/actions/coaching.actions';
import { ICoaching } from '../state/coaching/coaching.model';
import { ModuleMeta } from '../../../../../backend/src/modules/coaching/models/moduleMeta/module-meta.model';
import { SessionActions } from '../../../../../backend/src/modules/session/actions/session.actions';
import { WebAppInterface } from '../../android/WebAppInterface';
import { MMCookie } from '../enums/cookie-enum';
import { AppContextQuery } from '../application/state/app-context.query';
import { DeviceQuery } from '../device/state/device.query';
import { IOSNativeMessages } from '../../ios/ios-native-messages.enum';
import { UserQuery } from '../state/user/user.query';
import { IosService } from '../../ios/ios.service';
import { LoggingTool } from '../../../tools/logging/contract';

declare var android: WebAppInterface;

@Injectable()
@UntilDestroy({ checkProperties: true })
/**
 * socket service
 */
export class SocketService implements OnDestroy {
    /**
     * time in ms after which a non-established connection is considered as lost
     */
    public readonly MAX_CONNECTION_TIMEOUT = 10000;

    /**
     * time in ms to wait after losing connection to show the 'disconnected' hint
     */
    private readonly SHOW_DISCONNECTED_TIMEOUT = 10000;

    /**
     * the actual socket managing the connection
     */
    public socket: any;

    /**
     * whether user if offline or nor (only true when losing connection for x seconds)
     */
    public offline = false;

    public connecting = false;

    private offlineTimout: NodeJS.Timer;

    public isDevEnvironment: boolean;

    private timer: NodeJS.Timer;

    /**
     * used to prevent sign out when first socket connection fails
     * and only a white screen is shown to the user
     * @private
     */
    private _preventSignOut = false;

    private loggingOut: boolean;

    set preventSignOut(hasTimedOut: boolean) {
        this._preventSignOut = hasTimedOut;
    }

    constructor(
        private configService: ConfigService,
        private notificationService: NotificationService,
        private coachingQuery: CoachingQuery,
        private router: Router,
        private jwtHelperService: JwtHelperService,
        private http: HttpClient,
        private authStore: AuthStore,
        private appContextQuery: AppContextQuery,
        private userQuery: UserQuery,
        private deviceQuery: DeviceQuery,
        private loggingTool: LoggingTool,
        private oauthService: OAuthService,
    ) {
        this.isDevEnvironment = this.configService.isDevEnvironment();
    }

    ngOnDestroy(): void {
        if (this.offlineTimout) {
            clearTimeout(this.offlineTimout);
        }
        clearInterval(this.timer);
    }

    public isAuthTokenValid(): boolean {
        // get the tokens from the local storage
        const authToken = sessionStorage.getItem(MMCookie.AUTH_TOKEN);

        const decodedAuthToken = this.jwtHelperService.decodeToken(authToken);

        // check expiration on tokens
        const isAuthTokenExpired = this.jwtHelperService.isTokenExpired(authToken);

        // we need a user, auth token in order to continue
        if (!decodedAuthToken?.user || !authToken || isAuthTokenExpired) {
            return false;
        }
        return true;
    }

    public getNewAuthToken(user: IUser): Observable<string> {
        return new Observable(observer => {
            this.http
                .post(
                    this.configService.BASE_URL + '/api/session/auth-token',
                    { userId: user._id },
                    { responseType: 'text' },
                )
                .subscribe({
                    next: (token: string) => {
                        observer.next(token);
                    },
                    error: error => {
                        observer.error(error);
                    },
                    complete: () => {
                        observer.complete();
                    },
                });
        });
    }

    public connectToSocket(): Observable<IUser> {
        return new Observable(observer => {
            const authToken = sessionStorage.getItem(MMCookie.AUTH_TOKEN);
            const decodedAuthToken = this.jwtHelperService.decodeToken(authToken);

            if (!this.isAuthTokenValid()) {
                this.handleFailedSocketConnection();
            }

            if (this.connecting) {
                this.tryAgainInInterval(observer, decodedAuthToken?.user);
                return;
            }

            this.loggingOut = false;

            if (this.isDevEnvironment) {
                this.loggingTool.debug(
                    `Calling connect on socket.service - socket set? ${JSON.stringify(
                        !!this.socket,
                    )}, connected? ${JSON.stringify(
                        this.socket && this.socket.connected,
                    )}, connecting? ${JSON.stringify(this.connecting)}, loggingOut? ${JSON.stringify(
                        this.loggingOut,
                    )}, offline? ${JSON.stringify(this.offline)}`,
                );
            }

            // if connect is called in a connected socket, simply call back
            if (this.socket && this.socket.connected) {
                observer.next(decodedAuthToken?.user);
                observer.complete();
                return;
            }

            this.connecting = true;

            const { application } = this.appContextQuery.getValue();
            const version = this.configService.versionByApp[application];

            this.socket = io.connect(this.configService.BASE_URL + '/somnio', {
                query: {
                    userId: decodedAuthToken?.user._id,
                    applicationName: application,
                    applicationVersion: version,
                },
                auth: {
                    token: authToken,
                },
            });

            this.socket.on(SessionActions.JWT_NOT_VERIFIED, () => {
                this.handleFailedSocketConnection();
            });

            // listen for disconnect event
            this.socket.on('disconnect', reason => {
                this.connecting = false;
                if (this.isDevEnvironment) {
                    this.loggingTool.debug(`Disconnect event occurred - reason: ${reason}`);
                }

                // wait five seconds to show disconnect
                this.offlineTimout = setTimeout(() => {
                    if (!this.loggingOut) {
                        this.notificationService.displayNotification(
                            NotificationType.ERROR,
                            'notification_socket_disconnect',
                            [],
                            NotificationService.DURATION_LONG,
                        );
                    }

                    this.offline = true;
                }, this.SHOW_DISCONNECTED_TIMEOUT);
            });

            // listen for reconnect event
            this.socket.on('connect', () => {
                this._preventSignOut = false;

                if (this.isDevEnvironment) {
                    this.loggingTool.debug(
                        `Connect event called on socket.service - socket set? ${JSON.stringify(
                            !!this.socket,
                        )}, connected? ${JSON.stringify(
                            this.socket && this.socket.connected,
                        )}, connecting? ${JSON.stringify(this.connecting)}, loggingOut? ${JSON.stringify(
                            this.loggingOut,
                        )}, offline? ${JSON.stringify(this.offline)}`,
                    );
                }

                this.connecting = false;
                this.authStore.updateWithUserData(
                    decodedAuthToken?.user._id,
                    '',
                    authToken,
                    decodedAuthToken?.user.language,
                );

                // if reconnect
                setTimeout(() => {
                    this.updateSocketPropertiesAfterReconnect();
                }, 1000);

                // from reconnect
                if (this.offline) {
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_socket_reconnect',
                    );
                }

                this.offline = false;

                if (this.offlineTimout) {
                    clearTimeout(this.offlineTimout);
                }

                // wait out jwt token verification before mobile device auth token update
                setTimeout(() => {
                    if (this.socket.connected) {
                        // update the android device token
                        // android handling
                        if (typeof android !== 'undefined' && decodedAuthToken?.user._id) {
                            // inform android interface about the credentials
                            try {
                                android.userLoggedIn(decodedAuthToken?.user._id, authToken);
                            } catch (e) {
                                // backward compatibility for android apps that did not implement this method
                                // => simply skip calling the method that is not available and continue the regular login procedure
                            }
                        }

                        // update the ios device tokens during socket connect
                        if (this.deviceQuery.getValue().app && this.deviceQuery.getValue().iOS) {
                            (this.userQuery.selectActive() as Observable<IUser>)
                                .pipe(untilDestroyed(this))
                                .subscribe(activeUser => {
                                    this.sendUserDetailsToIOS(activeUser, authToken);
                                });
                        }
                    }
                }, this.MAX_CONNECTION_TIMEOUT);

                observer.next(decodedAuthToken.user);
            });

            // listen for reconnect success event
            this.socket.on('reconnect', attempt => {
                if (this.isDevEnvironment) {
                    this.loggingTool.debug(`Reconnect success with attempt ${attempt}`);
                }
            });

            if (this.isDevEnvironment) {
                // listen for reconnect attempt
                this.socket.on('reconnect_attempt', attempt => {
                    this.loggingTool.debug(`Reconnect attempt ${attempt}`);
                });
            }
            if (this.isDevEnvironment) {
                // listen for reconnect errors
                this.socket.on('reconnect_error', error => {
                    const msg = get(error, 'message');
                    const pollError = msg && msg.indexOf('xhr poll error') >= 0;

                    this.loggingTool.error(`Reconnect failure: ${error} - poll error? ${JSON.stringify(pollError)}`);
                });
            }

            // listen for general socket io errors
            this.socket.on('error', error => {
                this.loggingTool.error(`socket io error: ${error}`);
            });

            // listen to token updates
            this.socket.on(SessionActions.AUTH_TOKEN_UPDATE, payload => {
                this.loggingTool.debug('Auth token remotely updated');
                sessionStorage.setItem(MMCookie.AUTH_TOKEN, payload?.authToken);
            });

            // initialise timeout
            let timeOut = 0;

            // prevent creation of multiple parallel timers
            if (this.timer) {
                clearInterval(this.timer);
            }

            // check for a valid socket connection every 100ms
            this.timer = setInterval(() => {
                if (!window.navigator.onLine) {
                    clearInterval(this.timer);
                }

                // adjust timeOut
                timeOut += 300;

                // check if connection timeout reached
                if (timeOut >= this.MAX_CONNECTION_TIMEOUT) {
                    timeOut = 0;
                    if (window.navigator.onLine && !this.socket.connected && !this._preventSignOut) {
                        this.handleFailedSocketConnection();
                    }
                    observer.error(new Error('err_timeout'));
                } else if (this.socket.connected) {
                    observer.next(decodedAuthToken?.user);
                    observer.complete();

                    // clear timer
                    clearInterval(this.timer);
                }
            }, 300);
        });
    }

    private tryAgainInInterval(observer: Subscriber<IUser>, user: IUser): void {
        let counter = 0;

        if (this.isDevEnvironment) {
            this.loggingTool.debug(`retry again count: ${counter}`);
        }

        const connectingInterval = setInterval(() => {
            if (this.socket && this.socket.connected) {
                observer.next(user);
                observer.complete();
                clearInterval(connectingInterval);
                return;
            }
            if (counter >= this.MAX_CONNECTION_TIMEOUT) {
                clearInterval(connectingInterval);
            }
            counter += 500;
        }, 500);
    }

    public handleFailedSocketConnection(): void {
        this.connecting = false;

        clearInterval(this.timer);
        clearTimeout(this.offlineTimout);

        const activeUser: IUser = this.userQuery.getActive() as IUser;

        if (activeUser) {
            this.loggingTool.debug('User found in local storage. Logging out');
            this.fire(SessionActions.LOGOUT, {}).subscribe();
        } else {
            // clear tokens
            sessionStorage.removeItem(MMCookie.AUTH_TOKEN);

            this.oauthService.logOut();

            if (this.router.url !== '/session/login') {
                this.notificationService.displayNotification(NotificationType.INFO, 'notification_session_expired');
            }
        }

        this.loggingTool.debug('after failed connection redirect to login with refresh');
        // TODO test if this works or not
        this.router.navigate(['/session']).then();
    }

    /**
     * wraps emit event in an observable
     */
    public fire(name: string, load: any): Observable<any> {
        return new Observable<any>(observer => {
            if (this.socket && this.socket.connected) {
                this.emitFromSocket(name, load, observer);
                return;
            }

            // wait for socket to be established and try again
            let counter = 0;
            const limit = 3000;
            const connectInterval = setInterval(() => {
                if (this.socket && this.socket.connected) {
                    this.emitFromSocket(name, load, observer);

                    clearInterval(connectInterval);
                } else if (counter >= limit) {
                    observer.error({
                        code: ErrorCode.CONNECTION_LOST_RETRY,
                        message: 'No socket connection',
                    });
                    this.connectToSocket().subscribe();

                    clearInterval(connectInterval);
                }
                counter += 300;
            }, 300);
        });
    }

    private emitFromSocket(name: string, load: any, observer: Subscriber<any>): void {
        this.socket.emit(name, load, response => {
            if (response.error) {
                observer.error(response);
            } else {
                observer.next(response);
                observer.complete();
            }
        });
    }

    /**
     * update socket properties after reconnect
     * @private
     */
    private updateSocketPropertiesAfterReconnect(): void {
        // set active coaching
        const activeCoaching: ICoaching = this.coachingQuery.getActive() as ICoaching;
        if (activeCoaching) {
            this.fire(CoachingActions.SET_ACTIVE_COACHING, activeCoaching._id).subscribe();
        }

        // set active module meta
        const activeModuleMeta: ModuleMeta = this.coachingQuery.getValue().activeMeta;
        if (activeModuleMeta) {
            this.fire(CoachingActions.SET_ACTIVE_MODULE_META, activeModuleMeta._id).subscribe();
        }
    }

    private sendUserDetailsToIOS(user: IUser, authToken: string): void {
        if (!user || !authToken) {
            return;
        }

        const useriOSThryveToken = IosService.retrieveUserThryveToken(user);
        const useriOSThryveUsages = IosService.retrieveUserThryveUsages(user);

        const message = !!useriOSThryveToken
            ? IOSNativeMessages.SAVE_LOGIN_DETAILS +
              '|' +
              user._id.toString() +
              '|' +
              authToken +
              '|' +
              useriOSThryveToken +
              '|' +
              useriOSThryveUsages
            : IOSNativeMessages.SAVE_LOGIN_DETAILS + '|' + user._id.toString() + '|' + authToken;
        try {
            window['webkit'].messageHandlers.postMessageListener.postMessage(message);
        } catch (e) {
            this.notificationService.displayNotification(
                NotificationType.ERROR,
                'ios_message_failure',
                null,
                NotificationService.DURATION_LONG,
            );
        }
    }
}
