export enum IOSNativeMessages {
    START_RATING = 'rating',
    START_THRYVE_INTEGRATION = 'integrateHK',
    STOP_THRYVE_INTEGRATION = 'disconnectHK',
    START_THRYVE_SYNC = 'syncHK',

    SAVE_LOGIN_DETAILS = 'saveUserLoginDetails',
    RESET_NOTIFICATION_BADGE = 'resetNotificationBadge',

    // region
    // # Hanko authentication messages
    WEB_HANKO_IS_PLATFORM_AVAILABLE = 'isUserVerifyingPlatformAuthenticatorAvailable',
    WEB_HANKO_CREATE_CREDENTIAL = 'hankoCreateCredential',
    WEB_HANKO_USE_CREDENTIAL = 'hankoUseCredential',
    // endregion

    // local push notification
    LOCAL_NOTIFICATION_MESSAGE = 'localNotification',
}
