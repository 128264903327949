import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StepState } from './step.store';
import { IStep } from './step.model';
import { StepStore } from './step.store';

@Injectable({
    providedIn: 'root',
})
@QueryConfig({
    sortBy: 'index',
    sortByOrder: Order.ASC,
})

/**
 * module query
 */
export class StepQuery extends QueryEntity<StepState, IStep> {
    /**
     * constructor
     */
    constructor(protected store: StepStore) {
        super(store);
    }
}
