<div [ngClass]="isInStep ? 'nutrition-protocol-height-step' : 'nutrition-protocol-height'" [class.report]="isInReport">
    <mm-nutrition-protocol-score
        *ngFor="let nutrition of nutritionValues"
        [type]="nutrition.type"
        [averageValue]="nutrition.averageValue"
    ></mm-nutrition-protocol-score>

    <mat-card appearance="outlined" style="margin: 10px 5px">
        <div class="dash-score-header">
            <div class="dash-score-text">DASH-Score</div>
            <mat-icon class="dash-score-icon icon-very-light" [matTooltip]="'ht_tip_dash_score_intensity' | localise"
                >help_outline</mat-icon
            >
        </div>

        <mat-card-content>
            <div class="dash-score">{{ averageDashScore }}</div>
        </mat-card-content>
    </mat-card>
</div>
