export enum CoachingActions {
    GET = 'coachings:get',
    GET_SOURCE = 'coachings:getSource',
    GET_COACHING_KEYS = 'coachings:getCoachingKeys',
    GET_BY_ID = 'coaching:getById',
    GET_FOR_SOCKET_USER = 'coaching:getForSocketUser',
    GET_FOR_USER = 'coaching:getForUser',
    CREATE = 'coaching:create',
    UPDATE = 'coaching:update',
    REMOVE = 'coaching:remove',
    PUBLISH = 'coaching:publish',
    NEW_VERSION = 'coaching:newVersion',
    UPDATE_AVAILABLE = 'coaching:updateAvailable',
    UPDATE_TO_LATEST = 'coaching:updateToLatest',
    UPDATE_ALL_MODULES = 'coaching:updateAllModules',
    UNLOCK_ALL_MODULES = 'coaching:openAllModules',
    LOCK_ALL_MODULES = 'coaching:closeAllModules',
    COMPLETE_MODULE = 'coaching:completeModule',
    COMPLETE_DYNAMIC_MODULE_DEMO_ACTION = 'coaching:completeDynamicModuleDemoAction',
    REMOVE_WAIT_TIMES = 'coaching:removeWaitTimes',
    ADD_WAIT_TIMES = 'coaching:addWaitTimes',
    SET_ACTIVE_MODULE_META = 'coaching:setActiveModuleMeta',
    MARK_MENU_AS_OPENED = 'coaching:markMenuAsOpened',
    SET_ACTIVE_COACHING = 'coaching:setActive',
    MARK_SUMMARY_AS_OPENED = 'coaching:markSummaryAsOpened',
    DOWNLOAD_ALL_SUMMARIES = 'coaching:downloadAllSummaries',
    RATING_CONDITION_MET = 'coaching:ratingConditionMet',
    UPDATE_APP_RATING = 'coaching:updateAppRating',
    UPDATE_COACHING_SETTINGS = 'coaching:updateCoachingSettings',
    UPDATE_COACHING_MODULES = 'coaching:updateCoachingModules',
    SET_WAITING_ROOM_STATE = 'coaching:setWaitingRoomState',
    COMPUTE_NEXT_DYNAMIC_MODULE_FROM_MODULE_STEP = 'coaching:computeNextDynamicModuleFromModuleStep',
    EVALUATE_EXPRESSION = 'coaching:evaluateExpression',
    ARE_REQUIRED_ENTRIES_FULFILLED = 'module:areRequiredEntriesFulfilled',
}
