import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StepProgressState } from '../step-progress-state.enum';
import { AnimationProgressState } from '../animation-progress-state.enum';

export interface CoachState {
    progress: number;
    audioDuration: number;
    coachLoaded: boolean;
    paused: boolean;
    loadPreviousStep: boolean;
    togglePlayPause: boolean;
    stepProgressState: StepProgressState;
    animationProgressState: AnimationProgressState;
}

export interface InstructionQueue {
    type: string;
    name: string;
    delay: number;
    played?: boolean;
    markName?: string;
    start?: number;
}

export function createInitialState(): CoachState {
    return {
        progress: 0,
        audioDuration: 0,
        coachLoaded: false,
        paused: false,
        loadPreviousStep: false,
        togglePlayPause: false,
        stepProgressState: StepProgressState.IDLE,
        animationProgressState: AnimationProgressState.IDLE,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'coach' })
export class CoachStore extends Store<CoachState> {
    constructor() {
        super(createInitialState());
    }
}
