import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'order',
    pure: false,
})

/**
 * creates a ordering pipe
 * */
export class OrderPipe implements PipeTransform {
    transform(list: any, [property]): any {
        let reverse = false;

        if (property[0] === '-') {
            property = property.substr(1);
            reverse = true;
        }

        const ordered = _.sortBy(list, obj => {
            return obj[property];
        });

        return reverse ? ordered.reverse() : ordered;
    }
}
