import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ICoaching } from './coaching.model';
import { IModuleMeta } from '../module-meta/module-meta.model';

export interface CoachingState extends EntityState<ICoaching> {
    updateAvailable: boolean;
    activeMeta: IModuleMeta;
    keys: string[];
    loadingMinor: boolean;
}

@Injectable({
    providedIn: 'root',
})
/**
 * coaching store
 */
@StoreConfig({ name: 'coachings', idKey: '_id', resettable: true })
export class CoachingStore extends EntityStore<CoachingState, ICoaching> {
    /**
     * constructor
     */
    constructor() {
        super();
    }

    /**
     * minor loading
     * @param loading
     */
    public setMinorLoading(loading: boolean): void {
        this.update({ loadingMinor: loading });
    }
}
