import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'mm-data-access-prompt',
    templateUrl: './data-access-prompt.component.html',
    styleUrls: ['./data-access-prompt.component.sass'],
})

/**
 * confirmation prompt
 */
export class DataAccessPromptComponent implements OnInit, OnDestroy {
    /**
     * identifier
     */
    public identifier: string;

    /**
     * time left to take decision
     */
    public timeLeft = 100;

    /**
     * interval
     */
    private interval = null;

    /**
     * constructor
     */
    constructor(private dialogRef: MatDialogRef<DataAccessPromptComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
        this.identifier = data.identifier;
    }

    /**
     * called on component init
     */
    ngOnInit(): void {
        this.interval = setInterval(() => {
            this.timeLeft -= 1;

            if (this.timeLeft <= 0) {
                this.dialogRef.close(false);
            }
        }, 200);
    }

    /**
     * called on component destruction
     */
    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    /**
     * confirm
     */
    public confirm(): void {
        this.dialogRef.close(true);
    }
}
