import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateCalc' })
export class DateCalcPipe implements PipeTransform {
    transform(value: number, property: string): number {
        if (property === 'w') {
            // to weeks
            return value / 7;
        } else if (property === 'm') {
            // to months
            return value / 30;
        }
    }
}
