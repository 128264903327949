import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { localeDe } from '@mobiscroll/angular';
import { IWeeklyPlanner } from '../../../../shared/state/weekly-planner/weekly-planner.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Language } from '../../../../shared/state/language/language.model';
import { LanguageQuery } from '../../../../shared/state/language/language.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslationService } from '../../../../shared/translation/translation.service';
import { CoachingService } from '../../../../shared/state/coaching/coaching.service';
import { WeeklyPlannerType } from '../../../../../../../backend/src/shared/entities/weekly-planner-type.enum';
import { WeeklyPlanner } from '../../../../../../../backend/src/modules/coaching/modules/weekly-planner/models/weekly-planner.model';
import { WeeklyPlannerService } from '../../../../shared/state/weekly-planner/weekly-planner.service';
import { IWeeklyPlannerSettings } from '../../../../shared/state/weekly-planner/weekly-planner-settings.model';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'mm-weekly-planner-day',
    templateUrl: './weekly-planner-day.component.html',
    styleUrls: ['./weekly-planner-day.component.sass'],
})
export class WeeklyPlannerDayComponent implements OnInit {
    public static readonly DIALOG_WIDTH = '450px';

    get exercise(): AbstractControl {
        return this.addExerciseForm.get('exercise');
    }

    get customExercise(): AbstractControl {
        return this.addExerciseForm.get('customExercise');
    }

    get strings() {
        return this.translationService.strings.coaching;
    }

    get commons() {
        return this.translationService.strings.common;
    }

    constructor(
        private dialog: MatDialog,
        private languageQuery: LanguageQuery,
        private formBuilder: UntypedFormBuilder,
        private translationService: TranslationService,
        private coachingService: CoachingService,
        private weeklyPlannerService: WeeklyPlannerService,
    ) {
        // create the exercise addition form
        this.addExerciseForm = this.formBuilder.group({
            exercise: ['', [Validators.required]],
            customExercise: ['', []],
            time: ['', [Validators.required]],
        });

        this.addNextModuleForm = this.formBuilder.group({
            time: ['', [Validators.required]],
        });
    }

    @Input() day: Date;
    @Input() isFirstItem: boolean;
    @Input() isLastItem: boolean;
    @Input() items: any[];
    @Input() dayPlans: IWeeklyPlanner[];
    @Input() weeklyPlannerTypeOptions: WeeklyPlannerType;
    @Input() hasScheduledNextModule: boolean;
    @Output() saveClicked: EventEmitter<WeeklyPlanner> = new EventEmitter<WeeklyPlanner>();
    @Output() updateClicked: EventEmitter<IWeeklyPlanner> = new EventEmitter<IWeeklyPlanner>();
    @Output() deleteClicked: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('addItemTemplate') addItemTemplate: TemplateRef<string>;

    public addExerciseForm: UntypedFormGroup;
    public addNextModuleForm: UntypedFormGroup;
    public dialogRef: MatDialogRef<string, TemplateRef<string>>;
    public localeDe = localeDe;
    public currentLanguage$: Observable<Language>;
    public hasCustomInput = false;
    public weeklyPlannerType = WeeklyPlannerType.SELECT;

    public WeeklyPlannerType: typeof WeeklyPlannerType = WeeklyPlannerType;
    public customExercises: string[] = [];

    ngOnInit(): void {
        this.currentLanguage$ = this.languageQuery.selectActive() as Observable<Language>;
        if (this.weeklyPlannerTypeOptions && this.weeklyPlannerTypeOptions !== WeeklyPlannerType.SELECT) {
            // for the current step, the possible options have been pre-defined
            this.weeklyPlannerType = this.weeklyPlannerTypeOptions;
        }
        this.weeklyPlannerService
            .fetchSettings()
            .pipe(untilDestroyed(this))
            .subscribe((settings: IWeeklyPlannerSettings) => {
                this.customExercises = settings.customExercises;
            });
    }

    public onAddItemClicked(): void {
        this.dialogRef = this.dialog.open(this.addItemTemplate, { width: WeeklyPlannerDayComponent.DIALOG_WIDTH });
    }

    public saveItem(day: Date): void {
        const plan = {} as IWeeklyPlanner;
        this.populateNextModuleData(plan, day);
        this.populateExerciseData(plan, day);
        plan.weeklyPlannerType = this.weeklyPlannerType;
        this.saveClicked.emit(plan);
        this.dialogRef?.close();
        this.hasCustomInput = false;
    }

    public onClickExistingExercise(plan: IWeeklyPlanner): void {
        this.weeklyPlannerType = plan.weeklyPlannerType;

        this.populateForm(plan);

        this.dialogRef = this.dialog.open(this.addItemTemplate, {
            width: WeeklyPlannerDayComponent.DIALOG_WIDTH,
            data: { plan },
        });
    }

    public onUpdateClicked(plan: IWeeklyPlanner): void {
        plan.weeklyPlannerType = this.weeklyPlannerType;
        this.populateExerciseData(plan);
        this.updateClicked.emit(plan);
        this.dialogRef?.close();
        this.hasCustomInput = false;
    }

    private populateExerciseData(plan: IWeeklyPlanner, day?: Date): void {
        if (this.weeklyPlannerType !== WeeklyPlannerType.EXERCISE || !this.addExerciseForm.valid) {
            return;
        }
        if (this.hasCustomInput && this.customExercise.value !== '') {
            this.addUserCustomExercise(this.customExercise.value);
            this.exercise.setValue(this.customExercise.value);
        }
        const values = this.addExerciseForm.getRawValue();
        plan.date = values.time;
        if (day) {
            // set selected day if available
            plan.date = this.mergeDayAndTime(day, values.time);
        }
        plan.eventData = this.hasCustomInput
            ? this.addExerciseForm.getRawValue().customExercise
            : this.addExerciseForm.getRawValue().exercise;
    }

    private populateNextModuleData(plan: IWeeklyPlanner, day?: Date): void {
        if (this.weeklyPlannerType !== WeeklyPlannerType.NEXT_MODULE || !this.addNextModuleForm.valid) {
            return;
        }
        const values = this.addNextModuleForm.getRawValue();
        plan.date = values.time;
        plan.eventData = this.coachingService.getKeyOfNextUnlockedModule();
        if (day) {
            // set selected day if available
            plan.date = this.mergeDayAndTime(day, values.time);
        }
    }

    public onDeleteClicked(id: string): void {
        this.deleteClicked.emit(id);
        this.dialogRef.close();
        this.hasCustomInput = false;
    }

    public setHasCustomInput(selectedExercise: any): void {
        this.hasCustomInput = selectedExercise === this.strings.weeklyPlannerScreen.setNewExercise();
    }

    public removeUserCustomExercise(userExercise: string): void {
        if (!userExercise) {
            return;
        }
        this.weeklyPlannerService
            .updateSettings(this.customExercises.filter(exercise => exercise !== userExercise.trim()))
            .pipe(untilDestroyed(this))
            .subscribe(settings => {
                this.customExercises = settings.customExercises;
                this.exercise.setValue(null);
            });
    }

    private addUserCustomExercise(newExercise: string): void {
        if (
            !newExercise ||
            newExercise.trim() === '' ||
            this.customExercises.find(exercise => exercise === newExercise.trim())
        ) {
            return;
        }
        this.weeklyPlannerService
            .updateSettings([...this.customExercises, newExercise.trim()])
            .pipe(untilDestroyed(this))
            .subscribe(settings => {
                this.customExercises = settings.customExercises;
                this.exercise.setValue(null);
            });
    }

    private mergeDayAndTime(day: Date, time: Date): Date {
        return moment(day).set('hour', time.getHours()).set('minute', time.getMinutes()).toDate();
    }

    private populateForm(plan: IWeeklyPlanner): void {
        switch (plan.weeklyPlannerType) {
            case WeeklyPlannerType.EXERCISE:
                this.addExerciseForm.get('time').setValue(new Date(plan.date));
                this.addExerciseForm.get('exercise').setValue(this.getExerciseName(plan.eventData));
                break;
            default:
                break;
        }
    }

    public showFormForWeeklyPlannerType(forWeeklyPlannerType: WeeklyPlannerType, forItem: IWeeklyPlanner): boolean {
        return (
            (this.weeklyPlannerTypeOptions === WeeklyPlannerType.SELECT && !forItem?._id) ||
            this.weeklyPlannerType === forWeeklyPlannerType
        );
    }

    public formatDay(day: Date): string {
        return moment(day).format('Do MMM');
    }

    /**
     * returns appropriate name in cases where exercise is a key rather than a exercise name
     * @param exerciseString
     */
    public getExerciseName(exerciseString: string): string {
        return this.strings.challenges.challengeList[exerciseString]() !== ''
            ? this.strings.challenges.challengeList[exerciseString]()
            : exerciseString;
    }
}
