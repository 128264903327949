import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocketService } from '../../socket/socket.service';
import { LicenseStore } from './license.store';
import * as moment from 'moment';
import { LicenseQuery } from './license.query';
import { ILicense } from './license.model';
import { LicensingActions } from '../../../../../../backend/src/shared/modules/licensing/actions/licensing.actions';
import { LicenseType } from '../../../../../../backend/src/shared/modules/licensing/enums/license-type.enum';
import { NotificationService } from '../../notification/notification.service';
import { NotificationType } from '../../notification/notification-type.enum';
import { ICoaching } from '../coaching/coaching.model';
import { AppContextQuery } from '../../application/state/app-context.query';
import { CoachingService } from '../coaching/coaching.service';
import { CoachingType } from '../../../../../../backend/src/modules/coaching/enums/coaching-types.enum';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { UpgradeActions } from '../../../../../../backend/src/shared/modules/licensing/actions/upgrade.actions';
import { TranslationService } from '../../translation/translation.service';
import { LoggingTool } from '../../../../tools/logging/contract';

@Injectable({
    providedIn: 'root',
})
export class LicenseService {
    get strings() {
        return this.translationService.strings.coaching.licenseScreen;
    }

    get commonStrings() {
        return this.translationService.strings.common.notifications;
    }

    constructor(
        private socketService: SocketService,
        private licenseStore: LicenseStore,
        private notificationService: NotificationService,
        private licenseQuery: LicenseQuery,
        private appContextQuery: AppContextQuery,
        private coachingService: CoachingService,
        private translationService: TranslationService,
        private loggingTool: LoggingTool,
    ) {}

    public redeemCode(code: string, application: Application): Observable<ILicense> {
        return new Observable(observer => {
            this.socketService.fire(LicensingActions.REDEEM_CODE, { code: code, application: application }).subscribe(
                (license: ILicense) => {
                    // todo: check if new application available

                    this.licenseStore.update(license._id, license);
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        this.commonStrings.codeRedeemed(),
                    );
                    observer.next(license);
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'error_code_' + err.code);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public getForSocketUser(): Observable<ILicense[]> {
        return new Observable(observer => {
            this.socketService.fire(LicensingActions.GET_FOR_SOCKET_USER, {}).subscribe(
                (licenses: ILicense[]) => {
                    this.licenseStore.set(licenses);

                    if (licenses && licenses.length) {
                        const application = this.appContextQuery.getValue().application;
                        let licenseToSetActive = licenses[0]; // fallback
                        for (const license of licenses) {
                            if (
                                this.coachingService.isCoachingKeyApplication(
                                    license.coachingKey as CoachingType,
                                    application,
                                )
                            ) {
                                licenseToSetActive = license;
                                break;
                            }
                        }
                        this.licenseStore.setActive(licenseToSetActive._id);
                    }

                    observer.next(licenses);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * get the licenses for a given user
     * @param userId
     */
    public getByUser(userId: string): Observable<ILicense[]> {
        return new Observable(observer => {
            this.socketService.fire(LicensingActions.GET_FOR_USER, userId).subscribe(
                (licenses: ILicense[]) => {
                    observer.next(licenses);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * get the licenses for a given mail address
     * @param email
     */
    public getByEmail(email: string): Observable<ILicense[]> {
        return new Observable(observer => {
            this.socketService.fire(LicensingActions.GET_BY_MAIL, email).subscribe(
                (licenses: ILicense[]) => {
                    observer.next(licenses);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public getByCode(code: string): Observable<ILicense[]> {
        return new Observable(observer => {
            this.socketService.fire(LicensingActions.GET_BY_CODE, code).subscribe(
                (licenses: ILicense[]) => {
                    observer.next(licenses);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * update a user license
     * @param license
     */
    public update(license: ILicense): Observable<ILicense> {
        return new Observable(observer => {
            this.socketService.fire(LicensingActions.UPDATE, license).subscribe(
                (updatedLicense: ILicense) => {
                    this.notificationService.displayNotification(NotificationType.INFO, 'notification_license_saved');
                    observer.next(updatedLicense);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_license_saved_error',
                    );
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * checks if a coaching is licensed
     */
    public isCoachingLicensed(coaching: ICoaching): boolean {
        if (!coaching) {
            return false;
        }

        // get all licenses
        let licenses = [];

        try {
            licenses = this.licenseQuery.getAll();
        } catch (_e) {
            this.loggingTool.error('Error getting licenses from store');
            return false;
        }

        // check access by license
        for (const license of licenses) {
            if (license.coachingKey === coaching.key && !this.hasExpired(license)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Checks if a license has expired
     * @param license
     */
    public hasExpired(license: ILicense): boolean {
        return moment(license.validUntil).toDate() < moment().toDate();
    }

    /**
     * checks if license is DiGA license
     * @param license
     */
    public isDigaLicense(license: ILicense): boolean {
        if (!license) {
            return false;
        }

        return (
            license.licenseType === LicenseType.DIGA ||
            license.licenseType === LicenseType.DIGA_UNVERIFIED ||
            license.licenseType === LicenseType.DIGA_PAID
        );
    }

    public checkIfUpgrading(code: string, coachingKey: CoachingType): Observable<boolean> {
        return new Observable(observer => {
            this.socketService.fire(UpgradeActions.CHECK_UPGRADE, { code, coachingKey }).subscribe(
                response => {
                    observer.next(response);
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public upgradeLicense(code: string, application: Application, keepCoaching: boolean): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(UpgradeActions.UPGRADE_LICENSE, { code, application, keepCoaching }).subscribe(
                () => {
                    observer.next();
                },
                err => {
                    observer.error(err);
                    this.loggingTool.error(err);
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'general_upgrade_not_successful',
                    );
                },
                () => {
                    observer.complete();
                },
            );
        });
    }
}
