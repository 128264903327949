import { Injectable } from '@angular/core';

import { Environment } from '../environment/environment.enum';
import { Application } from '../../../../../backend/src/shared/modules/coaching/enums/application.enum';

const appVersions = require('../../../../../versions.json').apps;

@Injectable()
export class ConfigService {
    public BASE_URL: string;

    private urlPrefixByEnv: Record<Environment, string> = {
        [Environment.LOCAL]: '',
        [Environment.DEV]: 'dev',
        [Environment.INT]: 'int',
        [Environment.PROD]: 'app',
    };

    public urlDomainByApp: Record<Application, string> = {
        [Application.SOMNIO_JUNIOR]: 'junior-somn.io',
        [Application.SOMNIO]: 'somn.io',
        [Application.ACTENSIO]: 'actens.io',
        [Application.GLYKIO]: 'glyk.io',
        [Application.MALIO]: 'malio-schlaftagebuch.de',
    };

    public versionByApp: Record<Application, string> = { ...appVersions };

    public serialNumberByApp: Record<Application, string> = {
        [Application.SOMNIO]: 'SL-SOMNIO-202l0-0001',
        [Application.ACTENSIO]: 'SL-ACTENSIO-202l0-0001',
        [Application.GLYKIO]: 'SL-GLYKIO-202l0-0001',
        [Application.MALIO]: 'SL-MALIO-202l0-0001',
        [Application.SOMNIO_JUNIOR]: '', // TODO SOMNIO_JUNIOR
    };

    public udiCodeByApp: Record<Application, string> = {
        [Application.SOMNIO]: `(9N)1312257SOMNIO-V1067(1T)V10${this.versionByApp[Application.SOMNIO].replace(
            /\./g,
            ``,
        )}`,
        [Application.ACTENSIO]: `(9N)1312257ACTENSIO-V1071(1T)V10${this.versionByApp[Application.ACTENSIO].replace(
            /\./g,
            ``,
        )}`,
        [Application.GLYKIO]: `PP 12257 GLYKIO 15${this.versionByApp[Application.GLYKIO].replace(/\./g, ``)}`,
        [Application.MALIO]: `(0N)to-be-defined${this.versionByApp[Application.GLYKIO].replace(/\./g, ``)}`, // TODO malio
        [Application.SOMNIO_JUNIOR]: `(0N)to-be-defined${this.versionByApp[Application.GLYKIO].replace(/\./g, ``)}`, // TODO SOMNIO_JUNIOR
    };

    constructor() {
        this.BASE_URL = window.location.origin;
        if (window.location.origin.includes('localhost')) {
            this.BASE_URL = 'http://localhost:3000';
        }
    }

    public getEnvironment(): Environment {
        if (['localhost', 'http://192.'].some(v => this.BASE_URL.includes(v))) {
            return Environment.LOCAL;
        } else if (this.BASE_URL.includes(this.urlPrefixByEnv[Environment.DEV])) {
            return Environment.DEV;
        } else if (
            [this.urlPrefixByEnv[Environment.INT], 'pilot-schlafgut.sbk.org', 'pilot-sleepwell.sbk.org'].some(v =>
                this.BASE_URL.includes(v),
            )
        ) {
            return Environment.INT;
        } else {
            return Environment.PROD;
        }
    }

    public isDevEnvironment(): boolean {
        return this.getEnvironment() !== Environment.PROD;
    }

    public getHostForApplication(application: Application, environment: Environment): string {
        return `https://${this.urlPrefixByEnv[environment]}.${this.urlDomainByApp[application]}`;
    }
}
