import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableAkitaProdMode } from '@datorama/akita';
import { environment } from './environments/environment';

if (environment.name !== 'development') {
    disableConsoleOutput();
    enableProdMode();
    enableAkitaProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(() => {});

function disableConsoleOutput() {
    Object.keys(console).forEach(function (key) {
        // eslint-disable-next-line no-console
        console[key] = function () {};
    });

    window.console = console;

    // override, so that errors do not get logged to the console
    window.onerror = function () {
        return true;
    };
}
