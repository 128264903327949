import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'mm-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.sass'],
})

/**
 * dialog header
 */
export class DialogHeaderComponent implements OnInit {
    /**
     * dialog title
     */
    @Input() title: string;

    /**
     * back arrow
     */
    @Input() showBackButton = false;

    /**
     * x in right corner
     */
    @Input() showCloseButton = true;

    /**
     *
     */
    @Output() backButtonClicked: EventEmitter<void> = new EventEmitter<void>();

    /**
     * constructor
     */
    constructor() {}

    /**
     * called on init
     */
    ngOnInit() {}
}
