import { NgModule } from '@angular/core';
import { DialogModule } from '../../../shared/dialog/dialog.module';
import { TimeChangePromptService } from './time-change-prompt.service';
import { TimeChangePromptComponent } from './time-change-prompt.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../shared/pipes/pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [DialogModule, MatDialogModule, PipesModule, MatIconModule, MatButtonModule, CommonModule],
    declarations: [TimeChangePromptComponent],
    providers: [TimeChangePromptService],
    exports: [],
})
export class TimeChangePromptModule {}
