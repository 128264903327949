<mat-dialog-content style="line-height: 1.5; padding-top: 2em; margin-top: -2em">
    <mm-dialog-header [title]="'license_renewal_info_header' | localise : [expireDays]"></mm-dialog-header>
    <div style="text-align: center">
        <p [innerHTML]="'license_renewal_info' | localise"></p>
    </div>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-around">
    <button mat-raised-button style="display: block" color="primary" (click)="onGotItClick()" type="button">
        <span>{{ 'general_got_it' | localise }}</span>
    </button>

    <button mat-raised-button style="display: block" color="primary" (click)="sendInfosViaMail()" type="button">
        <span>{{ 'license_renewal_info_send_mail' | localise }}</span>
    </button>
</mat-dialog-actions>
