import { NgModule } from '@angular/core';
import { DialogModule } from '../../../shared/dialog/dialog.module';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../shared/pipes/pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WeeklyPlannerDayComponent } from './weekly-planner-day/weekly-planner-day.component';
import { WeeklyPlannerComponent } from './weekly-planner.component';
import { MatSelectModule } from '@angular/material/select';
import { MbscDatepickerModule } from '@mobiscroll/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HeaderModule } from '../../../shared/header/header.module';

@NgModule({
    imports: [
        DialogModule,
        BrowserModule,
        MatDialogModule,
        PipesModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
        MatCardModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatSelectModule,
        MbscDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        FormsModule,
        FlexLayoutModule,
        HeaderModule,
    ],
    declarations: [WeeklyPlannerComponent, WeeklyPlannerDayComponent],
    providers: [],
    exports: [],
})
export class WeeklyPlannerModule {}
