import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDeCh from '@angular/common/locales/de-CH';
import { MbscDatepickerModule } from '@mobiscroll/angular';
import { MatMenuModule } from '@angular/material/menu';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

import { appRoutingProviders, routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { PipesModule } from './shared/pipes/pipe.module';
import { AuthService } from './shared/state/auth/auth.service';
import { LanguageService } from './shared/language/language.service';
import { NavigationService } from './shared/navigation/navigation.service';
import { SocketService } from './shared/socket/socket.service';
import { ConfigService } from './shared/config/config.service';
import { CoachingService } from './shared/state/coaching/coaching.service';
import { ModuleService } from './shared/state/module/module.service';
import { StepService } from './shared/state/step/step.service';
import { UserService } from './shared/state/user/user.service';
import { SpeechService } from './shared/speech/speech.service';
import { LicenseService } from './shared/state/license/license.service';
import { NotificationService } from './shared/notification/notification.service';
import { RoleService } from './shared/security/role.service';
import { IdleCheckModule } from './shared/idle-check/idle-check.module';
import { SystemNotificationService } from './shared/state/system-notification/system-notification.service';
import { SbkService } from './shared/state/auth/sbk/sbk.service';
import { DialogHelperService } from './shared/dialog-helper/dialog-helper.service';
import { AppContextModule } from './shared/application/app-context.module';
import { NotificationComponent } from './shared/notification/notification/notification.component';
import { TimeChangePromptModule } from './modules/coaching/time-change-prompt/time-change-prompt.module';
import { NutritionProtocolModule } from './modules/coaching/nutrition-protocol/nutrition.protocol.module';
import { LicenseExpirePromptModule } from './modules/coaching/license-expire-prompt/license-expire-prompt.module';
import { PushNotificationService } from './modules/coaching/profile/push-notifications/push-notification.service';
import { MMCookie } from './shared/enums/cookie-enum';
import { AppGlobalErrorhandler } from './app-global-errorhandler';
import { WeeklyPlannerModule } from './modules/coaching/weekly-planner/weekly-planner.module';
import { TranslationService } from './shared/translation/translation.service';
import { AddHeadersInterceptor } from './shared/http/add-headers.interceptor';
import { SecurityService } from './shared/security/security.service';
import { ServerDowntimePromptModule } from './modules/coaching/server-downtime-prompt/server-downtime-prompt.module';
import { LoggingToolProvider } from '../tools/logging/provider';
import { UserTestInvitationPromptModule } from './modules/coaching/user-test-invitation-prompt/user-test-invitation-prompt.module';

registerLocaleData(localeDeCh);

function authTokenGetter(): any {
    return sessionStorage.getItem(MMCookie.AUTH_TOKEN);
}

const domainWhitelist = [
    'localhost:4200',
    'app.somn.io,',
    'app.actens.io',
    'dev.somn.io',
    'int.somn.io',
    'dev.actens.io',
    'int.actens.io',
    'schlafgut.sbk.org',
    'pilot-schlafgut.sbk.org',
];

@NgModule({
    declarations: [AppComponent, NotificationComponent],
    imports: [
        OAuthModule.forRoot(),
        routing,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        IdleCheckModule,
        HammerModule,
        PipesModule,
        HttpClientModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AppContextModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        MatInputModule,
        MatNativeDateModule,
        MatCardModule,
        MatTooltipModule,
        MatTabsModule,
        MatDialogModule,
        MatChipsModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatListModule,
        MatCheckboxModule,
        MbscDatepickerModule,
        MatMenuModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: authTokenGetter,
                allowedDomains: domainWhitelist,
            },
        }),
        TimeChangePromptModule,
        NutritionProtocolModule,
        LicenseExpirePromptModule,
        WeeklyPlannerModule,
        ServerDowntimePromptModule,
        UserTestInvitationPromptModule,
    ],
    providers: [
        appRoutingProviders,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: 'de-CH' }, // can be set dynamically in app (see docs)
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
        },
        AuthService,
        LanguageService,
        NavigationService,
        SocketService,
        ConfigService,
        CoachingService,
        ModuleService,
        StepService,
        UserService,
        RoleService,
        LicenseService,
        SystemNotificationService,
        SpeechService,
        NotificationService,
        RoleService,
        SbkService,
        DialogHelperService,
        PushNotificationService,
        {
            provide: ErrorHandler,
            useClass: AppGlobalErrorhandler,
        },
        TranslationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeadersInterceptor,
            multi: true,
        },
        SecurityService,
        LoggingToolProvider,
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
