<div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
    <div fxFlex="30px">
        <ng-container [ngSwitch]="notificationType">
            <mat-icon *ngSwitchCase="NotificationType.WARNING" class="warn">warning</mat-icon>
            <mat-icon *ngSwitchCase="NotificationType.ERROR" class="error">error_outline</mat-icon>
            <mat-icon *ngSwitchDefault class="success">check</mat-icon>
        </ng-container>
    </div>

    <div fxFlex="grow">
        <span>{{ notification }}</span>
    </div>

    <div fxFlex="30px">
        <button mat-icon-button (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
