import { Injectable } from '@angular/core';
import { NotificationType } from './notification-type.enum';
import { Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification/notification.component';
import { LanguageService } from '../language/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslationService } from '../translation/translation.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public static DURATION_SHORT = 3000;
    public static DURATION_LONG = 10000;

    constructor(
        private snackBar: MatSnackBar,
        private languageService: LanguageService,
        private transService: TranslationService,
    ) {}

    public displayNotification(
        type: NotificationType,
        notification: string,
        parameters?: string[],
        duration?: number,
    ): Observable<any> {
        let snack: MatSnackBarRef<NotificationComponent> = null;
        const snackConfiguration: MatSnackBarConfig = {
            duration: duration || NotificationService.DURATION_SHORT,
            horizontalPosition: 'center',
            data: {
                notification: this.languageService.getText(notification, parameters || []) || notification,
                notificationType: type,
            },
        };

        snack = this.snackBar.openFromComponent(NotificationComponent, snackConfiguration);

        return new Observable(observer => {
            snack
                .onAction()
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    observer.next();
                });
        });
    }

    public displayInfo(info: string, duration?: number): void {
        this.display(info, NotificationType.INFO, duration);
    }

    public displayWarning(warning: string, duration?: number): void {
        this.display(warning, NotificationType.WARNING, duration);
    }

    public displayError(error?: string, duration?: number): void {
        this.display(error || this.transService.strings.common.defaultError(), NotificationType.ERROR, duration);
    }

    public displayErrorWithCode(error?: any, fallBackMessageTranslationKey?: string, duration?: number): void {
        const fallBackMessage =
            this.transService.strings.common.errorMessages.generalErrorMessages[fallBackMessageTranslationKey]();
        if (error.code) {
            const translatedErrorMessage =
                this.transService.strings.common.errorMessages.errorMessagesWithCode[`errorMessage${error.code}`]() ??
                fallBackMessage;

            this.display(
                translatedErrorMessage || this.transService.strings.common.defaultError(),
                NotificationType.ERROR,
                duration,
            );

            return;
        }

        this.display(
            fallBackMessage || this.transService.strings.common.defaultError(),
            NotificationType.ERROR,
            duration,
        );
    }

    private display(
        notification: string,
        type: NotificationType,
        duration: number = NotificationService.DURATION_SHORT,
    ): void {
        this.snackBar.openFromComponent(NotificationComponent, {
            duration,
            horizontalPosition: 'center',
            data: {
                notification,
                notificationType: type,
            },
        });
    }
}

export enum ErrorTranslationKey {
    MESSAGE_SEND_ERROR = 'messageSendError',
    PROFILE_SAVE_ERROR = 'profileSaveError',
    EXERCISE_SAVE_ERROR = 'exerciseSaveError',
    GENERAL_SAVE_ERROR = 'generalSaveError',
}
