import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '../../translation/translation.service';

@Component({
    templateUrl: './static-html-dialog.component.html',
})
export class StaticHtmlDialogComponent {
    public data: Input;

    get commonStrings() {
        return this.translationService.strings.common;
    }

    static open(
        dialog: MatDialog,
        config: Omit<MatDialogConfig<Input>, 'data'> & { data: Input },
    ): MatDialogRef<StaticHtmlDialogComponent, boolean> {
        return dialog.open<StaticHtmlDialogComponent, Input, boolean>(StaticHtmlDialogComponent, config);
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: Input | undefined,
        private dialogRef: MatDialogRef<StaticHtmlDialogComponent>,
        private translationService: TranslationService,
    ) {
        this.data = data;
    }

    public onClose(): void {
        this.dialogRef.close(true);
    }
}

interface Input {
    title: string;
    html: string;
}
