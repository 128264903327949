import * as moment from 'moment';
import { SbkTitle } from '../enums/sbk-title.enum';
import { SbkSalutation } from '../enums/sbk-salutation.enum';
import { SbkLanguage } from '../enums/sbk-language.enum';
import { SbkTel } from './sbk-tel.model';
import { SbkService } from '../services/sbk.service';

/**
 * represents an sbk user profile ('attendee') in the sbk atm tool
 */
export class SbkProfile {
    public static readonly insurancePrefix = `/atm/api/insurances/`;

    public sbkUserId: string;
    public salutation: SbkSalutation;
    public title: SbkTitle;
    public firstName: string;
    public lastName: string;
    public language: SbkLanguage;
    public dateOfBirth: Date;
    public insurance: string;
    public policyNumber: string;
    public tel: SbkTel;
    public email: string;
    public dataConsent: boolean;
    public adApproval: boolean;
    public sendMails: boolean;
    public campaignId: number;

    /**
     * constructor
     * @param sbkUserId
     * @param salutation
     * @param title
     * @param firstName
     * @param lastName
     * @param language
     * @param dateOfBirth
     * @param insurance
     * @param policyNumber
     * @param tel
     * @param email
     * @param gaveDataConsent
     * @param gaveAdApproval
     * @param sendMails
     * @param campaignId
     */
    constructor(
        sbkUserId: string,
        salutation: SbkSalutation,
        title: SbkTitle,
        firstName: string,
        lastName: string,
        language: SbkLanguage,
        dateOfBirth: Date,
        insurance: string,
        policyNumber: string,
        tel: SbkTel,
        email: string,
        gaveDataConsent: boolean,
        gaveAdApproval: boolean,
        sendMails: boolean,
        campaignId: number,
    ) {
        this.sbkUserId = sbkUserId;
        this.salutation = salutation;
        this.title = title;
        this.firstName = firstName;
        this.lastName = lastName;
        this.language = language;
        this.dateOfBirth = dateOfBirth;
        this.insurance = insurance;
        this.policyNumber = policyNumber;
        this.tel = tel;
        this.email = email;
        this.dataConsent = gaveDataConsent;
        this.adApproval = gaveAdApproval;
        this.sendMails = sendMails;
        this.campaignId = campaignId;
    }

    /**
     * transforms the data objects to 'raw' values such that they can be used to populate the form fields
     */
    public toFormValues(language: SbkLanguage): any {
        const birthDateForForm = moment(this.dateOfBirth).format('DD.MM.YYYY');
        // fun fact 1: atm phone values comes as three parts prefix, but field must be filled as text-only and then sent with pipe notation
        // fun fact 2: atm insurance values comes with prefix, but fields in dropdown have no prefix
        const insurance = this.insurance ? this.insurance.replace(SbkProfile.insurancePrefix, '') : '';

        return {
            salutation: this.salutation.toString(),
            title: this.title.toString(),
            firstName: this.firstName,
            lastName: this.lastName,
            language: language,
            dateOfBirth: birthDateForForm,
            insurance: insurance,
            policyNumber: this.policyNumber,
            tel1: this.tel.part0,
            tel2: this.tel.part1,
            tel3: this.tel.part2,
            email: this.email,
            dataConsent: this.dataConsent,
            adApproval: this.adApproval,
        };
    }
}
