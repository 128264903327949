import { Injectable } from '@angular/core';
import { SocketService } from '../../../../../shared/socket/socket.service';
import { ErrorTranslationKey, NotificationService } from '../../../../../shared/notification/notification.service';
import { Observable } from 'rxjs';
import { NotificationType } from '../../../../../shared/notification/notification-type.enum';
import { HypertensionJournalActions } from '../../../../../../../../backend/src/modules/coaching/modules/hypertensionJournal/enums/hypertension-journal.actions';
import { HypertensionJournalEntryStore } from './hypertension-journal-entry.store';
import { GetEntriesOptions } from '../get-entries-options/get-entries-options.model';
import { applyTransaction, ID } from '@datorama/akita';
import { ActivityScoreResult } from '../../../../../../../../backend/src/modules/coaching/modules/hypertensionJournal/interfaces/general-activity-score-interface';
import { HypertensionJournalStimulants } from '../../../../../../../../backend/src/shared/modules/evaluation/enums/hypertension-journal-stimulants.enum';
import { NutritionType } from '../../../../../../../../backend/src/modules/coaching/modules/hypertensionJournal/enums/nutrition-type.enum';
import { LoggingTool } from '../../../../../../tools/logging/contract';
import { IHypertensionJournalEntry } from './hypertension-journal-entry.model';

@Injectable({ providedIn: 'root' })
export class HypertensionJournalEntryService {
    public static readonly PATH_TYPE_HYPERTENSION = 'hypertension';
    public static readonly PATH_TYPE_BLOOD_PRESSURE = 'bloodPressure';

    constructor(
        private socketService: SocketService,
        private hypertensionJournalEntryStore: HypertensionJournalEntryStore,
        private notificationService: NotificationService,
        private loggingTool: LoggingTool,
    ) {}

    /**
     * get hypertension journal entries (with options)
     * @param options
     */
    public getJournalEntries(options: GetEntriesOptions): Observable<IHypertensionJournalEntry[]> {
        return new Observable(observer => {
            this.socketService
                .fire(HypertensionJournalActions.GET, {
                    start: options.startDate,
                    end: options.endDate,
                })
                .subscribe(
                    (journalEntries: IHypertensionJournalEntry[]) => {
                        if (!options.noStore) {
                            this.hypertensionJournalEntryStore.set(journalEntries);
                        }
                        observer.next(journalEntries);
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    },
                );
        });
    }

    public createJournalEntry(
        journalEntry: IHypertensionJournalEntry,
        setActive: boolean = false,
    ): Observable<IHypertensionJournalEntry> {
        delete journalEntry._id;
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.CREATE, journalEntry).subscribe({
                next: (savedEntry: IHypertensionJournalEntry) => {
                    this.hypertensionJournalEntryStore.add(savedEntry);

                    if (setActive) {
                        this.hypertensionJournalEntryStore.setActive(savedEntry._id);
                    }

                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_journal_entry_saved',
                    );

                    observer.next(savedEntry);
                },
                error: err => {
                    this.notificationService.displayErrorWithCode(err, ErrorTranslationKey.GENERAL_SAVE_ERROR);
                    observer.error(err);
                },
                complete: () => {
                    observer.complete();
                },
            });
        });
    }

    public updateJournalEntry(
        journalEntry: IHypertensionJournalEntry,
        setActive: boolean = false,
    ): Observable<IHypertensionJournalEntry> {
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.UPDATE, journalEntry).subscribe({
                next: (savedEntry: IHypertensionJournalEntry) => {
                    this.hypertensionJournalEntryStore.update(savedEntry._id, savedEntry);

                    if (setActive) {
                        this.hypertensionJournalEntryStore.setActive(savedEntry._id);
                    }

                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_journal_entry_saved',
                    );

                    observer.next(savedEntry);
                },
                error: err => {
                    this.notificationService.displayErrorWithCode(err, ErrorTranslationKey.GENERAL_SAVE_ERROR);
                    observer.error(err);
                },
                complete: () => {
                    observer.complete();
                },
            });
        });
    }

    /**
     * delete a hypertension journal entry
     * @param journalEntryId
     */
    public deleteJournalEntry(journalEntryId: string | ID): Observable<IHypertensionJournalEntry> {
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.DELETE, journalEntryId).subscribe(
                (deletedEntry: IHypertensionJournalEntry) => {
                    applyTransaction(() => {
                        this.hypertensionJournalEntryStore.remove(journalEntryId);
                        this.hypertensionJournalEntryStore.setActive(null);
                    });

                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_journal_entry_removed',
                    );

                    observer.next(deletedEntry);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(
                        NotificationType.ERROR,
                        'notification_journal_entry_removed_error',
                    );
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * adds mindfulness duration to journal
     */
    public addMindfulnessDuration(minutes: number): Observable<IHypertensionJournalEntry> {
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.ADD_MINDFULNESS_DURATION, minutes).subscribe(
                (savedEntry: IHypertensionJournalEntry) => {
                    this.notificationService.displayNotification(
                        NotificationType.INFO,
                        'notification_journal_update_meditation',
                    );
                    observer.next(savedEntry);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayNotification(NotificationType.ERROR, 'error_code_' + err.code);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * get dash score color based on points
     * @param score
     */
    public getDashScoreClass(score: number): string {
        if (score >= 27) {
            return 'excellent';
        }
        if (score >= 22) {
            return 'good';
        }
        if (score >= 17) {
            return 'ok';
        }
        if (score >= 9) {
            return 'bad';
        }
        return 'poor';
    }

    /**
     * get score from nutrition
     */
    public getScoreFromNutrition(value: number, category: string): number {
        let score = 0;

        switch (category) {
            case NutritionType.FRUITS:
            case NutritionType.VEGETABLES:
                if (value >= 4) {
                    score = 4;
                } else if (value >= 3) {
                    score = 3;
                } else if (value >= 2) {
                    score = 2;
                } else if (value >= 1) {
                    score = 1;
                }
                break;
            case NutritionType.WHOLE_GRAIN:
                if (value >= 3) {
                    score = 4;
                } else if (value >= 2.25) {
                    score = 3;
                } else if (value >= 1.5) {
                    score = 2;
                } else if (value >= 0.75) {
                    score = 1;
                }
                break;
            case NutritionType.LOW_FAT_MILK:
                if (value >= 2) {
                    score = 4;
                } else if (value >= 1.5) {
                    score = 3;
                } else if (value >= 1) {
                    score = 2;
                } else if (value >= 0.5) {
                    score = 1;
                }
                break;
            case NutritionType.GOOD_FATS:
                if (value >= 1.5) {
                    score = 4;
                } else if (value >= 1.1) {
                    score = 3;
                } else if (value >= 0.7) {
                    score = 2;
                } else if (value >= 0.3) {
                    score = 1;
                }
                break;
            case NutritionType.RED_MEAT:
            case NutritionType.SALT:
                if (value <= 1) {
                    score = 4;
                } else {
                    score = 1;
                }
                break;
            case NutritionType.SUGAR:
                if (value <= 1) {
                    score = 4;
                } else {
                    score = 1;
                }
                break;
        }

        return score;
    }

    /**
     * generates demo data
     */
    public generateDemoEntries(): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.GENERATE_DEMO_ENTRIES, {}).subscribe(
                () => {
                    observer.next();
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);
                    observer.error(err);
                },
            );
        });
    }

    /**
     * Gets general activity score for the periods pre or post
     * @param period
     */
    public getGeneralActivityScore(period: string): Observable<ActivityScoreResult> {
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.GENERAL_ACTIVITY_SCORE, period).subscribe(
                response => {
                    observer.next(response);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * For nutrition protocol
     * @param weeksBack
     */
    public getAverageNutritionValues(weeksBack: number): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(HypertensionJournalActions.AVERAGE_NUTRITION_VALUES, weeksBack).subscribe(
                values => {
                    observer.next(values);
                    observer.complete();
                },
                err => {
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    /**
     * returns the respective class that resolves coloring
     * @param value
     * @param category
     */
    public getClassFromStimulants(value: number, category: HypertensionJournalStimulants): string {
        switch (category) {
            case HypertensionJournalStimulants.ALCOHOL:
                return value < 2 ? 'good' : value === 2 ? 'mid' : 'bad';
            case HypertensionJournalStimulants.CIGARETTES:
                return value < 1 ? 'good' : value === 1 ? 'mid' : 'bad';
            default:
                return 'good';
        }
    }
}
