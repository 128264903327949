const dictionary = {
    en_us: {
        blot_function: 'Function',
        blot_value: 'Value',
        blot_speech: 'Speech',
        blot_animation: 'Animation',
        blot_coach: 'Coach',
        blot_expressionValue: 'Expression value',
        blot_bracket: 'Bracket',
        blot_comparator: 'Comparator',
        blot_logic: 'Logic',
        blot_evaluation: 'Evaluation',
        blot_menu: 'Menu',
        blot_custom: 'Custom',

        breathing_inhale: 'Inhale',
        breathing_exhale: 'Exhale',

        body_scan_title: 'Body scan',
        body_scan_instruction: 'Here you can listen to/download the body scan in your own time',

        concat_fallAsleepFaster: 'Fall asleep faster',
        concat_laterWakeUp: 'Wake up later',
        concat_betterSleepThrough: 'Less time awake',
        concat_sleepLonger: 'Longer sleep',
        concat_betterSleepQuality: 'Better sleep',
        concat_moreEnergy: 'More energy',

        evaluation_completed_content: 'Completed contents of the program',
        evaluation_connected_to: 'Connected to',
        evaluation_energy_during_day: 'Energy during the day',
        evaluation_energy_during_day_legend: 'Energy during the day (0 = a little, 10 = a lot)',
        evaluation_fall_asleep_time: 'Fall asleep time', // Einschlafdauer // Endormi(e) après
        evaluation_fitness_tracker: 'Fitness tracker',
        evaluation_insomnia_type: 'Type of insomnia',
        evaluation_insomnia_type_A: 'Type A',
        evaluation_insomnia_type_A_info: 'Primarily problems falling asleep',
        evaluation_insomnia_type_B: 'Type B',
        evaluation_insomnia_type_B_info: 'Primarily problems remaining asleep',
        evaluation_insomnia_type_C: 'Type C',
        evaluation_insomnia_type_C_info: 'Primarily problems of early awakening',
        evaluation_insomnia_type_mixedAB: 'Mixed (AB)',
        evaluation_insomnia_type_mixedABC: 'Mixed (ABC)',
        evaluation_insomnia_type_mixedABC_info:
            'Primarily problems falling and remaining asleep and of early awakening',
        evaluation_insomnia_type_mixedAB_info: 'primarily problems falling asleep and remaining asleep',
        evaluation_insomnia_type_mixedAC: 'Mixed (AC)',
        evaluation_insomnia_type_mixedAC_info: 'Primarily problems falling asleep and of early awakening',
        evaluation_insomnia_type_mixedBC: 'Mixed (BC)',
        evaluation_insomnia_type_mixedBC_info: 'Primarily problems sleeping through and early awakening',
        evaluation_insomnia_type_tooltip: 'Type of sleep problems',
        evaluation_isi_end: 'ISI-End',
        evaluation_isi_end_tooltip: "Sleep Score in module 'Closing session'",
        evaluation_isi_follow_up: 'ISI-Follow-up',
        evaluation_isi_follow_up_tooltip: "Sleep score in module 'Follow up'",
        evaluation_isi_score_high: 'Indication for clinical insomnia (severe)',
        evaluation_isi_score_light: 'Indication for subthreshold insomnia',
        evaluation_isi_score_low: 'No indication of clinically significant insomnia',
        evaluation_isi_score_moderate: 'Indication for clinical insomnia (moderate severity)',
        evaluation_isi_start: 'ISI-Start',
        evaluation_isi_start_tooltip: "Sleep score in module 'Introduction'",
        evaluation_number_of_hours: 'Number of hours',
        evaluation_number_of_minutes: 'Number of minutes',
        evaluation_number_of_modules: 'Number of modules',
        evaluation_relevant_params: 'Relevant parameters',
        evaluation_sleep_efficiency: 'Sleep efficiency',
        evaluation_sleep_efficiency_explain_text:
            'Sleep efficiency is the ratio of sleep duration to time spent in bed',
        evaluation_sleep_journal_entries: 'Sleep journal entries',
        evaluation_blood_sugar_journal_entries: 'Blood sugar entries',
        evaluation_sleep_journal_entries_split: 'Sleep journal entries',
        evaluation_sleep_type: 'Sleep type',
        evaluation_sleep_type_definitiveLark: 'Definite lark',
        evaluation_sleep_type_definitiveLark_info: 'Moderate morning type (daily high in the morning)',
        evaluation_sleep_type_definitiveNightOwl: 'Definite owl',
        evaluation_sleep_type_definitiveNightOwl_info: 'Evening type (daily high in the evening)',
        evaluation_sleep_type_moderateLark: 'Moderate lark',
        evaluation_sleep_type_moderateLark_info: 'Morning type (daily high in the morning)',
        evaluation_sleep_type_moderateNightOwl: 'Moderate owl',
        evaluation_sleep_type_moderateNightOwl_info:
            'Moderate evening type (daily high in the late afternoon or evening)',
        evaluation_sleep_type_neutralType: 'Neutral',
        evaluation_sleep_type_neutralType_info: 'Neither evening nor morning type',
        evaluation_sleep_type_tooltip: 'Chronotype',
        evaluation_start_date: 'Start date',
        evaluation_subjective_sleep_quality: 'Subjective sleep quality',
        evaluation_sleep_time: 'Sleep time',
        evaluation_time_in_bed: 'Time in bed',
        evaluation_time_in_bed_and_sleep_time: 'Time in bed and sleep time',
        evaluation_unknown_device: 'Unknown device',
        evaluation_wake_time_during_night: 'Awake time at night',
        evaluation_wake_time_in_bed_after_wake_up: 'Wake time in bed after wake-up',
        evaluation_average_sleep_efficiency_of_last_seven_days:
            'Your sleep efficiency (average of the last seven days) is',
        evaluation_sleep_rhythm: 'Sleep rhythm',

        error_code_429: 'Too many attempts, please wait and try again',
        error_code_500: 'Unknown server error',
        error_code_1001: 'Operation not allowed',
        error_code_3000: 'Connection could not be established',
        error_code_9001: 'Wrong email or password',
        error_code_9002: 'Error finding user',
        error_code_9003: 'Failed to log in after external authentication',
        error_code_9004: 'Failed to authenticate with external provider',
        error_code_9005: 'Failed to create user after successful authentication',
        error_code_9006: 'E-mail address has already been used',
        error_code_9007:
            'Your account has not yet been activated: please click on the link that was sent to you after registration.',
        error_code_9008: 'The old password is incorrect',
        error_code_9009: 'Invalid activation link or account already activated',
        error_code_9010: 'The account data could not be updated',
        error_code_9011: 'User activation failure - please try again',
        error_code_9012:
            'Please do not log in using Google authentication, but with your original registration login method',
        error_code_9013: 'Reset token invalid or expired',
        error_code_9015: 'Second factor needed',
        error_code_9016: 'Code invalid, please log in again',
        error_code_9022: 'Invalid code',
        error_code_9023: 'license code has already been used',
        error_code_9024: 'You provided a discount code',
        error_code_9025: 'You have entered a discount code',
        error_code_9026: 'Could not initialize license',
        error_code_9027: 'A license of the same type already exists',
        error_code_9029: 'A coaching with this key already exists',
        error_code_9039: 'Journal entry overlaps another entry',
        error_code_9040: 'A voucher cannot be redeemed several times for the same account',
        error_code_9042: 'Your request was rejected',
        error_code_9043: 'User is currently not connected',
        error_code_9047: 'Invalid license code',
        error_code_9048: 'Discount code instead of license code',
        error_code_9049: 'license code depleted',
        error_code_9051: 'Voucher is already in use',
        error_code_9053: 'You are not authorized to download this file',
        error_code_9054: 'No license code provided',
        error_code_9055: 'Invalid prescription code: please contact us',
        error_code_9056: 'Prescription code already used',
        error_code_9057: 'license code not valid yet',
        error_code_9058: 'license code expired',
        error_code_9059: 'Invalid object',
        error_code_9060: 'Prescription code could not be verified',
        error_code_9061: 'Prescription code X-Rechnung could not be sent',
        error_code_9062: 'DiGA endpoint communication error',
        error_code_9063: 'X-Rechnung has already been submitted and/ or paid',
        error_code_9064: 'X-Rechnung has already been recorded in SevDesk',
        error_code_9066: 'Sorry, there are not enough data available - please re-do the introduction module',
        error_code_9067: 'Error finding Medication',
        error_code_9069: 'Coaching could not be found',
        error_code_9070: 'Account not valid for current application',
        error_code_9071: 'License code is not intended for the current application',
        error_code_9080: 'Medication could not be found',
        error_code_9081: 'Could not store the sleep window - please reload the page or restart the app and try again',
        error_code_9082: 'Please check internet connection and then try again',
        error_code_9083: 'Incomplete data: graphical analysis not possible',
        error_code_9085: 'IP address is not on whitelist',
        error_code_9086:
            'Changing your email before activating your account is only allowed for a short period of time.',
        error_code_9089: 'Upgrade failed',
        error_code_9090: 'Downgrade is not allowed. Please contact our Support',
        error_code_9091: 'Passwordless setup with the device has failed: $$',
        error_code_9092:
            'Setup failure: has this device already been registered? Try to uninstall and reinstall the app.',
        error_code_9207: 'No next module id found',
        error_code_9208: 'Could not found a module step',
        error_code_9211: 'No module step or no entry module found',
        error_code_9214: 'Found more than one module step for criteria',
        error_code_undefined: 'Unknown error',
        error_code_null: 'Unknown error',
        error_code_general: 'Error',

        // iOS Native
        ios_message_failure: 'Could not send message to iOS',

        // sbk
        error_code_9100: 'Unknown communication error',
        error_code_9101: 'Activation key unknown',
        error_code_9102: 'Activation key invalid',
        error_code_9103: 'Activation key expired',
        error_code_9104: 'New password must be set',
        error_code_9105: 'Invalid credentials',
        error_code_9106: 'Please register using your existing credentials',
        error_code_9107: 'Campaign not started',
        error_code_9108: 'Campaign deactivated',
        error_code_9109: 'Data consent removed',
        error_code_9110:
            'Your account is not yet activated. We have sent you a welcome e-mail with which you can confirm your participation.',
        error_code_9111: 'No data consent given',
        error_code_9112: 'Already registered with other campaign',
        error_code_9113: 'Registration closed',
        error_code_9114: 'Unknown email',
        error_code_9115: 'Wrong campaign ID',
        error_code_9116: 'Minimum delay to reset password has not yet passed',
        error_code_9117: 'Minimum delay to resend activation link has not yet passed',
        error_code_9118: 'Registration fields are invalid',
        error_code_9119: 'Your account is already activated',
        error_code_9097: 'No legal update',

        // thryve
        error_code_9200: "There's already a connection with a tracker",
        error_code_9201: "There's no connection with a tracker yet",
        error_code_9202: 'A connection attempt was made few minutes ago, please wait a few minutes before trying again',

        // journals
        error_code_9300: 'Entry already exists for this date',

        doc_access_title: 'Access for medical personnel',
        doc_access_patient_data_info:
            'Evaluation data of a patient can be retrieved via a 8-digit access code. The access requires a manual confirmation by the patient.',
        doc_waiting_for_patient: 'Waiting for patient confirmation...',

        general_complete: 'Finalise',
        general_device_name: 'Device name (e.g. My laptop)',
        general_created: 'Created',
        general_last_used: 'Last used',
        general_welcome_back: 'Welcome back!',
        general_not_now: 'Not now',
        general_manual: 'Instruction manual',
        general_worries: 'worries',
        general_overload: 'overload',
        general_leisure: 'leisure',
        general_daily_life: 'everyday life',
        general_work: 'work',
        general_summary: 'Summary (PDF)',
        general_all_summaries: 'All summaries',
        general_max_number_of_files: 'maximum number of files: $$',
        general_size_limit: 'maximum size of files: $$ MB',
        general_file_type_not_allowed: 'This file type is not allowed',
        general_and: 'and',
        general_answerFromSelection: 'Label of answer item',
        general_cigarette: 'cigarette',
        general_cigarettes: 'cigarettes',
        general_euro: 'Euro',
        general_euros: 'Euros',
        general_week: 'Week',
        general_weeks: 'weeks',
        general_mmHg: 'milimeter of mercury',
        general_bpm: 'beats per minute',
        general_mmHg_abr: 'mmHg',
        general_bpm_abr: 'bpm',
        general_entry: 'entry',
        general_entries: 'entries',
        general_piece: 'piece',
        general_pieces: 'pieces',
        general_piece_pieces: 'piece(s)',
        general_portion: 'portion',
        general_portions: 'portions',
        general_portion_portions: 'portion(s)',
        general_leave_app_confirmation: 'Attention: you are leaving the app. Continue?',
        general_warn_fitbit_external_link: 'Attention: when you click on "Connect to fitbit" you will leave the app.',
        general_terms_and_conditions: 'Terms and conditions',
        general_privacy_policy: 'Privacy policy',
        general_app_version: 'App version',
        general_data_processing: 'Data processing consent',
        general_exclusion_criteria: 'exclusion criteria',
        general_no_confirmation_mail: 'Confirmation email not received?',
        general_forgot_password: 'Forgot password',
        general_average: 'Average',
        general_explain_license_extension:
            'You can renew your current license or unlock a new program by entering a prescription or license code.',
        general_for_program: 'For application',
        general_goals: 'Goals',
        general_goals_change_in_profile: 'Here you can customize your personal goals',
        general_first_goal: 'First goal',
        general_second_goal: 'Second goal',
        general_third_goal: 'Third goal',
        general_no_goal: 'No goal',
        general_overall: 'overall',
        general_show_regression: 'Show regression',
        general_institution: "Doctor's office",
        general_location: 'Postal code',
        general_updates: 'Updates',
        general_prompt_remove_entry: 'Are you sure you want to remove this entry?',
        general_weekly_average: '7-day average',
        general_your_entry: 'Your entry',
        general_confirm: 'Confirm',
        general_voucher_export: 'CSV export',
        general_two_factor_details: 'Enable two-factor authentication for additional security',
        general_two_factor_authentication: 'Two-Factor Authentication',
        general_create_password_to_activate: 'Please create a password to activate your account:',
        general_click_to_activate_account: 'You have already set a password. Click below to activate the app.',
        general_generate_demo_data_prompt:
            'All existing journal entries will be deleted. Sure that you want to continue?',
        general_locked: 'Locked',
        general_select_provider: 'Select provider',
        general_demo_account: 'Demo account',
        general_flag_for_rating: 'Flag for rating',
        general_provider: 'Provider',
        general_generate_sleep_data: 'Generate entries',
        general_demo_actions: 'Demo actions',
        general_demo_actions_details: 'You have a demo account. The following actions are available:',
        general_remove_wait_times: 'Remove wait times',
        general_unlock_all_modules: 'Unlock all modules',
        general_here: 'here',
        general_link: 'Link',
        general_more_info: 'More information',
        general_auto_next: 'Continue automatically',
        general_auto_next_detail: 'Jump to next step automatically at the end of a step if no interaction is required',
        general_module_duration: 'Duration: $$ minutes',
        general_sleep_phase: 'Sleep phase',
        general_awake_phase: 'Awake phase',
        general_consent: 'Consent',
        general_sources: 'Sources',
        general_gender_other: 'Other',
        general_analysis: 'Analysis',
        general_reports: 'Reports',
        general_in_progress_please_wait: 'In progress, please wait...',
        general_job_started_mail_will_be_sent: 'Job started, the result will be sent to your mail address',
        general_job_started_notification_will_be_sent:
            "Job started, you'll be notified once the report is ready for download",
        general_job_completed: 'Job has been completed',
        general_active_after_wakeup: 'Active after wakeup',
        general_sleep_quality: 'Sleep quality',
        general_comparison: 'comparison',
        general_split_evaluation_view: 'Delta evaluation (data is divided visually)',
        general_new_entry: 'New entry',
        general_edit_entry: 'Edit entry',
        general_sleep: 'Sleep',
        general_voucher_internal: 'Internal only',
        general_license_expired: 'Your license has expired',
        general_answer_label: 'Answer label',
        general_support_confirmation: 'Request sent',
        general_send_request: 'Submit',
        general_request: 'Request',
        general_request_detail: 'Please describe your request as precisely as possible',
        general_new_password: 'New password',
        general_own_next: 'Own "next" button',
        general_your_insomnia_cycle: 'Your insomnia cycle',
        general_feedback: 'Feedback',
        general_entries_remaining_help_title: 'Remaining entries?',
        general_entries_remaining_help:
            'The program controls various contents and interventions based on your journal entries. A certain minimum number of entries is required for this to work and for further modules to be opened.',
        general_entries_remaining_help_b: 'Only new entries that you created after completing the last module count.',
        general_entry_remaining: '1 entry remaining',
        general_entries_remaining: '$$ entries remaining',
        general_your_sleep_window: 'Sleep window',
        general_no_sleep: 'No sleep',
        general_entries_remaining_evening: 'noch <b>$$</b> Einträge im Abendprotokoll nötig',
        general_entries_remaining_blood_pressure: 'noch <b>$$</b> Einträge im Blutdruckprotokoll nötig',
        general_entry_remaining_evening: 'noch <b>1</b> Eintrag im Abendprotokoll nötig',
        general_entry_remaining_blood_pressure: 'noch <b>1</b> Eintrag im Blutdruckprotokoll nötig',
        general_entries_to_unlock_module: 'Um das nächste Modul freizuschalten, sind',
        general_shift_window_back: 'Shift window back',
        general_save_window: 'Save sleep window',
        general_shift_window_forward: 'Shift window forward',
        general_plus_minutes: '+$$ minutes',
        general_minus_minutes: '-$$ minutes',
        general_age: 'Age',
        general_gender: 'Gender',
        general_male: 'Male',
        general_female: 'Female',
        general_custom_element: 'Custom element',
        general_menu: 'Menu',
        general_point: 'point',
        general_points: 'points',
        general_add_notification: 'Create notification',
        general_history: 'History',
        general_validators: 'Validators',
        general_system_notifications: 'Notifications',
        general_data_export_profile_json_title: 'Export',
        general_data_export_profile_json_details:
            'You can download all data stored in the system about you in an interoperable format (JSON).',
        general_data_export_sleep_journals_xlsx_title: 'Export sleep journal entries',
        general_data_export_sleep_journals_xlsx_awake_protocol_title: 'Export awake protocol',
        general_data_export_sleep_journals_xlsx_details: 'Your sleep journal data is available here in Excel format.',
        general_data_export_sleep_journals_xlsx_sheet_title: 'Sleep journal entries',
        general_data_export_sleep_journals_xlsx_sheet_date_created: 'Export date',
        general_data_export_sleep_journals_xlsx_sheet_user_mail: 'User',
        general_data_export_sleep_journals_xlsx_sheet_header_entry_nr: 'Entry no.',
        general_data_export_sleep_journals_xlsx_sheet_header_to_bed_date: 'Went to bed',
        general_data_export_sleep_journals_xlsx_sheet_header_get_up_date: 'Got up',
        general_data_export_sleep_journals_xlsx_sheet_header_no_sleep: 'Did not fall asleep',
        general_data_export_sleep_journals_xlsx_sheet_header_tried_to_sleep: 'Tried to sleep',
        general_data_export_sleep_journals_xlsx_sheet_header_fell_asleep: 'Fell asleep',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_amount: 'Number of waking phases',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_duration:
            'Duration of waking phases during sleep phase',
        general_data_export_sleep_journals_xlsx_sheet_header_wake_up_date: 'Woke up',
        general_data_export_sleep_journals_xlsx_sheet_header_from_tracker: 'Imported from sleep devices',
        general_data_export_visual_analysis_title: 'Visual evaluation',
        general_data_export_visual_analysis_details: 'Your data is available here in PDF format.',
        general_data_export_visual_analysis_file_name: 'somnio_sleep_data',
        general_data_export_visual_analysis_averages_last_week: 'Average values - last week',
        general_data_export_visual_analysis_overall: 'Values overall',
        general_data_export_visual_analysis_relation_bed_sleep: 'Time in bed / time asleep',
        general_data_export_visual_analysis_relation_bed: 'Time in bed',
        general_data_export_visual_analysis_relation_sleep: 'Time asleep',
        general_data_export_visual_analysis_awake_in_bed: 'Time in bed after waking up',
        general_data_export_visual_analysis_sleep_data: 'Medical report of',
        general_download: 'Download',
        general_encrypted: 'encrypted',
        general_view_download: 'View/Download',
        general_coming_soon: 'Coming soon',
        general_download_as_pdf: 'Download as PDF',
        general_open_print_dialog: 'Open print dialog',
        general_data_security: 'Data security',
        general_revoke_consent: 'Revoke consent',
        general_warning: 'Warning',
        general_prompt_revoke_consent_info:
            'By revoking consent to the processing of personal data, the application can no longer be used. Your account will be deactivated.',
        general_prompt_revoke_consent_confirm:
            'Please confirm the revocation by entering the email address of the account.',
        general_prompt_remove_account_info:
            'When the account is removed, your profile is irrevocably deleted together with all associated data.',
        general_prompt_remove_account_confirm:
            'Please confirm the deletion by entering the e-mail address of the account.',
        general_account: 'Account',
        general_data_processing_agreement:
            'When creating your account, you have given consent to mementor for the processing your personal data.',
        general_remove_account: 'Delete account',
        general_remove_account_description:
            'The account is physically deleted, i.e. the entire entry is removed from the database',
        general_anonymize_account: 'Anonymize account',
        general_anonymize_account_description:
            'The data associated with the user is deleted. Only data specific to billing such as registration date, license and vouchers will be retained. The e-mail address is made anonymous.',
        general_allow_medical_access_short: 'Allow access?',
        general_allow_medical_access:
            'Do you want to give "$$" one-time access to the evaluation of your therapy data?',
        general_load_evaluation: 'Load evaluation',
        general_optional: 'Optional',
        general_code: 'Code',
        general_percentage: 'Percentage',
        general_decibel: 'Decibel',
        general_sleep_efficiency: 'Sleep efficiency',
        general_bed_time: 'Bed time',
        general_sleep_time: 'Sleep time',
        general_prompt_confirm_short: 'Sure?',
        general_prompt_confirm: 'Are you sure?',
        general_yes: 'Yes',
        general_no: 'No',
        general_forward: 'Next',
        general_back: 'Back',
        general_sleep_journal_evaluation: 'Sleep journal',
        general_sleep_tracker: 'Sleep tracker',
        general_sleep_tracker_connected: 'Sleep tracker is connected. The sleep diary is filled out automatically.',
        general_connect_fitbit: 'Connect to Fitbit',
        general_disconnect_fitbit: 'Disconnect',
        general_connect_fitbit_info:
            'Connecting a Fitbit sleep devices allows you to fill out your sleep journal automatically',
        general_times: 'times',
        general_health_data_consent: 'processing of my health data',
        general_health_data_consent_a: 'I agree to the',
        general_health_data_consent_b: '',
        general_medication_plan: 'Medicationplan',
        general_drag_file: 'Drag file',
        general_or_select_file: 'Or select file',
        general_or: 'Or',
        general_support_page_somnio:
            'For questions and support please visit our <a href="https://support.mementor.de/help/en-gb/16-somnio" target="_blank">support page</a>.',
        general_support_page_actensio:
            'For questions and support please visit our <a href="https://support.mementor.de/help/de-de/49-actensio" target="_blank">support page</a>.',
        general_support_question: 'Unable to resolve your problem or want to contact us directly? ',
        general_support_option_choose: 'Select a type of problem',
        general_support_tutorial_sentence: 'Find a tutorial on how to register by video here',
        general_support_tutorial_button: 'Watch video',
        general_support_content_issues: 'Content issues',
        general_support_technical_issues: 'Technical issues',
        general_support_technical_tooltip: 'If you have technical issues you will get help here',
        general_support_faq: 'You can find answers to the most frequently asked questions on the support page of',
        general_support_counseling: 'Make an appointment directly here',
        general_support_calendar: 'To the calendar',
        general_support_form: 'Get in touch using the support form below',
        general_support_page_sbk:
            'For questions and support please visit our <a href="https://support.mementor.de/help/en-gb/16-somnio" target="_blank">support page</a>.',
        general_support_form_sbk:
            'Unable to resolve your problem or want to contact us directly? Get in touch at <a href="mailto:sbk.schlafgut@mementor.de">sbk.schlafgut@mementor.de</a>',
        general_help: 'Help',
        general_support: 'Support',
        general_notifications: 'Notifications',
        general_data_protection: 'Data protection',
        general_journal_entries: 'Journal entries',
        general_required_entries: 'Required entries',
        general_required_entries_detail: 'Number of journal entries to unlock the next module',
        general_monday_abbr: 'MO',
        general_tuesday_abbr: 'TU',
        general_wednesday_abbr: 'WE',
        general_thursday_abbr: 'TH',
        general_friday_abbr: 'FR',
        general_saturday_abbr: 'SA',
        general_sunday_abbr: 'SU',
        general_language: 'Language',
        general_bad: 'Bad',
        general_good: 'Good',
        general_low: 'Low',
        general_high: 'High',
        general_large: 'Large',
        general_well_being: 'Well-being',
        general_consumption: 'Consumption',
        general_load_more: 'Load more',
        general_edit_voucher: 'Edit Voucher',
        general_edit_provider: 'Edit provider',
        general_edit_medication: 'Edit Medication',
        general_your_sleep_development: 'Sleep development',
        general_efficiency: 'Efficiency',
        general_fall_asleep_time: 'Fall asleep time',
        general_awake_time: 'Awake time',
        general_comment: 'Comment',
        general_hello_name: 'Hello $$!',
        general_sleep_type_mixed: 'mixed type',
        general_sleep_type_fallAsleep: 'fall asleep type',
        general_sleep_type_sleepThrough: 'sleep through type',
        general_sleep_type_wakeUpEarly: 'wake up early type',
        general_confirm_account: 'Confirm account',
        general_restart: 'Restart',
        general_add_user: 'Add user',
        general_user_actions: 'Actions',
        general_active: 'Active',
        general_account_activated_redirecting: 'Account activated, please log in with your email and password',
        general_password_reset: 'Password has been reset',
        general_reset_instructions_sent:
            'If an account with this e-mail address exists, further instructions have been sent to this address.',
        general_input_label: 'Input text label',
        general_min_value: 'Minimal value',
        general_max_value: 'Maximal value',
        general_external_value: 'External value',
        general_animation_blot_driven: 'animation is driven by external value',
        general_slider_label: 'Slider label',
        general_slider_min_label: 'Label minimal value',
        general_slider_max_label: 'Label maximal value',
        general_slider_min_value: 'Minimal value',
        general_slider_max_value: 'Maximal value',
        general_slider_show_ticks: 'Show ticks',
        general_day: 'day',
        general_days: 'days',
        general_day_days: 'day(s)',
        general_hour: 'hour',
        general_hours: 'hours',
        general_minute: 'minute',
        general_minute_minutes: 'minute(s)',
        general_module_entry_point: 'Module entry point',
        general_separate_by_comma: 'Separate elements with a comma',
        general_number: 'Number',
        general_integer: 'Integer',
        general_list: 'List',
        general_list_items: 'List elements',
        general_answer_type: 'Answer type',
        general_unit: 'Unit',
        general_key: 'Key',
        general_edit_answer_container: 'Edit answer container',
        general_add_answer_container: 'Add container',
        general_answer_containers: 'Answer containers',
        general_select_answer_container: 'Select answer container',
        general_answer_value: 'Value',
        general_answer_container: 'Container to save answer in',
        general_answer_container_name: 'Name of answer container',
        general_questionnaire: 'Questionnaire',
        general_questionnaires: 'Questionnaires',
        general_add_questionnaire: 'Add questionnaire',
        general_edit_questionnaire: 'Edit questionnaire',
        general_questions: 'Questions',
        general_question_question: 'Question',
        general_question_title: 'Title',
        general_edit_question: 'Edit question',
        general_add_question: 'Add question',
        general_copy_question: 'Copy question',
        general_question_type_select: 'Select',
        general_question_type_buttons: 'Button list',
        general_question_type_number_input: 'Number input',
        general_question_type_mobiscroll_number: 'Mobiscroll Number input',
        general_question_type_mobiscroll_timespan: 'Mobiscroll Timespan input',
        general_conditions: 'Conditions',
        general_expression: 'Expression',
        general_edit_user: 'Edit user',
        general_availability: 'Availability',
        general_filters: 'Filters',
        general_icon: 'Icon',
        general_edit_coaching: 'Edit coaching',
        general_spoken_text: 'Spoken text',
        general_edit_animation: 'Edit animation',
        general_add_animation: 'Add animation',
        general_select_animation: 'Select animation',
        general_select_questionnaire: 'Select questionnaire',
        general_select: 'Select',
        general_animations: 'Animations',
        general_animation: 'Animation',
        general_no_animation: 'No animation',
        general_new_modules_available: 'New modules available',
        general_update: 'Update',
        general_start: 'Start',
        general_start_module: 'Start module',
        general_continue_module: 'Continue',
        general_select_step: 'Step selection',
        general_edit_module: 'Edit module',
        general_minutes: 'minutes',
        general_mgdl: 'milligrams per deciliter',
        general_mmol: 'millimoles',
        general_drinking_glass: 'glass',
        general_drinking_glasses: 'glasses',
        general_percent: 'percent',
        general_percentage_point: 'percent point',
        general_percentage_points: 'percent points',
        general_specify_parameters: 'Specify parameters',
        general_select_step_property: 'Select text property',
        general_edit_step: 'Edit step',
        general_values: 'Values',
        general_functions: 'Functions',
        general_speech: 'Speech',
        general_roles: 'Roles',
        general_add_role: 'Add role',
        general_add: 'Add',
        general_roles_menu: 'Roles',
        general_sleep_training: 'Sleep training',
        general_minutes_short: ' min.',
        general_search: 'Search',
        general_wait_time: 'Wait time',
        general_flow_manager: 'Flow manager',
        general_select_module: 'Select module',
        general_first_name: 'First name',
        general_last_name: 'Last name',
        general_no_module_selected: 'No module selected',
        general_description: 'Description',
        general_saved_value: 'Saved value',
        general_next_step: 'Next step',
        general_complete_questionnaire: 'Complete questionnaire',
        general_save: 'Save',
        general_save_and_continue: 'Save & continue',
        general_delete: 'Delete',
        general_cancel: 'Cancel',
        general_add_module: 'Add module',
        general_create_module: 'Create module',
        general_add_coaching: 'Add coaching',
        general_value: 'Value',
        general_save_answer: 'Save answer',
        general_answer_property: 'Answer property name',
        general_users: 'Users',
        general_user: 'User',
        general_step_name: 'Step name',
        general_coaching_manager: 'Coachings',
        general_module_manager: 'Modules',
        general_step_manager: 'Steps',
        general_text: 'Text',
        general_email: 'Email',
        general_wrong_email: 'Typo? Change your email...',
        general_password: 'Password',
        general_password_set_new: 'Set a new password',
        general_password_new: 'New password',
        general_password_old: 'Old password',
        general_password_tip_show: 'Show password',
        general_password_tip_hide: 'Hide password',
        general_repeat_password: 'Repeat password',
        general_request_password: 'Forgot password',
        general_request_password_submit: 'Reset password',
        general_login: 'Login',
        general_login_action: 'Login',
        general_login_with_password: 'Login with password',
        general_login_passwordless: 'Login without password',
        general_manage_devices: 'Manage your devices',
        general_setup_and_go_passwordless: 'After setup you can log in without entering a password',
        general_setup: 'Setup',
        general_unregister_device: 'Unregister this device',
        general_login_faster: 'Log in faster',
        general_passwordless_login:
            'Your device supports login without password. Do you want to set up such an access now?',
        general_passwordless_login_active: 'Login without password enabled!',
        general_passwordless_login_active_b: 'Please enter a name for your device:',
        general_register: 'Create new account',
        general_register_long: 'Create a new account',
        general_prescription_or_license_code: 'Do you have a prescription or license code?',
        general_unlock: 'Unlock',
        general_enter_code: 'Code entry',
        general_enter_code_detail: 'Enter your 16-digit prescription or license code here:',
        general_start_without_code:
            'You can already create your account. But to use the program you need a prescription or license code.',
        general_we_will_support_you: 'We will support you in this.',
        general_register_action: 'Register',
        general_register_tk: 'Registration TK',
        general_almost_done: 'Almost done!',
        general_registration_success: 'Registration has succeeded! Please check your inbox ($$).',
        general_notify_mail_delay: ' Note that it may take several minutes until the confirmation mail arrives.',
        general_activation_success: 'Activation succeeded. You may now log in.',
        general_activation_new_password_success:
            'Request succeeded - please check your inbox to retrieve your new password.',
        general_coaching_single: 'Coaching',
        general_coaching: 'My coaching',
        general_settings: 'Settings',
        general_user_data: 'User data',
        general_user_groups: 'User groups',
        general_logout: 'Logout',
        general_application: 'Application',
        general_session: 'Session',
        general_manager: 'Manager',
        general_administration: 'Administration',
        general_sleep_journal: 'Sleep Journal',
        general_license: 'License',
        general_licenses: 'licenses',
        general_licenses_overview: 'Overview of your active licenses',
        general_license_extend: 'Activate/renew license',
        general_license_buy: 'Buy license',
        general_license_management: 'license Management',
        general_license_code: 'Prescription or license code',
        general_license_type_admin: 'Created by admin',
        general_license_type_trial: 'Evaluation license',
        general_license_type_provider: 'Provider license',
        general_license_type_diga: 'DiGA license',
        general_license_type_diga_unverified: 'DiGA license (unverified)',
        general_license_type_diga_rejected: 'DiGA license (rejected)',
        general_voucher: 'Voucher',
        general_vouchers: 'Vouchers',
        general_voucher_manager: 'Voucher Manager',
        general_voucher_add: 'Add voucher',
        general_voucher_find_expiring_temp_diga_licenses: 'DiGA temp lic',
        general_voucher_invoicing_summary: 'DiGA code invoicing overview',
        general_voucher_sync_with_sevdesk: 'Sync with SevDesk',
        general_voucher_name: 'Voucher description',
        general_voucher_discount_percentage: 'Voucher discount',
        general_voucher_validity_days: 'Validity (days)',
        general_voucher_validity_unlimited: 'Unlimited',
        general_voucher_provider: 'Provider of the code (optional)',
        general_voucher_coaching: 'For coaching',
        general_voucher_quantity: 'Redeemable x times',
        general_voucher_code: 'Voucher code',
        general_redeem_code: 'Redeem code',
        general_voucher_redeem_execute: 'Redeem',
        general_voucher_confirm_diga: 'Confirm DiGA voucher',
        general_voucher_reject_diga: 'Reject DiGA voucher',
        general_voucher_usages: 'Usages',
        general_upgrade: 'Upgrade',
        general_upgrade_successful:
            'Upgrade successful! <br> By clicking on "Got it" you will be automatically logged out and must log in again afterwards.',
        general_upgrade_not_successful: 'Ugrade failed',
        general_upgrade_license:
            'By redeeming this license code you will receive an upgrade to the DiGA (app on prescription) somnio. <br> <br> Would you like to transfer your existing journal data with the upgrade to somnio?',
        general_localise: 'Localise',
        general_provider_manager: 'Provider/ Customer/ Code Issuer',
        general_provider_menu: 'Providers',
        general_provider_name: 'Provider name',
        general_provider_parent: 'Parent provider',
        general_provider_add: 'Add new',
        general_provider_none: 'No provider/ unknown',
        general_continue: 'Continue',
        general_speak: 'Speak',
        general_accept_terms: 'I accept the',
        general_terms_and: 'and the',
        general_terms_abbr: 'TOS',
        general_privacy_policy_abbr: 'PP',
        general_exclusion_criteria_a: 'I have taken note of the',
        general_exclusion_criteria_b: '',
        general_back_to_login: 'Back to login',
        general_my_coaching: 'Coaching',
        general_monitor: 'Medical report',
        general_coachings: 'Coachings',
        general_select_coaching: 'Select coaching',
        general_medication: 'Medication',
        general_modules: 'Modules',
        general_steps: 'Steps',
        general_step: 'Step',
        general_add_step: 'Add step',
        general_answer: 'Answer',
        general_answers: 'Answers',
        general_name: 'Name',
        general_username: 'Your name',
        general_module: 'Module',
        general_all: 'All',
        general_none: 'None',
        general_little: 'A little',
        general_medium: 'Medium',
        general_lots: 'A lot',
        general_close: 'Close',
        general_date_days: 'days',
        general_date_week: 'week',
        general_date_weeks: 'weeks',
        general_week_weeks: 'week(s)',
        general_date_months: 'months',
        general_date_created: 'Creation date',
        general_text_copied: 'Text has been copied to the clipboard',
        general_version: 'Version',
        general_publish: 'Publish',
        general_new_version: 'New version',
        general_delete_version: 'Delete version',
        general_shortcuts: 'Shortcuts',
        general_published: 'Published',
        general_published_on: 'Published on',
        general_edit_module_meta: 'Edit module meta: $$',
        general_wait_time_hours: 'Waiting time in hours',
        general_has_license_code: 'I have a license code',
        general_activate_account: 'Activate account',
        general_sleep_coaching: 'Sleep coaching',
        general_receive_newsletter: 'I would like to receive the newsletter',
        general_newsletter: 'Newsletter',
        general_receive_mail_notifications: 'Receive notifications via email',
        general_receive_push_notifications: 'Receive push notifications',
        general_profile: 'Profile',
        general_clients: 'User groups',
        general_save_password: 'Save password',
        general_save_profile: 'Save profile',
        general_coach_settings: 'Coach',
        general_coach_talking_speed: 'Speaking rate',
        general_slow: 'Slow',
        general_fast: 'Fast',
        general_test: 'Test',
        general_about: 'About',
        general_medical_device: 'Medical device',
        general_serial_number: 'Serial number',
        general_udi_code: 'UDI Code',
        general_somnio_description:
            'somnio is a digital insomnia therapy for people that have difficulty initiating or maintaining sleep',
        general_actensio_description:
            'actensio is a digital hypertension therapy device intended to cure hypertension by lowering the blood pressure using a guideline oriented lifestyle change.',
        general_got_it: 'Got it',
        general_understood: 'Got it',
        general_authentication: '2-Factor Authorization',
        general_no_account_yet: 'No account yet?',
        general_insert_two_factor_code: 'Please enter the code we have just sent you by email',
        general_did_not_get_code: "Didn't receive a code?",
        general_send_again: 'Send again',
        general_resend_activation_link: 'Resend the activation link',
        general_activation_link_missing: 'Activation link not received?',
        general_code_sent: 'Code sent to $$',
        general_x_label: 'Label x-axis',
        general_y_label: 'Label y-axis',
        general_days_back: 'Days back',
        general_evaluation: 'Evaluation',
        general_evaluation_journal: 'Evaluation Journal',
        general_evaluation_questionnaire: 'Evaluation Questionnaire',
        general_show_subtitles: 'Show subtitles',
        general_sleep_restfulness_question: 'How restful was your sleep?',
        general_multi_select_dialog_option_default_title: 'Selection',
        general_multi_select_dialog_option_default_message: 'Please select an option',
        general_multi_select_dialog_option_confirm_selection: 'Confirm selection',
        general_additional_data: 'Additional data',
        general_to: 'to',
        general_stay_logged_in: 'Stay logged in',
        general_XRechnung_title: 'X-Rechnung',
        general_XRechnung_submit_sevdesk_insurance: 'Submit X-Rechnung to the insurance and SevDesk',
        general_XRechnung_submit_sevdesk_only: 'Submit X-Rechnung to SevDesk only',
        general_XRechnung_submit_insurance_only: 'Submit X-Rechnung to insurance only',
        general_XRechnung_submit_insurance_override: 'Override XRechnung at insurance company',
        general_XRechnung_serviceDate: 'Date of service provision',
        general_XRechnung_buyerReference: "'Leitweg-Id' (Buyer Reference)",
        general_XRechnung_insuranceName: 'Invoice recipient company',
        general_XRechnung_insuranceIk: 'Insurance IK',
        general_XRechnung_ikBilling: 'Billing IK',
        general_XRechnung_insuranceStreet: 'Street',
        general_XRechnung_insurancePostCode: 'Postal code',
        general_XRechnung_insuranceCity: 'City',
        general_XRechnung_insuranceCountryCode: 'Country code',
        general_XRechnung_invoiceNr: 'Invoice Nr',
        general_XRechnung_sent_date: 'Date sent',
        general_XRechnung_paid_date: 'Date paid',
        general_XRechnung_sevDesk_Id: 'SevDesk ID',
        general_XRechnung_already_paid: 'Already paid',
        general_payrexx_title: 'Payrexx',
        general_payrexx_sync_to_sevdesk: 'Send to SevDesk',
        general_currency: 'Currency',
        general_amount: 'Amount',
        general_payment_provider_name: 'Payment provider',
        general_payment_provider_transaction_id: 'Transaction ID',
        general_payment_provider_transaction_uuid: 'Transaction UUID',
        general_status: 'Status',
        general_extend_license_by_mail: 'Extend for email',
        general_extend_license_mail_address: 'User email',
        general_extend_license_days: 'Extend by days',
        general_extend_license_validity_date: 'New validity date (after extension)',
        general_check_voucher: 'Validate voucher',
        general_check_voucher_sum_valid: 'The checksum is valid',
        general_check_voucher_sum_invalid: 'The checksum is invalid',
        general_check_voucher_used: 'The voucher has already been used',
        general_check_voucher_unused: 'The voucher has not been used',
        general_refresh: 'Refresh',
        general_display: 'Show',
        general_seven_days: '7 days',
        general_shorten: 'Shorten',
        general_extend: 'Extend',
        general_invalid_parameter: 'Invalid parameter',
        general_certificate: 'Certificate',
        general_certificate_title: 'Certificate',
        general_certificate_expired: 'Certificate has expired',
        general_certificate_issued: 'Certificate issuance',
        general_certificate_we_confirm: 'We confirm',
        general_certificate_has_completed: 'has completed the sleep training',
        general_certificate_description: 'You can download your participation certificate here.',
        general_certificate_name: 'Name',
        general_import_medication_list: 'Import',
        general_for: 'For',
        general_default: 'Default',
        general_travel: 'Locomotion',
        general_recipes: 'Recipes',
        general_not_accepted: 'Not accepted',
        general_translation_suggestion: 'Translation suggestion',
        general_translation_suggestion_add_new: 'Suggest a new text based on this one',
        general_translation_suggestion_remove_this: 'Remove this suggestion',
        general_translation_suggestion_make_current: 'Apply this text suggestion',
        general_translation_suggestion_cannot_remove_current:
            'This is the current version and thus cannot be removed - please apply another version first',
        general_mail_sent: 'Email sent',
        general_not_connected: 'Not connected',
        general_of: 'Of',

        general_thryve_connection_management: 'Tracker connection management',
        general_thryve_connect_title: 'Connections with tracker',
        general_thryve_connect: 'Connect',
        general_thryve_connected: 'Connected with tracker',
        general_thryve_connected_success: 'Successfully connected with tracker, you may close this window',
        general_thryve_connected_failure: "Couldn't connect with tracker, please try again",
        general_thryve_disconnected_success: 'Successfully disconnected tracker, you may close this window',
        general_thryve_disconnected_failure: "Couldn't disconnect tracker, please try again",
        general_thryve_disconnect: 'Disconnect',
        notification_thryve_disconnected: 'Disconnected tracker',
        notification_thryve_select_usage: 'Please select at least one usage',
        notification_no_pw_reset_link: 'No password reset link available',
        general_thryve_connect_info: 'The connection with an activity tracker enables automatic journal completion.',
        general_thryve_explanation_somnio:
            'After connection of an activity tracker, sleep data is being synchronized automatically with the programme.',
        general_thryve_confirm_tracker_connection_somnio:
            "Precision of automatic sleep tracking varies from person to person. Therefore mementor can't guarantee that the data is correct and recommends <b>checking and adjusting the synchronized data where appropriate</b>.",
        general_thryve_hint_provider:
            '<b>Caution</b> Data processing takes place through our partner Thrye. Due to this, the provider <i>Thryve</i> or <i>mHealth Pioneers GmbH</i> will be shown in the agreement, instead of <i>mementor</i>.',
        general_thryve_hint_leaving_app:
            '<b>Caution</b> Through clicking on the connection link of the tracking provider, you will leave the app.',
        general_thryve_usage_sleep: 'Sleep',
        general_thryve_usage_activity: 'Activities',
        general_thryve_usage_blood_pressure: 'Blood press & pulse',
        general_thryve_provider: 'Provider',
        general_administration_ratings: 'Ratings',
        general_whoops: 'Whoops!',
        general_reload: 'Reload',
        general_your_answer: 'Your answer',
        general_no_data_provided_yet: 'No data provided yet',
        general_notification_general_testNotification: 'Test notification',
        general_notification_general_modules_progress: 'Next module',
        general_notification_actensio_missing_hypertension_journal_entry: 'Missing entries in the evening log',
        general_notification_actensio_missing_blood_pressure_entry: 'Missing entries in the blood pressure log',
        general_notification_actensio_missing_medication: 'Report medication intake',
        general_notification_somnio_missing_sleep_journal_entry: 'Missing sleep journal entries',
        general_notification_somnio_jr_missing_sleep_journal_entry: 'Missing sleep journal entries',
        general_notification_somnio_missing_awake_journal_entry: 'Missing evening log entries',
        general_notification_somnio_jr_missing_awake_journal_entry: 'Missing evening log entries',
        general_notification_glykio_weekly_planner_reminder: 'Next dates',
        general_notification_glykio_protocols_reminder: 'Reminder of diary entries',
        general_notification_glykio_perception_reminder: 'Perception training reminder',

        handlebars_fitbit_success_title: 'Connection success',
        handlebars_fitbit_success_message:
            'Your account has successfully been linked to Fitbit. You can close this window.',
        handlebars_mail_disable_notification_title_success: 'Unsubscription successful',
        handlebars_mail_disable_notification_message_success:
            'You will no longer receive notifications via e-mail. You can modify this setting in the profile page of your account.',
        handlebars_mail_disable_notification_title_error: 'Unsubscription failure',
        handlebars_mail_disable_notification_message_error:
            'The link to disable the notification is invalid. You can modify this setting in the profile page of your account.',
        handlebars_generali_registration_failure_title: 'Unable to create generali user',
        handlebars_generali_registration_failure_message: 'Unable to create a new account with a generali user',
        handlebars_tk_registration_failure_title: 'Unable to create TK user',
        handlebars_tk_registration_failure_message: 'Unable to create a new account with a TK user',
        handlebars_new_email_error_title: 'Confirmation has failed',
        handlebars_new_email_error_message:
            'The confirmation token for the new e-mail address is invalid or has expired',
        handlebars_new_email_success_title: 'Confirmation success',
        handlebars_new_email_success_message: 'You may now use your new e-mail address to log in.',

        history_action_create: 'creates',
        history_action_update: 'updates',
        history_action_remove: 'removes',
        history_action_anonymize: 'anonymizes',
        history_entity_user: 'user',
        history_targeted_user: 'target user',
        history_unidentified_user: 'unidentified user',

        hint_leave_blank_to_keep: 'Leave blank to keep',
        hint_identifier: 'Your name or institution',
        hint_access_code: 'Access code generated by the patient',
        hint_change_mail:
            'If you choose to change your e-mail address, a confirmation link will be sent to the new address',
        hint_change_mail_confirmation_pending: 'Confirmation of new e-mail address pending',

        isi_sleep_test: 'Sleep test',
        isi_explain_why:
            'In order for us to direct you to the correct sleep training, we require some more information from you.',
        isi_question_one: 'Please estimate to which extent your sleep has been affected in the last two weeks by ...',
        isi_question_two: 'How satisfied/dissatisfied are you with your sleep at the moment?',
        isi_question_three:
            'To which extent has your performance (e.g. ability to focus, memory) and your general condition (e.g. tiredness, mood) been affected by day through your (poor) sleep?',
        isi_question_four:
            'To which extent have other people been to able to notice that your quality of life has been impaired by your (poor) sleep?',
        isi_question_five: 'To which extent are you worried because of your (poor) sleep?',
        isi_none: 'None',
        isi_light: 'Mild',
        isi_medium: 'Moderate',
        isi_severe: 'Severe',
        isi_very_severe: 'Very severe',
        isi_very_satisfied: 'Very satisfied',
        isi_satisfied: 'Satisfied',
        isi_neutral: 'Neutral',
        isi_unsatisfied: 'Dissatisfied',
        isi_very_unsatisfied: 'Very dissatisfied',
        isi_not_at_all: 'Not at all',
        isi_a_little: 'A little',
        isi_moderate: 'Somewhat',
        isi_quite: 'Very much',
        isi_very: 'Extremely',
        isi_fall_asleep_issues: 'Difficulty falling asleep',
        isi_sleep_through_issues: 'Difficulty sleeping through',
        isi_wake_up_early: 'Waking up too early',
        isi_sleep_satisfaction: 'Sleep satisfaction',
        isi_impact_on_daily_life: 'Impact on daily life',
        isi_impact_on_quality_of_life: 'Impact on quality of life',
        isi_worries: 'Worries',

        insomnia_cycle_switchOff: 'Unable to switch off thoughts',
        insomnia_cycle_pressure: 'Putting myself under pressure',
        insomnia_cycle_consequences: 'Thoughts about consequences',
        insomnia_cycle_awake: 'The bed makes me feel awake',
        insomnia_cycle_angry: 'Anger',
        insomnia_cycle_body: 'Physical reaction',
        insomnia_cycle_exhaustion: 'Tired and exhausted',
        insomnia_cycle_performance: 'Impact on performance or ability to focus',
        insomnia_cycle_depression: 'Feeling low',
        insomnia_cycle_noSleep: 'Lying awake in bed',
        insomnia_cycle_sleepLonger: 'Staying in bed for longer',
        insomnia_cycle_catchUp: 'Catching up with sleep',

        journal_alcohol: 'Alcohol',
        journal_caffeine: 'Caffeine',
        journal_fall_asleep_duration: 'Fall asleep time',
        journal_personal_note: 'Personal note',
        journal_entry_created: 'Entry date',
        journal_mood: 'Mood',
        journal_performance: 'Performance',
        journal_energy: 'Energy',

        sleep_relaxation_title: 'Progressive muscle relaxation',
        sleep_relaxation_instruction:
            'Here you can listen to/download the progressive muscle relaxation in your own time',

        sleep_window_length: 'Sleep window length',
        sleep_window_suggestion: 'Recommendation',
        sleep_window_extend: 'Extend sleep window',
        sleep_window_shorten: 'Shorten sleep window',
        sleep_window_keep: 'Keep sleep window',
        sleep_window_no_data: 'No data available for a recommendation',
        sleep_window_info_lengthen_shorten: 'Extension/Shortening',
        sleep_window_info_process_a:
            'Depending on your average sleep efficiency in the last 7 days, Albert will recommend extending, shortening or keeping the length of your sleep window.',
        sleep_window_info_process_b:
            'You begin with the sleep window which Albert has suggested to you in the Sleeping Hours module. Your goal is to extend this sleep window step by step according to the recommendations or to shorten it again under certain conditions until you find your optimal sleeping time.',
        sleep_window_info_extension_condition: 'Recommendation for an extension if:',
        sleep_window_info_average_efficiency:
            'Your average sleep efficiency has been at least 85% over the last seven days.',
        sleep_window_info_average_efficiency_shorten:
            'Your average sleep efficiency has been below 80% over the last seven days.',
        sleep_window_info_your_efficiency: 'Your current sleep efficiency is: $$%',
        sleep_window_info_shorten_condition: 'Recommendation for a reduction if:',
        sleep_window_info_additional_conditions:
            'For a recommendation of a reduction/extension, the following conditions have to be fulfilled additionally:',
        sleep_window_info_minimum_entries: 'At least 3 sleep journal entries in the last 7 days',
        sleep_window_info_missing_entries: 'You need $$ more entries',
        sleep_window_info_your_entries: 'You completed $$ entries',
        sleep_window_info_last_extension:
            'The most recent reduction/extension of the sleep window was at least 7 days ago',
        sleep_window_info_your_last_extension: 'Your last change was $$ days ago',

        spoken_ordinal_high: 'strong connection',
        spoken_ordinal_medium: 'medium connection',
        spoken_ordinal_low: 'low connection',

        license_renewal_info_send_mail: 'Send info by e-mail',
        license_renewal_info_header: 'Your license expires in $$ days',
        license_renewal_info:
            'How to renew your license: <br> <ol><li> Contact your health insurance company and ask for a follow-up license. </li><li> You will receive a new 16-digit code. </li><li> Log in and renew your access under "License" </li></ol><br><b> You need a new prescription? </b><br> Ask your health care provider for a follow-up prescription or try the <a href="https://www.teleclinic.com/app/somnio/">Teleclinic</a> telemedicine service. <br><br> Feel free to contact our support team with any questions.',
        license_upgrade_how_to:
            '<ol><li>Talk to your doctor or psychotherapist and ask for a prescription for somnio.</li> <li>The specialist prescribes somnio (prescription contains "somnio" and the PZN 16898724).</li> <li>Submit the prescription to your health insurance company.</li> <li>The health insurance company generates an activation code for somnio and sends it to you.</li> <li>Start the training in the app and activate somnio with the code under the "License" section. Alternatively, you can use the training in the browser at <a href="https://app.somn.io">app.somn.io</a>.</li></ol> <b>Note:</b> You have the possibility to transfer your existing data to the new version of somnio without any problems when upgrading.',
        license_upgrade_how_to_header: '<b>Upgrade to the DiGA (app on prescription) somnio*</b>',
        license_upgrade_how_to_somnio:
            '*The prescription version of somnio includes advanced features and content recommended for those diagnosed with insomnia.',

        mail_notice_requires_html:
            'Please notice that in order to use the application correctly, you will need a mail program that can render html messages.',
        mail_hello: 'Hello',
        mail_hello_formal: 'Hello',
        mail_registration_activation_title: 'Welcome to $$',
        mail_registration_activation_generali_message: 'Thank you very much for your registration.',
        mail_registration_activation_link_one_coaching:
            'Almost done! Please complete your registration by clicking the following button:',
        mail_registration_activation_link_multiple_coachings_hint:
            'Almost there! You are about to unlock $$. Note that you already have an account with another digital health application we provide. <br>For your convenience and to save you from entering data twice, we are linking the two accounts. Please use the same user information (email and password) to log in.',
        mail_registration_activation_alternative_to_coach_linking:
            'If you do not agree to the linking, you can simply register with another email address.',
        mail_registration_activation_link_multiple_coachings_click: 'To do so, please click on the following button:',
        mail_registration_activation_generali_link:
            'If the activation failed in the browser, repeat the process with this link:',
        mail_registration_activate_account: 'Complete registration',
        mail_registration_waiting_room: 'We will then guide you through all the necessary steps to activate $$.',
        mail_registration_activation_goodbye: 'We hope you have a successful training!',
        mail_app_download_tip: 'You can download the somnio app here',
        mail_request_new_password_title: 'Set a new password',
        mail_request_new_password_message:
            'A new password has been requested for your account. If you did not initiate this, you can simply ignore this message. If you wish to get a new password: please click the link below.',
        mail_set_new_email_address_title: 'Confirm new e-mail address',
        general_new_email: 'New email address:',
        mail_set_new_email_address_message:
            "You have requested to set a new mail address. In order to confirm the modification, please click the link below. Please note: if you're not $$, someone else may have attempted to use your e-mail address. In this case, please ignore and delete this message.",
        mail_set_new_email_address_link: 'Confirm',
        mail_set_new_email_address_duplicate_title: 'Set new e-mail address for existing account',
        mail_set_new_email_address_duplicate_message:
            'A user has requested to use this e-mail address for registering. However, you already have an account with this address. In case you have forgotten your credentials, please click on the link below to reset them.',
        mail_password_reset_title: 'Password reset',
        mail_password_reset_message: 'Your password has been reset.',
        mail_send_new_password_title: 'New password requested',
        mail_license_expiring_title: 'Your license for $$',
        mail_license_expired_title: 'Your license for $$',
        mail_license_expired_generali_message:
            'We would like to inform you that your license for sleep training has expired. Thank you very much for participating. We hope the program has met your expectations.',
        mail_license_expiration_pipedrive_title: 'Your trial license will expire in $$ days',
        mail_license_expiration_pipedrive:
            'due to the requirements of the German Drug Advertising Act (Heilmittelwerbegesetz), unfortunately, the trial period for digital health applications is limited. We would like to remind you that your personal access is still available for $$ days. In order to get an overall impression of the app, you have the option to instantly unlock all training modules in your "Profile" under the tab "Demo Actions".',
        mail_license_expiration_pipedrive_footer: 'We hope you enjoy testing somnio. <br><br> The somnio team',
        mail_set_new_password: 'Set new password',
        mail_registered_not_logged_in_title: 'Your registration at $$',
        mail_two_factor_authentication_title: 'Two-factor authentication',
        mail_two_factor_authentication_message: 'Here is your code for the two-factor authorization',
        mail_unsubscribe_newsletter_text:
            'Would you like to stop receiving notifications by mail from $$? Please click ',
        mail_unsubscribe_newsletter_link: 'here',
        mail_module_open_not_started_title: 'Open modules in $$',
        mail_module_open_not_started_body:
            'We noticed there are open modules in $$ that you haven\'t started yet. On <a href="$$">$$</a> you can launch the open module $$.',
        mail_module_started_not_completed_title: 'Module not completed in $$',
        mail_module_started_not_completed_body:
            'We noticed that you have been working on the module $$ but didn\'t complete it yet. You can complete the module on <a href="$$">$$</a>',
        mail_inactivity_warning_title: 'We miss you!',
        mail_inactivity_warning_body:
            "We haven't seen you for a long time! Please note that the data associated with your account will be irrevocably deleted if you don't log in to the program by $$.",
        mail_news: 'News',
        mail_contact_us:
            'If you have any further questions, please feel free to contact us via <a href="mailto:support@mementor.de">support@mementor.de</a> <br><br>Your mementor team',
        mail_contact_us_formal:
            'If you have any further questions, please feel free to contact us via <a href="mailto:support@mementor.de">support@mementor.de</a> <br><br>Your mementor team',
        mail_payment_voucher_bought_title: 'Your code for somnio',
        mail_payment_voucher_bought_message:
            "Welcome to somnio, your sleep training for a good night's sleep! We've received your payment and you can get started now:",
        mail_payment_voucher_bought_step_1: 'Click <a href="$$">here</a> to create a new somnio user account',
        mail_payment_voucher_bought_step_2: 'Use the code below as the license code',
        mail_payment_voucher_bought_step_3:
            'After that, you can get going with somnio. If you have any questions during the activation process or later on in the training, please contact support on <a href="mailto:support@mementor.de">support@mementor.de</a>',
        mail_payment_voucher_bought_code: 'Your license code: ',
        mail_payment_voucher_bought_step_4:
            'Note: the execution of the contract already begins before the expiry of the withdrawal period. With this purchase, you expressly waive the 14-day right of withdrawal.',
        mail_payment_voucher_bought_footer1:
            'We hope you have a great start and most importantly, a deep and restful sleep!',
        mail_duplicate_registration_title: 'Account already exists',
        mail_duplicate_registration_message:
            'You just tried to register. An account with your email address already exists. You can log in Do you have problems signing in? You can reset your password',
        mail_duplicate_registration_message_b: '. Do you have problems signing in? You can reset your password',
        mail_duplicate_registration_message_c: '.',
        mail_demo_account: 'Test license',
        mail_demo_account_welcome:
            'We are happy to hear that you are interested in somnio. With this e-mail, you receive your personal test license and thus the opportunity to familiarise yourself with our digital sleep training.',
        mail_your_license_code: 'Your personal access code is:',
        mail_demo_account_details:
            'After redeeming the code, the application is fully accessible for 14 days. You receive a demo access in which you can unlock all modules directly and remove the displayed waiting times. This can be done in the app in your profile under the category "Demo activities".',
        mail_registration: 'Registration',
        mail_demo_account_closure:
            'We hope you enjoy the training. Please do not hesitate to contact us if you have any questions or feedback: medical@mementor.de or 0341 581 444 33. ',
        mail_flyer_confirmation: 'somnio: Confirmation of your info material order',
        mail_flyer_confirmation_details_a:
            'Thank you for your interest in our digital sleep training somnio. We will shortly deliver the info brochures for your patients to the indicated address.',
        mail_flyer_confirmation_details_b:
            'We recommend you to forward a brochure to your patients when prescribing somnio to ensure they are provided with the essential information on the program, and the next steps as well as our contact details with which they may contact us if they have any questions or problems.',
        mail_flyer_confirmation_details_c:
            'If you have not already tried the program yourself, you may request a free test account for professionals <a href="https://somn.io/doc/#test">here</a>, valid for two weeks.',
        mail_flyer_subject: 'Patients flyer somnio',
        mail_certificate_title: 'Congratulations!',
        mail_certificate_download_body:
            'You have completed the $$ and showed a lot of motivation and perseverance - you can be proud of yourself! In the profile page, you may now download your participation certificate.',
        mail_certificate_download_link: 'Get the certificate',
        mail_certificate_download_goodbye:
            'All the best for your further journey!<br><br>Best wishes, the mementor team.',
        mail_personal_counseling_body:
            'if you would like to have a personal support talk with an expert, you can book an appointment at the following link: <br><br> <a href="https://my.calenso.com/book/mementor/somnio/support-session@mementor.de">Book an appointment</a> <br><br> In the field for comments, you can briefly describe your request. A time frame of up to 10 minutes is provided for the support call on technical questions. Up to 20 minutes are allotted for the support call on content-related questions. You will then be called at the agreed time.',
        mail_personal_counseling_title: 'Your support call',
        mail_hint_noreply_address_informal:
            'This is an automatically generated message. Please do not reply to it - the email will not be read or processed.',
        mail_hint_noreply_address_formal:
            'This is an automatically generated message. Please do not reply to it - the email will not be read or processed.',
        mail_service_call_offer:
            'aller Anfang ist schwer. Daher wollten wir uns bei dir erkundigen und fragen, wie die ersten Tage mit actensio gelaufen sind? Was lief gut, was weniger? Brauchst du eventuell noch einen kleinen Motivationsschub oder ein offenes Ohr, dem du von deinen Erfahrungen berichten möchtest? <br><br> Dann kannst du gern ein 20-minütiges Supportgespräch mit einer*einem Mitarbeiter*in vereinbaren. Unter folgendem Link kannst du deinen Telefontermin buchen. <br> <br> <a href="https://my.calenso.com/book/mementor/actensio-content">Zum Kalender</a> <br><br> Wir rufen dich zum vereinbarten Zeitpunkt zurück.',
        mail_service_call_offer_subject: 'Buch dein persönliches Supportgespräch',
        mail_upgrade_to_somnio_info:
            'Since your sleep score indicates insomnia, we recommend that you get the <b> full version of somnio, available by prescription</b>. Here\'s how to obtain somnio: <ol> <li>Talk to your doctor or psychotherapist and get a prescription for somnio. </li> <li>The specialist prescribes somnio (prescription contains "somnio" and the PZN number 16898724). </li> <li>Submit the prescription to Techniker Krankenkasse (TK).  </li> <li>The health insurance company generates an activation code for somnio.</li> <li>Start the training in the app and activate somnio with the code under the heading "License". Alternatively, you can also use the training in the browser at <a href="https://app.somn.io.">https://app.somn.io</a>.  </li> </ol> <i> Note: You have the possibility to transfer your existing data to the new version of somnio without any problems.  </i> <br> <br> Now you can take the first steps with the full version of somnio to reduce the symptoms of your sleep disorder and improve your sleep with lasting effects.',
        mail_upgrade_to_somnio_info_title: 'Get somnio',
        mail_final_interview_title: 'Final discussion',
        mail_final_interview_explanation:
            'if you would like a personal final discussion with a specialist, you can book an appointment at the following link:',
        mail_final_interview_link: 'https://my.calenso.com/book/mementor/somnio-finalinterview',
        mail_final_interview_book_appointment: 'Book appointment',
        mail_final_interview_description:
            'You will then be called at the agreed time. If you like, you can already share your initial thoughts about the program with us in the "Comments" field.',
        mail_final_interview_timeframe: 'The time frame for the call is 20 minutes.',
        mail_internal_support_multiple_partner_registrations_title: 'Multiple Partner Registrations',
        mail_internal_support_multiple_partner_registrations_description:
            'a user with a partner registration tried to register another coaching.',
        mail_internal_support_multiple_partner_registrations_partner: 'The user is registered with this partner: ',
        mail_internal_support_multiple_partner_registrations_existing: 'The user has following coaching(s): ',
        mail_internal_support_multiple_partner_registrations_new: 'The user tried to register this coaching: ',

        // actensio-specific mailing messages
        mail_actensio_license_expiring_message_non_diga:
            'We would like to inform you that your license for actensio expires in $$ days. After the license expires, you will need a new license code to log in again.',
        mail_actensio_license_expiring_message_diga:
            'we would like to inform you that your license for actensio will expire in $$ days. In order to continue having access to your journals, the module content and regular data analysis after the license expires, you will need a new <b>prescription code</b>. You can get this from your doctor, therapist or telemedicine provider. You can find out more about the prescription process <a href="https://actens.io/#access">here</a>. ',
        mail_actensio_license_expired_message_non_diga:
            'We would like to inform you that your license for actensio has expired. Thank you very much for participating. We hope the program has met your expectations',
        mail_actensio_license_expired_message_diga:
            'we would like to inform you that your license for actensio has expired. In order to continue to have access to your journals, the module content as well as the regular data evaluation after your license has expired, you need a new <b>prescription code</b>. You can get this from your doctor, therapist or telemedicine provider. You can find out more about the prescription process <a href="https://actens.io/#access">here</a>.<br>We hope you were satisfied with the program and that you continue having a strong health! We\'re glad you participated. ',
        mail_actensio_registered_not_logged_in_body:
            'Welcome to actensio! Thanks for your registration. We have not seen you lately. If you have trouble logging in, please contact our support. Please be aware that if you never log in, your account will be removed due to inactivity on $$. But as soon as you log in for the first time, your account will be safe and ready to use!',
        mail_actensio_not_started_first_module_warning_title:
            "Let's go: Launch your first actensio training session now with your digital coach!",
        mail_actensio_not_started_first_module_warning_body:
            'Your <b>digital virtual coach Albert</b> is ready and waiting for you! Get started with your first actensio training module now at <a href="https://app.actens.io">https://app.actens.io</a> and boost your <b>daily well-being</b>.<br><br>If you experience problems <b>logging in</b>, please contact us at <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_actensio_journal_reminder_title: 'Actensio journal entries missed',
        mail_actensio_journal_reminder_body:
            'You have forgotten to fill in your actensio journals for the recent $$ days. Your last journal entry dates from $$. In order to successfully continue the coaching, it is essential to regularly update your data.',
        mail_actensio_journal_reminder_hint_tracker:
            "You connected a $$ tracker for your training. Unfortunately, the data hasn't synchronized automatically. This may be due to the following reasons:<ul><li>The data from your tracker ($$) couldn't be synchronized with the tracker app (please do this manually in the $$ app)</li><li>Tracker battery wasn't sufficiently charged during measurement process</li><li>The tracker has only been connected recently</li><li>The agreement to data processing in the $$ account doesn't include all of the required values, or has been retracted</li><li>Measurement may not be completed if the tracker is being worn too loosely</li><li>Older tracker models may not record all data with the required precision</li></ul><br><br>Please complete the data for the missing dates manually.",

        // somnio-specific mailing messages
        mail_somnio_license_expiring_message_non_diga:
            'We would like to inform you that your license for somnio expires in $$ days. After the license expires, you will need a new license code to log in again.',
        mail_somnio_license_expiring_message_diga:
            'we would like to inform you that your license for somnio will expire in $$ days. In order to keep having access to your sleep journal, your sleep window, the training modules and the data evaluation after the license expires, you need a new prescription code.<br> Since you already have an indication, you may apply for your follow-up license directly with your health insurance company. You should receive a new license code within a few days. It is best to contact your health insurance company directly.  <br><br> A follow-up license extends the sleep training for another 90 days. Your data is retained and available again as soon as the access is renewed. <br><br> In case your health insurer requires a new prescription, please request this from your doctor or therapist again and then submit the prescription to your health insurance company. <br><br> Alternatively, you may use the serviced of the telemedicine provider (e.g. <a style="color: #009688; text-decoration: none; font-weight: 500" href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">TeleClinic</a>)',
        mail_somnio_license_expired_message_non_diga:
            'We would like to inform you that your license for somnio has expired. Thank you very much for participating. We hope the program has met your expectations',
        mail_somnio_license_expired_message_diga:
            'we would like to inform you that your license for our sleep training has expired. In order to continue to have access to your sleep diary, your sleep window, the module content as well as the regular data evaluation after your license has expired, you need a new <b>prescription code</b>. You can get this from your doctor, therapist or telemedicine provider. You can find out more about the prescription process <a href="https://somn.io/#access">here</a>.<br>We hope you were satisfied with the program and that you continue having a good and restful sleep! We\'re glad you participated. ',
        mail_somnio_registered_not_logged_in_body:
            'Welcome to somnio! Thanks for your registration. We have not seen you lately. If you have trouble logging in, please contact our support. Please be aware that if you never log in, your account will be removed due to inactivity on $$. But as soon as you log in for the first time, your account will be safe and ready to use!',
        mail_somnio_not_started_first_module_warning_title:
            "Let's go: Launch your first training session now with your digital sleep coach!",
        mail_somnio_not_started_first_module_warning_body:
            'Your <b>digital virtual sleep coach Albert</b> is ready and waiting for you! Get started with your first training module now at <a href="https://app.somn.io">https://app.somn.io</a> for a good night\'s sleep and boost your <b>daily well-being</b>.<br><br>If you experience problems <b>logging in</b>, please contact us at <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_somnio_journal_reminder_title: 'Sleep journal entries missed',
        mail_somnio_journal_reminder_body:
            'You have forgotten to fill in your sleep journals for the recent $$ days. Your last sleep journal entry dates from $$. In order to successfully continue the coaching, it is essential to regularly update your sleep data.',
        mail_somnio_journal_reminder_hint_tracker:
            "You connected a $$ tracker for your training. Unfortunately, the data hasn't synchronized automatically. This may be due to the following reasons:<ul><li>The data from your tracker ($$) couldn't be synchronized with the tracker app (please do this manually in the $$ app)</li><li>Tracker battery wasn't sufficiently charged during measurement process</li><li>The tracker has only been connected recently</li><li>The agreement to data processing in the $$ account doesn't include all of the required values, or has been retracted</li><li>Measurement may not be completed if the tracker is being worn too loosely</li><li>Older tracker models may not record all data with the required precision</li></ul><br><br>Please complete the data for the missing dates manually.",

        mail_waiting_room_reminder_subject_1: 'Almost there! Just a few more steps and you can start with somnio!',
        mail_waiting_room_reminder_part_1:
            "Congratulations! You've decided to tackle your insomnia or sleep disorder and want to get on the path to healthy and restful sleep. The good news is that you are only a few steps away!",
        mail_waiting_room_reminder_part_2:
            '<b>And this is how you get to access somnio:</b> <br> <ol><li>You\'ve downloaded somnio from the app store and have already successfully registered. &#10004;</li><li>To finally start using somnio, you still need a prescription for somnio from a doctor or psychotherapist. Make an appointment with the specialist of your choice (doctor, psychotherapist), or with a telemedicine provider (e. g <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> or <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>), talk about your sleep disorders and tell them about somnio. On our website <a href="https://www.somn.io/en">somn.io</a> you will find comprehensive information material, e. g. an <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">information card</a> for medical professionals.</li>',
        mail_waiting_room_reminder_part_3: '<b>You have already received a prescription for somnio?</b>',
        mail_waiting_room_reminder_part_4:
            '<ol start="3"><li>Great, then you can submit it to your health insurance company within 30 days after it was issued. Most of them offer this conveniently via the app or the respective homepage. All public and most private health insurance companies cover the costs for somnio - <b> for you, somnio is free! </b></li> <li>After you have submitted the prescription, your health insurance company will send you the <b>16-digit activation code </b> for somnio. Then all you have to do is enter it and get started! </li></ol>',
        mail_waiting_room_reminder_part_5:
            '<b>Remember:</b> If you have not logged into the program by $$ the data associated with your account will be irrevocably deleted.<br> <b>Good sleep can be learned.</b>',
        mail_waiting_room_reminder_subject_2:
            'Finally sleep through the night? Just a few more steps and you can start with somnio!',
        mail_waiting_room_reminder_part_1_2:
            "Congratulations! A few days ago you registered with somnio because you finally want to sleep through the night again. But you haven't started somnio yet. But you are only a few steps away from making your dream of a good night's sleep come true!",
        mail_waiting_room_reminder_part_2_2:
            '<b>Here again as a reminder - your quick way to somnio:</b> <br> <ol><li>You have successfully registered in the somnio app or the somnio browser application. &#10004;</li><li>Make an appointment for the prescription of somnio at the specialist of your choice (doctor, psychotherapist) or book an online appointment with a telemedicine provider (e.g <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> or <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>),within a few hours. Talk about your sleep disorders and tell the specialist about somnio. On our website <a href="https://www.somn.io/en">somn.io</a>   you will find comprehensive information material, e. g. an <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">information card</a> for medical professionals.</li>',
        mail_waiting_room_reminder_subject_3: 'How important is healthy sleep to you? ',
        mail_waiting_room_reminder_part_1_3:
            "Hello! A few weeks ago you registered with somnio because you finally want to sleep through the night again. But so far you still haven't started somnio. <br> We're wondering: have you encountered any problems so far, or are you still unclear about the somnio prescription process?",
        mail_waiting_room_reminder_part_2_3:
            '<b>Here is your quick way to somnio again:</b> <br> <ol><li>You have successfully registered in the somnio app or the somnio browser application. &#10004;</li><li>Make an appointment for the prescription of somnio at the specialist of your choice (doctor, psychotherapist) or book an online appointment with a telemedicine provider (e.g. <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> or <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>),within a few hours. </li>',
        mail_waiting_room_reminder_part_4_3:
            '<ol start="3"><li>Great, then you can submit your prescription to your health insurance company within 30 days after it was issued. Most of them offer this conveniently via the app or the respective homepage.</li> <li>Your health insurer will then send you the <b>16-digit activation code</b> for somnio. <br> Then it\'s just a matter of entering it and getting started! </li></ol> ',

        meditation_exercise_title: 'Focused breathing',
        meditation_exercise_instruction:
            'During this exercise, focus for a few minutes on how your breath flows in and out in rhythm with the animation.',
        meditation_exercise_completed: 'Exercise completed',
        meditation_exercise_completed_write_to_journal:
            'Meditation completed. Transfer time ($$ minutes) to your journal?',
        meditation_exercise_write_to_journal: 'Transfer',
        meditation_exercise_start: 'Start',
        meditation_exercise_end: 'Quit',

        lottie_no_insomnia: 'No insomnia',
        lottie_light_insomnia: 'Light insomnia',
        lottie_moderate_insomnia: 'Moderate insomnia',
        lottie_severe_insomnia: 'Severe insomnia',
        lottie_week: 'Week',
        lottie_overall: 'Overall',

        licensing_title: 'licenses',
        licensing_no_license_found: 'No license found',
        licensing_credit_debit_card: 'Credit or debit card',
        licensing_credit_debit_card_submit: 'Execute payment',
        licensing_valid_until: 'Valid until',
        licensing_trial: 'Evaluation license',
        licensing_module_not_licenced: 'No valid license found for this module',
        licensing_voucher_creation: 'Voucher creation',
        licensing_user_registration: 'User registration',
        licensing_coaching_activation: 'Coaching activation',

        notification_coaching_saved: 'Coaching saved',
        notification_coaching_saved_error: 'Coaching could not be saved',
        notification_coaching_removed: 'Coaching removed',
        notification_coaching_removed_error: 'Coaching could not be removed',
        notification_coaching_published: 'Coaching published',
        notification_coaching_published_error: 'Coaching could not be published',
        notification_version_created: 'New version created',
        notification_version_created_error: 'New version could not be created',
        notification_coaching_version_updated: 'Successfully updated to latest version',
        notification_coaching_version_updated_error: 'Could not update to latest version',
        notification_socket_disconnect: 'Disconnected from server',
        notification_socket_reconnect: 'Connection recovered',
        notification_session_expired: 'Session expired, please log in',
        notification_step_add: "Step '$$' added",
        notification_step_remove: "Step '$$' removed",
        notification_step_remove_count: 'Removed $$ steps',
        notification_step_update: "Step '$$' saved",
        notification_step_save_error: 'Step could not be saved',
        notification_journal_entry_saved: 'Entry saved',
        notification_journal_entry_removed: 'Entry removed',
        notification_journal_entry_removed_error: 'Entry could not be removed',
        notification_text_localised: 'Text localized',
        notification_animation_saved: 'Animation "$$" saved',
        notification_animation_saved_error: 'Could not save animation',
        notification_animation_removed: 'Animation "$$" removed',
        notification_medication_saved: 'Medication "$$" saved',
        notification_medication_saved_error: 'Could not save medication',
        notification_medication_removed: 'Medication "$$" removed',
        notification_module_saved: 'Module saved',
        notification_module_saved_error: 'Module could not be saved',
        notification_module_removed: 'Module removed',
        notification_module_removed_error: 'Module could not be removed',
        notification_module_published: 'Module has been published',
        notification_module_published_error: 'Could not publish module',
        notification_answer_container_saved: 'Answer container "$$" saved',
        notification_answer_container_removed: 'Answer container "$$" removed',
        notification_user_saved: 'User "$$" saved',
        notification_user_saved_error: 'User "$$" could not be saved',
        notification_user_removed: 'User "$$" removed',
        notification_user_removed_error: 'User "$$" could not be removed',
        notification_user_anonymized: 'User "$$" anonymized',
        notification_user_anonymized_error: 'Unable to anonymize user "$$"',
        notification_profile_saved: 'Profile saved',
        notification_profile_saved_error: 'Error saving profile',
        notification_profile_email_updated: 'A confirmation link has been sent to the new address',
        notification_cannot_edit_published: 'Published modules cannot be edited',
        notification_module_locked: 'The module "$$" has been locked by $$',
        notification_module_unlocked: 'The module "$$" has been unlocked by $$',
        notification_provider_saved: 'Provider "$$" saved',
        notification_provider_saved_error: 'Provider "$$" could not be saved',
        notification_provider_removed: 'Provider "$$" removed',
        notification_provider_removed_error: 'Provider "$$" could not be removed',
        notification_fitbit_connected: 'Connected to Fitbit',
        notification_fitbit_disconnected: 'Disconnected from Fitbit',
        notification_copy_clipboard: 'Copied to clipboard',
        notification_generic_success: 'Operation successful',
        notification_generic_error: 'Operation failed with an error',
        notification_authorization_get_error: 'Error loading access codes/links',
        notification_authorization_saved: 'Access saved',
        notification_authorization_saved_license_limit_reached: 'Access saved. Valid until date of license expiration',
        notification_authorization_saved_error: 'Error saving access',
        notification_authorization_removed: 'Access removed',
        notification_authorization_removed_error: 'Error removing access',
        notification_idle_logout: 'Automatic logout due to inactivity',
        notification_idle_logout_warning: 'Inactivity detected, logout in $$ minutes',
        notification_password_saved: 'Password saved',
        notification_password_saved_error: 'Error saving the password',
        notification_system_notification_saved: 'System notification "$$" saved',
        notification_system_notification_removed: 'System notification removed',
        notification_sleep_window_saved: 'Sleep window saved',
        notification_sleep_window_saved_error: 'Error saving sleep window',
        notification_code_redeemed: 'Voucher redeemed',
        notification_voucher_saved: 'Voucher successfully saved',
        notification_voucher_saved_error: 'Unable to save voucher',
        notification_vouchers_export_error: 'Error while exporting vouchers',
        notification_vouchers_manual_processing_started:
            'Manually treating vouchers, this could take some time, please be patient...',
        notification_vouchers_manual_processing_success: 'Successfully treated $$ vouchers manually',
        notification_vouchers_manual_processing_error: 'Error while treating vouchers manually',
        notification_sync_vouchers_success: 'Vouchers successfully synchronised',
        notification_sync_vouchers_error: 'Error while synchronising vouchers',
        notification_voucher_generic_error: 'Error redeeming the code',
        notification_diga_code_check_download_success: 'Verification report created with success',
        notification_diga_code_check_download_error: 'Could not create verification report',
        notification_xRechnung_send_success: 'X-Rechnung successfully transmitted',
        notification_xRechnung_send_error: 'Error sending the X-Rechnung',
        notification_voucher_removed: 'Voucher has been removed',
        notification_voucher_removed_error: 'Voucher could not be removed',
        notification_secure_logout: 'Safely logged out',
        notification_profile_export_error: 'Unable to download profile',
        notification_role_added: 'Rolle hinzugefügt',
        notification_role_added_error: 'Rolle konnte nicht hinzugefügt werden',
        notification_role_removed: 'Rolle entfernt',
        notification_role_removed_error: 'Rolle konnte nicht entfernt werden',
        notification_all_modules_unlocked: 'All modules unlocked',
        notification_all_modules_unlocked_error: 'Modules could not be unlocked',
        notification_wait_times_removed: 'All waiting times removed',
        notification_wait_times_removed_error: 'Waiting times could not be removed',
        notification_license_saved: 'license saved',
        notification_license_saved_error: 'license could not be saved',
        notification_demo_entries_generated: 'Demo data has been generated',
        notification_demo_entries_generated_error: 'Demo data could not be generated',
        notification_sleep_journal_visual_analysis_success:
            'Successfully created visual evaluation of sleep journal entries',
        notification_sleep_journal_visual_analysis_error: 'Unable to create visual evaluation of sleep journal entries',
        notification_sleep_journal_excel_export_success: 'Successfully downloaded sleep journal entries',
        notification_sleep_journal_excel_export_failure: 'Unable to export sleep journal entries',
        notification_resent_activation_email: 'Activation email has been resent',
        notification_resent_activation_email_error: 'Could not resent activation email',
        notification_contact_support_error: 'Message could not be sent',
        notification_journal_summary_error: 'Summary could not be loaded',
        notification_sleep_window_min_length: 'Minimal window length: $$ hours',
        notification_sleep_window_max_length: 'Maximal window length: $$ hours',
        notification_goals_saved: 'Your goals have been saved',
        notification_payrexx_sync_success: 'Synchronisation success',
        notification_payrexx_sync_failure: 'Synchronisation failure',
        notification_medication_plan_saved: 'Medication plan saved',
        notification_medication_plan_updated: 'Medication plan updated',
        notification_medication_plan_saved_error: 'Error saving medication plan',
        notification_medication_plan_removed: 'Medication plan deleted',
        notification_questionnaire_saved_success: 'Questionnaire saved',
        notification_questionnaire_saved_error: 'Unable to save questionnaire',
        notification_questionnaire_removed_success: 'Questionnaire successfully removed',
        notification_questionnaire_removed_error: "Questionnaire can't be removed",
        notification_journal_update_meditation: 'Duration has been saved to your journal',
        notification_web_authn_connected_error: 'Login without password could not be set up',
        notification_web_authn_login_error: 'Login without password failed',
        notification_device_deregistration_error: 'Could not remove device',
        notification_credential_rename_error: 'Could not rename device',
        notification_cannot_edit_step: 'Moule locked, cannot edit step right now',
        notification_exercise_saved: 'Exercise successfully saved',
        notification_exercise_save_error: 'Error while saving exercise',
        notification_operation_not_supported: 'Operation currently not supported',
        notification_userNotFound: 'User not found',
        notification_health_id_login_success: 'Successfully authorized with Health Id',
        notification_health_id_login_error: 'Error while authorising with Health Id',

        speech_no_data: ' - there is no data.',

        // sbk
        sbk_fields_salutation: 'Salutation',
        sbk_fields_title: 'Title',
        sbk_fields_first_name: 'First name',
        sbk_fields_last_name: 'Surname',
        sbk_fields_policy_number: 'Policy number',
        sbk_fields_date_of_birth: 'Date of birth',
        sbk_fields_insurance: 'Insurance',
        sbk_fields_phone: 'Phone number',
        sbk_fields_accept_ad_approval:
            'I agree that SBK may contact me in order to gather user feedback on this campaign by phone or e-mail and process my data for this purpose',
        sbk_fields_terms: 'I agree to the terms & conditions of SBK',
        sbk_fields_data_terms: 'Privacy policy',
        sbk_fields_participation_terms: 'Use criteria',
        sbk_fields_impressum: 'Impressum',
        sbk_save_personal_data: 'Save user data',
        sbk_load_personal_data_error: 'Could not load user data',
        sbk_must_confirm_new_campaign:
            'Please agree to the terms & conditions of Schlafgut in order to activate your account.',
        sbk_must_click_confirmation_link:
            "You've successfully registered for Schlafgut. Please click on the link sent to the e-mail address you provided for confirmation.",
        sbk_must_set_password: 'Please set a password to activate your account.',
        sbk_activation_succeeded_login_now: 'Your account has been activated successfully. You may log in now.',
        sbk_report_to_upload_triggered: 'The report will be generated and forwarded to SBK',
        sbk_can_login_now: 'You can log in now',
        sbk_register_with_existing_credentials: 'Please register and use the credentials of your existing account',
        sbk_register_using_link: 'Please register using the link to the campaign',
        sbk_or_login: 'Participate with existing registration data from a previous SBK health promotion campaign.',
        sbk_app_name: 'Sleep well!',
        sbk_app_lead: 'Start the day with more energy',
        sbk_claim: 'We are on <br> your side',
        sbk_campaign_has_expired:
            'The campaign you have registered for is deactivated. Please register for a new campaign using your existing login details via a registration link you will receive from SBK',
        sbk_recommend: 'Recommend SBK',
        sbk_recommend_detail:
            'We are pleased that you are taking part in "Sleep well! Let your friends, acquaintances or colleagues benefit from it and recommend us to others! We will thank you with attractive rewards!',
        sbk_recommend_link: 'Simply click here!',
        sbk_fields_terms_1: 'Yes, I have read the ',
        sbk_fields_terms_2: ' and the ',
        sbk_fields_terms_3:
            'for the SBK campaign "Sleep well" and agree to the processing of personal data specified therein.',
        sbk_fields_invalid_list: 'The following form fields are invalid:',

        tip_add_answer: 'Add answer',
        tip_remove_answer: 'Remove answer',
        tip_drag_to_reorder: 'Drag to reorder',
        tip_edit: 'Edit',
        tip_details: 'Details',
        tip_next_week: 'Next week',
        tip_previous_week: 'Previous week',
        tip_user_settings: 'Manage your account',
        tip_replay: 'Play again',
        tip_back: 'Replay this step',
        tip_select_animation: 'Select animation',
        tip_add_condition: 'Add condition',
        tip_remove_condition: 'Remove condition',
        tip_save_in_answer_container: 'Container into which the answer is saved',
        tip_answer_key: 'Name of the key under which the response is stored',
        tip_answer_type: 'Expected format of the response',
        tip_answer_list_items: 'List of allowed responses',
        tip_next_step: 'Next step if condition matches',
        tip_localised_answer_text: 'Localized answer text',
        tip_answer_value: 'Value to save upon selection of this answer',
        tip_copy_to_clipboard: 'Copy to clipboard',
        tip_pause: 'Pause',
        tip_play: 'Play',
        tip_skip: 'Skip',
        tip_one_step_back: 'Back to previous step',
        tip_duplicate: 'Duplicate',
        tip_required_entries_details: 'Number of required journal entries to unlock the next module',
        tip_clear_animation: 'Clear animation',
        tip_performance_since_last_sleep_phase: 'Performance since last sleep phase',
        tip_journal_entry_date: 'The date should match the the day you got up',
        tip_journal_mood: 'Mood since last sleep phase',

        tip_questionnaire_name: "The questionnaire key can only be set once and can't be changed afterwards",

        validation_input_query: 'Is your entry correct?',
        validation_input_query_coaching: 'Is your entry correct?',
        validation_required: 'Required',
        validation_missing_value: 'Input missing',
        validation_valid_email: 'Valid e-mail address',
        validation_invalid_email: 'Invalid e-mail address',
        validation_valid_password: 'Valid password',
        validation_password_too_short: 'Password too short',
        validation_password_too_long: 'Password too long (max. 30 characters)',
        validation_min_length: 'Minimum of $$ characters',
        validation_max_length: 'Maximum of $$ characters',
        validation_license_error_general: 'Error',
        validation_time_invalid: 'Time invalid',
        validation_date_invalid: 'Date invalid',
        validation_repeated_password_mismatch: "Passwords don't match",
        validation_must_accept_terms: 'Please accept the T&C and the PP',
        validation_must_accept_pp: 'Please accept the PP',
        validation_required_to_access: 'Requirements for using the program',
        validation_password_wrong: 'Your old password was incorrect',
        validation_invalid_expression: 'Invalid expression',
        validation_one_answer_required: 'At least one answer is required',
        validation_invalid_license_code: 'Invalid license code',
        validation_license_code: 'license code',
        validation_diga_code: 'DiGA code',
        validation_min_chars_criteria: 'Contains at least 8 characters',
        validation_special_char_criteria: 'Contains a special character',
        validation_upper_case_criteria: 'Contains an uppercase character',
        validation_date_in_future: 'Date cannot be in the future',
        validation_too_short: 'Entry too short',
        validation_no_match_email: 'E-mail address does not match',
        validation_min: 'Minimum $$',
        validation_max: 'Maximum $$',
        validation_negative_number: 'No negative numbers',

        welcome_title: 'Welcome!',
        welcome_next_steps: 'Next steps',
        welcome_skip_modules: 'Skip modules',
        welcome_text_generic: 'We look forward to accompanying you on your journey.',
        welcome_text_sleep: 'We look forward to accompanying you on the journey to better sleep.',
        welcome_text_b: 'Simply click on the first module in the overview to start the training.',
        welcome_text_c:
            'This is a <b>demo account</b>. In the profile you can unlock all modules and trigger further actions.',
        welcome_text_d:
            'Modules that you have already completed in the last training can be skipped with a single click after starting the module.',

        // actensio notification keys
        notification_hypertension_journal_excel_export_success: 'Download der Hypertonie-Tagebucheinträge',
        notification_hypertension_journal_excel_export_failure: 'Fehler beim Export der Hypertonie-Tagebucheinträge',
        // actensio export keys
        general_data_export_hypertension_journals_xlsx_sheet_user_mail: 'Benutzer',
        general_data_export_hypertension_journals_xlsx_title: 'Export von Hypertonie-Tagebucheinträgen',
        general_data_export_hypertension_journals_xlsx_details:
            'Die Einträge, die du im Hypertonie-Tagebuch erfasst hast, sind hier verfügbar im Excel-Format.',
        general_data_export_hypertension_journals_xlsx_sheet_title: 'Hypertonie-Tagebucheinträge',
        general_data_export_hypertension_journals_xlsx_sheet_date_created: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_nr: 'Eintrag Nr.',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_date: 'Messzeitpunkt',
        general_data_export_hypertension_journals_xlsx_sheet_header_created_at: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_fruits: 'Obst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_vegetables: 'Gemüse',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_whole_grain: 'Vollkorn',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_low_fat_milk: 'Fettarme Milchprodukte',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_good_fats:
            'Nüsse, Hülsenfrüchte & Ölsaaten',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_salt: 'Salz',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_red_meat: 'Fettiges/rotes Fleisch',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_sugar: 'Stark gezuckerte Lebensmittel',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_dash_score: 'DASH-Score',
        general_data_export_hypertension_journals_xlsx_sheet_header_from_tracker: 'Von Fitnesstracker geladen',

        general_data_export_hypertension_journals_xlsx_sheet_header_medication: 'Medikament',
        general_data_export_hypertension_journals_xlsx_sheet_header_medication_dosage: 'Dosierung',

        general_data_export_hypertension_journals_xlsx_sheet_header_activity_low_intensity: 'Geringe Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_medium_intensity: 'Mittlere Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_high_intensity: 'Hohe Intensität',

        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_mindfulness: 'Achtsamkeit',
        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_other_meditation:
            'Andere Entspannungstechnik',

        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_alcohol: 'Alkoholische Getränke',
        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_cigarettes: 'Zigaretten',

        general_data_export_hypertension_journals_xlsx_blood_pressure_protocol_title: 'Export BlutdruckProtokoll',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_source: 'Quelle',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_location: 'Ort der Messung',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_updated_time: 'Datum aktualisiert',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_has_complaints: 'Beschwerden',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_systolic: 'Systolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_diastolic: 'Diastolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_pulse: 'Puls',

        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_paralysis: 'Lähmung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_speechImpediment: 'Sprachstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_visualImpairment: 'Sehstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_headache: 'Kopfschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_chestPain: 'Brustschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_breathlessness: 'Atemnot',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_dizziness: 'Schwindel',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_tinnitus: 'Tinnitus',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_noseBleed: 'Nasenbluten',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_home: 'Zuhause',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_doctor: 'Arztpraxis',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_pharmacy: 'Apotheke',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_manual: 'Manuell',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_thryve: 'Von Thryve',

        high_systolic_blood_pressure_warning:
            'Du hast einen systolischen <b>Blutdruck über 180</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br> Ist dein Blutdruck bei <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_diastolic_blood_pressure_warning:
            'Du hast einen diastolischen  <b>Blutdruck über 120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_systolic_and_diastolic_blood_pressure_warning:
            'Du hast einen <b>Blutdruck über 180/120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den  <b>ärztlichen Notdienst</b>.',
        low_blood_pressure_warning:
            'Deine Blutdruckwerte sind ungewöhnlich <b>niedrig</b>. <br> Bitte halte mit deinem behandelnden Arzt oder deiner behandelnden Ärztin Rücksprache über diese Werte',

        // entry warning message bits
        input_message_with_value:
            "You just documented a '$$' that is longer than 12 hours. <br> <br> Are you sure that's correct? Please note that the time input is in 24 hour format.",
        journal_fall_asleep_duration_warning: 'Fall asleep time',
        journal_sleep_time_warning: 'Sleep time',
        journal_time_in_bed_warning: 'Time in bed',
        journal_entry_warning_and: 'and',
    },
    en_au: {
        blot_function: 'Function',
        blot_value: 'Value',
        blot_speech: 'Speech',
        blot_animation: 'Animation',
        blot_coach: 'Coach',
        blot_expressionValue: 'Expression value',
        blot_bracket: 'Bracket',
        blot_comparator: 'Comparator',
        blot_logic: 'Logic',
        blot_evaluation: 'Evaluation',
        blot_menu: 'Menu',
        blot_custom: 'Custom',

        breathing_inhale: 'Inhale',
        breathing_exhale: 'Exhale',

        body_scan_title: 'Body scan',
        body_scan_instruction: 'Here you can listen to/download the body scan in your own time',

        concat_fallAsleepFaster: 'Fall asleep faster',
        concat_laterWakeUp: 'Wake up later',
        concat_betterSleepThrough: 'Less time awake',
        concat_sleepLonger: 'Longer sleep',
        concat_betterSleepQuality: 'Better sleep',
        concat_moreEnergy: 'More energy',

        evaluation_completed_content: 'Completed contents of the program',
        evaluation_connected_to: 'Connected to',
        evaluation_energy_during_day: 'Energy during the day',
        evaluation_energy_during_day_legend: 'Energy during the day (0 = a little, 10 = a lot)',
        evaluation_fall_asleep_time: 'Fall asleep time', // Einschlafdauer // Endormi(e) après
        evaluation_fitness_tracker: 'Fitness tracker',
        evaluation_insomnia_type: 'Type of insomnia',
        evaluation_insomnia_type_A: 'Type A',
        evaluation_insomnia_type_A_info: 'Primarily problems falling asleep',
        evaluation_insomnia_type_B: 'Type B',
        evaluation_insomnia_type_B_info: 'Primarily problems remaining asleep',
        evaluation_insomnia_type_C: 'Type C',
        evaluation_insomnia_type_C_info: 'Primarily problems of early awakening',
        evaluation_insomnia_type_mixedAB: 'Mixed (AB)',
        evaluation_insomnia_type_mixedABC: 'Mixed (ABC)',
        evaluation_insomnia_type_mixedABC_info:
            'Primarily problems falling and remaining asleep and of early awakening',
        evaluation_insomnia_type_mixedAB_info: 'primarily problems falling asleep and remaining asleep',
        evaluation_insomnia_type_mixedAC: 'Mixed (AC)',
        evaluation_insomnia_type_mixedAC_info: 'Primarily problems falling asleep and of early awakening',
        evaluation_insomnia_type_mixedBC: 'Mixed (BC)',
        evaluation_insomnia_type_mixedBC_info: 'Primarily problems sleeping through and early awakening',
        evaluation_insomnia_type_tooltip: 'Type of sleep problems',
        evaluation_isi_end: 'ISI-End',
        evaluation_isi_end_tooltip: "Sleep Score in module 'Closing session'",
        evaluation_isi_follow_up: 'ISI-Follow-up',
        evaluation_isi_follow_up_tooltip: "Sleep score in module 'Follow up'",
        evaluation_isi_score_high: 'Indication for clinical insomnia (severe)',
        evaluation_isi_score_light: 'Indication for subthreshold insomnia',
        evaluation_isi_score_low: 'No indication of clinically significant insomnia',
        evaluation_isi_score_moderate: 'Indication for clinical insomnia (moderate severity)',
        evaluation_isi_start: 'ISI-Start',
        evaluation_isi_start_tooltip: "Sleep score in module 'Introduction'",
        evaluation_number_of_hours: 'Number of hours',
        evaluation_number_of_minutes: 'Number of minutes',
        evaluation_number_of_modules: 'Number of modules',
        evaluation_relevant_params: 'Relevant parameters',
        evaluation_sleep_efficiency: 'Sleep efficiency',
        evaluation_sleep_efficiency_explain_text:
            'Sleep efficiency is the ratio of sleep duration to time spent in bed',
        evaluation_sleep_journal_entries: 'Sleep journal entries',
        evaluation_blood_sugar_journal_entries: 'Blood sugar entries',
        evaluation_sleep_journal_entries_split: 'Sleep journal entries',
        evaluation_sleep_type: 'Sleep type',
        evaluation_sleep_type_definitiveLark: 'Definite lark',
        evaluation_sleep_type_definitiveLark_info: 'Moderate morning type (daily high in the morning)',
        evaluation_sleep_type_definitiveNightOwl: 'Definite owl',
        evaluation_sleep_type_definitiveNightOwl_info: 'Evening type (daily high in the evening)',
        evaluation_sleep_type_moderateLark: 'Moderate lark',
        evaluation_sleep_type_moderateLark_info: 'Morning type (daily high in the morning)',
        evaluation_sleep_type_moderateNightOwl: 'Moderate owl',
        evaluation_sleep_type_moderateNightOwl_info:
            'Moderate evening type (daily high in the late afternoon or evening)',
        evaluation_sleep_type_neutralType: 'Neutral',
        evaluation_sleep_type_neutralType_info: 'Neither evening nor morning type',
        evaluation_sleep_type_tooltip: 'Chronotype',
        evaluation_start_date: 'Start date',
        evaluation_subjective_sleep_quality: 'Subjective sleep quality',
        evaluation_sleep_time: 'Sleep time',
        evaluation_time_in_bed: 'Time in bed',
        evaluation_time_in_bed_and_sleep_time: 'Time in bed and sleep time',
        evaluation_unknown_device: 'Unknown device',
        evaluation_wake_time_during_night: 'Awake time at night',
        evaluation_wake_time_in_bed_after_wake_up: 'Wake time in bed after wake-up',
        evaluation_average_sleep_efficiency_of_last_seven_days:
            'Your sleep efficiency (average of the last seven days) is',
        evaluation_sleep_rhythm: 'Sleep rhythm',

        error_code_429: 'Too many attempts, please wait and try again',
        error_code_500: 'Unknown server error',
        error_code_1001: 'Operation not allowed',
        error_code_3000: 'Connection could not be established',
        error_code_9001: 'Wrong email or password',
        error_code_9002: 'Error finding user',
        error_code_9003: 'Failed to log in after external authentication',
        error_code_9004: 'Failed to authenticate with external provider',
        error_code_9005: 'Failed to create user after successful authentication',
        error_code_9006: 'E-mail address has already been used',
        error_code_9007:
            'Your account has not yet been activated: please click on the link that was sent to you after registration.',
        error_code_9008: 'The old password is incorrect',
        error_code_9009: 'Invalid activation link or account already activated',
        error_code_9010: 'The account data could not be updated',
        error_code_9011: 'User activation failure - please try again',
        error_code_9012:
            'Please do not log in using Google authentication, but with your original registration login method',
        error_code_9013: 'Reset token invalid or expired',
        error_code_9015: 'Second factor needed',
        error_code_9016: 'Code invalid, please log in again',
        error_code_9022: 'Invalid code',
        error_code_9023: 'license code has already been used',
        error_code_9024: 'You provided a discount code',
        error_code_9025: 'You have entered a discount code',
        error_code_9026: 'Could not initialize license',
        error_code_9027: 'A license of the same type already exists',
        error_code_9029: 'A coaching with this key already exists',
        error_code_9039: 'Journal entry overlaps another entry',
        error_code_9040: 'A voucher cannot be redeemed several times for the same account',
        error_code_9042: 'Your request was rejected',
        error_code_9043: 'User is currently not connected',
        error_code_9047: 'Invalid license code',
        error_code_9048: 'Discount code instead of license code',
        error_code_9049: 'license code depleted',
        error_code_9051: 'Voucher is already in use',
        error_code_9053: 'You are not authorized to download this file',
        error_code_9054: 'No license code provided',
        error_code_9055: 'Invalid license code: please contact us',
        error_code_9056: 'License code already used',
        error_code_9057: 'License code not valid yet',
        error_code_9058: 'License code expired',
        error_code_9059: 'Invalid object',
        error_code_9060: 'license code could not be verified',
        error_code_9061: 'Prescription code X-Rechnung could not be sent',
        error_code_9062: 'DiGA endpoint communication error',
        error_code_9063: 'X-Rechnung has already been submitted and/ or paid',
        error_code_9064: 'X-Rechnung has already been recorded in SevDesk',
        error_code_9066: 'Sorry, there are not enough data available - please re-do the introduction module',
        error_code_9067: 'Error finding Medication',
        error_code_9069: 'Coaching could not be found',
        error_code_9070: 'Account not valid for current application',
        error_code_9071: 'License code is not intended for the current application',
        error_code_9080: 'Medication could not be found',
        error_code_9081: 'Could not store the sleep window - please reload the page or restart the app and try again',
        error_code_9082: 'Please check internet connection and then try again',
        error_code_9083: 'Incomplete data: graphical analysis not possible',
        error_code_9085: 'IP address is not on whitelist',
        error_code_9086:
            'Changing your email before activating your account is only allowed for a short period of time.',
        error_code_9089: 'Upgrade failed',
        error_code_9090: 'Downgrade is not allowed. Please contact our Support',
        error_code_9091: 'Passwordless setup with the device has failed: $$',
        error_code_9092:
            'Setup failure: has this device already been registered? Try to uninstall and reinstall the app.',
        error_code_9207: 'No next module id found',
        error_code_9208: 'Could not found a module step',
        error_code_9211: 'No module step or no entry module found',
        error_code_9214: 'Found more than one module step for criteria',
        error_code_undefined: 'Unknown error',
        error_code_null: 'Unknown error',
        error_code_general: 'Error',

        // iOS Native
        ios_message_failure: 'Could not send message to iOS',

        // sbk
        error_code_9100: 'Unknown communication error',
        error_code_9101: 'Activation key unknown',
        error_code_9102: 'Activation key invalid',
        error_code_9103: 'Activation key expired',
        error_code_9104: 'New password must be set',
        error_code_9105: 'Invalid credentials',
        error_code_9106: 'Please register using your existing credentials',
        error_code_9107: 'Campaign not started',
        error_code_9108: 'Campaign deactivated',
        error_code_9109: 'Data consent removed',
        error_code_9110:
            'Your account is not yet activated. We have sent you a welcome e-mail with which you can confirm your participation.',
        error_code_9111: 'No data consent given',
        error_code_9112: 'Already registered with other campaign',
        error_code_9113: 'Registration closed',
        error_code_9114: 'Unknown email',
        error_code_9115: 'Wrong campaign ID',
        error_code_9116: 'Minimum delay to reset password has not yet passed',
        error_code_9117: 'Minimum delay to resend activation link has not yet passed',
        error_code_9118: 'Registration fields are invalid',
        error_code_9119: 'Your account is already activated',
        error_code_9097: 'No legal update',

        // thryve
        error_code_9200: "There's already a connection with a tracker",
        error_code_9201: "There's no connection with a tracker yet",
        error_code_9202: 'A connection attempt was made few minutes ago, please wait a few minutes before trying again',

        // journals
        error_code_9300: 'Entry already exists for this date',

        doc_access_title: 'Access for medical personnel',
        doc_access_patient_data_info:
            'Evaluation data of a patient can be retrieved via a 8-digit access code. The access requires a manual confirmation by the patient.',
        doc_waiting_for_patient: 'Waiting for patient confirmation...',

        general_complete: 'Finalise',
        general_device_name: 'Device name (e.g. My laptop)',
        general_created: 'Created',
        general_last_used: 'Last used',
        general_welcome_back: 'Welcome back!',
        general_not_now: 'Not now',
        general_manual: 'Instruction manual',
        general_worries: 'worries',
        general_overload: 'overload',
        general_leisure: 'leisure',
        general_daily_life: 'everyday life',
        general_work: 'work',
        general_summary: 'Summary (PDF)',
        general_all_summaries: 'All summaries',
        general_max_number_of_files: 'maximum number of files: $$',
        general_size_limit: 'maximum size of files: $$ MB',
        general_file_type_not_allowed: 'This file type is not allowed',
        general_and: 'and',
        general_answerFromSelection: 'Label of answer item',
        general_cigarette: 'cigarette',
        general_cigarettes: 'cigarettes',
        general_euro: 'Euro',
        general_euros: 'Euros',
        general_week: 'Week',
        general_weeks: 'weeks',
        general_mmHg: 'milimeter of mercury',
        general_bpm: 'beats per minute',
        general_mmHg_abr: 'mmHg',
        general_bpm_abr: 'bpm',
        general_entry: 'entry',
        general_entries: 'entries',
        general_piece: 'piece',
        general_pieces: 'pieces',
        general_piece_pieces: 'piece(s)',
        general_portion: 'portion',
        general_portions: 'portions',
        general_portion_portions: 'portion(s)',
        general_leave_app_confirmation: 'Attention: you are leaving the app. Continue?',
        general_warn_fitbit_external_link: 'Attention: when you click on "Connect to fitbit" you will leave the app.',
        general_terms_and_conditions: 'Terms and conditions',
        general_privacy_policy: 'Privacy policy',
        general_app_version: 'App version',
        general_data_processing: 'Data processing consent',
        general_exclusion_criteria: 'exclusion criteria',
        general_no_confirmation_mail: 'Confirmation email not received?',
        general_forgot_password: 'Forgot password',
        general_average: 'Average',
        general_explain_license_extension:
            'You can renew your current license or unlock a new program by entering a license code.',
        general_for_program: 'For application',
        general_goals: 'Goals',
        general_goals_change_in_profile: 'Here you can customize your personal goals',
        general_first_goal: 'First goal',
        general_second_goal: 'Second goal',
        general_third_goal: 'Third goal',
        general_no_goal: 'No goal',
        general_overall: 'overall',
        general_show_regression: 'Show regression',
        general_institution: "Doctor's office",
        general_location: 'Postal code',
        general_updates: 'Updates',
        general_prompt_remove_entry: 'Are you sure you want to remove this entry?',
        general_weekly_average: '7-day average',
        general_your_entry: 'Your entry',
        general_confirm: 'Confirm',
        general_voucher_export: 'CSV export',
        general_two_factor_details: 'Enable two-factor authentication for additional security',
        general_two_factor_authentication: 'Two-Factor Authentication',
        general_create_password_to_activate: 'Please create a password to activate your account:',
        general_click_to_activate_account: 'You have already set a password. Click below to activate the app.',
        general_generate_demo_data_prompt:
            'All existing journal entries will be deleted. Sure that you want to continue?',
        general_locked: 'Locked',
        general_select_provider: 'Select provider',
        general_demo_account: 'Demo account',
        general_flag_for_rating: 'Flag for rating',
        general_provider: 'Provider',
        general_generate_sleep_data: 'Generate entries',
        general_demo_actions: 'Demo actions',
        general_demo_actions_details: 'You have a demo account. The following actions are available:',
        general_remove_wait_times: 'Remove wait times',
        general_unlock_all_modules: 'Unlock all modules',
        general_here: 'here',
        general_link: 'Link',
        general_more_info: 'More information',
        general_auto_next: 'Continue automatically',
        general_auto_next_detail: 'Jump to next step automatically at the end of a step if no interaction is required',
        general_module_duration: 'Duration: $$ minutes',
        general_sleep_phase: 'Sleep phase',
        general_awake_phase: 'Awake phase',
        general_consent: 'Consent',
        general_sources: 'Sources',
        general_gender_other: 'Other',
        general_analysis: 'Analysis',
        general_reports: 'Reports',
        general_in_progress_please_wait: 'In progress, please wait...',
        general_job_started_mail_will_be_sent: 'Job started, the result will be sent to your mail address',
        general_job_started_notification_will_be_sent:
            "Job started, you'll be notified once the report is ready for download",
        general_job_completed: 'Job has been completed',
        general_active_after_wakeup: 'Active after wakeup',
        general_sleep_quality: 'Sleep quality',
        general_comparison: 'comparison',
        general_split_evaluation_view: 'Delta evaluation (data is divided visually)',
        general_new_entry: 'New entry',
        general_edit_entry: 'Edit entry',
        general_sleep: 'Sleep',
        general_voucher_internal: 'Internal only',
        general_license_expired: 'Your license has expired',
        general_answer_label: 'Answer label',
        general_support_confirmation: 'Request sent',
        general_send_request: 'Submit',
        general_request: 'Request',
        general_request_detail: 'Please describe your request as precisely as possible',
        general_new_password: 'New password',
        general_own_next: 'Own "next" button',
        general_your_insomnia_cycle: 'Your insomnia cycle',
        general_feedback: 'Feedback',
        general_entries_remaining_help_title: 'Remaining entries?',
        general_entries_remaining_help:
            'The program controls various contents and interventions based on your journal entries. A certain minimum number of entries is required for this to work and for further modules to be opened.',
        general_entries_remaining_help_b: 'Only new entries that you created after completing the last module count.',
        general_entry_remaining: '1 entry remaining',
        general_entries_remaining: '$$ entries remaining',
        general_your_sleep_window: 'Sleep window',
        general_no_sleep: 'No sleep',
        general_entries_remaining_evening: 'noch <b>$$</b> Einträge im Abendprotokoll nötig',
        general_entries_remaining_blood_pressure: 'noch <b>$$</b> Einträge im Blutdruckprotokoll nötig',
        general_entry_remaining_evening: 'noch <b>1</b> Eintrag im Abendprotokoll nötig',
        general_entry_remaining_blood_pressure: 'noch <b>1</b> Eintrag im Blutdruckprotokoll nötig',
        general_entries_to_unlock_module: 'Um das nächste Modul freizuschalten, sind',
        general_shift_window_back: 'Shift window back',
        general_save_window: 'Save sleep window',
        general_shift_window_forward: 'Shift window forward',
        general_plus_minutes: '+$$ minutes',
        general_minus_minutes: '-$$ minutes',
        general_age: 'Age',
        general_gender: 'Gender',
        general_male: 'Male',
        general_female: 'Female',
        general_custom_element: 'Custom element',
        general_menu: 'Menu',
        general_point: 'point',
        general_points: 'points',
        general_add_notification: 'Create notification',
        general_history: 'History',
        general_validators: 'Validators',
        general_system_notifications: 'Notifications',
        general_data_export_profile_json_title: 'Export',
        general_data_export_profile_json_details:
            'You can download all data stored in the system about you in an interoperable format (JSON).',
        general_data_export_sleep_journals_xlsx_title: 'Export sleep journal entries',
        general_data_export_sleep_journals_xlsx_awake_protocol_title: 'Export awake protocol',
        general_data_export_sleep_journals_xlsx_details: 'Your sleep journal data is available here in Excel format.',
        general_data_export_sleep_journals_xlsx_sheet_title: 'Sleep journal entries',
        general_data_export_sleep_journals_xlsx_sheet_date_created: 'Export date',
        general_data_export_sleep_journals_xlsx_sheet_user_mail: 'User',
        general_data_export_sleep_journals_xlsx_sheet_header_entry_nr: 'Entry no.',
        general_data_export_sleep_journals_xlsx_sheet_header_to_bed_date: 'Went to bed',
        general_data_export_sleep_journals_xlsx_sheet_header_get_up_date: 'Got up',
        general_data_export_sleep_journals_xlsx_sheet_header_no_sleep: 'Did not fall asleep',
        general_data_export_sleep_journals_xlsx_sheet_header_tried_to_sleep: 'Tried to sleep',
        general_data_export_sleep_journals_xlsx_sheet_header_fell_asleep: 'Fell asleep',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_amount: 'Number of waking phases',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_duration:
            'Duration of waking phases during sleep phase',
        general_data_export_sleep_journals_xlsx_sheet_header_wake_up_date: 'Woke up',
        general_data_export_sleep_journals_xlsx_sheet_header_from_tracker: 'Imported from sleep devices',
        general_data_export_visual_analysis_title: 'Visual evaluation',
        general_data_export_visual_analysis_details: 'Your journal data is available here in PDF format.',
        general_data_export_visual_analysis_file_name: 'somnio_sleep_data',
        general_data_export_visual_analysis_averages_last_week: 'Average values - last week',
        general_data_export_visual_analysis_overall: 'Values overall',
        general_data_export_visual_analysis_relation_bed_sleep: 'Time in bed / time asleep',
        general_data_export_visual_analysis_relation_bed: 'Time in bed',
        general_data_export_visual_analysis_relation_sleep: 'Time asleep',
        general_data_export_visual_analysis_awake_in_bed: 'Time in bed after waking up',
        general_data_export_visual_analysis_sleep_data: 'Medical report of',
        general_download: 'Download',
        general_encrypted: 'encrypted',
        general_view_download: 'View/Download',
        general_coming_soon: 'Coming soon',
        general_download_as_pdf: 'Download as PDF',
        general_open_print_dialog: 'Open print dialog',
        general_data_security: 'Data security',
        general_revoke_consent: 'Revoke consent',
        general_warning: 'Warning',
        general_prompt_revoke_consent_info:
            'By revoking consent to the processing of personal data, the application can no longer be used. Your account will be deactivated.',
        general_prompt_revoke_consent_confirm:
            'Please confirm the revocation by entering the email address of the account.',
        general_prompt_remove_account_info:
            'When the account is removed, your profile is irrevocably deleted together with all associated data.',
        general_prompt_remove_account_confirm:
            'Please confirm the deletion by entering the e-mail address of the account.',
        general_account: 'Account',
        general_data_processing_agreement:
            'When creating your account, you have given consent to mementor for the processing your personal data.',
        general_remove_account: 'Delete account',
        general_remove_account_description:
            'The account is physically deleted, i.e. the entire entry is removed from the database',
        general_anonymize_account: 'Anonymize account',
        general_anonymize_account_description:
            'The data associated with the user is deleted. Only data specific to billing such as registration date, license and vouchers will be retained. The e-mail address is made anonymous.',
        general_allow_medical_access_short: 'Allow access?',
        general_allow_medical_access:
            'Do you want to give "$$" one-time access to the evaluation of your therapy data?',
        general_load_evaluation: 'Load evaluation',
        general_optional: 'Optional',
        general_code: 'Code',
        general_percentage: 'Percentage',
        general_decibel: 'Decibel',
        general_sleep_efficiency: 'Sleep efficiency',
        general_bed_time: 'Bed time',
        general_sleep_time: 'Sleep time',
        general_prompt_confirm_short: 'Sure?',
        general_prompt_confirm: 'Are you sure?',
        general_yes: 'Yes',
        general_no: 'No',
        general_forward: 'Next',
        general_back: 'Back',
        general_sleep_journal_evaluation: 'Sleep journal',
        general_sleep_tracker: 'Sleep tracker',
        general_sleep_tracker_connected: 'Sleep tracker is connected. The sleep diary is filled out automatically.',
        general_connect_fitbit: 'Connect to Fitbit',
        general_disconnect_fitbit: 'Disconnect',
        general_connect_fitbit_info:
            'Connecting a Fitbit sleep devices allows you to fill out your sleep journal automatically',
        general_times: 'times',
        general_health_data_consent: 'processing of my health data',
        general_health_data_consent_a: 'I agree to the',
        general_health_data_consent_b: '',
        general_medication_plan: 'Medicationplan',
        general_drag_file: 'Drag file',
        general_or_select_file: 'Or select file',
        general_or: 'Or',
        general_support_page_somnio:
            'For questions and support please visit our <a href="https://support.mementor.de/help/en-gb/16-somnio" target="_blank">support page</a>.',
        general_support_page_actensio:
            'For questions and support please visit our <a href="https://support.mementor.de/help/de-de/49-actensio" target="_blank">support page</a>.',
        general_support_question: 'Unable to resolve your problem or want to contact us directly? ',
        general_support_option_choose: 'Select a type of problem',
        general_support_tutorial_sentence: 'Find a tutorial on how to register by video here',
        general_support_tutorial_button: 'Watch video',
        general_support_content_issues: 'Content issues',
        general_support_technical_issues: 'Technical issues',
        general_support_technical_tooltip: 'If you have technical issues you will get help here',
        general_support_faq: 'You can find answers to the most frequently asked questions on the support page of',
        general_support_counseling: 'Make an appointment directly here',
        general_support_calendar: 'To the calendar',
        general_support_form: 'Get in touch using the support form below',
        general_support_page_sbk:
            'For questions and support please visit our <a href="https://support.mementor.de/help/en-gb/16-somnio" target="_blank">support page</a>.',
        general_support_form_sbk:
            'Unable to resolve your problem or want to contact us directly? Get in touch at <a href="mailto:sbk.schlafgut@mementor.de">sbk.schlafgut@mementor.de</a>',
        general_help: 'Help',
        general_support: 'Support',
        general_notifications: 'Notifications',
        general_data_protection: 'Data protection',
        general_journal_entries: 'Journal entries',
        general_required_entries: 'Required entries',
        general_required_entries_detail: 'Number of journal entries to unlock the next module',
        general_monday_abbr: 'MO',
        general_tuesday_abbr: 'TU',
        general_wednesday_abbr: 'WE',
        general_thursday_abbr: 'TH',
        general_friday_abbr: 'FR',
        general_saturday_abbr: 'SA',
        general_sunday_abbr: 'SU',
        general_language: 'Language',
        general_bad: 'Bad',
        general_good: 'Good',
        general_low: 'Low',
        general_high: 'High',
        general_large: 'Large',
        general_well_being: 'Well-being',
        general_consumption: 'Consumption',
        general_load_more: 'Load more',
        general_edit_voucher: 'Edit Voucher',
        general_edit_provider: 'Edit provider',
        general_edit_medication: 'Edit Medication',
        general_your_sleep_development: 'Sleep development',
        general_efficiency: 'Efficiency',
        general_fall_asleep_time: 'Fall asleep time',
        general_awake_time: 'Awake time',
        general_comment: 'Comment',
        general_hello_name: 'Hello $$!',
        general_sleep_type_mixed: 'mixed type',
        general_sleep_type_fallAsleep: 'fall asleep type',
        general_sleep_type_sleepThrough: 'sleep through type',
        general_sleep_type_wakeUpEarly: 'wake up early type',
        general_confirm_account: 'Confirm account',
        general_restart: 'Restart',
        general_add_user: 'Add user',
        general_user_actions: 'Actions',
        general_active: 'Active',
        general_account_activated_redirecting: 'Account activated, please log in with your email and password',
        general_password_reset: 'Password has been reset',
        general_reset_instructions_sent:
            'If an account with this e-mail address exists, further instructions have been sent to this address.',
        general_input_label: 'Input text label',
        general_min_value: 'Minimal value',
        general_max_value: 'Maximal value',
        general_external_value: 'External value',
        general_animation_blot_driven: 'animation is driven by external value',
        general_slider_label: 'Slider label',
        general_slider_min_label: 'Label minimal value',
        general_slider_max_label: 'Label maximal value',
        general_slider_min_value: 'Minimal value',
        general_slider_max_value: 'Maximal value',
        general_slider_show_ticks: 'Show ticks',
        general_day: 'day',
        general_days: 'days',
        general_day_days: 'day(s)',
        general_hour: 'hour',
        general_hours: 'hours',
        general_minute: 'minute',
        general_minute_minutes: 'minute(s)',
        general_module_entry_point: 'Module entry point',
        general_separate_by_comma: 'Separate elements with a comma',
        general_number: 'Number',
        general_integer: 'Integer',
        general_list: 'List',
        general_list_items: 'List elements',
        general_answer_type: 'Answer type',
        general_unit: 'Unit',
        general_key: 'Key',
        general_edit_answer_container: 'Edit answer container',
        general_add_answer_container: 'Add container',
        general_answer_containers: 'Answer containers',
        general_select_answer_container: 'Select answer container',
        general_answer_value: 'Value',
        general_answer_container: 'Container to save answer in',
        general_answer_container_name: 'Name of answer container',
        general_questionnaire: 'Questionnaire',
        general_questionnaires: 'Questionnaires',
        general_add_questionnaire: 'Add questionnaire',
        general_edit_questionnaire: 'Edit questionnaire',
        general_questions: 'Questions',
        general_question_question: 'Question',
        general_question_title: 'Title',
        general_edit_question: 'Edit question',
        general_add_question: 'Add question',
        general_copy_question: 'Copy question',
        general_question_type_select: 'Select',
        general_question_type_buttons: 'Button list',
        general_question_type_number_input: 'Number input',
        general_question_type_mobiscroll_number: 'Mobiscroll Number input',
        general_question_type_mobiscroll_timespan: 'Mobiscroll Timespan input',
        general_conditions: 'Conditions',
        general_expression: 'Expression',
        general_edit_user: 'Edit user',
        general_availability: 'Availability',
        general_filters: 'Filters',
        general_icon: 'Icon',
        general_edit_coaching: 'Edit coaching',
        general_spoken_text: 'Spoken text',
        general_edit_animation: 'Edit animation',
        general_add_animation: 'Add animation',
        general_select_animation: 'Select animation',
        general_select_questionnaire: 'Select questionnaire',
        general_select: 'Select',
        general_animations: 'Animations',
        general_animation: 'Animation',
        general_no_animation: 'No animation',
        general_new_modules_available: 'New modules available',
        general_update: 'Update',
        general_start: 'Start',
        general_start_module: 'Start module',
        general_continue_module: 'Continue',
        general_select_step: 'Step selection',
        general_edit_module: 'Edit module',
        general_minutes: 'minutes',
        general_mgdl: 'milligrams per deciliter',
        general_mmol: 'millimoles',
        general_drinking_glass: 'glass',
        general_drinking_glasses: 'glasses',
        general_percent: 'percent',
        general_percentage_point: 'percent point',
        general_percentage_points: 'percent points',
        general_specify_parameters: 'Specify parameters',
        general_select_step_property: 'Select text property',
        general_edit_step: 'Edit step',
        general_values: 'Values',
        general_functions: 'Functions',
        general_speech: 'Speech',
        general_roles: 'Roles',
        general_add_role: 'Add role',
        general_add: 'Add',
        general_roles_menu: 'Roles',
        general_sleep_training: 'Sleep training',
        general_minutes_short: ' min.',
        general_search: 'Search',
        general_wait_time: 'Wait time',
        general_flow_manager: 'Flow manager',
        general_select_module: 'Select module',
        general_first_name: 'First name',
        general_last_name: 'Last name',
        general_no_module_selected: 'No module selected',
        general_description: 'Description',
        general_saved_value: 'Saved value',
        general_next_step: 'Next step',
        general_complete_questionnaire: 'Complete questionnaire',
        general_save: 'Save',
        general_save_and_continue: 'Save & continue',
        general_delete: 'Delete',
        general_cancel: 'Cancel',
        general_add_module: 'Add module',
        general_create_module: 'Create module',
        general_add_coaching: 'Add coaching',
        general_value: 'Value',
        general_save_answer: 'Save answer',
        general_answer_property: 'Answer property name',
        general_users: 'Users',
        general_user: 'User',
        general_step_name: 'Step name',
        general_coaching_manager: 'Coachings',
        general_module_manager: 'Modules',
        general_step_manager: 'Steps',
        general_text: 'Text',
        general_email: 'Email',
        general_wrong_email: 'Typo? Change your email...',
        general_password: 'Password',
        general_password_set_new: 'Set a new password',
        general_password_new: 'New password',
        general_password_old: 'Old password',
        general_password_tip_show: 'Show password',
        general_password_tip_hide: 'Hide password',
        general_repeat_password: 'Repeat password',
        general_request_password: 'Forgot password',
        general_request_password_submit: 'Reset password',
        general_login: 'Login',
        general_login_action: 'Login',
        general_login_with_password: 'Login with password',
        general_login_passwordless: 'Login without password',
        general_manage_devices: 'Manage your devices',
        general_setup_and_go_passwordless: 'After setup you can log in without entering a password',
        general_setup: 'Setup',
        general_unregister_device: 'Unregister this device',
        general_login_faster: 'Log in faster',
        general_passwordless_login:
            'Your device supports login without password. Do you want to set up such an access now?',
        general_passwordless_login_active: 'Login without password enabled!',
        general_passwordless_login_active_b: 'Please enter a name for your device:',
        general_register: 'Create new account',
        general_register_long: 'Create a new account',
        general_prescription_or_license_code: 'Do you have a license code?',
        general_unlock: 'Unlock',
        general_enter_code: 'Code entry',
        general_enter_code_detail: 'Enter your 16-digit license code here:',
        general_start_without_code:
            'You can already create your account. But to use the program you need a license code.',
        general_we_will_support_you: 'We will support you in this.',
        general_register_action: 'Register',
        general_register_tk: 'Registration TK',
        general_almost_done: 'Almost done!',
        general_registration_success: 'Registration has succeeded! Please check your inbox ($$).',
        general_notify_mail_delay: ' Note that it may take several minutes until the confirmation mail arrives.',
        general_activation_success: 'Activation succeeded. You may now log in.',
        general_activation_new_password_success:
            'Request succeeded - please check your inbox to retrieve your new password.',
        general_coaching_single: 'Coaching',
        general_coaching: 'My coaching',
        general_settings: 'Settings',
        general_user_data: 'User data',
        general_user_groups: 'User groups',
        general_logout: 'Logout',
        general_application: 'Application',
        general_session: 'Session',
        general_manager: 'Manager',
        general_administration: 'Administration',
        general_sleep_journal: 'Sleep Journal',
        general_license: 'License',
        general_licenses: 'licenses',
        general_licenses_overview: 'Overview of your active licenses',
        general_license_extend: 'Activate/renew license',
        general_license_buy: 'Buy license',
        general_license_management: 'license Management',
        general_license_code: 'License code',
        general_license_type_admin: 'Created by admin',
        general_license_type_trial: 'Evaluation license',
        general_license_type_provider: 'Provider license',
        general_license_type_diga: 'DiGA license',
        general_license_type_diga_unverified: 'DiGA license (unverified)',
        general_license_type_diga_rejected: 'DiGA license (rejected)',
        general_voucher: 'Voucher',
        general_vouchers: 'Vouchers',
        general_voucher_manager: 'Voucher Manager',
        general_voucher_add: 'Add voucher',
        general_voucher_find_expiring_temp_diga_licenses: 'DiGA temp lic',
        general_voucher_invoicing_summary: 'DiGA code invoicing overview',
        general_voucher_sync_with_sevdesk: 'Sync with SevDesk',
        general_voucher_name: 'Voucher description',
        general_voucher_discount_percentage: 'Voucher discount',
        general_voucher_validity_days: 'Validity (days)',
        general_voucher_validity_unlimited: 'Unlimited',
        general_voucher_provider: 'Provider of the code (optional)',
        general_voucher_coaching: 'For coaching',
        general_voucher_quantity: 'Redeemable x times',
        general_voucher_code: 'Voucher code',
        general_redeem_code: 'Redeem code',
        general_voucher_redeem_execute: 'Redeem',
        general_voucher_confirm_diga: 'Confirm DiGA voucher',
        general_voucher_reject_diga: 'Reject DiGA voucher',
        general_voucher_usages: 'Usages',
        general_upgrade: 'Upgrade',
        general_upgrade_successful:
            'Upgrade successful! <br> By clicking on "Got it" you will be automatically logged out and must log in again afterwards.',
        general_upgrade_not_successful: 'Ugrade failed',
        general_upgrade_license:
            'By redeeming this license code you will receive an upgrade to the DiGA (app on prescription) somnio. <br> <br> Would you like to transfer your existing journal data with the upgrade to somnio?',
        general_localise: 'Localise',
        general_provider_manager: 'Provider/ Customer/ Code Issuer',
        general_provider_menu: 'Providers',
        general_provider_name: 'Provider name',
        general_provider_parent: 'Parent provider',
        general_provider_add: 'Add new',
        general_provider_none: 'No provider/ unknown',
        general_continue: 'Continue',
        general_speak: 'Speak',
        general_accept_terms: 'I accept the',
        general_terms_and: 'and the',
        general_terms_abbr: 'TOS',
        general_privacy_policy_abbr: 'PP',
        general_exclusion_criteria_a: 'I have taken note of the',
        general_exclusion_criteria_b: '',
        general_back_to_login: 'Back to login',
        general_my_coaching: 'Coaching',
        general_monitor: 'Medical report',
        general_coachings: 'Coachings',
        general_select_coaching: 'Select coaching',
        general_medication: 'Medication',
        general_modules: 'Modules',
        general_steps: 'Steps',
        general_step: 'Step',
        general_add_step: 'Add step',
        general_answer: 'Answer',
        general_answers: 'Answers',
        general_name: 'Name',
        general_username: 'Your name',
        general_module: 'Module',
        general_all: 'All',
        general_none: 'None',
        general_little: 'A little',
        general_medium: 'Medium',
        general_lots: 'A lot',
        general_close: 'Close',
        general_date_days: 'days',
        general_date_week: 'week',
        general_date_weeks: 'weeks',
        general_week_weeks: 'week(s)',
        general_date_months: 'months',
        general_date_created: 'Creation date',
        general_text_copied: 'Text has been copied to the clipboard',
        general_version: 'Version',
        general_publish: 'Publish',
        general_new_version: 'New version',
        general_delete_version: 'Delete version',
        general_shortcuts: 'Shortcuts',
        general_published: 'Published',
        general_published_on: 'Published on',
        general_edit_module_meta: 'Edit module meta: $$',
        general_wait_time_hours: 'Waiting time in hours',
        general_has_license_code: 'I have a license code',
        general_activate_account: 'Activate account',
        general_sleep_coaching: 'Sleep coaching',
        general_receive_newsletter: 'I would like to receive the newsletter',
        general_newsletter: 'Newsletter',
        general_receive_mail_notifications: 'Receive notifications via email',
        general_receive_push_notifications: 'Receive push notifications',
        general_profile: 'Profile',
        general_clients: 'User groups',
        general_save_password: 'Save password',
        general_save_profile: 'Save profile',
        general_coach_settings: 'Coach',
        general_coach_talking_speed: 'Speaking rate',
        general_slow: 'Slow',
        general_fast: 'Fast',
        general_test: 'Test',
        general_about: 'About',
        general_medical_device: 'Medical device',
        general_serial_number: 'Serial number',
        general_udi_code: 'UDI Code',
        general_somnio_description:
            'somnio is a digital insomnia therapy for people that have difficulty initiating or maintaining sleep',
        general_actensio_description:
            'actensio is a digital hypertension therapy device intended to cure hypertension by lowering the blood pressure using a guideline oriented lifestyle change.',
        general_got_it: 'Got it',
        general_understood: 'Got it',
        general_authentication: '2-Factor Authorization',
        general_no_account_yet: 'No account yet?',
        general_insert_two_factor_code: 'Please enter the code we have just sent you by email',
        general_did_not_get_code: "Didn't receive a code?",
        general_send_again: 'Send again',
        general_resend_activation_link: 'Resend the activation link',
        general_activation_link_missing: 'Activation link not received?',
        general_code_sent: 'Code sent to $$',
        general_x_label: 'Label x-axis',
        general_y_label: 'Label y-axis',
        general_days_back: 'Days back',
        general_evaluation: 'Evaluation',
        general_evaluation_journal: 'Evaluation Journal',
        general_evaluation_questionnaire: 'Evaluation Questionnaire',
        general_show_subtitles: 'Show subtitles',
        general_sleep_restfulness_question: 'How restful was your sleep?',
        general_multi_select_dialog_option_default_title: 'Selection',
        general_multi_select_dialog_option_default_message: 'Please select an option',
        general_multi_select_dialog_option_confirm_selection: 'Confirm selection',
        general_additional_data: 'Additional data',
        general_to: 'to',
        general_stay_logged_in: 'Stay logged in',
        general_XRechnung_title: 'X-Rechnung',
        general_XRechnung_submit_sevdesk_insurance: 'Submit X-Rechnung to the insurance and SevDesk',
        general_XRechnung_submit_sevdesk_only: 'Submit X-Rechnung to SevDesk only',
        general_XRechnung_submit_insurance_only: 'Submit X-Rechnung to insurance only',
        general_XRechnung_submit_insurance_override: 'Override XRechnung at insurance company',
        general_XRechnung_serviceDate: 'Date of service provision',
        general_XRechnung_buyerReference: "'Leitweg-Id' (Buyer Reference)",
        general_XRechnung_insuranceName: 'Invoice recipient company',
        general_XRechnung_insuranceIk: 'Insurance IK',
        general_XRechnung_ikBilling: 'Billing IK',
        general_XRechnung_insuranceStreet: 'Street',
        general_XRechnung_insurancePostCode: 'Postal code',
        general_XRechnung_insuranceCity: 'City',
        general_XRechnung_insuranceCountryCode: 'Country code',
        general_XRechnung_invoiceNr: 'Invoice Nr',
        general_XRechnung_sent_date: 'Date sent',
        general_XRechnung_paid_date: 'Date paid',
        general_XRechnung_sevDesk_Id: 'SevDesk ID',
        general_XRechnung_already_paid: 'Already paid',
        general_payrexx_title: 'Payrexx',
        general_payrexx_sync_to_sevdesk: 'Send to SevDesk',
        general_currency: 'Currency',
        general_amount: 'Amount',
        general_payment_provider_name: 'Payment provider',
        general_payment_provider_transaction_id: 'Transaction ID',
        general_payment_provider_transaction_uuid: 'Transaction UUID',
        general_status: 'Status',
        general_extend_license_by_mail: 'Extend for email',
        general_extend_license_mail_address: 'User email',
        general_extend_license_days: 'Extend by days',
        general_extend_license_validity_date: 'New validity date (after extension)',
        general_check_voucher: 'Validate voucher',
        general_check_voucher_sum_valid: 'The checksum is valid',
        general_check_voucher_sum_invalid: 'The checksum is invalid',
        general_check_voucher_used: 'The voucher has already been used',
        general_check_voucher_unused: 'The voucher has not been used',
        general_refresh: 'Refresh',
        general_display: 'Show',
        general_seven_days: '7 days',
        general_shorten: 'Shorten',
        general_extend: 'Extend',
        general_invalid_parameter: 'Invalid parameter',
        general_certificate: 'Certificate',
        general_certificate_title: 'Certificate',
        general_certificate_expired: 'Certificate has expired',
        general_certificate_issued: 'Certificate issuance',
        general_certificate_we_confirm: 'We confirm',
        general_certificate_has_completed: 'has completed the sleep training',
        general_certificate_description: 'You can download your participation certificate here.',
        general_certificate_name: 'Name',
        general_import_medication_list: 'Import',
        general_for: 'For',
        general_default: 'Default',
        general_travel: 'Locomotion',
        general_recipes: 'Recipes',
        general_not_accepted: 'Not accepted',
        general_translation_suggestion: 'Translation suggestion',
        general_translation_suggestion_add_new: 'Suggest a new text based on this one',
        general_translation_suggestion_remove_this: 'Remove this suggestion',
        general_translation_suggestion_make_current: 'Apply this text suggestion',
        general_translation_suggestion_cannot_remove_current:
            'This is the current version and thus cannot be removed - please apply another version first',
        general_mail_sent: 'Email sent',
        general_not_connected: 'Not connected',
        general_of: 'Of',

        general_thryve_connection_management: 'Tracker connection management',
        general_thryve_connect_title: 'Connections with tracker',
        general_thryve_connect: 'Connect',
        general_thryve_connected: 'Connected with tracker',
        general_thryve_connected_success: 'Successfully connected with tracker, you may close this window',
        general_thryve_connected_failure: "Couldn't connect with tracker, please try again",
        general_thryve_disconnected_success: 'Successfully disconnected tracker, you may close this window',
        general_thryve_disconnected_failure: "Couldn't disconnect tracker, please try again",
        general_thryve_disconnect: 'Disconnect',
        notification_thryve_disconnected: 'Disconnected tracker',
        notification_thryve_select_usage: 'Please select at least one usage',
        notification_no_pw_reset_link: 'No password reset link available',
        general_thryve_connect_info: 'The connection with an activity tracker enables automatic journal completion.',
        general_thryve_explanation_somnio:
            'After connection of an activity tracker, sleep data is being synchronized automatically with the programme.',
        general_thryve_confirm_tracker_connection_somnio:
            "Precision of automatic sleep tracking varies from person to person. Therefore mementor can't guarantee that the data is correct and recommends <b>checking and adjusting the synchronized data where appropriate</b>.",
        general_thryve_hint_provider:
            '<b>Caution</b> Data processing takes place through our partner Thrye. Due to this, the provider <i>Thryve</i> or <i>mHealth Pioneers GmbH</i> will be shown in the agreement, instead of <i>mementor</i>.',
        general_thryve_hint_leaving_app:
            '<b>Caution</b> Through clicking on the connection link of the tracking provider, you will leave the app.',
        general_thryve_usage_sleep: 'Sleep',
        general_thryve_usage_activity: 'Activities',
        general_thryve_usage_blood_pressure: 'Blood press & pulse',
        general_thryve_provider: 'Provider',
        general_administration_ratings: 'Ratings',
        general_whoops: 'Whoops!',
        general_reload: 'Reload',
        general_your_answer: 'Your answer',
        general_no_data_provided_yet: 'No data provided yet',
        general_notification_general_testNotification: 'Test notification',
        general_notification_general_modules_progress: 'Next module',
        general_notification_actensio_missing_hypertension_journal_entry: 'Missing entries in the evening log',
        general_notification_actensio_missing_blood_pressure_entry: 'Missing entries in the blood pressure log',
        general_notification_actensio_missing_medication: 'Report medication intake',
        general_notification_somnio_missing_sleep_journal_entry: 'Missing sleep journal entries',
        general_notification_somnio_jr_missing_sleep_journal_entry: 'Missing sleep journal entries',
        general_notification_somnio_missing_awake_journal_entry: 'Missing evening log entries',
        general_notification_somnio_jr_missing_awake_journal_entry: 'Missing evening log entries',
        general_notification_glykio_weekly_planner_reminder: 'Next dates',
        general_notification_glykio_protocols_reminder: 'Reminder of diary entries',
        general_notification_glykio_perception_reminder: 'Perception training reminder',

        handlebars_fitbit_success_title: 'Connection success',
        handlebars_fitbit_success_message:
            'Your account has successfully been linked to Fitbit. You can close this window.',
        handlebars_mail_disable_notification_title_success: 'Unsubscription successful',
        handlebars_mail_disable_notification_message_success:
            'You will no longer receive notifications via e-mail. You can modify this setting in the profile page of your account.',
        handlebars_mail_disable_notification_title_error: 'Unsubscription failure',
        handlebars_mail_disable_notification_message_error:
            'The link to disable the notification is invalid. You can modify this setting in the profile page of your account.',
        handlebars_generali_registration_failure_title: 'Unable to create generali user',
        handlebars_generali_registration_failure_message: 'Unable to create a new account with a generali user',
        handlebars_tk_registration_failure_title: 'Unable to create TK user',
        handlebars_tk_registration_failure_message: 'Unable to create a new account with a TK user',
        handlebars_new_email_error_title: 'Confirmation has failed',
        handlebars_new_email_error_message:
            'The confirmation token for the new e-mail address is invalid or has expired',
        handlebars_new_email_success_title: 'Confirmation success',
        handlebars_new_email_success_message: 'You may now use your new e-mail address to log in.',

        history_action_create: 'creates',
        history_action_update: 'updates',
        history_action_remove: 'removes',
        history_action_anonymize: 'anonymizes',
        history_entity_user: 'user',
        history_targeted_user: 'target user',
        history_unidentified_user: 'unidentified user',

        hint_leave_blank_to_keep: 'Leave blank to keep',
        hint_identifier: 'Your name or institution',
        hint_access_code: 'Access code generated by the patient',
        hint_change_mail:
            'If you choose to change your e-mail address, a confirmation link will be sent to the new address',
        hint_change_mail_confirmation_pending: 'Confirmation of new e-mail address pending',

        isi_sleep_test: 'Sleep test',
        isi_explain_why:
            'In order for us to direct you to the correct sleep training, we require some more information from you.',
        isi_question_one: 'Please estimate to which extent your sleep has been affected in the last two weeks by ...',
        isi_question_two: 'How satisfied/dissatisfied are you with your sleep at the moment?',
        isi_question_three:
            'To which extent has your performance (e.g. ability to focus, memory) and your general condition (e.g. tiredness, mood) been affected by day through your (poor) sleep?',
        isi_question_four:
            'To which extent have other people been to able to notice that your quality of life has been impaired by your (poor) sleep?',
        isi_question_five: 'To which extent are you worried because of your (poor) sleep?',
        isi_none: 'None',
        isi_light: 'Mild',
        isi_medium: 'Moderate',
        isi_severe: 'Severe',
        isi_very_severe: 'Very severe',
        isi_very_satisfied: 'Very satisfied',
        isi_satisfied: 'Satisfied',
        isi_neutral: 'Neutral',
        isi_unsatisfied: 'Dissatisfied',
        isi_very_unsatisfied: 'Very dissatisfied',
        isi_not_at_all: 'Not at all',
        isi_a_little: 'A little',
        isi_moderate: 'Somewhat',
        isi_quite: 'Very much',
        isi_very: 'Extremely',
        isi_fall_asleep_issues: 'Difficulty falling asleep',
        isi_sleep_through_issues: 'Difficulty sleeping through',
        isi_wake_up_early: 'Waking up too early',
        isi_sleep_satisfaction: 'Sleep satisfaction',
        isi_impact_on_daily_life: 'Impact on daily life',
        isi_impact_on_quality_of_life: 'Impact on quality of life',
        isi_worries: 'Worries',

        insomnia_cycle_switchOff: 'Unable to switch off thoughts',
        insomnia_cycle_pressure: 'Putting myself under pressure',
        insomnia_cycle_consequences: 'Thoughts about consequences',
        insomnia_cycle_awake: 'The bed makes me feel awake',
        insomnia_cycle_angry: 'Anger',
        insomnia_cycle_body: 'Physical reaction',
        insomnia_cycle_exhaustion: 'Tired and exhausted',
        insomnia_cycle_performance: 'Impact on performance or ability to focus',
        insomnia_cycle_depression: 'Feeling low',
        insomnia_cycle_noSleep: 'Lying awake in bed',
        insomnia_cycle_sleepLonger: 'Staying in bed for longer',
        insomnia_cycle_catchUp: 'Catching up with sleep',

        journal_alcohol: 'Alcohol',
        journal_caffeine: 'Caffeine',
        journal_fall_asleep_duration: 'Fall asleep time',
        journal_personal_note: 'Personal note',
        journal_entry_created: 'Entry date',
        journal_mood: 'Mood',
        journal_performance: 'Performance',
        journal_energy: 'Energy',

        sleep_relaxation_title: 'Progressive muscle relaxation',
        sleep_relaxation_instruction:
            'Here you can listen to/download the progressive muscle relaxation in your own time',

        sleep_window_length: 'Sleep window length',
        sleep_window_suggestion: 'Recommendation',
        sleep_window_extend: 'Extend sleep window',
        sleep_window_shorten: 'Shorten sleep window',
        sleep_window_keep: 'Keep sleep window',
        sleep_window_no_data: 'No data available for a recommendation',
        sleep_window_info_lengthen_shorten: 'Extension/Shortening',
        sleep_window_info_process_a:
            'Depending on your average sleep efficiency in the last 7 days, Albert will recommend extending, shortening or keeping the length of your sleep window.',
        sleep_window_info_process_b:
            'You begin with the sleep window which Albert has suggested to you in the Sleeping Hours module. Your goal is to extend this sleep window step by step according to the recommendations or to shorten it again under certain conditions until you find your optimal sleeping time.',
        sleep_window_info_extension_condition: 'Recommendation for an extension if:',
        sleep_window_info_average_efficiency:
            'Your average sleep efficiency has been at least 85% over the last seven days.',
        sleep_window_info_average_efficiency_shorten:
            'Your average sleep efficiency has been below 80% over the last seven days.',
        sleep_window_info_your_efficiency: 'Your current sleep efficiency is: $$%',
        sleep_window_info_shorten_condition: 'Recommendation for a reduction if:',
        sleep_window_info_additional_conditions:
            'For a recommendation of a reduction/extension, the following conditions have to be fulfilled additionally:',
        sleep_window_info_minimum_entries: 'At least 3 sleep journal entries in the last 7 days',
        sleep_window_info_missing_entries: 'You need $$ more entries',
        sleep_window_info_your_entries: 'You completed $$ entries',
        sleep_window_info_last_extension:
            'The most recent reduction/extension of the sleep window was at least 7 days ago',
        sleep_window_info_your_last_extension: 'Your last change was $$ days ago',

        spoken_ordinal_high: 'strong connection',
        spoken_ordinal_medium: 'medium connection',
        spoken_ordinal_low: 'low connection',

        license_renewal_info_send_mail: 'Send info by e-mail',
        license_renewal_info_header: 'Your license expires in $$ days',
        license_renewal_info:
            'How to renew your license: <br> <ol><li> Contact your health insurance company and ask for a follow-up license. </li><li> You will receive a new 16-digit code. </li><li> Log in and renew your access under "License" </li></ol><br><b> You need a new prescription? </b><br> Ask your health care provider for a follow-up prescription or try the <a href="https://www.teleclinic.com/app/somnio/">Teleclinic</a> telemedicine service. <br><br> Feel free to contact our support team with any questions.',
        license_upgrade_how_to:
            '<ol><li>Talk to your doctor or psychotherapist and ask for a prescription for somnio.</li> <li>The specialist prescribes somnio (prescription contains "somnio" and the PZN 16898724).</li> <li>Submit the prescription to your health insurance company.</li> <li>The health insurance company generates an activation code for somnio and sends it to you.</li> <li>Start the training in the app and activate somnio with the code under the "License" section. Alternatively, you can use the training in the browser at <a href="https://app.somn.io">app.somn.io</a>.</li></ol> <b>Note:</b> You have the possibility to transfer your existing data to the new version of somnio without any problems when upgrading.',
        license_upgrade_how_to_header: '<b>Upgrade to the DiGA (app on prescription) somnio*</b>',
        license_upgrade_how_to_somnio:
            '*The prescription version of somnio includes advanced features and content recommended for those diagnosed with insomnia.',

        mail_notice_requires_html:
            'Please notice that in order to use the application correctly, you will need a mail program that can render html messages.',
        mail_hello: 'Hello',
        mail_hello_formal: 'Hello',
        mail_registration_activation_title: 'Welcome to $$',
        mail_registration_activation_generali_message: 'Thank you very much for your registration.',
        mail_registration_activation_link_one_coaching:
            'Almost done! Please complete your registration by clicking the following button:',
        mail_registration_activation_link_multiple_coachings_hint:
            'Almost there! You are about to unlock $$. Note that you already have an account with another digital health application we provide. <br>For your convenience and to save you from entering data twice, we are linking the two accounts. Please use the same user information (email and password) to log in.',
        mail_registration_activation_alternative_to_coach_linking:
            'If you do not agree to the linking, you can simply register with another email address.',
        mail_registration_activation_link_multiple_coachings_click: 'To do so, please click on the following button:',
        mail_registration_activation_generali_link:
            'If the activation failed in the browser, repeat the process with this link:',
        mail_registration_activate_account: 'Complete registration',
        mail_registration_waiting_room: 'We will then guide you through all the necessary steps to activate $$.',
        mail_registration_activation_goodbye: 'We hope you have a successful training!',
        mail_app_download_tip: 'You can download the somnio app here',
        mail_request_new_password_title: 'Set a new password',
        mail_request_new_password_message:
            'A new password has been requested for your account. If you did not initiate this, you can simply ignore this message. If you wish to get a new password: please click the link below.',
        mail_set_new_email_address_title: 'Confirm new e-mail address',
        general_new_email: 'New email address:',
        mail_set_new_email_address_message:
            "You have requested to set a new mail address. In order to confirm the modification, please click the link below. Please note: if you're not $$, someone else may have attempted to use your e-mail address. In this case, please ignore and delete this message.",
        mail_set_new_email_address_link: 'Confirm',
        mail_set_new_email_address_duplicate_title: 'Set new e-mail address for existing account',
        mail_set_new_email_address_duplicate_message:
            'A user has requested to use this e-mail address for registering. However, you already have an account with this address. In case you have forgotten your credentials, please click on the link below to reset them.',
        mail_password_reset_title: 'Password reset',
        mail_password_reset_message: 'Your password has been reset.',
        mail_send_new_password_title: 'New password requested',
        mail_license_expiring_title: 'Your license for $$',
        mail_license_expired_title: 'Your license for $$',
        mail_license_expired_generali_message:
            'We would like to inform you that your license for sleep training has expired. Thank you very much for participating. We hope the program has met your expectations.',
        mail_license_expiration_pipedrive_title: 'Your trial license will expire in $$ days',
        mail_license_expiration_pipedrive:
            'due to the requirements of the German Drug Advertising Act (Heilmittelwerbegesetz), unfortunately, the trial period for digital health applications is limited. We would like to remind you that your personal access is still available for $$ days. In order to get an overall impression of the app, you have the option to instantly unlock all training modules in your "Profile" under the tab "Demo Actions".',
        mail_license_expiration_pipedrive_footer: 'We hope you enjoy testing somnio. <br><br> The somnio team',
        mail_set_new_password: 'Set new password',
        mail_registered_not_logged_in_title: 'Your registration at $$',
        mail_two_factor_authentication_title: 'Two-factor authentication',
        mail_two_factor_authentication_message: 'Here is your code for the two-factor authorization',
        mail_unsubscribe_newsletter_text:
            'Would you like to stop receiving notifications by mail from $$? Please click ',
        mail_unsubscribe_newsletter_link: 'here',
        mail_module_open_not_started_title: 'Open modules in $$',
        mail_module_open_not_started_body:
            'We noticed there are open modules in $$ that you haven\'t started yet. On <a href="$$">$$</a> you can launch the open module $$.',
        mail_module_started_not_completed_title: 'Module not completed in $$',
        mail_module_started_not_completed_body:
            'We noticed that you have been working on the module $$ but didn\'t complete it yet. You can complete the module on <a href="$$">$$</a>',
        mail_inactivity_warning_title: 'We miss you!',
        mail_inactivity_warning_body:
            "We haven't seen you for a long time! Please note that the data associated with your account will be irrevocably deleted if you don't log in to the program by $$.",
        mail_news: 'News',
        mail_contact_us:
            'If you have any further questions, please feel free to contact us via <a href="mailto:support@mementor.de">support@mementor.de</a> <br><br>Your mementor team',
        mail_contact_us_formal:
            'If you have any further questions, please feel free to contact us via <a href="mailto:support@mementor.de">support@mementor.de</a> <br><br>Your mementor team',
        mail_payment_voucher_bought_title: 'Your code for somnio',
        mail_payment_voucher_bought_message:
            "Welcome to somnio, your sleep training for a good night's sleep! We've received your payment and you can get started now:",
        mail_payment_voucher_bought_step_1: 'Click <a href="$$">here</a> to create a new somnio user account',
        mail_payment_voucher_bought_step_2: 'Use the code below as the license code',
        mail_payment_voucher_bought_step_3:
            'After that, you can get going with somnio. If you have any questions during the activation process or later on in the training, please contact support on <a href="mailto:support@mementor.de">support@mementor.de</a>',
        mail_payment_voucher_bought_code: 'Your license code: ',
        mail_payment_voucher_bought_step_4:
            'Note: the execution of the contract already begins before the expiry of the withdrawal period. With this purchase, you expressly waive the 14-day right of withdrawal.',
        mail_payment_voucher_bought_footer1:
            'We hope you have a great start and most importantly, a deep and restful sleep!',
        mail_duplicate_registration_title: 'Account already exists',
        mail_duplicate_registration_message:
            'You just tried to register. An account with your email address already exists. You can log in Do you have problems signing in? You can reset your password',
        mail_duplicate_registration_message_b: '. Do you have problems signing in? You can reset your password',
        mail_duplicate_registration_message_c: '.',
        mail_demo_account: 'Test license',
        mail_demo_account_welcome:
            'We are happy to hear that you are interested in somnio. With this e-mail, you receive your personal test license and thus the opportunity to familiarise yourself with our digital sleep training.',
        mail_your_license_code: 'Your personal access code is:',
        mail_demo_account_details:
            'After redeeming the code, the application is fully accessible for 14 days. You receive a demo access in which you can unlock all modules directly and remove the displayed waiting times. This can be done in the app in your profile under the category "Demo activities".',
        mail_registration: 'Registration',
        mail_demo_account_closure:
            'We hope you enjoy the training. Please do not hesitate to contact us if you have any questions or feedback: medical@mementor.de or 0341 581 444 33. ',
        mail_flyer_confirmation: 'somnio: Confirmation of your info material order',
        mail_flyer_confirmation_details_a:
            'Thank you for your interest in our digital sleep training somnio. We will shortly deliver the info brochures for your patients to the indicated address.',
        mail_flyer_confirmation_details_b:
            'We recommend you to forward a brochure to your patients when prescribing somnio to ensure they are provided with the essential information on the program, and the next steps as well as our contact details with which they may contact us if they have any questions or problems.',
        mail_flyer_confirmation_details_c:
            'If you have not already tried the program yourself, you may request a free test account for professionals <a href="https://somn.io/doc/#test">here</a>, valid for two weeks.',
        mail_flyer_subject: 'Patients flyer somnio',
        mail_certificate_title: 'Congratulations!',
        mail_certificate_download_body:
            'You have completed the $$ and showed a lot of motivation and perseverance - you can be proud of yourself! In the profile page, you may now download your participation certificate.',
        mail_certificate_download_link: 'Get the certificate',
        mail_certificate_download_goodbye:
            'All the best for your further journey!<br><br>Best wishes, the mementor team.',
        mail_personal_counseling_body:
            'if you would like to have a personal support talk with an expert, you can book an appointment at the following link: <br><br> <a href="https://my.calenso.com/book/mementor/somnio/support-session@mementor.de">Book an appointment</a> <br><br> In the field for comments, you can briefly describe your request. A time frame of up to 10 minutes is provided for the support call on technical questions. Up to 20 minutes are allotted for the support call on content-related questions. You will then be called at the agreed time.',
        mail_personal_counseling_title: 'Your support call',
        mail_hint_noreply_address_informal:
            'This is an automatically generated message. Please do not reply to it - the email will not be read or processed.',
        mail_hint_noreply_address_formal:
            'This is an automatically generated message. Please do not reply to it - the email will not be read or processed.',
        mail_service_call_offer:
            'aller Anfang ist schwer. Daher wollten wir uns bei dir erkundigen und fragen, wie die ersten Tage mit actensio gelaufen sind? Was lief gut, was weniger? Brauchst du eventuell noch einen kleinen Motivationsschub oder ein offenes Ohr, dem du von deinen Erfahrungen berichten möchtest? <br><br> Dann kannst du gern ein 20-minütiges Supportgespräch mit einer*einem Mitarbeiter*in vereinbaren. Unter folgendem Link kannst du deinen Telefontermin buchen. <br> <br> <a href="https://my.calenso.com/book/mementor/actensio-content">Zum Kalender</a> <br><br> Wir rufen dich zum vereinbarten Zeitpunkt zurück.',
        mail_service_call_offer_subject: 'Buch dein persönliches Supportgespräch',
        mail_upgrade_to_somnio_info:
            'Since your sleep score indicates insomnia, we recommend that you get the <b> full version of somnio, available by prescription</b>. Here\'s how to obtain somnio: <ol> <li>Talk to your doctor or psychotherapist and get a prescription for somnio. </li> <li>The specialist prescribes somnio (prescription contains "somnio" and the PZN number 16898724). </li> <li>Submit the prescription to Techniker Krankenkasse (TK).  </li> <li>The health insurance company generates an activation code for somnio.</li> <li>Start the training in the app and activate somnio with the code under the heading "License". Alternatively, you can also use the training in the browser at <a href="https://app.somn.io.">https://app.somn.io</a>.  </li> </ol> <i> Note: You have the possibility to transfer your existing data to the new version of somnio without any problems.  </i> <br> <br> Now you can take the first steps with the full version of somnio to reduce the symptoms of your sleep disorder and improve your sleep with lasting effects.',
        mail_upgrade_to_somnio_info_title: 'Get somnio',
        mail_final_interview_title: 'Final discussion',
        mail_final_interview_explanation:
            'if you would like a personal final discussion with a specialist, you can book an appointment at the following link:',
        mail_final_interview_link: 'https://my.calenso.com/book/mementor/somnio-finalinterview',
        mail_final_interview_book_appointment: 'Book appointment',
        mail_final_interview_description:
            'You will then be called at the agreed time. If you like, you can already share your initial thoughts about the program with us in the "Comments" field.',
        mail_final_interview_timeframe: 'The time frame for the call is 20 minutes.',
        mail_internal_support_multiple_partner_registrations_title: 'Multiple Partner Registrations',
        mail_internal_support_multiple_partner_registrations_description:
            'a user with a partner registration tried to register another coaching.',
        mail_internal_support_multiple_partner_registrations_partner: 'The user is registered with this partner: ',
        mail_internal_support_multiple_partner_registrations_existing: 'The user has following coaching(s): ',
        mail_internal_support_multiple_partner_registrations_new: 'The user tried to register this coaching: ',

        // actensio-specific mailing messages
        mail_actensio_license_expiring_message_non_diga:
            'We would like to inform you that your license for actensio expires in $$ days. After the license expires, you will need a new license code to log in again.',
        mail_actensio_license_expiring_message_diga:
            'we would like to inform you that your license for actensio will expire in $$ days. In order to continue having access to your journals, the module content and regular data analysis after the license expires, you will need a new <b>prescription code</b>. You can get this from your doctor, therapist or telemedicine provider. You can find out more about the prescription process <a href="https://actens.io/#access">here</a>. ',
        mail_actensio_license_expired_message_non_diga:
            'We would like to inform you that your license for actensio has expired. Thank you very much for participating. We hope the program has met your expectations',
        mail_actensio_license_expired_message_diga:
            'we would like to inform you that your license for actensio has expired. In order to continue to have access to your journals, the module content as well as the regular data evaluation after your license has expired, you need a new <b>prescription code</b>. You can get this from your doctor, therapist or telemedicine provider. You can find out more about the prescription process <a href="https://actens.io/#access">here</a>.<br>We hope you were satisfied with the program and that you continue having a strong health! We\'re glad you participated. ',
        mail_actensio_registered_not_logged_in_body:
            'Welcome to actensio! Thanks for your registration. We have not seen you lately. If you have trouble logging in, please contact our support. Please be aware that if you never log in, your account will be removed due to inactivity on $$. But as soon as you log in for the first time, your account will be safe and ready to use!',
        mail_actensio_not_started_first_module_warning_title:
            "Let's go: Launch your first actensio training session now with your digital coach!",
        mail_actensio_not_started_first_module_warning_body:
            'Your <b>digital virtual coach Albert</b> is ready and waiting for you! Get started with your first actensio training module now at <a href="https://app.actens.io">https://app.actens.io</a> and boost your <b>daily well-being</b>.<br><br>If you experience problems <b>logging in</b>, please contact us at <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_actensio_journal_reminder_title: 'Actensio journal entries missed',
        mail_actensio_journal_reminder_body:
            'You have forgotten to fill in your actensio journals for the recent $$ days. Your last journal entry dates from $$. In order to successfully continue the coaching, it is essential to regularly update your data.',
        mail_actensio_journal_reminder_hint_tracker:
            "You connected a $$ tracker for your training. Unfortunately, the data hasn't synchronized automatically. This may be due to the following reasons:<ul><li>The data from your tracker ($$) couldn't be synchronized with the tracker app (please do this manually in the $$ app)</li><li>Tracker battery wasn't sufficiently charged during measurement process</li><li>The tracker has only been connected recently</li><li>The agreement to data processing in the $$ account doesn't include all of the required values, or has been retracted</li><li>Measurement may not be completed if the tracker is being worn too loosely</li><li>Older tracker models may not record all data with the required precision</li></ul><br><br>Please complete the data for the missing dates manually.",

        // somnio-specific mailing messages
        mail_somnio_license_expiring_message_non_diga:
            'We would like to inform you that your license for somnio expires in $$ days. After the license expires, you will need a new license code to log in again.',
        mail_somnio_license_expiring_message_diga:
            'we would like to inform you that your license for somnio will expire in $$ days. In order to keep having access to your sleep journal, your sleep window, the training modules and the data evaluation after the license expires, you need a new prescription code.<br> Since you already have an indication, you may apply for your follow-up license directly with your health insurance company. You should receive a new license code within a few days. It is best to contact your health insurance company directly.  <br><br> A follow-up license extends the sleep training for another 90 days. Your data is retained and available again as soon as the access is renewed. <br><br> In case your health insurer requires a new prescription, please request this from your doctor or therapist again and then submit the prescription to your health insurance company. <br><br> Alternatively, you may use the serviced of the telemedicine provider (e.g. <a style="color: #009688; text-decoration: none; font-weight: 500" href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">TeleClinic</a>)',
        mail_somnio_license_expired_message_non_diga:
            'We would like to inform you that your license for somnio has expired. Thank you very much for participating. We hope the program has met your expectations',
        mail_somnio_license_expired_message_diga:
            'we would like to inform you that your license for our sleep training has expired. In order to continue to have access to your sleep diary, your sleep window, the module content as well as the regular data evaluation after your license has expired, you need a new <b>prescription code</b>. You can get this from your doctor, therapist or telemedicine provider. You can find out more about the prescription process <a href="https://somn.io/#access">here</a>.<br>We hope you were satisfied with the program and that you continue having a good and restful sleep! We\'re glad you participated. ',
        mail_somnio_registered_not_logged_in_body:
            'Welcome to somnio! Thanks for your registration. We have not seen you lately. If you have trouble logging in, please contact our support. Please be aware that if you never log in, your account will be removed due to inactivity on $$. But as soon as you log in for the first time, your account will be safe and ready to use!',
        mail_somnio_not_started_first_module_warning_title:
            "Let's go: Launch your first training session now with your digital sleep coach!",
        mail_somnio_not_started_first_module_warning_body:
            'Your <b>digital virtual sleep coach Albert</b> is ready and waiting for you! Get started with your first training module now at <a href="https://app.somn.io">https://app.somn.io</a> for a good night\'s sleep and boost your <b>daily well-being</b>.<br><br>If you experience problems <b>logging in</b>, please contact us at <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_somnio_journal_reminder_title: 'Sleep journal entries missed',
        mail_somnio_journal_reminder_body:
            'You have forgotten to fill in your sleep journals for the recent $$ days. Your last sleep journal entry dates from $$. In order to successfully continue the coaching, it is essential to regularly update your sleep data.',
        mail_somnio_journal_reminder_hint_tracker:
            "You connected a $$ tracker for your training. Unfortunately, the data hasn't synchronized automatically. This may be due to the following reasons:<ul><li>The data from your tracker ($$) couldn't be synchronized with the tracker app (please do this manually in the $$ app)</li><li>Tracker battery wasn't sufficiently charged during measurement process</li><li>The tracker has only been connected recently</li><li>The agreement to data processing in the $$ account doesn't include all of the required values, or has been retracted</li><li>Measurement may not be completed if the tracker is being worn too loosely</li><li>Older tracker models may not record all data with the required precision</li></ul><br><br>Please complete the data for the missing dates manually.",

        mail_waiting_room_reminder_subject_1: 'Almost there! Just a few more steps and you can start with somnio!',
        mail_waiting_room_reminder_part_1:
            "Congratulations! You've decided to tackle your insomnia or sleep disorder and want to get on the path to healthy and restful sleep. The good news is that you are only a few steps away!",
        mail_waiting_room_reminder_part_2:
            '<b>And this is how you get to access somnio:</b> <br> <ol><li>You\'ve downloaded somnio from the app store and have already successfully registered. &#10004;</li><li>To finally start using somnio, you still need a prescription for somnio from a doctor or psychotherapist. Make an appointment with the specialist of your choice (doctor, psychotherapist), or with a telemedicine provider (e. g <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> or <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>), talk about your sleep disorders and tell them about somnio. On our website <a href="https://www.somn.io/en">somn.io</a> you will find comprehensive information material, e. g. an <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">information card</a> for medical professionals.</li>',
        mail_waiting_room_reminder_part_3: '<b>You have already received a prescription for somnio?</b>',
        mail_waiting_room_reminder_part_4:
            '<ol start="3"><li>Great, then you can submit it to your health insurance company within 30 days after it was issued. Most of them offer this conveniently via the app or the respective homepage. All public and most private health insurance companies cover the costs for somnio - <b> for you, somnio is free! </b></li> <li>After you have submitted the prescription, your health insurance company will send you the <b>16-digit activation code </b> for somnio. Then all you have to do is enter it and get started! </li></ol>',
        mail_waiting_room_reminder_part_5:
            '<b>Remember:</b> If you have not logged into the program by $$ the data associated with your account will be irrevocably deleted.<br> <b>Good sleep can be learned.</b>',
        mail_waiting_room_reminder_subject_2:
            'Finally sleep through the night? Just a few more steps and you can start with somnio!',
        mail_waiting_room_reminder_part_1_2:
            "Congratulations! A few days ago you registered with somnio because you finally want to sleep through the night again. But you haven't started somnio yet. But you are only a few steps away from making your dream of a good night's sleep come true!",
        mail_waiting_room_reminder_part_2_2:
            '<b>Here again as a reminder - your quick way to somnio:</b> <br> <ol><li>You have successfully registered in the somnio app or the somnio browser application. &#10004;</li><li>Make an appointment for the prescription of somnio at the specialist of your choice (doctor, psychotherapist) or book an online appointment with a telemedicine provider (e.g <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> or <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>),within a few hours. Talk about your sleep disorders and tell the specialist about somnio. On our website <a href="https://www.somn.io/en">somn.io</a>   you will find comprehensive information material, e. g. an <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">information card</a> for medical professionals.</li>',
        mail_waiting_room_reminder_subject_3: 'How important is healthy sleep to you? ',
        mail_waiting_room_reminder_part_1_3:
            "Hello! A few weeks ago you registered with somnio because you finally want to sleep through the night again. But so far you still haven't started somnio. <br> We're wondering: have you encountered any problems so far, or are you still unclear about the somnio prescription process?",
        mail_waiting_room_reminder_part_2_3:
            '<b>Here is your quick way to somnio again:</b> <br> <ol><li>You have successfully registered in the somnio app or the somnio browser application. &#10004;</li><li>Make an appointment for the prescription of somnio at the specialist of your choice (doctor, psychotherapist) or book an online appointment with a telemedicine provider (e.g. <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> or <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>),within a few hours. </li>',
        mail_waiting_room_reminder_part_4_3:
            '<ol start="3"><li>Great, then you can submit your prescription to your health insurance company within 30 days after it was issued. Most of them offer this conveniently via the app or the respective homepage.</li> <li>Your health insurer will then send you the <b>16-digit activation code</b> for somnio. <br> Then it\'s just a matter of entering it and getting started! </li></ol> ',

        meditation_exercise_title: 'Focused breathing',
        meditation_exercise_instruction:
            'During this exercise, focus for a few minutes on how your breath flows in and out in rhythm with the animation.',
        meditation_exercise_completed: 'Exercise completed',
        meditation_exercise_completed_write_to_journal:
            'Meditation completed. Transfer time ($$ minutes) to your journal?',
        meditation_exercise_write_to_journal: 'Transfer',
        meditation_exercise_start: 'Start',
        meditation_exercise_end: 'Quit',

        lottie_no_insomnia: 'No insomnia',
        lottie_light_insomnia: 'Light insomnia',
        lottie_moderate_insomnia: 'Moderate insomnia',
        lottie_severe_insomnia: 'Severe insomnia',
        lottie_week: 'Week',
        lottie_overall: 'Overall',

        licensing_title: 'licenses',
        licensing_no_license_found: 'No license found',
        licensing_credit_debit_card: 'Credit or debit card',
        licensing_credit_debit_card_submit: 'Execute payment',
        licensing_valid_until: 'Valid until',
        licensing_trial: 'Evaluation license',
        licensing_module_not_licenced: 'No valid license found for this module',
        licensing_voucher_creation: 'Voucher creation',
        licensing_user_registration: 'User registration',
        licensing_coaching_activation: 'Coaching activation',

        notification_coaching_saved: 'Coaching saved',
        notification_coaching_saved_error: 'Coaching could not be saved',
        notification_coaching_removed: 'Coaching removed',
        notification_coaching_removed_error: 'Coaching could not be removed',
        notification_coaching_published: 'Coaching published',
        notification_coaching_published_error: 'Coaching could not be published',
        notification_version_created: 'New version created',
        notification_version_created_error: 'New version could not be created',
        notification_coaching_version_updated: 'Successfully updated to latest version',
        notification_coaching_version_updated_error: 'Could not update to latest version',
        notification_socket_disconnect: 'Disconnected from server',
        notification_socket_reconnect: 'Connection recovered',
        notification_session_expired: 'Session expired, please log in',
        notification_step_add: "Step '$$' added",
        notification_step_remove: "Step '$$' removed",
        notification_step_remove_count: 'Removed $$ steps',
        notification_step_update: "Step '$$' saved",
        notification_step_save_error: 'Step could not be saved',
        notification_journal_entry_saved: 'Entry saved',
        notification_journal_entry_removed: 'Entry removed',
        notification_journal_entry_removed_error: 'Entry could not be removed',
        notification_text_localised: 'Text localized',
        notification_animation_saved: 'Animation "$$" saved',
        notification_animation_saved_error: 'Could not save animation',
        notification_animation_removed: 'Animation "$$" removed',
        notification_medication_saved: 'Medication "$$" saved',
        notification_medication_saved_error: 'Could not save medication',
        notification_medication_removed: 'Medication "$$" removed',
        notification_module_saved: 'Module saved',
        notification_module_saved_error: 'Module could not be saved',
        notification_module_removed: 'Module removed',
        notification_module_removed_error: 'Module could not be removed',
        notification_module_published: 'Module has been published',
        notification_module_published_error: 'Could not publish module',
        notification_answer_container_saved: 'Answer container "$$" saved',
        notification_answer_container_removed: 'Answer container "$$" removed',
        notification_user_saved: 'User "$$" saved',
        notification_user_saved_error: 'User "$$" could not be saved',
        notification_user_removed: 'User "$$" removed',
        notification_user_removed_error: 'User "$$" could not be removed',
        notification_user_anonymized: 'User "$$" anonymized',
        notification_user_anonymized_error: 'Unable to anonymize user "$$"',
        notification_profile_saved: 'Profile saved',
        notification_profile_saved_error: 'Error saving profile',
        notification_profile_email_updated: 'A confirmation link has been sent to the new address',
        notification_cannot_edit_published: 'Published modules cannot be edited',
        notification_module_locked: 'The module "$$" has been locked by $$',
        notification_module_unlocked: 'The module "$$" has been unlocked by $$',
        notification_provider_saved: 'Provider "$$" saved',
        notification_provider_saved_error: 'Provider "$$" could not be saved',
        notification_provider_removed: 'Provider "$$" removed',
        notification_provider_removed_error: 'Provider "$$" could not be removed',
        notification_fitbit_connected: 'Connected to Fitbit',
        notification_fitbit_disconnected: 'Disconnected from Fitbit',
        notification_copy_clipboard: 'Copied to clipboard',
        notification_generic_success: 'Operation successful',
        notification_generic_error: 'Operation failed with an error',
        notification_authorization_get_error: 'Error loading access codes/links',
        notification_authorization_saved: 'Access saved',
        notification_authorization_saved_license_limit_reached: 'Access saved. Valid until date of license expiration',
        notification_authorization_saved_error: 'Error saving access',
        notification_authorization_removed: 'Access removed',
        notification_authorization_removed_error: 'Error removing access',
        notification_idle_logout: 'Automatic logout due to inactivity',
        notification_idle_logout_warning: 'Inactivity detected, logout in $$ minutes',
        notification_password_saved: 'Password saved',
        notification_password_saved_error: 'Error saving the password',
        notification_system_notification_saved: 'System notification "$$" saved',
        notification_system_notification_removed: 'System notification removed',
        notification_sleep_window_saved: 'Sleep window saved',
        notification_sleep_window_saved_error: 'Error saving sleep window',
        notification_code_redeemed: 'Voucher redeemed',
        notification_voucher_saved: 'Voucher successfully saved',
        notification_voucher_saved_error: 'Unable to save voucher',
        notification_vouchers_export_error: 'Error while exporting vouchers',
        notification_vouchers_manual_processing_started:
            'Manually treating vouchers, this could take some time, please be patient...',
        notification_vouchers_manual_processing_success: 'Successfully treated $$ vouchers manually',
        notification_vouchers_manual_processing_error: 'Error while treating vouchers manually',
        notification_sync_vouchers_success: 'Vouchers successfully synchronised',
        notification_sync_vouchers_error: 'Error while synchronising vouchers',
        notification_voucher_generic_error: 'Error redeeming the code',
        notification_diga_code_check_download_success: 'Verification report created with success',
        notification_diga_code_check_download_error: 'Could not create verification report',
        notification_xRechnung_send_success: 'X-Rechnung successfully transmitted',
        notification_xRechnung_send_error: 'Error sending the X-Rechnung',
        notification_voucher_removed: 'Voucher has been removed',
        notification_voucher_removed_error: 'Voucher could not be removed',
        notification_secure_logout: 'Safely logged out',
        notification_profile_export_error: 'Unable to download profile',
        notification_role_added: 'Rolle hinzugefügt',
        notification_role_added_error: 'Rolle konnte nicht hinzugefügt werden',
        notification_role_removed: 'Rolle entfernt',
        notification_role_removed_error: 'Rolle konnte nicht entfernt werden',
        notification_all_modules_unlocked: 'All modules unlocked',
        notification_all_modules_unlocked_error: 'Modules could not be unlocked',
        notification_wait_times_removed: 'All waiting times removed',
        notification_wait_times_removed_error: 'Waiting times could not be removed',
        notification_license_saved: 'license saved',
        notification_license_saved_error: 'license could not be saved',
        notification_demo_entries_generated: 'Demo data has been generated',
        notification_demo_entries_generated_error: 'Demo data could not be generated',
        notification_sleep_journal_visual_analysis_success:
            'Successfully created visual evaluation of sleep journal entries',
        notification_sleep_journal_visual_analysis_error: 'Unable to create visual evaluation of sleep journal entries',
        notification_sleep_journal_excel_export_success: 'Successfully downloaded sleep journal entries',
        notification_sleep_journal_excel_export_failure: 'Unable to export sleep journal entries',
        notification_resent_activation_email: 'Activation email has been resent',
        notification_resent_activation_email_error: 'Could not resent activation email',
        notification_contact_support_error: 'Message could not be sent',
        notification_journal_summary_error: 'Summary could not be loaded',
        notification_sleep_window_min_length: 'Minimal window length: $$ hours',
        notification_sleep_window_max_length: 'Maximal window length: $$ hours',
        notification_goals_saved: 'Your goals have been saved',
        notification_payrexx_sync_success: 'Synchronisation success',
        notification_payrexx_sync_failure: 'Synchronisation failure',
        notification_medication_plan_saved: 'Medication plan saved',
        notification_medication_plan_updated: 'Medication plan updated',
        notification_medication_plan_saved_error: 'Error saving medication plan',
        notification_medication_plan_removed: 'Medication plan deleted',
        notification_questionnaire_saved_success: 'Questionnaire saved',
        notification_questionnaire_saved_error: 'Unable to save questionnaire',
        notification_questionnaire_removed_success: 'Questionnaire successfully removed',
        notification_questionnaire_removed_error: "Questionnaire can't be removed",
        notification_journal_update_meditation: 'Duration has been saved to your journal',
        notification_web_authn_connected_error: 'Login without password could not be set up',
        notification_web_authn_login_error: 'Login without password failed',
        notification_device_deregistration_error: 'Could not remove device',
        notification_credential_rename_error: 'Could not rename device',
        notification_cannot_edit_step: 'Moule locked, cannot edit step right now',
        notification_exercise_saved: 'Exercise successfully saved',
        notification_exercise_save_error: 'Error while saving exercise',
        notification_operation_not_supported: 'Operation currently not supported',
        notification_userNotFound: 'User not found',
        notification_health_id_login_success: 'Successful authorized with HealthId',
        notification_health_id_login_error: 'Error while authorising with Health Id',

        speech_no_data: ' - there is no data.',

        // sbk
        sbk_fields_salutation: 'Salutation',
        sbk_fields_title: 'Title',
        sbk_fields_first_name: 'First name',
        sbk_fields_last_name: 'Surname',
        sbk_fields_policy_number: 'Policy number',
        sbk_fields_date_of_birth: 'Date of birth',
        sbk_fields_insurance: 'Insurance',
        sbk_fields_phone: 'Phone number',
        sbk_fields_accept_ad_approval:
            'I agree that SBK may contact me in order to gather user feedback on this campaign by phone or e-mail and process my data for this purpose',
        sbk_fields_terms: 'I agree to the terms & conditions of SBK',
        sbk_fields_data_terms: 'Privacy policy',
        sbk_fields_participation_terms: 'Use criteria',
        sbk_fields_impressum: 'Impressum',
        sbk_save_personal_data: 'Save user data',
        sbk_load_personal_data_error: 'Could not load user data',
        sbk_must_confirm_new_campaign:
            'Please agree to the terms & conditions of Schlafgut in order to activate your account.',
        sbk_must_click_confirmation_link:
            "You've successfully registered for Schlafgut. Please click on the link sent to the e-mail address you provided for confirmation.",
        sbk_must_set_password: 'Please set a password to activate your account.',
        sbk_activation_succeeded_login_now: 'Your account has been activated successfully. You may log in now.',
        sbk_report_to_upload_triggered: 'The report will be generated and forwarded to SBK',
        sbk_can_login_now: 'You can log in now',
        sbk_register_with_existing_credentials: 'Please register and use the credentials of your existing account',
        sbk_register_using_link: 'Please register using the link to the campaign',
        sbk_or_login: 'Participate with existing registration data from a previous SBK health promotion campaign.',
        sbk_app_name: 'Sleep well!',
        sbk_app_lead: 'Start the day with more energy',
        sbk_claim: 'We are on <br> your side',
        sbk_campaign_has_expired:
            'The campaign you have registered for is deactivated. Please register for a new campaign using your existing login details via a registration link you will receive from SBK',
        sbk_recommend: 'Recommend SBK',
        sbk_recommend_detail:
            'We are pleased that you are taking part in "Sleep well! Let your friends, acquaintances or colleagues benefit from it and recommend us to others! We will thank you with attractive rewards!',
        sbk_recommend_link: 'Simply click here!',
        sbk_fields_terms_1: 'Yes, I have read the ',
        sbk_fields_terms_2: ' and the ',
        sbk_fields_terms_3:
            'for the SBK campaign "Sleep well" and agree to the processing of personal data specified therein.',
        sbk_fields_invalid_list: 'The following form fields are invalid:',

        tip_add_answer: 'Add answer',
        tip_remove_answer: 'Remove answer',
        tip_drag_to_reorder: 'Drag to reorder',
        tip_edit: 'Edit',
        tip_details: 'Details',
        tip_next_week: 'Next week',
        tip_previous_week: 'Previous week',
        tip_user_settings: 'Manage your account',
        tip_replay: 'Play again',
        tip_back: 'Replay this step',
        tip_select_animation: 'Select animation',
        tip_add_condition: 'Add condition',
        tip_remove_condition: 'Remove condition',
        tip_save_in_answer_container: 'Container into which the answer is saved',
        tip_answer_key: 'Name of the key under which the response is stored',
        tip_answer_type: 'Expected format of the response',
        tip_answer_list_items: 'List of allowed responses',
        tip_next_step: 'Next step if condition matches',
        tip_localised_answer_text: 'Localized answer text',
        tip_answer_value: 'Value to save upon selection of this answer',
        tip_copy_to_clipboard: 'Copy to clipboard',
        tip_pause: 'Pause',
        tip_play: 'Play',
        tip_skip: 'Skip',
        tip_one_step_back: 'Back to previous step',
        tip_duplicate: 'Duplicate',
        tip_required_entries_details: 'Number of required journal entries to unlock the next module',
        tip_clear_animation: 'Clear animation',
        tip_performance_since_last_sleep_phase: 'Performance since last sleep phase',
        tip_journal_entry_date: 'The date should match the the day you got up',
        tip_journal_mood: 'Mood since last sleep phase',

        tip_questionnaire_name: "The questionnaire key can only be set once and can't be changed afterwards",

        validation_input_query: 'Is your entry correct?',
        validation_input_query_coaching: 'Is your entry correct?',
        validation_required: 'Required',
        validation_missing_value: 'Input missing',
        validation_valid_email: 'Valid e-mail address',
        validation_invalid_email: 'Invalid e-mail address',
        validation_valid_password: 'Valid password',
        validation_password_too_short: 'Password too short',
        validation_password_too_long: 'Password too long (max. 30 characters)',
        validation_min_length: 'Minimum of $$ characters',
        validation_max_length: 'Maximum of $$ characters',
        validation_license_error_general: 'Error',
        validation_time_invalid: 'Time invalid',
        validation_date_invalid: 'Date invalid',
        validation_repeated_password_mismatch: "Passwords don't match",
        validation_must_accept_terms: 'Please accept the T&C and the PP',
        validation_must_accept_pp: 'Please accept the PP',
        validation_required_to_access: 'Requirements for using the program',
        validation_password_wrong: 'Your old password was incorrect',
        validation_invalid_expression: 'Invalid expression',
        validation_one_answer_required: 'At least one answer is required',
        validation_invalid_license_code: 'Invalid license code',
        validation_license_code: 'license code',
        validation_diga_code: 'DiGA code',
        validation_min_chars_criteria: 'Contains at least 8 characters',
        validation_special_char_criteria: 'Contains a special character',
        validation_upper_case_criteria: 'Contains an uppercase character',
        validation_date_in_future: 'Date cannot be in the future',
        validation_too_short: 'Entry too short',
        validation_no_match_email: 'E-mail address does not match',
        validation_min: 'Minimum $$',
        validation_max: 'Maximum $$',
        validation_negative_number: 'No negative numbers',

        welcome_title: 'Welcome!',
        welcome_next_steps: 'Next steps',
        welcome_skip_modules: 'Skip modules',
        welcome_text_generic: 'We look forward to accompanying you on your journey.',
        welcome_text_sleep: 'We look forward to accompanying you on the journey to better sleep.',
        welcome_text_b: 'Simply click on the first module in the overview to start the training.',
        welcome_text_c:
            'This is a <b>demo account</b>. In the profile you can unlock all modules and trigger further actions.',
        welcome_text_d:
            'Modules that you have already completed in the last training can be skipped with a single click after starting the module.',

        // actensio notification keys
        notification_hypertension_journal_excel_export_success: 'Download der Hypertonie-Tagebucheinträge',
        notification_hypertension_journal_excel_export_failure: 'Fehler beim Export der Hypertonie-Tagebucheinträge',
        // actensio export keys
        general_data_export_hypertension_journals_xlsx_sheet_user_mail: 'Benutzer',
        general_data_export_hypertension_journals_xlsx_title: 'Export von Hypertonie-Tagebucheinträgen',
        general_data_export_hypertension_journals_xlsx_details:
            'Die Einträge, die du im Hypertonie-Tagebuch erfasst hast, sind hier verfügbar im Excel-Format.',
        general_data_export_hypertension_journals_xlsx_sheet_title: 'Hypertonie-Tagebucheinträge',
        general_data_export_hypertension_journals_xlsx_sheet_date_created: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_nr: 'Eintrag Nr.',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_date: 'Messzeitpunkt',
        general_data_export_hypertension_journals_xlsx_sheet_header_created_at: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_fruits: 'Obst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_vegetables: 'Gemüse',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_whole_grain: 'Vollkorn',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_low_fat_milk: 'Fettarme Milchprodukte',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_good_fats:
            'Nüsse, Hülsenfrüchte & Ölsaaten',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_salt: 'Salz',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_red_meat: 'Fettiges/rotes Fleisch',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_sugar: 'Stark gezuckerte Lebensmittel',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_dash_score: 'DASH-Score',
        general_data_export_hypertension_journals_xlsx_sheet_header_from_tracker: 'Von Fitnesstracker geladen',

        general_data_export_hypertension_journals_xlsx_sheet_header_medication: 'Medikament',
        general_data_export_hypertension_journals_xlsx_sheet_header_medication_dosage: 'Dosierung',

        general_data_export_hypertension_journals_xlsx_sheet_header_activity_low_intensity: 'Geringe Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_medium_intensity: 'Mittlere Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_high_intensity: 'Hohe Intensität',

        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_mindfulness: 'Achtsamkeit',
        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_other_meditation:
            'Andere Entspannungstechnik',

        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_alcohol: 'Alkoholische Getränke',
        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_cigarettes: 'Zigaretten',

        general_data_export_hypertension_journals_xlsx_blood_pressure_protocol_title: 'Export BlutdruckProtokoll',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_source: 'Quelle',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_location: 'Ort der Messung',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_has_complaints: 'Beschwerden',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_updated_time: 'Datum aktualisiert',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_systolic: 'Systolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_diastolic: 'Diastolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_pulse: 'Puls',

        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_paralysis: 'Lähmung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_speechImpediment: 'Sprachstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_visualImpairment: 'Sehstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_headache: 'Kopfschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_chestPain: 'Brustschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_breathlessness: 'Atemnot',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_dizziness: 'Schwindel',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_tinnitus: 'Tinnitus',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_noseBleed: 'Nasenbluten',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_home: 'Zuhause',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_doctor: 'Arztpraxis',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_pharmacy: 'Apotheke',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_manual: 'Manuell',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_thryve: 'Von Thryve',

        high_systolic_blood_pressure_warning:
            'Du hast einen systolischen <b>Blutdruck über 180</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br> Ist dein Blutdruck bei <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_diastolic_blood_pressure_warning:
            'Du hast einen diastolischen  <b>Blutdruck über 120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_systolic_and_diastolic_blood_pressure_warning:
            'Du hast einen <b>Blutdruck über 180/120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den  <b>ärztlichen Notdienst</b>.',
        low_blood_pressure_warning:
            'Deine Blutdruckwerte sind ungewöhnlich <b>niedrig</b>. <br> Bitte halte mit deinem behandelnden Arzt oder deiner behandelnden Ärztin Rücksprache über diese Werte',

        // entry warning message bits
        input_message_with_value:
            "You just documented a '$$' that is longer than 12 hours. <br> <br> Are you sure that's correct? Please note that the time input is in 24 hour format.",
        journal_fall_asleep_duration_warning: 'Fall asleep time',
        journal_sleep_time_warning: 'Sleep time',
        journal_time_in_bed_warning: 'Time in bed',
        journal_entry_warning_and: 'and',
    },
    de_ch: {
        blot_function: 'Function',
        blot_value: 'Value',
        blot_speech: 'Speech',
        blot_animation: 'Animation',
        blot_coach: 'Coach',
        blot_expressionValue: 'Expression value',
        blot_bracket: 'Bracket',
        blot_comparator: 'Comparator',
        blot_logic: 'Logic',
        blot_evaluation: 'Evaluation',
        blot_menu: 'Menu',
        blot_custom: 'Custom',

        breathing_inhale: 'Einatmen',
        breathing_exhale: 'Ausatmen',

        body_scan_title: 'Body Scan',
        body_scan_instruction: ' Hier kannst du dir den Body Scan in aller Ruhe anhören und herunterladen',

        concat_fallAsleepFaster: 'Schneller einschlafen',
        concat_betterSleepThrough: 'Besser durchschlafen',
        concat_laterWakeUp: 'Später aufwachen',
        concat_sleepLonger: 'Mehr schlafen',
        concat_betterSleepQuality: ' Bessere Schlafqualität',
        concat_moreEnergy: 'Mehr Energie',

        evaluation_completed_content: 'Abgeschlossene Programminhalte',
        evaluation_connected_to: 'Verbunden mit',
        evaluation_energy_during_day: 'Energie während des Tages',
        evaluation_energy_during_day_legend: 'Energie während des Tages (0 = wenig, 10 = viel)',
        evaluation_fall_asleep_time: 'Einschlafdauer', // Einschlafdauer // Endormi(e) après
        evaluation_fitness_tracker: 'Fitnesstracker',
        evaluation_insomnia_type: 'Art der Insomnie',
        evaluation_insomnia_type_A: 'Typ A',
        evaluation_insomnia_type_A_info: 'Vordergründig Einschlafprobleme',
        evaluation_insomnia_type_B: 'Typ B',
        evaluation_insomnia_type_B_info: 'Vordergründig Durchschlafprobleme',
        evaluation_insomnia_type_C: 'Typ C',
        evaluation_insomnia_type_C_info: 'Vordergründig zu frühes Erwachen',
        evaluation_insomnia_type_mixedAB: 'Gemischt (AB)',
        evaluation_insomnia_type_mixedABC: 'Gemischt (ABC)',
        evaluation_insomnia_type_mixedABC_info: 'Vordergründig Einschlafprobleme, Durchschlafprobleme und Früherwachen',
        evaluation_insomnia_type_mixedAB_info: 'Vordergründig Einschlaf- und Durchschlafprobleme',
        evaluation_insomnia_type_mixedAC: 'Gemischt (AC)',
        evaluation_insomnia_type_mixedAC_info: 'Vordergründig Einschlafprobleme und Früherwachen',
        evaluation_insomnia_type_mixedBC: 'Gemischt (BC)',
        evaluation_insomnia_type_mixedBC_info: 'Vordergründig Durchschlafprobleme und Früherwachen',
        evaluation_insomnia_type_tooltip: 'Art der Schlafprobleme',
        evaluation_isi_end: 'ISI-Ende',
        evaluation_isi_end_tooltip: "Schlaf-Score im Modul 'Abschluss'",
        evaluation_isi_follow_up: 'ISI-Follow-up',
        evaluation_isi_follow_up_tooltip: "Schlaf-Score im Modul 'Nachsorge'",
        evaluation_isi_score_high: 'Hinweis auf klinisch bedeutsame Insomnie (starke Ausprägung)',
        evaluation_isi_score_light: 'Hinweis auf unterschwellig klinische Insomnie (leichte Ausprägung)',
        evaluation_isi_score_low: 'Kein Hinweis auf klinisch bedeutsame Insomnie',
        evaluation_isi_score_moderate: 'Hinweis auf klinisch bedeutsame Insomnie (moderate Ausprägung)',
        evaluation_isi_start: 'ISI-Start',
        evaluation_isi_start_tooltip: "Schlaf-Score im Modul 'Einführung'",
        evaluation_number_of_hours: 'Anzahl Stunden',
        evaluation_number_of_minutes: 'Anzahl Minuten',
        evaluation_number_of_modules: 'Anzahl an Modulen',
        evaluation_relevant_params: 'Relevante Parameter',
        evaluation_sleep_efficiency: 'Schlafeffizienz',
        evaluation_sleep_efficiency_explain_text:
            'Die Schlafeffizienz ist das Verhältnis der Schlafdauer zur Zeit im Bett',
        evaluation_sleep_journal_entries: 'Schlaftagebucheinträge',
        evaluation_blood_sugar_journal_entries: 'Blutzuckereinträge',
        evaluation_sleep_journal_entries_split: 'Schlaftagebuch-Einträge',
        evaluation_sleep_type: 'Schlaftyp',
        evaluation_sleep_type_definitiveLark: 'Lerche',
        evaluation_sleep_type_definitiveLark_info: 'Morgentyp (Tageshoch am Morgen)',
        evaluation_sleep_type_definitiveNightOwl: 'Definitive Eule',
        evaluation_sleep_type_definitiveNightOwl_info: 'Abendtyp (Tageshoch am Abend)',
        evaluation_sleep_type_moderateLark: 'Moderate Lerche',
        evaluation_sleep_type_moderateLark_info: 'Moderater Morgentyp (Tageshoch am Vormittag)',
        evaluation_sleep_type_moderateNightOwl: 'Moderate Eule',
        evaluation_sleep_type_moderateNightOwl_info: 'Moderater Abendtyp (Tageshoch am späteren Nachmittag oder Abend)',
        evaluation_sleep_type_neutralType: 'Neutral',
        evaluation_sleep_type_neutralType_info: 'Weder Abend- noch Morgentyp',
        evaluation_sleep_type_tooltip: 'Art des Chronotyps',
        evaluation_start_date: 'Startdatum',
        evaluation_subjective_sleep_quality: 'Subjektive Schlafqualität',
        evaluation_sleep_time: 'Schlafdauer',
        evaluation_time_in_bed: 'Zeit im Bett',
        evaluation_time_in_bed_and_sleep_time: 'Zeit im Bett und Schlafdauer',
        evaluation_unknown_device: 'Unbekanntes Gerät',
        evaluation_wake_time_during_night: 'Wachzeit während der Nacht', // Durée d'éveil?
        evaluation_wake_time_in_bed_after_wake_up: 'Wachzeit im Bett nach dem Aufwachen',
        evaluation_average_sleep_efficiency_of_last_seven_days:
            'Deine aktuelle Schlafeffizienz (Durchschnitt der letzten sieben Tage) beträgt',
        evaluation_sleep_rhythm: 'Schlafrhythmus',

        error_code_429: 'Zu viele Versuche, bitte kurz warten und nochmal versuchen',
        error_code_500: 'Unbekannter Server fehler',
        error_code_1001: 'Operation nicht erlaubt',
        error_code_3000: 'Verbindung konnte nicht hergestellt werden',
        error_code_9001: 'Ungültige E-Mail-Adresse oder Passwort',
        error_code_9002: 'Benutzer nicht gefunden',
        error_code_9003: 'Fehler beim Einloggen nach externer Authentifizierung',
        error_code_9004: 'Authentifizierung mit externem Anbieter fehlgeschlagen',
        error_code_9005: 'Zustimmung zur Datenverarbeitung fehlt. Bitte Support kontaktieren',
        error_code_9006: 'Diese E-Mail wird bereits verwendet',
        error_code_9007:
            'Dieses Konto wurde noch nicht aktiviert. Bitte klicke auf den Link, der Dir nach der Registrierung zugesandt wurde',
        error_code_9008: 'Das eingegebene alte Passwort ist nicht korrekt',
        error_code_9009: 'Ungültiger Aktivierungslink oder Konto bereits aktiviert',
        error_code_9010: 'Benutzerdaten konnten nicht aktualisiert werden',
        error_code_9011: 'Fehler bei der Aktivierung - bitte versuche es erneut',
        error_code_9012:
            'Bitte melde dich nicht über Google an. Nutze die Login-Methode, mit welcher du dich ursprünglich registriert hast',
        error_code_9013: 'Reset-Token ungültig oder abgelaufen',
        error_code_9015: 'Zweiter Faktor nötig',
        error_code_9016: 'Ungültiger Code, bitte wiederhole die Anmeldung',
        error_code_9022: 'Ungültiger Code',
        error_code_9023: 'Lizenzcode wurde schon benutzt',
        error_code_9024: 'Code berechtigt nicht zu einer Lizenz',
        error_code_9025: 'Du hast einen Rabattcode eingegeben',
        error_code_9026: 'Lizenz konnte nicht erstellt werden',
        error_code_9027: 'Eine Lizenz desselben Typs existiert bereits',
        error_code_9029: 'Ein Schlaftraining mit diesem Schlüssel existiert bereits',
        error_code_9039: 'Für dieses Datum besteht bereits ein Eintrag',
        error_code_9040: 'Ein Voucher kann nicht mehrmals für dasselbe Konto eingelöst werden',
        error_code_9042: 'Ihre Anfrage wurde abgeleht',
        error_code_9043: 'Der Benutzer ist derzeit nicht erreichbar',
        error_code_9047: 'Ungültiger Lizenzcode',
        error_code_9048: 'Rabattcode statt Lizenzcode',
        error_code_9049: 'Lizenzcode aufgebraucht',
        error_code_9051: 'Voucher wird bereits verwendet',
        error_code_9053: 'Sie sind nicht berechtigt, diese Datei herunterzuladen',
        error_code_9054: 'Kein Lizenzcode',
        error_code_9055: 'Ungültiger Rezeptcode. Bitte nehmen Sie Kontakt mit uns auf: support@mementor.de',
        error_code_9056: 'Rezeptcode bereits eingelöst',
        error_code_9057: 'Lizenzcode noch nicht gültig',
        error_code_9058: 'Lizenzcode abgelaufen',
        error_code_9059: 'Ungültiges Objekt',
        error_code_9060: 'Rezeptcode konnte nicht verifiziert werden',
        error_code_9061: 'Rezeptcode X-Rechnung konnte nicht versendet werden',
        error_code_9062: 'DiGA-Endpoint Kommunikationsfehler',
        error_code_9063: 'X-Rechnung wurde bereits verschickt und/ oder bezahlt',
        error_code_9064: 'X-Rechnung wurde bereits in SevDesk erfasst',
        error_code_9066:
            'Entschuldige, es sind nicht genügend Daten vorhanden - bitte Einführungsmodul erneut durchführen',
        error_code_9069: 'Coaching nicht gefunden',
        error_code_9070: 'Konto für aktuelle Anwendung nicht gültig',
        error_code_9071: 'Rezept- oder Lizenzcode ist nicht für die aktuelle Anwendung bestimmt',
        error_code_9080: 'Medikament konnte nicht gefunden werden',
        error_code_9081:
            'Schlaffenster konnte nicht gespeichert werden - bitte Seite neu laden oder App neu starten und erneut versuchen',
        error_code_9082: 'Bitte Internetverbindung prüfen und nochmals versuchen',
        error_code_9083: 'Unvollständige Daten: grafische Auswertung nicht möglich',
        error_code_9085: 'IP-Adresse nicht in Whitelist',
        error_code_9086:
            'Das Ändern der eigenen E-Mail Adresse vor einer Accountaktivierung ist nur für eine kurze Zeit verfügbar.',
        error_code_9089: 'Upgrade fehlgeschlagen',
        error_code_9090: 'Ein Downgrade ist nicht möglich. Bitte kontaktiere unseren Support',
        error_code_9091: 'Die Einrichtung auf dem Gerät ist fehlgeschlagen: $$',
        error_code_9092:
            'Einrichtung fehlgeschlagen: wurde dieses Gerät bereits registriert? Versuche, die App neu zu installieren.',
        error_code_9207: 'Keine Modul-ID für nächsten Schritt gefunden',
        error_code_9208: 'Kein Modulschritt gefunden',
        error_code_9211: 'Kein Modulschritt oder kein Eingangsmodul gefunden',
        error_code_9214: 'Mehr als ein Modulschritt für Kriterien gefunden',
        error_code_undefined: 'Unbekannter Fehler',
        error_code_null: 'Unbekannter Fehler',
        error_code_general: 'Fehler',

        // iOS Native
        ios_message_failure: 'Nachricht konnte nicht an iOS gesendet werden',

        // sbk
        error_code_9100: 'Unbekannter Kommunikationsfehler',
        error_code_9101: 'Aktivierungstoken unbekannt',
        error_code_9102: 'Aktivierungstoken ungültig',
        error_code_9103: 'Aktivierungstoken ist abgelaufen',
        error_code_9104: 'Neues Passwort muss gesetzt werden',
        error_code_9105: 'Ungültige Zugangsdaten',
        error_code_9106: 'Bitte registrieren Sie sich über den Link, der Ihnen geschickt wurde',
        error_code_9107: 'Kampagne wurde noch nicht gestartet',
        error_code_9108: 'Kampagne wurde deaktiviert',
        error_code_9109: 'Datenverarbeitungszustimmung wurde zurückgezogen',
        error_code_9110:
            'Sie haben sich erfolgreich zur Aktion registriert. Um Ihren Account zu aktivieren klicken Sie bitte auf den Link in der Willkommens-Mail. Diese haben wir Ihnen gerade zugesendet.',
        error_code_9111: 'Datenverarbeitungszustimmung wurde nicht gegeben',
        error_code_9112: 'Bereits registriert bei anderer Kampagne',
        error_code_9113: 'Registrierung ist geschlossen',
        error_code_9114: 'Unbekannte E-Mail-Adresse',
        error_code_9115: 'Falsche Kampagnen-ID',
        error_code_9116: 'Mindestabstand zwischen zwei Passwort-Zurücksetzen-Anfragen nicht eingehalten',
        error_code_9117: 'Mindestabstand zwischen zwei Aktivierungslink-Anfragen nicht eingehalten',
        error_code_9118: 'Profilfelder ungültig',
        error_code_9119: 'Ihr Konto ist bereits aktiviert',
        error_code_9097: 'Kein legales Update',

        // thryve
        error_code_9200: 'Es besteht bereits eine Verbindung mit einem Tracker',
        error_code_9201: 'Es besteht noch keine Verbindung mit einem Tracker',
        error_code_9202:
            'Der letzte Verbindungsversuch war vor wenigen Minuten. Bitte versuche es in wenigen Minuten wieder.',

        // journals
        error_code_9300: 'An diesem Tag existiert bereits ein Eintrag.',

        doc_access_title: 'Zugang für medizinisches Personal',
        doc_access_patient_data_info:
            'Auswertunsgdaten eines Patienten können über einen 8-Stelligen Zugangscodes abgerufen werden. Der Zugang erfordert eine manuelle Bestätigung des Patienten.',
        doc_waiting_for_patient: 'Warte auf Bestätigung des Patienten...',

        general_complete: 'Abschließen',
        general_device_name: 'Gerätebezeichnung (z.B. Mein Laptop)',
        general_created: 'Erstellt',
        general_last_used: 'Zuletzt benutzt',
        general_welcome_back: 'Willkommen zurück!',
        general_not_now: 'Nicht jetzt',
        general_manual: 'Gebrauchsanweisung',
        general_worries: 'Sorgen',
        general_overload: 'Überforderung',
        general_leisure: 'Freizeit',
        general_travel: 'Fortbewegung',
        general_recipes: 'Rezepte',
        general_work: 'Arbeit',
        general_summary: 'Zusammenfassung (PDF)',
        general_all_summaries: 'Alle Zusammenfassungen',
        general_max_number_of_files: 'Maximale Anzahl Dateien: $$',
        general_size_limit: 'Maximale Dateigrösse: $$ MB',
        general_file_type_not_allowed: 'Dieser Datentyp ist nicht erlaubt',
        general_and: 'und',
        general_answerFromSelection: 'Text der Antwortauswahl',
        general_cigarette: 'Zigarette',
        general_cigarettes: 'Zigaretten',
        general_euro: 'Euro',
        general_euros: 'Euro',
        general_week: 'Woche',
        general_weeks: 'Wochen',
        general_mmHg: 'Millimeter Quecksilbersäule',
        general_mmHg_abr: 'mmHg',
        general_bpm_abr: 'bpm',
        general_bpm: 'Schläge pro Minute',
        general_entry: 'Eintrag',
        general_entries: 'Einträge',
        general_entries_nominative: 'Einträge',
        general_entries_genitive: 'Einträge',
        general_entries_dative: 'Einträgen',
        general_entries_accusative: 'Einträge',
        general_piece: 'Stück',
        general_pieces: 'Stücke',
        general_piece_pieces: 'Stück(e)',
        general_pieces_dative: 'Stücken',
        general_pieces_genitive: 'Stücke',
        general_pieces_accusative: 'Stücke',
        general_pieces_nominative: 'Stücke',
        general_portion: 'Portion',
        general_portions: 'Portionen',
        general_portion_portions: 'Portion(en)',
        general_leave_app_confirmation: 'Achtung, du verlässt die Anwendung. Trotzdem fortfahren?',
        general_warn_fitbit_external_link: 'Achtung, beim Klick auf "Verbindung mit fitbit" verlässt du die App.',
        general_terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
        general_privacy_policy: 'Datenschutzerklärung',
        general_app_version: 'App version',
        general_data_processing: 'Einwilligung zur Verarbeitung von Personen- und Gesundheitsdaten',
        general_exclusion_criteria: 'Ausschlusskriterien',
        general_no_confirmation_mail: 'Bestätigungsmail nicht erhalten?',
        general_forgot_password: 'Passwort vergessen',
        general_average: 'Durchschnitt',
        general_explain_license_extension:
            'Du kannst durch die Eingabe eines Rezept- oder Lizenzcodes deine aktuelle Lizenz verlängern oder ein neues Programm freischalten.',
        general_for_program: 'Für Programm',
        general_goals: 'Ziele',
        general_goals_change_in_profile: 'Hier kannst du deine persönlichen Ziele anpassen',
        general_first_goal: 'Erstes Ziel',
        general_second_goal: 'Zweites Ziel',
        general_third_goal: 'Drittes Ziel',
        general_no_goal: 'Kein Ziel',
        general_overall: 'Gesamt',
        general_show_regression: 'Regression anzeigen',
        general_institution: 'Arztpraxis',
        general_location: 'Postleitzahl',
        general_updates: 'Updates',
        general_prompt_remove_entry: 'Sicher, dass du diesen Eintrag entfernen möchtest?',
        general_weekly_average: '7-Tage Schnitt',
        general_your_entry: 'Dein Eintrag',
        general_confirm: 'Bestätigen',
        general_voucher_export: 'CSV Export',
        general_two_factor_details: 'Aktiviere die Zwei-Faktor-Authentifizierung für zusätzliche Sicherheit',
        general_two_factor_authentication: 'Zwei-Faktor-Authentifizierung',
        general_create_password_to_activate: 'Bitte erstelle ein Passwort, um dein Konto zu aktivieren:',
        general_click_to_activate_account:
            'Du hast bereits ein Passwort vergeben. Klicke unten um das Programm zu aktivieren.',
        general_generate_demo_data_prompt: 'Alle bestehenden Tagesbucheinträge werden gelöscht. Trotzdem fortfahren?',
        general_select_provider: 'Anbieter Auswählen',
        general_demo_account: 'Demo Konto',
        general_flag_for_rating: 'Flag für Bewertung',
        general_provider: 'Anbieter',
        general_generate_sleep_data: 'Einträge generieren',
        general_demo_actions: 'Demo-Aktionen',
        general_demo_actions_details: 'Du hast ein Demo-Konto. Es stehen folgende Aktionen zur verfügung:',
        general_remove_wait_times: 'Wartezeiten entfernen',
        general_unlock_all_modules: 'Alle Module freischalten',
        general_here: 'hier',
        general_link: 'Link',
        general_more_info: 'Mehr Infos',
        general_auto_next: 'Automatisch weiter',
        general_auto_next_detail:
            'Am Ende eines Schritts automatisch zum nächsten springen, falls keine Interaktion nötig ist',
        general_module_duration: 'Bearbeitungszeit: $$ Minuten',
        general_sleep_phase: 'Schlafphase',
        general_awake_phase: 'Wachphase',
        general_consent: 'Einwilligung',
        general_sources: 'Quellen',
        general_gender_other: 'Divers',
        general_analysis: 'Analyse',
        general_reports: 'Berichte',
        general_in_progress_please_wait: 'In Arbeit, bitte warten...',
        general_job_started_mail_will_be_sent: 'Auftrag erstellt, das Resultat wird per Mail verschickt',
        general_job_started_notification_will_be_sent:
            'Auftrag erstellt, eine E-Mail wird verschickt, sobald das Resultat heruntergeladen werden kann',
        general_job_completed: 'Auftrag abgeschlossen',
        general_active_after_wakeup: 'Aktiv nach Aufwachen',
        general_sleep_quality: 'Schlafqualität',
        general_comparison: 'Vergleich',
        general_split_evaluation_view: 'Delta-Auswertung (Daten werden visuell geteilt)',
        general_new_entry: 'Neuer Eintrag',
        general_edit_entry: 'Eintrag bearbeiten',
        general_sleep: 'Schlaf',
        general_voucher_internal: 'Nur intern',
        general_license_expired: 'Lizenz ist abgelaufen. Zugang wurde blockiert.',
        general_answer_label: 'Antwort Label',
        general_support_confirmation: 'Anfrage abgeschickt',
        general_send_request: 'Absenden',
        general_request: 'Anfrage',
        general_request_detail: 'Bitte beschreibe dein Anliegen so präzise wie möglich',
        general_new_password: 'Neues Passwort',
        general_own_next: 'Eigener "Weiter" Button',
        general_your_insomnia_cycle: 'Dein Kreislauf der Insomnie',
        general_feedback: 'Feedback',
        general_entries_remaining_help_title: 'Weitere Einträge?',
        general_entries_remaining_help:
            'Das Programm steuert verschiedene Inhalte und Interventionen aufgrund deiner Tagebucheinträge. Damit das funktioniert und weitere Module durchgeführt werden können, ist eine gewisse Mindestanzahl an Einträgen erforderlich.',
        general_entries_remaining_help_b:
            'Es zählen dabei nur neue Einträge, die du nach Abschluss des letzten Moduls erstellt hast.',
        general_entry_remaining:
            'Noch <b>1</b> neuer Eintrag nötig im Morgenprotokoll, bis sich das nächste Modul öffnet',
        general_entries_remaining:
            'Noch <b>$$</b> neue Einträge nötig im Morgenprotokoll, bis sich das nächste Modul öffnet',
        general_entries_remaining_evening: 'noch <b>$$</b> Einträge im Abendprotokoll nötig',
        general_entries_remaining_blood_pressure: 'noch <b>$$</b> Einträge im Blutdruckprotokoll nötig',
        general_entry_remaining_evening: 'noch <b>1</b> Eintrag im Abendprotokoll nötig',
        general_entry_remaining_blood_pressure: 'noch <b>1</b> Eintrag im Blutdruckprotokoll nötig',
        general_entries_to_unlock_module: 'Um das nächste Modul freizuschalten, sind',
        general_your_sleep_window: 'Schlaffenster',
        general_no_sleep: 'Kein Schlaf',
        general_shift_window_back: 'Fester nach hinten verschieben',
        general_save_window: 'Schlaffenster speichern',
        general_shift_window_forward: 'Fenster nach vorne verschieben',
        general_plus_minutes: '+$$ Minuten',
        general_minus_minutes: '-$$ Minuten',
        general_age: 'Alter',
        general_gender: 'Geschlecht',
        general_male: 'Männlich',
        general_female: 'Weiblich',
        general_custom_element: 'Custom element',
        general_menu: 'Menu',
        general_point: 'Punkt',
        general_points: 'Punkte',
        general_points_dative: 'Punkten',
        general_points_genitive: 'Punkte',
        general_points_accusative: 'Punkte',
        general_points_nominative: 'Punkte',
        general_add_notification: 'Benachrichtigung erstellen',
        general_history: 'History',
        general_validators: 'Validierung',
        general_system_notifications: 'Benachrichtigungen',
        general_data_export_profile_json_title: 'Export Benutzerdaten',
        general_data_export_profile_json_details:
            'Du kannst alle im System über dich gespeicherten Daten in einem interoperablen Format (JSON) herunterladen.',
        general_data_export_sleep_journals_xlsx_title: 'Export Tagebucheinträge',
        general_data_export_sleep_journals_xlsx_awake_protocol_title: 'Export Wachprotokoll',
        general_data_export_sleep_journals_xlsx_details:
            'Die Einträge, die du im Schlaftagebuch erfasst hast, sind hier verfügbar im Excel-Format.',
        general_data_export_sleep_journals_xlsx_sheet_title: 'Schlaftagebucheinträge',
        general_data_export_sleep_journals_xlsx_sheet_date_created: 'Datum Export',
        general_data_export_sleep_journals_xlsx_sheet_user_mail: 'Benutzer',
        general_data_export_sleep_journals_xlsx_sheet_header_entry_nr: 'Eintrag Nr.',
        general_data_export_sleep_journals_xlsx_sheet_header_to_bed_date: 'Ins Bett gegangen',
        general_data_export_sleep_journals_xlsx_sheet_header_get_up_date: 'Aufgestanden',
        general_data_export_sleep_journals_xlsx_sheet_header_no_sleep: 'Nicht eingeschlafen',
        general_data_export_sleep_journals_xlsx_sheet_header_tried_to_sleep: 'Versuch einzuschlafen',
        general_data_export_sleep_journals_xlsx_sheet_header_fell_asleep: 'Eingeschlafen',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_amount: 'Anzahl Wachphasen',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_duration: 'Wachzeit in der Bettphase',
        general_data_export_sleep_journals_xlsx_sheet_header_wake_up_date: 'Aufgewacht',
        general_data_export_sleep_journals_xlsx_sheet_header_from_tracker: 'Von Schlaftracker geladen',
        general_data_export_visual_analysis_title: 'Visuelle Auswertung',
        general_data_export_visual_analysis_details:
            'Die Einträge, die du im Tagebuch erfasst hast, sind hier verfügbar im PDF-Format.',
        general_data_export_visual_analysis_file_name: 'somnio_schlafdaten',
        general_data_export_visual_analysis_averages_last_week: 'Durchschnittswerte der letzten Woche',
        general_data_export_visual_analysis_overall: 'Gesamtverlauf',
        general_data_export_visual_analysis_relation_bed_sleep: 'Bettliegezeit / Schlafzeit',
        general_data_export_visual_analysis_relation_bed: 'Bettliegezeit',
        general_data_export_visual_analysis_relation_sleep: 'Schlafzeit',
        general_data_export_visual_analysis_awake_in_bed: 'Wach im Bett nach Aufwachen',
        general_data_export_visual_analysis_sleep_data: 'Medizinischer Bericht von',
        general_download: 'Download',
        general_encrypted: 'verschlüsselt',
        general_view_download: 'Ansehen/ Herunterladen',
        general_coming_soon: 'Bald verfügbar',
        general_download_as_pdf: 'Als PDF herunterladen',
        general_open_print_dialog: 'Druckerdialog öffnen',
        general_data_security: 'Datenschutz',
        general_revoke_consent: 'Einwilligung zurückziehen',
        general_warning: 'Warnung',
        general_prompt_revoke_consent_info:
            'Mit dem Widerruf der Einwilligung zur Verarbeitung personenbezogener Daten kann die Anwendung nicht mehr genutzt werden. Dein Konto wird deaktiviert und innerhalb der nächsten 30 Tage gelöscht.',
        general_prompt_revoke_consent_confirm:
            'Bitte bestätige den Widerruf mit der Eingabe der E-Mailadresse deines Kontos.',
        general_prompt_remove_account_info:
            'Mit der Löschung des Kontos wird dein Profil zusammen mit allen dazugehörigen Daten unwiderruflich gelöscht. Du hast die Möglichkeit unter "Export" alle über dich gespeicherten Daten in einem Interoperablen Format (JSON) herunterladen.',
        general_prompt_remove_account_confirm:
            'Bitte bestätige die Löschung mit der Eingabe der E-Mail-Adresse des Kontos.',
        general_account: 'Konto',
        general_data_processing_agreement:
            'Bei der Erstellung deines Kontos hast du mementor die Zustimmung zur Verarbeitung deiner personenbezogenen Daten erteilt.',
        general_remove_account: 'Konto löschen',
        general_remove_account_description:
            'Das Konto wird physikalisch gelöscht, d.h. der gesamte Eintrag wird aus der Datenbank entfernt',
        general_anonymize_account: 'Konto anonymisieren',
        general_anonymize_account_description:
            'Die mit dem Benutzer verbundenen Daten werden gelöscht. Einzig für die Abrechnung spezifische Daten wie Registrierdatum, Lizenz und Vouchers werden behalten. Die E-Mail-Adresse wird anonymisiert.',
        general_allow_medical_access_short: 'Zugang erlauben?',
        general_allow_medical_access:
            'Willst du "$$" einen einmaligen Zugang zur Auswertung deines Therapiedaten geben?',
        general_load_evaluation: 'Auswertung laden',
        general_optional: 'Optional',
        general_code: 'Code',
        general_percentage: 'Prozent',
        general_percentage_point: 'Prozentpunkt',
        general_percentage_points: 'Prozentpunkte',
        general_decibel: 'Dezibel',
        general_sleep_efficiency: 'Schlafeffizienz',
        general_bed_time: 'Bettzeit',
        general_sleep_time: 'Schlafzeit',
        general_prompt_confirm_short: 'Sicher?',
        general_prompt_confirm: 'Bist du sicher?',
        general_yes: 'Ja',
        general_no: 'Nein',
        general_any: 'Alle',
        general_forward: 'Weiter',
        general_back: 'Zurück',
        general_sleep_journal_evaluation: 'Schlaftagebuch',
        general_sleep_tracker: 'Schlaftracker',
        general_sleep_tracker_connected: 'Schlaftracker verbunden. Schlaftagebuch wird automatisch geführt.',
        general_connect_fitbit: 'Verbindung mit fitbit',
        general_disconnect_fitbit: 'Verbindung trennen',
        general_connect_fitbit_info:
            'Die Anbindung eines fitbit Schlaftrackers ermöglicht das automatische Ausfüllen des Schlaftagebuchs.',
        general_times: 'Mal',
        general_health_data_consent: 'Verarbeitung meiner Personen- und Gesundheitsdaten',
        general_health_data_consent_a: 'Ich stimme der',
        general_health_data_consent_b: 'zu*',
        general_medication_plan: 'Medikationsplan',
        general_drag_file: 'Datei ziehen',
        general_or_select_file: 'Oder Datei auswählen',
        general_or: 'Oder',
        general_support_page_somnio:
            'Für Fragen und Support besuche bitte die <a href="https://support.mementor.de/help/de-de/16-somnio" target="_blank">Supportseite</a> von somnio.',
        general_support_page_actensio:
            'Für Fragen und Support besuche bitte die <a href="https://support.mementor.de/help/de-de/49-actensio" target="_blank">Supportseite</a> von actensio.',
        general_support_question: 'Du konntest dein Problem nicht lösen?',
        general_support_option_choose: 'Wähle eine Art des Problems aus',
        general_support_tutorial_sentence: 'Hier findest du eine Anleitung zur Registrierung per Video',
        general_support_tutorial_button: 'Zum Video',
        general_support_content_issues: 'Inhaltliche Fragen',
        general_support_technical_issues: 'Technische Fragen',
        general_support_technical_tooltip: 'Bei technischen Problemen wird dir hier geholfen',
        general_support_faq: 'Antworten auf die häufigsten Fragen findest du auf der Supportseite von',
        general_support_counseling: 'Vereinbare hier direkt einen Gesprächstermin',
        general_support_calendar: 'Zum Kalender',
        general_support_form: 'Schreibe uns direkt über das untenstehende Supportformular',
        general_support_page_sbk: 'Der Support erfolgt durch den Auftragsverabeiter die mementor DE GmbH für die SBK.',
        general_support_form_sbk:
            'Bei Fragen und Support wende dich an <a href="mailto:sbk.schlafgut@mementor.de">sbk.schlafgut@mementor.de</a> oder nutze direkt das Supportformular:',
        general_help: 'Hilfe',
        general_support: 'Support',
        general_notifications: 'Benachrichtigungen',
        general_data_protection: 'Datenschutz',
        general_journal_entries: 'Tagebucheinträge',
        general_required_entries: 'Benötigte Einträge',
        general_required_entries_detail: 'Anzahl benötigter Einträge, um das nächste Modul freizuschalten',
        general_monday_abbr: 'MO',
        general_tuesday_abbr: 'DI',
        general_wednesday_abbr: 'MI',
        general_thursday_abbr: 'DO',
        general_friday_abbr: 'FR',
        general_saturday_abbr: 'SA',
        general_sunday_abbr: 'SO',
        general_language: 'Sprache',
        general_bad: 'Schlecht',
        general_good: 'Gut',
        general_low: 'Gering',
        general_high: 'Hoch',
        general_large: 'Groß',
        general_well_being: 'Befinden',
        general_consumption: 'Konsum',
        general_load_more: 'Weitere laden',
        general_edit_voucher: 'Voucher bearbeiten',
        general_edit_provider: 'Anbieter bearbeiten',
        general_edit_medication: 'Medikament bearbeiten',
        general_your_sleep_development: 'Schlafentwicklung',
        general_efficiency: 'Effizienz',
        general_fall_asleep_time: 'Einschlafzeit',
        general_awake_time: 'Wachzeit',
        general_comment: 'Kommentar',
        general_hello_name: 'Hallo $$!',
        general_sleep_type_mixed: 'gemischter Typ',
        general_sleep_type_fallAsleep: 'Einschlaftyp',
        general_sleep_type_sleepThrough: 'Durchschlaftyp',
        general_sleep_type_wakeUpEarly: 'zu frühes Erwachen',
        general_confirm_account: 'Konto bestätigen',
        general_restart: 'Neustart',
        general_add_user: 'Benutzer erstellen',
        general_user_actions: 'Aktionen',
        general_active: 'Aktiv',
        general_account_activated_redirecting:
            'Konto aktiviert, bitte melde dich mit deiner E-Mail-Adresse und Passwort an',
        general_password_reset: 'Passwort zurückgesetzt',
        general_reset_instructions_sent:
            'Falls ein Konto mit dieser E-Mail existiert, wurden die Anweisungen zum Zurücksetzen des Passworts dorthin geschickt.',
        general_input_label: 'Beschriftung Benutzereingabe',
        general_min_value: 'Minimalwert',
        general_max_value: 'Maximalwert',
        general_external_value: 'Externer Wert',
        general_animation_blot_driven: 'Animation wird durch externe Wert gesteuert',
        general_slider_label: 'Beschriftung Slider',
        general_slider_min_label: 'Beschriftung Minimalwert',
        general_slider_max_label: 'Beschriftung Maximalwert',
        general_slider_min_value: 'Minimalwert',
        general_slider_max_value: 'Maximalwert',
        general_slider_show_ticks: 'Ticks anzeigen',
        general_day: 'Tag',
        general_days: 'Tage',
        general_day_days: 'Tag(e)',
        general_days_dative: 'Tagen',
        general_days_genitive: 'Tage',
        general_days_accusative: 'Tage',
        general_days_nominative: 'Tage',
        general_hour: 'Stunde',
        general_hours: 'Stunden',
        general_minute: 'Minute',
        general_minutes: 'Minuten',
        general_minute_minutes: 'Minute(n)',
        general_mgdl: 'Milligramm pro Deziliter',
        general_mmol: 'Millimol',
        general_drinking_glass: 'Glas',
        general_drinking_glasses: 'Gläser',
        general_module_entry_point: 'Modul-Einstiegspunkt',
        general_separate_by_comma: 'Elemente mit Komma trennen',
        general_number: 'Zahl',
        general_integer: 'Ganzzahl',
        general_list: 'Liste',
        general_list_items: 'Listenelemente',
        general_answer_type: 'Antwort-Typ',
        general_unit: 'Einheit',
        general_key: 'Schlüssel',
        general_edit_answer_container: 'Antwortbehälter bearbeiten',
        general_add_answer_container: 'Behälter hinzufügen',
        general_answer_containers: 'Antwortbehälter',
        general_select_answer_container: 'Auswahl des Antwortbehälters',
        general_answer_value: 'Wert',
        general_answer_container: 'Antwortbehälter',
        general_answer_container_name: 'Name des Antwortbehälters',
        general_questionnaire: 'Fragebogen',
        general_questionnaires: 'Fragebögen',
        general_add_questionnaire: 'Fragebogen hinzufügen',
        general_edit_questionnaire: 'Fragebogen bearbeiten',
        general_complete_questionnaire: 'Fragebogen abschließen',
        general_questions: 'Fragen',
        general_question_question: 'Frage',
        general_question_title: 'Überschrift',
        general_edit_question: 'Frage bearbeiten',
        general_add_question: 'Frage hinzufügen',
        general_copy_question: 'Frage kopieren',
        general_question_type_select: 'Auswahl',
        general_question_type_buttons: 'Buttons',
        general_question_type_number_input: 'Zahleninput',
        general_question_type_mobiscroll_number: 'Mobiscroll Zahleninput',
        general_question_type_mobiscroll_timespan: 'Mobiscroll Timespan input',
        general_conditions: 'Konditionen',
        general_expression: 'Ausdruck',
        general_edit_user: 'Benutzer bearbeiten',
        general_availability: 'Verfügbarkeit',
        general_filters: 'Filter',
        general_icon: 'Icon',
        general_edit_coaching: 'Training bearbeiten',
        general_spoken_text: 'Gesprochener Text',
        general_edit_animation: 'Animation bearbeiten',
        general_add_animation: 'Animation hinzufügen',
        general_select_animation: 'Animation auswählen',
        general_select_questionnaire: 'Fragebogen auswählen',
        general_select: 'Auswählen',
        general_animations: 'Animationen',
        general_animation: 'Animation',
        general_no_animation: 'Keine Animation',
        general_new_modules_available: 'Neue Module verfügbar',
        general_update: 'Update',
        general_start: 'Start',
        general_start_module: 'Modul starten',
        general_continue_module: 'Fortfahren',
        general_select_step: 'Schrittauswahl',
        general_edit_module: 'Modul bearbeiten',
        general_percent: 'Prozent',
        general_specify_parameters: 'Parameter',
        general_select_step_property: 'Schritt-Eigenschaft auswählen',
        general_edit_step: 'Schritt bearbeiten',
        general_values: 'Werte',
        general_functions: 'Funktionen',
        general_speech: 'Sprache',
        general_roles: 'Rollen',
        general_add_role: 'Rolle hinzufügen',
        general_add: 'Hinzufügen',
        general_roles_menu: 'Rollen',
        general_sleep_training: 'Schlaftraining',
        general_minutes_short: ' min',
        general_search: 'Suche',
        general_wait_time: 'Wartezeit',
        general_flow_manager: 'Modulfluss',
        general_select_module: 'Modulauswahl',
        general_first_name: 'Vorname',
        general_last_name: 'Nachname',
        general_no_module_selected: 'Kein Modul ausgewählt',
        general_description: 'Beschreibung',
        general_saved_value: 'Gespeicherter Wert',
        general_next_step: 'Nächster Schritt',
        general_save: 'Speichern',
        general_save_and_continue: 'Speichern & weiter',
        general_delete: 'Löschen',
        general_cancel: 'Abbrechen',
        general_add_module: 'Modul hinzufügen',
        general_create_module: 'Modul erstellen',
        general_add_coaching: 'Training erstellen',
        general_value: 'Wert',
        general_save_answer: 'Antwort speichern',
        general_answer_property: 'Bezeichnung der Antwort',
        general_users: 'Benutzer',
        general_user: 'Benutzer',
        general_step_name: 'Schrittbezeichnung',
        general_coaching_manager: 'Trainings',
        general_module_manager: 'Module',
        general_step_manager: 'Schritte',
        general_text: 'Text',
        general_email: 'E-Mail',
        general_wrong_email: 'Vertippt? Mailadresse ändern...',
        general_password: 'Passwort',
        general_password_set_new: 'Neues Passwort setzen',
        general_password_new: 'Neues Passwort',
        general_password_old: 'Altes Passwort',
        general_password_tip_show: 'Passwort anzeigen',
        general_password_tip_hide: 'Passwort verbergen',
        general_repeat_password: 'Passwort wiederholen',
        general_request_password: 'Passwort vergessen',
        general_request_password_submit: 'Passwort zurücksetzen',
        general_login: 'Login',
        general_login_action: 'Einloggen',
        general_login_with_password: 'Login mit Passwort',
        general_login_passwordless: 'Login ohne Passwort',
        general_manage_devices: 'Verwalte deine Geräte',
        general_setup_and_go_passwordless: 'Nach der Einrichtung kannst du dich ohne Passwort anmelden',
        general_setup: 'Einrichtung',
        general_unregister_device: 'Geräteverbindung trennen',
        general_login_faster: 'Schneller einloggen',
        general_passwordless_login:
            'Dein Gerät unterstützt ein Login ohne Passwort. Möchtest du einen solchen Zugang jetzt einrichten?',
        general_passwordless_login_active: 'Login ohne Passwort aktiviert!',
        general_passwordless_login_active_b: 'Bitte gib noch eine Bezeichnung für dein Gerät ein:',
        general_register: 'Neues Konto',
        general_register_long: 'Neues Konto erstellen',
        general_prescription_or_license_code: 'Hast du ein Rezept oder einen Lizenzcode?',
        general_unlock: 'Freischalten',
        general_enter_code: 'Codeeingabe',
        general_enter_code_detail: 'Gib hier deinen 16-Stelligen Rezept- oder Lizenzcode ein:',
        general_start_without_code:
            'Du kannst dein Konto bereits anlegen, aber um das Programm zu nutzen, benötigst du ein Rezept oder einen Lizenzcode.',
        general_we_will_support_you: 'Wir werden dich dabei unterstützen.',
        general_register_action: 'Konto erstellen',
        general_register_tk: 'Registrierung TK',
        general_almost_done: 'Fast geschafft!',
        general_registration_success:
            'Bitte prüfe dein E-Mail-Postfach ($$) und klicke auf den Link, um dein Konto zu aktivieren.',
        general_notify_mail_delay: 'Beachte, dass es einige Minuten dauern kann, bis die Bestätigungsmail ankommt.',
        general_activation_success: 'Dein Konto wurde erfolgreich aktiviert. Du kannst dich nun anmelden.',
        general_activation_new_password_success:
            'Anfrage erfolgreich verschickt - bitte prüfe dein E-Mail-Postfach, um das neue Passwort zu erhalten.',
        general_coaching_single: 'Training',
        general_coaching: 'Mein Training',
        general_settings: 'Einstellungen',
        general_user_data: 'Benutzerangaben',
        general_user_groups: 'Benutzergruppen',
        general_logout: 'Abmelden',
        general_application: 'Applikation',
        general_session: 'Sitzung',
        general_manager: 'Manager',
        general_administration: 'Administration',
        general_sleep_journal: 'Schlaftagebuch',
        general_license: 'Lizenz',
        general_licenses: 'Lizenzen',
        general_licenses_overview: 'Übersicht deiner aktiven Lizenzen',
        general_license_extend: 'Lizenz aktivieren/verlängern',
        general_license_buy: 'Lizenz erwerben',
        general_license_management: 'Lizenz-Management',
        general_license_code: 'Rezept- oder Lizenzcode',
        general_license_type_admin: 'Von Administrator erstellt',
        general_license_type_trial: 'Evaluationslizenz (veraltet)',
        general_license_type_provider: 'Anbieterlizenz',
        general_license_type_diga: 'DiGA-Lizenz',
        general_license_type_diga_unverified: 'DiGA-Lizenz (nicht verifiziert)',
        general_license_type_diga_rejected: 'DiGA-Lizenz (abgelehnt)',
        general_voucher: 'Voucher',
        general_vouchers: 'Vouchers',
        general_voucher_manager: 'Voucher Manager',
        general_voucher_add: 'Voucher erstellen',
        general_voucher_find_expiring_temp_diga_licenses: 'DiGA Temp Lizenzen',
        general_voucher_invoicing_summary: 'DiGA-Code Verrechnungsübersicht',
        general_voucher_sync_with_sevdesk: 'Sync mit SevDesk',
        general_voucher_name: 'Bezeichnung des Vouchers',
        general_voucher_discount_percentage: 'Voucher Rabatt %',
        general_voucher_validity_days: 'Gültigkeit (Tage)',
        general_voucher_validity_unlimited: 'Unlimitiert',
        general_voucher_provider: 'Voucher-Herausgeber (optional)',
        general_voucher_coaching: 'Für Training',
        general_voucher_quantity: 'Kann x mal eingelöst werden',
        general_voucher_code: 'Voucher Code',
        general_redeem_code: 'Code einlösen',
        general_voucher_redeem_execute: 'Einlösen',
        general_voucher_confirm_diga: 'DiGA-Voucher bestätigen',
        general_voucher_reject_diga: 'DiGA-Voucher ablehnen',
        general_voucher_usages: 'Verwendungen',
        general_upgrade: 'Upgrade',
        general_upgrade_successful:
            'Upgrade erfolgreich! <br> Mit dem Click auf "Alles klar" wirst du automatisch ausgeloggt und musst dich anschließend wieder einloggen.',
        general_upgrade_not_successful: 'Upgrade nicht erfolgreich',
        general_upgrade_license:
            'Mit dem Einlösen dieses Lizenzcodes führst du ein Upgrade auf die DiGA (App auf Rezept) somnio durch. <br> <br> Möchtest du deine bestehenden Tagebuchdaten mit dem Upgrade auf somnio übernehmen?',
        general_localise: 'Lokalisieren',
        general_provider_manager: 'Anbieter/ Kunde/ Code-Herausgeber',
        general_provider_menu: 'Anbieter',
        general_provider_name: 'Anbieter-Name',
        general_provider_parent: 'Übergeordneter Anbieter',
        general_provider_add: 'Anbieter hinzufügen',
        general_provider_none: 'Kein Anbieter/ unbekannt',
        general_continue: 'Weiter',
        general_speak: 'Sprich',
        general_accept_terms: 'Ich akzeptiere die',
        general_terms_and: 'und',
        general_terms_abbr: 'AGB',
        general_privacy_policy_abbr: 'DSE',
        general_exclusion_criteria_a: 'Ich habe die',
        general_exclusion_criteria_b: 'für die Nutzung zur Kenntnis genommen',
        general_back_to_login: 'Zum Login',
        general_my_coaching: 'Training',
        general_monitor: 'Medizinischer Bericht',
        general_coachings: 'Trainings',
        general_select_coaching: 'Wähle dein Training aus',
        general_medication: 'Medikamente',
        general_modules: 'Module',
        general_steps: 'Schritte',
        general_step: 'Schritt',
        general_add_step: 'Schritt hinzufügen',
        general_answer: 'Antwort',
        general_answers: 'Antworten',
        general_name: 'Bezeichnung',
        general_username: 'Dein Name',
        general_module: 'Modul',
        general_all: 'Alle',
        general_none: 'Kein',
        general_little: 'Wenig',
        general_medium: 'Mittel',
        general_lots: 'Viel',
        general_close: 'Schließen',
        general_date_days: 'Tage',
        general_date_week: 'Woche',
        general_date_weeks: 'Wochen',
        general_week_weeks: 'Woche(n)',
        general_date_months: 'Monate',
        general_date_created: 'Erstellungsdatum',
        general_text_copied: 'Text in die Zwischenablage kopiert',
        general_version: 'Version',
        general_publish: 'Veröffentlichen',
        general_new_version: 'Neue Version',
        general_delete_version: 'Version löschen',
        general_shortcuts: 'Shortcuts',
        general_published: 'Veröffentlicht',
        general_published_on: 'Veröffentlicht am',
        general_edit_module_meta: 'Modul-Meta bearbeiten: $$',
        general_wait_time_hours: 'Wartezeit in Stunden',
        general_has_license_code: 'Ich habe einen Lizenzcode',
        general_activate_account: 'Konto aktivieren',
        general_sleep_coaching: 'Schlaftraining',
        general_receive_newsletter: 'Ich möchte den Newsletter erhalten',
        general_newsletter: 'Newsletter',
        general_receive_mail_notifications: 'Erinnerungen via E-Mail erhalten',
        general_receive_push_notifications: 'Push-Nachrichten erhalten',
        general_profile: 'Profil',
        general_clients: 'Benutzergruppe',
        general_save_password: 'Passwort speichern',
        general_save_profile: 'Profil speichern',
        general_coach_settings: 'Coach',
        general_coach_talking_speed: 'Sprechgeschwindigkeit',
        general_slow: 'Langsam',
        general_fast: 'Schnell',
        general_test: 'Test',
        general_about: 'Über $$',
        general_medical_device: 'Medizinprodukt',
        general_serial_number: 'Seriennummer',
        general_udi_code: 'UDI-Code',
        general_somnio_description:
            'somnio ist eine digitale Anwendung zur Behandlung von Ein- und Durchschlafstörungen (Insomnie)',
        general_actensio_description:
            'actensio ist eine digitale Anwendung zur Behandlung von Bluthochdruck, dessen Interventionen auf leitlinienkonformen Lebensstilveränderungen beruhen.',
        general_got_it: 'Alles klar',
        general_understood: 'Verstanden',
        general_authentication: '2-Faktor Autorisierung',
        general_no_account_yet: 'Noch kein Konto?',
        general_insert_two_factor_code: 'Bitte gib den Code ein, den wir Dir soeben per E-Mail geschickt haben',
        general_did_not_get_code: 'Keinen Code erhalten?',
        general_send_again: 'Erneut senden',
        general_resend_activation_link: 'Aktivierungslink erneut zuschicken',
        general_activation_link_missing: 'Aktivierungslink nicht erhalten?',
        general_code_sent: 'Code geschickt an $$',
        general_x_label: 'Beschriftung X-Achse',
        general_y_label: 'Beschriftung Y-Achse',
        general_days_back: 'Tage Zurück',
        general_evaluation: 'Auswertung',
        general_evaluation_journal: 'Auswertung Journal',
        general_evaluation_questionnaire: 'Auswertung Fragebogen',
        general_show_subtitles: 'Untertitel anzeigen',
        general_sleep_restfulness_question: 'Wie erholsam war dein Schlaf?',
        general_multi_select_dialog_option_default_title: 'Auswahl',
        general_multi_select_dialog_option_default_message: 'Bitte wähle eine Option',
        general_multi_select_dialog_option_confirm_selection: 'Auswahl bestätigen',
        general_additional_data: 'Zusätzliche Daten',
        general_to: 'bis',
        general_stay_logged_in: 'Eingeloggt bleiben',
        general_XRechnung_title: 'X-Rechnung',
        general_XRechnung_submit_sevdesk_insurance: 'Schicke X-Rechnung an Versicherung & SevDesk',
        general_XRechnung_submit_sevdesk_only: 'Schicke X-Rechnung nur an SevDesk',
        general_XRechnung_submit_insurance_only: 'Schicke X-Rechnung nur an Versicherung',
        general_XRechnung_submit_insurance_override: 'Überschreibe X-Rechnung bei Versicherung',
        general_XRechnung_serviceDate: 'Datum der Leistungserbringung',
        general_XRechnung_buyerReference: 'Leitweg-Id (Buyer Reference)',
        general_XRechnung_insuranceName: 'Rechnungsempfänger Firma',
        general_XRechnung_insuranceIk: 'Versicherungs-IK',
        general_XRechnung_ikBilling: 'IK Abrechnungsstelle',
        general_XRechnung_insuranceStreet: 'Strasse',
        general_XRechnung_insurancePostCode: 'Postcode',
        general_XRechnung_insuranceCity: 'Ort',
        general_XRechnung_insuranceCountryCode: 'Land-Code',
        general_XRechnung_invoiceNr: 'Rechnungsnummer',
        general_XRechnung_sent_date: 'Versanddatum',
        general_XRechnung_paid_date: 'Bezahldatum',
        general_XRechnung_sevDesk_Id: 'SevDesk ID',
        general_XRechnung_already_paid: 'Bereits bezahlt',
        general_payrexx_title: 'Payrexx',
        general_payrexx_sync_to_sevdesk: 'An SevDesk schicken',
        general_currency: 'Währung',
        general_amount: 'Betrag',
        general_payment_provider_name: 'Zahlungsanbieter',
        general_payment_provider_transaction_id: 'Transaktions-ID',
        general_payment_provider_transaction_uuid: 'Transaktions-UUID',
        general_status: 'Status',
        general_extend_license_by_mail: 'Für E-Mail verlängern',
        general_extend_license_mail_address: 'User E-Mail',
        general_extend_license_days: 'Verlängerung um Tage',
        general_extend_license_validity_date: 'Gültig bis (nach Verlängerung)',
        general_check_voucher: 'Voucher prüfen',
        general_check_voucher_sum_valid: 'Die Prüfsumme ist korrekt',
        general_check_voucher_sum_invalid: 'Die Prüfsumme ist nicht korrekt',
        general_check_voucher_used: 'Der Voucher wurde bereits verwendet',
        general_check_voucher_unused: 'Der Voucher wurde noch nicht verwendet',
        general_refresh: 'Aktualisieren',
        general_display: 'Anzeigen',
        general_seven_days: '7 Tage',
        general_shorten: 'Verkürzen',
        general_extend: 'Verlängern',
        general_invalid_parameter: 'Ungültiger Parameter',
        general_certificate: 'Zertifikat',
        general_certificate_title: 'Abschlusszertifikat',
        general_certificate_expired: 'Zertifikat ist abgelaufen',
        general_certificate_issued: 'Zertifikatausgabe',
        general_certificate_we_confirm: 'Wir bestätigen',
        general_certificate_has_completed: 'hat das Schlaftraining vollständig abgeschlossen',
        general_certificate_description: 'Hier kannst du dir das Abschlusszertifikat herunterladen.',
        general_certificate_name: 'Name',
        general_import_medication_list: 'Importieren',
        general_for: 'Für',
        general_default: 'Default',
        general_prescriptionDetails_institution: 'Verschreibende Institution',
        general_in_sevdesk_recorded: 'In SevDesk erfasst',
        general_show_results: 'Show Results',
        general_filter_voucher: 'Filter Voucher',
        general_not_accepted: 'Nicht akzeptiert',
        general_translation_suggestion: 'Textvorschlag',
        general_translation_suggestion_add_new: 'Neuen Textvorschlag basierend auf diesem hinzufügen',
        general_translation_suggestion_remove_this: 'Diesen Textvorschlag entfernen',
        general_translation_suggestion_make_current: 'Diesen Textvorschlag anwenden',
        general_translation_suggestion_cannot_remove_current:
            'Dies ist die aktuelle Textversion und kann deshalb nicht entfernt werden - bitte zuerst eine andere Version anwenden',
        general_mail_sent: 'E-Mail versendet',
        general_not_connected: 'Nicht verbunden',
        general_of: 'Von',

        // sleep-devices
        general_thryve_connection_management: 'Trackerverbindungen verwalten',
        general_thryve_connect_title: 'Verbindungen mit Trackern',
        general_thryve_connect: 'Verbinden',
        general_thryve_connected: 'Mit Tracker verbunden',
        general_thryve_connected_success: 'Erfolgreich mit Tracker verbunden, Du kannst das Fenster schließen',
        general_thryve_connected_failure: 'Konnte nicht mit Tracker verbinden, bitte versuchen Sie es erneut',
        general_thryve_disconnected_success:
            'Erfolgreich Verbindung mit Tracker getrennt, Du kannst das Fenster schließen',
        general_thryve_disconnected_failure:
            'Konnte Verbindung mit Tracker nicht trennen, bitte versuchen Sie es erneut',
        general_thryve_disconnect: 'Trennen',
        notification_thryve_disconnected: 'Verbindung mit Tracker wurde getrennt',
        notification_thryve_select_usage: 'Bitte wählen Sie mindestens eine Verwendung',
        notification_no_pw_reset_link: 'Kein Reset-Passwort-Link verfügbar',
        general_thryve_connect_info:
            'Die Anbindung eines Aktivitätstrackers ermöglicht das automatische Ausfüllen des Tagebuchs.',
        general_thryve_explanation_somnio:
            'Nach der Einbindung eines Aktivitätstrackers werden die Schlafdaten automatisch mit dem Programm synchronisiert.',
        general_thryve_confirm_tracker_connection_somnio:
            'Die Präzision von automatischem Schlaftracking variiert von Person zu Person. mementor kann deshalb nicht für die Korrektheit der Daten garantieren und empfiehlt ausdrücklich, die <b>synchronisierten Daten regelmäßig zu kontrollieren und ggf. anzupassen</b>.',
        general_thryve_hint_provider:
            '<b>Hinweis</b> Die Datenübermittlung findet über unseren Partner Thryve statt. Aus diesem Grund wird bei der Einwilligung der Anbieter <i>Thryve</i> oder <i>mHealth Pioneers GmbH</i> angezeigt werden anstelle von <i>mementor</i>.',
        general_thryve_hint_leaving_app:
            '<b>Achtung</b> beim Klick auf den Verbindungslink des Trackingproviders verlässt du die App.',
        general_thryve_usage_sleep: 'Schlafdaten',
        general_thryve_usage_activity: 'Aktivitäten',
        general_thryve_usage_blood_pressure: 'Blutdruck & Puls',
        general_thryve_provider: 'Anbieter',
        general_administration_ratings: 'Bewertungen',
        general_whoops: 'Hoppla!',
        general_reload: 'Erneut laden',
        general_your_answer: 'Deine Antwort',
        general_no_data_provided_yet: 'Noch keine Daten eingetragen.',
        general_notification_general_test_notification: 'Test-Benachrichtigung',
        general_notification_general_modules_progress: 'Nächstes Modul',
        general_notification_actensio_missing_hypertension_journal_entry: 'Fehlende Einträge im Abendprotokoll',
        general_notification_actensio_missing_blood_pressure_entry: 'Fehlende Einträge im Blutdruckprotokoll',
        general_notification_actensio_missing_medication: 'Fehlende Medikamenteneinnahme-Protokolle',
        general_notification_somnio_missing_sleep_journal_entry: 'Fehlende Schlaftagebucheinträge',
        general_notification_somnio_missing_awake_journal_entry: 'Fehlende Abendprotokolleinträge',
        general_notification_somnio_jr_missing_sleep_journal_entry: 'Fehlende Schlaftagebucheinträge',
        general_notification_somnio_jr_missing_awake_journal_entry: 'Fehlende Abendprotokolleinträge',
        general_notification_glykio_weekly_planner_reminder: 'Nächste Termine',
        general_notification_glykio_protocols_reminder: 'Erinnerung an Tagebucheinträge',
        general_notification_glykio_perception_reminder: 'Erinnerung an Wahrnehmungstraining',

        handlebars_fitbit_success_title: 'Verbindung erfolgreich',
        handlebars_fitbit_success_message:
            'Dein Konto wurde erfolgreich mit fitbit verknüpft. Du kannst dieses Fenster schließen.',
        handlebars_mail_disable_notification_title_success: 'Abmeldung erfolgreich',
        handlebars_mail_disable_notification_message_success:
            'Du wirst keine weiteren Benachrichtigungen erhalten. Du kannst diese Einstellung im Profil deines Kontos anpassen.',
        handlebars_mail_disable_notification_title_error: 'Abmeldung nicht erfolgreich',
        handlebars_mail_disable_notification_message_error:
            'Der Link zum Abmelden der Benachrichtigungen ist nicht gültig. Du kannst diese Einstellung im Profil deines Kontos anpassen.',
        handlebars_generali_registration_failure_title: 'Generali Benutzer konnte nicht erstellt werden',
        handlebars_generali_registration_failure_message:
            'Es gab ein Problem beim Erstellen eines Kontos für einen Generali-Benutzer',
        handlebars_tk_registration_failure_title: 'TK-Benutzer konnte nicht erstellt werden',
        handlebars_tk_registration_failure_message:
            'Es gab ein Problem beim Erstellen eines Kontos für einen TK-Benutzer',
        handlebars_new_email_error_title: 'Bestätigung fehlgeschlagen',
        handlebars_new_email_error_message: 'Bestätigungslink für die neue E-Mail-Adresse ist ungültig oder abgelaufen',
        handlebars_new_email_success_title: 'Bestätigung erfolgreich',
        handlebars_new_email_success_message: 'Du kannst dich von nun an mit der neuen E-Mail-Adresse anmelden.',

        history_action_create: 'erstellt',
        history_action_update: 'aktualisiert',
        history_action_remove: 'löscht',
        history_action_anonymize: 'anonymisiert',
        history_entity_user: 'Benutzer',
        history_targeted_user: 'Zielnutzer',
        history_unidentified_user: 'unidentifizerter Nutzer',

        hint_leave_blank_to_keep: 'Leer lassen um Bestehendes zu behalten',
        hint_identifier: 'Ihr Name/Institution',
        hint_access_code: 'Vom Patient erstellter Zugangscode',
        hint_change_mail: 'Wenn du die E-Mail-Adresse änderst, wird ein Bestätigungslink an die neue Adresse geschickt',
        hint_change_mail_confirmation_pending: 'Bestätigung der neuen E-Mail-Adresse ausstehend',

        ht_tip_journal_measurement_time: 'Zeitpunkt an dem der Blutdruck gemessen wurde',

        isi_sleep_test: 'Schlaftest',
        isi_explain_why:
            'Um Sie in das für Sie passende Schlaftraining zu leiten, benötigen wir noch einige Informationen.',
        isi_question_one:
            'Bitte schätzen Sie ein, inwiefern Ihr Schlaf in den letzten beiden Wochen beeinträchtigt wurde durch ...',
        isi_question_two: 'Wie zufrieden/unzufrieden sind Sie mit Ihrem gegenwärtigen Schlaf?',
        isi_question_three:
            'Wie stark ist Ihre Leistungsfähigkeit (z.B. Konzentration, Gedächtnis) und Ihr Wohlbefinden (z.B. Müdigkeit, Stimmung) tagsüber durch Ihren (schlechten) Schlaf beeinträchtigt?',
        isi_question_four:
            'Wie stark glauben Sie, dass andere Personen die Auswirkung Ihres (schlechten) Schlafes auf Ihre Lebensqualität wahrnehmen?',
        isi_question_five: 'Inwiefern macht Ihnen Ihr (schlechter) Schlaf zur Zeit Sorgen?',
        isi_none: 'Gar nicht',
        isi_light: 'Leicht',
        isi_medium: 'Mittel',
        isi_severe: 'Schwer',
        isi_very_severe: 'Sehr schwer',
        isi_very_satisfied: 'Sehr zufrieden',
        isi_satisfied: 'Zufrieden',
        isi_neutral: 'Neutral',
        isi_unsatisfied: 'Unzufrieden',
        isi_very_unsatisfied: 'Sehr unzufrieden',
        isi_not_at_all: 'Überhaupt nicht',
        isi_a_little: 'Ein wenig',
        isi_moderate: 'Mäßig',
        isi_quite: 'Stark',
        isi_very: 'Sehr stark',
        isi_fall_asleep_issues: 'Einschlafstörungen',
        isi_sleep_through_issues: 'Durchschlafstörungen',
        isi_wake_up_early: 'Früherwachen',
        isi_sleep_satisfaction: 'Zufriedenheit',
        isi_impact_on_daily_life: 'Beeinträchtigung',
        isi_impact_on_quality_of_life: 'Wahrnehmung',
        isi_worries: 'Sorgen',

        insomnia_cycle_switchOff: 'Kann nicht abschalten',
        insomnia_cycle_pressure: 'Setze mich unter Druck',
        insomnia_cycle_consequences: 'Gedanken über Konsequenzen',
        insomnia_cycle_awake: 'Bett macht mich wach',
        insomnia_cycle_angry: 'Ärger',
        insomnia_cycle_body: 'Körperliche Reaktion',
        insomnia_cycle_exhaustion: 'Müde und Erschöpft',
        insomnia_cycle_performance: 'Leistungs- oder Konzentrationsschwierigkeiten',
        insomnia_cycle_depression: 'Niedergeschlagen',
        insomnia_cycle_noSleep: 'Wach im Bett',
        insomnia_cycle_sleepLonger: 'Im Bett liegen bleiben',
        insomnia_cycle_catchUp: 'Schlaf nachholen',

        journal_alcohol: 'Alkohol',
        journal_caffeine: 'Koffein',
        journal_fall_asleep_duration: 'Einschlafdauer',
        journal_personal_note: 'Persönliche Notiz',
        journal_entry_created: 'Eintragsdatum',
        journal_mood: 'Stimmung',
        journal_performance: 'Leistungsfähigkeit',
        journal_energy: 'Energie',

        sleep_relaxation_title: 'Progressive Muskelentspannung',
        sleep_relaxation_instruction:
            'Hier kannst du dir die progressive Muskelentspannung in aller Ruhe anhören/herunterladen',

        sleep_window_length: 'Länge des Schlaffensters',
        sleep_window_suggestion: 'Empfehlung',
        sleep_window_extend: 'Fenster verlängern',
        sleep_window_shorten: 'Fenster verkürzen',
        sleep_window_keep: 'Fenster beibehalten',
        sleep_window_no_data: 'Keine Daten für eine Empfehlung vorhanden',
        sleep_window_info_lengthen_shorten: 'Verlängerung/Verkürzung',
        sleep_window_info_process_a:
            'Je nachdem, wie deine durchschnittliche Schlafeffizienz in den letzten 7 Tagen ausgefallen ist, empfiehlt Albert das Beibehalten, Verlängern oder Verkürzen des Schlaffensters.',
        sleep_window_info_process_b:
            'Du startest mit dem Schlaffenster, das Albert dir im Modul Schlafzeiten vorgeschlagen hat. Ziel ist es, dieses Schlaffenster anhand der Empfehlungen Stück für Stück zu erweitern oder unter bestimmten Umständen auch wieder zu verkürzen, bis eine optimale Schlafzeit gefunden wurde.',
        sleep_window_info_extension_condition: 'Empfehlung für eine Verlängerung, wenn:',
        sleep_window_info_average_efficiency:
            'Deine durchschnittliche Schlafeffizienz über 85% in den letzten 7 Tagen war',
        sleep_window_info_average_efficiency_shorten:
            'Deine durchschnittliche Schlafeffizienz unter 80% in den letzten 7 Tagen war',
        sleep_window_info_your_efficiency: 'Deine Schlafeffizienz beträgt aktuell: $$%',
        sleep_window_info_shorten_condition: 'Empfehlung für eine Verkürzung, wenn:',
        sleep_window_info_additional_conditions:
            'Für eine Empfehlung zur Verkürzung/Verlängerung müssen zusätzlich folgende Bedingungen erfüllt sein:',
        sleep_window_info_minimum_entries: 'Mindestens 3 Schlaftagebucheinträge in den letzten 7 Tagen',
        sleep_window_info_missing_entries: 'Dir fehlen noch $$ Einträge',
        sleep_window_info_your_entries: 'Du hast $$ Einträge gemacht',
        sleep_window_info_last_extension:
            'Die letzte Verlängerung/Verkürzung des Schlaffenster liegt mindestens 7 Tage zurück',
        sleep_window_info_your_last_extension: 'Deine letzte Änderung erfolgte vor $$ Tagen',

        spoken_ordinal_high: 'starker Zusammenhang',
        spoken_ordinal_medium: 'mittelstarker Zusammenhang',
        spoken_ordinal_low: 'schwacher Zusammenhang',

        license_renewal_info_send_mail: 'Infos per E-Mail zusenden',
        license_renewal_info_header: 'Deine Lizenz läuft in $$ Tagen ab',
        license_renewal_info:
            'So erneuerst du deine Lizenz: <br> <ol><li> Wende dich an deine Krankenkasse und frage nach einer Folgelizenz.</li> <li> Du erhältst einen neuen 16-stelligen Code.</li><li> Verlängere deinen Zugang unter "Lizenz". </li></ol><br> <b>Du brauchst ein neues ärztliches Rezept?</b> <br> Frage deinen Arzt oder deine Ärztin nach einer Folgeverschreibung oder probiere es mit dem telemedizinischen Service der <a href="https://www.teleclinic.com/app/somnio/">Teleclinic</a>. <br><br> Wende dich bei Fragen gerne an unser Support-Team.',
        license_upgrade_how_to:
            '<ol><li>Suche das Gespräch mit deiner behandelnden ärztlichen oder psychotherapeutischen Fachperson und lass dir somnio verschreiben.</li> <li>Die Fachperson verordnet somnio (Rezept enthält “somnio” und die PZN 16898724).\n</li> <li>Reiche das Rezept bei deiner Krankenkasse ein.</li> <li>Die Krankenkasse generiert einen Freischaltcode für somnio und sendet ihn dir zu.</li> <li>Starte das Training in der App und schalte, unter der Rubrik “Lizenz”, somnio mit dem Code frei. Alternativ kannst du das Training auch im Browser nutzen unter <a href="https://app.somn.io">app.somn.io</a>.</li></ol> <b>Hinweis:</b> Du hast beim Upgrade die Möglichkeit, deine bereits vorhandenen Daten in die neue Version von somnio problemlos zu übertragen.',
        license_upgrade_how_to_header: '<b>Upgrade auf die DiGA (App auf Rezept) somnio*</b>',
        license_upgrade_how_to_somnio:
            '*Die verschreibungsfähige Version von somnio enthält erweiterte Funktionen und Inhalte, die bei einer Diagnose Insomnie empfohlen werden.',

        mail_notice_requires_html:
            'Bitte beachten Sie, dass Sie zur fehlerfreien Nutzung ein Mailprogramm benötigen, welches HTML-Nachrichten darstellen kann.',
        mail_hello: 'Hallo',
        mail_hello_formal: 'Guten Tag',
        mail_registration_activation_title: 'Willkommen bei $$',
        mail_registration_activation_generali_message: 'Vielen Dank für deine Registrierung.',
        mail_registration_activation_link_one_coaching:
            'Fast geschafft! Bitte vervollständige die Registrierung, indem du auf den folgenden Button klickst:',
        mail_registration_activation_link_multiple_coachings_hint:
            'Fast geschafft! Du bist dabei, $$ freizuschalten. Beachte dabei, dass du bereits ein Konto bei einer anderen, von uns bereitgestellten, digitalen Gesundheitsanwendung hast.<br>Zur Vereinfachung und um dir die doppelte Eingabe von Daten zu ersparen, verknüpfen wir die beiden Konten. Bitte verwende für das Login <b>dieselben Benutzerangaben (E-Mail und Passwort).',
        mail_registration_activation_alternative_to_coach_linking:
            'Solltest du der Verkettung nicht zustimmen, kannst du dich einfach mit einer anderen E-Mailadresse registrieren.',
        mail_registration_activation_link_multiple_coachings_click: 'Klicke hierzu bitte auf den folgenden Button:',
        mail_registration_activation_generali_link:
            'Sollte die Aktivierung im Browser fehlgeschlagen sein, kannst du den Vorgang mit diesem Link wiederholen:',
        mail_registration_activate_account: 'Registrierung abschließen',
        mail_registration_waiting_room:
            'Im Anschluss begleiten wir dich durch alle nötigen Schritte zur Freischaltung von $$.',
        mail_registration_activation_goodbye: 'Wir wünschen Dir ein erfolgreiches Training!',
        mail_app_download_tip: 'Hier kannst du die somnio App herunterladen',
        mail_request_new_password_title: 'Neues Passwort erstellen',
        mail_request_new_password_message:
            'Ein neues Passwort wurde für dein Konto angefordert. Falls du dies nicht veranlasst hast, kannst du diese Nachricht einfach ignorieren. Um ein neues Passwort zu wählen, klicke bitte auf den nachfolgenden Link:',
        mail_set_new_email_address_title: 'Neue E-Mail-Adresse bestätigen',
        general_new_email: 'Neue E-Mail-Adresse:',
        mail_set_new_email_address_message:
            'Du hast eine neue E-Mail-Adresse für dein Konto gesetzt. Um diese Änderung zu bestätigen, klicke bitte auf den nachfolgenden Link. Hinweis: Wenn du nicht $$ bist, dann hat jemand versucht deine Adresse zu verwenden. In diesem Fall kannst du diese Nachricht ignorieren und löschen.',
        mail_set_new_email_address_link: 'Bestätigen',
        mail_set_new_email_address_duplicate_title: 'Neue E-Mail-Adresse für bestehendes Konto',
        mail_set_new_email_address_duplicate_message:
            'Ein Benutzer möchte diese E-Mail-Adresse für sein Konto verwenden. Du hast jedoch bereits ein Konto unter dieser Adresse. Wenn du die Login-Daten vergessen hast, kannst du diese unter dem folgenden Link zurücksetzen.',
        mail_password_reset_title: 'Passwort zurückgesetzt',
        mail_password_reset_message: 'Dein Passwort wurde zurückgesetzt.',
        mail_send_new_password_title: 'Neues Passwort angefordert',
        mail_license_expiring_title: 'Deine Lizenz für $$',
        mail_license_expired_title: 'Deine Lizenz für $$',
        mail_license_expired_generali_message:
            'Wir möchten dich darauf hinweisen, dass deine Lizenz für das Schlaftraining abgelaufen ist. Vielen Dank, dass du teilgenommen hast. Wir hoffen, dass du mit dem Programm zufrieden warst und wünschen Dir weiterhin einen guten, erholsamen Schlaf!',
        mail_license_expiration_pipedrive_title: 'Hinweis: Ihre Testlizenz endet in $$ Tagen',
        mail_license_expiration_pipedrive:
            'aufgrund der Vorgaben des Heilmittelwerbegesetzes ist der Testzeitraum für digitale Gesundheitsanwendungen leider begrenzt. Hiermit möchten wir Sie daran erinnern, dass Ihnen Ihr persönlicher Zugang noch $$ Tage zur Verfügung steht. Damit Sie sich einen umfassenden Eindruck machen können, haben Sie in Ihrem Profil die Möglichkeit, unter dem Reiter "Demo-Aktionen" alle Module sofort freizuschalten.',
        mail_license_expiration_pipedrive_footer: 'Viel Spaß beim Testen wünscht Ihnen <br><br> Ihr somnio Team.',
        mail_set_new_password: 'Neues Passwort erstellen',
        mail_registered_not_logged_in_title: 'Deine Registrierung bei $$',
        mail_two_factor_authentication_title: 'Zwei-Faktor-Authentifizierung',
        mail_two_factor_authentication_message: 'Hier ist dein Code für die Zwei-Faktor-Authentifizierung:',
        mail_unsubscribe_newsletter_text:
            'Du wünschst keine weiteren Benachrichtigungen per E-Mail von $$ zu erhalten? Dann klicke bitte ',
        mail_unsubscribe_newsletter_link: 'hier',
        mail_module_open_not_started_title: 'Offene Module in $$',
        mail_module_open_not_started_body:
            'Es gibt Neuigkeiten: In $$ wurde ein neues Modul freigeschaltet! Klicke auf <a href="$$">$$</a>, um direkt mit dem Modul $$ zu starten. Viel Spaß!',
        mail_module_started_not_completed_title: 'Nicht abgeschlossenes Modul in $$',
        mail_module_started_not_completed_body:
            'Wir haben gesehen, dass du kürzlich das Modul $$ angefangen, aber noch nicht abgeschlossen hast. Mit Klick auf <a href="$$">$$</a>, kannst du das Modul fortsetzen und somit abschließen. Viel Spaß!',
        mail_inactivity_warning_title: 'Wir vermissen dich!',
        mail_inactivity_warning_body:
            'Wir haben dich schon seit längerer Zeit nicht mehr gesehen. Denke daran, dass die mit deinem Konto verbundenen Daten unwiderruflich gelöscht werden, wenn du dich bis am $$ nicht mehr im Programm anmeldest.',
        mail_news: 'Aktuelles',
        mail_contact_us:
            'Bei weiteren Fragen kannst du dich gerne via <a href="mailto:support@mementor.de">support@mementor.de</a> an uns wenden <br><br> Dein mementor-Team',
        mail_contact_us_formal:
            'Bei weiteren Fragen können Sie sich gerne via <a href="mailto:support@mementor.de">support@mementor.de</a> an uns wenden <br><br> Ihr mementor-Team',
        mail_contact_us_formal_reply_to_support:
            'Sie können den Support via <a href="mailto:support@mementor.de">support@mementor.de</a> erreichen <br><br> Ihr mementor-Team',
        mail_payment_voucher_bought_title: 'Ihr Code für somnio',
        mail_payment_voucher_bought_message:
            'Willkommen bei somnio, Ihrem Schlaftraining für einen guten und erholsamen Schlaf! Wir haben Ihre Zahlung erhalten und Sie können loslegen:',
        mail_payment_voucher_bought_step_1:
            'Klicken Sie <a href="$$">hier</a>, um für somnio ein neues Konto zu erstellen',
        mail_payment_voucher_bought_step_2: 'Als Lizenzcode können Sie den unten aufgeführten Code eingeben',
        mail_payment_voucher_bought_step_3: 'Danach können Sie mit somnio starten.',
        mail_payment_voucher_bought_step_4:
            'Hinweis: die Ausführung des Vertrags beginnt bereits vor Ablauf der Widerrufsfrist. Mit diesem Kauf verzichten Sie ausdrücklich auf das 14 tätige Widerrufsrecht.',
        mail_payment_voucher_bought_footer1: 'Wir wünschen Ihnen einen guten Start und vor allem einen guten Schlaf!',
        mail_payment_voucher_bought_code: 'Ihr Lizenzcode: ',
        mail_duplicate_registration_title: 'Konto existiert bereits',
        mail_duplicate_registration_message:
            'Du hast eben versucht, dich zu registrieren. Wir haben bereits ein Konto mit deiner E-Mail-Adresse. Du kannst dich',
        mail_duplicate_registration_message_b: 'einloggen. Hast du Probleme mit dem Login? Du kannst du dein Passwort',
        mail_duplicate_registration_message_c: 'zurücksetzen',
        mail_demo_account: 'Testlizenz somnio',
        mail_demo_account_welcome:
            'wir freuen uns sehr, dass Sie sich für <b>somnio</b> interessieren. Mit dieser E-Mail erhalten Sie Ihre persönliche Testlizenz und somit die Möglichkeit, sich mit unserem digitalen Schlaftraining vertraut zu machen.',
        mail_your_license_code: 'Ihr persönlicher Zugangscode lautet:',
        mail_demo_account_details:
            'Nach dem Einlösen des Codes steht Ihnen die Anwendung für 14 Tage zur Verfügung. Sie erhalten einen Demo-Zugang, in dem Sie alle Module direkt freischalten und die angezeigten Wartezeiten entfernen können. Diese Einstellung kann in der App unter der Rubrik "Demo-Aktionen" in Ihrem Profil vorgenommen werden.',
        mail_registration: 'Registrierung',
        mail_demo_account_closure:
            'Wir wünschen Ihnen viel Freude. Bei Fragen oder Anregungen können Sie uns gerne jederzeit kontaktieren: <a href="mailto: medical@mementor.de">medical@mementor.de</a> oder 0341 581 444 33.',
        mail_flyer_confirmation: 'somnio: Eingang Ihrer Bestellung von Flyern',
        mail_flyer_confirmation_details_a:
            'Vielen Dank für Ihr Interesse am digitalen Schlaftraining somnio. Die Flyer für Ihre Patient:innen senden wir Ihnen in Kürze an die angegebene Adresse.',
        mail_flyer_confirmation_details_b:
            'Wir empfehlen, den Patient:innen bei der Verordnung einen Flyer auszuhändigen, so dass sie mit den nötigen Informationen zum Programm ausgestattet und über die nächsten Schritte informiert sind und zugleich Kontaktdaten von uns vorliegen haben, unter denen sie sich bei etwaigen Fragen und Problemen melden können.',
        mail_flyer_confirmation_details_c:
            'Falls Sie das Programm noch nicht selbst ausprobiert haben, können Sie <a href="https://somn.io/doc/#test">hier</a> einen kostenfreien zweiwöchigen Testaccount für Fachpersonen beantragen.',
        mail_flyer_subject: 'Patientenflyer somnio',
        mail_certificate_title: 'Glückwunsch!',
        mail_certificate_download_body:
            'Du hast $$ abgeschlossen und dabei viel Motivation und Durchhaltevermögen bewiesen - Du kannst stolz auf dich sein! In der Profilansicht kannst du dir nun dein Teilnahmezertifikat herunterladen.',
        mail_certificate_download_link: 'Zum Zertifikat',
        mail_certificate_download_goodbye:
            'Wir wünschen dir alles Gute für die Zukunft!<br><br>Herzliche Grüße, das mementor Team.',
        mail_personal_counseling_body:
            'falls du ein persönliches Support-Gespräch mit einer Fachperson wünschst, so kannst du unter folgendem Link einen Termin buchen: <br><br> <a href="https://my.calenso.com/book/mementor/somnio/support-session@mementor.de">Termin buchen</a> <br><br> Im Feld für Bemerkungen, kannst du dein Anliegen gern kurz benennen. Für das Support-Gespräch zu technischen Fragen ist ein zeitlicher Rahmen von bis zu 10 Minuten vorgesehen. Für das Support-Gespräch zu inhaltlichen Fragen sind bis zu 20 Minuten vorgesehen. Du wirst dann zum vereinbarten Zeitpunkt angerufen.',
        mail_personal_counseling_title: 'Dein Support-Gespräch',
        mail_hint_noreply_address_informal:
            'Dies ist eine automatisch generierte Nachricht. Bitte antworte nicht darauf - die E-Mail wird nicht gelesen oder weiterverarbeitet.',
        mail_hint_noreply_address_formal:
            'Dies ist eine automatisch generierte Nachricht. Bitte antworten Sie nicht darauf - die E-Mail wird nicht gelesen oder weiterverarbeitet.',
        mail_service_call_offer:
            'aller Anfang ist schwer. Daher wollten wir uns bei dir erkundigen und fragen, wie die ersten Tage mit actensio gelaufen sind? Was lief gut, was weniger? Brauchst du eventuell noch einen kleinen Motivationsschub oder ein offenes Ohr, dem du von deinen Erfahrungen berichten möchtest? <br><br> Dann kannst du gern ein 20-minütiges Supportgespräch mit einer*einem Mitarbeiter*in vereinbaren. Unter folgendem Link kannst du deinen Telefontermin buchen. <br> <br> <a href="https://my.calenso.com/book/mementor/actensio-content">Zum Kalender</a> <br><br> Wir rufen dich zum vereinbarten Zeitpunkt zurück.',
        mail_service_call_offer_subject: 'Buch dein persönliches Supportgespräch',
        mail_upgrade_to_somnio_info:
            'Da dein Schlaf-Score auf eine Insomnie hindeutet, empfehlen wir dir die <b>Vollversion von somnio, die auf Rezept erhältlich ist</b>. So erhältst du somnio: <ol> <li>Suche das Gespräch mit deiner behandelnden ärztlichen oder psychotherapeutischen Fachperson und lass dir somnio verschreiben. </li> <li> Die Fachperson verordnet somnio (Rezept enthält “somnio” und die PZN 16898724). </li> <li>Reiche das Rezept bei der Techniker Krankenkasse (TK) ein.</li> <li>Die Krankenkasse generiert einen Freischaltcode für somnio.</li> <li>Starte das Training in der App und schalte, unter der Rubrik “Lizenz”, somnio mit dem Code frei. Alternativ kannst du das Training auch im Browser nutzen unter <a href="https://app.somn.io.">https://app.somn.io</a>.  </li> </ol> <i> Hinweis: Du hast dabei die Möglichkeit, deine bereits vorhandenen Daten in die neue Version von somnio problemlos zu übertragen.  </i> <br> <br> Nun kannst du die ersten Schritte mit der Vollversion von somnio unternehmen, um die Symptome deiner Schlafstörung zu reduzieren und deinen Schlaf nachhaltig zu verbessern.',
        mail_upgrade_to_somnio_info_title: 'somnio erhalten',
        mail_brand_ambassador_request_title: 'Werden Sie Markenbotschafter:in',
        mail_brand_ambassador_request_thank_you: 'vielen Dank für Ihre positive Rückmeldung.',
        mail_brand_ambassador_request_profited_from_application:
            'Wir freuen uns, dass Sie von somnio profitieren konnten.',
        mail_brand_ambassador_request_description:
            'Die Firma mementor DE GmbH, als Hersteller der DiGAs somnio und actensio, bekommt hin und wieder Presseanfragen bezüglich Interviews von Betroffenen, die im Print, Audio, Online und/oder Videoformat veröffentlicht werden. Aus diesem Grund würden wir uns freuen, wenn wir Ihre Kontaktinformationen für eine solche Anfrage bei uns hinterlegen dürfen, um Sie in Zukunft für solche Anfragen als möglichen Interviewpartner hinzuzuziehen. Mit Hilfe Ihrer Stimme erreichen wir weitere Betroffene, die nach Lösungen für Ihre gesundheitlichen Einschränkungen suchen und noch keine unserer Anwendungen ausprobiert haben. Wenn Sie mit einer Aufnahme Ihrer Kontaktinformationen einverstanden sind, genügt eine kurze Rückantwort auf diese E-Mail, in der Sie eindeutig Ihre Zustimmung äußern (z.B. mit den Formulierungen "Hiermit gebe ich mein Einverständnis" oder "Hiermit willige ich ein"). Nachfolgend erhalten Sie alle Hinweise bezüglich unseres Vorgehens beim Datenschutz.',
        mail_final_interview_title: 'Einladung zum Abschlussgespräch',
        mail_final_interview_explanation:
            'falls du ein persönliches Abschlussgespräch mit einer Fachperson wünschst, kannst du unter folgendem Link einen Termin buchen:',
        mail_final_interview_link: 'https://my.calenso.com/book/mementor/somnio-finalinterview',
        mail_final_interview_book_appointment: 'Termin buchen',
        mail_final_interview_description:
            'Du wirst dann zum vereinbarten Zeitpunkt angerufen. Sofern du magst, kannst du bereits im Feld “Bemerkungen” erste Gedanken zum Programm mit uns teilen.',
        mail_final_interview_timeframe: 'Für das Gespräch ist ein zeitlicher Rahmen von 20 Minuten vorgesehen.',
        mail_multiple_partner_registrations_title: 'Hinweis zu Ihrer Registrierung',
        mail_multiple_partner_registrations_explanation:
            'Sie haben versucht, sich für eine Mementor-App zu registrieren. Sie sind jedoch bereits unter dieser Adresse bei einer unserer Apps registriert. Bitte kontaktieren Sie den Support.',
        mail_internal_support_multiple_partner_registrations_title: 'Multiple Partner Registrations',
        mail_internal_support_multiple_partner_registrations_description:
            'a user with a partner registration tried to register another coaching.',
        mail_internal_support_multiple_partner_registrations_partner: 'The user is registered with this partner: ',
        mail_internal_support_multiple_partner_registrations_existing: 'The user has following coaching(s): ',
        mail_internal_support_multiple_partner_registrations_new: 'The user tried to register this coaching: ',

        // actensio-specific mailing messages
        mail_actensio_license_expiring_message_non_diga:
            'Wir möchten dich darüber informieren, dass deine Lizenz für actensio in $$ Tagen abläuft. Nach dem Ablauf der Lizenz benötigst du einen neuen Lizenzcode, um dich wieder einloggen zu können.',
        mail_actensio_license_expiring_message_diga:
            'wir möchten dich darüber informieren, dass deine Lizenz für actensio in $$ Tagen abläuft. Um nach dem Ablauf deiner Lizenz weiterhin Zugriff auf deine Tagebücher, die Modulinhalte sowie die regelmäßige Datenauswertung zu haben, benötigst du einen <b>neuen Rezeptcode</b>. Diesen kannst du dir von deinem Arzt/ deiner Ärztin, Therapeut:in oder einem Telemedizinanbieter ausstellen lassen.<br>Über den genauen Ablauf des Verschreibungsprozesses kannst du dich <a href="https://actens.io/#access">hier</a> informieren.',
        mail_actensio_license_expired_message_non_diga:
            'Wir möchten dich darauf hinweisen, dass deine Lizenz für actensio abgelaufen ist. Vielen Dank, dass du teilgenommen hast. Wir hoffen, dass du mit dem Programm zufrieden warst und wünschen Dir weiterhin gute Gesundheit!',
        mail_actensio_license_expired_message_diga:
            '<p>wir möchten dich darauf hinweisen, dass deine Lizenz für actensio abgelaufen ist. Um nach dem Ablauf deiner Lizenz weiterhin Zugriff auf ddie Modulinhalte sowie die regelmäßige Datenauswertung zu haben, benötigst du einen <b>neuen Rezeptcode</b>. Diesen kannst du dir von deinem Arzt/ deiner Ärztin, Therapeut:in oder einem Telemedizinanbieter ausstellen lassen.<br>Über den genauen Ablauf des Verschreibungsprozesses kannst du dich <a href="https://actens.io/#access">hier</a> informieren.</p><p>Wir hoffen, dass du mit dem Programm zufrieden warst und wünschen dir weiterhin gute Gesundheit! Schön, dass du teilgenommen hast.</p>',
        mail_actensio_registered_not_logged_in_body:
            'Willkommen bei actensio! Du hast dich registriert, jedoch die Module nicht bearbeitet. Auf <a href="https://app.actens.io">https://app.actens.io</a> kannst du die Bearbeitung der Module starten. Wenn du Probleme beim Einloggen hast, kannst du gerne unseren Support <a href="mailto: support@mementor.de">kontaktieren</a>. Bitte beachte, dass dein Konto ohne Login infolge von Inaktivität nach 30 Tagen gelöscht wird.',
        mail_actensio_not_started_first_module_warning_title:
            "Los geht's: Starte jetzt das erste Modul mit deinem digitalen actensio Coach!",
        mail_actensio_not_started_first_module_warning_body:
            'Dein <b>digitaler Coach Albert</b> erwartet dich! Beginne unter <a href="https://app.actens.io">https://app.actens.io</a> jetzt dein erstes Modul, um dein <b>Wohlbefinden am Tag zu steigern</b>.<br><br>Solltest du Probleme beim <b>Einloggen</b> haben, melde dich bei uns unter <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_actensio_journal_reminder_title: 'Tagebucheintrag actensio',
        mail_actensio_journal_reminder_body:
            'Wir haben gesehen, dass du in den letzten $$ Tagen keinen Tagebucheintrag vorgenommen hast. Dein letzter Eintrag stammt vom $$. Die Daten aus dem Tagebuch sind die Grundlage für das Training. Es ist daher sehr wichtig, dass du jeden Tag das Protokoll ausfüllst.',
        mail_actensio_journal_reminder_hint_tracker:
            'Du hast für dein Training einen $$-Tracker eingebunden. Leider konnten die Daten dafür nicht automatisch übernommen werden. Das kann folgende Gründe haben:<ul><li>Die Daten deines Trackers ($$) konnten nicht an die App des Trackers synchronisiert werden (bitte manuell durchführen in der $$ App)</li><li>Der Akku des Trackers war ungenügend geladen bei der Messung</li><li>Der Tracker ist erst seit Kurzem verbunden</li><li>Die Einwilligung zur Datenübermittlung im Konto von $$ umfasst nicht alle benötigten Werte oder wurde zurückgezogen</li><li>Manchmal kann die Messung nicht vorgenommen werden, wenn der Tracker zu lose getragen wird</li><li>Ältere Tracker können nicht alle Daten in geforderten Präzision liefern</li></ul><br><br>Bitte trage deshalb für die fehlenden Tagen die Daten manuell nach.',

        // somnio-specific mailing messages
        mail_somnio_license_expiring_message_non_diga:
            'Wir möchten dich darüber informieren, dass deine Lizenz für somnio in $$ Tagen abläuft. Nach dem Ablauf der Lizenz benötigst du einen neuen Lizenzcode, um dich wieder einloggen zu können.',
        mail_somnio_license_expiring_message_diga:
            'wir möchten dich darüber informieren, dass deine Lizenz für somnio in $$ Tagen abläuft. Um nach dem Ablauf deiner Lizenz weiterhin Zugriff auf dein Schlaftagebuch, dein Schlaffenster, die Modulinhalte sowie die regelmäßige Datenauswertung zu haben, benötigst du einen <b>neuen Rezeptcode</b>. <br> Da bei dir bereits eine Indikation vorliegt, kannst du deine Folgelizenz direkt bei deiner Krankenkasse beantragen. In der Regel erhältst du innerhalb weniger Tage einen neuen Lizenzcode. Wende dich hierfür am besten direkt an deine Krankenkasse. <br><br> Durch eine Folgelizenz verlängert sich das Schlaftraining um weitere 90 Tage. Die Daten bleiben erhalten und sind wieder verfügbar, sobald der Zugang erneuert wird. <br><br>Sollte deine Krankenkasse ein neues Rezept verlangen, so müsstest du erneut bei deiner/deinem behandelnden Arzt/Ärztin oder Therapeut:in vorstellig werden und anschließend das Rezept bei deiner Krankenkasse einreichen. <br><br> Alternativ kannst du auch den Service eines Telemedizinanbieters z.B <a style="color: #009688; text-decoration: none; font-weight: 500" href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">TeleClinic</a> nutzen.',
        mail_somnio_license_expired_message_non_diga:
            'Wir möchten dich darauf hinweisen, dass deine Lizenz für somnio abgelaufen ist. Vielen Dank, dass du teilgenommen hast. Wir hoffen, dass du mit dem Programm zufrieden warst und wünschen Dir weiterhin einen guten, erholsamen Schlaf!',
        mail_somnio_license_expired_message_diga:
            '<p>wir möchten dich darauf hinweisen, dass deine Lizenz für somnio abgelaufen ist. Um nach dem Ablauf deiner Lizenz weiterhin Zugriff auf dein Schlaftagebuch, dein Schlaffenster, die Modulinhalte sowie die regelmäßige Datenauswertung zu haben, benötigst du einen <b>neuen Rezeptcode</b>. Diesen kannst du dir von deinem Arzt/ deiner Ärztin, Therapeut:in oder einem Telemedizinanbieter ausstellen lassen.<br>Über den genauen Ablauf des Verschreibungsprozesses kannst du dich <a href="https://somn.io/#access">hier</a> informieren.</p><p>Wir hoffen, dass du mit dem Programm zufrieden warst und wünschen dir weiterhin einen guten, erholsamen Schlaf! Schön, dass du teilgenommen hast.</p>',
        mail_somnio_registered_not_logged_in_body:
            'Willkommen beim somnio Schlaftraining! Du hast dich registriert, jedoch die Module nicht bearbeitet. Auf <a href="https://app.somn.io">https://app.somn.io</a> kannst du die Bearbeitung der Module starten. Wenn du Probleme beim Einloggen hast, kannst du gerne unseren Support <a href="mailto: support@mementor.de">kontaktieren</a>. Bitte beachte, dass dein Konto ohne Login infolge von Inaktivität nach 30 Tagen gelöscht wird.',
        mail_somnio_not_started_first_module_warning_title:
            "Los geht's: Starte jetzt das erste Modul mit deinem digitalen Schlafcoach!",
        mail_somnio_not_started_first_module_warning_body:
            'Dein <b>digitaler Schlafcoach Albert</b> erwartet dich! Beginne unter <a href="https://app.somn.io">https://app.somn.io</a> jetzt dein erstes Modul, um <b>erholsamer zu schlafen</b> und dein <b>Wohlbefinden am Tag zu steigern</b>.<br><br>Solltest du Probleme beim <b>Einloggen</b> haben, melde dich bei uns unter <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_somnio_journal_reminder_title: 'Schlaftagebucheinträge somnio',
        mail_somnio_journal_reminder_body:
            'Wir haben gesehen, dass du in den letzten $$ Tagen keinen Schlaftagebucheintrag vorgenommen hast. Dein letzter Eintrag stammt vom $$. Die Daten aus dem Schlaftagebuch sind die Grundlage für das Schlaftraining. Es ist daher sehr wichtig, dass du jeden Tag das Schlaf- und Wachprotokoll ausfüllst.',
        mail_somnio_journal_reminder_hint_tracker:
            'Du hast für dein Training einen $$-Tracker eingebunden. Leider konnten die Daten dafür nicht automatisch übernommen werden. Das kann folgende Gründe haben:<ul><li>Die Daten deines Trackers ($$) konnten nicht an die App des Trackers synchronisiert werden (bitte manuell durchführen in der $$ App)</li><li>Der Akku des Trackers war ungenügend geladen bei der Messung</li><li>Der Tracker ist erst seit Kurzem verbunden</li><li>Die Einwilligung zur Datenübermittlung im Konto von $$ umfasst nicht alle benötigten Werte oder wurde zurückgezogen</li><li>Manchmal kann die Messung nicht vorgenommen werden, wenn der Tracker zu lose getragen wird</li><li>Ältere Tracker können nicht alle Daten in geforderten Präzision liefern</li></ul><br><br>Bitte trage deshalb für die fehlenden Tagen die Daten manuell nach.',

        mail_waiting_room_reminder_subject_1:
            'Fast geschafft! Nur noch wenige Schritte und du kannst mit somnio starten!',
        mail_waiting_room_reminder_part_1:
            'Glückwunsch! Du hast dich dazu entschlossen, deine Ein- und Durchschlafstörungen anzugehen und dich auf den Weg zu einem gesunden und erholsamen Schlaf zu begeben. Die gute Nachricht: Nur noch wenige Schritte trennen dich davon! ',
        mail_waiting_room_reminder_part_2:
            '<b>So erhältst du Zugang zu somnio:</b> <br> <ol><li>Du hast dich in der somnio App oder der Browser Anwendung von somnio erfolgreich registriert. &#10004;</li><li>Um somnio endlich starten zu können, benötigst du noch eine Rezeptverordnung für somnio durch einen Arzt/Ärztin oder Psychotherapeut:in. Mache dir einen Termin vor Ort bei bei der Fachperson deiner Wahl (Arzt/Ärztin, Psychotherapeut:in), oder buche dir einen Online-Termin bei einem Telemedizinanbieter (z.B. bei <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> oder <a href="https://www.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>), sprich deine Ein- und Durchschlafstörungen an und erzähle von somnio. Auf unserer Webseite <a href="https://www.somn.io">somn.io</a>  findest du dafür umfassendes Informationsmaterial z.B. eine <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">Infokarte</a> für deinen Arzt/Ärztin oder Psychotherapeut:in.</li>  ',
        mail_waiting_room_reminder_part_3: '<b>Du hast somnio per Kassenrezept verschrieben bekommen?</b>',
        mail_waiting_room_reminder_part_4:
            '<ol start="3"><li>Super, dann kannst du dein Rezept innerhalb von 30 Tagen nach Ausstellung bei deiner Krankenkasse einreichen. Das geht bei den meisten ganz bequem per App oder über die jeweilige Homepage. Alle gesetzlichen und die meisten privaten Krankenkassen übernehmen die Kosten für somnio - <b>für dich ist somnio kostenlos! </b></li> <li>Nachdem du das Rezept eingereicht hast, erhältst du von deiner Krankenkasse den <b>16-stelligen Freischaltcode</b> für somnio zugeschickt. Dann heißt es nur noch: Eingeben und loslegen! </li></ol> ',
        mail_waiting_room_reminder_part_5:
            '<b>Denke daran:</b> Solltest du dich nicht bis zum $$ im Programm angemeldet haben, werden die mit deinem Konto verbundenen Daten unwiderruflich gelöscht. <br> <b>Guter Schlaf ist erlernbar.</b>',
        mail_waiting_room_reminder_subject_2:
            'Endlich durchschlafen? Nur noch wenige Schritte und du kannst mit somnio starten!',
        mail_waiting_room_reminder_part_1_2:
            'Glückwunsch! Vor einigen Tagen hast du dich bei somnio registriert, weil du endlich wieder durchschlafen möchtest. Bisher hast du somnio allerdings noch nicht gestartet. Dabei fehlen dir nur noch wenige Schritte, damit du von gutem Schlaf nicht nur träumen kannst!',
        mail_waiting_room_reminder_part_2_2:
            '<b>Hier noch einmal zur Erinnerung - dein schneller Weg zu somnio:</b> <br> <ol><li>Du hast dich in der somnio App oder der Browser Anwendung von somnio erfolgreich registriert. &#10004;</li><li>Um somnio endlich starten zu können, benötigst du noch eine Rezeptverordnung für somnio durch einen Arzt/Ärztin oder Psychotherapeut:in. Mache dir einen Termin vor Ort bei bei der Fachperson deiner Wahl (Arzt/Ärztin, Psychotherapeut:in), oder buche dir einen Online-Termin bei einem Telemedizinanbieter (z.B. bei <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> oder <a href="https://www.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>), sprich deine Ein- und Durchschlafstörungen an und erzähle von somnio. Auf unserer Webseite <a href="https://www.somn.io">somn.io</a>  findest du dafür umfassendes Informationsmaterial z.B. eine <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">Infokarte</a> für deinen Arzt/Ärztin oder Psychotherapeut:in.</li>  ',
        mail_waiting_room_reminder_subject_3: 'Wie wichtig ist dir ein gesunder Schlaf?',
        mail_waiting_room_reminder_part_1_3:
            'Hallo! Vor einigen Wochen hast du dich bei somnio registriert, weil du endlich wieder durchschlafen möchtest. Bisher hast du somnio allerdings immer noch nicht gestartet. <br> Wir fragen uns: Haben sich für dich bisher Probleme ergeben oder ist dir der Verordnungsprozess von somnio noch nicht ganz klar?',
        mail_waiting_room_reminder_part_2_3:
            '<b>Hier noch einmal zur Erinnerung - dein schneller Weg zu somnio:</b> <br> <ol><li>Du hast dich in der somnio App oder der Browser Anwendung von somnio erfolgreich registriert. &#10004;</li><li>Mache dir für die Rezeptverordnung von somnio einen Termin vor Ort bei bei der Fachperson deiner Wahl (Arzt/Ärztin, Psychotherapeut:in) oder buche dir innerhalb weniger Stunden einen Online-Termin bei einem Telemedizinanbieter (z.B. bei <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> oder <a href="https://www.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>)</li>  ',
        mail_waiting_room_reminder_part_4_3:
            '<ol start="3"><li>Super, dann kannst du dein Rezept innerhalb von 30 Tagen nach Ausstellung bei deiner Krankenkasse einreichen. Das geht meistens per App oder über die jeweilige Homepage.</li> <li>Danach erhältst du von deiner Krankenkasse den<b>16-stelligen Freischaltcode</b> für somnio zugeschickt. <br> Dann heißt es nur noch: Eingeben und loslegen! </li></ol> ',

        monitor_access_title: 'Zugriffanfrage bestätigen',

        meditation_exercise_title: 'Atem-Meditation',
        meditation_exercise_instruction:
            'Während dieser Übung fokussierst du dich für einige Minuten darauf, wie dein Atem im Rhythmus der Animation ein- und wieder ausströmt.',
        meditation_exercise_completed: 'Meditation abgeschlossen',
        meditation_exercise_completed_write_to_journal:
            'Meditation abgeschlossen. Zeit ($$ Minuten) in dein Tagebuch übertragen?',
        meditation_exercise_write_to_journal: 'Übertragen',
        meditation_exercise_start: 'Starten',
        meditation_exercise_end: 'Beenden',

        lottie_no_insomnia: 'Keine Insomnie',
        lottie_light_insomnia: 'Leichte Insomnie',
        lottie_moderate_insomnia: 'Moderate Insomnie',
        lottie_severe_insomnia: 'Schwere Insomnie',
        lottie_week: 'Woche',
        lottie_overall: 'Gesamt',

        licensing_title: 'Lizenzen',
        licensing_no_license_found: 'Keine Lizenzen gefunden',
        licensing_credit_debit_card: 'Kredit- oder Debitkarte',
        licensing_credit_debit_card_submit: 'Kostenpflichtig erwerben',
        licensing_valid_until: 'Gültig bis',
        licensing_trial: 'Evaluationslizenz (veraltet)',
        licensing_module_not_licensed: 'Keine gültige Lizenz für dieses Modul gefunden',
        licensing_voucher_creation: 'Voucher Erstellung',
        licensing_user_registration: 'Nutzer Registrierung',
        licensing_coaching_activation: 'Coaching Aktivierung',

        notification_coaching_saved: 'Coaching gespeichert',
        notification_coaching_saved_error: 'Coaching konnte nicht gespeichert werden',
        notification_coaching_removed: 'Coaching gelöscht',
        notification_coaching_removed_error: 'Coaching konnte nicht gelöscht werden',
        notification_coaching_published: 'Coaching veröffentlicht',
        notification_coaching_published_error: 'Coaching konnte nicht veröffentlicht werden',
        notification_version_created: 'Neue Version erstellt',
        notification_version_created_error: 'Neue Version konnte nicht erstellt werden',
        notification_coaching_version_updated: 'Update auf neuste Version erfolgreich',
        notification_coaching_version_updated_error: 'Update auf neuste Version nicht erfolgreich',
        notification_socket_disconnect: 'Du bist offline. Verbindungsaufbau...',
        notification_socket_reconnect: 'Verbindung zum Server wiederhergestellt',
        notification_session_expired: 'Sitzung abgelaufen, bitte melde dich neu an',
        notification_step_add: "Schritt '$$' hinzugefügt",
        notification_step_remove: "Schritt '$$' entfernt",
        notification_step_remove_count: '$$ Schritte entfernt',
        notification_step_update: "Schritt '$$' gespeichert",
        notification_step_save_error: 'Schritt konnte nicht gespeichert werden',
        notification_journal_entry_saved: 'Eintrag gespeichert',
        notification_journal_entry_removed: 'Eintrag gelöscht',
        notification_journal_entry_removed_error: 'Eintrag konnte nicht gelöscht werden',
        notification_text_localised: 'Text lokalisiert',
        notification_animation_saved: 'Animation "$$" gespeichert',
        notification_animation_saved_error: 'Animation konnte nicht gespeichert werden',
        notification_animation_removed: 'Animation "$$" entfernt',
        notification_medication_saved: 'Medikament "$$" gespeichert',
        notification_medication_saved_error: 'Medikament konnte nicht gespeichert werden',
        notification_medication_removed: 'Medikament "$$" entfernt',
        notification_module_saved: 'Modul gespeichert',
        notification_module_saved_error: 'Modul konnte nicht gespeichert werden',
        notification_module_removed: 'Modul entfernt',
        notification_module_removed_error: 'Modul konnte nicht entfernt werden',
        notification_module_published: 'Modul veröffentlicht',
        notification_module_published_error: 'Modul konnte nicht veröffentlicht werden',
        notification_answer_container_saved: 'Antwortbehälter "$$" gespeichert',
        notification_answer_container_removed: 'Antwortbehälter "$$" gelöscht',
        notification_user_saved: 'Benutzer "$$" gespeichert',
        notification_user_saved_error: 'Benutzer "$$" konnte nicht gespeichert werden',
        notification_user_removed: 'Benutzer "$$" gelöscht',
        notification_user_removed_error: 'Benutzer "$$" konnte nicht gelöscht werden',
        notification_user_anonymized: 'Benutzer "$$" anonymisiert',
        notification_user_anonymized_error: 'Benutzer konnte nicht "$$" anonymisiert werden',
        notification_profile_saved: 'Profil gespeichert',
        notification_profile_saved_error: 'Profil konnte nicht gespeichert werden',
        notification_profile_email_updated: 'Ein Bestätigungslink wurde an die neue E-Mail-Adresse geschickt',
        notification_cannot_edit_published: 'Veröffentlichte Module können nicht bearbeitet werden',
        notification_module_locked: 'Das Modul "$$" wurde von $$ blockiert',
        notification_module_unlocked: 'Das Modul "$$" wurde von $$ freigegeben',
        notification_provider_saved: 'Provider "$$" gespeichert',
        notification_provider_saved_error: 'Provider "$$" konnte nicht gespeichert werden',
        notification_provider_removed: 'Provider "$$" entfernt',
        notification_provider_removed_error: 'Provider "$$" konnte nicht entfernt werden',
        notification_fitbit_connected: 'Verbindung mit fitbit hergestellt',
        notification_fitbit_disconnected: 'Verbindung mit fitbit getrennt',
        notification_copy_clipboard: 'In Zwischenablage kopiert',
        notification_generic_success: 'Operation erfolgreich',
        notification_generic_error: 'Fehler beim Durchführen der Operation',
        notification_authorization_get_error: 'Fehler beim Laden der Zugangscodes',
        notification_authorization_saved: 'Zugang gespeichert',
        notification_authorization_saved_license_limit_reached: 'Zugang gespeichert. Valide bis Lizenzende.',
        notification_authorization_saved_error: 'Fehler bei der Erstellung des Zugangs',
        notification_authorization_removed: 'Zugang gelöscht',
        notification_authorization_removed_error: 'Fehler bei der Löschung des Zugangs',
        notification_idle_logout: 'Automatischer Logout aufgrund von Inaktivität',
        notification_idle_logout_warning: 'Inaktivität entdeckt, Logout in $$ Minute(n)',
        notification_password_saved: 'Passwort gespeichert',
        notification_password_saved_error: 'Passwort konnte nicht gespeichert werden',
        notification_system_notification_saved: 'Systemnachricht "$$" gespeichert',
        notification_system_notification_removed: 'Systemnachricht gelöscht',
        notification_sleep_window_saved: 'Schlaffenster gespeichert',
        notification_sleep_window_saved_error: 'Fehler bei der Speicherung des Schlaffensters',
        notification_code_redeemed: 'Voucher eingelöst',
        notification_voucher_saved: 'Voucher erfolgreich gespeichert',
        notification_voucher_saved_error: 'Voucher konnte nicht gespeichert werden',
        notification_vouchers_export_error: 'Fehler beim Voucherexport',
        notification_vouchers_manual_processing_started:
            'Manuelle Verarbeitung gestartet. Dies kann eine Weile dauern...',
        notification_vouchers_manual_processing_success: 'Erfolgreich $$ Vouchers manuell verarbeitet',
        notification_vouchers_manual_processing_error: 'Fehler beim manuellen Verarbeiten von Vouchers',
        notification_sync_vouchers_success: 'Vouchers erfolgreich synchronisiert',
        notification_sync_vouchers_error: 'Fehler beim Synchronisieren',
        notification_voucher_generic_error: 'Fehler beim Einlösen des Codes',
        notification_diga_code_check_download_success: 'Prüfreport erfolgreich erstellt',
        notification_diga_code_check_download_error: 'Prüfreport konnte nicht erstellt werden',
        notification_xRechnung_send_success: 'X-Rechnung erfolgreich übermittelt',
        notification_xRechnung_send_error: 'Fehler beim Übermitteln der X-Rechnung',
        notification_voucher_removed: 'Voucher wurde entfernt',
        notification_voucher_removed_error: 'Voucher konnte nicht entfernt werden',
        notification_secure_logout: 'Sicher abgemeldet',
        notification_profile_export_error: 'Profil konnte nicht heruntergeladen werden',
        notification_role_added: 'Rolle hinzugefügt',
        notification_role_added_error: 'Rolle konnte nicht hinzugefügt werden',
        notification_role_removed: 'Rolle entfernt',
        notification_role_removed_error: 'Rolle konnte nicht entfernt werden',
        notification_all_modules_unlocked: 'Alle Module freigeschaltet',
        notification_all_modules_unlocked_error: 'Module konnten nicht freigeschaltet werden',
        notification_wait_times_removed: 'Alle Wartezeiten wurden entfernt',
        notification_wait_times_removed_error: 'Wartezeiten konnten nicht entfernt werden',
        notification_license_saved: 'Lizenz gespeichert',
        notification_license_saved_error: 'Lizenz konnte nicht gespeichert werden',
        notification_demo_entries_generated: 'Demo-Daten wurden erstellt',
        notification_demo_entries_generated_error: 'Demo-Daten konnten nicht erstellt werden',
        notification_sleep_journal_visual_analysis_success:
            'Visuelle Auswertung der Schlaftagebucheinträge erfolgreich erstellt',
        notification_sleep_journal_visual_analysis_error:
            'Visuelle Auswertung der Schlaftagebucheinträge konnte nicht erstellt werden',
        notification_sleep_journal_excel_export_success: 'Download der Schlaftagebucheinträge erfolgreich',
        notification_sleep_journal_excel_export_failure: 'Fehler beim Export der Schlaftagebucheinträge',
        notification_resent_activation_email: 'E-Mail zur Kontoaktivierung wurde versandt',
        notification_resent_activation_email_error: 'E-Mail zur Kontoaktivierung konnte nicht versendet werden',
        notification_contact_support_error: 'Nachricht konnte nicht versendet werden',
        notification_journal_summary_error: 'Zusammenfassung konnte nicht geladen werden',
        notification_sleep_window_min_length: 'Die minimale Länge des Fensters beträgt $$ Stunden',
        notification_sleep_window_max_length: 'Die maximale Länge des Fensters beträgt $$ Stunden',
        notification_goals_saved: 'Deine Ziele wurden gespeichert',
        notification_payrexx_sync_success: 'Synchronisation erfolgreich',
        notification_payrexx_sync_failure: 'Synchronisation fehlgeschlagen',
        notification_medication_plan_saved: 'Medikationsplan gespeichert',
        notification_medication_plan_updated: 'Medikationsplan aktualisiert',
        notification_medication_plan_saved_error: 'Fehler beim Speichern des Medikationsplans',
        notification_medication_plan_removed: 'Medikationsplan gelöscht',
        notification_questionnaire_saved_success: 'Fragebogen gespeichert',
        notification_questionnaire_saved_error: 'Fehler beim Speichern des Fragebogens',
        notification_questionnaire_removed_success: 'Fragebogen gelöscht',
        notification_questionnaire_removed_error: 'Fragebogen konnte nicht gelöscht werden',
        notification_journal_update_meditation: 'Meditationsdauer wurde in das Abenprotokoll übertragen',
        notification_web_authn_connected_error: 'Login ohne Passwort konnte nicht eingerichtet werden',
        notification_web_authn_login_error: 'Login ohne Passwort fehlgeschlagen',
        notification_device_deregistration_error: 'Gerät konnte nicht entfernt werden',
        notification_credential_rename_error: 'Bezeichnung des Geräts konnte nicht geändert werden',
        notification_cannot_edit_step: 'Modul blockiert, Schritt kann derzeit nicht bearbeitet werden',
        notification_exercise_saved: 'Übung gespeichert',
        notification_exercise_save_error: 'Fehler beim Speichern der Übung',
        notification_operation_not_supported: 'Funktion derzeit nicht unterstützt',
        notification_userNotFound: 'Benutzer konnte nicht gefunden werden',
        notification_health_id_login_success: 'Erfolgreich angemeldet mit Gesundheits-ID',
        notification_health_id_login_error: 'Fehler beim Anmelden mit Gesundheits-ID',
        notification_health_id_activation_success: 'Gesundheits-ID wurde erfolgreich aktiviert',

        report_hypertension_journal_entries: 'Blutdruckeinträge',
        report_dash_score: 'DASH-Score',
        report_blood_pressure: 'Blutdruckwerte',
        report_activity: 'Bewegung',
        report_activity_low: 'Geringe Intensität',
        report_activity_medium: 'Mittlere Intensität',
        report_activity_high: 'Hohe Intensität',
        report_nutrition_protocol: 'Ernährungsprofil',
        report_nutrition_protocol_info: 'Ernährungsprofil der letzten zwei Wochen basierend auf $$ Einträgen',
        report_alcohol_consumption: 'Alkoholkonsum',
        report_cigarettes_consumption: 'Zigarettenkonsum',
        report_relaxation_title: 'Achtsamkeits- und Entspannungsübungen',
        report_relaxation: 'Achtsamkeit',
        report_other_technique: 'Andere Technik',
        report_medication: 'Medikament',
        report_number_of_minutes: 'Anzahl Minuten',
        report_portion: 'Portionen',
        report_piece: 'Stück',
        report_dosage_ie: 'IE (Internationale Einheit)',
        report_dosage_others: '',

        speech_no_data: ' - dazu habe ich keine Daten.',

        // sbk
        sbk_fields_salutation: 'Anrede',
        sbk_fields_title: 'Titel',
        sbk_fields_first_name: 'Vorname',
        sbk_fields_last_name: 'Nachname',
        sbk_fields_policy_number: 'Policennummer',
        sbk_fields_date_of_birth: 'Geburtsdatum',
        sbk_fields_insurance: 'Versicherung',
        sbk_fields_phone: 'Telefonnummer',
        sbk_fields_accept_ad_approval:
            'Ich bin damit einverstanden, dass die SBK mich zur Einholung von Feedback zur Aktion telefonisch oder schriftlich per Post oder per E-Mail kontaktieren sowie meine Daten zu diesem Zweck verarbeiten darf',
        sbk_fields_terms: 'Ich stimme den Bestimmungen der SBK zu',
        sbk_fields_data_terms: 'Datenschutzbestimmungen',
        sbk_fields_participation_terms: 'Teilnahmebedingungen',
        sbk_fields_impressum: 'Impressum',
        sbk_save_personal_data: 'Personalien speichern',
        sbk_load_personal_data_error: 'Personalien konnten nicht geladen werden',
        sbk_must_confirm_new_campaign:
            'Mit bestehenden Anmeldedaten aus einer früheren Gesundheitsförderungsaktion der SBK teilnehmen.',
        sbk_must_click_confirmation_link:
            'Erfolgreich registriert für Schlafgut. Bitte klicken Sie zur Bestätigung den Link, welcher Ihnen soeben an die Mailadresse geschickt wurde.',
        sbk_must_set_password: 'Zum Aktivieren müssen Sie noch ein Passwort setzen.',
        sbk_activation_succeeded_login_now: 'Ihr Konto wurde erfolgreich aktiviert. Sie können sich nun einloggen.',
        sbk_report_to_upload_triggered: 'Der Report wird generiert und an die SBK-Schnittstelle gesendet',
        sbk_can_login_now: 'Sie können sich nun einloggen',
        sbk_register_with_existing_credentials: 'Bitte registrieren Sie sich mit Ihren bestehenden Zugangsdaten',
        sbk_register_using_link: 'Bitte registrieren Sie sich über den Link zur Kampagne',
        sbk_or_login: 'Mit bestehenden Anmeldedaten aus einer früheren Gesundheitsförderungsaktion der SBK teilnehmen.',
        sbk_app_name: 'Schlaf gut!',
        sbk_app_lead: 'Mit mehr Energie in den Tag',
        sbk_claim: 'Wir sind auf <br> deiner Seite',
        sbk_campaign_has_expired:
            'Die Aktion für die Sie sich registriert haben, ist deaktiviert. Bitte registrieren Sie sich für eine neue Aktion mit Ihren bestehenden Login-Daten über einen Registrierungslink, den Sie von der SBK erhalten',
        sbk_recommend: 'SBK empfehlen',
        sbk_recommend_detail:
            'Schön, dass Du an unserer Aktion "Schlaf gut!" teilnimmst. Lass doch Freunde, Bekannte oder Kollegen ebenfalls davon profitieren und empfehle uns weiter! Wir bedanken uns dafür mit attraktiven Prämien!',
        sbk_recommend_link: 'Einfach hier klicken!',

        sbk_fields_terms_1: 'Ja, ich habe die ',
        sbk_fields_terms_2: ' und den ',
        sbk_fields_terms_3:
            'für die SBK-Aktion „Schlaf gut“ gelesen und bin mit der dort beschriebenen Verarbeitung meiner personenbezogenen Daten einverstanden.',
        sbk_fields_invalid_list: 'Die folgenden Felder sind ungültig:',

        tip_add_answer: 'Antwort hinzufügen',
        tip_remove_answer: 'Antwort entfernen',
        tip_drag_to_reorder: 'Ziehen um neu zu ordnen',
        tip_edit: 'Bearbeiten',
        tip_details: 'Details',
        tip_next_week: 'Nächste Woche',
        tip_previous_week: 'Vorherige Woche',
        tip_user_settings: 'Verwalte dein Konto',
        tip_replay: 'Erneut abspielen',
        tip_back: 'Diesen Schritt noch einmal abspielen',
        tip_select_animation: 'Animation auswählen',
        tip_add_condition: 'Kondition hinzufügen',
        tip_remove_condition: 'Kondition entfernen',
        tip_save_in_answer_container: 'Behälter, in den die Antwort gespeichert wird',
        tip_answer_key: 'Bezeichnung des Schlüssels, unter dem die Antwort gespeichert wird',
        tip_answer_type: 'Erwartetes Format der Antwort',
        tip_answer_list_items: 'Liste der erlaubten Antworten',
        tip_next_step: 'Nächster Schritt, falls Kondition stimmt',
        tip_localised_answer_text: 'Übersetzter Antwort-Text',
        tip_answer_value: 'Bei der Auswahl der Antwort zu speichernder Wert',
        tip_copy_to_clipboard: 'In Zwischenablage kopieren',
        tip_pause: 'Pause',
        tip_play: 'Wiedergabe',
        tip_skip: 'Überspringen',
        tip_one_step_back: 'Zurück zum vorherigen Schritt',
        tip_duplicate: 'Duplizieren',
        tip_required_entries_details: 'Anzahl benötigter Tagebucheinträge, um das nächste Modul zu öffnen',
        tip_clear_animation: 'Animation entfernen',
        tip_journal_entry_date: 'Das Datum sollte auf den Tag fallen, an dem du aufgestanden bist',
        tip_journal_mood: 'Stimmung seit der letzten Schlafphase',
        tip_questionnaire_name:
            'Der Schlüssel des Fragebogens kann nur einmal gesetzt und danach nicht mehr geändert werden',

        validation_input_query: 'Ist Ihre Eingabe korrekt?',
        validation_input_query_coaching: 'Ist deine Eingabe korrekt?',
        validation_required: 'Pflichtangabe',
        validation_missing_value: 'Eingabe fehlt',
        validation_valid_email: 'Gültige Adresse',
        validation_invalid_email: 'Ungültige Adresse',
        validation_valid_password: 'Gültiges Passwort',
        validation_password_too_short: 'Passwort zu kurz',
        validation_password_too_long: 'Passwort zu lang (Max. 30 zeichen)',
        validation_password_requirements_not_met: 'Das Passwort entspricht nicht den Kriterien',
        validation_min_length: 'Mindestens $$ Zeichen',
        validation_max_length: 'Maximal $$ Zeichen',
        validation_license_error_general: 'Fehler',
        validation_time_invalid: 'Ungültige Zeit',
        validation_date_invalid: 'Ungültiges Datum',
        validation_repeated_password_mismatch: 'Passwörter stimmen nicht überein',
        validation_must_accept_terms: 'Bitte akzeptiere die AGB und DSE',
        validation_must_accept_pp: 'Bitte akzeptiere die DSE',
        validation_required_to_access: 'Voraussetzung für die Nutzung des Programms',
        validation_password_wrong: 'Dein altes Passwort ist nicht korrekt',
        validation_invalid_expression: 'Ungültiger Ausdruck',
        validation_one_answer_required: 'Mindestens eine Antwort erforderlich',
        validation_invalid_license_code: 'Ungültiger Lizenzcode',
        validation_license_code: 'Lizenz-Code',
        validation_diga_code: 'Rezeptcode',
        validation_min_chars_criteria: 'Enthält mind. 8 Zeichen',
        validation_special_char_criteria: 'Enthält ein Sonderzeichen',
        validation_upper_case_criteria: 'Enthält einen Großbuchstaben',
        validation_date_in_future: 'Datum kann nicht in der Zukunft liegen',
        validation_too_short: 'Eingabe zu kurz',
        validation_no_match_email: 'E-Mail stimmt nicht überein',
        validation_min: 'Mindestens $$',
        validation_max: 'Maximal $$',
        validation_negative_number: 'Keine negativen Werte',

        welcome_title: 'Willkommen!',
        welcome_next_steps: "So geht's weiter",
        welcome_skip_modules: 'Module überspringen',
        welcome_text_generic: 'Wir freuen uns, dich auf deinem Weg begleiten zu dürfen.',
        welcome_text_sleep: 'Wir freuen uns, dich auf dem Weg zu einem besseren Schlaf begleiten zu dürfen.',
        welcome_text_b: 'Klicke in der Modulübersicht einfach auf das erste Modul, um das Training zu starten.',
        welcome_text_c:
            'Dies ist ein <b>Demo-Konto</b>. Im Profil kannst du alle Module freischalten und weitere Aktionen auslösen.',
        welcome_text_d:
            'Module, die du im letzten Training bereits durchlaufen hast, kannst du nach Start des Moduls einfach mit einem Klick überspringen.',

        // actensio notification keys
        notification_hypertension_journal_excel_export_success: 'Download der Hypertonie-Tagebucheinträge',
        notification_hypertension_journal_excel_export_failure: 'Fehler beim Export der Hypertonie-Tagebucheinträge',
        // actensio export keys
        general_data_export_hypertension_journals_xlsx_sheet_user_mail: 'Benutzer',
        general_data_export_hypertension_journals_xlsx_title: 'Export von Hypertonie-Tagebucheinträgen',
        general_data_export_hypertension_journals_xlsx_details:
            'Die Einträge, die du im Hypertonie-Tagebuch erfasst hast, sind hier verfügbar im Excel-Format.',
        general_data_export_hypertension_journals_xlsx_sheet_title: 'Hypertonie-Tagebucheinträge',
        general_data_export_hypertension_journals_xlsx_sheet_date_created: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_nr: 'Eintrag Nr.',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_date: 'Messzeitpunkt',
        general_data_export_hypertension_journals_xlsx_sheet_header_created_at: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_fruits: 'Obst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_vegetables: 'Gemüse',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_whole_grain: 'Vollkorn',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_low_fat_milk: 'Fettarme Milchprodukte',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_good_fats:
            'Nüsse, Hülsenfrüchte & Ölsaaten',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_salt: 'Salz',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_red_meat: 'Fettiges/rotes Fleisch',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_sugar: 'Stark gezuckerte Lebensmittel',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_dash_score: 'DASH-Score',
        general_data_export_hypertension_journals_xlsx_sheet_header_from_tracker: 'Von Fitnesstracker geladen',

        general_data_export_hypertension_journals_xlsx_sheet_header_medication: 'Medikament',
        general_data_export_hypertension_journals_xlsx_sheet_header_medication_dosage: 'Dosierung',

        general_data_export_hypertension_journals_xlsx_sheet_header_activity_low_intensity: 'Geringe Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_medium_intensity: 'Mittlere Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_high_intensity: 'Hohe Intensität',

        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_mindfulness: 'Achtsamkeit',
        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_other_meditation:
            'Andere Entspannungstechnik',

        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_alcohol: 'Alkoholische Getränke',
        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_cigarettes: 'Zigaretten',

        general_data_export_hypertension_journals_xlsx_blood_pressure_protocol_title: 'Export BlutdruckProtokoll',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_source: 'Quelle',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_location: 'Ort der Messung',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_has_complaints: 'Beschwerden',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_updated_time: 'Datum aktualisiert',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_systolic: 'Systolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_diastolic: 'Diastolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_pulse: 'Puls',

        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_paralysis: 'Lähmung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_speechImpediment: 'Sprachstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_visualImpairment: 'Sehstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_headache: 'Kopfschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_chestPain: 'Brustschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_breathlessness: 'Atemnot',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_dizziness: 'Schwindel',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_tinnitus: 'Tinnitus',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_noseBleed: 'Nasenbluten',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_home: 'Zuhause',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_doctor: 'Arztpraxis',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_pharmacy: 'Apotheke',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_manual: 'Manuell',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_thryve: 'Von Thryve',

        high_systolic_blood_pressure_warning:
            'Du hast einen systolischen <b>Blutdruck über 180</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br> Ist dein Blutdruck bei <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_diastolic_blood_pressure_warning:
            'Du hast einen diastolischen  <b>Blutdruck über 120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_systolic_and_diastolic_blood_pressure_warning:
            'Du hast einen <b>Blutdruck über 180/120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den  <b>ärztlichen Notdienst</b>.',
        low_blood_pressure_warning:
            'Deine Blutdruckwerte sind ungewöhnlich <b>niedrig</b>. <br> Bitte halte mit deinem behandelnden Arzt oder deiner behandelnden Ärztin Rücksprache über diese Werte',

        aria_label_module_dashboard_active: 'Modul $$, aktiv dargestellt',
        aria_label_module_dashboard_inactive: 'Modul $$, inaktiv dargestellt',
        aria_label_menu_open: 'Menü öffnen,',
        aria_label_menu_close: 'Menü schließen,',
        aria_label_sleep_window: 'Dein Schlaffenster ist von $$ Uhr bis $$ Uhr',
        aria_label_sleep_window_button_active: 'Button zum $$ des Schalffensters aktiv dargestellt',
        aria_label_sleep_window_button_inactive: 'Button zum $$ des Schalffensters inaktiv dargestellt',
        aria_label_sleep_journal_diagram:
            'Hier siehst du das Diagramm mit deiner Bettzeit, Schlafzeit und deiner Schlafeffizienz.',
        aria_label_add_journal_entry: 'Über das Plus-Zeichen im Diagramm kannst du einen neuen Eintrag machen',
        aria_label_add_entry: 'Eintrag hinzufügen',
        aria_label_date_picker: 'Eingabe Datum im Format DD.MM.YYYY',
        aria_label_time_picker: 'Eingabe Uhrzeit im Format HH:MM',
        aria_label_invalid_fields: 'Folgende Eingaben fehlen',

        // entry warning message bits
        input_message_with_value:
            "Du hast gerade eine '$$' angegeben, die 12 Stunden überschreitet. <br> <br> Bist du sicher, dass das stimmt? Bitte beachte, dass Du die Zeit im 24-Stunden-Format angibst.",
        journal_fall_asleep_duration_warning: 'Einschlafdauer',
        journal_sleep_time_warning: 'Schlafzeit',
        journal_time_in_bed_warning: 'Zeit im Bett',
        journal_entry_warning_and: 'und',
    },
    fr_ch: {
        blot_function: 'Function',
        blot_value: 'Value',
        blot_speech: 'Speech',
        blot_animation: 'Animation',
        blot_coach: 'Coach',
        blot_expressionValue: 'Expression value',
        blot_bracket: 'Bracket',
        blot_comparator: 'Comparator',
        blot_logic: 'Logic',
        blot_evaluation: 'Évaluation',
        blot_menu: 'Menu',
        blot_custom: 'Custom',

        breathing_inhale: 'Inspirer',
        breathing_exhale: 'Expirer',

        body_scan_title: 'Body Scan',
        body_scan_instruction: 'Ici, tu peux écouter / télécharger le body scan',

        concat_fallAsleepFaster: "S'endormir plus vite",
        concat_betterSleepThrough: 'Mieux maintenir le sommeil',
        concat_laterWakeUp: 'Se réveiller plus tard',
        concat_sleepLonger: 'Dormir plus longtemps',
        concat_betterSleepQuality: 'Meilleure qualité du sommeil',
        concat_moreEnergy: "Plus d'énergie",

        evaluation_completed_content: 'Contenus du programme complétés',
        evaluation_connected_to: 'Connecté au',
        evaluation_energy_during_day: 'Énergie pendant la journée',
        evaluation_energy_during_day_legend: 'Énergie pendant la journée (0 = peu, 10 = beaucoup)',
        evaluation_fall_asleep_time: 'Délai d’endormissement', // Einschlafdauer // Endormi(e) après
        evaluation_fitness_tracker: 'Tracker d’activité',
        evaluation_insomnia_type: 'Type de sommeil',
        evaluation_insomnia_type_A: 'Type A',
        evaluation_insomnia_type_A_info: 'Principalement des problèmes d’endormissement',
        evaluation_insomnia_type_B: 'Type B',
        evaluation_insomnia_type_B_info: 'Principalement des problèmes de maintien du sommeil',
        evaluation_insomnia_type_C: 'Type C',
        evaluation_insomnia_type_C_info: 'Principalement des problèmes des réveils précoces',
        evaluation_insomnia_type_mixedAB: 'Mixte (AB)',
        evaluation_insomnia_type_mixedABC: 'Mixte (ABC)',
        evaluation_insomnia_type_mixedABC_info:
            'Principalement des problèmes d’endormissement, de maintien du sommeil et des réveils précoces',
        evaluation_insomnia_type_mixedAB_info:
            'Principalement des problèmes d’endormissement et de maintien du sommeil',
        evaluation_insomnia_type_mixedAC: 'Mixte (AC)',
        evaluation_insomnia_type_mixedAC_info: 'Principalement des problèmes d’endormissement et des réveils précoces',
        evaluation_insomnia_type_mixedBC: 'Mixte (BC)',
        evaluation_insomnia_type_mixedBC_info:
            'Principalement des problèmes de maintien du sommeil et des réveils précoces',
        evaluation_insomnia_type_tooltip: 'Nature des problèmes de sommeil',
        evaluation_isi_end: 'ISI-Fini:',
        evaluation_isi_end_tooltip: "ISI-Score du module 'Clôture'",
        evaluation_isi_follow_up: 'ISI-Suivi ',
        evaluation_isi_follow_up_tooltip: "ISI-Score du module 'Suivi'",
        evaluation_isi_score_high: 'Indication d’insomnie clinique (sévère)',
        evaluation_isi_score_light: 'Indication d’insomnie sub-clinique (légère)',
        evaluation_isi_score_low: 'Absence d’insomnie',
        evaluation_isi_score_moderate: 'Indication d’insomnie clinique (modérée)',
        evaluation_isi_start: 'ISI-Début:',
        evaluation_isi_start_tooltip: 'ISI-Score du module ‘Introduction’',
        evaluation_number_of_hours: "Nombre d'heures",
        evaluation_number_of_minutes: 'Nombre de minutes',
        evaluation_number_of_modules: 'Nombre de modules',
        evaluation_relevant_params: 'Paramètres importants',
        evaluation_sleep_efficiency: "L'efficacité du sommeil",
        evaluation_sleep_efficiency_explain_text:
            "L'efficacité du sommeil est le rapport entre la durée du sommeil et le temps passé au lit.",
        evaluation_sleep_journal_entries: 'Entrées dans le journal de sommeil',
        evaluation_blood_sugar_journal_entries: 'Blutzuckereinträge',
        evaluation_sleep_journal_entries_split: 'Entrées dans le journal de sommeil',
        evaluation_sleep_type: 'Type d’insomnie',
        evaluation_sleep_type_definitiveLark: 'L’alouette absolue',
        evaluation_sleep_type_definitiveLark_info: 'Type du matin (pic d’énergie le matin)',
        evaluation_sleep_type_definitiveNightOwl: 'L’hibou absolu',
        evaluation_sleep_type_definitiveNightOwl_info: 'Type du soir (pic d’énergie le soir)',
        evaluation_sleep_type_moderateLark: 'L’alouette modérée',
        evaluation_sleep_type_moderateLark_info: 'Type du matin modéré (pic d’énergie dans la matinée)',
        evaluation_sleep_type_moderateNightOwl: 'L’hibou modéré',
        evaluation_sleep_type_moderateNightOwl_info:
            'Type du soir modéré (pic d’énergie en fin d’après-midi ou le soir)',
        evaluation_sleep_type_neutralType: 'Neutre',
        evaluation_sleep_type_neutralType_info: 'Type ni du soir ni du matin',
        evaluation_sleep_type_tooltip: 'Chronotype',
        evaluation_start_date: 'Date de départ',
        evaluation_subjective_sleep_quality: 'Qualité subjective du sommeil',
        evaluation_sleep_time: 'Temps de sommeil',
        evaluation_time_in_bed: 'Temps passé au lit',
        evaluation_time_in_bed_and_sleep_time: 'Temps passé au lit et temps de sommeil',
        evaluation_unknown_device: 'Appareil inconnu',
        evaluation_wake_time_during_night: 'Temps d’éveil nocturne', // Durée d'éveil?
        evaluation_wake_time_in_bed_after_wake_up: 'Temps d’éveil passé au lit après le réveil',
        evaluation_average_sleep_efficiency_of_last_seven_days:
            "L'efficacité de ton sommeil (moyenne des sept derniers jours) est de",
        evaluation_sleep_rhythm: 'Rythme veille-sommeil',

        error_code_429: 'Trop de tentatives, attendre et réessayer',
        error_code_500: 'Erreur de serveur inconnue',
        error_code_1001: 'Opération non autorisée',
        error_code_3000: 'La connexion a échoué',
        error_code_9001: 'Adresse e-mail ou mot de passe non valide',
        error_code_9002: "Utilisateur n'a pas pu être trouvé",
        error_code_9003: "Erreur de connexion suite à l'authentification externe",
        error_code_9004: 'Authentification externe a échoué',
        error_code_9005: 'Manque du consentement au traitement de données. Contacter le service d’assistance ',
        error_code_9006: 'Cette adresse e-mail est déjà utilisée',
        error_code_9007:
            "Ton compte n'a pas encore été activé. Clique sur le lien qui t'a été envoyé par mail après l'enregistrement.",
        error_code_9008: "L'ancien mot de passe n'est pas correct.",
        error_code_9009: "Lien d'activation est invalide ou le compte a déjà été activé",
        error_code_9010: "Le compte n'a pas pu être mis à jour",
        error_code_9011: "Erreur lors de l'activation - réessayer",
        error_code_9012:
            "Ne pas se connecter via  Google. Utilise la même méthode de connexion que pour l'enregistrement.",
        error_code_9013: 'Jeton de réinitialisation non valide',
        error_code_9015: 'Deuxième facteur nécessaire',
        error_code_9016: 'Code non valide, reconnecte-toi',
        error_code_9022: 'Code non valide',
        error_code_9023: 'Le code a déjà été utilisé',
        error_code_9024: 'Le code ne donne pas droit à une licence',
        error_code_9025: 'Tu as fourni un code de réduction',
        error_code_9026: "Impossible d'initialiser la licence",
        error_code_9027: 'Il existe déjà une licence du même type',
        error_code_9029: 'Il existe déjà un coaching avec cette clé',
        error_code_9039: "L'entrée chevauche une entrée existante",
        error_code_9040: 'Le code ne peut pas être utilisé plusieurs fois pour le même compte',
        error_code_9042: 'Votre demande a été rejetée',
        error_code_9043: "L'utilisateur n'est pas joignable actuellement",
        error_code_9047: 'Code de licence non valable',
        error_code_9048: 'Code de réduction au lieu du code de licence',
        error_code_9049: 'Code de licence épuisé',
        error_code_9051: 'Le bon est déjà utilisé',
        error_code_9053: "Tu n'es pas autorisé(e) à télécharger ce fichier",
        error_code_9054: 'Aucun code de licence fourni',
        error_code_9055: 'Code DiGA non valide: veuillez nous contacter',
        error_code_9056: 'Code DiGA déja utilisé',
        error_code_9057: "Code de licence n'est pas encore valable",
        error_code_9058: 'Code de licence expiré',
        error_code_9059: 'Objet non valable',
        error_code_9060: "Code DiGA n'a pas pu être validé",
        error_code_9061: "XRechnung pour code DiGA n'a pas pu être transmise",
        error_code_9062: "Erreur de communication avec l'interface DiGA",
        error_code_9063: 'XRechnung a déjà été envoyée ou payée',
        error_code_9064: 'XRechnung a déjà été enregistrée dans sevDesk',
        error_code_9066:
            "Désolé, pas assez de données disponibles - veuillez exécuter à nouveau le module d'introduction.",
        error_code_9069: "Coaching n'a pas pu être trouvé",
        error_code_9070: "Compte non valide pour l'application actuelle",
        error_code_9071: "Le code de licence n'est pas prévu pour l'application actuelle.",
        error_code_9080: "Médicament n'a pas pu être trouvé.",
        error_code_9081:
            "Impossible d'enregistrer la fenêtre de sommeil - recharger la page ou redémarrer l'application et réessayer.",
        error_code_9082: 'Veuillez vérifier la connexion internet et réessayer',
        error_code_9083: 'Données incomplètes : évaluation graphique impossible',
        error_code_9085: 'Adresse IP pas dans la whitelist',
        error_code_9086:
            "Si ton compte n'est pas encore activé, tu ne pourras modifier ton adresse e-mail que pendant une courte période.",
        error_code_9089: 'Échec de la mise à niveau',
        error_code_9090: "Le downgrade n'est pas autorisé. Veuillez contacter notre support",
        error_code_9091: "La configuration sur l'appareil a échoué : $$",
        error_code_9092:
            "Échec de la configuration : cet appareil a-t-il déjà été enregistré ? Essaye de réinstaller l'application.",
        error_code_9207: 'No next module id found',
        error_code_9208: 'Could not found a module step',
        error_code_9211: 'No module step or no entry module found',
        error_code_9214: 'Found more than one module step for criteria',
        error_code_undefined: 'Erreur inconnue',
        error_code_general: 'Erreur',

        // iOS Native
        ios_message_failure: "Impossible d'envoyer un message à iOS",

        // sbk: no need to translate to French
        error_code_9100: 'Erreur de communication inconnue',
        error_code_9101: "Jeton d'activation inconnu",
        error_code_9102: "Jeton d'activation non valable",
        error_code_9103: "Jeton d'activation a expiré",
        error_code_9104: "Création d'un nouveau mot de passe nécessaire",
        error_code_9105: "Informations d'identification non valides",
        error_code_9106: "Veuillez vous enregistrer avec vos informations d'identification existantes",
        error_code_9107: "La campagne n'a pas encore commencé",
        error_code_9108: 'La campagne a été déactivée',
        error_code_9109: 'Le consentement au traitement des données a été retiré',
        error_code_9110: "Le compte n'a pas encore été activé",
        error_code_9111: "Il n'y a pas eu de consentement au traitement des données",
        error_code_9112: 'Vous êtes déjà enregistré(e) dans une autre campagne',
        error_code_9113: 'Enregistrement est fermé',
        error_code_9114: 'Adresse e-mail inconnue',
        error_code_9115: 'ID de campagne incorrecte',
        error_code_9116: 'Veuillez respecter le délai minimal entre deux demandes de réinitialisation du mot de passe',
        error_code_9117: "Veuillez respecter le délai minimal entre deux demandes d'un lien d'activation",
        error_code_9118: "Champs d'enregistrement non valides",
        error_code_9119: 'Le compte a déjà été activé',
        error_code_9097: "La mise à jour n'est pas légale.",

        // thryve
        error_code_9200: 'Il existe déjà une connexion avec un tracker',
        error_code_9201: "Aucun tracker n'est connecté",
        error_code_9202:
            'Une tentative de connexion a été faite il y a quelques minutes, veuillez attendre quelques minutes avant de réessayer.',

        // journals
        error_code_9300: 'Il existe déjà une entrée à cette date.',

        doc_access_title: 'Accès pour le personnel de santé',
        doc_access_patient_data_info:
            "Les données d'évaluation d'un(e) patient(e) peuvent être récupérées avec un code d'accès à 6 chiffres. L'accès nécessite la confirmation manuelle de la patiente/du patient.",
        doc_waiting_for_patient: 'Attendre la confirmation de la patiente/du patient',

        general_complete: 'Finaliser',
        general_device_name: "Nom de l'appareil (p.ex. Mon portable)",
        general_created: 'Créé',
        general_last_used: 'Dernier usage',
        general_welcome_back: 'Bienvenue à nouveau !',
        general_not_now: 'Pas maintenant',
        general_manual: "Mode d'emploi",
        general_worries: 'Préoccupations',
        general_overload: 'Surcharge',
        general_leisure: 'Loisirs',
        general_daily_life: 'Quotidien',
        general_work: 'Travail',
        general_summary: 'Résumé (PDF)',
        general_all_summaries: 'Tous les résumés',
        general_max_number_of_files: 'Nombre maximale de fichiers : $$',
        general_size_limit: 'Taille maximale de fichier : $$ MB',
        general_file_type_not_allowed: "Ce type de fichier n'est pas autorisé",
        general_and: 'et',
        general_answerFromSelection: 'Texte de la réponse',
        general_cigarette: 'cigarette',
        general_cigarettes: 'cigarettes',
        general_euro: 'euro',
        general_euros: 'euros',
        general_week: 'Semaine',
        general_weeks: 'semaines',
        general_mmHg: 'millimètres de mercure',
        general_bpm: 'battements par minute',
        general_mmHg_abr: 'mmHg',
        general_bpm_abr: 'bpm',
        general_entry: 'entrée',
        general_entries: 'entrées',
        general_piece: 'pièce',
        general_pieces: 'pièces',
        general_piece_pieces: 'pièce(s)',
        general_portion: 'portion',
        general_portions: 'portions',
        general_portion_portions: 'portion(s)',
        general_leave_app_confirmation: "Attention, tu quittes l'application. Poursuivre ?",
        general_warn_fitbit_external_link:
            'Attention, lorsque tu cliques sur "Connexion à fitbit", tu quittes l\'application.',
        general_terms_and_conditions: 'Conditions Générales',
        general_privacy_policy: 'Déclaration de protection des données',
        general_app_version: "Version de l'appli",
        general_data_processing: 'Consentement au traitement des données personnelles et de santé',
        general_exclusion_criteria: 'Critères d’exclusion',
        general_no_confirmation_mail: 'Pas reçu le courriel de confirmation ?',
        general_forgot_password: 'Mot de passe oublié',
        general_average: 'Moyenne',
        general_explain_license_extension:
            'Vous pouvez renouveler votre licence actuelle ou déverrouiller un nouveau programme en saisissant un code de licence.',
        general_for_program: 'Pour le programme',
        general_goals: 'Objectifs',
        general_goals_change_in_profile: 'Ici, tu peux ajuster tes objectifs personnels',
        general_first_goal: 'Premier objectif',
        general_second_goal: 'Deuxième objectif',
        general_third_goal: 'Troisième objectif',
        general_no_goal: 'Aucun objectif',
        general_overall: 'global',
        general_show_regression: 'Montrer regression',
        general_institution: 'Cabinet médical',
        general_location: 'Code postal',
        general_updates: 'Mises à jour',
        general_prompt_remove_entry: 'Es-tu sûr(e) de vouloir supprimer cette entrée ?',
        general_weekly_average: 'Moyenne de 7 jours',
        general_your_entry: 'Ton entrée',
        general_confirm: 'Confirmer',
        general_voucher_export: 'Export CSV',
        general_two_factor_details: "Activer l'authentification à 2 facteurs pour une sécurité renforcée",
        general_two_factor_authentication: 'Authentification à 2 facteurs',
        general_create_password_to_activate: 'Créer un mot de passe pour activer ton compte :',
        general_click_to_activate_account:
            'Vous avez déjà défini un mot de passe. Cliquez ci-dessous pour activer le programme.',
        general_generate_demo_data_prompt:
            'Toutes les entrées existantes du journal de sommeil seront supprimées. Poursuivre ?',
        general_locked: 'Verrouillé',
        general_select_provider: 'Choisir fournisseur',
        general_demo_account: 'Compte démo',
        general_flag_for_rating: "Drapeau pour l'évaluation",
        general_provider: 'Fournisseur',
        general_generate_sleep_data: 'Générer des entrées fictives',
        general_demo_actions: 'Actions de démo',
        general_demo_actions_details: 'Tu as un compte de démonstration. Les actions suivantes sont disponibles :',
        general_remove_wait_times: "Supprimer les délais d'attente",
        general_unlock_all_modules: 'Déverrouiller tous les modules',
        general_here: 'ici',
        general_link: 'Lien',
        general_more_info: "Plus d'informations",
        general_auto_next: 'Poursuivre automatiquement',
        general_auto_next_detail:
            "Passer automatiquement à l'étape suivante à la fin d'une étape si aucune interaction n'est nécessaire",
        general_module_duration: 'Durée : $$ minutes',
        general_sleep_phase: 'Phase de sommeil',
        general_awake_phase: "Phase d'éveil",
        general_consent: 'Consentement',
        general_sources: 'Sources',
        general_gender_other: 'Autre',
        general_analysis: 'Analyses',
        general_reports: 'Rapports',
        general_in_progress_please_wait: 'En cours, s.t.p. patiente un instant...',
        general_job_started_mail_will_be_sent: "Action lancée, le résultat sera envoyé à l'adresse e-mail",
        general_job_started_notification_will_be_sent:
            "Job started, you'll be notified once the report is ready for download",
        general_job_completed: 'Job has been completed',
        general_active_after_wakeup: 'Actif après le réveil',
        general_sleep_quality: 'Qualité du sommeil',
        general_comparison: 'Comparaison',
        general_split_evaluation_view: 'Évaluation delta (les données sont divisées visuellement)',
        general_new_entry: 'Nouvelle entrée',
        general_edit_entry: "Modifier l'entrée",
        general_sleep: 'Sommeil',
        general_voucher_internal: 'Interne uniquement',
        general_license_expired: 'Ta licence a expiré',
        general_answer_label: 'Étiquette de réponse',
        general_support_confirmation: 'Demande envoyée',
        general_send_request: 'Envoyer',
        general_request: 'Demande',
        general_request_detail: 'Décrire le sujet de ta demande aussi précisément que possible',
        general_new_password: 'Nouveau mot de passe',
        general_own_next: 'Bouton "Continuer" propre',
        general_your_insomnia_cycle: "Ton cercle d'insomnie",
        general_feedback: 'Feedback',
        general_entries_remaining_help_title: "D'autres entrées ?",
        general_entries_remaining_help:
            "Le programme contrôle de divers contenus en fonction de ton journal. Pour que cela fonctionne, il faut un certain nombre d'entrées dans le journal avant que tu puisses accéder d'autres modules.",
        general_entry_remaining: 'Encore une entrée',
        general_entries_remaining: 'Encore $$ entrées',
        general_entries_remaining_evening: 'noch <b>$$</b> Einträge im Abendprotokoll nötig',
        general_entries_remaining_blood_pressure: 'noch <b>$$</b> Einträge im Blutdruckprotokoll nötig',
        general_entries_to_unlock_module: 'Um das nächste Modul freizuschalten, sind',
        general_your_sleep_window: 'Fenêtre de sommeil',
        general_no_sleep: 'Pas de sommeil',
        general_shift_window_back: 'Décaler la fenêtre (plus tôt)',
        general_save_window: 'Sauvegarder la fenêtre',
        general_shift_window_forward: 'Décaler la fenêtre (plus tard)',
        general_plus_minutes: '+$$ minutes',
        general_minus_minutes: '-$$ minutes',
        general_age: 'Âge',
        general_gender: 'Sexe',
        general_male: 'Homme',
        general_female: 'Femme',
        general_custom_element: 'Élément sur mesure',
        general_menu: 'Menu',
        general_point: 'Point',
        general_points: 'Points',
        general_add_notification: 'Ajouter une notification',
        general_history: 'Historique',
        general_validators: 'Validateurs',
        general_system_notifications: 'Notifications',
        general_data_export_profile_json_title: 'Export',
        general_data_export_profile_json_details:
            'Tu peux télécharger toutes les données te concernant stockées dans le système dans un format interopérable (JSON).',
        general_data_export_sleep_journals_xlsx_title: 'Exporter les entrées du journal de sommeil',
        general_data_export_sleep_journals_xlsx_awake_protocol_title: 'Export rapport du soir',
        general_data_export_sleep_journals_xlsx_details:
            'Ici, tu peux télécharger les entrées que tu as faites dans le journal de sommeil en format Excel.',
        general_data_export_sleep_journals_xlsx_sheet_title: 'Entrées dans le journal de sommeil',
        general_data_export_sleep_journals_xlsx_sheet_date_created: "Date de l'exportation",
        general_data_export_sleep_journals_xlsx_sheet_user_mail: 'Utilisateur',
        general_data_export_sleep_journals_xlsx_sheet_header_entry_nr: "No. de l'entrée",
        general_data_export_sleep_journals_xlsx_sheet_header_to_bed_date: 'Heure du coucher',
        general_data_export_sleep_journals_xlsx_sheet_header_get_up_date: 'Heure du lever',
        general_data_export_sleep_journals_xlsx_sheet_header_no_sleep: 'Pas dormi',
        general_data_export_sleep_journals_xlsx_sheet_header_tried_to_sleep: "Tentation de s'endormir",
        general_data_export_sleep_journals_xlsx_sheet_header_fell_asleep: 'Endormi',
        general_data_export_sleep_journals_xlsx_sheet_header_awake_amount: "Nombre des phases d'éveil",
        general_data_export_sleep_journals_xlsx_sheet_header_awake_duration:
            "Temps d'éveil pendant la phase de sommeil",
        general_data_export_sleep_journals_xlsx_sheet_header_wake_up_date: 'Réveil',
        general_data_export_sleep_journals_xlsx_sheet_header_from_tracker: 'Importé via un tracker de sommeil',
        general_data_export_visual_analysis_title: 'Évaluation visuelle',
        general_data_export_visual_analysis_details:
            'Ici, tu peux télécharger les entrées que tu as faites dans le journal de sommeil en format PDF.',
        general_data_export_visual_analysis_file_name: 'somnio_donnees_sommeil',
        general_data_export_visual_analysis_averages_last_week: 'Valeurs moyennes de la semaine dernière',
        general_data_export_visual_analysis_overall: 'Analyse globale',
        general_data_export_visual_analysis_relation_bed_sleep: 'Temps au lit / temps de sommeil',
        general_data_export_visual_analysis_relation_bed: 'Temps au lit',
        general_data_export_visual_analysis_relation_sleep: 'Temps de sommeil',
        general_data_export_visual_analysis_awake_in_bed: 'Temps au lit après le réveil',
        general_data_export_visual_analysis_sleep_data: 'Rapport médical de',
        general_download: 'Téléchargement',
        general_encrypted: 'crypté',
        general_view_download: 'Consulter / Télécharger',
        general_coming_soon: 'À venir',
        general_download_as_pdf: 'Télécharger comme PDF',
        general_open_print_dialog: 'Ouvrir la boîte de dialogue imprimer',
        general_data_security: 'Protection des données',
        general_revoke_consent: 'Retirer le consentement',
        general_warning: 'Attention',
        general_prompt_revoke_consent_info:
            "Une fois que le consentement au traitement des données personnelles est retiré, l'application ne peut plus être utilisée. Ton compte sera désactivé et supprimé dans les 30 jours qui suivent.",
        general_prompt_revoke_consent_confirm:
            "Confirmer le retrait du consentement en entrant l'adresse e-mail du compte.",
        general_prompt_remove_account_info:
            'Lorsque tu supprimes ton compte, ton profil sera irrévocablement supprimé ainsi que toutes les données associées.',
        general_prompt_remove_account_confirm: "Confirmer la suppression en saisissant l'adresse e-mail du compte.",
        general_account: 'Compte',
        general_data_processing_agreement:
            'Lors de la création de ton compte, tu as déclaré à mementor le consentement au traitement de tes données personnelles.',
        general_remove_account: 'Supprimer le compte',
        general_remove_account_description:
            "Le compte sera physiquement supprimé, c'est-à-dire que l'entrée entière du compte sera retirée de la base de données",
        general_anonymize_account: 'Anonymisation du compte',
        general_anonymize_account_description:
            "Les données associées à l'utilisateur seront supprimées. Seules les données spécifiques à la facturation, telles que la date d'enregistrement, la licence et les bons, seront conservées. L'adresse e-mail sera rendue anonyme.",
        general_allow_medical_access_short: "Autoriser l'accès ?",
        general_allow_medical_access: 'Veux-tu donner accès à "$$" pour  l\'évaluation de tes données thérapeutiques ?',
        general_generate_access_code_detail:
            'Créer un code d\'accès afin que le personnel médical puisse accéder temporairement à tes données de sommeil via <a href="https://app.somn.io/doc" target="_blank">https://app.somn.io/doc</a>.',
        general_generate_access_code: "Créer un code d'accès",
        general_load_evaluation: "Charger l'évaluation",
        general_optional: 'Optionnel',
        general_code: 'Code',
        general_percentage: 'Pourcentage',
        general_decibel: 'Décibel',
        general_sleep_efficiency: 'Efficacité',
        general_bed_time: 'Temps au lit',
        general_sleep_time: 'Durée du sommeil',
        general_prompt_confirm_short: 'Sûr(e) ?',
        general_prompt_confirm: 'Es-tu sûr(e)?',
        general_yes: 'Oui',
        general_no: 'Non',
        general_forward: 'Avancer',
        general_back: 'Retour',
        general_sleep_journal_evaluation: 'Journal de sommeil',
        general_sleep_tracker: 'Tracker de sommeil',
        general_sleep_tracker_connected:
            'Tracker de sommeil connecté. Le journal de sommeil sera tenu automatiquement.',
        general_connect_fitbit: 'Connexion avec fitbit',
        general_disconnect_fitbit: 'Déconnecter',
        general_connect_fitbit_info:
            "La connexion d'un devices de sommeil fitbit te permet de remplir automatiquement ton journal de sommeil",
        general_times: 'fois',
        general_health_data_consent: 'traitement de mes données personnelles et de santé',
        general_health_data_consent_a: 'Je consens au',
        general_health_data_consent_b: '',
        general_medication_plan: 'Plan de médication',
        general_drag_file: 'Glisser fichier ici',
        general_or_select_file: 'Sélectionner un fichier',
        general_or: 'Ou',
        general_support_page_somnio:
            'Pour toute question ou demande d‘aide, consulte le site <a href="https://support.mementor.de/help/de-de/16-somnio" target="_blank">d\'assistance </a> de somnio.',
        general_support_page_actensio:
            'Pour toute question ou demande d‘aide, consulte le site <a href="https://support.mementor.de/help/de-de/49-actensio" target="_blank">d\'assistance </a> de actensio.',
        general_support_question:
            "Nous n'avons pas pu résoudre tes problèmes ou tu souhaites nous contacter directement ?",
        general_support_form: 'Écrivez-nous directement en utilisant le formulaire de support ci-dessous',
        general_support_option_choose: 'Quelle est la nature de ta question ?',
        general_support_tutorial_sentence: "Voici un tutoriel vidéo sur l'enregistrement",
        general_support_tutorial_button: 'Voir la vidéo',
        general_support_content_issues: 'Questions relatives au contenu',
        general_support_technical_issues: 'Questions techniques',
        general_support_technical_tooltip: 'En cliquant ici, tu recevras du soutien lors des problèmes techniques',
        general_support_faq: "Pour lire les réponses aux questions fréquentes, consulte notre site d'assistance de",
        general_support_counseling: 'Prends rendez-vous pour une consultation',
        general_support_calendar: 'Au calendrier',
        general_help: 'Aide',
        general_support: 'Assistance',
        general_notifications: 'Notifications',
        general_data_protection: 'Protection des données',
        general_journal_entries: 'Entrées dans le journal',
        general_required_entries: 'Entrées requises',
        general_required_entries_detail: "Nombre d'entrées nécessaires pour débloquer le module suivant",
        general_monday_abbr: 'Lun',
        general_tuesday_abbr: 'Mar',
        general_wednesday_abbr: 'Mer',
        general_thursday_abbr: 'Jeu',
        general_friday_abbr: 'Ven',
        general_saturday_abbr: 'Sam',
        general_sunday_abbr: 'Dim',
        general_language: 'Langue',
        general_bad: 'Mauvaise',
        general_good: 'Bonne',
        general_low: 'Basse',
        general_high: 'Haute',
        general_large: 'Grand',
        general_well_being: 'Bien-être',
        general_consumption: 'Consommation',
        general_awake_journal: 'Rapport du soir',
        general_load_more: 'Charger plus',
        general_edit_voucher: 'Modifier bon',
        general_edit_provider: 'Modifier fournisseur',
        general_edit_medication: 'Modifier Médicament',
        general_your_sleep_development: 'Évolution de ton sommeil',
        general_efficiency: 'Efficacité',
        general_fall_asleep_time: "Délai d'endormissement",
        general_awake_time: "Durée d'éveil",
        general_comment: 'Commentaire',
        general_hello_name: 'Bonjour $$ !',
        general_sleep_type_mixed: 'Type mixte',
        general_sleep_type_fallAsleep: 'Type endormissement',
        general_sleep_type_sleepThrough: 'Type maintien',
        general_sleep_type_wakeUpEarly: 'Réveil précoce',
        general_confirm_account: 'Confirmation de compte',
        general_restart: 'Redémarrer',
        general_add_user: 'Créer utilisateur',
        general_user_actions: 'Actions',
        general_active: 'Actif',
        general_account_activated_redirecting:
            'Compte activé, connecte-toi avec ton adresse e-mail et ton mot de passe',
        general_password_reset: 'Mot de passe réinitialisé',
        general_reset_instructions_sent:
            "S'il y a un compte avec cette adresse e-mail, les instructions y ont été envoyées",
        general_input_label: 'Étiquette de texte',
        general_min_value: 'Valeur minimale',
        general_max_value: 'Valeur maximale',
        general_external_value: 'Valeur externe',
        general_animation_blot_driven: "L'animation est contrôlée par une valeur externe",
        general_slider_label: 'Label curseur',
        general_slider_min_label: 'Label valeur minimale',
        general_slider_max_label: 'Label valeur maximale',
        general_slider_min_value: 'Valeur minimale',
        general_slider_max_value: 'Valeur maximale',
        general_slider_show_ticks: 'Afficher les ticks',
        general_day: 'jour',
        general_days: 'jours',
        general_day_days: 'jour(s)',
        general_hour: 'heure',
        general_hours: 'heures',
        general_minute: 'minute',
        general_minutes: 'minutes',
        general_minute_minutes: 'minute(s)',
        general_mgdl: 'milligrammes par décilitre',
        general_mmol: 'millimoles',
        general_drinking_glass: 'verre',
        general_drinking_glasses: 'verres',
        general_module_entry_point: "Point d'entrée du module",
        general_separate_by_comma: 'Séparer les éléments par une virgule',
        general_number: 'Numéro',
        general_integer: 'Nombre entier',
        general_list: 'Liste',
        general_list_items: 'Éléments de liste',
        general_answer_type: 'Type de réponse',
        general_unit: 'Unité',
        general_key: 'Clé',
        general_edit_answer_container: 'Modifier conteneur de réponse',
        general_add_answer_container: 'Ajouter conteneur',
        general_answer_containers: 'Conteneurs de réponse',
        general_select_answer_container: 'Sélection du conteneur',
        general_answer_value: 'Valeur',
        general_answer_container: 'Conteneur de réponse',
        general_answer_container_name: 'Nom du conteneur de réponse',
        general_questionnaire: 'Questionnaire',
        general_questionnaires: 'Questionnaires',
        general_add_questionnaire: 'Ajouter questionnaire',
        general_edit_questionnaire: 'Modifier questionnaire',
        general_questions: 'Questions',
        general_question_question: 'Question',
        general_question_title: 'Titre',
        general_edit_question: 'Modifier question',
        general_add_question: 'Ajouter question',
        general_copy_question: 'Copier question',
        general_question_type_select: 'Sélection',
        general_question_type_buttons: 'Liste des boutons',
        general_question_type_number_input: 'Entrée numérique',
        general_question_type_mobiscroll_number: 'Mobiscroll number input',
        general_question_type_mobiscroll_timespan: 'Mobiscroll timespan input',
        general_conditions: 'Conditions',
        general_expression: 'Expression',
        general_edit_user: 'Modifier utilisateur',
        general_availability: 'Disponibilité',
        general_filters: 'Filtres',
        general_icon: 'Icon',
        general_edit_coaching: 'Modifier coaching',
        general_spoken_text: 'Parole',
        general_edit_animation: 'Modifier animation',
        general_add_animation: 'Ajouter animation',
        general_select_animation: 'Choisir animation',
        general_select_questionnaire: 'Choisir questionnaire',
        general_select: 'Sélectionner',
        general_animation: 'Animation',
        general_no_animation: "Pas d'animation",
        general_animations: 'Animations',
        general_new_modules_available: 'Nouveaux modules disponibles',
        general_update: 'Mise à jour',
        general_start: 'Lancer',
        general_start_module: 'Lancer module',
        general_continue_module: 'Poursuivre',
        general_select_step: "Sélection d'étape",
        general_edit_module: 'Modifier module',
        general_percent: 'pour cent',
        general_percentage_point: 'point de pourcentage',
        general_percentage_points: 'points de pourcentage',
        general_specify_parameters: 'Paramêtres',
        general_select_step_property: "Sélectionner propriété de l'étape",
        general_edit_step: 'Modifier étape',
        general_values: 'Valeurs',
        general_functions: 'Fonctions',
        general_speech: 'Language',
        general_roles: 'Rôles',
        general_add_role: 'Ajouter un rôle',
        general_add: 'Ajouter',
        general_roles_menu: 'Rôles',
        general_sleep_training: 'Coaching sommeil',
        general_minutes_short: 'min',
        general_search: 'Recherche',
        general_wait_time: "Temps d'attente",
        general_flow_manager: 'Flux de module',
        general_select_module: 'Sélection de module',
        general_first_name: 'Prénom',
        general_last_name: 'Nom de famille',
        general_no_module_selected: 'Aucun module sélectionné',
        general_description: 'Description',
        general_saved_value: 'Valeur enregistrée',
        general_next_step: 'Prochaine étape',
        general_complete_questionnaire: 'Enregistrer et continuer',
        general_save: 'Enregistrer',
        general_save_and_continue: 'Enregistrer et continuer',
        general_delete: 'Supprimer',
        general_cancel: 'Annuler',
        general_add_module: 'Ajouter module',
        general_create_module: 'Créer module',
        general_add_coaching: 'Créer coaching',
        general_value: 'Valeur',
        general_save_answer: 'Enregistrer réponse',
        general_answer_property: 'Nom de la réponse',
        general_users: 'Utilisateurs',
        general_user: 'Utilisateur',
        general_step_name: "Nom de l'étape",
        general_coaching_manager: 'Coachings',
        general_module_manager: 'Modules',
        general_step_manager: 'Étapes',
        general_text: 'Texte',
        general_email: 'E-mail',
        general_wrong_email: "Faute de frappe? Cliquer ici pour ajuster l'adresse e-mail...",
        general_password: 'Mot de passe',
        general_password_set_new: 'Définir un nouveau mot de passe',
        general_password_new: 'Nouveau mot de passe',
        general_password_old: 'Ancien mot de passe',
        general_password_tip_show: 'Afficher le mot de passe',
        general_password_tip_hide: 'Cacher le mot de passe',
        general_repeat_password: 'Répéter le mot de passe',
        general_request_password: 'Mot de passe oublié',
        general_request_password_submit: 'Réinitialiser mot de passe',
        general_login: 'Connexion',
        general_login_action: 'Se connecter',
        general_login_with_password: 'Connexion avec mot de passe',
        general_login_passwordless: 'Connexion sans mot de passe',
        general_manage_devices: 'Gérez vos appareils',
        general_setup_and_go_passwordless: 'Après la configuration, tu peux te connecter sans mot de passe.',
        general_setup: 'Configuration',
        general_unregister_device: "Déconnecter l'appareil",
        general_login_faster: 'Se connecter plus vite',
        general_passwordless_login:
            'Ton appareil prend en charge la connexion sans mot de passe. Souhaite-tu mettre en place un tel accès maintenant ?',
        general_passwordless_login_active: 'Connexion activée sans mot de passe !',
        general_passwordless_login_active_b: 'Entre un nom pour ton appareil :',
        general_register: 'Inscription',
        general_register_long: 'Créer un nouveau compte',
        general_prescription_or_license_code: 'Avez-vous une ordonnance ou un code de licence ?',
        general_unlock: 'Débloquer',
        general_enter_code: 'Saisie du code',
        general_enter_code_detail: "Saisissez ici votre code d'ordonnance ou de licence à 16 chiffres :",
        general_start_without_code:
            "Vous pouvez déjà créer votre compte. Pour utiliser le programme, vous devez toutefois disposer d'une ordonnance ou d'un code de licence.",
        general_we_will_support_you: 'Nous vous soutiendrons dans cette démarche.',
        general_register_action: "S'inscrire",
        general_register_tk: 'Inscription TK',
        general_almost_done: 'On y est presque !',
        general_registration_success: 'Enregistré avec succès.',
        general_notify_mail_delay: 'Vérifie ta boîte aux lettres. Un délai de quelques minutes est possible.',
        general_activation_success:
            'Ton compte a été activé avec succès. Tu peux maintenant te connecter avec tes données de connexion.',
        general_activation_new_password_success:
            'Ta demande a été transmise. Vérifies ta boîte aux lettres pour retrouver le nouveau mot de passe.',
        general_coaching_single: 'Coaching',
        general_coaching: 'Mon coaching',
        general_settings: 'Paramètres',
        general_user_data: "Données d'utilisateur",
        general_user_groups: "Groupes d'utilisateur",
        general_logout: 'Déconnexion',
        general_application: 'Application',
        general_session: 'Session',
        general_manager: 'Manager',
        general_administration: 'Administration',
        general_sleep_journal: 'Journal de sommeil',
        general_license: 'Licence',
        general_licenses: 'Licences',
        general_licenses_overview: 'Aperçu de vos licences actives',
        general_license_extend: 'Activer/renouveler une licence',
        general_license_buy: 'Acheter une licence',
        general_license_management: 'Gestion des licences',
        general_license_code: "Code d'ordonnance/de licence",
        general_license_type_admin: 'Créée par administrateur',
        general_license_type_trial: "Licence d'évaluation",
        general_license_type_provider: 'Licence de fournisseur',
        general_license_type_diga: 'Licence DiGA',
        general_license_type_diga_unverified: 'Licence DiGA (non vérifiée)',
        general_voucher: 'Bon',
        general_vouchers: 'Bons',
        general_voucher_manager: 'Administration des bons',
        general_voucher_add: 'Ajouter un bon',
        general_voucher_find_expiring_temp_diga_licenses: 'Lic DiGA temp',
        general_voucher_sync_with_sevdesk: 'Sync avec SevDesk',
        general_voucher_name: 'Nom du bon',
        general_voucher_discount_percentage: 'Rabais en %',
        general_voucher_validity_days: 'Validité (Jours)',
        general_voucher_validity_unlimited: 'Aucune limite',
        general_voucher_provider: 'Fournisseur (optionnel)',
        general_voucher_coaching: 'Pour coaching',
        general_voucher_quantity: 'Valide pour x clients',
        general_voucher_code: 'Code du bon',
        general_redeem_code: 'Utiliser le code',
        general_voucher_redeem_execute: 'Appliquer',
        general_voucher_confirm_diga: 'Confirmer bon DiGA',
        general_voucher_usages: 'Usages',
        general_upgrade_not_successful: 'La mise à niveau a échoué',
        general_upgrade: 'Mise à niveau',
        general_upgrade_successful:
            'Mise à niveau réussie ! <br> En cliquant sur "Je l\'ai", tu seras automatiquement déconnecté et tu devras ensuite te reconnecter.',
        general_upgrade_license:
            'En activant ce code de licence, tu effectues une mise à niveau vers la DiGA (appli sur ordonnance) somnio. <br> <br> Souhaites-tu transférer les données de ton journal existant au nouveau coaching ?',
        general_localise: 'Traduire',
        general_provider_manager: 'Fournisseur/Client/Fournisseur du bon',
        general_provider_menu: 'Fournisseurs',
        general_provider_name: 'Nom du fournisseur',
        general_provider_parent: 'Fournisseur parent',
        general_provider_add: 'Ajouter nouveau',
        general_provider_none: 'Pas de fournisseur/ inconnu',
        general_continue: 'Continuer',
        general_speak: 'Parle',
        general_accept_terms: "J'accepte les",
        general_terms_and: 'et la',
        general_terms_abbr: 'CG',
        general_privacy_policy_abbr: 'PPD',
        general_exclusion_criteria_a: "J'ai pris note des",
        general_exclusion_criteria_b: '',
        general_back_to_login: 'Retour au login',
        general_my_coaching: 'Coaching',
        general_monitor: 'Rapport médical',
        general_coachings: 'Coachings',
        general_select_coaching: 'Sélectionner coaching',
        general_medication: 'Médicaments',
        general_modules: 'Modules',
        general_steps: 'Étapes',
        general_step: 'Étape',
        general_add_step: 'Ajouter étape',
        general_answer: 'Réponse',
        general_answers: 'Réponses',
        general_name: 'Nom',
        general_username: 'Ton nom',
        general_module: 'Module',
        general_all: 'Tous',
        general_none: 'Aucun',
        general_little: 'Peu',
        general_medium: 'Moyen',
        general_lots: 'Beaucoup',
        general_close: 'Fermer',
        general_date_days: 'jours',
        general_date_week: 'semaine',
        general_date_weeks: 'semaines',
        general_week_weeks: 'semaine(s)',
        general_date_months: 'mois',
        general_date_created: 'Date de création',
        general_text_copied: 'Texte a été copié dans le presse-papier',
        general_version: 'Version',
        general_publish: 'Publier',
        general_new_version: 'Nouvelle version',
        general_shortcuts: 'Raccourcis',
        general_delete_version: 'Supprimer version',
        general_published: 'Publié',
        general_published_on: 'Publié le',
        general_edit_module_meta: 'Modifier méta du module: $$',
        general_wait_time_hours: "Temps d'attente en heures",
        general_has_license_code: "J'ai un code de licence",
        general_activate_account: 'Activer compte',
        general_sleep_coaching: 'Coaching sommeil',
        general_receive_newsletter: 'Je souhaite recevoir la newsletter',
        general_newsletter: 'Newsletter',
        general_receive_mail_notifications: 'Recevoir des notifications par e-mail',
        general_receive_push_notifications: 'Recevoir des notifications "push"',
        general_profile: 'Profil',
        general_clients: "Groupe d'utilisateurs",
        general_save_password: 'Enregistrer mot de passe',
        general_save_profile: 'Enregistrer profil',
        general_coach_settings: 'Coach',
        general_coach_talking_speed: "Vitesse d'élocution",
        general_slow: 'Lent',
        general_fast: 'Rapide',
        general_test: 'Test',
        general_about: 'À propos',
        general_medical_device: 'Dispositif médica',
        general_serial_number: 'Numéro de série',
        general_udi_code: 'Le code UDI',
        general_somnio_description:
            "somnio est une application numérique pour le traitement des troubles de l'endormissement et du maintien du sommeil (insomnie)",
        general_actensio_description:
            "actensio est un dispositif thérapeutique numérique destiné à soigner l'hypertension en abaissant la pression artérielle au moyen de modifications du mode de vie axées sur les protocoles de soins.",
        general_got_it: "Je l'ai",
        general_understood: 'Compris',
        general_authentication: 'Authentification à 2 facteurs',
        general_no_account_yet: 'Pas encore de compte?',
        general_insert_two_factor_code: "Entre le code que nous venons de t'envoyer par e-mail",
        general_did_not_get_code: 'Pas obtenu de code ?',
        general_send_again: 'Envoyer à nouveau',
        general_resend_activation_link: "Renvoyer le lien d'activation",
        general_activation_link_missing: "Pas reçu le lien d'activation ?",
        general_code_sent: 'Code envoyé à $$',
        general_x_label: "Nom de l'axe X",
        general_y_label: "Nom de l'axe Y",
        general_days_back: 'Jours en arrière',
        general_evaluation: 'Évaluation',
        general_evaluation_journal: 'Évaluation Journal',
        general_evaluation_questionnaire: 'Évaluation Questionnaire',
        general_show_subtitles: 'Afficher les sous-titres',
        general_sleep_restfulness_question: 'Ton sommeil a-t-il été reposant ?',
        general_multi_select_dialog_option_default_title: 'Sélection',
        general_multi_select_dialog_option_default_message: 'Sélectionne une option',
        general_multi_select_dialog_option_confirm_selection: 'Confirmer la sélection',
        general_additional_data: 'Données complémentaires',
        general_to: 'à',
        general_stay_logged_in: 'Rester connecté',
        general_XRechnung_title: 'XRechnung',
        general_XRechnung_submit_sevdesk_insurance: "Envoyer XRechnung à l'entreprise et SevDesk",
        general_XRechnung_submit_sevdesk_only: 'Envoyer XRechnung à SevDesk seulement',
        general_XRechnung_submit_insurance_only: "Envoyer XRechnung à l'entreprise seulement",
        general_XRechnung_submit_insurance_override: "Écraser XRechnung à l'entreprise",
        general_XRechnung_serviceDate: 'Date de la prestation',
        general_XRechnung_buyerReference: "'Leitweg-Id' (Buyer Reference)",
        general_XRechnung_insuranceName: 'Destinataire de la facture entreprise',
        general_XRechnung_insuranceIk: "IK de l'entreprise",
        general_XRechnung_ikBilling: 'IK pour la facture',
        general_XRechnung_insuranceStreet: 'Rue',
        general_XRechnung_insurancePostCode: 'Code postal',
        general_XRechnung_insuranceCity: 'Lieu',
        general_XRechnung_insuranceCountryCode: 'Code du pays',
        general_XRechnung_invoiceNr: 'No. de la facture',
        general_XRechnung_sent_date: "Date de l'envoi",
        general_XRechnung_paid_date: 'Date de paiement',
        general_XRechnung_sevDesk_Id: 'SevDesk ID',
        general_XRechnung_already_paid: 'Déjà payé',
        general_payrexx_title: 'Payrexx',
        general_payrexx_sync_to_sevdesk: 'Envoyer à SevDesk',
        general_currency: 'Monnaie',
        general_amount: 'Somme',
        general_payment_provider_name: 'Fournisseur du payment',
        general_payment_provider_transaction_id: 'ID de la transaction',
        general_payment_provider_transaction_uuid: 'UUID de la transaction',
        general_status: 'Statut',
        general_extend_license_by_mail: 'Prolonger pour adresse e-mail',
        general_extend_license_mail_address: "E-mail de l'utilisateur",
        general_extend_license_days: 'Prologner de jours',
        general_extend_license_validity_date: "Valable jusqu'au (nouvelle date)",
        general_check_voucher: 'Vérifier le voucher',
        general_check_voucher_sum_valid: 'La somme de contrôle est valide',
        general_check_voucher_sum_invalid: "La somme de contrôle n'est pas valide",
        general_check_voucher_used: 'Le voucher a déjà été utilisé',
        general_check_voucher_unused: "Le voucher n'a pas encore été utilisé",
        general_refresh: 'Actualiser',
        general_display: 'Afficher',
        general_seven_days: '7 jours',
        general_shorten: 'Raccourcir',
        general_extend: 'Prolonger',
        general_invalid_parameter: 'Paramètre non valide',
        general_certificate: 'Certificat',
        general_certificate_title: 'Certificat',
        general_certificate_expired: 'Certificat a expiré',
        general_certificate_issued: 'Certificat créé',
        general_certificate_we_confirm: 'Nous confirmons',
        general_certificate_has_completed: 'a terminé le coaching sommeil',
        general_certificate_description: 'Ici tu peux télécharger ton certificat de participation.',
        general_certificate_name: 'Nom',
        general_import_medication_list: 'Importer',
        general_for: 'Pour',
        general_default: 'Default',
        general_travel: 'Locomotion',
        general_recipes: 'Recettes',
        general_translation_suggestion: 'Traduction proposition',
        general_translation_suggestion_add_new: 'Ajouter une nouvelle version de texte depuis celui-ci',
        general_translation_suggestion_remove_this: 'Effacer cette version de texte',
        general_translation_suggestion_make_current: 'Utiliser cette version',
        general_translation_suggestion_cannot_remove_current:
            "Il s'agit de la version actuelle et ne peut donc pas être supprimée - il faut d'abord appliquer une autre version",
        general_mail_sent: 'E-mail envoyé',
        general_not_connected: 'non connecté',
        general_of: 'De',

        // sleep devices

        general_thryve_connection_management: 'Gérer les connexions des trackers',
        general_thryve_connect_title: 'Connexions aux trackers',
        general_thryve_connect: 'Connecter',
        general_thryve_connected: 'Connecté à un tracker',
        general_thryve_connected_success: 'Connecté avec succès à un tracker, tu peux fermer la fenêtre',
        general_thryve_connected_failure: 'Connexion au tracker a echoué, réessayer',
        general_thryve_disconnected_success: 'Déconnexion du tracker avec succès, tu peux fermer la fenêtre',
        general_thryve_disconnected_failure: 'Déconnexion du tracker a echoué, réessayer',
        general_thryve_disconnect: 'Déconnecter',
        notification_thryve_disconnected: 'Tracker a été déconnecté',
        notification_thryve_select_usage: "Veuillez choisir au moins un domaine d'utilisation",
        notification_no_pw_reset_link: "Aucun lien de réinitialisation du mot de passe n'est disponible",
        general_thryve_connect_info:
            "La connexion d'un tracker d'activités permet de remplir automatiquement le journal.",
        general_thryve_explanation_somnio:
            "Suite à la connexion d'un tracker d'activités, les données de sommeil seront automatiquement synchronisées avec le programme.",
        general_thryve_confirm_tracker_connection_somnio:
            "La précision du suivi automatique du sommeil peut varier d'une personne à l'autre. mementor ne peut donc pas garantir l'exactitude des données et recommande vivement de <b>vérifier régulièrement les données synchronisées et de les ajuster si nécessaire</b>.",
        general_thryve_hint_provider:
            "<b>Remarque</b> Le transfert des données s'effectue via notre partenaire Thryve. Pour cette raison, le fournisseur <i>Thryve</i> ou <i>mHealth Pioneers GmbH</i> sera affichée au lieu de <i>mementor</i> lors du consentement.",
        general_thryve_hint_leaving_app:
            "<b>Attention</b> En cliquant sur le lien de connexion du fournisseur du suivi, tu quittes l'application.",
        general_thryve_usage_sleep: 'Sommeil',
        general_thryve_usage_activity: 'Activités',
        general_thryve_usage_blood_pressure: 'Pression artér. & pouls',
        general_thryve_provider: 'Fournisseur',
        general_administration_ratings: 'Commentaires',
        general_whoops: 'Oups!',
        general_reload: 'Recharger',
        general_your_answer: 'Ta réponse',
        general_no_data_provided_yet: 'Aucune entrée disponible.',
        general_notification_general_test_notification: "Notification d'essai",
        general_notification_general_modules_progress: 'Prochain module',
        general_notification_actensio_missing_hypertension_journal_entry:
            'Entrées manquantes dans le procès-verbal de la soirée',
        general_notification_actensio_missing_blood_pressure_entry:
            'Entrées manquantes dans le journal de la tension artérielle',
        general_notification_actensio_missing_medication: 'Absence de protocoles de prise de médicaments',
        general_notification_somnio_missing_sleep_journal_entry: 'Entrées manquantes dans le rapport du matin',
        general_notification_somnio_jr_missing_sleep_journal_entry: 'Entrées manquantes dans le rapport du matin',
        general_notification_somnio_missing_awake_journal_entry: 'Entrées manquantes dans le rapport du soir',
        general_notification_somnio_jr_missing_awake_journal_entry: 'Entrées manquantes dans le rapport du soir',
        general_notification_glykio_weekly_planner_reminder: 'Prochains rendez-vous',
        general_notification_glykio_protocols_reminder: 'Rappel des entrées du journal',
        general_notification_glykio_perception_reminder: 'Rappel de la formation sur la perception',

        handlebars_fitbit_success_title: 'Connexion réussie',
        handlebars_fitbit_success_message: 'Ton compte a été relié avec succès à fitbit. Tu peux fermer cette fenêtre.',
        handlebars_mail_disable_notification_title_success: 'Désinscription avec succès',
        handlebars_mail_disable_notification_message_success:
            'Tu ne recevras plus de notifications par e-mail. Tu peux toujours ajuster ce paramêtre dans ton profil de ton compte.',
        handlebars_mail_disable_notification_title_error: 'Désinscription a échoué',
        handlebars_mail_disable_notification_message_error:
            "Le lien pour se désinscrire des notifications n'est pas valable. Tu peux toujours ajuster ce paramètre dans ton profil de ton compte.",
        handlebars_generali_registration_failure_title: 'Erreur de création de compte',
        handlebars_generali_registration_failure_message:
            "Un problème est survenu lors de la création d'un compte pour un utilisateur Generali",
        handlebars_tk_registration_failure_title: 'Erreur de création de compte',
        handlebars_tk_registration_failure_message:
            "Un problème est survenu lors de la création d'un compte pour un utilisateur de TK",
        handlebars_new_email_error_title: 'Confirmation a échoué',
        handlebars_new_email_error_message:
            "Le lien de confirmation pour la nouvelle adresse e-mail n'est pas correcte ou n'est plus valable.",
        handlebars_new_email_success_title: 'Confirmation réussie',
        handlebars_new_email_success_message:
            'Dès maintenant, tu peux te connecter en utilisant la nouvelle adresse e-mail.',

        history_action_create: 'crée',
        history_action_update: 'met à jour',
        history_action_remove: 'supprime',
        history_action_anonymize: 'anonymise',
        history_entity_user: 'utilisateur',

        hint_leave_blank_to_keep: 'Laisser vide pour conserver',
        hint_identifier: 'Votre nom ou institution',
        hint_access_code: "Code d'accès créé par le/la patient(e)",
        hint_change_mail:
            'Lorsque tu modifies ton adresse e-mail, un message de confirmation sera envoyé à la nouvelle adresse',
        hint_change_mail_confirmation_pending: 'Confirmation de la nouvelle adresse e-mail requise',

        isi_sleep_test: 'Test de sommeil',
        isi_explain_why:
            'Afin de vous guider vers le coaching sommeil qui vous convient le mieux, nous avons besoin de quelques informations supplémentaires.',
        isi_question_one:
            "Veuillez évaluer la sévérité de vos difficultés de sommeil au cours des deux dernières semaines (y compris aujourd'hui) :",
        isi_question_two: 'À quel point êtes-vous satisfait(e) / insatisfait(e) de votre sommeil actuel ?',
        isi_question_three:
            "Jusqu'à quel point considérez-vous que vos difficultés de sommeil perturbent votre fonctionnement quotidien (ex. : fatigue durant le jour, capacité à travailler/ à effectuer les tâches quotidiennes à la maison, concentration, mémoire et/ou humeur) ?",
        isi_question_four:
            'À quel point considérez-vous, que vos difficultés de sommeil sont apparentes pour les autres en termes de détérioration de la qualité de votre vie ?',
        isi_question_five:
            'À quel point êtes-vous inquiet(ète)/préoccupé(e) à propos de vos difficultés actuelles de sommeil ?',
        isi_none: 'Aucune',
        isi_light: 'Légère',
        isi_moderate: 'Moyennement',
        isi_severe: 'Sévère',
        isi_very_severe: 'Extrême',
        isi_very_satisfied: 'Très satisfait(e)',
        isi_satisfied: 'Satisfait(e)',
        isi_neutral: 'Ni satisfait(e) ni insatisfait(e)',
        isi_unsatisfied: 'Insatisfait(e)',
        isi_very_unsatisfied: 'Trés insatisfait(e)',
        isi_not_at_all: 'Aucunement',
        isi_a_little: 'Légèrement',
        isi_quite: 'Très',
        isi_very: 'Extrêmement',
        isi_fall_asleep_issues: "Difficulté à s'endormir",
        isi_sleep_through_issues: 'Difficulté à rester endormi(e)',
        isi_wake_up_early: 'Problèmes de réveils trop tôt le matin',
        isi_sleep_satisfaction: 'Satisfaction',
        isi_impact_on_daily_life: 'Altération du fonctionnement quotidien',
        isi_impact_on_quality_of_life: 'Altération de la qualité de vie',
        isi_worries: 'Inquiétudes',

        insomnia_cycle_switchOff: 'Difficultés de déconnecter de la journée',
        insomnia_cycle_pressure: 'Je me mets sous pression',
        insomnia_cycle_consequences: 'Réflexions liées aux conséquences',
        insomnia_cycle_awake: 'Le lit me réveille',
        insomnia_cycle_angry: 'Se sentir en colère',
        insomnia_cycle_body: 'Réactions physiques',
        insomnia_cycle_exhaustion: 'Se sentir fatigué et épuisé',
        insomnia_cycle_performance: 'Difficultés de concentration ou de performance ',
        insomnia_cycle_depression: 'Se sentir abattu',
        insomnia_cycle_noSleep: 'Être réveillé au lit',
        insomnia_cycle_sleepLonger: 'Rester plus longtemps au lit',
        insomnia_cycle_catchUp: 'Rattraper le sommeil perdu',

        journal_alcohol: 'Alcool',
        journal_caffeine: 'Caféine',
        journal_fall_asleep_duration: 'Endormi(e) après',
        journal_personal_note: 'Note personnelle',
        journal_entry_created: "Date de l'entrée",
        journal_mood: 'Humeur',
        journal_performance: 'Performance',
        journal_energy: 'Énergie',

        sleep_relaxation_title: 'Relaxation musculaire progressive',
        sleep_relaxation_instruction: 'Ici, tu peux écouter/télécharger la relaxation musculaire progressive',

        sleep_window_length: 'Durée de la fenêtre de sommeil',
        sleep_window_suggestion: 'Recommandation',
        sleep_window_extend: 'Prolonger fenêtre',
        sleep_window_shorten: 'Raccourcir fenêtre',
        sleep_window_keep: 'Maintenir fenêtre',
        sleep_window_no_data: 'Pas de données disponible pour une recommandation',
        sleep_window_info_lengthen_shorten: 'Prolonger/Raccourcir',
        sleep_window_info_process_a:
            'En fonction de ton efficacité de sommeil moyenne des 7 derniers jours, Albert te recommande de maintenir, prolonger ou raccourcir le créneau horaire de sommeil.',
        sleep_window_info_process_b:
            "Tu commences par la fenêtre de sommeil qu'Albert t'a proposé dans le module Heures de sommeil. L'objectif est de raccourcir ce créneau horaire si nécessaire pour ensuite l'élargir progressivement selon les recommandations jusqu'à trouver la durée optimale de sommeil pour toi.",
        sleep_window_info_extension_condition: 'Recommandation de prolonger si :',
        sleep_window_info_average_efficiency:
            'Ton efficacité moyenne du sommeil des 7 derniers jours était supérieure à 85%',
        sleep_window_info_average_efficiency_shorten:
            'Ton efficacité moyenne du sommeil des 7 derniers jours était inférieure à 80%',
        sleep_window_info_your_efficiency: 'Ton efficacité actuelle du sommeil est de $$%',
        sleep_window_info_shorten_condition: 'Recommandation de raccourcir si :',
        sleep_window_info_additional_conditions:
            "Pour la recommandation de prolonger/raccourcir la fenêtre, les conditions suivantes doivent également s'appliquer :",
        sleep_window_info_minimum_entries:
            'Un nombre minimum de 3 entrées dans le journal de sommeil au cours des 7 derniers jours',
        sleep_window_info_missing_entries: 'Il te faut encore $$ entrées',
        sleep_window_info_your_entries: 'Tu as fait $$ entrées',
        sleep_window_info_last_extension:
            'La dernière modification de la fenêtre de sommeil a eu lieu il y a au moins 7 jours',
        sleep_window_info_your_last_extension: 'La dernière modification a eu lieu il y a $$ jours',

        spoken_ordinal_high: 'corrélation forte',
        spoken_ordinal_medium: 'corrélation moyenne',
        spoken_ordinal_low: 'corrélation faible',

        license_renewal_info_send_mail: 'Envoyer des infos par e-mail',
        license_renewal_info_header: 'Ta licence expire dans $$ jours',
        license_renewal_info:
            'Voici comment renouveler ta licence : <br><ol> <li> Auprès de ta caisse d\'assurance maladie, demande une licence de renouvellement. </li>  <li> Tu obtiendras un nouveau code à 16 chiffres. </li> <li> Connecte-toi et renouvelle ton accès sous "Licence". </li></ol>><br><b>As-tu besoin d\'une nouvelle ordonnance médicale ?</b>  <br> Demande à ton médecin une ordonnance de renouvellement. Tu peux également profiter du service de télémédecine de la <a href="https://www.teleclinic.com/app/somnio/">Teleclinic</a>. <br><br> N\'hésite pas à contacter notre équipe d\'assistance si tu as des questions.',
        license_upgrade_how_to:
            '<ol><li>Parle avec ton médecin traitant ou ton psychothérapeute et fais-toi prescrire somnio.</li> <li>Le spécialiste prescrit somnio (l\'ordonnance contient "somnio" et le PZN 16898724).</li> <li>Soumets l\'ordonnance à ta caisse d’assurance maladie.</li> <li>La caisse maladie génère un code d\'activation pour somnio et te l\'envoie.</li> <li>Démarre le coaching dans l\'application et active somnio avec le code sous la rubrique "Licence". Tu peux également utiliser le coaching dans le navigateur à l\'adresse <a href="https://app.somn.io">app.somn.io</a>.</li></ol> <b>Remarque :</b> lors de la mise à niveau, tu as la possibilité de transférer sans problème tes données déjà existantes dans la nouvelle version de somnio.',
        license_upgrade_how_to_header: "<b>Mise à niveau vers l'application DiGA (Appli sur ordonnance) somnio*</b>",
        license_upgrade_how_to_somnio:
            "*La version prescriptible de somnio contient des fonctions avancées et des contenus recommandés en cas de diagnostic d'insomnie.",

        mail_notice_requires_html:
            "Veuillez noter que pour une utilisation sans erreur, vous avez besoin d'un programme de courrier électronique qui peut afficher des messages HTML.",
        mail_hello: 'Bonjour',
        mail_hello_formal: 'Bonjour',
        mail_registration_activation_title: 'Bienvenue à $$',
        mail_registration_activation_message: 'Tu y es presque.',
        mail_registration_activation_link_one_coaching: "Pour terminer l'inscription, clique sur le lien suivant :",
        mail_registration_activation_link_multiple_coachings_hint:
            "Tu y es presque! Tu es sur le point d'activer $$. Note que tu as déjà un compte sur une autre application de santé numérique que nous fournissons.<br>Pour simplifier les choses et t'éviter de saisir deux fois les mêmes données, nous relions les deux comptes. S. t. p., utilise <b>les mêmes données d'utilisateur (e-mail et mot de passe) pour te connecter.",
        mail_registration_activation_alternative_to_coach_linking:
            "Si tu n'acceptes pas que les comptes soient reliés, tu peux simplement t'inscrire avec une autre adresse e-mail.",
        mail_registration_activation_link_multiple_coachings_click: 'Pour ce faire, clique sur le bouton suivant:',
        mail_registration_activate_account: "Terminer l'inscription",
        mail_registration_waiting_room:
            'Nous te guiderons ensuite à travers toutes les étapes nécessaires pour activer $$.',
        mail_registration_activation_goodbye: 'Nous te souhaitons bien du succès avec ton coaching.',
        mail_app_download_tip: "Ici, tu peux télécharger l'appli somnio",
        mail_request_new_password_title: "Demande d'un nouveau mot de passe",
        mail_request_new_password_message:
            "Un nouveau mot de passe a été demandé. Si ce n'était pas toi qui as initié cette requête, tu peux ignorer ce message. Si tu souhaites recevoir un nouveau mot de passe, clique sur le lien ci-dessous.",
        mail_set_new_email_address_title: 'Confirmer la nouvelle adresse e-mail',
        general_new_email: 'La nouvelle adresse e-mail:',
        mail_set_new_email_address_message:
            "Tu as changé l'adresse e-mail de ton compte. Clique sur le lien ci-dessous afin de confirmer cette modification. Remarque : si tu n'es pas $$, quelqu'un d'autre a essayé d'utiliser ton adresse e-mail. Dans ce cas, ignore ce message et supprime-le.",
        mail_set_new_email_address_link: 'Confirmer',
        mail_set_new_email_address_duplicate_title: 'Nouvelle adresse e-mail pour un compte existant',
        mail_set_new_email_address_duplicate_message:
            "Quelqu'un souhaite utiliser cette adresse e-mail pour son compte. Tu as déjà un compte avec cette adresse e-mail. Si tu as oublié tes données de connexion, tu peux les réinitialiser via le lien ci-dessous.",
        mail_password_reset_title: 'Réinitialisation du mot de passe',
        mail_password_reset_message: 'Ton mot de passe a été réinitialisé.',
        mail_license_expiring_title: 'Ta licence pour $$',
        mail_license_expired_title: 'Ta licence pour $$',
        mail_license_expired_generali_message:
            "Bonjour, nous tenons à t'informer que ta licence pour notre coaching sommeil a expiré. Merci beaucoup pour ta participation. Nous espérons que tu as été satisfait(e) du programme et te souhaitons un bon sommeil ! L'équipe de somnio",
        mail_set_new_password: 'Créer un nouveau mot de passe',
        mail_registered_not_logged_in_title: "Bienvenu(e) chez $$. On t'y attend...",
        mail_two_factor_authentication_title: 'Ton code de connexion',
        mail_two_factor_authentication_message: 'Entre le code suivant',
        mail_unsubscribe_newsletter_text: 'Tu aimerais te désabonner des notifications par e-mail de $$ ? Clique ',
        mail_unsubscribe_newsletter_link: 'ici',
        mail_module_open_not_started_title: 'Nouveau module disponible dans $$',
        mail_module_open_not_started_body:
            'Il y a des nouvelles ! Nous avons vu qu\'il y a des modules non traités dans $$. Clique sur <a href="$$">$$</a> pour lancer $$.',
        mail_module_started_not_completed_title: 'Module commencé, mais pas terminé dans $$',
        mail_module_started_not_completed_body:
            'Récemment, tu as commencé le module $$ sans le terminer. Pour achever le module clique sur <a href="$$">$$</a>. Nous nous réjouirions de te revoir !',
        mail_inactivity_warning_title: 'Tu nous manques !',
        mail_inactivity_warning_body:
            "Nous ne t'avons pas vu depuis un moment ! Sache que les données associées à ton compte seront irrévocablement supprimées si tu ne te connectes pas jusqu'au $$. L'équipe de mementor",
        mail_news: 'Actualités',
        mail_contact_us:
            'Si tu as d\'autres questions, n\'hésite pas à nous contacter via <a href="mailto:support@mementor.de">support@mementor.de</a>. <br><br> Ton équipe de mementor',
        mail_contact_us_formal:
            'Si vous avez d\'autres questions, n\'hésitez pas à nous contacter via <a href="mailto:support@mementor.de">support@mementor.de</a>. <br><br> Votre équipe de mementor',
        mail_payment_voucher_bought_title: 'Votre code pour somnio',
        mail_payment_voucher_bought_message:
            'Merci beaucoup et bienvenu(e) chez somnio ! Nous avons bien reçu votre paiement. Vous pouvez donc commencer le programme. Veuillez procéder comme suit :',
        mail_payment_voucher_bought_step_1:
            'Veuillez cliquer <a href="$$">ici</a> pour créer un nouveau compte dans somnio',
        mail_payment_voucher_bought_step_2: 'Le code ci-dessous est votre code de licence',
        mail_payment_voucher_bought_step_3: 'Ensuite vous pouvez commencer.',
        mail_payment_voucher_bought_step_4:
            "Remarque : l'exécution du contrat commence déjà avant l'expiration du délai de rétractation. Par cet achat, vous renoncez expressément au droit de rétractation de 14 jours.",
        mail_payment_voucher_bought_code: 'Votre code de licence : ',
        mail_payment_voucher_bought_footer1: 'Nous vous souhaitons un bon commencement et surtout un bon sommeil !',
        mail_duplicate_registration_title: 'Compte existant',
        mail_duplicate_registration_message:
            "Tu viens d'essayer de t'inscrire chez nous. Il existe déjà un compte avec cette adresse e-mail. Tu peux te connecter",
        mail_duplicate_registration_message_b:
            '. Tu as des problèmes de connextion ? Tu peux demander un nouveau mot de passe',
        mail_duplicate_registration_message_c: '.',
        mail_demo_account: "Licence d'essai",
        mail_demo_account_welcome:
            "Nous nous réjouissons de votre intérêt pour somnio. Nous vous faisons parvenir par cet e-mail votre licence personnelle d'essai avec laquelle il vous est possible de vous familiariser avec notre coaching sommeil numérique.",
        mail_your_license_code: "Votre code d'accès personnel :",
        mail_demo_account_details:
            "Vous pourrez utiliser le programme pendant 14 jours après avoir activer votre code. Il s'agit d'une version démo dans laquelle vous pourrez déverrouiller tous les modules et enlever les délais d'attentes affichés. Vous trouverez les paramètres en question dans l'application sous \"Options démo\" dans votre profil.",
        mail_registration: 'Enregistrement',
        mail_demo_account_closure:
            "Nous espérons que vous avez pu profiter de cet essai. En cas de questions ou de propositions, n'hésitez pas à nous contacter : medical@mementor.de ou +49 341 581 444 33. ",
        mail_flyer_confirmation: 'somnio : Votre commande de dépliants',
        mail_flyer_confirmation_details_a:
            "Nous vous remercions de votre intérêt porté à somnio, le coaching sommeil numérique. Les dépliants pour vos patients seront bientôt envoyés à l'adresse indiquée.",
        mail_flyer_confirmation_details_b:
            "Nous recommandons de remettre à vos patients un dépliant au moment de la prescription afin qu'ils diposent des informations nécessaires sur le programme et les prochaines étapes. En outre, ils y trouvent nos coordonnées où ils pourront nous contacter en cas de questions ou de prolèmes.",
        mail_flyer_confirmation_details_c:
            'Si vous êtes médecin ou thérapeute et vous n\'avez pas encore testé le programme vous-même, vous avez la possibilité de solliciter <a href="https://somn.io/doc/#test">ici</a> un accès d\'essai de deux semaines.',
        mail_flyer_subject: 'Flyer pour les patients somnio',
        mail_certificate_title: 'Félicitations !',
        mail_certificate_download_body:
            'Tu as terminé le coaching $$. Tu as fait preuve de motivation et persévérance. Tu peux être fier/fière de toi ! Dans ton profil de ton compte, tu peux maintenant télécharger ton certificat de participation.',
        mail_certificate_download_link: 'Au certificat',
        mail_certificate_download_goodbye:
            "Nous te souhaitons nos meilleurs voeux pour l'avenir ! Bonne continuation !<br><br>Cordialement, l'équipe de somnio.",
        mail_personal_counseling_body:
            "si vous souhaitez avoir un entretien de soutien personnel avec un(e) spécialiste, vous pouvez prendre rendez-vous en cliquant sur le lien suivant : <br> <br> <a href=\"https://my.calenso.com/book/mementor/somnio/support-session@mementor.de\">Prendre rendez-vous</a> <br><br> Vous êtes invité(e) à exposer brièvement vos préoccupations dans le champ de commentaires. Pour l'entretien de soutien sur les questions techniques, une durée de 10 minutes est prévue. Pour les questions concernant le contenu ou autres, une durée de jusqu'à 20 minutes est prévue. Vous serez ensuite appelé(e) à l'heure convenue.",
        mail_personal_counseling_title: 'Votre appel de soutien',
        mail_hint_noreply_address_informal:
            "Ceci est un message généré automatiquement. Merci de ne pas y répondre. L'e-mail ne sera pas lu ni traité.",
        mail_hint_noreply_address_formal:
            "Ceci est un message généré automatiquement. Merci de ne pas y répondre. L'e-mail ne sera pas lu ni traité.",
        mail_license_expiration_pipedrive_title: "Votre licence d'essai expirera dans $$ jours",
        mail_license_expiration_pipedrive:
            'conformément aux normes de la loi relative à la publicité dans le secteur de la santé (Heilmittelwerbegesetz), la période d\'essai est limitée pour les applications numériques de santé. Par la présente, nous tenons à vous rappeler que vous aurez accès au programme pendant encore 12 jours. Afin d\'obtenir une impression complète du programme, vous avez la possibilité de débloquer tous les modules d\'un coup, en passant par l\'onglet "Actions de démo" dans votre "Profil". ',
        mail_license_expiration_pipedrive_footer: "Amusez-vous bien lors de l'essai ! <br> L'équipe de somnio ",
        mail_service_call_offer:
            'aller Anfang ist schwer. Daher wollten wir uns bei dir erkundigen und fragen, wie die ersten Tage mit actensio gelaufen sind? Was lief gut, was weniger? Brauchst du eventuell noch einen kleinen Motivationsschub oder ein offenes Ohr, dem du von deinen Erfahrungen berichten möchtest? <br><br> Dann kannst du gern ein 20-minütiges Supportgespräch mit einer*einem Mitarbeiter*in vereinbaren. Unter folgendem Link kannst du deinen Telefontermin buchen. <br> <br> <a href="https://my.calenso.com/book/mementor/actensio-content">Zum Kalender</a> <br><br> Wir rufen dich zum vereinbarten Zeitpunkt zurück.',
        mail_service_call_offer_subject: 'Buch dein persönliches Supportgespräch',
        mail_upgrade_to_somnio_info:
            'Comme ton score de sommeil indique une insomnie, nous te recommandons la <b> version complète de somnio, disponible sur ordonnance</b>. Voici comment obtenir somnio: <ol> <li>Cherche à parler avec ton médecin traitant ou ton psychothérapeute et fais-toi prescrire somnio.    </li> <li>Le spécialiste prescrit somnio (l’ordonnance doit contenir le nom “somnio” et le PZN 16898724). </li> <li>Soumets l’ordonnance à la Techniker Krankenkasse (TK). </li> <li>La caisse d’assurance maladie génère un code d’activation pour somnio.</li> <li>Démarre le coaching dans l’appli et active ton compte somnio en entrant le code sous la rubrique « Licence ». Tu peux également accéder au coaching dans le navigateur sous <a href="https://app.somn.io.">https://app.somn.io</a>.  </li> </ol> <i>Remarque : tu as alors la possibilité de transférer facilement tes données existantes dans la nouvelle version de somnio.  </i> <br> <br> Tu peux maintenant faire tes premiers pas avec la version complète de somnio afin de réduire les symptômes de ton trouble du sommeil et d’améliorer durablement ton sommeil.',
        mail_upgrade_to_somnio_info_title: 'Obtenir somnio',
        mail_final_interview_title: 'Entretien final ',
        mail_final_interview_explanation:
            'si tu souhaites un entretien final personnel avec un spécialiste, tu peux réserver un rendez-vous en cliquant sur le lien suivant:',
        mail_final_interview_link: 'https://my.calenso.com/book/mementor/somnio-finalinterview',
        mail_final_interview_book_appointment: 'Réserver un rendez-vous',
        mail_final_interview_description:
            'Tu seras ensuite appelé(e) à l\'heure convenue. Si tu le souhaites, tu peux déjà partager avec nous tes premières réflexions sur le programme en les tapant dans le champ "Commentaires".',
        mail_final_interview_timeframe: "L'entretien est prévu pour une durée de 20 minutes.",
        mail_internal_support_multiple_partner_registrations_title: 'Multiple Partner Registrations',
        mail_internal_support_multiple_partner_registrations_description:
            'a user with a partner registration tried to register another coaching.',
        mail_internal_support_multiple_partner_registrations_partner: 'The user is registered with this partner: ',
        mail_internal_support_multiple_partner_registrations_existing: 'The user has following coaching(s): ',
        mail_internal_support_multiple_partner_registrations_new: 'The user tried to register this coaching: ',

        // actensio-specific mailing messages
        mail_actensio_license_expiring_message_non_diga:
            "La licence de ton coaching actensio expire dans les $$ prochains jours. Ouvres l'application pour renouveler ta licence.",
        mail_actensio_license_expiring_message_diga:
            "nous tenons à t'informer que ta licence pour actensio expire dans $$ jours. Pour continuer à avoir accès aux contenus des modules ainsi qu'à l'évaluation régulière de tes données après l'expiration de ta licence, tu auras besoin d'un nouveau <b>code d'ordonnance</b>. Tu peux l'obtenir auprès de ton médecin, thérapeute ou prestataire de télémédecine. Pour en savoir plus sur le processus exact de prescription, cliques <a href=\"https://actens.io/#access\">ici</a>.",
        mail_actensio_license_expired_message_non_diga:
            "Bonjour, nous tenons à t'informer que ta licence pour actensio a expiré. Merci beaucoup pour ta participation. Nous espérons que tu as été satisfait(e) du programme et te souhaitons bonne santé ! L'équipe de actensio",
        mail_actensio_license_expired_message_diga:
            "nous tenons à t'informer que ta licence pour actensio a expiré. Pour continuer à avoir accès aux contenus des modules ainsi qu'à l'évaluation régulière de tes données après l'expiration de la licence, tu as besoin d'un nouveau <b>code d'ordonnance</b>.<br>Tu peux l'obtenir auprès de ton médecin, thérapeute ou prestataire de télémédecine. Pour en savoir plus sur le processus exact de prescription, cliques <a href=\"https://actens.io/#access\">ici</a>.<br><br>Nous espérons que tu as été satisfait(e) du programme et te souhaitons une bonne continuation et bonne santé. Merci d'y avoir participé.",
        mail_actensio_registered_not_logged_in_body:
            "Bienvenue chez actensio ! Merci pour ton inscription. Nous ne t'avons pas vu en ligne dernièrement. Si tu as rencontré des problèmes lors de la connexion, n'hésite pas à nous contacter. Sache que ton compte sera effacé le $$ en raison d'inactivité, si tu ne te connectes pas. Si tu te connectes avant, ton compte sera sûr et prêt pour l'usage!",
        mail_actensio_not_started_first_module_warning_title: "C'est parti ! Débute la première séance avec actensio !",
        mail_actensio_not_started_first_module_warning_body:
            'Ton <b>coach numérique Albert</b> est prêt et t\'attend déjà ! Commence par le premier module du coaching sur <a href="https://app.actens.io">https://app.actens.io</a> et améliore ton <b>bien-être quotidien</b>.<br><br>Si tu rencontres des problèmes lors de la <b>connexion</b>, contacte-nous via <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_actensio_journal_reminder_title: 'Journal omis',
        mail_actensio_journal_reminder_body:
            "Tu n'as pas tenu ton journal d'actensio pendant les $$ derniers jours. La dernière entrée date du $$. Pour que le coaching soit efficace, remplis régulièrement les rapports.",
        mail_actensio_journal_reminder_hint_tracker:
            "Tu as connecté un tracker $$ à ton coaching. Malheureusement, les données n'ont pas pu être transférées automatiquement. Cela peut être dû aux raisons suivantes : <ul><li>Les données de ton tracker ($$) n'ont pas pu synchronisées avec l'application du tracker. Il faut donc effectuer la synchronisation manuellement dans l'application $$. </li><li>La batterie du tracker n'était pas suffisamment chargée lors de la mesure</li><li>Le tracker n'a été connecté que récemment</li><li>Le consentement au transfert de données dans le compte de $$ ne comprend pas toutes les valeurs nécessaires ou a été retiré</li><li>Il peut arriver que la mesure n'est pas possible si tu ne portes pas correctement le tracker </li><li>Des trackers plus anciens ne peuvent pas fournir toutes les données de la précision requise</li></ul><br><br> Par conséquent, s.t.p., saisis manuellement les données pour les jours manquants.",

        // somnio-specific mailing messages
        mail_somnio_license_expiring_message_non_diga:
            "La licence de ton coaching somnio expire dans les $$ prochains jours. Ouvres l'application pour renouveler ta licence.",
        mail_somnio_license_expiring_message_diga:
            "Nous tenons à t'informer que ta licence pour somnio expirera dans $$ jours. Pour continuer à avoir accès à ton journal du sommeil, à ta fenêtre de sommeil, au contenu des modules ainsi  qu'à l'évaluation régulière des données après l'expiration de ta licence, tu auras besoin d'un nouveau code de licence. <br> Puisque tu as déjà une indication (prescription), tu peux demander ta licence de suivi directement auprès de ta caisse d'assurance maladie. En règle générale, tu recevras un nouveau code de licence dans les jours qui suivent. Il est préférable de contacter ta caisse d'assurance maladie dans les délais les plus brefs. <br><br> Une licence de suivi permet de prolonger le coaching sommeil pendant 90 jours supplémentaires. Les données sont conservées et sont à nouveau disponibles dès que l'accès est renouvelé. <br><br> Si ta caisse d'assurance maladie exige une nouvelle ordonnance, tu devras consulter à nouveau ton/ta médecin ou thérapeute, puis soumettre l'ordonnance à ta caisse d'assurance maladie. Tu peux également recourir aux services d'un prestataire de télémédecine (par exemple, <a style=\"color: #009688; text-decoration: none; font-weight: 500\" href=\"https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio\">TeleClinic</a>).",
        mail_somnio_license_expired_message_non_diga:
            "Bonjour, nous tenons à t'informer que ta licence pour notre coaching sommeil a expiré. Merci beaucoup pour ta participation. Nous espérons que tu as été satisfait(e) du programme et te souhaitons un bon sommeil ! L'équipe de somnio",
        mail_somnio_license_expired_message_diga:
            "Nous tenons à t'informer que ta licence pour notre coaching sommeil a expiré. Pour continuer à avoir accès à ton journal de sommeil, ta fenêtre de sommeil, aux contenus des modules ainsi qu'à l'évaluation régulière de tes données après l'expiration de la licence, tu as besoin d'un nouveau <b>code de licence</b>.<br>Tu peux l'obtenir auprès de ton médecin, thérapeute ou prestataire de télémédecine. Pour en savoir plus sur le processus exact de prescription, clique <a href=\"https://somn.io/#access\">ici</a>.<br><br>Nous espérons que tu as été satisfait(e) du programme et te souhaitons une bonne continuation et que ton sommeil continue à être bon et reposant. Merci d'y avoir participé.",
        mail_somnio_registered_not_logged_in_body:
            "Bienvenu(e) chez somnio ! Merci pour ton inscription. Nous ne t'avons pas vu(e) en ligne dernièrement. Si tu as rencontré des problèmes lors de la connexion, n'hésite pas à nous contacter. Sache que ton compte sera effacé le $$ en raison d'inactivité, si tu ne te connectes pas. Si tu te connectes avant, ton compte sera sûr et prêt pour l'usage!",
        mail_somnio_not_started_first_module_warning_title:
            "C'est parti ! Débute la première séance avec ton coach numérique du sommeil !",
        mail_somnio_not_started_first_module_warning_body:
            'Ton <b>coach numérique du sommeil Albert</b> est prêt et t\'attend déjà ! Commence par le premier module du coaching sur <a href="https://app.somn.io">https://app.somn.io</a> pour un meilleur sommeil et améliore ton <b>bien-être quotidien</b>.<br><br>Si tu rencontres des problèmes lors de la <b>connexion</b>, contacte-nous via <a href="mailto:support@mementor.de">support@mementor.de</a>.',
        mail_somnio_journal_reminder_title: 'Journal omis',
        mail_somnio_journal_reminder_body:
            "Tu n'as pas tenu ton journal de sommeil pendant les $$ derniers jours. La dernière entrée date du $$. Pour que le coaching soit efficace, remplis régulièrement les rapports du matin et du soir.",
        mail_somnio_journal_reminder_hint_tracker:
            "Tu as connecté un tracker $$ à ton coaching. Malheureusement, les données n'ont pas pu être transférées automatiquement. Cela peut être dû aux raisons suivantes : <ul><li>Les données de ton tracker ($$) n'ont pas pu synchronisées avec l'application du tracker. IL faut donc effectuer la synchronisation manuellement dans l'application $$. </li><li>La batterie du tracker n'était pas suffisamment chargée lors de la mesure</li><li>Le tracker n'a été connecté que récemment</li><li>Le consentement au transfert de données dans le compte de $$ ne comprend pas toutes les valeurs nécessaires ou a été retiré</li><li>Il peut arriver que la mesure n'est pas possible si tu ne portes pas correctement le tracker </li><li>Des trackers plus anciens ne peuvent pas fournir toutes les données de la précision requise</li></ul><br><br> Par conséquent, il faut manuellement saisir les données pour les jours manquants.",

        mail_waiting_room_reminder_subject_1:
            'Tu y es presque ! Plus que quelques étapes et tu pourras commencer avec somnio ! ',
        mail_waiting_room_reminder_part_1:
            'Félicitations ! Tu as décidé de t’attaquer à tes troubles du sommeil et tu souhaites t’engager sur la voie vers un sommeil sain et réparateur. La bonne nouvelle, c’est que tu n’as plus que quelques étapes à passer !',
        mail_waiting_room_reminder_part_2:
            '<b>Voici comment accéder à somnio</b> <br> <ol><li>Tu as téléchargé l’appli somnio dans l’App-Store et tu t’y es déjà inscrit(e) avec succès. &#10004;</li><li>Pour enfin commencer à utiliser somnio, il te faut encore la prescription de somnio par un médecin ou un psychothérapeute. A cet effet, tu peux ou prendre rendez-vous avec le spécialiste de ton choix (médecin, psychothérapeute), ou fixer un rendez-vous en ligne auprès d’un prestataire de télémédecine (p. ex. <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> ou <a href=https://fr.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>). Lors de la consultation, décris tes troubles du sommeil et parle de somnio. Sur notre site Web <a href="https://www.somn.io/fr">somn.io</a> tu trouveras de plus amples informations, p. ex. une <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">fiche d’information</a> destinée aux professionnels de la santé.</li>',
        mail_waiting_room_reminder_part_3: '<b>As-tu déjà reçu une ordonnance pour somnio ? </b>',
        mail_waiting_room_reminder_part_4:
            '<ol start="3"><li>Très bien, tu peux alors soumettre ton ordonnance à ta caisse d’assurance maladie dans les 30 jours suivant la prescription. La plupart des caisses proposent de le faire via l’application ou via leur site Web respectif. Toutes les caisses d’assurance maladie publiques et la plupart des caisses privées prennent en charge les coûts de somnio.<b> somnio est donc gratuit pour toi ! </b></li> <li>Après avoir soumis l’ordonnance, ta caisse d’assurance maladie t’enverra le <b> code d’activation à 16 caractères </b> de somnio. Ensuite, il ne te reste plus qu’à entrer le code et à commencer !\n </li></ol>',
        mail_waiting_room_reminder_part_5:
            "<b>N’oublie pas :</b> si tu ne t’es pas connecté au programme avant le $$ les données associées à ton compte seront irrévocablement supprimées.\n<br> <b>Un bon sommeil, ça s'apprend. </b>",
        mail_waiting_room_reminder_subject_2:
            'Dormir enfin toute la nuit ? Plus que quelques étapes et tu peux commencer avec somnio !',
        mail_waiting_room_reminder_part_1_2:
            "Félicitations ! Il y a quelques jours, tu t'es inscrit(e) sur somnio car tu souhaitais enfin retrouver une bonne nuit de sommeil. Mais jusqu'à présent, tu n'as pas encore démarré somnio. Pourtant, il ne te reste plus que quelques étapes à franchir pour que tu ne fasses pas que rêver d'un bon sommeil !",
        mail_waiting_room_reminder_part_2_2:
            '<b>Voici encore une fois un rappel - ton chemin rapide vers somnio :</b> <br> <ol><li>Tu t’es déjà inscrit(e) avec succès dans l’application mobile ou l’application Web de somnio. &#10004;</li><li>Pour la prescription de somnio, prends rendez-vous sur place chez le spécialiste de ton choix (médecin, psychothérapeute) ou réserve une consultation en ligne en quelques heures auprès d’un prestataire de télémédecine (p. ex <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> ou <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>). Lors de la consultation, décris tes troubles du sommeil et parle de somnio. Sur notre site Web <a href="https://www.somn.io/en">somn.io</a> tu trouveras de plus amples informations, p. ex. une <a href="https://somn.io/wp-content/uploads/2021/05/Infokarte.pdf">fiche d’information</a> destinée aux professionnels de la santé.</li>',
        mail_waiting_room_reminder_subject_3: "Quelle est l'importance d'un sommeil sain pour toi ?\n ",
        mail_waiting_room_reminder_part_1_3:
            "Bonjour ! Il y a quelques semaines, tu t’es inscrit(e) sur somnio car tu souhaitais enfin retrouver un bon sommeil. Mais jusqu'à présent, tu n’as pas encore débuté le programme. <br> Nous nous demandons si tu as rencontré des problèmes ou si le processus de prescription de somnio n’est pas encore clair pour toi.",
        mail_waiting_room_reminder_part_2_3:
            '<b>Voici encore une fois les étapes à passer pour un accès rapide à somnio : </b> <br> <ol><li>Tu t’es enregistré(e) avec succès dans l’application mobile ou dans l’application Web de somnio. &#10004;</li><li>Pour la prescription de somnio, prends rendez-vous sur place chez le spécialiste de ton choix (médecin, psychothérapeute) ou réserve une consultation en ligne en quelques heures auprès d’un prestataire de télémédecine (p. ex <a href="https://www.teleclinic.com/app/somnio/?utm_source=Partner&utm_medium=online-partner&utm_campaign=1555-diga-somnio">Teleclinic</a> ou <a href="https://en.avimedical.com/diga/somnio-bei-avi-medical">Avimedical</a>).',
        mail_waiting_room_reminder_part_4_3:
            "<ol start=\"3\"><li>Super, tu peux alors envoyer ton ordonnance à ta caisse maladie dans les 30 jours suivant sa délivrance. La plupart des caisses proposent de le faire via l’application ou via leur site Web respectif.</li> <li>Ensuite, ta caisse d'assurance maladie t'enverra le <b>code d'activation à 16 caractères</b> pour somnio. <br> Ensuite, il ne te reste plus qu’à entrer le code et à commencer ! </li></ol> ",

        meditation_exercise_title: 'Respiration guidée',
        meditation_exercise_instruction:
            "Dans cette exercice de respiration, observe pendant quelques minutes comment ton souffle entre et ressort du corps en rythme avec l'animation.",
        meditation_exercise_completed: 'Méditation terminée',
        meditation_exercise_completed_write_to_journal:
            'Méditation terminée. Reporter le temps ($$ minutes) dans ton journal?',
        meditation_exercise_write_to_journal: 'Transmettre',
        meditation_exercise_start: 'Commencer',
        meditation_exercise_end: 'Arrêter',

        lottie_no_insomnia: "Absence d'insomnie",
        lottie_light_insomnia: 'Insomnie légère ',
        lottie_moderate_insomnia: 'Insomnie modérée',
        lottie_severe_insomnia: 'Insomnie sévère',
        lottie_week: 'Semaine',
        lottie_overall: 'Global',

        licensing_title: 'Licences',
        licensing_no_license_found: 'Aucune licence trouvée',
        licensing_credit_debit_card: 'Carte de crédit/carte de débit',
        licensing_credit_debit_card_submit: 'Effectuer le paiement',
        licensing_valid_until: "Valide jusqu'au",
        licensing_trial: "Licence d'évaluation",
        licensing_module_not_licensed: 'Aucune licence valide trouvée pour ce module',
        licensing_voucher_creation: 'Voucher Erstellung',
        licensing_user_registration: 'Nutzer Registrierung',
        licensing_coaching_activation: 'Coaching Aktivierung',

        notification_coaching_saved: 'Coaching enregistré',
        notification_coaching_saved_error: "Coaching n'a pas pu être enregistré",
        notification_coaching_removed: 'Coaching supprimé',
        notification_coaching_removed_error: "Coaching n'a pas pu être supprimé",
        notification_coaching_published: 'Coaching publié',
        notification_coaching_published_error: "Coaching n'a pas pu être publié",
        notification_version_created: 'Nouvelle version créée',
        notification_version_created_error: "Nouvelle version n'a pas pu être créée",
        notification_coaching_version_updated: 'Mise à jour à la dernière version',
        notification_coaching_version_updated_error: 'Mise à jour échouée',
        notification_socket_disconnect: 'Déconnecté du serveur',
        notification_socket_reconnect: 'Connexion au serveur restaurée',
        notification_session_expired: 'Session expirée, connectes-toi à nouveau',
        notification_step_add: "Étape '$$' ajoutée",
        notification_step_remove: "Étape '$$' supprimée",
        notification_step_remove_count: '$$ étapes supprimées',
        notification_step_update: "Étape '$$' enregistrée",
        notification_step_save_error: "Étape  n'a pas pu être enregistrée",
        notification_journal_entry_saved: 'Entrée enregistrée',
        notification_journal_entry_removed: 'Entrée supprimée',
        notification_journal_entry_removed_error: "Entrée n'a pas pu être supprimée",
        notification_text_localised: 'Texte localisé',
        notification_animation_saved: 'Animation "$$" enregistrée',
        notification_animation_saved_error: "Animation n'a pas pu être enregistrée",
        notification_animation_removed: 'Animation "$$" supprimée',
        notification_medication_saved: 'Médicament "$$" enregistré',
        notification_medication_saved_error: "Médicament n'a pas pu être enregistré",
        notification_medication_removed: 'Médicament "$$" supprimé',
        notification_module_saved: 'Module enregistré',
        notification_module_saved_error: "Module n'a pas pu être enregistré",
        notification_module_removed: 'Module supprimé',
        notification_module_removed_error: "Module n'a pas pu être supprimé",
        notification_module_published: 'Module publié',
        notification_module_published_error: "Le module n'a pas pu être publié",
        notification_answer_container_saved: 'Conteneur de réponse "$$" enregistré',
        notification_answer_container_removed: 'Conteneur de réponse "$$" supprimé',
        notification_user_saved: 'Utilisateur "$$" enregistré',
        notification_user_saved_error: 'Utilisateur "$$" n\'a pas pu être enregistré',
        notification_user_removed: 'Utilisateur "$$" supprimé',
        notification_user_removed_error: 'Utilisateur "$$" n\'a pas pu être supprimé',
        notification_user_anonymized: 'Utilisateur "$$" rendu anonyme  ',
        notification_user_anonymized_error: 'Utilisateur "$$" n\'a pas pu être rendu anonyme',
        notification_profile_saved: 'Profil enregistré',
        notification_profile_saved_error: "Profil n'a pas pu être enregistré",
        notification_profile_email_updated: 'Un lien de confirmation a été envoyé à la nouvelle adresse e-mail',
        notification_cannot_edit_published: 'Les modules publiés ne peuvent pas être modifiés',
        notification_module_locked: 'Le module "$$" a été verrouillé par $$',
        notification_module_unlocked: 'Le module "$$" a été débloqué par $$',
        notification_provider_saved: 'Fournisseur "$$" enregistré',
        notification_provider_saved_error: 'Fournisseur "$$" n\'a pas pu être enregistré',
        notification_provider_removed: 'Fournisseur "$$" supprimé',
        notification_provider_removed_error: 'Fournisseur "$$" n\'a pas pu être supprimé',
        notification_fitbit_connected: 'Connecté avec fitbit',
        notification_fitbit_disconnected: 'Déconnecté de fitbit',
        notification_copy_clipboard: 'Copié dans le presse-papiers',
        notification_generic_success: 'Opération réussie',
        notification_generic_error: "Erreur lors de l'exécution de l'opération",
        notification_authorization_get_error: 'Erreur lors du chargement des accès',
        notification_authorization_saved: 'Accès enregistré',
        notification_authorization_saved_license_limit_reached: "Prolongé jusqu'à la fin de la licence",
        notification_authorization_saved_error: "Erreur lors de l'enregistrement de l'accès",
        notification_authorization_removed: 'Accès supprimé',
        notification_authorization_removed_error: "Erreur lors de la suppression de l'accès",
        notification_idle_logout: "Déconnexion automatique à cause d'inactivité",
        notification_idle_logout_warning: 'Inactivité détectée, déconnexion en $$ minute(s)',
        notification_password_saved: 'Mot de passe enregistré',
        notification_password_saved_error: "Erreur lors de l'enregistrement du nouveau mot de passe",
        notification_system_notification_saved: 'Notification de système "$$" enregistrée',
        notification_system_notification_removed: 'Notification de système supprimée',
        notification_sleep_window_saved: 'Fenêtre de sommeil enregistrée',
        notification_sleep_window_saved_error: "Fenêtre de sommeil n'a pas pu être enregistrée",
        notification_code_redeemed: 'Bon remboursé',
        notification_voucher_saved: 'Bon enregistré',
        notification_voucher_saved_error: "Bon n'a pas pu être enregistré",
        notification_vouchers_export_error: "Erreur lors de l'export des bons",
        notification_vouchers_manual_processing_started: 'Opération commencé. Ceci peut durer quelque temps...',
        notification_vouchers_manual_processing_success: 'Traité $$ vouchers manuellement avec succès',
        notification_vouchers_manual_processing_error: 'Erreur lors du traitement manuel des vouchers',
        notification_sync_vouchers_success: 'Bons synchronisés avec succès',
        notification_sync_vouchers_error: 'Erreur lors de la synchronisation des bons',
        notification_voucher_generic_error: "Erreur lors de l'activation du code",
        notification_diga_code_check_download_success: 'Créé rapport de vérification avec succès',
        notification_diga_code_check_download_error: "Rapport de vérification n'a pas pu être créé",
        notification_xRechnung_send_success: 'XRechnung transmis avec succès',
        notification_xRechnung_send_error: "XRechnung n'a pas pu être transmis",
        notification_voucher_removed: 'Bon supprimé',
        notification_voucher_removed_error: "Bon n'a pas pu être supprimé",
        notification_secure_logout: 'Déconnexion sécurisée',
        notification_profile_export_error: "Le profil n'a pas pu être téléchargé",
        notification_role_added: 'Rôle ajouté',
        notification_role_added_error: "Rôle n'a pas pu être ajouté",
        notification_role_removed: 'Rôle supprimé',
        notification_role_removed_error: "Rôle n'a pas pu être supprimé",
        notification_all_modules_unlocked: 'Tous les modules sont déverrouillés',
        notification_all_modules_unlocked_error: "Les modules n'ont pas pu être déverrouillés",
        notification_wait_times_removed: "Tous les délais d'attente ont été supprimés",
        notification_wait_times_removed_error: "Les délais d'attente n'ont pas pu être supprimés",
        notification_license_saved: 'Licence enregistrée',
        notification_license_saved_error: "La licence n'a pas pu être enregistrée",
        notification_demo_entries_generated: 'Les données de démonstration ont été créées',
        notification_demo_entries_generated_error: "Les données de démonstration n'ont pas pu être créées",
        notification_sleep_journal_visual_analysis_success:
            'Évaluation visuelle des entrées dans le journal de sommeil a été créée correctement',
        notification_sleep_journal_visual_analysis_error:
            "Évaluation visuelle des entrées dans le journal de sommeil n'a pas pu être créée correctement",
        notification_sleep_journal_excel_export_success:
            'Entrées dans le journal de sommeil correctement téléchargées.',
        notification_sleep_journal_excel_export_failure:
            "Erreur lors de l'exportation des entrées dans le journal de sommeil",
        notification_resent_activation_email: "Le courriel d'activation de compte a été envoyé",
        notification_resent_activation_email_error: "Le courriel d'activation du compte n'a pas pu être envoyé",
        notification_contact_support_error: "Message n'a pas pu être envoyé",
        notification_journal_summary_error: "Le résumé n'a pas pu être chargé",
        notification_sleep_window_min_length: 'Durée minimale de la fenêtre : $$ heures',
        notification_sleep_window_max_length: 'Durée maximale de la fenêtre : $$ heures',
        notification_goals_saved: 'Tes objectifs ont été sauvegardés',
        notification_payrexx_sync_success: 'Synchronisation avec succès',
        notification_payrexx_sync_failure: 'Synchronisation a échoué',
        notification_medication_plan_saved: 'Plan de médication enregistré',
        notification_medication_plan_updated: 'Plan de médication actualisé',
        notification_medication_plan_saved_error: "Le plan de médication n'a pas pu être enregistrée",
        notification_medication_plan_removed: 'Plan de médication supprimé',
        notification_questionnaire_saved_success: 'questionnaire enregistré',
        notification_questionnaire_saved_error: "Le questionnaire n'a pas pu être enregistré",
        notification_questionnaire_removed_success: 'questionnaire supprimé',
        notification_questionnaire_removed_error: "Le questionnaire n'a pas pu être supprimé",
        notification_journal_update_meditation: 'Le temps de méditation a été transféré au rapport du soir',
        notification_web_authn_connected_error: "La connexion sans mot de passe n'a pas pu être mise en place",
        notification_web_authn_login_error: 'Échec de la connexion sans mot de passe',
        notification_device_deregistration_error: "L'appareil n'a pas pu être retiré.",
        notification_credential_rename_error: "Le nom de l'appareil n'a pas pu être modifié",
        notification_cannot_edit_step: "Module bloqué, actuellement pas possible de modifier l'étape",
        notification_exercise_saved: 'Exercice enregistré',
        notification_exercise_save_error: "Erreur lors de l'enregistrement de l'exercice",
        notification_operation_not_supported: 'Operation pas disponible à ce moment',
        notification_userNotFound: 'User not found',
        notification_health_id_login_success: 'Successful authorized with HealthId',
        notification_health_id_login_error: 'Error while authorising with Health Id',

        speech_no_data: " - je n'ai pas de données.",

        // sbk
        sbk_fields_salutation: 'Civilité',
        sbk_fields_title: 'Titre',
        sbk_fields_first_name: 'Prénom',
        sbk_fields_last_name: 'Nom',
        sbk_fields_policy_number: "Numéro d'assurance",
        sbk_fields_date_of_birth: 'Date de naissance',
        sbk_fields_insurance: 'Assurance',
        sbk_fields_phone: 'Numéro de téléphone',
        sbk_fields_accept_ad_approval:
            "J'accepte que la SBK me contacte par téléphone ou par écrit par voie postale ou courriel pour demander du feedback sur l'action et j'autorise le traitement de mes données dans ce but.",
        sbk_fields_terms: "J'accepte les conditions de la SBK",
        sbk_fields_data_terms: 'Politique de Protection des données',
        sbk_fields_participation_terms: 'Conditions de participation',
        sbk_fields_impressum: 'Mentions légales',
        sbk_save_personal_data: 'Sauvegarder les données personnelles',
        sbk_load_personal_data_error: "Les données personnelles n'ont pas pu être chargées",
        sbk_must_confirm_new_campaign:
            "Il vous faut consentir aux conditions d'utilisation de Schlafgut pour activer votre compte.",
        sbk_must_click_confirmation_link:
            'Enregistré avec succès pour Schlafgut. Veuillez confirmer en cliquant sur le lien qui vous a été envoyé par courriel.',
        sbk_must_set_password: 'Veuillez définir un mot de passe pour aciver le compte.',
        sbk_activation_succeeded_login_now:
            'Votre compte a été activé avec succès. Vous pouvez désormais vous connecter.',
        sbk_report_to_upload_triggered: "Générer et envoyer le rapport à l'interface SBK",
        sbk_can_login_now: 'Vous pouvez vous connecter maintenant',
        sbk_register_with_existing_credentials:
            'Veuillez vous enregistrer en utilisant les identifiants de votre compte existant',
        sbk_register_using_link: 'Veuillez vous enregistrer à travers le lien de la campagne',
        sbk_or_login: 'Se connecter?',
        sbk_app_name: 'Dors bien !',
        sbk_app_lead: "Commencer la journée avec plus d'énergie",
        sbk_claim: 'Nous sommes <br> de ton côté.',
        sbk_campaign_has_expired:
            'Die Aktion für die Sie sich registriert haben, ist deaktiviert. Bitte registrieren Sie sich für eine neue Aktion mit Ihren bestehenden Login-Daten über einen Registrierungslink, den Sie von der SBK erhalten',
        sbk_recommend: 'Recommander SBK',
        sbk_recommend_detail:
            'Nous sommes heureux que vous participiez à notre campagne "Dors bien ! Faites-en profiter vos amis, connaissances ou collègues et recommandez-nous à d\'autres ! Nous vous remercions avec des primes attractives !',
        sbk_recommend_link: 'Simplement cliquez ici !',
        sbk_fields_terms_1: "Oui, j'ai lu les ",
        sbk_fields_terms_2: " et j'accepte la ",
        sbk_fields_terms_3:
            'pour l\'action "Schlaf gut" et je suis d\'accord avec le traitement de mes données personnelles',

        tip_add_answer: 'Ajouter réponse',
        tip_remove_answer: 'Supprimer réponse',
        tip_drag_to_reorder: 'Tirer pour réarranger',
        tip_edit: 'Modifier',
        tip_details: 'Details',
        tip_next_week: 'Semaine suivante',
        tip_previous_week: 'Semaine précédente',
        tip_user_settings: 'Gérer ton compte',
        tip_replay: 'Répéter',
        tip_back: 'Relire cette étape',
        tip_select_animation: 'Choisir animation',
        tip_add_condition: 'Ajouter condition',
        tip_remove_condition: 'Supprimer condition',
        tip_save_in_answer_container: 'Conteneur dans lequel la réponse est enregistrée',
        tip_answer_key: 'Nom de la clé sous laquelle la réponse est enregistrée',
        tip_answer_type: 'Format attendu de la réponse',
        tip_answer_list_items: 'Liste des réponses autorisées',
        tip_next_step: 'Prochaine étape si la condition est correcte',
        tip_localised_answer_text: 'Texte de réponse traduit',
        tip_answer_value: 'Valeur à enregistrer lors de la sélection de cette réponse',
        tip_copy_to_clipboard: 'Copier dans le presse-papiers',
        tip_pause: 'Pause',
        tip_play: 'Lecture',
        tip_skip: 'Sauter',
        tip_one_step_back: "Revenir à l'étape précédente",
        tip_duplicate: 'Dupliquer',
        tip_required_entries_details: "Nombre d'entrées requises au journal pour ouvrir le prochain module",
        tip_clear_animation: "Supprimer l'animation",
        tip_journal_entry_date: "La date où tu t'es levé(e)",
        tip_journal_mood: 'Humeur dans la journée, voire depuis la dernière phase de sommeil',
        tip_questionnaire_name:
            "La clé du questionnaire ne peut être définie qu'une seule fois et ne peut plus être modifiée par la suite",

        validation_input_query: 'Votre entrée est-elle correcte ?',
        validation_input_query_coaching: 'Ton entrée est-elle correcte ?',
        validation_required: 'Entrée requise',
        validation_missing_value: 'Entrée manquante',
        validation_valid_email: 'Adresse valide',
        validation_invalid_email: 'Adresse non valide',
        validation_valid_password: 'Mot de passe valide',
        validation_password_too_short: 'Mot de passe trop court',
        validation_password_too_long: 'Mot de passe trop long (Max. 30 caractères)',
        validation_min_length: 'Minimum de $$ caractères',
        validation_max_length: 'Maximum de $$ caractères',
        validation_license_error_general: 'Erreur',
        validation_time_invalid: 'Temps non valide',
        validation_date_invalid: 'Date non valide',
        validation_repeated_password_mismatch: 'Les mots de passe ne sont pas identiques',
        validation_must_accept_terms: 'Accepte les CG et la PPD',
        validation_must_accept_pp: 'Accepte la PPD',
        validation_required_to_access: 'Requis pour utiliser le programme',
        validation_password_wrong: "L'ancien mot de passe n'est pas correcte",
        validation_invalid_expression: 'Expression non valide',
        validation_one_answer_required: 'Au moins une réponse requise',
        validation_invalid_license_code: 'Code de licence non valide',
        validation_license_code: 'Code de licence',
        validation_diga_code: 'Code DiGA',
        validation_valid_license_code: 'Code de licence valide',
        validation_min_chars_criteria: 'Doit compter au moins 8 caractères',
        validation_special_char_criteria: 'Doit contenir un caractère spécial',
        validation_upper_case_criteria: 'Doit contenir un caractère majuscule',
        validation_date_in_future: 'Date ne peut pas se situer dans le futur',
        validation_too_short: 'Saisie trop courte',
        validation_no_match_email: "L'e-mail n'est pas correct.",
        validation_min: 'Minimum de $$',
        validation_max: 'Maximum de $$',
        validation_negative_number: 'Impossible de saisir des valeurs négatives',

        welcome_title: 'Bienvenue !',
        welcome_next_steps: 'Étapes suivantes',
        welcome_skip_modules: 'Sauter modules',
        welcome_text_generic: "Nous nous réjouissons de t'accompagner sur ton voyage.",
        welcome_text_sleep: "Nous nous réjouissons de t'accompagner sur la voie vers un meilleur sommeil.",
        welcome_text_b: "Il suffit de cliquer sur le premier module dans l'aperçu pour commencer.",
        welcome_text_c:
            "Il s'agit d'un compte de démonstration <b>. Dans ton profil, tu peux déverrouiller tous les modules et déclencher d'autres actions.",
        welcome_text_d:
            'Les modules que tu as déjà complétés au cours du dernier coaching peuvent être sautés en un seul clic une fois que tu les auras lancés.',

        // actensio notification keys
        notification_hypertension_journal_excel_export_success: 'Download der Hypertonie-Tagebucheinträge',
        notification_hypertension_journal_excel_export_failure: 'Fehler beim Export der Hypertonie-Tagebucheinträge',
        // actensio export keys
        general_data_export_hypertension_journals_xlsx_sheet_user_mail: 'Benutzer',
        general_data_export_hypertension_journals_xlsx_title: 'Export von Hypertonie-Tagebucheinträgen',
        general_data_export_hypertension_journals_xlsx_details:
            'Die Einträge, die du im Hypertonie-Tagebuch erfasst hast, sind hier verfügbar im Excel-Format.',
        general_data_export_hypertension_journals_xlsx_sheet_title: 'Hypertonie-Tagebucheinträge',
        general_data_export_hypertension_journals_xlsx_sheet_date_created: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_nr: 'Eintrag Nr.',
        general_data_export_hypertension_journals_xlsx_sheet_header_entry_date: 'Messzeitpunkt',
        general_data_export_hypertension_journals_xlsx_sheet_header_created_at: 'Datum erfasst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_fruits: 'Obst',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_vegetables: 'Gemüse',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_whole_grain: 'Vollkorn',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_low_fat_milk: 'Fettarme Milchprodukte',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_good_fats:
            'Nüsse, Hülsenfrüchte & Ölsaaten',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_salt: 'Salz',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_red_meat: 'Fettiges/rotes Fleisch',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_sugar: 'Stark gezuckerte Lebensmittel',
        general_data_export_hypertension_journals_xlsx_sheet_header_nutrition_dash_score: 'DASH-Score',
        general_data_export_hypertension_journals_xlsx_sheet_header_from_tracker: 'Von Fitnesstracker geladen',

        general_data_export_hypertension_journals_xlsx_sheet_header_medication: 'Medikament',
        general_data_export_hypertension_journals_xlsx_sheet_header_medication_dosage: 'Dosierung',

        general_data_export_hypertension_journals_xlsx_sheet_header_activity_low_intensity: 'Geringe Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_medium_intensity: 'Mittlere Intensität',
        general_data_export_hypertension_journals_xlsx_sheet_header_activity_high_intensity: 'Hohe Intensität',

        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_mindfulness: 'Achtsamkeit',
        general_data_export_hypertension_journals_xlsx_sheet_header_relaxation_other_meditation:
            'Andere Entspannungstechnik',

        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_alcohol: 'Alkoholische Getränke',
        general_data_export_hypertension_journals_xlsx_sheet_header_stimulants_cigarettes: 'Zigaretten',

        general_data_export_hypertension_journals_xlsx_blood_pressure_protocol_title: 'Export BlutdruckProtokoll',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_source: 'Quelle',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_location: 'Ort der Messung',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_has_complaints: 'Beschwerden',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_updated_time: 'Datum aktualisiert',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_systolic: 'Systolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_diastolic: 'Diastolischer Blutdruck',
        general_data_export_blood_pressure_journals_xlsx_sheet_header_pulse: 'Puls',

        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_paralysis: 'Lähmung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_speechImpediment: 'Sprachstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_visualImpairment: 'Sehstörung',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_headache: 'Kopfschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_chestPain: 'Brustschmerzen',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_breathlessness: 'Atemnot',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_dizziness: 'Schwindel',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_tinnitus: 'Tinnitus',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_complaints_ht_noseBleed: 'Nasenbluten',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_home: 'Zuhause',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_doctor: 'Arztpraxis',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_location_ht_pharmacy: 'Apotheke',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_manual: 'Manuell',
        general_data_export_blood_pressure_journals_xlsx_sheet_data_source_ht_thryve: 'Von Thryve',

        high_systolic_blood_pressure_warning:
            'Du hast einen systolischen <b>Blutdruck über 180</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br> Ist dein Blutdruck bei <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_diastolic_blood_pressure_warning:
            'Du hast einen diastolischen  <b>Blutdruck über 120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den <b>ärztlichen Notdienst</b>.',
        high_systolic_and_diastolic_blood_pressure_warning:
            'Du hast einen <b>Blutdruck über 180/120</b> mmHg angegeben. Dies deutet auf ein <b>akutes gesundheitliches Risiko</b> hin. <br>  Ist dein Blutdruck bei  <b>zwei aufeinander</b> folgenden Messungen an unterschiedlichen Armen stark erhöht (systolischer Wert über 180 und/oder diastolischer Wert über 120), kontaktiere bitte umgehend den  <b>ärztlichen Notdienst</b>.',
        low_blood_pressure_warning:
            'Deine Blutdruckwerte sind ungewöhnlich <b>niedrig</b>. <br> Bitte halte mit deinem behandelnden Arzt oder deiner behandelnden Ärztin Rücksprache über diese Werte',

        // entry warning message bits
        input_message_with_value:
            "Tu viens de saisir un '$$' qui dépasse 12 heures. <br> <br> Es-tu sûr que c'est bien le cas ? Attention, la saisie du temps est faite au format 24 heures.",
        journal_fall_asleep_duration_warning: "Délai d'endormissement",
        journal_sleep_time_warning: 'Durée du sommeil',
        journal_time_in_bed_warning: 'Temps passé au lit',
        journal_entry_warning_and: 'et le',
    },
    it_ch: {
        general_continue: 'Avanti',
    },
};

// @ts-ignore
export default dictionary;
