import { Inject, Injectable } from '@angular/core';
import { createLanguage, Language } from '../state/language/language.model';
import { LanguageStore } from '../state/language/language.store';
import { LanguageQuery } from '../state/language/language.query';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import dictionary from '../../../../../backend/src/assets/localisation/dictionary';
import dictionarySbk from '../../../../../backend/src/assets/localisation/themes/dictionarySbk';
import { AuthQuery } from '../state/auth/auth.query';
import { AuthState } from '../state/auth/auth.store';
import { Branding } from '../../../../../backend/src/shared/modules/authentication/enums/brandings.enum';
import { CookieStore } from '../cookie/cookie-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary } from './dictionary.interface';
import { AppContextQuery } from '../application/state/app-context.query';
import { AppContextState } from '../application/state/app-context.store';
import { Application } from '../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { UserQuery } from '../state/user/user.query';
import { IUser } from '../state/user/user.model';
import { RoleName } from '../../../../../backend/src/shared/modules/security/enums/security-roles.enum';
import { RoleQuery } from '../security/state/role/role.query';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LicenseQuery } from '../state/license/license.query';
import { localeDe, localeEn, localeFr, MbscLocale } from '@mobiscroll/angular';
import { merge } from 'rxjs';
import { Translation } from '../../../../../backend/src/shared/modules/language/models/translation.model';
import { MMCookie } from '../enums/cookie-enum';
import { Region } from '../../../../../backend/src/shared/entities/region.enum';
import { ITranslation } from '../state/translation/translation.model';
import { DOCUMENT } from '@angular/common';
import { isCurrentRegion } from '../region-helper/region.helper';
import { LoggingTool } from '../../../tools/logging/contract';
import { getTranslationWithDialectFallback } from '../../../../../backend/src/shared/inputs/translation/translation-fallback';

@Injectable()
@UntilDestroy({ checkProperties: true })

/**
 *  @deprecated service should not be used anymore
 * */
export class LanguageService {
    /**
     * dictionary holds list (hash map) of all translations
     */
    public dictionary = dictionary;

    /**
     * additional branding-specific dictionaries
     */
    public customDictionaries: Map<Branding, any> = new Map<Branding, any>();

    private isRegion = isCurrentRegion();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private userQuery: UserQuery,
        private roleQuery: RoleQuery,
        private languageStore: LanguageStore,
        private formBuilder: UntypedFormBuilder,
        private licenseQuery: LicenseQuery,
        private authQuery: AuthQuery,
        private appContextQuery: AppContextQuery,
        private languageQuery: LanguageQuery,
        private loggingTool: LoggingTool,
    ) {
        // declare languages using hardcoded branding to make sure some languages are available at all times
        const languages = LanguageService.getLanguages(this.appContextQuery.getValue());

        // set languages
        this.languageStore.set(languages);

        // discover for now
        this.discoverLanguage(this.appContextQuery.getValue());

        // get dictionaries
        this.customDictionaries[Branding.SBK] = dictionarySbk;

        // subscribe to relevant changes
        this.setupSubscriptions();
    }

    /**
     * get the languages that are available in the training
     * @private
     */
    private static getLanguages(appContext: AppContextState): Language[] {
        if (appContext.application === Application.ACTENSIO) {
            return [createLanguage(1, 'Deutsch', 'de_ch', true)];
        }
        return [
            createLanguage(1, 'Deutsch', 'de_ch', true),
            createLanguage(2, 'Français', 'fr_ch', true),
            createLanguage(3, 'English', 'en_us', true),
            createLanguage(4, 'Italian', 'it_ch', true),
            createLanguage(5, 'English (Aus)', 'en_au', true),
        ];
    }

    /**
     * handle subscriptions
     * @private
     */
    private setupSubscriptions(): void {
        // update languages depending on the app context
        merge(this.appContextQuery.select(), this.licenseQuery.select())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.adjustAvailableLanguages();
            });

        // subscribe to changes in auth user
        this.authQuery
            .select()
            .pipe(untilDestroyed(this))
            .subscribe((authState: AuthState) => {
                if (
                    authState &&
                    authState.language &&
                    authState.language !== (this.languageQuery.getActive() as Language).code
                ) {
                    this.adjustAvailableLanguages();
                }
            });
    }

    /**
     * change which languages are available based on the app context
     * @private
     */
    private adjustAvailableLanguages(): void {
        // get the app context
        const appContext = this.appContextQuery.getValue();

        let deAvailable: boolean;
        let frAvailable: boolean;
        let enAvailable: boolean;
        let itAvailable: boolean;
        let auAvailable: boolean;

        // handle branding
        switch (appContext.branding) {
            case Branding.SBK:
                deAvailable = true;
                frAvailable = false;
                enAvailable = true;
                itAvailable = false;
                auAvailable = false;
                break;
            case Branding.MEMENTOR:
            default:
                deAvailable = true;
                frAvailable = true;
                enAvailable = true;
                itAvailable = false;
                auAvailable = this.isRegion(Region.OCEANIC);
                break;
        }

        // if not SBK, handle app specific
        if (appContext.branding !== Branding.SBK) {
            switch (appContext.application) {
                case Application.ACTENSIO:
                    deAvailable = true;
                    frAvailable = false;
                    enAvailable = false;
                    itAvailable = false;
                    auAvailable = false;
            }
        }

        // restrict based on joined date
        const release = moment('2021-03-31', 'YYYY-MM-DD').toDate();
        const user: IUser = this.userQuery.getActive() as IUser;

        // if joined date before release, allow german only
        if (user && new Date(user.joined) < release) {
            deAvailable = true;
            frAvailable = false;
            enAvailable = false;
            itAvailable = false;
            auAvailable = false;
        }

        // check if admin, all languages available
        if (
            user &&
            this.roleQuery?.getAll()?.length &&
            this.hasRoles([RoleName.ADMIN, RoleName.CONTENT, RoleName.MANAGER, RoleName.SALES, RoleName.SUPPORT])
        ) {
            deAvailable = true;
            frAvailable = true;
            enAvailable = true;
            itAvailable = true;
            auAvailable = true;
        }

        // set available languages
        this.languageStore.set([
            createLanguage(1, 'Deutsch', 'de_ch', deAvailable),
            createLanguage(2, 'Français', 'fr_ch', frAvailable),
            createLanguage(3, 'English (US)', 'en_us', enAvailable),
            createLanguage(4, 'Italian', 'it_ch', itAvailable),
            createLanguage(5, 'English (AU)', 'en_au', auAvailable),
        ]);

        // check if active language is still supported
        const activeLanguage = this.languageQuery.getActive() as Language;
        const language = this.languageQuery.getEntity(activeLanguage._id);

        if (!language.active) {
            this.languageStore.setActive(1);
        }
    }

    /**
     * add a dictionary
     */
    public addDictionary(newDictionary: Dictionary): void {
        for (const language of this.languageQuery.getAll()) {
            this.dictionary[language.code] = {
                ...this.dictionary[language.code],
                ...newDictionary[language.code],
            };
        }
    }

    /**
     * gets a localised string based on a dictionary key and optional parameters
     * @param key
     * @param params
     * @param languageCode
     */
    public getText(key: string, params?: any[], languageCode?: string): string {
        // abort if data not yet loaded
        if (this.dictionary === undefined || key === '') {
            return key;
        }

        // if no language code has been specified, get default
        if (!languageCode) {
            languageCode = (this.languageQuery.getActive() as Language).code;
        }

        // get from JSON text DB
        let text = this.dictionary[languageCode][key];

        // check if there is a specific text for the current branding
        const branding = this.appContextQuery.getValue().branding;
        if (
            _.has(this.customDictionaries, branding) &&
            _.get(this.customDictionaries[branding], `${languageCode}.${key}`, '')
        ) {
            // overwrite text from custom branding texts
            text = _.get(this.customDictionaries[branding], `${languageCode}.${key}`);
        }

        // replace parameters in string if defined
        text = this.replaceTextParams(text, params);

        // return key if no value found
        return text !== null ? text : key;
    }

    public setActiveLanguage(language: Language): void {
        moment.locale(language.code.substr(0, 2));

        CookieStore.setCookie(MMCookie.LANGUAGE, language.code);

        this.languageStore.setActive(language._id);

        if (language?.code) {
            this.document.documentElement.lang = language.code.substring(0, 2);
        }
    }

    /**
     * set the active language by code
     * @param code
     */
    public setActiveLanguageByCode(code: string): void {
        const language: Language[] = this.languageQuery.getAll({
            filterBy: entity => entity.code === code,
        });

        if (language.length > 0) {
            this.setActiveLanguage(language[0]);
        } else {
            this.loggingTool.error(`Cannot set language by code: ${code}`);
        }
    }

    public getActiveLanguage(): Language {
        return this.languageQuery.getActive() as Language;
    }

    /**
     * get mobiscroll locale by active language
     */
    public getMobiScrollLocale(): MbscLocale {
        const activeLanguage = this.languageQuery.getActive() as Language;
        switch (activeLanguage.code) {
            case 'de_ch':
                return localeDe;
            case 'fr_ch':
                return localeFr;
            case 'en_us':
            case 'en_au':
                return localeEn;
            default:
                return localeDe;
        }
    }

    /**
     * gets a translation form group
     */
    public getTranslationFormGroup(
        required: boolean,
        defaultTextKey: string = '',
        prefillWith: Translation = null,
    ): UntypedFormGroup {
        const de_ch_preset =
            !!defaultTextKey && defaultTextKey !== ''
                ? this.getText(defaultTextKey, null, 'de_ch')
                : prefillWith
                ? prefillWith.de_ch
                : '';
        const fr_ch_preset =
            !!defaultTextKey && defaultTextKey !== ''
                ? this.getText(defaultTextKey, null, 'fr_ch')
                : prefillWith
                ? prefillWith.fr_ch
                : '';
        const it_ch_preset =
            !!defaultTextKey && defaultTextKey !== ''
                ? this.getText(defaultTextKey, null, 'it_ch')
                : prefillWith
                ? prefillWith.it_ch
                : '';
        const en_us_preset =
            !!defaultTextKey && defaultTextKey !== ''
                ? this.getText(defaultTextKey, null, 'en_us')
                : prefillWith
                ? prefillWith.en_us
                : '';
        const en_au_preset =
            !!defaultTextKey && defaultTextKey !== ''
                ? this.getText(defaultTextKey, null, 'en_au')
                : prefillWith
                ? prefillWith.en_au
                : '';

        return this.formBuilder.group({
            de_ch: [de_ch_preset, required ? Validators.required : null],
            fr_ch: [fr_ch_preset, required ? Validators.required : null],
            it_ch: [it_ch_preset, required ? Validators.required : null],
            en_us: [en_us_preset, required ? Validators.required : null],
            en_au: [en_au_preset, null],
        });
    }

    /**
     * replaces parameterised text with given arguments
     */
    public replaceTextParams(text: string, params: string[]): string {
        if (params && text) {
            for (let i = 0; i < params.length; i++) {
                text = text.replace('$$', params[i]);
            }
        }
        return text;
    }

    /**
     * discover language from browser
     */
    public discoverLanguage(context: AppContextState): void {
        if (context.application === Application.ACTENSIO) {
            return this.setActiveLanguageByCode('de_ch');
        }
        // check cookie first
        const langCookie: string = CookieStore.getCookie(MMCookie.LANGUAGE) as string;

        if (langCookie) {
            return this.setActiveLanguageByCode(langCookie);
        }

        // if cookie was not found, try browser language
        const browserLanguage = (navigator.language || navigator['userLanguage']).toLowerCase();

        // if browser language specified
        if (browserLanguage) {
            if (browserLanguage.indexOf('de') >= 0) {
                const language = this.languageQuery.getAll({
                    filterBy: lang => lang.code === 'de_ch',
                })[0];
                if (language.active) {
                    return this.setActiveLanguageByCode('de_ch');
                }
            } else if (browserLanguage.indexOf('fr') >= 0) {
                const language = this.languageQuery.getAll({
                    filterBy: lang => lang.code === 'fr_ch',
                })[0];
                if (language.active) {
                    return this.setActiveLanguageByCode('fr_ch');
                }
            } else if (browserLanguage.indexOf('en-us') >= 0) {
                const language = this.languageQuery.getAll({
                    filterBy: lang => lang.code === 'en_us',
                })[0];
                if (language.active) {
                    return this.setActiveLanguageByCode('en_us');
                }
            }
        }

        // default to de_ch
        this.setActiveLanguageByCode('de_ch');
    }

    /**
     * checks if user has a certain role
     */
    public hasRoles(roleNames: RoleName[]): boolean {
        let hasRole = false;
        for (const roleName of roleNames) {
            const roles = this.roleQuery.getAll({
                filterBy: role => role.name === roleName,
            });
            hasRole = hasRole || roles.length > 0;
        }

        return hasRole;
    }

    /**
     * get string from translation object based on current language with fallback or EN-AU
     * @param translation
     */
    public getStringFromTranslation(translation: ITranslation): string {
        const language = this.languageQuery.getActive() as Language;

        if (translation && language) {
            return getTranslationWithDialectFallback(translation, language.code);
        }

        return '';
    }
}
