const hypertensionJournalDictionary = {
    en_us: {
        ht_journal_blood_pressure: 'Blutdruck',
        ht_journal_pulse: 'Puls',
        ht_low_intensity: 'Low intensity',
        ht_medium_intensity: 'Medium intensity',
        ht_high_intensity: 'High intensity',
        ht_all_intensity: 'All activity',
        ht_steps: 'Steps',
        ht_activity_header: 'Your activity profile',
        ht_stress_header: 'Your stress profile',
        ht_activity: 'Activity',

        ht_days_with_medication: 'Days with medication',

        ht_mindfulness: 'Mindfullnes',
        ht_other_meditation: 'Other technique',
        ht_alcohol: 'Alcohol (servings)',
        ht_cigarettes: 'Zigaretten',

        ht_vegetables: 'Vegetables',
        ht_fruits: 'Fruity',
        ht_wholeGrain: 'Whole grain',
        ht_lowFatMilk: 'Low fat milk',
        ht_goodFats: 'Nuts and Oils',
        ht_goodFats_short: 'Nuts and Oils',
        ht_salt: 'Salt',
        ht_redMeat: 'Red meat',
        ht_sugar: 'Sugar',

        ht_home: 'Home',
        ht_doctor: 'Doctors office',
        ht_pharmacy: 'Pharmacy',

        ht_paralysis: 'Paralysis',
        ht_speechImpediment: 'Speech impediment',
        ht_visualImpairment: 'Visual impairment',
        ht_headache: 'Headache',
        ht_chestPain: 'Chest pain',
        ht_breathlessness: 'Breathlessness',
        ht_dizziness: 'Dizziness',
        ht_tinnitus: 'Tinnitus',
        ht_noseBleed: 'Nose bleed',

        ht_tip_low_intensity:
            'Bei Bewegung mit geringer Intensität kommst du nicht ins Schwitzen oder gerätst außer Atem. Dazu zählt zum Beispiel langsames Gehen oder Stehen mit wenig zusätzlichen Bewegungen',
        ht_tip_medium_intensity:
            'Bei Bewegungen mittlerer Intensität bzw. moderater aerober Aktivität kommst du leicht ins Schwitzen, du kannst dich aber noch gut unterhalten. Dazu zählt zum Beispiel rasches Gehen, Wandern, Nordic Walking, langsames Fahrradfahren, Staubsaugen, oder leichte Gartenarbeit. Zur Senkung deines Blutdrucks solltest du dich an 5 bis 7 Tagen pro Woche für mindestens 30 Minuten pro Tag mit mittlerer (aerober) Intensität bewegen.',
        ht_tip_high_intensity:
            'Bei Bewegungen hohen Intensität bzw. intensiver aerober Aktivität kommst du stark ins Schwitzen. Es fällt dir schwer, dich zu unterhalten und du kommst außer Atem, wie z.B. beim Joggen, schnellem Radfahren, Tennis, Schwimmen, intensive Gartenarbeit',
        ht_tip_dash_score_intensity:
            'Je höher dein DASH-Score ist, desto genauer hast du dich an die Empfehlungen der DASH-Diät gehalten. Bei den blutdrucksenkenden Lebensmitteln erhältst du mehr Punkte, je mehr du von diesen Lebensmitteln isst. Bei den blutdrucksteigernden Lebensmitteln erhältst du mehr Punkte, je weniger du von diesen Produkten isst. Wenn du genau wissen möchtest, wie sich der DASH-Score berechnet, wende dich bitte an unseren Support.',
        ht_tip_journal_vegetables: 'Eine Portion entspricht ca. 100 g bis 150 g Gemüse',
        ht_tip_journal_fruits:
            'Eine Portion entspricht etwa 100 g bis 150 g Obst. Das ist z.B. ein großer Apfel, eine Banane, eine Orange oder ein kleines Glas Orangensaft',
        ht_tip_journal_wholeGrain: 'Eine Portion entspricht z.B. einer Scheibe Vollkornbrot oder 30 g Vollkornmüsli',
        ht_tip_journal_lowFatMilk: 'Eine Portion entspricht etwa 150 g Joghurt, Kefir, Buttermilch oder Magerquark',
        ht_tip_journal_goodFats:
            'Eine Portion Nüsse oder Ölsaaten entspricht etwa 25 g. Eine Portion Hülsenfrüchte  entspricht ca. 70 g roher bzw. 125 g gegarter Bohnen, Linsen oder Kichererbsen',
        ht_tip_journal_salt:
            'Eine stark salzhaltige Portion entspricht einem Gericht mit einem Kochsalzgehalt von 4 bis 5 Gramm',
        ht_tip_journal_redMeat:
            'Eine Portion entspricht etwa 100 bis 150 Gramm rotem, industriell verarbeitetem oder fettigem Fleisch von Rind, Schwein, Lamm oder Ziege',
        ht_tip_journal_sugar:
            'Eine Portion entspricht beispielsweise einem Schokoriegel, einem süßen Gebäckstück oder 250 Milliliter eines Softdrinks',
        ht_tip_journal_alcohol:
            'Eine Portion Alkohol entspricht einer Menge von 12 Gramm reinem Alkohol. Das sind z.B. 0,33 l Bier, 100 ml Wein oder 40 ml Schnaps',
        ht_tip_journal_cigarettes: 'Die Anzahl konsumierter Zigaretten',
        ht_tip_mindfulness: 'Anzahl der Minuten, die du an diesem Tag in Achtsamkeitsübungen investiert hast',
        ht_tip_other_meditation:
            'Anzahl der Minuten, die du an diesem Tag in andere Entspannungstechniken, wie z.B. Progressive Muskelentspannung oder Autogenes Training investiert hast',
        ht_tip_activity:
            'In your activity profile you can see how strong your physical activity is in the areas of leisure, locomotion and work.',
        ht_tip_stress:
            'Your stress profile gives you information about the extent to which worries, social factors and excessive demands contribute to your stress experience.',
        ht_tip_hypertension_interpretation:
            'Bitte beachte: Aus einzelnen Blutdruckwerten ziehen wir keine Rückschlüsse auf den Schweregrad der Hypertonie, da Einzelmessungen schwankungsanfällig und somit nicht für Diagnosezwecke geeignet sind.',
        ht_tip_medication_selection:
            'Erfasse Medikamente im <a href="coaching/hypertensionJournal/medicationPlan">Medikationsplan</a>, damit sie hier erscheinen.',
        ht_average_of: 'Average of the last',
        ht_nutrition_protocol: 'Your nutrition profile',
        ht_hypertension_evaluation: 'Tagebuch',
    },
    de_ch: {
        ht_journal_blood_pressure: 'Blutdruck',
        ht_journal_pulse: 'Puls',
        ht_low_intensity: 'Geringe Intensität',
        ht_medium_intensity: 'Mittlere Intensität',
        ht_high_intensity: 'Hohe Intensität',
        ht_all_intensity: 'Gesamte Aktivität',
        ht_steps: 'Schritte',
        ht_activity_header: 'Dein Bewegungsprofil',
        ht_stress_header: 'Dein Stressprofil',
        ht_activity: 'Bewegung',

        ht_days_with_medication: 'Tage mit Medikamenteneinnahme (7-Tage)',

        ht_mindfulness: 'Achtsamkeit',
        ht_other_meditation: 'Andere Entspannungstechnik',
        ht_alcohol: 'Alkoholische Getränke',
        ht_cigarettes: 'Zigaretten',

        ht_vegetables: 'Gemüse',
        ht_fruits: 'Obst',
        ht_wholeGrain: 'Vollkorn',
        ht_lowFatMilk: 'Fettarme Milchprodukte',
        ht_goodFats: 'Nüsse, Hülsenfrüchte & Ölsaaten',
        ht_goodFats_short: 'Nüsse und Ölsaaten...',
        ht_salt: 'Salz',
        ht_redMeat: 'Fettiges/rotes Fleisch',
        ht_sugar: 'Stark gezuckerte Lebensmittel',

        ht_home: 'Zuhause',
        ht_doctor: 'Arztpraxis',
        ht_pharmacy: 'Apotheke',

        ht_paralysis: 'Lähmung',
        ht_speechImpediment: 'Sprachstörung',
        ht_visualImpairment: 'Sehstörung',
        ht_headache: 'Kopfschmerzen',
        ht_chestPain: 'Brustschmerzen',
        ht_breathlessness: 'Atemnot',
        ht_dizziness: 'Schwindel',
        ht_tinnitus: 'Tinnitus',
        ht_noseBleed: 'Nasenbluten',

        ht_validation_below_systole: 'Muss kleiner sein als Systole',

        ht_tip_low_intensity:
            'Bei Bewegung mit geringer Intensität kommst du nicht ins Schwitzen und gerätst nicht außer Atem. Dazu zählt zum Beispiel langsames Gehen oder Stehen mit wenig zusätzlichen Bewegungen',
        ht_tip_medium_intensity:
            'Bei Bewegungen mittlerer Intensität bzw. moderater aerober Aktivität kommst du leicht ins Schwitzen, du kannst dich aber noch gut unterhalten. Dazu zählt zum Beispiel rasches Gehen, Wandern, Nordic Walking, langsames Fahrradfahren, Staubsaugen, oder leichte Gartenarbeit. Zur Senkung deines Blutdrucks solltest du dich an 5 bis 7 Tagen pro Woche für mindestens 30 Minuten pro Tag mit mittlerer (aerober) Intensität bewegen.',
        ht_tip_high_intensity:
            'Bei Bewegungen hohen Intensität bzw. intensiver aerober Aktivität kommst du stark ins Schwitzen. Es fällt dir schwer, dich zu unterhalten und du kommst außer Atem, wie zB. beim Joggen, schnellem Radfahren, Tennis, Schwimmen, intensive Gartenarbeit',
        ht_tip_dash_score_intensity:
            'Je höher dein DASH-Score ist, desto genauer hast du dich an die Empfehlungen der DASH-Diät gehalten. Bei den blutdrucksenkenden Lebensmitteln erhältst du mehr Punkte, je mehr du von diesen Lebensmitteln isst. Bei den blutdrucksteigernden Lebensmitteln erhältst du mehr Punkte, je weniger du von diesen Produkten isst. Wenn du genau wissen möchtest, wie sich der DASH-Score berechnet, wende dich bitte an unseren Support.',
        ht_tip_journal_vegetables: 'Eine Portion entspricht ca. 100 g bis 150 g Gemüse',
        ht_tip_journal_fruits:
            'Eine Portion entspricht etwa 100 g bis 150 g Obst. Das ist z.B ein großer Apfel, eine Banane, eine Orange oder ein kleines Glas Orangensaft',
        ht_tip_journal_wholeGrain: 'Eine Portion entspricht z.B einer Scheibe Vollkornbrot oder 30 g Vollkornmüsli',
        ht_tip_journal_lowFatMilk: 'Eine Portion entspricht etwa 150 g Joghurt, Kefir, Buttermilch oder Magerquark',
        ht_tip_journal_goodFats:
            'Eine Portion Nüsse oder Ölsaaten entspricht etwa 25 g. Eine Portion Hülsenfrüchte  entspricht ca. 70 g roher bzw. 125 g gegarter Bohnen, Linsen oder Kichererbsen',
        ht_tip_journal_salt:
            'Eine stark salzhaltige Portion entspricht einem Gericht mit einem Kochsalzgehalt von 4 bis 5 Gramm',
        ht_tip_journal_redMeat:
            'Eine Portion entspricht etwa 100 bis 150 Gramm rotem, industriell verarbeitetem oder fettigem Fleisch von Rind, Schwein, Lamm oder Ziege',
        ht_tip_journal_sugar:
            'Eine Portion entspricht beispielsweise einem Schokoriegel, einem süßen Gebäckstück oder 250 Milliliter eines Softdrinks',
        ht_tip_journal_alcohol:
            'Eine Portion Alkohol entspricht einer Menge von 12 Gramm reinem Alkohol. Das sind z.B. 0,33 l Bier, 100 ml Wein oder 40 ml Schnaps',
        ht_tip_journal_cigarettes: 'Anzahl gerauchter Zigaretten',
        ht_tip_mindfulness: 'Anzahl der Minuten, die du an diesem Tag in Achtsamkeitsübungen investiert hast',
        ht_tip_other_meditation:
            'Anzahl der Minuten, die du an diesem Tag in andere Entspannungstechniken, wie z.B. Progressive Muskelentspannung oder Autogenes Training investiert hast',
        ht_tip_activity:
            'In deinem Bewegungsprofil erkennst du, wie stark deine körperliche Aktivität in den Bereichen Freizeit, Fortbewegung und Arbeit ausgeprägt ist.',
        ht_tip_stress:
            'Dein Stressprofil gibt dir Aufschluss darüber, wie stark Sorgen, mangelnde Anerkennung und Überforderung zu deinem Stresserleben beitragen.',
        ht_tip_hypertension_interpretation:
            'Bitte beachte: Aus einzelnen Blutdruckwerten ziehen wir keine Rückschlüsse auf den Schweregrad der Hypertonie, da Einzelmessungen schwankungsanfällig und somit nicht für Diagnosezwecke geeignet sind.',
        ht_tip_medication_selection:
            'Erfasse Medikamente im <a href="coaching/hypertensionJournal/medicationPlan">Medikationsplan</a>, damit sie hier erscheinen.',
        ht_average_of: 'Durchschnitt der letzten',
        ht_nutrition_protocol: 'Dein Ernährungsprofil',
        ht_hypertension_evaluation: 'Tagebuch',
    },
    fr_ch: {
        ht_journal_blood_pressure: 'Blutdruck',
        ht_journal_pulse: 'Puls',
        ht_low_intensity: 'Low intensity',
        ht_medium_intensity: 'Medium intensity',
        ht_high_intensity: 'High intensity',
        ht_all_intensity: 'All activity',
        ht_steps: 'Steps',
        ht_activity_header: "Votre profil d'activité",
        ht_stress_header: 'Votre profil de stress',
        ht_activity: 'Activité',

        ht_days_with_medication: 'Tage mit Medikamenteneinnahme',

        ht_mindfulness: 'Achtsamkeit',
        ht_other_meditation: 'Andere Technik',
        ht_alcohol: 'Alcohol (servings)',
        ht_cigarettes: 'Cigarettes',

        ht_vegetables: 'Vegetables',
        ht_fruits: 'Fruity',
        ht_wholeGrain: 'Whole grain',
        ht_lowFatMilk: 'Low fat milk',
        ht_goodFats: 'Nuts and Oils',
        ht_goodFats_short: 'Nuts and Oils',
        ht_salt: 'Salt',
        ht_redMeat: 'Red meat',
        ht_sugar: 'Sugar',

        ht_home: 'Home',
        ht_doctor: 'Doctors office',
        ht_pharmacy: 'Pharmacy',

        ht_paralysis: 'Paralysis',
        ht_speechImpediment: 'Speech impediment',
        ht_visualImpairment: 'Visual impairment',
        ht_headache: 'Headache',
        ht_chestPain: 'Chest pain',
        ht_breathlessness: 'Breathlessness',
        ht_dizziness: 'Dizziness',
        ht_tinnitus: 'Tinnitus',
        ht_noseBleed: 'Nose bleed',

        ht_tip_low_intensity:
            'Bei Bewegung mit geringer Intensität kommst du nicht ins Schwitzen oder außer Atem. Dazu zählt zum Beispiel langsames Gehen oder Stehen mit wenig zusätzlichen Bewegungen',
        ht_tip_medium_intensity:
            'Bei Bewegungen mittlerer Intensität bzw. moderater aerobar Aktivität kommst du leicht ins Schwitzen, du kannst dich aber noch gut unterhalten. Dazu zählt zum Beispiel rasches Gehen, Wandern, Nordic Walking, langsames Fahrradfahren, Staubsaugen, oder leichte Gartenarbeit. Zur Senkung deines Blutdrucks solltest du dich an 5 bis 7 Tagen pro Woche für mindestens 30 Minuten pro Tag mit mittlerer (aerober) Intensität bewegen.',
        ht_tip_high_intensity:
            'Bei Bewegungen hohen Intensität bzw. intensiver aerober Aktivität kommst du stark ins Schwitzen. Es fällt dir schwer, dich zu unterhalten und du kommst außer Atem, wie zB. beim Joggen, schnellem Radfahren, Tennis, Schwimmen, intensive Gartenarbeit',
        ht_tip_dash_score_intensity:
            'Je höher dein DASH-Score ist, desto genauer hast du dich an die Empfehlungen der DASH-Diät gehalten. Bei den blutdrucksenkenden Lebensmitteln erhältst du mehr Punkte, je mehr du von diesen Lebensmitteln isst. Bei den blutdrucksteigernden Lebensmitteln erhältst du mehr Punkte, je weniger du von diesen Produkten isst. Wenn du genau wissen möchtest, wie sich der DASH-Score berechnet, wende dich bitte an unseren Support.',
        ht_tip_journal_vegetables: 'Eine Portion entspricht ca. 100 g bis 150 g Gemüse',
        ht_tip_journal_fruits:
            'Eine Portion entspricht etwa 100 g bis 150 g Obst. Das ist z.B ein großer Apfel, eine Banane, eine Orange oder ein kleines Glas Orangensaft',
        ht_tip_journal_wholeGrain: 'Eine Portion entspricht z.B einer Scheibe Vollkornbrot oder 30 g Vollkornmüsli',
        ht_tip_journal_lowFatMilk: 'Eine Portion entspricht etwa 150 g Joghurt, Kefir, Buttermilch oder Magerquark',
        ht_tip_journal_goodFats:
            'Eine Portion Nüsse oder Ölsaaten entspricht etwa 25 g. Eine Portion Hülsenfrüchte  entspricht ca. 70 g roher bzw. 125 g gegarter Bohnen, Linsen oder Kichererbsen',
        ht_tip_journal_salt:
            'Eine stark salzhaltige Portion entspricht einem Gericht mit einem Kochsalzgehalt von 4 bis 5 Gramm',
        ht_tip_journal_redMeat:
            'Eine Portion entspricht etwa 100 bis 150 Gramm rotem, industriell verarbeitetem oder fettigem Fleisch von Rind, Schwein, Lamm oder Ziege',
        ht_tip_journal_sugar:
            'Eine Portion entspricht beispielsweise einem Schokoriegel, einem süßen Gebäckstück oder 250 Milliliter eines Softdrinks',
        ht_tip_journal_alcohol:
            'Eine Portion Alkohol entspricht einer Menge von 12 Gramm reinem Alkohol. Das sind z.B. 0,33 l Bier, 100 ml Wein oder 40 ml Schnaps',
        ht_tip_journal_cigarettes: 'Die Anzahl konsumierter Zigaretten',
        ht_tip_mindfulness: 'Anzahl der Minuten, die du an diesem Tag in Achtsamkeitsübungen investiert hast',
        ht_tip_other_meditation:
            'Anzahl der Minuten, die du an diesem Tag in andere Entspannungstechniken, wie z.B. Progressive Muskelentspannung oder Autogenes Training investiert hast',
        ht_tip_activity:
            'Dein Dreieck gibt dir einen Überblick über dein Aktivitätslevel und den drei verschiedenen Bewegungs-Bereichen',
        ht_tip_stress:
            'Dein Dreieck gibt dir einen Überblick über dein Stress-Erleben und die drei dazugehörigen Stress-Bereiche.',
        ht_tip_hypertension_interpretation:
            'Bitte beachte: Aus einzelnen Blutdruckwerten ziehen wir keine Rückschlüsse auf den Schweregrad der Hypertonie, da Einzelmessungen schwankungsanfällig und somit nicht für Diagnosezwecke geeignet sind.',
        ht_tip_medication_selection:
            'Erfasse Medikamente im <a href="coaching/hypertensionJournal/medicationPlan">Medikationsplan</a>, damit sie hier erscheinen.',
        ht_average_of: 'Durchschnitt der letzten',
        ht_nutrition_protocol: 'Dein Ernährungsprofil',
        ht_hypertension_evaluation: 'Tagebuch',
    },
    it_ch: {},
};

export default hypertensionJournalDictionary;
