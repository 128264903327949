import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * CAVE:
 * 'dangerously' is used as prefix, to MAKE the developer AWARE of
 * possible cross side scripting side effects
 * in react a similar approach was used in the 'dangerouslySetInnerHTML' attribute of JSX syntax
 *
 * should NEVER be used on user input!
 *
 * (it is completely harmless though, if used on our content or dictionary)
 *
 */
@Pipe({
    name: 'dangerouslyUnsanitize',
})
export class UnsanitizeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
