import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mm-small-dialog-wrapper',
    templateUrl: './small-dialog-wrapper.component.html',
    styleUrls: ['./small-dialog-wrapper.component.sass'],
})
export class SmallDialogWrapperComponent implements OnInit {
    @Input() title = '';

    constructor() {}

    ngOnInit(): void {}
}
