export enum MMCookie {
    WEB_AUTHN = 'webAuthn',
    SHOW_LOGIN_SCREEN = 'showLoginScreen',
    USER_ANSWERED_PASSWORDLESS_QUESTION = 'userAnsweredPasswordlessQuestion',
    DEVICE_ID = 'deviceId',
    LANGUAGE = 'lang',
    AUTH_TOKEN = 'auth-token',
    REFRESH_TOKEN = 'refresh-token',
    COPIED_CONDITIONS = 'copiedConditions',

    LEGACY_WEB_AUTHN_REQUESTED = 'webAuthnRequested',
}
