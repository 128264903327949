import { Injectable } from '@angular/core';
import { SocketService } from '../../socket/socket.service';
import { Observable } from 'rxjs';
import { SystemNotificationStore } from './system-notification.store';
import { SystemNotificationActions } from '../../../../../../backend/src/shared/modules/system-notification/system-notification.actions';
import { SystemNotificationCreateInput } from '../../../../../../backend/src/shared/modules/system-notification/inputs/system-notification.input.create';
import { SystemNotificationUpdateInput } from '../../../../../../backend/src/shared/modules/system-notification/inputs/system-notification.input.update';
import { NotificationService } from '../../notification/notification.service';
import { applyTransaction } from '@datorama/akita';
import { SystemNotificationF } from './system-notification.entity';
import { LoggingTool } from '../../../../tools/logging/contract';

@Injectable()
export class SystemNotificationService {
    constructor(
        private socketService: SocketService,
        private store: SystemNotificationStore,
        private notificationService: NotificationService,
        private loggingTool: LoggingTool,
    ) {}

    // CREATE

    public create(input: SystemNotificationCreateInput): Observable<SystemNotificationF> {
        return new Observable(observer => {
            this.socketService.fire(SystemNotificationActions.CREATE, input).subscribe(
                (systemNotification: SystemNotificationF) => {
                    this.store.add(systemNotification);

                    this.notificationService.displayInfo(
                        `Created System Notification: ${systemNotification.title.en_us}`,
                    );

                    observer.next(systemNotification);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayError(err.text);

                    observer.error(err);
                },
            );
        });
    }

    // READ

    public getAll(): Observable<SystemNotificationF[]> {
        return new Observable(observer => {
            this.socketService.fire(SystemNotificationActions.GET_ALL, null).subscribe(
                (systemNotifications: SystemNotificationF[]) => {
                    applyTransaction(() => {
                        this.store.update({ unreadCount: 0 });
                        this.store.set(systemNotifications);
                    });

                    observer.next(systemNotifications);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);

                    observer.error(err);
                },
            );
        });
    }

    public getForUser(): Observable<SystemNotificationF[]> {
        return new Observable(observer => {
            this.socketService.fire(SystemNotificationActions.GET_FOR_USER, null).subscribe(
                (systemNotifications: SystemNotificationF[]) => {
                    applyTransaction(() => {
                        this.store.update({ unreadCount: 0 });
                        this.store.set(systemNotifications);
                    });

                    observer.next(systemNotifications);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);

                    observer.error(err);
                },
            );
        });
    }

    public getUnreadCountForUser(): Observable<number> {
        return new Observable(observer => {
            this.socketService.fire(SystemNotificationActions.GET_UNREAD_COUNT_FOR_USER, null).subscribe(
                (unreadCount: number) => {
                    this.store.update({ unreadCount });

                    observer.next(unreadCount);
                    observer.complete();
                },
                err => {
                    this.loggingTool.error(err);

                    observer.error(err);
                },
            );
        });
    }

    // UPDATE

    public update(input: SystemNotificationUpdateInput): Observable<SystemNotificationF> {
        return new Observable(observer => {
            this.socketService.fire(SystemNotificationActions.UPDATE, input).subscribe(
                (systemNotification: SystemNotificationF) => {
                    this.store.update(systemNotification._id, systemNotification);

                    this.notificationService.displayInfo(
                        `Updated System Notification: ${systemNotification.title.en_us}`,
                    );

                    observer.next(systemNotification);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayError(err.text);

                    observer.error(err);
                },
            );
        });
    }

    // DELETE

    public remove(systemNotification: SystemNotificationF): Observable<SystemNotificationF> {
        return new Observable(observer => {
            this.socketService.fire(SystemNotificationActions.REMOVE, systemNotification._id).subscribe(
                () => {
                    this.store.remove(systemNotification._id);

                    this.notificationService.displayInfo(
                        `Removed System Notification: ${systemNotification.title.en_us}`,
                    );

                    observer.next(systemNotification);
                    observer.complete();
                },
                err => {
                    this.notificationService.displayError(err.text);

                    observer.error(err);
                },
            );
        });
    }
}
