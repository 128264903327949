import { NgModule } from '@angular/core';
import { LocalisationPipe } from './localisation.pipe';
import { OrderPipe } from './oder.pipe';
import { SearchPipe } from './search.pipe';
import { DateCalcPipe } from './dateCalc.pipe';
import { UniquePipe } from './unique.pipe';
import { UnsanitizeHtmlPipe } from './unsanitize-html.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { NumerusToDictionaryKeyPipe } from './numerus-to-dictionary-key.pipe';
import { CoachingsPipe } from './coachings.pipe';
import { FilterPipe } from './filter.pipe';
import { ReversePipe } from './reverse.pipe';
import { GetDateFormatPipe } from './get-date-format.pipe';

export const PIPES = [
    LocalisationPipe,
    OrderPipe,
    SearchPipe,
    DateCalcPipe,
    UniquePipe,
    CoachingsPipe,
    UnsanitizeHtmlPipe,
    NumerusToDictionaryKeyPipe,
    UnsanitizeHtmlPipe,
    FormatDatePipe,
    FilterPipe,
    ReversePipe,
    GetDateFormatPipe,
];

@NgModule({
    declarations: PIPES,
    exports: PIPES,
})
export class PipesModule {}
