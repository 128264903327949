import { ID } from '@datorama/akita';
import { Answer } from '../../../../../../backend/src/modules/coaching/models/answer/answer.model';

/**
 * answer container
 */
export interface IAnswer extends Answer {
    _id: ID;
}

/**
 * factory function
 */
export function createAnswer(params: Partial<IAnswer>) {
    return { ...params } as IAnswer;
}
