import { trigger, state, style, animate, transition, query, group } from '@angular/animations';

/**
 * shift transition
 */
export function ShiftTransition() {
    // return the shift animation
    return trigger('routerTransition', [
        transition('* <=> *', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), { optional: true }),
            group([
                // block executes in parallel
                query(
                    ':enter',
                    [
                        style({ transform: 'translateX(100%)', opacity: 0 }),
                        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 })),
                    ],
                    { optional: true },
                ),
                query(
                    ':leave',
                    [
                        style({ transform: 'translateX(0%)', opacity: 1 }),
                        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 })),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
    ]);
}

/**
 * fade transition
 */
export function FadeTransition() {
    // return the fade animation
    return trigger('routerTransition', [
        transition('* <=> *', [
            query(
                ':enter, :leave',
                style({
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                }),
                { optional: true },
            ),

            group([
                // block executes in parallel
                query(':enter', [style({ opacity: 0 }), animate('.5s ease-in-out', style({ opacity: 1 }))], {
                    optional: true,
                }),
                query(':leave', [style({ opacity: 1 }), animate('.5s ease-in-out', style({ opacity: 0 }))], {
                    optional: true,
                }),
            ]),
        ]),
    ]);
}

/**
 * subtitle animation
 */
export function SubtitleAnimation() {
    return trigger('subtitleAnimation', [
        transition(':enter', [style({ opacity: 0 }), animate('.2s ease', style({ opacity: 1 }))]),
        transition(':leave', [style({ opacity: 1 }), animate('.2s ease', style({ opacity: 0 }))]),
    ]);
}
