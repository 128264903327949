import { Injectable } from '@angular/core';
import { SocketService } from '../../../../shared/socket/socket.service';
import { ErrorTranslationKey, NotificationService } from '../../../../shared/notification/notification.service';
import { Observable } from 'rxjs';
import { SupportActions } from '../../../../../../../backend/src/shared/modules/support/actions/support.actions';
import { ContactSupportBody } from '../../../../../../../backend/src/shared/modules/support/models/contact-support-body';

@Injectable({ providedIn: 'root' })

/**
 * support service
 */
export class SupportService {
    /**
     * constructor
     * */
    constructor(private socketService: SocketService, private notificationService: NotificationService) {}

    /**
     * contact support redirects the request to the ticketing system
     */
    public contactSupport(contactSupportBody: ContactSupportBody): Observable<any> {
        return new Observable(observer => {
            this.socketService.fire(SupportActions.CONTACT, contactSupportBody).subscribe(
                () => {
                    observer.next();
                    observer.complete();
                },
                err => {
                    this.notificationService.displayErrorWithCode(err, ErrorTranslationKey.MESSAGE_SEND_ERROR);
                    observer.error(err);
                },
                () => {
                    observer.complete();
                },
            );
        });
    }

    public reactivateConsent(coachingId: string): Observable<void> {
        return new Observable(observer => {
            this.socketService.fire(SupportActions.REACTIVATE_CONSENT, { coachingId }).subscribe(
                () => {
                    observer.next();
                    observer.complete();
                },
                error => {
                    observer.error(error);
                },
                () => observer.complete(),
            );
        });
    }
}
