import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IHypertensionJournalEntry } from './hypertension-journal-entry.model';

export interface HypertensionEntryState extends EntityState<IHypertensionJournalEntry> {
    defaultDate: Date;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'hypertensionJournalEntry', idKey: '_id' })
export class HypertensionJournalEntryStore extends EntityStore<HypertensionEntryState, IHypertensionJournalEntry> {
    constructor() {
        super({ defaultDate: null });
    }
}
