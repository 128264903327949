import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServerDowntimePromptComponent } from './server-downtime-prompt.component';
import { ServerDowntimePromptService } from './server-downtime-prompt.service';
import { PipesModule } from '../../../shared/pipes/pipe.module';
import { DialogModule } from '../../../shared/dialog/dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [DialogModule, MatDialogModule, PipesModule, MatIconModule, MatButtonModule, CommonModule],
    declarations: [ServerDowntimePromptComponent],
    providers: [ServerDowntimePromptService],
})
export class ServerDowntimePromptModule {}
