<mm-dialog-header [title]="strings.header({ applicationName })"></mm-dialog-header>

<ng-container [ngSwitch]="viewState">
    <ng-container *ngSwitchCase="PromptViewState.INITIAL_INVITE">
        <mat-dialog-content
            class="user-test-invitation-content"
            [innerHTML]="strings.body({ applicationName, applicationDomain })"
            (click)="onInvitationMessageClick($event)"
        />

        <mat-dialog-actions class="user-test-invitation-actions">
            <button
                mat-raised-button
                (click)="onResponseClick(TestInvitationPromptCoachingSetting.POSTPONED)"
                type="button"
            >
                {{ strings.askMeLater() }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="onResponseClick(TestInvitationPromptCoachingSetting.IGNORED)"
                type="button"
            >
                {{ strings.dontShowAgain() }}
            </button>
        </mat-dialog-actions>
    </ng-container>
    <ng-container *ngSwitchCase="PromptViewState.LEAVE_APP_WARNING">
        <mat-dialog-content class="confirmation-content" [innerHTML]="strings.leaveAppConfirmationMessage()" />
        <mat-dialog-actions class="confirmation-actions">
            <button
                (click)="promptViewStateSubject.next(PromptViewState.INITIAL_INVITE)"
                mat-raised-button
                type="button"
            >
                <span>{{ strings.backAction() }}</span>
            </button>
            <button
                (click)="promptViewStateSubject.next(PromptViewState.CONFIRM_SUCCESS)"
                color="primary"
                mat-raised-button
                type="button"
            >
                <span>
                    {{ strings.leaveAppContinueAction() }}
                </span>
            </button>
        </mat-dialog-actions>
    </ng-container>
    <ng-container *ngSwitchCase="PromptViewState.CONFIRM_SUCCESS">
        <mat-dialog-content class="confirmation-content" [innerHTML]="strings.testSuccessConfirmationMessage()" />
        <mat-dialog-actions class="confirmation-actions">
            <button
                (click)="promptViewStateSubject.next(PromptViewState.INITIAL_INVITE)"
                mat-raised-button
                type="button"
            >
                <span>{{ strings.backAction() }}</span>
            </button>
            <button
                (click)="promptViewStateSubject.next(PromptViewState.COMPLETED)"
                color="primary"
                mat-raised-button
                type="button"
            >
                <span>
                    {{ strings.testSuccessConfirmationAction() }}
                </span>
            </button>
        </mat-dialog-actions>
    </ng-container>
</ng-container>
