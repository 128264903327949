export enum LicensingActions {
    UPDATE = 'license:update',
    GET_FOR_SOCKET_USER = 'license:getForSocketUser',
    GET_FOR_USER = 'license:getForUser',
    GET_BY_MAIL = 'license:getByMail',
    GET_BY_CODE = 'license:getByCode',
    REDEEM_CODE = 'license:redeemCode',
    SEND_RENEWAL_INFOS_VIA_MAIL = 'license:sendRenewalInfosViaMail',
    GET_LICENSE_EXPIRATION_DAYS = 'license:getLicenseExpirationDays',
}
