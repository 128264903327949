import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { LicenseStore, State } from './license.store';
import { ILicense } from './license.model';

@Injectable({
    providedIn: 'root',
})

/**
 * License query
 */
export class LicenseQuery extends QueryEntity<State, ILicense> {
    /**
     * constructor
     */
    constructor(protected store: LicenseStore) {
        super(store);
    }
}
