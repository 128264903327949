import { Region } from '../../../../../../backend/src/shared/entities/region.enum';
import { Application } from '../../../../../../backend/src/shared/modules/coaching/enums/application.enum';
import { Branding } from '../../../../../../backend/src/shared/modules/authentication/enums/brandings.enum';
import { CoachingType } from '../../../../../../backend/src/modules/coaching/enums/coaching-types.enum';

export enum TestInvitationPromptCoachingSetting {
    IGNORED = 'ignored',
    POSTPONED = 'ask-later',
    COMPLETED = 'completed',
}

export default {
    promptDecisionCoachingSetting: 'userTestInvitationPrompt2024',
    promptDecisionTimestampCoachingSetting: 'userTestInvitationDecisionTimestamp2024',
    promptDisplayDelayMs: 10000,
    matDialogConfig: {
        autoFocus: false,
        maxWidth: '500px',
    },
    promptAddressedStates: [
        // states where we no longer display prompt to user at all
        TestInvitationPromptCoachingSetting.IGNORED,
        TestInvitationPromptCoachingSetting.COMPLETED,
    ],
    whenToDisplay: {
        region: Region.DEFAULT,
        branding: Branding.MEMENTOR,
        applications: [Application.ACTENSIO, Application.SOMNIO],
        coachingTypes: [CoachingType.ACTENSIO, CoachingType.SLEEP],
    },
    invitationLinkClassName: 'invite-link',
};
