import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '../../translation/translation.service';

@Component({
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.sass'],
})
export class ConfirmationDialogComponent {
    public data: Input | undefined;

    get strings() {
        return this.transService.strings.common.confirmationDialog;
    }

    static open(
        dialog: MatDialog,
        config?: MatDialogConfig<Input>,
    ): MatDialogRef<ConfirmationDialogComponent, boolean> {
        return dialog.open<ConfirmationDialogComponent, Input, boolean>(ConfirmationDialogComponent, config);
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: Input | undefined,
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private transService: TranslationService,
    ) {
        this.data = data;
    }

    public onConfirm(): void {
        this.dialogRef.close(true);

        // in safari, opening a link has to be executed on a click-callback, that's why this logic is inside this component
        if (this.data?.linkToOpenOnConfirm) {
            window.open(this.data.linkToOpenOnConfirm, '_blank');
        }
    }
}

interface Input {
    title?: string;
    message?: string;
    linkToOpenOnConfirm?: string;
}
