<mm-header *ngIf="!inStepEditor" [alwaysShowTitle]="true" [title]="strings.header()"></mm-header>
<div [ngClass]="inStepEditor ? 'weekly-planner-step-wrapper' : 'weekly-planner-wrapper'">
    <div class="week-banner">{{ 'general_week' | localise }} {{ from + ' - ' + to }}</div>
    <div class="day-planner-wrapper">
        <mm-weekly-planner-day
            *ngFor="let item of weekDays; let first = first; let last = last"
            [items]="items"
            [day]="item.day"
            [dayPlans]="item.dayPlans"
            [isFirstItem]="!!first"
            [isLastItem]="!!last"
            [weeklyPlannerTypeOptions]="weeklyPlannerType"
            [hasScheduledNextModule]="hasScheduledNextModule"
            (saveClicked)="onSaveClicked($event)"
            (updateClicked)="onUpdateClicked($event)"
            (deleteClicked)="onDeleteClicked($event)"
        >
        </mm-weekly-planner-day>
    </div>
</div>
