<mm-dialog-header [title]="strings.header()"> </mm-dialog-header>

<mat-dialog-content>
    <div style="text-align: center">
        <p [innerHTML]="getTimeChangeLanguageKey()"></p>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-raised-button
        style="display: block; margin: auto"
        color="primary"
        (click)="onGotItClick()"
        type="button"
    >
        <span>{{ 'general_got_it' | localise }}</span>
    </button>
</mat-dialog-actions>
