import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { DeviceState, DeviceStore } from './device.store';

@Injectable({
    providedIn: 'root',
})
export class DeviceQuery extends Query<DeviceState> {
    constructor(protected store: DeviceStore) {
        super(store);
    }
}
