import { Condition } from '../../../../../../backend/src/modules/coaching/models/condition/condition.model';
import { ID } from '@datorama/akita';

/**
 * condition type
 */
export interface ICondition extends Condition {
    _id: string | ID;
}

/**
 * factory function
 * @param params
 */
export function createCondition(params: Partial<ICondition>) {
    return { ...params } as ICondition;
}
