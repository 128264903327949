import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppContextService } from './app-context.service';

@NgModule({
    declarations: [],
    providers: [AppContextService],
    imports: [CommonModule],
})

/**
 * application module
 */
export class AppContextModule {
    /**
     * constructor
     */
    constructor(private applicationService: AppContextService) {
        this.applicationService.initialise();
    }
}
