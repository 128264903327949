<mat-dialog-actions>
    <div fxLayout="row" class="full">
        <div fxFlex="nogrow">
            <!-- delete module -->
            <button
                mat-button
                [disabled]="loading"
                *ngIf="id?.value && !disableDelete"
                color="accent"
                type="button"
                (click)="onDeleteEntity()"
            >
                <mat-icon>delete</mat-icon>
                <span fxHide.xs>{{ 'general_delete' | localise }}</span>
            </button>
        </div>

        <div fxFlex="grow" style="text-align: right">
            <ng-content></ng-content>

            <!-- cancel to do editor -->
            <button mat-button [disabled]="loading" type="button" matDialogClose>
                {{ 'general_cancel' | localise }}
            </button>

            <!-- save -->
            <button
                mat-button
                [disabled]="loading"
                style="margin-left: 5px"
                color="primary"
                type="submit"
                (click)="submit.next(true)"
            >
                <mat-spinner *ngIf="loading" class="button-spinner" [diameter]="20" [strokeWidth]="3"></mat-spinner>
                <mat-icon>check</mat-icon>
                <span fxHide.xs>{{ 'general_save' | localise }}</span>
            </button>
        </div>
    </div>

    <p class="error" *ngIf="!entityForm.valid && !entityForm.disabled && entityForm.touched">
        Bitte überprüfe deine Eingaben
    </p>
</mat-dialog-actions>
