import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { SystemNotificationF } from './system-notification.entity';

export interface SystemNotificationState extends EntityState<SystemNotificationF>, ActiveState {
    unreadCount: number;
}

const initialState: SystemNotificationState = {
    active: null,
    unreadCount: 0,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'systemNotification', idKey: '_id' })
export class SystemNotificationStore extends EntityStore<SystemNotificationState, SystemNotificationF> {
    constructor() {
        super(initialState);
    }
}
