import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface DeviceState {
    touch: boolean;
    iOS: boolean;
    android: boolean;
    app: boolean;
}

export function createInitialState(): DeviceState {
    return {
        touch: false,
        iOS: false,
        android: false,
        app: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'device' })
export class DeviceStore extends Store<DeviceState> {
    constructor() {
        super(createInitialState());
    }
}
