import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IRole } from './role.model';

export interface State extends EntityState<IRole> {}

@Injectable({
    providedIn: 'root',
})
/**
 * roles store
 */
@StoreConfig({ name: 'roles', idKey: '_id' })
export class RoleStore extends EntityStore<State, IRole> {
    /**
     * constructor
     */
    constructor() {
        super();
    }
}
