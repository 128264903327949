import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../pipes/pipe.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { HeaderComponent } from './header.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        FlexLayoutModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatTooltipModule,
        MatDialogModule,
    ],
    declarations: [HeaderComponent],
    providers: [],
    exports: [HeaderComponent],
})
export class HeaderModule {}
